import { Flex } from 'antd';
import DynamicTable from 'components/V2/dinamic-table';
import dayjs from 'dayjs';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useState } from 'react';
import { useMediaQuery } from '@chakra-ui/media-query';
import { PiEyeLight } from 'react-icons/pi';
import { PiEyeClosedLight } from 'react-icons/pi';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import fear from 'assets/images/fear.png';
import disgusted from 'assets/images/disgusted.png';
import sad from 'assets/images/sad.png';
import happy from 'assets/images/happy.png';
import prettyGood from 'assets/images/pretty-good.png';

import { FilterType } from 'infra/helpers/types';
import useHelperFeedbacks from 'core/features/helpers/hooks/useHelperFeedbacks';
import { FeedbackDetails } from 'core/features/helpers/typings';
import FeedbackFilter from 'components/feedback-filter';

function HelperFeedbackTable() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();


  const ratings = [
    {
      label: 'Muito Ruim',
      value: 1,
      image: disgusted,
    },
    {
      label: 'Ruim',
      value: 2,
      image: fear,
    },
    {
      label: 'Regular',
      value: 3,
      image: sad,
    },
    {
      label: 'Bom',
      value: 4,
      image: happy,
    },
    {
      label: 'Muito Bom',
      value: 5,
      image: prettyGood,
    },
  ];



 
  const [filters, setFilters] = useState<FilterType>({
    initialDate: [],
    ratings: [],
  });
  const { data, isLoading } = useHelperFeedbacks({
    page: page,
    pageSize: pageSize,
    ratings: filters?.ratings.map((item) => item.value) ?? undefined,
    search: search,
    initialDate: filters?.initialDate.map((item) => item.value)[0] ?? undefined,
  });

  const customExpandIcon = (props: any) => {
    if (props.expanded) {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <PiEyeLight style={{ fontSize: '22px' }} />
        </a>
      );
    } else {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <PiEyeClosedLight style={{ fontSize: '22px' }} />
        </a>
      );
    }
  };

  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };

  const columns = [
    {
      title: <p style={tableHeaderStyle}>#</p>,
      name: 'Icone',
      dataIndex: 'icon',
      key: 'icon',
      render: (_: string, record: FeedbackDetails) => (
        <img src={ratings.find((item)=> item.value === record.rating)?.image} alt="emoji" width={'40px'} />
      ),
    },
    {
      title: <p style={tableHeaderStyle}>Reposta</p>,
      name: 'Categoria',
      dataIndex: 'category',
      key: 'category',
      mobile: true,
      render: (_: string, record: FeedbackDetails) => (
        <p style={tableRowStyle}>{ratings.find((item)=> item.value === record.rating)?.label}</p>
      ),
      sorter: (a: FeedbackDetails, b: FeedbackDetails) => a.rating - b.rating,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Título</p>,
      name: 'Título',
      dataIndex: 'title',
      key: 'title',
      mobile: true,
      render: (_: string, record: FeedbackDetails) => (
        <p style={tableRowStyle}>{record.helper.title}</p>
      ),
      sorter: (a: FeedbackDetails, b: FeedbackDetails) =>
        a.helper.title.localeCompare(b.helper.title),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Feedback</p>,
      name: 'Feedback',
      dataIndex: 'feedback',
      key: 'feedback',
      render: (_: string, record: FeedbackDetails) => (
        <p style={tableRowStyle}>{record.feedback}</p>
      ),
      sorter: (a: any, b: any) => a.feedback.localeCompare(b.feedback),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Data</p>,
      name: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: (_: string, record: FeedbackDetails) => (
        <p style={tableRowStyle}>
          {dayjs(record.createdAt).format('DD/MM/YYYY')}
        </p>
      ),
      sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
  ];

  return (
    <Flex vertical>
      <DynamicTable
        id="id-tour-helpers-feedback-table"
        itemName={data && data?.total > 1 ? 'feedbacks' : 'feedback'}
        data={data?.data ?? []}
        page={page}
        onChangePage={setPage}
        isLoading={isLoading}
        allColumns={
          isMobile ? columns.filter((column) => column.mobile) : columns
        }
        defaultColumns={
          isMobile ? columns.filter((column) => column.mobile) : columns
        }
        hideCog={true}
        title=''
        pageSize={pageSize}
        onChangePageSize={setPageSize}
        total={data?.total ?? 0}
        appliedFilters={filters}
        onChangeFilters={setFilters}
        filters={
          <FeedbackFilter filters={filters} onChangeFilters={setFilters} />
        }
        onSearch={setSearch}
        searchBarPlaceholder='Pesquisar Ajuda'
        expandable={
          isMobile
            ? {
                expandedRowRender: (record: FeedbackDetails) => (
                  <Flex vertical>
                    <p style={{ margin: 0, fontWeight: 600 }}>
                      <span style={{ color: colorPrimary, fontWeight: 600 }}>
                        Data:{' '}
                      </span>
                      {dayjs(record.createdAt).format('DD/MM/YYYY')}
                    </p>
                    <p style={{ margin: 0, fontWeight: 600 }}>
                      <span style={{ color: colorPrimary, fontWeight: 600 }}>
                        Feedback:{' '}
                      </span>
                      {record.feedback === '' ? 'Não há' : record.feedback}
                    </p>
                  </Flex>
                ),
                expandIcon: (props: any) => customExpandIcon(props),
              }
            : null
        }
      />
    </Flex>
  );
}

export default HelperFeedbackTable;
