import styled from 'styled-components'; // Import styled-components

export const StyledBox = styled.div<any>`
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25),
    0px -0.5px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-width: ${({ size }) =>
    size === 'md' ? '368px' : size === 'lg' ? '569px' : '176px'};
  min-height:143px;
  padding: ${({ isMobile,size }) => (isMobile && size === 'md' || size === 'lg'? '14px 24px': isMobile ? '8px 0px' : '16px')};
  gap: ${({ isMobile }) => (isMobile ? '8px' : '16px')};
  color: black;
  position: relative;
`;
