import Layout from 'components/layout';
import { useMediaQuery } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Checkbox, Col, Collapse, Divider, Flex, Form, Input, Row } from 'antd';
import useTour from 'core/features/tour/hooks/useTour';
import { useSaveTour } from 'core/features/tour/hooks/useSaveTour';
import { TourForm } from 'core/features/tour/typings';
import BackButton from 'components/buttons/back-button';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import {
  notificateError,
  notificateSucess,
  openNotification,
  showConfirm,
} from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import CustomizedRequiredMark from 'components/required-mark';
import { PiEyeFill } from 'react-icons/pi';
import Button from 'components/buttons/button';
import CustomUpload from 'components/customUpload';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import { FiMove, FiTrash2 } from 'react-icons/fi';
import ModalSelectArea from '../../components/SelectSiteArea';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import TourPreview from '../../components/tourPreview';
import moment from 'moment';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import UploadImagem from 'components/inputs/upload-imagem';

const { TextArea } = Input;

function CreateTour() {
  const params = useParams<{ id: string }>();
  const { data, isLoading } = useTour(
    params.id === 'new' ? undefined : params.id
  );
  const history = useHistory();
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveTour();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary, colorGreyBorderAndFont, colorGreyCard } =
    useU4heroColors();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [form] = Form.useForm();
  const steps = Form.useWatch('steps', form);
  const initialTour = Form.useWatch('initialTour', form);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [activeKeys, setActiveKeys] = useState<string[]>(
    params.id === 'new' ? ['0'] : []
  );
  const updateForm = (
    fieldName: string | number | (string | number)[],
    value: any
  ) => {
    form.setFieldValue(fieldName, value);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedSteps = Array.from(steps);
    const [removed] = reorderedSteps.splice(result.source.index, 1);
    reorderedSteps.splice(result.destination.index, 0, removed);

    form.setFieldsValue({ steps: reorderedSteps });
  };

  const onSubmit = (values: TourForm) => {
    handleSave(
      {
        ...values,
        id: params.id !== 'new' ? params.id : undefined,
      },
      {
        onError: () => {
          notificateError(
            `Houve um problema ao ${
              params.id !== 'new' ? 'editar' : 'salvar'
            }. Verifique sua conexão e tente novamente.`
          );
        },
        onSuccess: () => {
          notificateSucess(
            `Tour ${params.id !== 'new' ? 'editado' : 'criado'} com sucesso!`
          );

          history.push('/helpers-u4hero?tab=3');
        },
      }
    );
  };

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const validateStep = (step: any) => {
    const required = ['title', 'referenceId', 'description', 'url'];
    for (const prop of required) {
      if (!step[prop]) {
        return false;
      }
    }
    return true;
  };

  return (
    <Layout.Container>
      {isPreviewOpen ? (
        <TourPreview
          onClose={() => setIsPreviewOpen(false)}
          steps={steps}
          url={steps && steps[0].url}
        />
      ) : null}
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        requiredMark={CustomizedRequiredMark}
        validateTrigger="submit"
        initialValues={
          data ?? {
            steps: [
              {
                title: undefined,
                description: undefined,
                image: undefined,
                url: undefined,
                referenceId: undefined,
              },
            ],
            name: undefined,
            initialTour: false,
          }
        }
      >
        <Flex
          gap={isMobile ? '12px' : '24px'}
          align="center"
          style={{ marginBottom: '12px' }}
        >
          <BackButton
            onClick={() => {
              if (form.isFieldsTouched()) {
                openNotification({
                  colorPrimary,
                  isMobile,
                  onClose: () => history.push('/helpers-u4hero?tab=3'),
                });
              } else {
                history.goBack();
              }
            }}
          />
          <Title primary>{params.id === 'new' ? 'Criar' : 'Editar'} Tour</Title>
        </Flex>
        <Flex vertical gap={'24px'}>
          {params.id !== 'new' && (
            <Flex
              justify="space-between"
              style={{
                width: '100%',
                background: 'rgba(244, 244, 244, 0.80)',
                padding: '16px',
                borderRadius: isMobile ? '20px' : '10px',
              }}
            >
              <Flex gap="24px" align="center">
                <Text>Criado por: {data?.author}</Text>{' '}
                <Text>
                  Em: {moment.utc(data?.createdAt).format('DD/MM/YYYY')}
                </Text>
              </Flex>
              {data?.updatedBy ? (
                <>
                  {' '}
                  <Divider
                    type="vertical"
                    style={{
                      background: 'rgba(38, 38, 38, 0.30)',
                      height: '48px',
                    }}
                  />{' '}
                  <Flex gap="24px" align="center">
                    <Text>Atualizado por: {data?.author}</Text>{' '}
                    <Text>
                      Em: {moment.utc(data?.updatedAt).format('DD/MM/YYYY')}
                    </Text>
                  </Flex>{' '}
                </>
              ) : null}
            </Flex>
          )}

          <Flex gap="16px" justify="end" align="center">
            <Button
              size="md"
              id="id-tour-create-tour-button-preview"
              variant="outline"
              style={{
                display: 'flex',
                gap: isMobile ? '4px' : '16px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => setIsPreviewOpen(true)}
              disabled={steps && !validateStep(steps[0])}
            >
              <PiEyeFill
                size={isMobile ? 16 : 28}
                color={
                  steps && !validateStep(steps[0])
                    ? colorGreyCard
                    : colorPrimary
                }
              />{' '}
              PRÉVIA
            </Button>

            <Button
              size="md"
              id="id-tour-create-tour-save-button"
              htmlType="submit"
              disabled={steps && !validateStep(steps[0])}
              loading={isLoadingSave}
            >
              SALVAR
            </Button>
          </Flex>

          <GenericCard>
            <Flex vertical style={{ width: '100%' }}>
              <Text
                size="lg"
                color={colorPrimary}
                style={{
                  fontWeight: 700,
                  marginBottom: isMobile ? '8px' : '16px',
                }}
              >
                Detalhes do Tour
              </Text>
              <Form.Item
                label="Nome do Tour "
                rules={[
                  {
                    required: true,
                    message: 'Por favor, crie um título para o tour!',
                  },
                ]}
                name="name"
              >
                <Input
                  id="id-tour-create-tour-input-name"
                  placeholder="Crie um título"
                  size={isMobile ? 'middle' : 'large'}
                />
              </Form.Item>
              <Form.Item name="initialTour" valuePropName="checked">
                <Checkbox>
                  Adicionar esse tour na categoria de “Tour Inicial”
                </Checkbox>
              </Form.Item>
              {initialTour ? (
                <>
                  <Form.Item
                    name={'message'}
                    label="Mensagem de Finalização"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, crie uma mensagem de finalização!',
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="Crie uma mensagem parabenizando o usuário por ter concluído esse tour. "
                      id="id-tour-create-tour-input-message"
                      size={isMobile ? 'middle' : 'large'}
                      rows={3}
                    />
                  </Form.Item>
                  <Flex vertical={isMobile} justify="space-around">
                    <Form.Item
                      name="backgroundImage"
                      label="Background da Modal"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor, carregue uma imagem!',
                        },
                      ]}
                    >
                      {/* @ts-ignore */}
                      <UploadImagem
                        placeholderImg="landscape"
                        apiPath="/v1/file/generic-upload?bucketName=u4hero-files&folder=tourImages"
                        buttonText={'CARREGAR IMAGEM'}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Imagem de Parabenização"
                      name="congratsImage"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor, carregue uma imagem!',
                        },
                      ]}
                    >
                      {/* @ts-ignore */}
                      <UploadImagem
                        placeholderImg="landscape"
                        apiPath="/v1/file/generic-upload?bucketName=u4hero-files&folder=tourImages"
                        buttonText={'CARREGAR IMAGEM'}
                      />
                    </Form.Item>
                  </Flex>
                </>
              ) : null}
            </Flex>
          </GenericCard>

          <Form.List name="steps">
            {(fields, { add, remove }) => (
              <Flex vertical gap="16px" style={{ marginBottom: '32px' }}>
                <Button
                  size="md"
                  style={{ alignSelf: 'end' }}
                  onClick={() => {
                    add({
                      title: undefined,
                      description: undefined,
                      image: undefined,
                      url: undefined,
                      redirectUrl: undefined,
                    });

                    setActiveKeys([...activeKeys, String(fields.length)]);
                  }}
                >
                  Adicionar etapa
                </Button>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="steps">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          display: 'flex',
                          gap: '16px',
                          flexDirection: 'column',
                        }}
                      >
                        {fields.map((field, index) => (
                          <Draggable
                            key={field.key}
                            draggableId={field.key.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Flex vertical>
                                  <Collapse
                                    expandIconPosition="right"
                                    activeKey={activeKeys}
                                    onChange={(keys) =>
                                      setActiveKeys(keys as string[])
                                    }
                                  >
                                    <Collapse.Panel
                                      header={
                                        <>
                                          <div style={{ position: 'relative' }}>
                                            <Flex justify="space-between">
                                              <div>
                                                <Text
                                                  size="lg"
                                                  color="white"
                                                  style={{
                                                    padding: '3px 10px',
                                                    background: hex2rgba(
                                                      colorPrimary,
                                                      0.6
                                                    ),
                                                    position: 'absolute',
                                                    borderRadius:
                                                      '10px 10px 10px 0px',
                                                    top: -12,
                                                    left: -18,
                                                  }}
                                                >
                                                  Etapa {index + 1}
                                                </Text>
                                              </div>
                                              <FiMove
                                                size={isMobile ? 20 : 24}
                                              />
                                              {fields.length > 1 ? (
                                                <FiTrash2
                                                  size={isMobile ? 20 : 24}
                                                  style={{
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    showConfirm({
                                                      message: `Tem certeza que deseja excluir a Etapa ${
                                                        index + 1
                                                      }?`,
                                                      colorPrimary,
                                                      isMobile,
                                                    }).then(
                                                      ({ isConfirmed }) => {
                                                        if (isConfirmed) {
                                                          remove(index);
                                                          setActiveKeys(
                                                            activeKeys.filter(
                                                              (key) =>
                                                                key !==
                                                                String(index)
                                                            )
                                                          );
                                                          notificateSucess(
                                                            `Etapa excluida com sucesso!`
                                                          );
                                                        }
                                                      }
                                                    );
                                                  }}
                                                />
                                              ) : (
                                                <div></div>
                                              )}
                                            </Flex>
                                          </div>
                                          <Text size="lg">
                                            {steps?.[index]?.title}
                                          </Text>
                                        </>
                                      }
                                      key={index}
                                    >
                                      <Flex style={{ padding: '8px' }} vertical>
                                        <Form.Item
                                          style={{ marginBottom: '16px' }}
                                          name={[index, 'title']}
                                          label="Título da Etapa"
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                'Por favor, crie um título para essa etapa!',
                                            },
                                          ]}
                                        >
                                          <Input
                                            size={isMobile ? 'middle' : 'large'}
                                            id="id-tour-create-tour-input-title"
                                            placeholder="Crie um título para essa etapa"
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          style={{ marginBottom: '16px' }}
                                          name={[index, 'description']}
                                          label="Descrição"
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                'Por favor, crie uma explicação para essa etapa!',
                                            },
                                          ]}
                                        >
                                          <TextArea
                                            placeholder="Digite a descrição dessa etapa"
                                            id="id-tour-create-tour-input-description"
                                            size={isMobile ? 'middle' : 'large'}
                                            rows={3}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          style={{ marginBottom: '16px' }}
                                          name={[index, 'image']}
                                          label="Imagem"
                                        >
                                          {/* @ts-ignore */}
                                          <CustomUpload placeholder="Carregue uma imagem para essa etapa" />
                                        </Form.Item>

                                        <Form.Item
                                          name={[index, 'referenceId']}
                                          hidden
                                        />

                                        <Row
                                          gutter={[24, 0]}
                                          justify="space-between"
                                        >
                                          <Col flex="auto">
                                            <Form.Item
                                              style={{
                                                marginBottom: '16px',
                                              }}
                                              name={[index, 'url']}
                                              label="Url do Elemento Destacado"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    'Por favor, insira uma URL!',
                                                },
                                              ]}
                                            >
                                              <Flex
                                                gap={isMobile ? '16px' : '24px'}
                                                vertical={isMobile}
                                              >
                                                <Form.Item
                                                  name={[index, 'url']}
                                                  style={{ width: '100%' }}
                                                >
                                                  <Input
                                                    size={
                                                      isMobile
                                                        ? 'middle'
                                                        : 'large'
                                                    }
                                                    id="id-tour-create-tour-input-url"
                                                    disabled
                                                    placeholder={
                                                      isMobile
                                                        ? 'Insira a URL dessa etapa.'
                                                        : 'Insira a URL onde o elemento a ser destacado está localizado'
                                                    }
                                                  />
                                                </Form.Item>
                                                <Button
                                                  size="md"
                                                  variant="outline"
                                                  onClick={() => {
                                                    setCurrentStep(index);
                                                    setIsOpenModal(true);
                                                  }}
                                                >
                                                  SELECIONAR ÁREA DE DESTAQUE
                                                </Button>
                                              </Flex>
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </Flex>
                                    </Collapse.Panel>
                                  </Collapse>
                                </Flex>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Flex>
            )}
          </Form.List>

          {isOpenModal ? (
            <ModalSelectArea
              value={steps?.[currentStep]?.referenceId}
              onChange={(referenceId) => {
                updateForm(['steps', currentStep, 'referenceId'], referenceId);
              }}
              onClose={() => setIsOpenModal(false)}
              updateUrl={(url) =>
                updateForm(['steps', currentStep, 'url'], url)
              }
              url={steps?.[currentStep]?.url}
            />
          ) : null}
        </Flex>
      </Form>
    </Layout.Container>
  );
}

export default CreateTour;
