import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Modal, message } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import Button from 'components/buttons/button';
import CarouselV2 from 'components/V2/carousel';
import { Stage, Layer, Image, Transformer, Rect } from 'react-konva';
import useImage from 'use-image';
import Konva from 'konva';
import { t } from 'core/resources/strings';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import useStoreItems from 'core/features/store-items/hooks/useStoreItems';

interface DraggableElementProps {
  src: string;
  onSelect: () => void;
  isSelected: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
}

const DraggableElement: React.FC<DraggableElementProps> = ({
  src,
  onSelect,
  isSelected,
  x,
  y,
  width,
  height,
}) => {
  const [image] = useImage(src);
  const shapeRef = useRef<Konva.Image>(null);
  const trRef = useRef<Konva.Transformer>(null);

  useEffect(() => {
    if (isSelected && shapeRef.current && trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer()?.batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Image
        image={image}
        onClick={onSelect}
        ref={shapeRef}
        draggable
        x={x}
        y={y}
        width={width}
        height={height}
        onDragEnd={(e: Konva.KonvaEventObject<DragEvent>) => {
          if (shapeRef.current) {
            shapeRef.current.position(e.target.position());
          }
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

interface ScenarioItem {
  id: string;
  image: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

interface ModalScenarioProps {
  onClose: () => void;
}

function downloadURI(uri: string, name: string) {
  var link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function ModalScenario({ onClose }: ModalScenarioProps) {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const [backgroundImageObj] = useImage(backgroundImage || '', 'anonymous');
  const [elements, setElements] = useState<ScenarioItem[]>([]);
  const [selectedId, selectShape] = useState<string | null>(null);
  const stageRef = useRef<Konva.Stage>(null);

  const { data } = useStoreItems({
    page: 1,
    limit: 1000,
    types: ['scenario', 'object'],
  });

  const totalSlidesNews = 10;
  const slidesToShow = 3;

  const addElement = (
    element: Omit<ScenarioItem, 'x' | 'y' | 'width' | 'height'>
  ) => {
    setElements([
      ...elements,
      { ...element, x: 50, y: 50, width: 100, height: 100 },
    ]);
  };

  const changeScenario = (scenario: string) => {
    setBackgroundImage(scenario);
  };

  const saveCanvasAsImage = () => {
    if (stageRef.current) {
      stageRef.current.batchDraw();
      const uri = stageRef.current.toDataURL({
        mimeType: 'image/png',
        width: window.innerWidth,
        height: window.innerHeight,
        quality: 1,
      });

      downloadURI(uri, 'result.png');
      notificateSucess('Cenário salvo com sucesso!');
    } else {
      notificateError('Não foi possível salvar o cenário. Tente novamente.');
    }
  };

  const scenarioItems = data?.data?.filter((item) => item.type === 'scenario');
  const objectsItems = data?.data?.filter((item) => item.type === 'object');

  return (
    <Modal
      title={<SubTitle primary>Definir Cenário</SubTitle>}
      open={true}
      onCancel={onClose}
      footer={
        <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
          <Button
            variant="outline"
            size="md"
            onClick={onClose}
            id="id-tour-my-school-student-modal-button-cancel"
          >
            {t('app.cancelar')}
          </Button>

          <Button
            htmlType="submit"
            size="md"
            onClick={saveCanvasAsImage}
            id="id-tour-my-school-student-modal-button-save"
          >
            {t('app.salvar')}
          </Button>
        </Flex>
      }
      width={isMobile ? '90%' : 980}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Flex vertical>
        <Flex>
          <Flex vertical style={{ width: '55%' }}>
            <Text
              size="md"
              bold
              style={{
                backgroundColor: hex2rgba(colorPrimary, 0.7),
                borderRadius: '20px 20px 0px 0px',
                padding: '8px',
                width: '100%',
              }}
              color="white"
              align="center"
            >
              Cenário Base
            </Text>
            <Stage width={512} height={400} ref={stageRef}>
              <Layer>
                <Rect x={0} y={0} width={80} height={80} fill="red" />
                <Rect x={800 - 80} y={0} width={80} height={80} fill="red" />
                {backgroundImage && (
                  <Image image={backgroundImageObj} width={800} height={400} />
                )}
                {elements.map((elem, i) => (
                  <DraggableElement
                    key={i}
                    src={elem.image}
                    isSelected={elem.id === selectedId}
                    onSelect={() => selectShape(elem.id)}
                    x={elem.x}
                    y={elem.y}
                    width={elem.width}
                    height={elem.height}
                  />
                ))}
              </Layer>
            </Stage>

            <CarouselV2
              totalSlides={scenarioItems?.length || 0}
              slidesToShow={2}
            >
              {scenarioItems?.map((item) => (
                <div
                  key={item.id}
                  onClick={() => changeScenario(item.imageUrl)}
                >
                  <img
                    src={item.imageUrl}
                    style={{
                      borderRadius: '10px',
                      width: '100%',
                      height: '128px',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              ))}
            </CarouselV2>
          </Flex>
          <Flex vertical style={{ width: '45%' }}>
            <Text
              size="md"
              bold
              style={{
                backgroundColor: hex2rgba(colorPrimary, 0.7),
                borderRadius: '20px 20px 0px 0px',
                padding: '8px',
                width: '100%',
              }}
              color="white"
              align="center"
            >
              Adicionar Elementos
            </Text>

            <CarouselV2
              totalSlides={totalSlidesNews}
              rows={2}
              slidesToShow={slidesToShow}
            >
              {objectsItems?.map((item) => (
                <div
                  key={item.id}
                  onClick={() =>
                    addElement({
                      id: item.id,
                      image: item.imageUrl,
                    })
                  }
                >
                  <img
                    src={item.imageUrl}
                    style={{
                      borderRadius: '10px',
                      border: '4px solid #E9E9E9',
                      padding: '8px',
                      minWidth: '100px',
                      marginBottom: '10px',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              ))}
            </CarouselV2>

            <Flex vertical>
              <Text
                size="md"
                bold
                style={{
                  backgroundColor: hex2rgba(colorPrimary, 0.7),
                  borderRadius: '20px 20px 0px 0px',
                  padding: '8px',
                  width: '100%',
                }}
                color="white"
                align="center"
              >
                Gerar Imagem com IA
              </Text>
              <Text size="md" style={{ padding: '8px' }}>
                Para gerar uma imagem com IA, selecione um cenário base e
                adicione elementos complementares.
              </Text>
              <Button size="md" style={{ alignSelf: 'center' }}>
                Gerar Imagem
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default ModalScenario;
