import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex } from 'antd';
import { HoverItem } from 'components/hover-item/hover';
import Star from 'components/star';
import Text from 'components/typography/text';
import { PracticeDetails } from 'core/features/practices/typings';
import { t } from 'core/resources/strings';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useHistory } from 'react-router';

function PracticeCard({ practice }: { practice: PracticeDetails }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();
  const { colorSecondary, colorGreyBorderAndFont, colorPrimary } =
    useU4heroColors();

  return (
    <HoverItem
      borderRadius={'20px'}
      background="white"
      hover={hex2rgba(colorPrimary, 0.3)}
      style={{
        padding: '16px',
        maxWidth: '352px',
        height: '100%',
        boxShadow:
          '0px 3px 4px 0px rgba(0, 0, 0, 0.25), 0px -0.5px 4px 0px rgba(0, 0, 0, 0.25)',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
      }}
      onClick={() => history.push(`/practices/${practice.id}`)}
    >
      <Flex
        vertical
        justify="space-between"
        style={{ width: 'fit-content', position: 'relative' }}
      >
        <div>
          <img
            alt={`banner ${practice.title}`}
            src={practice.banner}
            style={{ borderRadius: '10px', width: '320px' }}
          />
          <div
            style={{
              position: 'absolute',
              top: -30,
              right: -30,
            }}
          >
            <Star size={'60px'} color={colorPrimary} fontSize={'14px'}>
              {t(`app.${practice.analysisEnvironment.name}`)}
            </Star>
          </div>
          <Flex
            style={{
              width: '100%',
              position: 'relative',
              bottom: isMobile ? 18 : 24,
            }}
            justify="center"
          >
            <div
              style={{
                borderRadius: '5px 5px 0px 0px',
                fontSize: isMobile ? '12px' : '16px',
                paddingLeft: isMobile ? '14px' : '16px',
                paddingRight: isMobile ? '14px' : '16px',
                background: colorSecondary,
                color: 'white',
                textTransform: 'uppercase',
              }}
            >
              {practice.target} - {t(`app.${practice.practiceModality.name}`)}
            </div>
          </Flex>
        </div>

        <Text color={colorPrimary} bold align="center">
          {practice.title}
        </Text>
        <Text
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 4,
            lineClamp: 4,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {practice.goal}
        </Text>
      </Flex>
    </HoverItem>
  );
}

export default PracticeCard;
