import { useMediaQuery } from '@chakra-ui/media-query';
import { Modal, Flex } from 'antd';
import DynamicTable from 'components/V2/dinamic-table';
import { useState } from 'react';
import { PiEyeLight } from 'react-icons/pi';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { PiEyeClosedLight } from 'react-icons/pi';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import dayjs from 'dayjs';
import { FilterType } from 'infra/helpers/types';
import Button from 'components/buttons/button';
import fear from 'assets/images/fear.png';
import disgusted from 'assets/images/disgusted.png';
import sad from 'assets/images/sad.png';
import happy from 'assets/images/happy.png';
import prettyGood from 'assets/images/pretty-good.png';
import SubTitle from 'components/typography/subTitle';
import FeedbackFilter from 'components/feedback-filter';
import Text from 'components/typography/text';
import moment from 'moment';
import usePractice from 'core/features/practices/hooks/usePractice';
import usePracticeFeedbacks from 'core/features/practices/hooks/usePracticeFeedbacks';
import { FeedbackDetails } from 'core/features/practices/typings';
import usePracticeFeebackAvg from 'core/features/practices/hooks/usePracticeFeedbackAvg';
import { t } from 'core/resources/strings';

function PracticeFeedbackModal({
  onClose,
  id,
}: {
  onClose: () => void;
  id?: string;
}) {
  const { data } = usePractice(id);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();

  const customExpandIcon = (props: any) => {
    if (props.expanded) {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <PiEyeLight style={{ fontSize: '22px' }} />
        </a>
      );
    } else {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <PiEyeClosedLight style={{ fontSize: '22px' }} />
        </a>
      );
    }
  };

  const ratings = [
    {
      label: t('app.muitoRuim'),
      value: 1,
      image: disgusted,
    },
    {
      label: t('app.ruim'),
      value: 2,
      image: fear,
    },
    {
      label: t('app.regular'),
      value: 3,
      image: sad,
    },
    {
      label: t('app.bom'),
      value: 4,
      image: happy,
    },
    {
      label: t('app.muitoBom'),
      value: 5,
      image: prettyGood,
    },
  ];
  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };

  const [filters, setFilters] = useState<FilterType>({
    initialDate: [],
    ratings: [],
  });

  const { data: feedbackData, isLoading } = usePracticeFeedbacks({
    id: id,
    page: page,
    ratings: filters?.ratings.map((item) => item.value) ?? undefined,
    initialDate: filters?.initialDate.map((item) => item.value)[0] ?? undefined,
  });

  const { data: dataAverage } = usePracticeFeebackAvg(id);
  const columns = [
    {
      title: <p style={tableHeaderStyle}>{t('app.avaliacao')}</p>,
      name: t('app.avaliacao'),
      dataIndex: 'rating',
      key: 'rating',
      mobile: true,
      render: (_: string, record: FeedbackDetails) => (
        <p style={tableRowStyle}>
          {ratings.find((item) => item.value === record.rating)?.label}
        </p>
      ),
      sorter: (a: any, b: any) => a.rating - b.rating,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.data')}</p>,
      name: t('app.data'),
      dataIndex: 'date',
      key: 'date',
      render: (_: string, record: FeedbackDetails) => (
        <p style={tableRowStyle}>
          {dayjs(record.createdAt).format('DD/MM/YYYY')}
        </p>
      ),
      sorter: (a: any, b: any) => a.createdAt - b.createdAt,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.feedback')}</p>,
      name: t('app.feedback'),
      dataIndex: 'feedback',
      key: 'feedback',
      render: (_: string, record: FeedbackDetails) => (
        <p style={tableRowStyle}>{record.feedback}</p>
      ),
    },
  ];

  return (
    <Modal
      footer={[
        <Flex align="center" justify="center">
          <Button size="md" onClick={onClose}>
            {t('app.fechar')}
          </Button>
        </Flex>,
      ]}
      title={<SubTitle primary>{t('app.feedback')}</SubTitle>}
      open={true}
      onCancel={onClose}
      width={isMobile ? '100%' : '712px'}
    >
      <Flex vertical gap={32}>
        <Flex
          style={{
            background: `url(${data?.banner})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '20px',
            backgroundColor: colorPrimary,
            position: 'relative',
            height: '182px',
          }}
          gap={isMobile ? '8px' : '16px'}
          vertical
        >
          <Flex
            style={{
              background: colorPrimary,
              padding: '6px 8px',
              width: 'fit-content',
              alignSelf: 'center',
              borderRadius: '0px 0px 10px 10px',
            }}
            justify="center"
            align="center"
          >
            <Text size="lg" color="white" bold align="center">
              {data?.title}
            </Text>
          </Flex>

          {dataAverage?.average !== null && (
            <Flex
              style={{
                background: colorPrimary,
                padding: '6px 8px',
                width: 'fit-content',
                alignSelf: 'center',
                borderRadius: '10px',
                position: 'absolute',
                bottom: -20,
              }}
              justify="center"
              align="center"
            >
              <Flex gap="4px">
                <Text size="lg" color="white" bold>
                  {t('app.avaliacaoGeral')}:{' '}
                </Text>

                <img
                  src={
                    ratings.find((item) => item.value === dataAverage?.average)
                      ?.image
                  }
                  width="24px"
                />

                <Text size="lg" color="white" bold>
                  {
                    ratings.find((item) => item.value === dataAverage?.average)
                      ?.label
                  }
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>
        <DynamicTable
          id="id-tour-practices-feedback-table"
          itemName={
            feedbackData && feedbackData?.total > 1 ? 'feedbacks' : 'feedback'
          }
          data={feedbackData?.data ?? []}
          page={page}
          onChangePage={setPage}
          isLoading={isLoading}
          allColumns={columns}
          defaultColumns={
            isMobile ? columns.filter((col) => col.mobile) : columns
          }
          hideCog={true}
          pageSize={pageSize}
          onChangePageSize={setPageSize}
          total={feedbackData?.total ?? 0}
          appliedFilters={filters}
          onChangeFilters={setFilters}
          filters={
            <FeedbackFilter filters={filters} onChangeFilters={setFilters} />
          }
          expandable={
            isMobile
              ? {
                  expandedRowRender: (record: FeedbackDetails) => (
                    <Flex vertical>
                      <p style={{ margin: 0, fontWeight: 600 }}>
                        <span style={{ color: colorPrimary, fontWeight: 600 }}>
                          {t('app.data')}:{' '}
                        </span>
                        {moment.utc(record.createdAt).format('DD/MM/YYYY')}
                      </p>
                      <p style={{ margin: 0, fontWeight: 600 }}>
                        <span style={{ color: colorPrimary, fontWeight: 600 }}>
                          {t('app.feedback')}:{' '}
                        </span>
                        {record.feedback}
                      </p>
                    </Flex>
                  ),
                  rowExpandable: (record: FeedbackDetails) => record.feedback !== '',
                  expandIcon: (props: any) => customExpandIcon(props),
                }
              : null
          }
        />
      </Flex>
    </Modal>
  );
}

export default PracticeFeedbackModal;
