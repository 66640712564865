import { useQuery } from 'react-query';
import axios from 'infra/http';

export interface UseGetClassLowestCompetenceParams {
  startDate?: string;
  endDate?: string;
  classId?: string;
}

function useGetClassLowestCompetence(
  params: UseGetClassLowestCompetenceParams
) {
  const fetchData = async (
    params: UseGetClassLowestCompetenceParams
  ): Promise<{ id: string; name: any; average: number }> => {
    const { data } = await axios.get<{
      id: string;
      name: any;
      average: number;
    }>(`/v2/class/lowest-competence`, {
      params,
    });

    return data;
  };

  return useQuery(
    [`/v2/class/lowest-competence`, params],
    () => fetchData(params),
    {
      enabled: !!params.classId,
    }
  );
}

export default useGetClassLowestCompetence;
