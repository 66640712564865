import { Select } from 'antd';
import { useMediaQuery } from '@chakra-ui/react';

interface SelectSubClassificationProps {
  value: string;
  onChange: (v: string) => void;
  disabled: boolean;
  classification:
    | 'Configurações Gerais'
    | 'Primeiros Passos'
    | 'Funcionalidades';
}

function SelectSubClassification({
  value,
  onChange,
  disabled,
  classification,
}: SelectSubClassificationProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const options = {
    'Primeiros Passos': [
      {
        label: 'Conceitos Básicos',
        value: 'Conceitos Básicos',
      },
    ],
    'Configurações Gerais': [
      {
        label: 'Minha Conta',
        value: 'Minha Conta',
      },
      {
        label: 'Perfis e Permissões',
        value: 'Perfis e Permissões',
      },
    ],
    Funcionalidades: [
      {
        label: 'Início',
        value: 'Início',
      },
      {
        label: 'Minha Escola',
        value: 'Minha Escola',
      },
      {
        label: 'Perguntas',
        value: 'Perguntas',
      },
      {
        label: 'Jogos',
        value: 'Jogos',
      },
      {
        label: 'Atividades',
        value: 'Atividades',
      },
      {
        label: 'Relatórios',
        value: 'Relatórios',
      },
      {
        label: 'Indicadores',
        value: 'Indicadores',
      },
      {
        label: 'Meu Cantinho',
        value: 'Meu Cantinho',
      },
    ],
  };

  return (
    <Select
      id="id-tour-create-helper-input-classification"
      placeholder="Selecione a subdivisão"
      disabled={disabled}
      options={options[classification]}
      onChange={onChange}
      value={value}
      size={isMobile ? 'middle' : 'large'}
    />
  );
}

export default SelectSubClassification;
