import { Flex, Image, Spin } from "antd";
import { GenericCard } from "components/V2/generic-card-styled-component/index";
import useStudentInfo from "core/features/inkluzen/hooks/useStudent";
import useU4heroColors from "infra/packages/antd/u4heroTokens";
import { useState } from "react";
import { useParams } from "react-router-dom";
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined';

interface RouteParams {
  id: string;
}

function StudentInformation({
  type,
  showDrawer,
  fixed
}: {
  type?: string;
  showDrawer: () => void;
  fixed: boolean;
}) {
  const { id } = useParams<RouteParams>();
  const student = useStudentInfo(id);
  const { colorPrimary } = useU4heroColors();
  const [buttonWidth, setButtonWidth] = useState(type === 'process' ? '300px' : '234px');
  const [position, setPosition] = useState(fixed && 'fixed');
  const [right, setRight] = useState(fixed && 54);


  return (
    <GenericCard style={{ position: position, right: right, }} width='332px' maxHeigth='876px'>

      <Flex align='center' gap={30} vertical>
        <Image
          preview={false}
          src="/lumi-lendo.png"
          style={{
            maxHeight: 269,
            maxWidth: 280,
          }}
        />
        {student.data ?
          <Flex align="base-line" style={{ width: '90%', marginBottom: '10px' }} gap={24} vertical>
            <p style={{ color: "black", fontSize: '18px' }}><span style={{ color: colorPrimary, fontWeight: 700 }}>Nome: </span>{student.data?.student}</p>
            <p style={{ color: "black", fontSize: '18px' }}><span style={{ color: colorPrimary, fontWeight: 700 }}>RA: </span>{student.data?.RA}</p>
            <p style={{ color: "black", fontSize: '18px' }}><span style={{ color: colorPrimary, fontWeight: 700 }}>Série/Turma: </span>{student.data?.grade}</p>
            <p style={{ color: "black", fontSize: '18px' }}><span style={{ color: colorPrimary, fontWeight: 700 }}>Escola: </span>{student.data?.school}</p>
            <p style={{ color: "black", fontSize: '18px' }}><span style={{ color: colorPrimary, fontWeight: 700 }}>Município-UF: </span>{student.data?.city}</p>
            <p style={{ color: "black", fontSize: '18px' }}><span style={{ color: colorPrimary, fontWeight: 700 }}>Bairro: </span>{student.data?.district}</p>
          </Flex>
          : <Spin />
        }
        <Flex vertical gap={'20px'}>
          <button onClick={showDrawer} style={{
            backgroundColor: colorPrimary,
            width: buttonWidth,
            height: '59px',
            fontSize: '18px',
            fontWeight: 600,
            color: 'white',
            borderRadius: '10px',
          }} type="button">{type === 'process' ? 'ANOTAÇÃO PROCESSAMENTO' : 'ANOTAÇÃO DO ESTUDANTE'}</button>
          {
            type === 'process'
            && <button style={{
              border: `3px solid ${colorPrimary}`,
              width: buttonWidth,
              height: '59px',
              fontSize: '18px',
              fontWeight: 600,
              color: colorPrimary,
              borderRadius: '10px',
            }}><DownloadOutlined style={{ fontSize: '22px' }} /><> </> Baixar Relatório</button>}
        </Flex>
      </Flex>
    </GenericCard>
  )
}

export default StudentInformation;