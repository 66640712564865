import { useQuery } from "react-query";
import axios from "infra/http";
import { IManifestation } from "../types";

function useManifestations() {
  return useQuery('/v2/inkluzen/manifestations', async ():Promise<IManifestation[]> => {
    const { data } = await axios.get<IManifestation[]>('/v2/inkluzen/manifestations');
    
    return data;
  });

}

export default useManifestations;