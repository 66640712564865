import { Flex, Select } from 'antd';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import Title from 'components/typography/title';
import { useDistorcaoSerie } from 'core/features/qedu/hooks/useDistorcaoSerie';
import { useCurrentUser } from 'core/features/user/store';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';

export const series = [
  {
    id: 1,
    serie: '1º Ano (EF)',
    value: 'ef_1ano',
  },
  {
    id: 2,
    serie: '2º Ano (EF)',
    value: 'ef_2ano',
  },
  {
    id: 3,
    serie: '3º Ano (EF)',
    value: 'ef_3ano',
  },
  {
    id: 4,
    serie: '4º Ano (EF)',
    value: 'ef_4ano',
  },
  {
    id: 5,
    serie: '5º Ano (EF)',
    value: 'ef_5ano',
  },
  {
    id: 6,
    serie: '6º Ano (EF)',
    value: 'ef_6ano',
  },
  {
    id: 7,
    serie: '7º Ano (EF)',
    value: 'ef_7ano',
  },
  {
    id: 8,
    serie: '8º Ano (EF)',
    value: 'ef_8ano',
  },
  {
    id: 9,
    serie: '9º Ano (EF)',
    value: 'ef_9ano',
  },
  {
    id: 10,
    serie: '1º Ano (E.M)',
    value: 'em_1ano',
  },
  {
    id: 11,
    serie: '2º Ano (E.M)',
    value: 'em_2ano',
  },
  {
    id: 12,
    serie: '3º Ano (E.M)',
    value: 'em_3ano',
  },
  {
    id: 13,
    serie: '4º Ano (E.M)',
    value: 'em_4ano',
  },
  {
    id: 14,
    serie: 'Anos Iniciais - Total',
    value: 'ef_total_ai',
  },
  {
    id: 15,
    serie: 'Anos Finais - Total',
    value: 'ef_total_af',
  },
  {
    id: 16,
    serie: 'Ensino Médio - Total',
    value: 'em_total',
  },
];

function AgeGradeDistortion() {
  const { user } = useCurrentUser();
  const { data } = useDistorcaoSerie({ inep_id: user?.inep_id });

  const { colorGreyBorderAndFont } = useU4heroColors();
  const [selected, setSelected] = useState<string>('');
  const [filteredSeries, setFilteredSeries] = useState<
    { id: number; value: string; serie: string }[]
  >([]);

  useEffect(() => {
    if (data) {
      const filtered = series.filter(
        (item) => (data as any)?.[item.value] !== null
      );
      setFilteredSeries(filtered);
      if (filtered.length) {
        setSelected(filtered[0].value);
      }
    }
  }, [data]);

  return (
    <Flex
      vertical
      style={{ padding: '16px', height: '100%', position: 'relative' }}
      className="box-shadow-generic-card"
    >
      <Text
        style={{ position: 'absolute', top: 8, right: 16 }}
        size="sm"
        align="end"
        color={colorGreyBorderAndFont}
      >
        {data?.ano}
      </Text>
      <SubTitle primary style={{ lineHeight: 'normal' }}>
        Distorção de Idade-Série
      </SubTitle>
      <Text size="md" style={{ lineHeight: 'normal' }}>
        Porcentagem de estudantes que estão com atraso escolar.
      </Text>
      <Flex justify="flex-end">
        <Select
          size={'middle'}
          options={filteredSeries.map((item) => ({
            value: item.value,
            label: item.serie,
          }))}
          value={selected}
          onChange={(e) => setSelected(e)}
        />
      </Flex>
      <Title style={{ fontSize: '80px' }} primary align="center">
        {parseFloat((data as any)?.[selected]).toFixed(1)} %
      </Title>
    </Flex>
  );
}

export default AgeGradeDistortion;
