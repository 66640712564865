import { useQuery } from "react-query";
import axios from "infra/http";
import { ActivityForm } from "../types";

function useActivity(id?: string) {
  const fetchActivity = async (id: string): Promise<ActivityForm> => {
    const { data } = await axios.get<ActivityForm>(`/v2/activities/${id}`);

    return data;
  };

  return useQuery([`/v2/activities/${id}`, id], () => fetchActivity(id!), {
    enabled: !!id,
  });
}

export default useActivity;
