function ClassRoomIcon({ size = 50 }: { size?: number }) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 96 96"
      width={size}
      height={size}
    >
      <path d="M21.08,60.77a6.12,6.12,0,1,1,6.11-6.11A6.12,6.12,0,0,1,21.08,60.77Zm0-10.23a4.12,4.12,0,1,0,4.11,4.12A4.13,4.13,0,0,0,21.08,50.54Z" />
      <path d="M32.2,69.54H10a1,1,0,0,1-1-1,9.78,9.78,0,0,1,9.77-9.77h4.7a9.78,9.78,0,0,1,9.77,9.77A1,1,0,0,1,32.2,69.54ZM11,67.54H31.14a7.79,7.79,0,0,0-7.71-6.77h-4.7A7.79,7.79,0,0,0,11,67.54Z" />
      <path d="M48,60.77a6.12,6.12,0,1,1,6.12-6.11A6.12,6.12,0,0,1,48,60.77Zm0-10.23a4.12,4.12,0,1,0,4.12,4.12A4.12,4.12,0,0,0,48,50.54Z" />
      <path d="M59.07,69.54H36.83a1,1,0,0,1-1-1,9.78,9.78,0,0,1,9.77-9.77h4.71a9.78,9.78,0,0,1,9.76,9.77A1,1,0,0,1,59.07,69.54Zm-21.17-2H58a7.78,7.78,0,0,0-7.7-6.77H45.6A7.78,7.78,0,0,0,37.9,67.54Z" />
      <path d="M74.83,60.77a6.12,6.12,0,1,1,6.11-6.11A6.12,6.12,0,0,1,74.83,60.77Zm0-10.23a4.12,4.12,0,1,0,4.11,4.12A4.13,4.13,0,0,0,74.83,50.54Z" />
      <path d="M86,69.54H63.71a1,1,0,0,1-1-1,9.78,9.78,0,0,1,9.77-9.77h4.7A9.78,9.78,0,0,1,87,68.54,1,1,0,0,1,86,69.54Zm-21.18-2H84.88a7.77,7.77,0,0,0-7.7-6.77h-4.7A7.79,7.79,0,0,0,64.77,67.54Z" />
      <path d="M91,69.54H5a1,1,0,0,1,0-2H91a1,1,0,0,1,0,2Z" />
      <path d="M21.08,85.23a6.12,6.12,0,1,1,6.11-6.11A6.12,6.12,0,0,1,21.08,85.23Zm0-10.23a4.12,4.12,0,1,0,4.11,4.12A4.13,4.13,0,0,0,21.08,75Z" />
      <path d="M32.2,94H10a1,1,0,0,1-1-1,9.78,9.78,0,0,1,9.77-9.77h4.7A9.78,9.78,0,0,1,33.2,93,1,1,0,0,1,32.2,94ZM11,92H31.14a7.79,7.79,0,0,0-7.71-6.77h-4.7A7.79,7.79,0,0,0,11,92Z" />
      <path d="M48,85.23a6.12,6.12,0,1,1,6.12-6.11A6.12,6.12,0,0,1,48,85.23ZM48,75a4.12,4.12,0,1,0,4.12,4.12A4.12,4.12,0,0,0,48,75Z" />
      <path d="M59.07,94H36.83a1,1,0,0,1-1-1,9.78,9.78,0,0,1,9.77-9.77h4.71A9.78,9.78,0,0,1,60.07,93,1,1,0,0,1,59.07,94ZM37.9,92H58a7.78,7.78,0,0,0-7.7-6.77H45.6A7.78,7.78,0,0,0,37.9,92Z" />
      <path d="M74.83,85.23a6.12,6.12,0,1,1,6.11-6.11A6.12,6.12,0,0,1,74.83,85.23Zm0-10.23a4.12,4.12,0,1,0,4.11,4.12A4.13,4.13,0,0,0,74.83,75Z" />
      <path d="M86,94H63.71a1,1,0,0,1-1-1,9.78,9.78,0,0,1,9.77-9.77h4.7A9.78,9.78,0,0,1,87,93,1,1,0,0,1,86,94ZM64.77,92H84.88a7.77,7.77,0,0,0-7.7-6.77h-4.7A7.79,7.79,0,0,0,64.77,92Z" />
      <path d="M91,94H5a1,1,0,0,1,0-2H91a1,1,0,0,1,0,2Z" />
      <path d="M78.69,43.46H17.31a1,1,0,0,1-1-1V3a1,1,0,0,1,1-1H78.69a1,1,0,0,1,1,1V42.46A1,1,0,0,1,78.69,43.46Zm-60.38-2H77.69V4H18.31Z" />
      <path d="M68.68,16.42H49.35a1,1,0,1,1,0-2H68.68a1,1,0,0,1,0,2Z" />
      <path d="M68.68,23.73H49.35a1,1,0,0,1,0-2H68.68a1,1,0,0,1,0,2Z" />
      <path d="M68.68,31H49.35a1,1,0,0,1,0-2H68.68a1,1,0,0,1,0,2Z" />
      <path d="M33.39,33A10.27,10.27,0,1,1,43.65,22.73,10.28,10.28,0,0,1,33.39,33Zm0-18.53a8.27,8.27,0,1,0,8.26,8.27A8.28,8.28,0,0,0,33.39,14.46Z" />
      <path d="M42.65,23.73H33.39a1,1,0,0,1-1-1V13.46a1,1,0,0,1,2,0v8.27h8.26a1,1,0,0,1,0,2Z" />
      <path d="M26.85,30.27a1,1,0,0,1-.71-1.71L32.68,22a1,1,0,1,1,1.41,1.41L27.56,30A1,1,0,0,1,26.85,30.27Z" />
    </svg>
  );
}

export default ClassRoomIcon;
