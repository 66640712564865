import GenericFilterComponent from 'components/generic-filter';
import useTenants from 'core/features/tenants/hooks/useTenants';
import { useCurrentUser } from 'core/features/user/store';
import { FilterProps } from 'infra/helpers/types';
import { Permissions } from 'core/resources/enums/permissions';
import { NumberParam, useQueryParam } from 'use-query-params';
import useTenantsLocations from 'core/features/tenants/hooks/useTenantsLocations';

function InstituitionsFilter({ filters, onChangeFilters }: FilterProps) {
  const { user } = useCurrentUser();
  const [step, setStep] = useQueryParam('tab', NumberParam);
  const isU4Hero = !!user?.resources.includes(Permissions.U4hero.Tenant.View);
  const { isLoading, isFetching, data } = useTenants({
    page: 1,
    master: true,
    parentId: isU4Hero ? undefined : user?.tenantId,
    limit: 200,
  });
  const { data: states } = useTenantsLocations({
    master: step === 1,
    parentId: isU4Hero ? undefined : user?.tenantId,
  });

  return (
    <GenericFilterComponent
      onChangeFilters={onChangeFilters}
      filters={filters}
      filterGroups={[
        {
          title: 'Localização',
          options:
            states?.map((item) => {
              return { label: item.state, value: item.state };
            }) ?? [],
          filterName: 'locations',
        },
        {
          title: 'Organização',
          options:
            data?.data.map((item) => {
              return { label: item.name, value: item.id };
            }) ?? [],
          filterName: 'tenants',
        },
        {
          title: 'Status',
          options: [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false },
          ],
          filterName: 'status',
          type: 'radio',
        },
        {
          title: 'Data de Criação ',
          filterName: 'createdAt',
          options: [],
          type: 'date',
          open: true,
        },
      ]}
    />
  );
}

export default InstituitionsFilter;
