import { translate } from '../polyglot';

export default translate('pt-br', {
  app: {
    idadeMinima: 'Idade Mínima',
    idadeMaxima: 'Idade Máxima',
    genero: 'Gênero',
    tempoMedio: 'Tempo médio',
    objetivoLudico: 'Objetivo Lúdico',
    objetivoPedagogico: 'Objetivo Pedagogico',
    competencias: 'Competências',
    questoes: 'Questões',
    conteudos: 'Conteúdos',
    conteudo: 'Conteúdo',
    remover: 'Remover',
    emocionometro: 'Emocionômetro',
    indicadores_gerais: 'Indicadores Gerais',
    indicadores_avancados: 'Indicadores avançados',
    rankJogadores: 'Rank de Jogadores',
    detalhes: 'Detalhes',
    modelo: 'Modelo',
    cancelar: 'Cancelar',
    importar: 'Importar',
    importacao: 'Importação',
    ver_mais: 'Ver mais',
    historico: 'Histórico',
    recomendacao: 'Recomendação',
    adicionar: 'Adicionar',
    maximo: 'Máximo',
    minimo: 'Mínimo',
    escola: 'Escola',
    escolas: 'Escolas',
    estudantes: 'estudantes',
    estudante: 'estudante',
    serie: 'Série',
    series: 'Séries',
    turma: 'Turma',
    turmas: 'Turmas',
    imagem: 'Imagem',
    filtro: 'Filtro',
    filtrar: 'Filtrar',
    imprimir: 'Imprimir',
    enviarEmail: 'Enviar email',
    comentarios: 'Comentários',
    comentar: 'Comentar',
    colaboradores: 'Colaboradores',
    colaborador: 'Colaborador',
    graficos: 'Gráficos',
    grafico_geral: 'Gráficos geral',
    sair: 'Sair',
    configuracoes: 'Configurações',
    nome: 'Nome',
    opcoes: 'Opções',
    editar: 'Editar',
    desativar: 'Desativar',
    ativar: 'Ativar',
    ativo: 'Ativo',
    inativo: 'Inativo',
    aviso: 'Aviso',
    sucesso: 'Sucesso',
    erro: 'Erro',
    portugues: 'Português',
    ingles: 'Inglês',
    espanhol: 'Espanhol',
    criar: 'Criar',
    entrar: 'Entrar',
    facaBusca: 'Faça uma busca',
    voltar: 'Voltar',
    salvar: 'Salvar',
    documento: 'Documento',
    telefone: 'Telefone',
    site: 'Site',
    emailAdmin: 'Email do diretor',
    nomeAdmin: 'Nome do diretor',
    pais: 'País',
    endereco: 'Endereço',
    adicionarTurma: 'Adicionar turma',
    cidade: 'Cidade',
    bairro: 'Bairro',
    estado: 'Estado',
    rua: 'Rua',
    complemento: 'Complemento',
    numero: 'Número',
    cep: 'CEP',
    matutino: 'Matutino',
    vespertino: 'Vespertino',
    noturno: 'Noturno',
    periodo: 'Período',
    excluir: 'Excluir',
    perfil: 'Perfil',
    usuario: 'Usuário',
    senha: 'Senha',
    acessoTurmas: 'Acesso às turmas',
    idade: 'Idade',
    anos: 'Anos',
    dataNascimento: 'Data de nascimento',
    nomePai: 'Nome do pai',
    nomeMae: 'Nome da mãe',
    limpar: 'Limpar',
    aplicar: 'Aplicar',
    relatorio: 'Relatório',
    relatorios: 'Relatórios',
    relatorioCompleto: 'Relatório completo',
    sexo: 'Sexo',
    feminino: 'Feminino',
    masculino: 'Masculino',
    esqueciSenha: 'Esqueci minha senha',
    voltarParaLogin: 'Voltar para o login',
    recuperarSenha: 'Recuperar senha',
    jogos: 'Jogos',
    jogo: 'Jogo',
    aventura: 'Aventura',
    exploracao: 'Exploração',
    miniquest: 'Miniquest',
    rpg: 'RPG',
    capitulo: 'Capítulo',
    capitulos: 'Capítulos',
    tipo: 'Tipo',
    perguntas: 'Perguntas',
    licenca: 'Licença',
    descricao: 'Descrição',
    hipotese: 'Hipótese',
    orientacao: 'Orientação',
    resposta: 'Resposta',
    pergunta: 'Pergunta',
    tag: 'Tag',
    tag_pai: 'Tag pai',
    jogoEmocoes: 'Jogo das emoções',
    graficoEmocoes: 'Gráfico das emoções',

    autoGestao: 'Autogestão',
    autoconsciencia: 'Autoconsciência',
    habilidadesRelacionamento: 'Habilidades de relacionamento',
    conscienciaSocial: 'Consciência Social',
    tomadaDecisaoResponsavel: 'Tomada de Decisão Responsável',
    graficoSocioemocional: 'Gráfico Socioemocional',
    socioemocional: 'Socioemocional',

    nivemCompreensao: 'Nível de compreensão',
    otimaCompreensao: 'Ótima compreensão',
    boaCompreensao: 'Boa compreensão',
    razoavelCompreensao: 'Razóavel compreensão',
    poucaCompreensao: 'Pouca compreensão',
    graficoBullying: 'Gráfico bullying',

    memoriaTrabalho: 'Memória de trabalho',
    flexibilidadeCognitiva: 'Flexibilidade cognitiva',
    controleInibitorio: 'Controle Inibitório',
    legenda: 'Legenda',
    baixoControleInibitorio: 'Baixo controle inibitório',
    medioControleInibitorio: 'Médio controle inibitório',
    altoControleInibitorio: 'Alto controle inibitório',

    entendimentoTurma: 'Entendimento da turma',
    entendimentoSerie: 'Entendimento da série',
    entendimentoestudante: 'Entendimento do estudante',

    graficoFuncoesExecutivas: 'Gráfico de Funções Executivas',
    mediaCliques10Segundos: 'Média de cliques a cada 10 segundos',

    acimaDe20: 'Acima de 20',
    de15a20: 'de 15 a 20',
    ate10: 'Até 10',

    graficoHumor: 'Gráfico de humor',
    nivelCompreensao: 'Nivel de compreensão',

    'Estou feliz': 'Feliz',
    'Estou com medo': 'Medo',
    'Estou triste': 'Triste',
    'Estou com raiva': 'Raiva',
    'Estou com nojo': 'Nojo',

    'Estou feliz.': 'Feliz',
    'Estou com medo.': 'Medo',
    'Estou triste.': 'Triste',
    'Estou com raiva.': 'Raiva',
    'Estou com nojo.': 'Nojo',

    configuracao: 'Configuração',
    editar_perfil: 'Editar perfil',
    inicial: 'Inicial',
    encerramento: 'Encerramento',
    investigacao: 'Investigação',

    Admin: 'Administrador(a)',
    Teacher: 'Educador(a)',
    u4hero: 'U4hero',

    morning: 'Matutino',
    evening: 'Vespertino',
    afternoon: 'Vespertino',
    fulltime: 'Integral',
    nocturnal: 'Noturno',
    organizacao: 'Organização',
    Organization: 'Organização',
    schoolEvasion: 'Evasão Escolar',
    reprovation: 'Reprovação',
    absenteeism: 'Absenteísmo',
    conflicts: 'Conflitos e Violência Escolar',
    performance: 'Desempenho Acadêmico',
    participation: 'Participação em Atividades Contraturno',
    familySupport: 'Apoio Familiar',
    socioeconomicLevel: 'Nível Socioeconômico',
    acessibility: 'Acessibilidade e Inclusão',
    mentalHealth: 'Saúde Mental',
    accessToTech: 'Acesso à Tecnologia',
    schoolEvasionInfo:
      'Este indicador avalia a probabilidade de um estudante abandonar os estudos com base em competências e habilidades socioemocionais essenciais. As competências e os temas analisados neste indicador são: Consciência Social, Relacionamento, Segurança, Autoconsciência, Autogestão, Tomada de Decisão Responsável e Anti-Bullying.',
    reprovationInfo:
      'Avalia o potencial risco de um estudante ser retido no mesmo ano ou nível de ensino devido à falta de competências socioemocionais críticas. As competências e os temas analisados incluem Consciência Social, Relacionamento, Segurança, Autoconsciência, Autogestão, Tomada de Decisão Responsável e Anti-Bullying. Esse indicador reflete a probabilidade de um estudante enfrentar dificuldades acadêmicas e emocionais que podem levá-lo à reprovação.',
    performanceInfo:
      'Indicador que está associado ao rendimento de um estudante em um ambiente educacional, com base em sua capacidade de atender aos padrões e expectativas estabelecidos para o aprendizado e o sucesso escolar. As competências e os temas analisados incluem Consciência Social, Relacionamento, Segurança, Autoconsciência, Autogestão, Tomada de Decisão Responsável e a capacidade de combater o bullying.',
    absenteeismInfo:
      'Mede o risco de ausências frequentes não justificadas. As competências e os temas analisados neste indicador são:  Autogestão, Autoconsciência, Tomada de Decisão Responsável, Segurança e Anti-Bullying.',
    conflictsInfo:
      'Avalia o risco de conflitos e violência no ambiente escolar baseado nas reflexões e descobertas dos próprios estudantes e educadores. Reflete a probabilidade de incidentes prejudiciais ao bem-estar e à segurança de estudantes e educadores. Os temas analisados neste indicador são: Segurança e Anti-Bullying.',
    participationInfo:
      'Indicador que avalia o envolvimento dos estudantes em atividades fora do horário escolar. Essas atividades, que incluem clubes escolares e grupos de interesse, promovem o aprendizado além da sala de aula e o desenvolvimento de habilidades socioemocionais. O indicador é composto por competências como Autoconsciência, Relacionamento, Autogestão,Tomada de Decisão Responsável e Consciência Social e o tema Segurança.',
    familySupportInfo:
      'Apoio Familiar é um indicador que avalia o envolvimento da família no processo educacional do estudante. O apoio da família é essencial para o desenvolvimento socioemocional do estudante e para a criação de um ambiente seguro e protegido. As competências avaliadas neste indicador incluem Relacionamento, Consciência Social e o tema Segurança.',
    socioeconomicLevelInfo:
      'Nível Socioeconômico é um indicador que avalia o nível socioeconômico dos estudantes no contexto escolar, familiar e social. Este indicador é construído com base no nível de escolaridade e renda familiar dos estudantes. A competência avaliada neste indicador é a Consciência Social e o tema Segurança.',
    acessibilityInfo:
      'Inclusão e Acessibilidade é um indicador que avalia a inclusão e o atendimento de estudantes com  demandas de acessibilidade em um ambiente escolar. Este indicador é composto por competências como Consciência Social e Relacionamento e o tema Segurança.',
    mentalHealthInfo:
      'Saúde Mental é um indicador que avalia o bem-estar emocional e psicológico dos estudantes e educadores no ambiente escolar. As competências e os temas analisados incluem Autogestão, Autoconsciência, Tomada de Decisão Responsável, Segurança e Anti-Bullying.',
    accessToTechInfo:
      'O indicador de “Acesso à Tecnologia” reflete a disponibilidade e uso de ferramentas tecnológicas pelos estudantes. Um baixo indicador pode sinalizar dificuldades no acesso à tecnologia, impactando o processo de ensino-aprendizagem. As competências analisadas incluem Tomada de Decisão Responsável, Relacionamento e Autogestão.',
    Games: 'Jogos',
    students: 'Estudantes',
    users: 'Usuários',
    customQuestion: 'Perguntas customizadas',
    tenant: 'Instituição',
    report: 'Relatório',
    collaborators: 'Colaboradores',
    collaborator: 'Colaboradores',
    class: 'Turmas',
    profile: 'Perfil',
    dashboard: 'Página inicial',
    activity: 'Atividade',
    indicators: 'Indicadores',
    settings: 'Configurações',
    create: 'Criar',
    edit: 'Editar Próprias',
    view: 'Visualizar Próprias',
    delete: 'Deletar',
    activate: 'Ativar/Desativar',
    viewall: 'Visualizar Todas',
    editall: 'Editar Todas',
    changePassword: 'Alterar Senha',
    adapt: 'Adaptar',
    import: 'Importar',
    practices: 'Inspirações pedagógicas',
    Schools: 'Escolas',
    school: 'Escola',
    metrics: 'Métricas',
    games: 'Jogos',
    contacts: 'Contatos',
    competence: 'Competências',
    helpers: 'Ajudas',
    questions: 'Perguntas',
    locations: 'Traduções',
    home: 'Página inicial',
    org: 'Organização',
    safeSpace: 'Meu cantinho',
    selfAvaliation: 'Avaliação Inicial',
    avaliation: 'Avaliação',
    studentDetail: 'Detalhes do estudante',
    symptoms: 'Sintomas',
    macro: 'Visão Macro',
    student: 'Visão Estudante',
    geographic: 'Visão Geográfica',
    viewName: 'Ver nome',
    Comprehension: 'Conhecimento',
    'Spontaneous Decision': 'Comportamento',
    'Third Party': 'Percepção',
    serious: 'Grave',
    moderate: 'Moderado',
    light: 'Leve',
    no_manifest: 'Não manifesto',
    selfDeprecation: 'Auto-depreciação',
    somaticConcerns: 'Preocupações somáticas',
    changeInSelfImage: 'Mudança na auto-imagem',
    pessimism: 'Pessimismo',
    suicidalThoughtsOrDesires: 'Pensamentos ou desejos suicidas',
    lossOfPleasure: 'Perda de prazer',
    guilt: 'Sentimento de culpa',
    sadness: 'Tristeza	473',
    difficultyWorking: 'Dificuldade de trabalhar',
    difficultyConcentrating: 'Dificuldade de concentração',
    irritability: 'Irritabilidade',
    pastFailures: 'Fracassos passados',
    feelingOfUselessness: 'Sentimento de inutilidade',
    agitation: 'Agitação',
    indecision: 'Indecisão',
    weightLoss: 'Perda de peso',
    tirednessOrFatigue: 'Cansaço ou fadiga',
    lossOfSexualInterest: 'Perda de interesse sexual',
    changeInSleepPattern: 'Alteração no padrão de sono',
    cry: 'Choro',
    selfCriticism: 'Auto-criticismo',
    changeInAppetite: 'Alteração no apetite',
    feelingOfPunishment: 'Sentimento de punição',
    lossOfInterest: 'Perda de interesse',
    lossOfenergy: 'Perda de energia',
    'In person': 'Presencial',
    Remote: 'Remoto',
    Hybrid: 'Híbrido',
    Multiple: 'Múltiplos Contextos',
    Familiar: 'Familiar',
    School: 'Escolar',
    Digital: 'Digital',
    Social: 'Social',
    posts: 'Publicações',
    emotion: 'Emocionômetro',
    icon: 'Ícone',
    background: 'Fundo',
    frame: 'Moldura',
    activities: 'Atividades',
    b2c: 'B2C',
    groups: 'Grupos',
    ola: 'Olá',
    estudantesAtivos: 'Estudantes ativos essa semana',
    confiraPainelEstudante: 'Confira o Painel do Estudante',
    conhecaHistoria: 'Conheça a história de',
    leiaAgora: 'Leia agora',
    conferir: 'Conferir',
    fiquePorDentro: 'Fique por dentro das novidades da U4Hero',
    saibaMais: 'Saiba mais',
    rotoriLore:
      'Em uma floresta mágica chamada Floresta Cintila, havia uma criatura extraordinária conhecida como Rotori. Ele parecia um urso, mas suas características especiais o tornavam único em todo o vale. Tinha uma pelagem macia, castanho claro e cheia de pequenas manchinhas brancas, seus olhos eram como duas pérolas cintilantes, porém não possuía nenhuma boca.\
      O que tornava Rotori verdadeiramente especial era a sua habilidade de controlar o clima, trazendo sol nos dias nublados, fazendo nevar em pleno verão ou provocando uma brisa suave sempre que quisesse. Vive na Floresta Mágica, onde todas as criaturas habitam em harmonia. Conhecido por seu coração bondoso e sua amizade com todos.\
     Em Senáquia, existiam inúmeros seres mágicos, cada um com sua própria personalidade e aparência. No entanto, havia uma criatura malvada chamada Tagon, que sempre tentava perturbar o equilíbrio da Floresta Mágica, Tagon estava constantemente tramando planos para criar confusão e caos, afetando a paz da floresta.\
     Um dia, quando uma tempestade terrível ameaçou destruir todo o Reino de Senáquia, as criaturas da floresta se voltaram para Rotori em busca de ajuda. Com determinação ele enfrentou a tempestade e usou seus poderes para acalmar os ventos furiosos e as águas revoltas. Ele impediu que o Tagon causasse mais danos ao equilíbrio do reino.\
     Depois de sua vitória sobre Tagon, Rotori foi aclamado como o guardião da Floresta de Cintila, e continuou trazendo alegria e equilíbrio para o reino, garantindo que todas as estações do ano fossem mágicas e felizes.\
      A lenda de Rotori, inspirou as crianças a cuidarem da natureza e acreditarem na magia que pode ser encontrada em todos os lugares, se você souber onde procurar.',
    naeBoLore:
      'Em Senáquia, há diversas criaturas mágicas de todas as formas, até mesmo parecidas com vegetais, como Na e seu irmão Bo. Eles são uma espécie de nabo, porém nascidos como gêmeos siameses que vivem grudados um ao outro.\
      Eles viviam em um terreno aconchegante no Vale das Raízes, onde as casas eram subterrâneas e os moradores eram vegetais e legumes. Sempre que alguém precisava de uma boa risada ou de um amigo para brincar, lá estava Na e Bo com suas brincadeiras e sua capacidade de se esconder entre as vegetação para alegrar.',
    coepiLore:
      'Uma criatura muito especial e travessa chamada Coepi, com a aparência de uma coruja, mas com orelhas peludas de coelho que espreitavam por entre suas penas macias e brancas. Era o que o tornava único dentre os outros seres animalescos.\
      Adorava pregar peças e fazer travessuras pelo Vale das Raízes. Era conhecido por sua risada contagiante e seu espírito brincalhão. Coepi vivia na Floresta Cintila, um lugar repleto de árvores altas, riachos borbulhantes e flores coloridas.\
      Os moradores de Senáquia viviam preocupados em qual seria a próxima armadilha de Coepi. Pois com a sua capacidade de andar silenciosamente como um coelho, ele sempre passava despercebido pelo guardião da floresta e surpreendia a todos com seus truques.',
    ibaLore:
      'Onde todas as criaturas eram inspiradas na natureza, havia um muito especial chamado Ibá. Este ser mágico se assemelhava a um tronco de árvore, mas tinha uma expressão amigável em seu rosto de madeira. Era conhecido por sua sabedoria e sua capacidade de se conectar com a natureza de uma maneira única.\
      Ibá vivia no coração da Grande Floresta, um lugar onde as árvores eram altas e majestosas, e os riachos cantavam entre as pedras. Ele era o filho da floresta e era amado por todas as criaturas que habitavam ali. Ibá costumava sentar-se no pé da  antiga árvore, onde ouvia muitas histórias sábias que ela lhe contava.\
       Seus poderes eram essenciais para o crescimento das flores e das árvores, ele mantinha tudo correndo em seu ritmo e trazia harmonia para a Floresta Cintila.',
    tagonLore:
      'Onde todas as criaturas eram conhecidas por sua felicidade e bondade, havia uma criatura que se destacava por sua atitude mal-humorada. Seu nome era Tagon, e ele se assemelhava a um emaranhado de pelos escuros, suas sobrancelhas com uma expressão carrancuda e sua voz era resmungona.\
      Tagon vivia nas profundezas do Pântano de Grumblor, um lugar onde as árvores não tinham folhas, e o silêncio predominava. Ele preferia a solidão e raramente interagia com outras criaturas da floresta. Sua casinha estava escondida entre as sombras das árvores, e Tagon passava seus dias cochilando e resmungando sobre o barulho constante do mundo exterior.\
      No entanto, ele nutria muita inveja pela harmonia e felicidade da Floresta Cintila onde moravam a maioria das criaturas, lugar que era sempre alegre e cheio de vida. Muitas vezes ele era visto pisoteando flores e destruindo a floresta.',
    terryLore:
      'Tery, nascido no Vale das Raízes, tinha a forma de uma berinjela, mas possuía patas e uma folha no topo de sua cabeça, além de olhos pequenos brilhantes. Sua personalidade se assemelhava a de um cãozinho filhote.\
      Era conhecido por sempre estar correndo no Campo Ensolarado. Ele era um companheiro leal e sempre estava disposto a brincar e explorar as vastas paisagens do reino. Porém havia um lugar que ele nunca se arriscava em ir, o Pântano de Grumblor, aquele lugar lhe dava arrepios e além disso lá vivia o problemático Tagon, que odiava ser incomodado.',
    previaPontosAtenção: 'Prévia Pontos de Atenção',
    areasMelhoria: 'Áreas de Melhoria',
    pontosFortes: 'Pontos Fortes',
    maisDetalhes: 'Mais Detalhes',
    emocionometroNaoPreenchido:
      'O emocionômetro não foi preenchido nenhuma vez, não há nenhuma informação para ser exibida!',
    pontosAtenção: 'Pontos de Atenção',
    geral: 'Geral',
    explicacaoGeral:
      'Na análise geral serão apresentadas dois cards enfatizando áreas ressaltarão os pontos positivos e as conquistas notáveis.',
    naoHaInformacoesSuficientes:
      'Não há informações suficientes para serem exibidas! recomendamos que você se dedique ao desenvolvimento de habilidades com seus estudantes para visualizar as informações.',
    explicacaoTurma:
      'O intuito desta apresentação é analisar as habilidades nas turmas, focalizando naquela que demanda atenção e na que se destaca positivamente.',
    comparativo: 'Comparativo',
    explicacaoComparativo:
      'Nessa análise, identificamos o estudante com a habilidade menos desenvolvida e a comparamos com a média da turma na mesma habilidade.',
    atuar: 'Atuar',
    explorar: 'Explorar',
    conhecimento: 'Conhecimento',
    comportamento: 'Comportamento',
    percepcao: 'Percepção',
    conhecimentoDescricao:
      'Resultados obtidos a partir das análises que investigam o nível de compreensão dos conceitos relacionados às habilidades socioemocionais.',
    comportamentoDescricao:
      'Resultados obtidos a partir das análises que investigam a aplicação prática das habilidades socioemocionais.',
    percepcaoDescricao:
      'Resultados obtidos a partir das análises que investigam a capacidade de reconhecer as habilidades socioemocionais exteriorizados por outras pessoas.',
    semDados: 'Sem Dados',
    totalDeRespostas: 'Total de respostas',
    gerenciarEstudantes: 'Gerenciar Estudantes',
    gerenciarTurmas: 'Gerenciar Turmas',
    gerenciarColaboradores: 'Gerenciar Colaboradores',
    gerenciarPerfis: 'Gerenciar Perfis',
    dadosMinhaOrganizacao: 'Dados da minha organização',
    dadosMinhaEscola: 'Dados da minha escola',
    minhaEscola: 'Minha Escola',
    instituicao: 'Instituição',
    criarTurma: 'Criar Turma',
    turmaSérie: 'Turma/Série',
    sérieTurma: 'Série/Turma',
    nomeSérie: 'Nome/Série',
    chave: 'Chave',
    quantidadeDeEstudantes: 'Quantidade de Estudantes',
    qtdEstudantes: 'Qtd de Estudantes',
    anoLetivo: 'Ano Letivo',
    painelDaTurma: 'Painel da Turma',
    acoes: 'Ações',
    excluirTurmaConfirmacao:
      'Tem certeza que deseja excluir o registro da turma?',
    turmaExcluidaComSucesso: 'A turma foi excluída com sucesso!',
    erroAoExcluirTurma:
      'Houve um problema ao excluir a turma. Verifique sua conexão e tente novamente.',
    excluirTurmasConfirmacao:
      'Tem certeza que deseja excluir o registro das turmas?',
    turmasExcluidasComSucesso: 'As turmas foram excluídas com sucesso!',
    erroAoExcluirTurmas:
      'Houve um problema ao excluir as turmas. Verifique sua conexão e tente novamente.',
    deletar: 'Deletar',
    rematricula: 'Rematrícula',
    visualizar: 'Visualizar',
    verPainel: 'Ver painel',
    turno: 'Turno',
    Estudantes: 'Estudantes',
    professores: 'Professores',
    desenvolvimentoDeCompetencias:
      'Desenvolvimento de Competências e Habilidades',
    analiseDoClimaEscolar: 'Análise do Clima Escolar',
    tipoDeComparação: 'Tipo de Comparação',
    modoComparativo: 'Modo Comparativo',
    selecioneATurma: 'Selecione a turma',
    selecioneOEstudante: 'Selecione o estudante',
    areaDeFoco: 'Área de foco',
    habilidades: 'Habilidades',
    selecionarTodos: 'Selecionar todos',
    recomendacoesPedagogicas: 'Recomendações Pedagógicas',
    feliz: 'Feliz',
    triste: 'Triste',
    medo: 'Medo',
    nojo: 'Nojo',
    raiva: 'Raiva',
    totalDeAtividades: 'Total de Atividades',
    concluidas: 'Concluídas',
    emProgresso: 'Em Progresso',
    naoIniciadas: 'Não Iniciadas',
    rotaDeAprendizado: 'Rota de Aprendizado',
    limparFiltro: 'Limpar filtro',
    trilha: 'Trilha',
    avaliacaoInicial: 'Avaliação Inicial',
    inicio: 'Início',
    termino: 'Término',
    jogoEscolhido: 'Jogo Escolhido',
    progresso: 'Progresso',
    concluidoEm: 'Concluído em',
    registrosEmMeuCantinho: 'Registros em “Meu Cantinho”',
    meuCantinho: 'Meu Cantinho',
    mostrarMais: 'Mostrar Mais',
    totalDeRegistros: 'Total de Registros',
    naoClassificado: 'Não classificado',
    coracaoQuentinho: 'Coração quentinho',
    bomFicarDeOlho: 'É bom ficar de olho',
    redobreAtencao: 'Redobre a atenção',
    problemaSerio: 'Problema sério',
    finalizar: 'Finalizar',
    dadosGerais: 'Dados Gerais',
    equipe: 'Equipe',
    pedagogica: 'Pedagógica',
    equipePedagogica: 'Equipe Pedagógica',
    editada: 'editada',
    criada: 'criada',
    comSucesso: 'com sucesso!',
    houveUmProblemaAo: 'Houve um problema ao',
    aTurma: 'a turma',
    verifiqueSuaConexaoETenteNovamente:
      'Verifique sua conexão e tente novamente.',
    modoDemonstrativo: 'Modo Demonstrativo',
    mensagemDeErroAnoLetivo: 'Por favor, informe o ano letivo!',
    mensagemDeErroSerie: 'Por favor, informe a série!',
    mensagemDeErroTurno: 'Por favor, informe o turno!',
    mensagemDeErroNomeDaTurma: 'Por favor, escreva o nome da turma!',
    mensagemDeErroDataDeExpiracao: 'Por favor, informe a data de expiração!',
    dataDeExpiracao: 'Data de expiração',
    desejaCriarUmaAvaliacaoInicial: 'Deseja criar uma avaliação inicial?',
    mensagemDeErroAvaliacaoInicial:
      'Por favor, escolha se deseja criar uma avaliação inicial ou não!',
    integral: 'Integral',
    sim: 'Sim',
    nao: 'Não',
    aviso30DiasTurma:
      'Atenção: Após 30 dias da criação da turma, não será possível editar os campos "Ano letivo", "Série" e "Turno".',
    baixarQrCode: 'Baixar Qr Code',
    instrucoesQrCode:
      'Está na hora de testar nossa plataforma na prática no modo educador. Siga estes passos:',
    instrucao1: 'Escaneie o QR code com a câmera do seu celular.',
    instrucao2:
      'Cadastre-se inserindo seu nome ou apelido e clique em "ENTRAR" para acessar a página principal.',
    instrucao3: 'Agora é só aproveitar e explorar nossa plataforma.',
    visualizarQrCode: 'Visualizar QR Code',
    nomePerfil: 'Nome/Perfil',
    situacao: 'Situação',
    fechar: 'Fechar',
    perfilDe: 'Perfil de',
    educador: 'Educador',
    todosOsColaboradores: 'Todos os Colaboradores',
    selecionados: 'Selecionados',
    ateAgoraNenhumColaboradorEscolhido:
      'Até agora, nenhum colaborador foi escolhido. Por favor, selecione os colaboradores para visualizá-los aqui.',
    naoExistemMaisColaboradoresParaExibicao:
      'Não existem mais colaboradores para exibição. Todos os colaboradores ja foram escolhidos.',
    estudantesDaTurma: 'Estudantes da Turma',
    nomeChave: 'Nome/Chave',
    emAndamento: 'Em Andamento',
    naoIniciada: 'Não Iniciada',
    concluido: 'Concluído',
    expirada: 'Expirada',
    exEstudante: 'Ex-Estudante',
    todosOsEstudantes: 'Todos os Estudantes',
    naoExistemMaisEstudantesParaExibicao:
      'Não existem mais estudantes para exibição. Todos os estudantes ja foram escolhidos.',
    ateAgoraNenhumEstudanteEscolhido:
      'Até agora, nenhum estudante foi escolhido. Por favor, selecione os estudantes para visualizá-los aqui.',
    aAvaliacaoInicialÉCompostaPor: 'A avaliação inicial é composta por',
    sendoElas: 'sendo elas',
    daCompetencia: 'da competência',
    rematriculaEfetuadaComSucesso: 'Rematrícula efetuada com sucesso!',
    mensagemDeErroRematricula:
      'Houve um problema ao efetuar a rematrícula. Verifique sua conexão e tente novamente.',
    listaDeChavesDeAcessosDosEstudantes:
      'Lista de Chaves de Acessos dos Estudantes',
    versaoColorida: 'Versão Colorida',
    versaoEscalasDeCinza: 'Versão escalas de cinza',
    modoBilhete: 'Modo Bilhete',
    baixarTabela: 'Baixar tabela',
    baixarChaveDosEstudantes: 'Baixar chave dos estudantes',
    tecnologiaSocioemocional: 'U4Hero! Tecnologia Socioemocional',
    nomeDaEscola: 'Nome da Escola',
    mensagemBoasVindasAluno:
      'Olá, bem-vindo(a) à U4Hero! Você está a apenas 2 passos de iniciar sua jornada de diversão e aprendizagem:',
    passo1Bilhete:
      'Faça o download do nosso app nas lojas (Play Store, Windows Store ou App Store) ou acesse diretamente pelo navegador',
    passo2Bilhete: 'Insira sua chave de acesso e clique em REGISTRAR.',
    prepareSeParaUmaExperienciaIncrível:
      'Prepare-se para uma experiência incrível de desenvolvimento e crescimento com a',
    qualquerDuvidaMandeUmEmailPara: 'Qualquer dúvida mande um e-mail para',
    chavesDeAcesso: 'Chaves de Acesso',
    nomeDoEstudante: 'Nome do Estudante',
    serieTurma: 'Série/Turma',
    ultimoAcesso: 'Último acesso',
    ultimaResposta: 'Última resposta',
    painelDoEstudante: 'Painel do estudante',
    temCertezaQueDeseja: 'Tem certeza que deseja',
    restaurar: 'Restaurar',
    oCadastroDoEstudante: 'o cadastro do estudante ?',
    cadastro: 'Cadastro',
    excluido: 'excluido',
    restaurado: 'restaurado',
    opsAconteceuAlgumProblemaAoTentar:
      'Ops, aconteceu algum problema ao tentar',
    oCadastro: 'o cadastro',
    tenteNovamente: 'tente novamente!',
    gerarNovaChave: 'Gerar Nova Chave',
    ultimoAcessoApartirDe: 'Último acesso a partir de',
    estudantesDesativados: 'Estudantes desativados',
    estudantesComChaveAtiva: 'Estudantes com chave ativa',
    estudantesSemTurma: 'Estudantes sem turma',
    naoIniciado0: 'Não Iniciado (0%)',
    emAndamento179: 'Em Andamento (1% - 79%)',
    concluido100: 'Concluído (100%)',
    cadastroAtualizado: 'Cadastro atualizado',
    estudanteCadastrado: 'Estudante cadastrado',
    mensagemErroCadastro:
      'Ops, aconteceu algum problema ao tentar efetivar o cadastro, tente novamente!',
    mensagemSairSemSalvar:
      'Você não salvou as informações que inseriu, deseja sair mesmo assim?',
    informacoesNaoSalvas: 'Informações não salvas!',
    desejaRealmenteRealizarEstaOperacao:
      'Deseja realmente realizar esta operação?',
    temCerteza: 'Você tem certeza?',
    operacaoRealizadaComSucesso: 'Operação realizada com sucesso!',
    problemaAoRealizarOperacao: 'Houve um problema ao realizar esta operação!',
    informacoesDoEstudante: 'Informações do estudante',
    matricula: 'Matrícula',
    mensagemErroMatricula: 'Por favor, informe o número da matrícula!',
    nomeCompleto: 'Nome Completo',
    mensagemErroNomeCompleto:
      'Por favor, informe o nome completo do estudante!',
    dataDeNascimento: 'Data de Nascimento',
    mensagemErroDataDeNascimento: 'Por favor, informe a data de nascimento!',
    mensagemErroSexo: 'Por favor, informe o sexo do estudante!',
    informacoesDoResponsavel: 'Informações do Responsável',
    mensagemErroTelefone: 'O telefone deve ter pelo menos 10 números!',
    nomeDoResponsavel2: 'Nome do Responsável (2)',
    nomeDoResponsavel1: 'Nome do Responsável (1)',
    informacoesAcademicas: 'Informações Acadêmicas',
    mensagemErroImportacao:
      'Ops, aconteceu algum problema ao tentar realizar a importação, tente novamente!',
    importacaoRealizadaComSucesso: 'Importação realizada com sucesso!',
    mensagemVerificarImportacao:
      'Você não importou o arquivo que inseriu. deseja sair mesmo assim?',
    mensagemImportacao:
      'Se você deseja cadastrar vários estudantes de uma só vez, oferecemos a opção de importar dados através de uma tabela. Você pode conferir o modelo abaixo, os campos de nome, data de nascimento, documento e sexo são obrigatórios.',
    baixarModelo: 'Baixar modelo',
    cliqueAquiOuArrasteAplanilha: 'Clique aqui ou arraste a planilha',
    bomComportamento: 'Bom Comportamento',
    mauComportamento: 'Mau Comportamento',
    todos: 'Todos',
    observacao: 'Observação',
    selecioneOsComportamentos: 'Selecione os comportamentos',
    tipoDeObservacao: 'Tipo de Observação',
    observacoesAdicionaisOpcional: 'Observações adicionais (opcional)',
    observacaoAdicional: 'Observação adicional',
    historicoDeObservacoes: 'Histórico de Observações',
    comportamentosSelecionados: 'Comportamentos selecionados',
    mostrarMenos: 'Mostrar menos',
    lerMais: 'Ler mais',
    bomComportamento1: 'Ajudou a organizar a sala de aula.',
    bomComportamento2: 'Apresentou atitudes de liderança.',
    bomComportamento3: 'Apresentou atitudes de responsabilidade ambiental.',
    bomComportamento4: 'Colaborou bem em atividades de grupo.',
    bomComportamento5: 'Colaborou na manutenção da ordem na sala.',
    bomComportamento6: 'Completou as tarefas com qualidade.',
    bomComportamento7: 'Completou as tarefas com rapidez.',
    bomComportamento8: 'Contribuiu para a resolução de conflitos.',
    bomComportamento9: 'Contribuiu para um ambiente positivo.',
    bomComportamento10: 'Demonstrou empatia pelos colegas.',
    bomComportamento11: 'Expressou entusiasmo pelo aprendizado.',
    bomComportamento12: 'Foi proativo em buscar ajuda.',
    bomComportamento13: 'Foi proativo na resolução de problemas.',
    bomComportamento14: 'Manifestou interesse na disciplina.',
    bomComportamento15: 'Mostrou respeito pelos colegas.',
    bomComportamento16: 'Participou ativamente nas discussões em sala.',
    bomComportamento17: 'Participou em atividades extracurriculares.',
    bomComportamento18: 'Participou em eventos escolares.',
    bomComportamento19: 'Teve bom relacionamento com os colegas.',
    bomComportamento20: 'Teve interesse em aprender outros conteúdos.',
    mauComportamento1: 'Agrediu um colega.',
    mauComportamento2: 'Apresentou comportamento desrespeitoso.',
    mauComportamento3: 'Demonstrou falta de interesse na matéria.',
    mauComportamento4: 'Esqueceu os materiais.',
    mauComportamento5: 'Ficou distraído durante a aula.',
    mauComportamento6: 'Ficou em pé a aula toda.',
    mauComportamento7: 'Manifestou falta de empatia pelos colegas.',
    mauComportamento8: 'Mostrou falta de organização.',
    mauComportamento9: 'Mostrou-se ansioso durante testes ou avaliações.',
    mauComportamento10: 'Não aceitou as críticas.',
    mauComportamento11: 'Não concluiu as tarefas de casa.',
    mauComportamento12: 'Não parou de falar a aula toda.',
    mauComportamento13: 'Não quis trabalhar em grupo.',
    mauComportamento14: 'Não respeitou as regras da sala de aula.',
    mauComportamento15: 'Passou/Recebeu cola durante a avaliação.',
    mauComportamento16: 'Teve dificuldade em aceitar a autoridade dos colegas.',
    mauComportamento17: 'Teve dificuldade em aceitar opiniões divergentes.',
    mauComportamento18: 'Usou linguagem inadequada.',
    mauComportamento19: 'Usou o celular durante a aula.',
    mauComportamento20: 'Usou roupas inadequadas para a escola.',
    outroEspecifique: 'Outro (Especifique abaixo).',
    bomFicarDeOlho1: 'Demonstrou falta de autoconfiança.',
    bomFicarDeOlho2: 'Dormiu durante a aula.',
    bomFicarDeOlho3: 'Ficou isolado dos colegas.',
    bomFicarDeOlho4: 'Foi alvo de bullying.',
    bomFicarDeOlho5: 'Foi vítima de discriminação.',
    bomFicarDeOlho6: 'Teve dificuldade em controlar impulsos.',
    bomFicarDeOlho7: 'Teve dificuldade em expressar emoções.',
    bomFicarDeOlho8: 'Teve dificuldade em seguir instruções.',
    historicoDeAcessos: 'Histórico de Acessos',
    matriculado: 'Matriculado',
    eMailDeAcessoEnviadoComSucesso: 'E-mail de acesso enviado com sucesso!',
    erroAoEnviarEmail: 'Houve um problema ao enviar o e-mail. Verifique sua conexão e tente novamente.',
    colobadorJaExiste:'Colaborador não cadastrado! Um registro com o mesmo e-mail já existe.',
    erroAoSalvarAlteracoes: 'Não foi possível salvar as alterações. Verifique sua conexão e tente novamente.',
    erroAoCadastrarColaborador: 'Houve um problema ao cadastrar o colaborador. Verifique sua conexão e tente novamente.',
    alteracaoConcluidaComSucesso: 'A alteração foi concluída com sucesso!',
    colaboradorCriadoComSucesso: 'Colaborador criado com sucesso!',
    mensagemErroNomeColaborador: 'Por favor, informe o nome do colaborador!',
    insiraONomeCompleto: 'Insira o nome completo',
    insiraONomeDoColaborador: 'Insira o nome do colaborador',
    email: 'Email',
    mensagemAlteracaoEmail: 'Se precisar alterar o e-mail, por favor, entre em contato com nosso suporte para continuar o processo de alteração. Estamos aqui para ajudar!',
    mensagemErroEmail: 'Por favor, informe o e-mail!',
    exemploEmail: 'exemplo@exemplo.com',
    mensagemErroCpf: 'Por favor, informe o número do cpf!',
    cpf: 'CPF',
    mensagemErroPerfil: 'Por favor, selecione o perfil!',
    selecioneOPerfilCorrespondente: 'Selecione o perfil correspondente',
    alteracaoDeSenha: 'Alteração de Senha',
    novaSenha: 'Nova Senha',
    insiraANovaSenha: 'Insira a nova senha',
    reenviarEmailDeAcesso: 'Reenviar e-mail de acesso',
    importacaoDeColaboradoresConcluidaComSucesso: 'Importação de colaboradores concluída com sucesso!',
    erroAoImportarColaboradores: 'Houve um problema ao importar os colaboradores. Verifique sua conexão e tente novamente.',
    mensagemImportacaoColaboradores: 'Se desejar cadastrar vários colaboradores de uma vez, oferecemos a opção de importar dados através de uma tabela. Para isso, a planilha deverá seguir o exemplo do modelo fornecido. Caso algum colaborador não tenha o campo "perfil" preenchido, será automaticamente considerado como "educador".',
    status: 'Status',
    oCadastroDoColaborador: 'o cadastro do colaborador?',
    oCadastroDoColaboradorFoi: 'O cadastro do colaborador foi',
    desativado: 'desativado',
    ativado: 'ativado',
    voceNaoTemPermissaoPara: 'Você não tem permissão para',
    doColaborador: 'do colaborador',
    formatoDeImagemInvalido: 'Formato de imagem inválido',
    tamanhoDaImagemInvalido: 'Tamanho da imagem inválido',
    erroAoFazerUpload: 'Erro ao fazer upload!',
    formatosAceitos: 'Formatos aceitos',
    tamanhoMaximoDoArquivo: 'Tamanho máximo do arquivo',
    carregarFoto: 'CARREGAR FOTO',
    editadoComSucesso: 'editado com sucesso.',
    cadastradoComSucesso: 'cadastrado com sucesso.',
    mensagemErroNomePerfil: 'Por favor, insira o nome do perfil!',
    atribuaUmNomeParaEssePerfil: 'Atribua um nome para esse perfil',
    gerenciamentoDePermissoes: 'Gerenciamento de Permissões',
    nomeDoPerfil: 'Nome do Perfil',
    acessoCompleto: 'Acesso Completo',
    dataDeCriacao: 'Data de criação',
    temCertezaQueDesejaExcluirOPerfilDe: 'Tem certeza que deseja excluir o perfil de',
    perfilExcluidoComSucesso: 'Perfil excluído com sucesso!',
    erroAoExcluirPerfil: 'Houve um problema ao excluir o perfil. Verifique sua conexão e tente novamente.',
    perfilAtualizadoComSucesso: 'Perfil atualizado com sucesso!',
    perfilDaInstituicao: 'Perfil da Instituição',
    salvarAlteracoes: 'Salvar Alterações',
    informacoesDaInstituicao: 'Informações da Instituição',
    razaoSocial: 'Razão Social',
    porFavorInformeSeuNomeCompleto: 'Por favor, informe seu nome completo!',
    porFavorInformeONumeroDoCnpj: 'Por favor, informe o número do cnpj!',
    mensagemErroTelefoneInstituicao: 'Por favor, informe o telefone da instituição!',
    nomeFantasia: 'Nome Fantasia',
    trocarDirecao: 'Trocar Direção',
    direcaoEContato: 'Direção e Contato',
    nomeDoDiretor: 'Nome do Diretor',
    mensagemErroCep: 'O CEP deve ter 8 digitos',
    campoObrigatorio: 'Campo obrigatório!',
    logradouro: 'Logradouro',
    mensagemErroLogradouro: 'Por favor, informe o logradouro da escola!',
    ruaAvenidaTravessa: 'Rua, Avenida, Travessa...',
    mensagemErroBairro: 'Por favor, informe o bairro!',
    nomeDoBairro: 'Nome do bairro',
    aptoCasa: 'Apto, casa...',
    mensagemErroEstado: 'Por favor, informe o estado!',
    mensagemErroCidade: 'Por favor, informe a cidade!',
    progressoDoPerfil: 'Progresso do Perfil',
    menosDetalhes: 'Menos Detalhes',
    pesquisar: 'Pesquisar',
    filtradoPor: 'Filtrado por',
    consulteAListaDeColaboradoresCadastrados: 'Consulte a lista de colaboradores cadastrados e selecione o diretor da escola',
    visaoGeral: 'Visão Geral',
    catalogo: 'Catálogo',
    informacoesDoJogo: 'Informações do Jogo',
    atividades: 'Atividades',
    geografia: 'Geografia',
    historia: 'História',
    matematica: 'Matemática',
    duracao: 'Duração',
    temaExtraAbordado: 'Tema Extra Abordado',
    faixaEtaria: 'Faixa Etária',
    anosMais: 'anos +',
    desfavoritar: 'Desfavoritar',
    favoritar: 'Favoritar',
    testarJogo: 'Testar jogo',
    objetivo: 'Objetivo',
    autor: 'Autor',
    esteJogoFoiDesenvolvidoPor: 'Este jogo open source foi desenvolvido por',
    agradecemosAoAutorPorSeuTrabalho: 'Agradecemos ao autor por seu trabalho!',
    desenvolvidoPelaEquipe: 'Desenvolvido pela equipe U4Hero, este jogo é uma criação exclusiva.',
    carregandoJogo: 'Carregando jogo...',
    mensagemPreviaConcluida:' Esperamos que você tenha gostado desta amostra! Quando estiver pronto, clique em "Concluir" para encerrar a prévia.',
    concluir: 'Concluir',
    previaDoJogo: 'Prévia do Jogo',
    estratégia: 'Estratégia',
    puzzle: 'Puzzle',
    simulação: 'Simulação',
    classificacao: 'Classificação',
    jogosJaUtilizados: 'Jogos já utilizados',
    jogosNaoUtilizados: 'Jogos não utilizados',
    duracaoDoJogo: 'Duração do Jogo',
    ate: 'Até',
    temasExtras: 'Temas Extras',
    limiteDePerguntas: 'Limite de Perguntas',
    favoritos: 'Favoritos',
    acompanheOsIndicadores: 'Acompanhe os Indicadores e as Novidades dos Jogos em Tempo Real',
    jogosSendoUtilizados: 'Jogos sendo utilizados',
    taxaDeParticipacao: 'Taxa de Participação',
    emBreve: 'Em breve',
    ultimosAcessos: 'Últimos Acessos',
    novidades: 'Novidades',
    mensagemCarroselJogos:'Descubra um novo universo de aprendizado através dos jogos, onde seus alunos podem desenvolver habilidades socioemocionais de maneira envolvente e divertida.',
    feedbacks: 'Feedbacks',
    centralDePerguntas: 'Central de Perguntas',
    enunciado: 'Enunciado',
    habilidadesCamposDeConhecimento: 'Habilidades/ Campos de conhecimento',
    escopo: 'Escopo',
    tooltipPrivada: 'Perguntas privadas podem ser usadas apenas pelo autor da pergunta e pelo administrador da escola.',
    tooltipPublica: 'Perguntas públicas podem ser usadas por todos membros da escola.',
    tooltipGlobal: 'Perguntas globais podem ser usadas por todas as escolas da U4Hero.',
    aPergunta: 'a pergunta',
    aPerguntaFoi:'A pergunta foi',
    voceNaoTemPermissaoParaAtivarEstaPergunta: 'Você não tem permissão para alterar o status desta pergunta!',
    feedback: 'Feedback',
    muitoRuim: 'Muito Ruim',
    ruim: 'Ruim',
    regular: 'Regular',
    bom: 'Bom',
    muitoBom: 'Muito Bom',
    boa: 'Boa',
    categoria: 'Categoria',
    data: 'Data',
    icone: 'Ícone',
    avaliacao: 'Avaliação',
    aPartirDe: 'A partir de',
    naoHa: 'Não há',
    privada: 'Privada',
    publica: 'Pública',
    global: 'Global',
    competenciaHabilidade: 'Competência/Habilidade',
    ambienteAnalise: 'Ambiente de Análise',
    tipoAnalise: 'Tipo de Análise',
    tipoPergunta: 'Tipo de Pergunta',
    muitoBaixa: 'Muito Baixa',
    baixa: 'Baixa',
    moderado: 'Moderado',
    muitoBoa: 'Muito Boa',
    discordoCompletamente: 'Discordo Completamente',
    discordo: 'Discordo',
    neutro: 'Neutro',
    concordo: 'Concordo',
    concordoCompletamente: 'Concordo Completamente',
    detalhesDaPergunta: 'Detalhes da Pergunta',
    mensagemErroTipoAnalise: 'Por favor, selecione o que a pergunta estará avaliando!',
    mensagemErroAmbienteAnalise: 'Por favor, selecione o ambiente de análise!',
    mensagemErroFaixaEtaria: 'Por favor, determine a faixa etária, entre 5 e 18 anos!',
    aplicavelEm: 'Aplicável em',
    saibaMaisSobreAsFaixasEtarias: 'Saiba mais sobre as faixas etárias',
    mensagemErroEnunciado: 'Por favor, crie um enunciado!',
    digiteAafirmaçãoDaEscalaLikertAqui: 'Digite a afirmação da escala Likert aqui',
    placeholderLikert: 'Insira uma afirmação para que os respondentes possam indicar seu grau de concordância ou discordância.',
    placeholderEnunciado: 'Crie o enunciado da pergunta',
    mensagemErroTipoAfirmaçãoLikert: 'Por favor, defina o tipo de afirmação likert.',
    estaAfirmaçãoÉ: 'Esta afirmação é',
    positiva: 'Positiva',
    negativa: 'Negativa',
    imagemPNGouJPGate3Mb: 'Imagem PNG ou JPG até 3Mb.',
    alternativa: 'Alternativa',
    excluirA: 'excluir a',
    alternativaExcluidaComSucesso: 'Alternativa excluída com sucesso!',
    mensagemErroAlternativa: 'Por favor, crie uma alternativa para a pergunta!',
    mensagemErroCompreensao: 'Por favor, avalie o grau de compreensão da alternativa!',
    grauDeCompreensao: 'Grau de Compreensão',
    gerar:'Gerar',
    sugestoes:'Sugestões',
    sugestao:'Sugestão',
    alternativas:'Alternativas',
    mensagemErroSalvarSemAlteracoes: 'Faça alguma alteração antes de salvar!',
    mensagemValidacaoAlternativas: 'Para garantir a qualidade da pergunta, é necessário incluir pelo menos duas alternativas com diferentes graus de compreensão, como "muito baixa" e "muito boa". Isso ajuda a avaliar de forma mais precisa',
    erroAoCriarPergunta: 'Houve um problema ao criar a pergunta. Verifique sua conexão e tente novamente.',
    perguntaCriadaComSucesso: 'Pergunta criada com sucesso!',
    formatoDaResposta: 'Formato da Resposta',
    mensagemErroFormatoDaResposta: 'Por favor, selecione o formato da resposta!',
    escalaLikert: 'Escala Likert',
    alternativasPersonalizadas: 'Alternativas Personalizadas',
    areaDeAnalise: 'Área de Análise',
    definaAreaDeAnalise: 'Defina a área de análise!',
    competencia: 'Competência',
    focoDeAnaliseDoClimaEscolar: 'Foco de Análise do Clima Escolar',
    escolhaDoTipoDeTreinamento: 'Escolha do Tipo de Treinamento',
    mensagemErroCompetencia: 'Por favor, selecione uma competência!',
    mensagemErroHabilidade: 'Por favor, selecione uma habilidade!',
    mensagemErroClimaEscolar:'Defina qual será o foco de análise do clima escolar!',
    mensagemErroTreinamento: 'Defina o tipo de treinamento!',
    mensagemErroAreaDefoco:'Defina a área de Foco',
    mensagemDuasCompetencias:'Você já selecionou duas competências, não é necessário selecionar uma habilidade!',
    informacoesAdicionais: 'Informações Adicionais',
    mensagemErroTipoPergunta: 'Por favor, selecione qual é o tipo da pergunta!',
    mensagemErroEscopo: 'Por favor, defina o escopo da pergunta',
    treinamento: 'Treinamento',
    climaEscolar: 'Clima Escolar',
    pesquisa: 'Pesquisa',
    mensagemErroAreaDeAnalise: 'Por favor, defina a área de análise desejada',
    selecioneAreaDeAnaliseDesejada: 'Selecione a área de análise desejada:',
    atividade: 'Atividade',
    situacional: 'Situacional',
    moderada: 'Moderada',
    invalido: 'Inválido',
    modoVisualizacao: 'Modo Visualização',
    dublagem: 'Dublagem',
    desativou: 'Desativou',
    ativou: 'Ativou',
    movimentacao: 'Movimentação',
    explicacaoFaixaEtaria:'Ao criar uma pergunta, é crucial selecionar a faixa etária adequada para garantir a adequação do conteúdo à idade e ao desenvolvimento dos estudantes.',
    explicacaoFaixaEtaria2:'Além disso, é importante observar as regras específicas para direcionar a pergunta à turma correta.',
    à:'à',
    atividadeDaTurma:'Atividade da Turma', 
    atividadeIndividual:'Atividade Individual',
    avaliacaoDaTurma:'Avaliação da Turma',
    avaliacaoIndividual:'Avaliação Individual',
    responsavel:'Responsável',
    qtd:'Qtd.',
    tempoRestante:'Tempo Restante',
    voceNaoEoResponsavelPelaAtividade:'Você não é o responsável pela atividade!',
    voceNaoEoResponsavelPelaAutomacao:'Você não é o responsável pela automação!',
    excluirAtividadeConfirmacao:'Tem certeza que deseja excluir a atividade?',
    atividadeExcluidaComSucesso:'A atividade foi excluída com sucesso!',
    erroAoExcluirAtividade:'Houve um problema ao excluir a atividade. Verifique sua conexão e tente novamente.',
    dataDeInicio:'Data de Inicio',
    dataDeTermino:'Data de Término',
    pesquisarPeloResponsavel:'Pesquisar pelo responsável',
    frequencia:'Frequência',
    excluirAutomacaoConfirmacao:'Tem certeza que deseja excluir a automação?',
    automacaoExcluidaComSucesso:'A automação foi excluída com sucesso!',
    erroAoExcluirAutomação:'Houve um problema ao excluir a automação. Verifique sua conexão e tente novamente.',
    automacao:'Automação',
    prazo:'Prazo',
    definaOPrazodaAtividade:'Defina o prazo da atividade:',
    anterior:'Anterior',
    proximo:'Próximo',
    registrosDoMeuCantinho:'Registros do meu cantinho',
    naoHaNenhumRegistroNoMeuCantinho:'Não há nenhum registro no meu cantinho, não há informações para serem exibidas!',
    vejaVersaoCompleta:'Veja versão completa',
    selecioneAsTurmasASerAplicada:'Selecione a(s) turma(s) que a atividade será aplicada',
    selecioneOsEstudantesQueATividadeSeraAplicada:'Selecione o(s) estudante(s) que a atividade será aplicada',
    selecioneUmJogoParaATividade: 'Selecione um jogo para a atividade',
    minutos: 'minutos',
    utilizado: 'Utilizado',
    naoUtilizado: 'Não utilizado',
    vez: 'vez',
    competenciasEHabilidades: 'Competências e Habilidades',
    selecioneOQueSeraAbordado: 'Selecione o que será abordado',
    selecione:'Selecione',
    as:'as',
    perguntasQueSeraoInseridasNaAtividade:'Perguntas que serão inseridas na atividade',
    selecioneOTipoDePerguntaDesejada:'Selecione o tipo de pergunta desejada:',
    bancoDePerguntas:'Banco de Perguntas',
    bancoDePerguntasVazio:'Não existem mais perguntas disponíveis para exibição. Por favor, crie mais perguntas para ampliar as opções no banco de dados.',
    selecioneOTipoDePerguntaParaVisualizarAsOpcoesDisponiveis:'Selecione o tipo de pergunta para visualizar as opções disponíveis!',
    analise:'Análise',
    ambiente:'Ambiente',
    ateAgoraNenhumaPerguntaFoiEscolhida:'Até agora, nenhuma pergunta foi escolhida. Por favor, selecione as perguntas no banco de dados para visualizá-las aqui.',
    visualizacaoDasAlternativas:'Visualização das Alternativas',
    anonimo:'Anônimo',
    automatica:'Automática',
    registro:'Registro',
    lidas:'Lidas',
    naoLidas:'Não Lidas',
    naoIniciado: 'Não iniciado',
    informacoesSalvasComSucesso: 'Informações salvas com sucesso!',
    acessoNegado: 'Acesso Negado!',
    mensagemAcessoNegadoMeuCantinho:'Você recebeu uma nova mensagem no “Meu Cantinho”. No entanto, você não está mais vinculado a essa turma e não pode acessar a mensagem.',
    voltarParaPaginaInicial:'VOLTAR PARA PAGINA INICIAL',
    cantinhoDoEstudante:'Cantinho do Estudante',
    registroMeuCantinho: 'Registro Meu Cantinho',
    statusDoAcolhimento: 'Status do Acolhimento',
    vocenãoTemPermissãoParaEditarEsteAcolhimento: 'Você não tem permissão para editar este acolhimento!',
    classificacaoDoEducador: 'Classificação do Educador',
    classificacaoAutomatica: 'Classificação Automática',
    classificacaoSocioemocional: 'Classificação Socioemocional',
    classificacaoDoMeuCantinho: 'Classificação do Meu Cantinho',
    justificativaDaClassificacao: 'Justificativa da Classificação',
    expressoesCoracaoQuentinho: 'Expressões Coração Quentinho',
    expressoesBomFicarDeOlho: 'Expressões é bom ficar de olho',
    expressoesRedobreAAtencao: 'Expressões Redobre a atenção',
    expressoesProblemaSerio: 'Expressões Problema Sério',
    mensagemErroComentario: 'Ops, aconteceu algum problema ao tentar postar seu comentário. Verifique sua conexão e tente novamente!',
    comentarioPostadoComSucesso: 'Comentário postado com sucesso!',
    comentarioNaoPodeSerVazio: 'O comentário não pode ser vazio!',
    nenhumComentarioAteAgora: 'Nenhum comentário até agora, seja o primeiro a comentar!',
    insiraSeuComentario: 'Insira seu comentário',
    preenchaAMensagemAntesDeEnviar: 'Preencha a mensagem antes de enviar!',
    mensagemErroEnviarMensagem: 'Ops, aconteceu algum problema ao tentar enviar sua mensagem. Verifique sua conexão e tente novamente!',
    mensagemEnviadaComSucesso: 'Mensagem enviada com sucesso!',
    chatAssincrono: 'Chat (Assíncrono)',
    enviarMensagemAcolhimento: 'Envie uma mensagem de acolhimento para este estudante!',
    insiraSuaMensagem: 'Insira sua mensagem',
    meuPainel: 'Meu Painel',
    visaoEducador: 'Visão de Educador',
    visaoGestor: 'Visão de Gestor',
    visaoOrganizacao: 'Visão de Organização',
    painelDeIndicadores: 'Painel de Indicadores',
    temCertezaQueDesejaExcluirOIndicador: 'Tem certeza que deseja excluir o indicador',
    indicadorExcluidoComSucesso: 'O indicador foi excluído com sucesso!',
    mensagemErroSalvarIndicador: 'Houve um problema ao salvar o indicador. Verifique sua conexão e tente novamente.',
    totalDeEstudantes: 'Total de estudantes',
    totalDeTurmas: 'Total de turmas',
    totalDeProfessores: 'Total de professores',
    totalDeColaboradores: 'Total de colaboradores',
    entendaSobreAsCompetenciasEHabilidades: 'Entenda sobre as Competências e Habilidades',
    emocionometroDosUltimos7Dias: 'Emocionômetro dos últimos 7 dias',
    graficoDuplicadoComSucesso: 'Gráfico duplicado com sucesso! Você agora está editando uma cópia. Faça as alterações necessárias e salve para criar um novo gráfico.',
    maisGraficos: '+ Gráficos',
    personalizeSeuPainelDeIndicadores: 'Personalize seu Painel de Indicadores',
    exploreAPersonalizacaoDoSeuPainelDeIndicadores:'Explore a personalização do seu painel de indicadores! Clique no botão abaixo para começar a montar seu painel e escolha os indicadores que deseja visualizar. Dê vida aos seus dados e tenha insights relevantes sobre o desenvolvimento socioemocional dos estudantes.',
    iniciarPersonalizacao: 'Iniciar Personalização',
    temporal: 'Temporal',
    ano: 'Ano',
    copiar: 'Copiar',
    frequenciaDosEstudantes: 'Frequência dos Estudantes',
    sugestoesParaVoce: 'Sugestões para você',
    visaoDaEscolaAtividades: 'Visão da Escola - Atividades',
    atividadesNaEscola: 'Atividades na Escola',
    indicadoresAtualizados: 'Indicadores atualizados',
    indicadoresIncluidos: 'Indicadores incluidos',
    incluir: 'incluir',
    osIndicadores: 'os indicadores',
    adicionarIndicadores: 'Adicionar Indicadores',
    frequenciaDosEducadores: 'Frequência dos Educadores',
    opcoesPreCriadas: 'Opções Pré-Criadas',
    personalizacaoDosGraficos: 'Personalização dos Gráficos',
    grafico: 'Gráfico',
    anoDeAnalise: 'Ano de Análise',
    mensagemErroAnoDeAnalise: 'Por favor, defina o ano à ser ser levado em consideração!',
    definaOsIndicadores: 'Por favor, defina os indicadores!',
    indicadores: 'Indicadores',
    escolhaOsIndicadores: 'Escolha os indicadores',
    estiloDoGrafico: 'Estilo do Gráfico',
    mensagemErroEstiloDoGrafico: 'Por favor, escolha o tipo do gráfico!',
    nomeDoGrafico: 'Nome do Gráfico',
    mensagemErroNomeDoGrafico: 'Por favor, crie um nome para esse gráfico!',
    preenchaOsCamposParaContinuarCriandoOGráfico: 'Preencha os campos para continuar criando o gráfico',
    crieUmNomeParaSeuGráfico: 'Crie um nome para seu gráfico',
    categoriaASerAAnalizada: 'Categoria a ser analisada',
    mensagemErroCategoriaASerAAnalizada: 'Por favor, defina a categoria à ser analisada!',
    avaliacoesIniciais: 'Avaliações Iniciais',
    avaliacoes: 'Avaliações',
    definicaoDoPeriodoDeTempo: 'Definição do Período de Tempo:',
    mensagemErroDefinicaoDoPeriodoDeTempo: 'Por favor, defina o periodo de tempo!',
    anual: 'Anual',
    bimestral: 'Bimestral',
    trimestral: 'Trimestral',
    quadrimestral: 'Quadrimestral',
    semestral: 'Semestral',
    comparativo1: 'Comparativo 1',
    comparativo2: 'Comparativo 2',
    mensagemErroComparativo1: 'Por favor, selecione o comparativo 1!',
    mensagemErroComparativo2: 'Por favor, selecione o comparativo 2!',
    bimestre: 'Bimestre',
    trimestre: 'Trimestre',
    quadrimestre: 'Quadrimestre',
    semestre: 'Semestre',
    modoDeComparacao: 'Modo de Comparação',
    mensagemErroModoDeComparacao: 'Por favor, defina o modo de comparação!',
    periodoDeTempo: 'Período de Tempo',
    estudanteVersusTurma: 'Estudante versus Turma',
    modoDeVisualizacaoDoGrafico: 'Modo de Visualização do Gráfico:',
    mensagemErroModoDeVisualizacaoDoGrafico: 'Por favor, defina o modo de visualização do gráfico!',
    selecioneASerie: 'Selecione a série',
    mensagemErroTurma: 'Por favor, selecione a turma!',
    mensagemErroSelecioneEstudante: 'Por favor, selecione o estudante!',
    graficoDeLinha: 'Gráfico de linha',
    graficoDeArea: 'Gráfico de área',
    graficoDeBarra: 'Gráfico de barra',
    graficoDeRadar: 'Gráfico de radar',
    graficoDePizza: 'Gráfico de pizza',
    graficoDeDonut: 'Gráfico de donut',
    graficoDeColuna: 'Gráfico de coluna',
    graficoDeColunaEmpilhada: 'Gráfico de coluna empilhada',
    dadosASeremExibidosReferenteA: 'Dados a serem exibidos, referente à:',
    mensagemErroDadosASeremExibidos: 'Por favor, defina os dados à serem exibidos!',
    mensagemErroPeriodo: 'Por favor, defina o periodo!',
    mes: 'Mês',
    nEstudantes: 'Nº Estudantes',
    selecioneOPeriodoRelatorio: 'Selecione o período que deseja gerar o relatório.',
    dataFinal: 'Data final',
    selecioneORelatorioQueDesejaGerar: 'Selecione o relatório que deseja gerar.',
    relatorioEmocaoGeradoComSucesso: 'Relatório emoção gerado com sucesso!',
    emocao: 'Emoção',
    dataPublicacao: 'Data publicação',
    estudanteS: 'estudante(s)',
    turmaS: 'turma(s)',
    comentarioPublicado: 'Comentário publicado!',
    oComentarioNaoPodeSerVazio: 'O comentário não pode ser vazio.',
    escrevaSeuComentario: 'Escreva seu comentário.',
    naoGostei: 'Não gostei',
    gostei: 'Gostei',
    respostaDoEstudante: 'Resposta do estudante',
    respostas: 'Respostas',
    obrigadoPeloSeuFeedback: 'Obrigado pelo seu feedback!',
    opsHouveAlgumErroTenteNovamente: 'Ops, houve algum erro, tente novamente!',
    inspiracoesPedagogicas: 'Inspirações Pedagógicas',
    gerenciarInspracoes: 'GERENCIAR INSPIRAÇÕES',
    inspiracoesNoTotal: 'Inspirações no Total',
    novasInspracoes: 'Novas Inspirações',
    verTudo: 'Ver tudo',
    min: 'min',
    novo: 'Novo',
    dificuldade: 'Dificuldade',
    sugestoesDeInspiracoesPedagogicas: 'Sugestões de Inspirações Pedagógicas',
    pesquisarPorNomeDaInspiracao: 'Pesquisar por nome da inspiração',
    recursos: 'Recursos',
    disciplinaRelacionada: 'Disciplina Relacionada',
    arte: 'Arte',
    ciencias: 'Ciências',
    educacaoFisica: 'Educação Física',
    linguaInglesa: 'Língua Inglesa',
    linguaPortuguesa: 'Língua Portuguesa',
    modalidade: 'Modalidade',
    publicado: 'Publicado',
    rascunho: 'Rascunho',
    inspiracoes: 'Inspirações',
    inspiracao: 'Inspiração',
    titulo: 'Título',
    ultimaAtualizacao: 'Última atualização',
    temCertezaQueDesejaExcluirAInspiracaoPedagogica: 'Tem certeza que deseja excluir a Inspiração pedagógica?',
    inspiracaoPedagogicaExcluidaComSucesso: 'A Inspiração pedagógica foi excluída com sucesso!',
    erroAoExcluirInspiracaoPedagogica: 'Houve um problema ao excluir a Inspiração pedagógica. Verifique sua conexão e tente novamente.',
    temCertezaQueDesejaExcluirAsInspiracoesPedagogicas: 'Tem certeza que deseja excluir as Inspirações pedagógicas?',
    inspiracoesPedagogicasExcluidasComSucesso: 'As Inspirações pedagógicas foram excluídas com sucesso!',
    erroAoExcluirInspiracoesPedagogicas: 'Houve um problema ao excluir as Inspirações pedagógicas. Verifique sua conexão e tente novamente.',
    avaliacaoGeral: 'Avaliação Geral',
    temCertezaQueDesejaDesfazerAsAlteracoes: 'Tem certeza que deseja desfazer as alterações?',
    alteracoesDesfeitasComSucesso: 'Alterações desfeitas com sucesso!',
    erroAoDesfazerAlteracoes: 'Não foi possível desfazer as alterações. Verifique sua conexão e tente novamente.',
    personalizeEssaPraticaDeAcordoComSeuContexto: 'Personalize essa prática de acordo com seu contexto!',
    adaptarInspiracao: 'Adaptar Inspiração',
    essaPraticaPedagogicaFoiAlterada: 'Essa prática pedagógica foi alterada,',
    cliqueAqui: 'clique aqui',
    seDesejaDesfazerAsAlteracoesEDesfazerParaAPraticaOriginal: 'se deseja desfazer as alterações e retornar para a prática original!',
    erroAoSalvarFeedback: 'Ops, aconteceu algum problema ao salvar seu feedback, tente novamente!',
    comoVoceAvaliaEssaPraticaPedagogica: 'Como você avalia essa prática pedagógica?',
    alteracaoRealizadaComSucesso: 'Alteração realizada com sucesso!',
    erroRecursoInspiracao: 'Por favor, descreva o objetivo da Inspiração pedagógica!',
    placeholderRecursos:'Cite os materiais que serão necessários para realizar a Inspiração pedagógica, caso não haja escreva “não há”....',
    erroConteudoInspiracao: 'Por favor, escreva o passo a passo da inspiração!',
    formatoArquivoInvalido: 'Formato de arquivo inválido',
    tamanhoArquivoInvalido: 'Tamanho do arquivo inválido',
    cliqueOuArrasteUmaImagem: 'Clique ou arraste uma imagem',
    paraAdicionarAoFinalDoDocumento: 'para adicionar ao final do documento',
    ouArquivo: 'ou arquivo',
    notificacoes: 'Notificações',
    ajuda: 'Ajuda',
    metricas: 'Métricas',
    instituições: 'Instituições',
    publicacoes: 'Publicações',
    loja: 'Loja',
    sejaBemVindoÀU4Hero: 'Seja bem-vindo à U4Hero!',
    introducaoU4hero: 'Somos a primeira plataforma de jogos socioemocionais do Brasil, ajudando educadores a entender como as emoções impactam o desempenho escolar e social dos estudantes. Oferecemos jogos digitais divertidos e trilhas de aprendizagem personalizadas. Venha conhecer nossa plataforma!',
    vamosLa: 'Vamos lá!',
    jaConheco: 'Já conheço!',
    descubraMaisTarde: 'Descubra mais tarde!',
    descubraMaisTexto:'Você poderá explorar novamente, ou em outro momento, os recursos da nossa plataforma. Para acessar é simples: basta clicar na sua foto de perfil no menu e selecionar "Explorar Recursos", ou através da',
    centralDeAjuda: 'Central de Ajuda',
    obrigadoPorFazerParteDaNossaComunidade: 'Obrigado por fazer parte da nossa comunidade e esperamos que sua jornada conosco seja de sucesso!',
    pular: 'Pular',
    continuar: 'Continuar',
    terminar: 'Terminar',
    mensagemTourConcluido:'Você explorou todos os recursos da nossa plataforma! Estamos felizes por tê-lo(a) conosco. Agora que conhece todas as funcionalidades, aproveite ao máximo da sua jornada profissional',
    detalhamentoDeEstudantesPorEmoções: 'Detalhamento de Estudantes por Emoções',
    autenticacaoDeDoisFatoresFoi: 'A autenticação de dois fatores',
    ativada: 'ativada',
    desativada: 'desativada',
    codigoAutenticacaoInvalido: 'O código autenticação está inválido ou expirado!',
    a: 'a',
    daAutenticacaoDeDoisFatoresFalhouPorfavorInserirOCódigoEnviadoPor:'da autenticação de dois fatores falhou. Por favor, inserir o código enviado por e-mail para concluir o processo de desativação.',
    ativacao: 'ativação',
    desativacao: 'desativação',
    teEnviamosUmNovoCodigoChequeSeuEmail: 'Te enviamos um novo código, cheque seu email!',
    erroAoEnviarCodigo: 'Ocorreu um problema ao enviar o codigo. Tente novamente.',
    segurancaReforcada: 'Segurança Reforçada!',
    desativacaoDaAutenticacao: 'Desativação da Autenticação!',
    mensagem2FaAtivado: 'Você ativou a autenticação de dois fatores. Um e-mail com o código de segurança foi enviado para você',
    mensagem2FaDesativado: 'Você desativou a autenticação de dois fatores. Verifique seu e-mail para inserir o código de segurança e concluir o processo.',
    digiteAquiOCodigoQueTeEnviamosPeloEmail: 'Digite aqui o código que te enviamos pelo email.',
    verificar: 'Verificar',
    recebimentoDoSeuCodigoPodeLevarAteUmMinuto: 'O recebimento do seu código pode levar até um minuto. Se não o recebeu,',
    cliqueAquiParaSolicitarUmNovoCodigo: 'clique aqui para solicitar um novo código',
    acompanhamentoDaAtividade: 'Acompanhamento da Atividade',
    totalDePerguntas: 'Total de Perguntas',
    dias: 'dias',
    perguntasEducador: 'Perguntas Educador',
    perguntasPlataforma: 'Perguntas Plataforma',
    progressoDaTurma: 'Progresso da Turma',
    media: 'Média',
    naoHaNotificacoes: 'Não há notificações',
    marcarTodasComoLidas: 'Marcar todas como lidas',
    maisRecentes: 'Mais recentes',
    mostrarApenasNaoLidas: 'Mostrar apenas não lidas',
    guiaRapido: 'Guia Rápido',
    irParaCentralDeAjuda: 'Ir para Central de Ajuda',
    inicieOTourExplicativoDessaTela: 'Inicie o tour explicativo dessa tela',
    desejaIniciarOTour: 'Deseja iniciar o tour',
    meuPerfil: 'Meu Perfil',
    mensagemErroSalvarAlteracoes:'Houve um problema ao salvar as alterações. Por favor, verifique sua conexão e tente novamente.',
    informacoesPessoais: 'Informações Pessoais',
    contato: 'Contato',
    seguranca: 'Segurança',
    autenticacaoDeDoisFatores: 'Autenticação de dois fatores',
    alterarSenha: 'Alterar Senha',
    mensagemAlteracaoSenha: 'Sua senha foi alterada com sucesso. Por favor, faça o login novamente para continuar acessando sua conta com a nova senha.',
    mensagemErroSenhaAtual: 'A senha atual inserida não corresponde à senha correta.',
    mensagemErroAlteracaoSenha :'Houve um problema ao alterar a senha. Por favor, verifique sua conexão e tente novamente.',
    senhaAtual: 'Senha atual',
    mensagemErroSenhaAtualObrigatorio: 'Por favor, digite sua senha atual!',
    mensagemErroNovaSenhaObrigatorio: 'Por favor, digite sua nova senha!',
    mensagemErroSenhaMinimoCaracteres: 'Senha deve ter pelo menos 12 caracteres',
    mensagemErroSenhaCaracteresEspeciais: 'Senha deve conter pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
    confirmarSenha: 'Confirmar senha',
    mensagemErroSenhaConfirmacao: 'Por favor, confirme sua senha!',
    mensagemErroSenhasDiferentes: 'Ops, as senhas não são iguais!',
    confirmarAlteracao: 'Confirmar Alteração',
    blogDeNovidades: 'Blog de Novidades',
    criarEstudante: 'Criar estudante',
    criarAtividade: 'Criar atividade',
    camposDeConhecimentosEHabilidades: 'Campos de Conhecimentos e Habilidades',
    oQueAchouDasNovidades: 'O que achou das novidades?',
    paraNosAjudarAMelhorarPorFavorExpliqueOMotivoDaSuaAvaliacao: 'Para nos ajudar a melhorar, por favor, explique o motivo da sua avaliação:',
    expliqueOMotivoDaSuaAvaliacaoComOMaximoDeDetalhesPossivel: 'Explique o motivo da sua avaliação com o máximo de detalhes possível',
    leiaMais: 'Leia mais',
    sejaOProtagonistaDaSuaHistoriaConecteSeComU4hero: 'Seja o protagonista da sua história, conecte-se com U4hero!',
    mensagemErroSenha: 'Por favor, digite sua senha!',
    esqueceuSuaSenha: 'Esqueceu sua senha? Clique aqui!',
    mensagemErroEmailSenha: 'Ops! Parece que o e-mail e/ou a senha inseridos estão incorretos.',
    cadastroRealizadoComSucesso: 'Seu cadastro foi realizado com sucesso!',
    erroAoSalvarAcessoRapido: 'Erro ao salvar acesso rápido',
    acessoRapido: 'Acesso Rápido',
    nomeOuApelido: 'Nome ou Apelido',
    mensagemErroNomeOuApelido: 'Por favor, informe seu nome ou apelido!',
    emailNaoExistente: 'Email não existente',
    emailEnviado: 'E-mail enviado!',
    mensagemErroEmailSenhaInstrucoes: 'Enviamos um e-mail com as instruções para você criar uma nova senha!',
    esqueceuSuaSenhaPergunta: 'Esqueceu sua senha?',
    mensagemRecuperarSenhaInstrucoes: 'Não se preocupe! Por favor, insira seu e-mail cadastrado na U4hero e enviaremos instruções para criar uma nova senha.',
    linkExpirado: 'Link Expirado',
    mensagemLinkExpirado: 'O tempo para ativar seu cadastro expirou. Se você ainda deseja completá-lo, por favor, entre em contato com o administrador da sua escola para gerar um novo link de ativação.',
    mensagemLinkExpiradoCadastro: 'O link para finalizar seu cadastro expirou, parece que você já concluiu o cadastro anteriormente.Por favor, entre em contato conosco se precisar de ajuda para acessar sua conta.',
    entendi: 'Entendi',
    mensagemCadastroQuasePronto: 'Você está quase pronto para começar a sua jornada com a U4hero!',
    mensagemErroNome: 'Por favor, digite seu nome!',
    placeholderNome: 'Digite seu nome aqui',
    senhaRedefinida: 'Senha Redefinida!',
    mensagemSenhaRedefinida: 'Ótimo! Sua senha foi redefinida com sucesso. Agora você pode acessar sua conta com sua nova senha.',
    redefinaSuaSenha: 'Redefina sua senha!',
    mensagemRedefinirSenhaInstrucoes: 'Por favor, crie uma nova senha seguindo as diretrizes fornecidas abaixo',
    permissaoPara: 'Permissão para',
    recursoExcluidoComSucesso: 'Recurso excluído com sucesso!',
    erroAoExcluirRecurso: 'Houve um problema ao excluir o recurso. Verifique sua conexão e tente novamente.',
    recurso: 'Recurso',
    temCertezaQueDesejaExcluirORecurso: 'Tem certeza que deseja excluir o recurso',
    placeholderNomeRecurso: 'Insira o nome do recurso',
    placeholderDescricaoRecurso: 'Crie um breve descrição do recurso',
    mensagemErroDescricao: 'Por favor, crie uma descrição',
    alteracoesSalvas: 'Alterações salvas',
    instituicoes: 'Instituições',
    ultimo7Dias: 'Últimos 7 dias',
    ultimo15Dias: 'Últimos 15 dias',
    ultimo30Dias: 'Últimos 30 dias',
    ultimoAno: 'Último ano',
    todoPeriodo: 'Todo período',
    materiais: 'Materiais',
    erroMateriais: 'Por favor, descreva os materiais necessários para realizar a prática pedagógica!',
    instrucoes: 'Instruções',
    guiaDeInclusao: 'Guia de Inclusão',
    erroGuiaDeInclusao: 'Por favor, faça um guia de inclusão!',
    erroInstrucoes: 'Por favor, escreva o passo a passo da inspiração!',
    habilidade: 'Habilidade',
    reClass: 'Refazer a classificação automática',
    pontosDeObservacao: 'Pontos de Observação',
    orientacoesParaInclusao: 'Orientações para Inclusão',
    anexos: 'Anexos',
    codigoBNCC: 'Código BNCC',
    componenteCurricular: 'Componente Curricular',
    trilhaSugerida: 'Trilha Sugerida',
    previstoParaDurar: 'Previsto para durar',
    primeira: 'Primeira',
    segunda: 'Segunda',
    terceira: 'Terceira',
    quarta: 'Quarta',
    quinta: 'Quinta',
    mensagemErroFeedbackAlternativaPergunta:'Por favor, crie o feedback da alternativa!',
    mensagemErroIdiomaPergunta:'Por favor, defina o idioma da pergunta!',
    idiomaDaPergunta:'Idioma da Pergunta',
    Work:'Trabalho',
    scenario:'Cenário',
    object:'Objeto',
    character:'Personagem',
    sucessReClass: 'Mensagem enviada para reclassificação com sucesso!'
  }
});