import { useMutation } from "react-query";
import http from "infra/http";
import { UserForm } from "../typings/form";

export function useUpdateCurrentUser() {
  return useMutation(
    (data: UserForm) => {
      return http.put(`/v1/user/current`, data);
    },
    { retry: false }
  );
}
