import { useMediaQuery } from '@chakra-ui/react';
import { DatePicker, Form, Radio, Space } from 'antd';
import { t } from 'core/resources/strings';

function TimeInput({
  index,
  updateForm,
}: {
  index: number;
  updateForm: (
    fieldName: string | number | (string | number)[],
    value: any
  ) => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const form = Form.useFormInstance();
  const graph = Form.useWatch(['graphs', index], form);
  if (graph?.graphView !== 'time') {
    return null;
  }
  return (
    <>
      <Form.Item
        rules={[
          {
            required: true,
            message: t('app.mensagemErroPeriodo'),
          },
        ]}
        name={[index, 'timePeriod']}
        label={t('app.periodo')}
      >
        <Radio.Group id="id-tour-my-indicators-custom-panel-time-input">
          <Space direction={isMobile ? 'vertical' : 'horizontal'}>
            <Radio value={'month'}>{t('app.mes')}</Radio>
            <Radio value={'bimester'}>{t('app.bimestre')}</Radio>
            <Radio value={'trimester'}>{t('app.trimestre')}</Radio>
            <Radio value={'semester'}>{t('app.semestre')}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
}

export default TimeInput;
