import { useMediaQuery } from '@chakra-ui/react';
import { Col, Empty, Flex, Row } from 'antd';
import PlusButton from 'components/plus-button';
import SvgIcon from 'components/SvgIcon';
import { UsePaths } from 'core/features/metrics/typings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { StyledBox } from '../../pages/MetricsSchoolPage/styles';

function Competences({
  tags,
  action,
}: {
  tags?: UsePaths[];
  action: () => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px');
  const { colorPrimary } = useU4heroColors();

  return (
    <StyledBox isMobile={isMobile} size={'lg'}>
      {tags && tags.length > 0 ? (
        <PlusButton
          onClick={action}
          style={{ position: 'absolute', top: 8, right: 8 }}
        />
      ) : null}

      <p
        style={{
          fontSize: 18,
          fontWeight: 500,
          textAlign: 'start',
          color: colorPrimary,
          alignSelf: 'self-start',
        }}
      >
        Competências mais trabalhadas
      </p>
      <Row justify="space-between">
        {tags?.map((competence, index) => (
          <Col
            style={{
              borderWidth:
                index === 1 && isMobile
                  ? '1px 0px 1px 0px'
                  : index === 1
                  ? '0px 1px 0px 1px'
                  : '0px',
              borderColor: '#C4C4C4',
              borderStyle: 'solid',
            }}
            span={isMobile ? 24 : 24 / tags.length}
            key={competence.id}
          >
            <Flex
              style={{ padding: '8px' }}
              vertical
              justify="center"
              gap={16}
              align="center"
            >
              {competence.icon ? (
                <SvgIcon url={competence.icon} size={50} color={'black'} />
              ) : null}
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  textAlign: 'center',
                }}
              >
                {competence.name}
              </p>
            </Flex>
          </Col>
        ))}
      </Row>
      {!tags || tags?.length === 0 ? <Empty /> : null}
    </StyledBox>
  );
}

export default Competences;
