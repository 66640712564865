import {  Modal, Flex } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import useQuestionHistory from 'core/features/questions/hooks/useQuestionHistory';
import MovementsCard from '../movementCard';
import HistoricCard from '../HistoricCards';
import Button from 'components/buttons/button';
import { t } from 'core/resources/strings';

function HistoricModal({ onClose, id }: { onClose: () => void; id: string }) {
  const { colorPrimary } = useU4heroColors();
  const { data } = useQuestionHistory({ questionId: id });

  const findPreviousData = (index: number) => {
    if(index > data?.data.length!){
      return undefined;
    }
    for (let i = index; i <= data?.data?.length!; i++) {
      let previousData = data?.data[i];
      if (previousData?.type !== 'Enable' && previousData?.type !== 'Disable') {
        return previousData;
      }
    }
    return undefined;
  };
  return (
    <Modal
      footer={[
        <Flex align="center" justify="center">
          <Button size='md'  onClick={onClose}>
            {t('app.fechar')}
          </Button>
        </Flex>,
      ]}
      title={
        <p style={{ color: colorPrimary, fontWeight: 700, fontSize: '24px' }}>
          {t('app.historico')}
        </p>
      }
      open={true}
      onCancel={onClose}
    >
      <Flex vertical gap={16}>
        <Flex>
          <MovementsCard numberOfMovements={data?.total ?? 0} />
        </Flex>
        {data?.data.map((historic, index) => (
          <HistoricCard
            data={historic}
            previousData={findPreviousData(index + 1)}
          />
        ))}
      </Flex>
    </Modal>
  );
}

export default HistoricModal;
