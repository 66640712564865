import { useQuery } from 'react-query'
import axios from 'infra/http'
import { QuestionsDetails } from '../types/QuestionsDetails'
import { useEffect, useState } from 'react';

export interface UseQuestionsParams {
  page?: number
  tags?: string[]
  limit?: number
  search?: string
  tenantId?: string
  types?: string[]
  analysisTypes?: string[]
  environments?: string[]
  scope?: string[]
  minAge?: number
  maxAge?: number
  active?:boolean
}

function useQuestions(params: UseQuestionsParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchQuestions = async (
    params: UseQuestionsParams
  ): Promise<{ data: QuestionsDetails[]; total: number }> => {
    const { data, headers } = await axios.get<QuestionsDetails[]>(
      `/v2/questions`,
      {
        params: {
          page: params.page,
          search: debouncedQuery,
          tenantId: params.tenantId,
          limit: params.limit,
          tags: params.tags,
          types: params.types,
          analysisTypes: params.analysisTypes,
          environments: params.environments,
          scope: params.scope,
          minAge: params.minAge,
          maxAge: params.maxAge,
          active:params.active
        },
      }
    )

    return { data: data, total: Number(headers['total']) }
  }

  return useQuery(
    [
      `/v2/questions`,
      { ...params, search: debouncedQuery },
    ],
    () => fetchQuestions(params),
    {
      keepPreviousData: true,
    }
  )
}

export default useQuestions
