import { Table as TableAntd, Typography } from 'antd';
import 'core/resources/theme/styles.css';
import { Pluralizar } from 'infra/helpers/plural';
import { useMediaQuery } from '@chakra-ui/react';
import { TableRowSelection } from 'antd/es/table/interface';
const { Text } = Typography;
function Table<T = any>({
  data,
  columns,
  onChangePage,
  onChangePageSize,
  total,
  page,
  pageSize,
  itemName,
  isLoading,
  onClickRow,
  title,
  showHeader = true,
  showTotal = true,
  shadow = true,
  id,
  rowSelection,
  rowKey,
  noMargin = false,
  className,
  expandable,
}: {
  data: any[];
  columns: any[];
  onChangePage: (v: number) => void;
  onChangePageSize?: (v: number) => void;
  total: number;
  page: number;
  pageSize: number;
  itemName: string;
  isLoading?: boolean;
  onClickRow?: (item: T) => void;
  title?: any;
  showHeader?: boolean;
  showTotal?: boolean;
  shadow?: boolean;
  id?: string;
  rowSelection?: TableRowSelection<T>;
  rowKey?: any;
  noMargin?: boolean;
  className?:string;
  expandable?: any;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <TableAntd
      id={id}
      style={{ marginBottom: noMargin ? '' : isMobile ? '32px' : '65px' }}
      title={title}
      loading={isLoading}
      rowSelection={rowSelection}
      rowKey={rowKey}
      columns={columns}
      dataSource={data}
      showHeader={showHeader}
      expandable={expandable}
      className={shadow ? `box-shadow-generic-card ${className}`:className}
      rowClassName={onClickRow ? 'curso-pointer' : ''}
      showSorterTooltip={false}
      onRow={(record) => ({
        onClick: () => {
          if (onClickRow) {
            onClickRow(record);
          }
        },
      })}
      scroll={{ x: 'auto' }}
      pagination={{
        showSizeChanger: onChangePageSize ? true : false,
        position: ['bottomCenter'],
        total: total,
        pageSize: pageSize,
        onChange: (page, size) => {
          onChangePage(page);
          onChangePageSize && onChangePageSize(size);
        },
        current: page,
        showLessItems: true,
        showTotal: (total) =>
          showTotal ? (
            <Text>
              {total} {Pluralizar(itemName, total > 1)}
            </Text>
          ) : null,
      }}
    />
  );
}

export default Table;
