import { useQuery } from "react-query";
import axios from "infra/http";
import { SerieDetails } from "../typings";

function useSerie(id?: string) {
  const fetchData = async (id: string): Promise<SerieDetails> => {
    const { data } = await axios.get<SerieDetails>(`/v2/serie/${id}`);

    return data;
  };

  return useQuery([`/v2/serie/${id}`], () => fetchData(id!), {
    enabled: !!id,
  });
}

export default useSerie;
