import { ConfigProvider } from 'antd';
import antPtBR from 'antd/lib/locale/pt_BR';
import { borderColor } from 'core/resources/theme/colors';
import { useCurrentUser } from 'core/features/user/store';
import { useMediaQuery } from '@chakra-ui/media-query';
import { hex2rgba } from 'infra/helpers/hex2Rgba';

function AntdProvider({ children }: any) {
  const { user } = useCurrentUser();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <ConfigProvider
      theme={{
        token: {
          colorInfo: '#9A50E6',
          colorLink:
            user?.whiteLabel && user?.colorPrimary
              ? user.colorPrimary
              : '#7743B9',
          colorPrimary:
            user?.whiteLabel && user?.colorPrimary
              ? user.colorPrimary
              : '#7743B9',
          colorBgTextHover:
            user?.whiteLabel && user?.colorPrimaryLight
              ? user.colorPrimaryLight
              : '#EFE8F6',
          fontFamily: 'Poppins,sans-serif',
        },
        components: {
          Tooltip: {
            colorBgSpotlight:
              user?.whiteLabel && user?.colorPrimary
                ? user.colorPrimary
                : '#7743B9',
          },
          Badge: {
            dotSize: 6,
          },
          Table: {
            borderColor: borderColor,
            borderRadiusLG: 20,
          },
          Pagination: {
            borderRadius: 8,
            lineWidth: 2,
          },
          DatePicker: {
            borderRadius: 10,
            colorTextPlaceholder: 'black',
          },
          Card: {
            borderRadiusLG: 20,
          },
          Switch: {
            colorPrimary:
              user?.whiteLabel && user?.colorSecondary
                ? user?.colorSecondary
                : '#ED810F',
            colorPrimaryBorder:
              user?.whiteLabel && user?.colorSecondary
                ? user?.colorSecondary
                : '#ED810F',
            colorPrimaryHover:
              user?.whiteLabel && user?.colorSecondary
                ? user?.colorSecondary
                : '#ED810F',
          },
          Progress: {
            defaultColor:
              user?.whiteLabel && user?.colorPrimary
                ? user.colorPrimary
                : '#7743B9',
          },
          Carousel: {
            colorBgContainer:
              user?.whiteLabel && user.colorPrimaryMedium
                ? user.colorPrimaryMedium
                : '#9A50E6',
          },
          Popover: {
            colorBgElevated:
              user?.whiteLabel && user?.colorPrimary
                ? user.colorPrimary
                : '#7743B9',
          },
          Form: {
            labelFontSize: isMobile ? 14 : 18,
          },
          Input: {
            activeBorderColor:
              user?.whiteLabel && user?.colorPrimary
                ? user.colorPrimary
                : '#7743B9',
          },
          Slider: {
            railSize: 12,
            dotSize: 10,
            handleSize: 12,
          },
          Radio: {
            fontSize: isMobile ? 14 : 18,
          },
          Checkbox: {
            fontSize: isMobile ? 14 : 18,
          },
        },
      }}
      locale={antPtBR}
    >
      {children}
    </ConfigProvider>
  );
}

export default AntdProvider;
