import { useQuery } from 'react-query';
import axios from 'infra/http';
import { SymptomsDetails } from '../typings';
import { useEffect, useState } from 'react';

export interface UseSymptomsParams {
  page:number;
  tenantId?:string;
  initialDate?: string;
  finalDate?: string;
  search?: string;
}

function useSymptoms(params: UseSymptomsParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchData = async (
    params: UseSymptomsParams
  ): Promise<{ data: SymptomsDetails[]; total:number }> => {
    const { data ,headers } = await axios.get<SymptomsDetails[]>(
      `/v2/inkluzen/symptoms`,
      {
        params: {
          page:params.page,
          tenantId:params.tenantId,
          initialDate: params.initialDate,
          finalDate: params.finalDate,
          search: debouncedQuery,
        },
      }
    );

    return { data: data, total: Number(headers["total"]) };
  };

  return useQuery(
    [
      `/v2/inkluzen/symptoms`,
      {...params,search:debouncedQuery},
    ],
    () => fetchData(params),
    {
      keepPreviousData: true,
      enabled:params.tenantId !== undefined
    }
  );
}

export default useSymptoms;
