import { Flex } from 'antd';
import Text from 'components/typography/text';
import lumiWithLogo from 'assets/images/lumi-with-logo.png';
import element1 from 'assets/images/elemento_01.png';
import element2 from 'assets/images/elemento_02.png';
import { t } from 'core/resources/strings';

function StudenKeyItem({
  school,
  name,
  license,
}: {
  school: string;
  name: string;
  license: string;
}) {
  return (
    <Flex vertical gap={8} style={{ position: 'relative' }}>
      <Flex justify="space-between">
        <img src={lumiWithLogo} style={{ filter: 'grayscale(100%)' }} />
        <Text size="sm">
          <strong>{t('app.escola')}: </strong>
          {school}
        </Text>
        <img src={element2} style={{ filter: 'grayscale(100%)' }} />
      </Flex>
      <img
        src={element1}
        style={{
          filter: 'grayscale(100%)',
          position: 'absolute',
          bottom: 0,
          left: 0,
        }}
      />
      <div style={{ paddingLeft: 12, paddingRight: 16, paddingBottom: 10 }}>
        <Text style={{ fontSize: 12 }}>
          {t('app.mensagemBoasVindasAluno')}
          <ol style={{ paddingLeft: 12 }}>
            <li>
              {t('app.passo1Bilhete')}
              <p style={{ textDecoration: 'underline' }}>play.u4hero.com.</p>
            </li>
            <li>{t('app.passo2Bilhete')}</li>
          </ol>
        </Text>
        <Flex justify="space-between">
          <Text size="sm">
            <strong>{t('app.nome')}: </strong>
            {name}
          </Text>
          <Text size="sm">
            <strong>{t('app.chave')}: </strong>
            {license.toUpperCase()}
          </Text>
        </Flex>
        <Text style={{ fontSize: 12 }}>
          {t('app.prepareSeParaUmaExperienciaIncrível')}
          <strong>U4Hero!</strong>
        </Text>
        <Text style={{ fontSize: 10 }} align="end">
          {t('app.qualquerDuvidaMandeUmEmailPara')}:
        </Text>
        <Text style={{ fontSize: 10 }} align="end">
          atendimento@u4hero.com
        </Text>
      </div>
    </Flex>
  );
}

export default StudenKeyItem;
