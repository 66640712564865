import { Button } from "antd";
import { white } from "core/resources/theme/colors";
import useU4heroColors from "infra/packages/antd/u4heroTokens";
import { GoTrash } from "react-icons/go";

interface ITrashCanProps {
  onClick?: () => void;
}

function TrashCan({ onClick }: ITrashCanProps) {
  const { colorPrimary } = useU4heroColors();

  return (
    <Button style={
      {
        backgroundColor: colorPrimary,
        width: '36px', 
        height: '36px',
        borderRadius: '10px',
        padding:'8px'
      }
    } 
    icon={<GoTrash style={{color:white, fontWeight:600, fontSize:'16px'}} />} 
    onClick={onClick}
    />
    
  )
}

export default TrashCan