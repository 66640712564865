import { useState } from 'react';
import { Button, Col, Divider, Flex, Image, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import BookIcon from 'assets/images/icons/BookIcon';
import MortarBoardIcon from 'assets/images/icons/MortarBoardIcon';
import VerifiedAccountIcon from 'assets/images/icons/VerifiedAccountIcon';
import BrickIcon from 'assets/images/icons/BrickIcon';
import GraphIcon from 'assets/images/icons/GraphIcon';
import ToolsIcon from 'assets/images/icons/ToolsIcon';
import Table from 'components/V2/table';
import { ActivityDetails } from 'core/features/activity/types';
import moment from 'moment';
import PuzzleIcon from 'assets/images/icons/PuzzleIcon';
import DocumentsIcon from 'assets/images/icons/DocumentsIcon';
import SentIcon from 'assets/images/icons/SentIcon';
import { useMediaQuery } from '@chakra-ui/media-query';
import { StyledBox, StyledCard, StyledContainer } from './styles';
import PlusButton from 'components/plus-button';
import ClockIcon from 'assets/images/icons/ClockIcon';
import InfoBox from '../../components/InfoBox';
import CommentIcon from 'assets/images/icons/CommentIcon';
import useMetricsSchool from 'core/features/metrics/hooks/useMetricsSchool';
import Competences from '../../components/Competences';
import useActivitiesBySchool from 'core/features/activity/hooks/useActivitiesBySchool';
import ModalActivityProgress from 'components/modals/modal-activity-progress';
import ModalGameMetrics from 'components/modals/modal-game-metrics';
import DatePicker from 'components/date-picker';
import ModalPathMetrics from 'components/modals/modal-path-metrics';
import useMetricsCollaborator from 'core/features/metrics/hooks/useMetricsCollaborator';
import SelectCollaboratorsMetrics from 'components/inputs/select-collaborator-metrics';
import ModalCommentMetrics from 'components/modals/modal-comment-metrics';
import { EyeOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Layout from 'components/layout';

function MetricsSchoolPage() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [activityId, setActivityId] = useState('');
  const [collaboratorId, setCollaboratorId] = useState<string>();
  const [isOpenProgressModal, setIsOpenProgressModal] = useState(false);
  const [isOpenGamesModal, setIsOpenGamesModal] = useState(false);
  const [isOpenPathModal, setIsOpenPathModal] = useState(false);
  const [isOpenCommentModal, setIsOpenCommentModal] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [dateFilterMetrics, setDateFilterMetrics] = useState<any>();
  const [dateFilterActivities, setDateFilterActivities] = useState<any>();
  const { colorPrimary } = useU4heroColors();

  const params = useParams<{ id: string }>();

  const {
    data: activities,
    isLoading: isLoadingActivities,
    isFetching,
  } = useActivitiesBySchool({
    page,
    tenantId: params.id,
    limit: pageSize,
    collaboratorId: collaboratorId ?? undefined,
    startDate: dateFilterActivities
      ? moment(new Date(dateFilterActivities[0])).format('YYYY-MM-DD')
      : undefined,
    endDate: dateFilterActivities
      ? moment(new Date(dateFilterActivities[1])).format('YYYY-MM-DD')
      : undefined,
  });
  const { data } = useMetricsSchool({
    tenantId: params.id,
    startDate: dateFilterMetrics
      ? moment(new Date(dateFilterMetrics[0])).format('YYYY-MM-DD')
      : undefined,
    endDate: dateFilterMetrics
      ? moment(new Date(dateFilterMetrics[1])).format('YYYY-MM-DD')
      : undefined,
  });

  const { data: dataCollaborator } = useMetricsCollaborator({
    collaboratorId: collaboratorId,
    startDate: dateFilterMetrics
      ? moment(new Date(dateFilterMetrics[0])).format('YYYY-MM-DD')
      : undefined,
    endDate: dateFilterMetrics
      ? moment(new Date(dateFilterMetrics[1])).format('YYYY-MM-DD')
      : undefined,
  });

  const mostUsedGame = dataCollaborator
    ? dataCollaborator.mostUsedGame
    : data?.mostUsedGame;

  const isEachLoading = isFetching || isLoadingActivities;

  return (
    <Layout.Container>
      <StyledContainer isMobile={isMobile}>
        {isOpenProgressModal ? (
          <ModalActivityProgress
            onClose={() => setIsOpenProgressModal(false)}
            id={activityId}
          />
        ) : null}
        {isOpenGamesModal ? (
          <ModalGameMetrics
            onClose={() => setIsOpenGamesModal(false)}
            collaboratorId={collaboratorId}
            startDate={
              dateFilterMetrics
                ? moment(new Date(dateFilterMetrics[0])).format('YYYY-MM-DD')
                : undefined
            }
            endDate={
              dateFilterMetrics
                ? moment(new Date(dateFilterMetrics[1])).format('YYYY-MM-DD')
                : undefined
            }
          />
        ) : null}
        {isOpenPathModal ? (
          <ModalPathMetrics
            onClose={() => setIsOpenPathModal(false)}
            collaboratorId={collaboratorId}
            startDate={
              dateFilterMetrics
                ? moment(new Date(dateFilterMetrics[0])).format('YYYY-MM-DD')
                : undefined
            }
            endDate={
              dateFilterMetrics
                ? moment(new Date(dateFilterMetrics[1])).format('YYYY-MM-DD')
                : undefined
            }
          />
        ) : null}
        {isOpenCommentModal ? (
          <ModalCommentMetrics
            onClose={() => setIsOpenCommentModal(false)}
            collaboratorId={collaboratorId}
            startDate={
              dateFilterMetrics
                ? moment(new Date(dateFilterMetrics[0])).format('YYYY-MM-DD')
                : undefined
            }
            endDate={
              dateFilterMetrics
                ? moment(new Date(dateFilterMetrics[1])).format('YYYY-MM-DD')
                : undefined
            }
          />
        ) : null}
        <Button
          type="primary"
          size="large"
          icon={<ArrowLeftOutlined />}
          onClick={() => history.push('/metrics')}
        />
        <div style={{ marginTop: isMobile ? '0px' : '32px' }}>
          <Row justify="center" gutter={[88, 0]}>
            <Col flex="auto" style={{ marginTop: '40px' }}>
              <Flex vertical gap={16}>
                <StyledCard isMobile={isMobile}>
                  <Flex vertical gap={8}>
                    <p
                      style={{
                        fontSize: isMobile ? 18 : 20,
                        color: colorPrimary,
                        fontWeight: 700,
                      }}
                    >
                      Escola:
                      <span
                        style={{
                          fontSize: isMobile ? 18 : 20,
                          color: 'black',
                          fontWeight: 400,
                        }}
                      >
                        {data?.schoolName}
                      </span>
                    </p>
                    {dataCollaborator ? (
                      <p
                        style={{
                          fontSize: isMobile ? 18 : 20,
                          color: colorPrimary,
                          fontWeight: 700,
                          maxWidth: 302,
                        }}
                      >
                        Educador:
                        <span
                          style={{
                            fontSize: isMobile ? 18 : 20,
                            color: 'black',
                            fontWeight: 400,
                          }}
                        >
                          {dataCollaborator?.name}
                        </span>
                      </p>
                    ) : null}
                  </Flex>

                  {!isMobile ? (
                    <Image
                      preview={false}
                      src="/lumi-lendo.png"
                      style={{
                        maxHeight: 210,
                        maxWidth: 230,
                        marginTop: '-120px',
                      }}
                    />
                  ) : null}
                </StyledCard>
                <Flex
                  justify="flex-end"
                  style={{ marginTop: isMobile ? 8 : 42 }}
                >
                  <DatePicker
                    value={dateFilterActivities}
                    onChange={(v) => setDateFilterActivities(v)}
                  />
                </Flex>

                {isMobile ? (
                  <Table<ActivityDetails>
                    itemName="atividade"
                    data={activities?.data ?? []}
                    onChangePage={setPage}
                    onChangePageSize={setPageSize}
                    title={() => (
                      <p
                        style={{
                          fontWeight: 700,
                          fontSize: '18px',
                          color: colorPrimary,
                        }}
                      >
                        Rota de aprendizado
                      </p>
                    )}
                    total={activities?.total ?? 0}
                    page={page}
                    pageSize={pageSize}
                    isLoading={isEachLoading}
                    columns={[
                      {
                        title: <p style={{ fontSize: '16px' }}>Trilha</p>,
                        dataIndex: 'tag',
                        key: 'tag',
                        render: (tag: string, record: ActivityDetails) => (
                          <p style={{ fontSize: '14px' }}>{record.tag.name}</p>
                        ),
                      },
                      {
                        title: '',
                        key: 'action',
                        render: (_: string, record: ActivityDetails) => (
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              setActivityId(record.id);
                              setIsOpenProgressModal(true);
                            }}
                          ></Button>
                        ),
                      },
                    ]}
                  />
                ) : (
                  <Table<ActivityDetails>
                    itemName="atividade"
                    data={activities?.data ?? []}
                    onChangePage={setPage}
                    onChangePageSize={setPageSize}
                    title={() => (
                      <p
                        style={{
                          fontWeight: 700,
                          fontSize: '18px',
                          color: colorPrimary,
                        }}
                      >
                        Rota de aprendizado
                      </p>
                    )}
                    total={activities?.total ?? 0}
                    page={page}
                    pageSize={pageSize}
                    isLoading={isEachLoading}
                    columns={[
                      {
                        title: <p style={{ fontSize: '16px' }}>Trilha</p>,
                        dataIndex: 'tag',
                        key: 'tag',
                        render: (tag: string, record: ActivityDetails) => (
                          <p style={{ fontSize: '14px' }}>{record.tag.name}</p>
                        ),
                      },
                      {
                        title: <p style={{ fontSize: '16px' }}>Inicio</p>,
                        dataIndex: 'publishDate',
                        key: 'publishDate',
                        render: (_: string, record: ActivityDetails) => (
                          <p style={{ fontSize: '14px' }}>
                            {moment
                              .utc(record.publishDate)
                              .format('DD/MM/YYYY')}
                          </p>
                        ),
                      },
                      {
                        title: <p style={{ fontSize: '16px' }}>Término</p>,
                        dataIndex: 'endDate',
                        key: 'endDate',
                        render: (_: string, record: ActivityDetails) => (
                          <p style={{ fontSize: '14px' }}>
                            {moment.utc(record.endDate).format('DD/MM/YYYY')}
                          </p>
                        ),
                      },
                      {
                        title: (
                          <p style={{ fontSize: '16px' }}>Jogo Escolhido</p>
                        ),
                        key: 'game',
                        dataIndex: 'game',
                        render: (_: string, record: ActivityDetails) => (
                          <p style={{ fontSize: '14px' }}>{record.game.name}</p>
                        ),
                      },
                      {
                        title: '',
                        key: 'action',
                        render: (_: string, record: ActivityDetails) => (
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              setActivityId(record.id);
                              setIsOpenProgressModal(true);
                            }}
                          ></Button>
                        ),
                      },
                    ]}
                  />
                )}
              </Flex>
            </Col>
            <Col flex="680px">
              <Row justify="end" style={{ marginBottom: '14px' }}>
                <Flex
                  vertical={isMobile ? true : false}
                  gap={isMobile ? '14px' : '24px'}
                  justify="flex-end"
                  align={isMobile ? 'flex-end' : 'baseline'}
                >
                  <SelectCollaboratorsMetrics
                    value={collaboratorId}
                    onChange={(v) => setCollaboratorId(v)}
                    tenantId={params.id}
                    startDate={
                      dateFilterMetrics
                        ? moment(new Date(dateFilterMetrics[0])).format(
                            'YYYY-MM-DD'
                          )
                        : undefined
                    }
                    endDate={
                      dateFilterMetrics
                        ? moment(new Date(dateFilterMetrics[1])).format(
                            'YYYY-MM-DD'
                          )
                        : undefined
                    }
                  />
                  <DatePicker
                    value={dateFilterMetrics}
                    onChange={(v) => setDateFilterMetrics(v)}
                  />
                </Flex>
              </Row>
              <Row align="middle" gutter={isMobile ? [8, 12] : [24, 30]}>
                {!collaboratorId ? (
                  <Col span={isMobile ? 12 : 8}>
                    <InfoBox
                      icon={<BookIcon />}
                      title="Total de Educadores"
                      info={data?.collaborators}
                    />
                  </Col>
                ) : (
                  <Col span={isMobile ? 12 : 8}>
                    <InfoBox
                      icon={<ClockIcon />}
                      title="Tempo Online"
                      info="??"
                    />
                  </Col>
                )}

                <Col span={isMobile ? 12 : 8}>
                  <InfoBox
                    icon={<MortarBoardIcon />}
                    title="Total de Estudantes"
                    info={
                      dataCollaborator
                        ? dataCollaborator.students
                        : data?.students
                    }
                  />
                </Col>
                {!collaboratorId ? (
                  <Col span={isMobile ? 12 : 8}>
                    <InfoBox
                      icon={<VerifiedAccountIcon />}
                      title="Acessos Ativos"
                      info={data?.activeStudents}
                    />
                  </Col>
                ) : null}

                <Col span={isMobile ? 12 : 8}>
                  <InfoBox
                    icon={<BrickIcon />}
                    title="Atividades Criadas"
                    info={
                      dataCollaborator
                        ? dataCollaborator.countActivities
                        : data?.countActivities
                    }
                  />
                </Col>
                <Col span={isMobile ? 12 : 8}>
                  <InfoBox
                    icon={<GraphIcon />}
                    title="Frequência"
                    info="Em breve"
                  />
                </Col>
                <Col span={isMobile ? 12 : 8}>
                  <InfoBox
                    icon={<ToolsIcon />}
                    title="Função mais usada"
                    info="Em breve"
                  />
                </Col>
                <Col span={isMobile ? 12 : 8}>
                  <InfoBox
                    icon={<PuzzleIcon />}
                    title="Mais Jogado"
                    info={mostUsedGame}
                    button={mostUsedGame !== ''}
                    action={() => setIsOpenGamesModal(true)}
                  />
                </Col>
                <Col span={isMobile || collaboratorId ? 24 : 16}>
                  <StyledBox
                    isMobile={isMobile}
                    size={collaboratorId ? 'lg' : 'md'}
                  >
                    {dataCollaborator?.comments ? (
                      <PlusButton
                        style={{ position: 'absolute', top: 8, right: 8 }}
                        onClick={() => setIsOpenCommentModal(true)}
                      />
                    ) : null}

                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 500,
                        textAlign: 'start',
                        color: colorPrimary,
                        alignSelf: 'self-start',
                      }}
                    >
                      Relatórios
                    </p>
                    <Flex
                      vertical={isMobile}
                      justify="center"
                      align="center"
                      gap={isMobile ? 16 : 32}
                      style={{ padding: '0px 8px' }}
                    >
                      <Flex vertical justify="center" gap={8} align="center">
                        <DocumentsIcon />
                        <p style={{ fontSize: '16px', fontWeight: 500 }}>
                          Emitidos
                        </p>
                        <p
                          style={{
                            fontSize: 24,
                            fontWeight: 600,
                            textAlign: 'center',
                          }}
                        >
                          ??
                        </p>
                      </Flex>
                      <Divider
                        type={isMobile ? 'horizontal' : 'vertical'}
                        style={{
                          height: isMobile ? '1px' : '106px',
                          width: isMobile ? '106px' : '1px',
                          backgroundColor: '#C4C4C4',
                        }}
                      />
                      <Flex vertical justify="center" gap={8} align="center">
                        <SentIcon />
                        <p style={{ fontSize: '16px', fontWeight: 500 }}>
                          Enviados
                        </p>
                        <p
                          style={{
                            fontSize: 24,
                            fontWeight: 600,
                            textAlign: 'center',
                          }}
                        >
                          ??
                        </p>
                      </Flex>
                      {!collaboratorId ? null : (
                        <>
                          {' '}
                          <Divider
                            type={isMobile ? 'horizontal' : 'vertical'}
                            style={{
                              height: isMobile ? '1px' : '106px',
                              width: isMobile ? '106px' : '1px',
                              backgroundColor: '#C4C4C4',
                            }}
                          />
                          <Flex
                            vertical
                            justify="center"
                            gap={8}
                            align="center"
                          >
                            <CommentIcon />
                            <p style={{ fontSize: '16px', fontWeight: 500 }}>
                              Comentados
                            </p>
                            <p
                              style={{
                                fontSize: 24,
                                fontWeight: 600,
                                textAlign: 'center',
                              }}
                            >
                              {dataCollaborator?.comments}
                            </p>
                          </Flex>
                        </>
                      )}
                    </Flex>
                  </StyledBox>
                </Col>
                <Col span={24}>
                  <Competences
                    tags={
                      dataCollaborator
                        ? dataCollaborator.mostUsedCompetences
                        : data?.mostUsedCompetences
                    }
                    action={() => setIsOpenPathModal(true)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </StyledContainer>
    </Layout.Container>
  );
}

export default MetricsSchoolPage;
