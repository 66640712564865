import { useQuery } from "react-query";
import axios from "infra/http";
import { StudentsComments } from "../typings";

export interface UseMetricsCommentsParams {
  collaboratorId?:string;
  startDate?:string;
  endDate?:string;
}

function useMetricsCommentsCollaborator(params:UseMetricsCommentsParams) {
  const fetchData = async (params:UseMetricsCommentsParams): Promise<StudentsComments[]> => {
    const { data } = await axios.get<StudentsComments[]>(`/v2/metrics/comments`,{
      params:{
        collaboratorId: params.collaboratorId,
        startDate: params.startDate,
        endDate: params.endDate,
      }
    });

    return data;
  };

  return useQuery([`/v2/metrics/comments`], () => fetchData(params), {
    enabled: params.collaboratorId !== null,
  });
}

export default useMetricsCommentsCollaborator;
