import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Row } from 'antd';
import Button from 'components/buttons/button';
import { Graph } from 'core/features/indicators-profile/typings';
import { notificateSucess, showConfirm } from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import ModalAddIndicators from '../../components/modal-add-indicators';
import CustomGraph from '../components/customGraph';
import TotalClasses from '../components/totalClasses';
import TotalEducators from '../components/totalEducators';
import TotalSchools from '../components/totalSchools';
import TotalStudents from '../components/totalStudents';
import { v4 as uuidv4 } from 'uuid';
import { t } from 'core/resources/strings';

function OrganizationView() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [graphs, setGraphs] = useState<Graph[]>([]);
  const [graphIndex, setGraphIndex] = useState<number>();

  const { colorPrimary } = useU4heroColors();

  const onRemoveIndicator = ({
    label,
    value,
  }: {
    label: string;
    value: any;
  }) => {
    showConfirm({
      message: `${t('app.temCertezaQueDesejaExcluirOIndicador')} “${label}”?`,
      colorPrimary,
      isMobile,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        setGraphs(graphs.filter((item) => item.id !== value));
        notificateSucess(`${t('app.indicadorExcluidoComSucesso')}`);
      }
    });
  };

  return (
    <Flex vertical gap={16}>
      {isOpen ? (
        <ModalAddIndicators
          mode="organization"
          graphIndex={graphIndex}
          graphs={graphs}
          setGraphs={setGraphs}
          onClose={() => setIsOpen(false)}
        />
      ) : null}
      <>
        <Row gutter={isMobile ? [16, 8] : [47, 0]} justify="space-between">
          <Col sm={12} md={6} style={{ width: '50%' }}>
            <TotalStudents />
          </Col>
          <Col sm={12} md={6} style={{ width: '50%' }}>
            <TotalClasses />
          </Col>
          <Col sm={12} md={6} style={{ width: '50%' }}>
            <TotalEducators />
          </Col>
          <Col sm={12} md={6} style={{ width: '50%' }}>
            <TotalSchools />
          </Col>
        </Row>
        <Row align="stretch" gutter={isMobile ? [0, 16] : [32, 0]}>
          {/* <Col sm={24} md={12} style={{ width: '100%' }}>
            <StudentFrequencyGraph />
          </Col> */}
        </Row>
        <Row align="stretch" gutter={[40, isMobile ? 16 : 20]}>
          {graphs.map((graph, index) => (
            <Col key={index} span={isMobile ? 24 : 12}>
              <CustomGraph
                graph={graph}
                onDuplicate={() => {
                  setIsOpen(true);
                  const newGraphId = uuidv4();
                  const newIndex = graphs.length;
                  setGraphs([...graphs, { ...graph, id: newGraphId }]);
                  setGraphIndex(newIndex);
                  notificateSucess(`${t('app.graficoDuplicadoComSucesso')}`);
                }}
                onEdit={() => {
                  setIsOpen(true);
                  setGraphIndex(index);
                }}
                onRemove={() => {
                  onRemoveIndicator({
                    label: graph.name,
                    value: graph.id,
                  });
                }}
              />
            </Col>
          ))}
        </Row>
      </>

      <Button
        size="md"
        style={{ alignSelf: 'center' }}
        onClick={() => setIsOpen(true)}
      >
        {t('app.maisGraficos')}
      </Button>
    </Flex>
  );
}

export default OrganizationView;
