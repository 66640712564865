import { useQuery } from "react-query";
import axios from "infra/http";

export interface UseMetricCollaboratorsParams {
  tenantId: string;
  startDate?:string;
  endDate?:string;
}
interface Users {
  id:string;
  name:string;
  countActivities:number
}
function useMetricsCollaborators(params: UseMetricCollaboratorsParams) {

  const fetchMetrics = async (
    params: UseMetricCollaboratorsParams
  ): Promise<{ data: Users[] }> => {
    const response = await axios.get<Users[]>(`/v2/metrics/users`, {
      params: {
        tenantId: params.tenantId,
        startDate: params.startDate,
        endDate: params.endDate,
     
      },
    });

    return { data: response.data};
  };

  return useQuery(
    [`/v2/metrics/users?tenantId=${params.tenantId}}&startDate=${params.startDate}&endDate=${params.endDate}`, params],
    () => fetchMetrics(params),
    {
      keepPreviousData: true,
    }
  );
}

export default useMetricsCollaborators;
