import { useMutation } from "react-query";
import http from "infra/http";

export function useRecover() {
  return useMutation(
    (data: { email: string }) => {
      return http.post(`/v1/auth/recover-password`, data);
    },
    { retry: false }
  );
}
