import { useQuery } from "react-query";
import axios from "infra/http"
import { AnalysisType } from "../types/QuestionsDetails";

function useAnalysisTypes() {
  return useQuery('/v2/questions/analysis', async():Promise<AnalysisType[]> => {
    const { data } = await axios.get('/v2/questions/analysis');

    return data;
  });
}

export default useAnalysisTypes;