import useU4heroColors from "infra/packages/antd/u4heroTokens";
import { Flex } from "antd";

function Tag({ competence }: { competence: string }) {
  const { colorPrimary, colorPrimaryLight } = useU4heroColors();

  return (
    <Flex
      style={{
        backgroundColor: colorPrimaryLight,
        width: 'fit-content',
        padding: "4px",
        borderRadius: "5px",
      }}
    >
      <p style={{ color: colorPrimary, fontSize: "10px", fontWeight: 700 }}>
        {competence}
      </p>
    </Flex>
  );
}

export default Tag;
