import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Row, Select } from 'antd';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import AcademicPerformance from './academicPerfomance';
import AgeGradeDistortion from './ageGradeDistortion';
import Ideb from './ideb/ideb';
import ProperLearning from './properLearning';
import SchoolInfra from './schoolInfra';

function Qedu() {
  const { colorGreyBorderAndFont } = useU4heroColors();
  return (
    <Flex vertical style={{ width: '100%' }} gap={16} id="id-tour-section-qedu">
      <div>
        <SubTitle primary>Sua Escola Baseada nos Dados do IDEB</SubTitle>
        <Text size="sm" align="end" color={colorGreyBorderAndFont}>
          Fonte: QEDU
        </Text>
      </div>
      <Row gutter={[32, 32]} align="stretch">
        <Col md={6} xs={24}>
          <Ideb />
        </Col>
        <Col md={18} xs={24}>
          <ProperLearning/>
        </Col>
        <Col md={6} xs={24}>
         <AgeGradeDistortion/>
        </Col>

        <Col md={18} xs={24}>
         <AcademicPerformance/>
        </Col>

        <Col span={24}>
        <SchoolInfra/>
        </Col>
      </Row>
    </Flex>
  );
}

export default Qedu;
