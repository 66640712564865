import { useQuery } from 'react-query';
import axios from 'infra/http';
import { HighestRatesDetails } from '../typings';
import { useEffect, useState } from 'react';

export interface UseRegionalStatsParams {
  tenantId?: string;
  initialDate?: string;
  finalDate?: string;
  search?: string;
  gravity?: string;
  method?: string;
}

function useRegionalStats(params: UseRegionalStatsParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);
  
  const fetchData = async (
    params: UseRegionalStatsParams
  ): Promise<{ data: HighestRatesDetails[] }> => {
    const { data } = await axios.get<HighestRatesDetails[]>(
      `/v2/inkluzen/region`,
      {
        params: {
          tenantId: params.tenantId,
          initialDate: params.initialDate,
          finalDate: params.finalDate,
          search: debouncedQuery,
          gravity: params.gravity,
          method: params.method,
        },
      }
    );

    return { data: data };
  };

  return useQuery(
    [
      `/v2/inkluzen/region`,
      {...params,search:debouncedQuery},
    ],
    () => fetchData(params),
    {
      keepPreviousData: true,
      enabled: params.tenantId !== undefined,
    }
  );
}

export default useRegionalStats;
