import { useQuery } from 'react-query';
import axios from 'infra/http';
import { StudentItem } from '../typings';
import { useEffect, useState } from 'react';

export interface UseStudentsParams {
  page: number;
  limit?: number;
  search?: string;
  classId?: string;
  disableConcat?: boolean;
  disableQuery?: boolean;
  series?: string[];
  tenantId?: string;
  deleted?: boolean;
}

function useStudents(params: UseStudentsParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchStudents = async (
    params: UseStudentsParams
  ): Promise<{ data: StudentItem[]; total: number }> => {
    const { data, headers } = await axios.get<StudentItem[]>(`/v1/student`, {
      params: {
        page: params.page,
        limit: params.limit,
        search: debouncedQuery,
        classId: params.classId,
        series: params.series,
        tenantId: params.tenantId,
        deleted: params.deleted,
      },
    });

    return { data: data, total: Number(headers['total']) };
  };

  return useQuery(
    [
      `/v1/student`,
      {...params,search:debouncedQuery},
    ],
    () => fetchStudents(params),
    {
      keepPreviousData: true,
      enabled: !params.disableQuery,
    }
  );
}

export default useStudents;
