import { useMediaQuery } from '@chakra-ui/media-query';
import { Modal, Flex } from 'antd';
import useQuestion from 'core/features/questions/hooks/useQuestion';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import ViewCard from '../viewCard';
import { t } from 'core/resources/strings';
import { useHistory } from 'react-router';
import Button from 'components/buttons/button';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { Permissions } from 'core/resources/enums/permissions';
import { useCurrentUser } from 'core/features/user/store';
import { QuestionInfo } from '../../historic/HistoricCards';

function ViewModal({ onClose, id }: { onClose: () => void; id: string }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const { data } = useQuestion(id);
  const allScopes: { [key: number]: string } = {
    0: t('app.privada'),
    1: t('app.publica'),
    2: t('app.global'),
  };
  const questionTypes: { [key: number]: string } = {
    0: t('app.atividade'),
    1: t('app.avaliacaoInicial'),
    2: t('app.avaliacao'),
    3: t('app.situacional'),
  };

  const history = useHistory();
  const ordinal = ['Primeira', 'Segunda', 'Terceira', 'Quarta', 'Quinta'];
  const getComprehensionLevel = (value: number | undefined) => {
    let response;
    switch (true) {
      case value === 0:
        response = t('app.muitoBaixa');
        break;
      case value && value <= 25:
        response = t('app.baixa');
        break;
      case value && value <= 50:
        response = t('app.moderada');
        break;
      case value && value <= 75:
        response = t('app.boa');
        break;
      case value && value <= 100:
        response = t('app.muitoBoa');
        break;
      default:
        response = t('app.invalido');
    }

    return response;
  };
  const [check] = useCheckPermissions();
  const { user } = useCurrentUser();

  const notAllowedToEdit =
    (data?.privacyId === 2 && !check([Permissions.U4hero.Questions.Edit])) ||
    (data?.author?.id !== user?.id &&
      !check([Permissions.CustomQuestion.EditAll]) &&
      data?.privacyId !== 2) ||
    !check([
      Permissions.CustomQuestion.Edit,
      Permissions.U4hero.Questions.Edit,
    ]);
  return (
    <Modal
      footer={[
        <Flex align="center" justify="center">
          <Button size="md" onClick={() => onClose()}>
            {t('app.fechar')}
          </Button>
        </Flex>,
      ]}
      title={
        <p style={{ color: colorPrimary, fontWeight: 700, fontSize: '24px' }}>
          {t('app.modoVisualizacao')}
        </p>
      }
      open={true}
      onCancel={() => onClose()}
    >
      <Flex vertical gap={16}>
        <Flex>
          <ViewCard />
        </Flex>
        <Flex gap={5} style={{ padding: '10px' }} vertical>
          <QuestionInfo
            label={t('app.competencia')}
            content={data?.competence?.name ?? ''}
          />
          <QuestionInfo label="Habilidade" content={data?.skill.name ?? ''} />
          <QuestionInfo
            label={t('app.tipoAnalise')}
            content={t(`app.${data?.analysisType.name}`) ?? ''}
          />
          <QuestionInfo
            label={t('app.ambienteAnalise')}
            content={t(`app.${data?.analysisEnvironment.name}`) ?? ''}
          />
          <QuestionInfo
            label={t('app.faixaEtaria')}
            content={`${data?.minAge ?? ''} - ${data?.maxAge ?? ''}`}
          />
          <QuestionInfo label={t('app.enunciado')} content={data?.text ?? ''} />

          <Flex vertical>
            <p
              style={{
                fontSize: isMobile ? '14px' : '18px',
                color: 'black',
                fontWeight: 400,
              }}
            >
              {t('app.dublagem')}
            </p>
            <audio
              style={{
                width: '100%',
                backgroundColor: 'transparent',
                minHeight: '40px',
                borderRadius: '8px',
              }}
              controls
              src={data?.audio}
              preload={'auto'}
            >
              Your browser does not support the
              <code>audio</code> element.
            </audio>
          </Flex>

          {data?.options.map((option, index: number) => (
            <Flex vertical>
              <QuestionInfo
                label={`${ordinal[index]} alternativa`}
                content={option.text}
              />
              <Flex vertical>
                <p
                  style={{
                    fontSize: isMobile ? '14px' : '18px',
                    color: 'black',
                    fontWeight: 400,
                  }}
                >
                  {t('app.dublagem')}
                </p>
                <audio
                  style={{ width: '100%' }}
                  controls
                  src={option.audio}
                  preload={'auto'}
                >
                  Your browser does not support the
                  <code>audio</code> element.
                </audio>
              </Flex>
              <QuestionInfo
                label={t('app.grauDeCompreensao')}
                content={getComprehensionLevel(option.value)}
              />

              <QuestionInfo
                label={t('app.hipotese')}
                content={option.hypothesis}
              />

              <QuestionInfo
                label={t('app.sugestao')}
                content={option.orientation}
              />
            </Flex>
          ))}

          <QuestionInfo
            label={t('app.tipoPergunta')}
            content={questionTypes[data?.typeId ?? 0]}
          />
          <QuestionInfo
            label={t('app.escopo')}
            content={allScopes[data?.privacyId ?? 0]}
          />

          {!notAllowedToEdit && (
            <Button
              variant="outline"
              size="md"
              style={{ alignSelf: 'center' }}
              onClick={() => history.push(`/question/edit/${id}`)}
            >
              {t('app.editar')}
            </Button>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
}

export default ViewModal;
