import { Spinner, useMediaQuery } from '@chakra-ui/react';
import {
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
} from 'antd';
import SelectClass from 'components/inputs/select-class';
import SelectSerie from 'components/inputs/select-serie';
import { useSaveStudent } from 'core/features/students/hooks/useSaveStudent';
import useStudent from 'core/features/students/hooks/useStudent';
import { StudentForm } from 'core/features/students/typings/form';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import InputMask from 'react-input-mask';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import { useQueryClient } from 'react-query';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import { t } from 'core/resources/strings';

function ModalStudent({
  onClose,
  studentId,
  userId,
  tenantId,
}: {
  studentId?: string;
  userId?: string;
  onClose: () => void;
  tenantId?: string;
}) {
  const { data, isLoading } = useStudent(userId);
  const queryClient = useQueryClient();
  const { mutate: handleSave, isLoading: isLoadingSave } =
    useSaveStudent(studentId);
  const [form] = Form.useForm();
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const _handleSave = (values: StudentForm) => {
    if (!values.classId) {
      values.classId = null;
    }
    handleSave(
      { ...values, tenantId },
      {
        onSuccess: () => {
          notificateSucess(
            `${
              userId
                ? t('app.cadastroAtualizado')
                : t('app.estudanteCadastrado')
            } ${t('app.comSucesso')}`
          );
          queryClient.invalidateQueries('/v2/student');
          onClose();
        },
        onError: (err: any) => {
          notificateError(t('app.mensagemErroCadastro'));
        },
      }
    );
  };
  const serie = Form.useWatch('serie', form);

  useEffect(() => {
    if (serie && serie?.id !== data?.serie?.id) {
      form.setFieldsValue({
        class: {
          id: undefined,
        },
      });
    }
  }, [serie]);

  return (
    <Modal
      title={<SubTitle primary>{t('app.cadastro')}</SubTitle>}
      open={true}
      onCancel={() => {
        if (form.isFieldsTouched()) {
          openNotification({ onClose, colorPrimary, isMobile });
        } else {
          onClose();
        }
      }}
      footer={null}
      width={isMobile ? 288 : 416}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Form
        id="student-creation-modal"
        onFinish={(ev) => _handleSave(ev)}
        validateTrigger="onSubmit"
        layout="vertical"
        requiredMark={'optional'}
        form={form}
        initialValues={data || undefined}
      >
        <p
          style={{
            color: 'black',
            fontSize: isMobile ? '16px' : '18px',
            fontWeight: 400,
          }}
        >
          {t('app.informacoesDoEstudante')}
        </p>

        <Divider style={{ background: '#262626', margin: '5px 0' }} />
        {isLoadingSave ? <Spinner /> : null}
        <Form.Item
          label={t('app.matricula')}
          rules={[
            {
              required: true,
              message: t('app.mensagemErroMatricula'),
            },
          ]}
          name="document"
        >
          <Input
            id="id-tour-my-school-student-modal-input-registration"
            style={{
              width: '130px',
            }}
          />
        </Form.Item>

        <Form.Item
          label={t('app.nomeCompleto')}
          name="name"
          rules={[
            {
              required: true,
              message: t('app.mensagemErroNomeCompleto'),
            },
          ]}
        >
          <Input id="id-tour-my-school-student-modal-input-name" />
        </Form.Item>

        <Form.Item
          label={t('app.dataDeNascimento')}
          rules={[
            {
              required: true,
              message: t('app.mensagemErroDataDeNascimento'),
            },
          ]}
          name="birthday"
        >
          <Input
            id="id-tour-my-school-student-modal-input-date-of-birth"
            type="date"
            style={{ width: '165px' }}
          />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: t('app.mensagemErroSexo'),
            },
          ]}
          name="gender"
        >
          <Radio.Group id="id-tour-my-school-student-modal-input-gender">
            <span style={{ fontSize: isMobile ? '14px' : '18px' }}>
              {t('app.sexo')}:&nbsp;
            </span>
            <Space direction="horizontal">
              <Radio value={'F'}>{t('app.feminino')}</Radio>
              <Radio value={'M'}>{t('app.masculino')}</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <p
          style={{
            color: 'black',
            fontSize: isMobile ? '16px' : '18px',
            fontWeight: 400,
          }}
        >
          {t('app.informacoesDoResponsavel')}
        </p>

        <Divider style={{ background: '#262626', margin: '5px 0' }} />

        <Form.Item label={t('app.nomeDoResponsavel1')} name="motherName">
          <Input id="id-tour-my-school-student-modal-input-responsible-name" />
        </Form.Item>

        <Form.Item
          label={t('app.telefone')}
          name="motherPhone"
          rules={[
            {
              validator: async (rule, value) => {
                if (value) {
                  const formattedValue = value.replace(/\D/g, '');
                  if (formattedValue.length < 10) {
                    throw new Error(t('app.mensagemErroTelefone'));
                  }
                }
              },
            },
          ]}
        >
          <InputMask mask="(99) 99999-9999" autoComplete="off">
            {() => (
              <Input
                id="id-tour-my-school-student-modal-input-responsible-phone"
                style={{ width: '140px' }}
              />
            )}
          </InputMask>
        </Form.Item>

        <Form.Item label={t('app.nomeDoResponsavel2')} name="fatherName">
          <Input id="id-tour-my-school-student-modal-input-responsible-2name" />
        </Form.Item>

        <Form.Item
          label={t('app.telefone')}
          name="fatherPhone"
          rules={[
            {
              validator: async (rule, value) => {
                if (value) {
                  const formattedValue = value.replace(/\D/g, '');
                  if (formattedValue.length < 10) {
                    throw new Error(t('app.mensagemErroTelefone'));
                  }
                }
              },
            },
          ]}
        >
          <InputMask mask="(99) 99999-9999" autoComplete="off">
            {() => <Input style={{ width: '140px' }} />}
          </InputMask>
        </Form.Item>
        <p
          style={{
            color: 'black',
            fontSize: isMobile ? '16px' : '18px',
            fontWeight: 400,
          }}
        >
          {t('app.informacoesAcademicas')}
        </p>

        <Divider style={{ background: '#262626', margin: '5px 0' }} />

        <Row>
          <Col span={12}>
            <Form.Item
              label={t('app.serie')}
              name={['serie', 'id']}
              fieldKey={['serie', 'id']}
            >
              {/* @ts-ignore */}
              <SelectSerie id="id-tour-my-school-student-modal-input-serie" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={t('app.turma')} name="classId" fieldKey="classId">
              {/* @ts-ignore */}
              <SelectClass
                id="id-tour-my-school-student-modal-input-class"
                tenantId={tenantId}
                disabled={serie?.id === undefined}
                serieId={serie?.id}
              />
            </Form.Item>
          </Col>
        </Row>

        <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
          <Button
            variant="outline"
            size="md"
            disabled={isLoading || isLoadingSave}
            onClick={() => {
              if (form.isFieldsTouched()) {
                openNotification({ onClose, colorPrimary, isMobile });
              } else {
                onClose();
              }
            }}
            id="id-tour-my-school-student-modal-button-cancel"
          >
            {t('app.cancelar')}
          </Button>

          <Button
            htmlType="submit"
            size="md"
            loading={isLoading || isLoadingSave}
            id="id-tour-my-school-student-modal-button-save"
          >
            {t('app.salvar')}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
}

export default ModalStudent;
