import { Flex } from 'antd';
import Button from 'components/buttons/button';
import ModalGame from 'components/modals/modal-game';
import { useState } from 'react';
import GamesFeedbackModal from 'ui/pages/app/games-u4hero/components/gamesFeedbackModal';
import GamesTable from '../../gamesTable';

function GamesTab() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalFeedback, setIsOpenModalFeedback] = useState(false);
  const [gameId, setGameId] = useState<string>();
  return (
    <Flex vertical gap="16px">
      {isOpenModal ? (
        <ModalGame
          id={gameId}
          onClose={() => {
            setIsOpenModal(false);
            setGameId(undefined);
          }}
        />
      ) : null}
      {isOpenModalFeedback ? (
        <GamesFeedbackModal
          id={gameId}
          onClose={() => {
            setIsOpenModalFeedback(false);
            setGameId(undefined);
          }}
        />
      ) : null}
      <Button
        id="id-tour-create-helper-button-add-helper"
        size={'md'}
        onClick={() => setIsOpenModal(true)}
        style={{
          alignSelf: 'end',
        }}
      >
        Criar jogo
      </Button>
      <GamesTable
        onViewFeedback={(id) => {
          setGameId(id);
          setIsOpenModalFeedback(true);
        }}
        onEdit={(v) => {
          setGameId(v);
          setIsOpenModal(true);
        }}
      />
    </Flex>
  );
}

export default GamesTab;
