import { ReactNode } from 'react';
import { Typography } from 'antd';
import { TypographyProps } from 'antd/es/typography/Typography';
import { useMediaQuery } from '@chakra-ui/media-query';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';

const { Title: AntdTitle } = Typography;

interface GenericTitleProps extends TypographyProps<'div'> {
  children: ReactNode;
  primary?: boolean; 
  align?: 'left' | 'center' | 'right' | 'justify' | 'end';
  color?:string;
}

function Title(props: GenericTitleProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const { children,primary,align,color,...rest } = props;

  const titleStyle = {
    margin: props.style?.margin ?? '0px',
    ...props.style, 
    color: color ? color: primary ? colorPrimary : props.style?.color,
    fontSize:props.style?.fontSize ? props.style.fontSize: isMobile ? '24px' : '28px',
    fontWeight: 700, 
    textAlign:props.style?.textAlign ? props.style.textAlign : align,
  };

  return <AntdTitle {...rest} style={titleStyle} level={1}>{children}</AntdTitle>;
}

export default Title;
