import { useMutation } from "react-query";
import http from "infra/http";
import { LoginForm } from "../typings";

export function useLogin() {
  return useMutation(
    (data: LoginForm) => {
      const body: any = data;
      delete body.savePassword;
      return http.post(`/v1/auth/login`, body);
    },
    { retry: false }
  );
}
