import { useMediaQuery } from '@chakra-ui/react';
import { Dropdown, Flex, Menu } from 'antd';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { Permissions } from 'core/resources/enums/permissions';
import moment from 'moment';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import DynamicTable from 'components/V2/dinamic-table';
import { FaEllipsisVertical } from 'react-icons/fa6';
import {
  notificateError,
  notificateSucess,
  showConfirm,
} from 'infra/helpers/notifications';
import usePractices from 'core/features/practices/hooks/usePractices';
import { PracticeDetails } from 'core/features/practices/typings';
import { useHistory } from 'react-router';
import { PiEyeLight, PiEyeSlash } from 'react-icons/pi';
import { useDeletePractice } from 'core/features/practices/hooks/useDeletePractice';
import PracticeFilter from '../../../PracticesSuggestions/components/PracticeFilter';
import { FilterType } from 'infra/helpers/types';
import { t } from 'core/resources/strings';

function PracticesTable({onViewFeedback}:{onViewFeedback:(v:string)=> void}) {
  const [check] = useCheckPermissions();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState<FilterType>({
    environments: [],
    modalities: [],
    durations: [],
    age: [],
    dificulties: [],
    tags: [],
    subjects: [],
    publishDate: [],
    authors: [],
    published: [],
  });
  const [search, setSearch] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const { data, isLoading, refetch, isFetching } = usePractices({
    page,
    limit: pageSize,
    environments: filters.environments.map((item) => item.value) ?? undefined,
    durations: filters.durations.map((item) => item.value) ?? undefined,
    dificulties: filters.dificulties.map((item) => item.value) ?? undefined,
    tags: filters.tags.map((item) => item.value) ?? undefined,
    subjects: filters.subjects.map((item) => item.value) ?? undefined,
    modalities: filters.modalities.map((item) => item.value) ?? undefined,
    age: filters?.age?.map((item) => item.value)[0] ?? undefined,
    publishDate: filters?.publishDate.map((item) => item.value)[0] ?? undefined,
    authors: filters.authors.map((item) => item.value) ?? undefined,
    published: filters.published.length
      ? filters.published.map((item) => item.value)[0] === 1
      : undefined,
  });

  const { mutate: handleDelete } = useDeletePractice();
  const [detailsVisibility, setDetailsVisibility] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const isEachLoading = isLoading || isFetching;
  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };
  const columns = [
    {
      title: <p style={tableHeaderStyle}>{t('app.titulo')}</p>,
      name: t('app.titulo'),
      dataIndex: 'title',
      key: 'title',
      mobile: true,
      render: (game: string, record: PracticeDetails) => (
        <div>
          <p style={tableRowStyle}>{record.title}</p>
          {detailsVisibility[record.id] && (
            <Flex vertical>
              <Flex>
                <p
                  style={{ fontSize: 14, color: colorPrimary, fontWeight: 600 }}
                >
                  {t('app.autor')}:{' '}
                  <span style={{ color: 'black', fontWeight: 400 }}>
                    {record.author}
                  </span>
                </p>
              </Flex>
              <Flex>
                <p
                  style={{ fontSize: 14, color: colorPrimary, fontWeight: 600 }}
                >
                  {t('app.tipo')}:{' '}
                  <span style={{ color: 'black', fontWeight: 400 }}>
                    {record.published ? 'Publicado' : 'Rascunho'}
                  </span>
                </p>
              </Flex>
              <Flex>
                <p
                  style={{ fontSize: 14, color: colorPrimary, fontWeight: 600 }}
                >
                  {t('app.data')}:
                  <span style={{ color: 'black', fontWeight: 400 }}>
                    {moment.utc(record.updatedAt).format('DD/MM/YYYY')}
                  </span>
                </p>
              </Flex>
            </Flex>
          )}
        </div>
      ),
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.autor')}</p>,
      name: t('app.autor'),
      dataIndex: 'author',
      key: 'author',
      render: (_: string, record: PracticeDetails) => (
        <p style={tableRowStyle}>{record.author}</p>
      ),
      sorter: (a: any, b: any) => a.author.localeCompare(b.author),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.tipo')}</p>,
      name: t('app.tipo'),
      dataIndex: 'published',
      key: 'published',
      render: (_: string, record: PracticeDetails) => (
        <p style={tableRowStyle}>
          {record.published === true ? t('app.publicado') : t('app.rascunho')}
        </p>
      ),
      sorter: (a: any, b: any) => {
        if (a.published === b.published) {
          return 0;
        }
        return a.published ? -1 : 1;
      },
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.dataPublicacao')}</p>,
      name: t('app.dataPublicacao'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_: string, record: PracticeDetails) => (
        <p style={tableRowStyle}>
          {moment.utc(record.createdAt).format('DD/MM/YYYY')}
        </p>
      ),
      sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.ultimaAtualizacao')}</p>,
      name: t('app.ultimaAtualizacao'),
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      render: (_: string, record: PracticeDetails) => (
        <p style={tableRowStyle}>
          {moment.utc(record.updatedAt).format('DD/MM/YYYY')}
        </p>
      ),
      sorter: (a: any, b: any) => a.updatedAt.localeCompare(b.updatedAt),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.acoes')}</p>,
      key: 'action',
      mobile: true,
      dataIndex: 'action',
      name: t('app.acoes'),
      render: (_: string, record: PracticeDetails) => (
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => {
                if (e.key === 'edit') {
                  history.push(`/practices/edit/${record.id}`);
                }
                if(e.key === 'feedback'){
                  onViewFeedback(record.id);
                }
                if (e.key === 'delete') {
                  showConfirm({
                    message: t('app.temCertezaQueDesejaExcluirAInspiracaoPedagogica'),
                    colorPrimary,
                    isMobile,
                  }).then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      handleDelete([record.id], {
                        onSuccess: () => {
                          notificateSucess(
                            t('app.inspiracaoPedagogicaExcluidaComSucesso')
                          );
                          refetch();
                        },
                        onError: () => {
                          notificateError(
                            t('app.erroAoExcluirInspiracaoPedagogica')
                          );
                        },
                      });
                    }
                  });
                }
              }}
            >
              {check([Permissions.U4hero.Practices.Edit]) ? (
                <Menu.Item key={'edit'}>{t('app.editar')}</Menu.Item>
              ) : null}

              {check([Permissions.U4hero.Practices.Delete]) ? (
                <Menu.Item key={'delete'}>{t('app.deletar')}</Menu.Item>
              ) : null}
              <Menu.Item key={'feedback'}>{t('app.feedbacks')}</Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <FaEllipsisVertical size={24} style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ];

  if (isMobile) {
    columns.splice(-1, 0, {
      title: <p style={tableHeaderStyle}>{t('app.detalhes')}</p>,
      key: 'details',
      mobile: true,
      dataIndex: 'details',
      name: t('app.detalhes'),
      render: (_: string, record: PracticeDetails) => (
        <div style={{ width: 'fit-content' }}>
          {detailsVisibility[record.id] ? (
            <PiEyeLight size={30} onClick={() => toggleDetails(record.id)} />
          ) : (
            <PiEyeSlash size={30} onClick={() => toggleDetails(record.id)} />
          )}
        </div>
      ),
    });
  }

  const toggleDetails = (recordId: string) => {
    setDetailsVisibility((prev) => ({
      ...prev,
      [recordId]: !prev[recordId],
    }));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: string[], selectedRows: PracticeDetails[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const onDelete = () => {
    showConfirm({
      message: t('app.temCertezaQueDesejaExcluirAsInspiracoesPedagogicas'),
      colorPrimary,
      isMobile,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        handleDelete(selectedRowKeys, {
          onSuccess: () => {
            notificateSucess(
              t('app.inspiracaoPedagogicaExcluidaComSucesso')
            );
            refetch();
          },
          onError: () => {
            notificateError(
              t('app.erroAoExcluirInspiracoesPedagogicas')
            );
          },
        });
      }
    });
  };
  useEffect(() => {
    setPage(1);
  }, [search, filters]);
  return (
    <DynamicTable
      id="id-tour-practices-manager-practices-table"
      itemName={t('app.inspiracao')}
      data={data?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title=""
      total={data?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isEachLoading}
      defaultColumns={
        isMobile ? columns.filter((item) => item.mobile) : columns
      }
      allColumns={columns}
      onSearch={setSearch}
      hideCog={true}
      selected={selectedRowKeys?.length > 0}
      onDelete={onDelete}
      rowSelection={{ preserveSelectedRowKeys: true, ...rowSelection }}
      appliedFilters={filters}
      onChangeFilters={setFilters}
      filters={
        <PracticeFilter filters={filters} onChangeFilters={setFilters} />
      }
    ></DynamicTable>
  );
}

export default PracticesTable;
