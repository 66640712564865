import useU4heroColors from "infra/packages/antd/u4heroTokens";
import { ReactElement } from 'react';
import { IconBaseProps } from '@ant-design/icons/lib/components/Icon';

function FilterButtonElement({
  name,
  value,
  onChange,
  state,
}: {
  name: string | ReactElement<IconBaseProps>;
  value: string;
  onChange: (v: string) => void;
  state: string;
}) {
  const {colorPrimary} = useU4heroColors();
  return (
    <button
      style={{
        padding: '4px 8px',
        borderRadius: '10px',
        background: state === value ? colorPrimary : undefined,
        color: state === value  ? 'white' : 'black',
        fontWeight: state === value ? 600:400
      }}
      onClick={()=> onChange(value)}
    >
      {name}
    </button>
  );
}

export default FilterButtonElement;
