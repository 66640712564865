import { Divider, Flex, Modal } from 'antd';
import Button from 'components/buttons/button';
import Status from 'components/status';
import { TenantDetails } from 'core/features/tenants/typings';
import { primary } from 'core/resources/theme/colors';
import moment from 'moment';
import './styles.css';
function ModalSchoolInfo({
  onClose,
  school,
}: {
  onClose: () => void;
  school?: TenantDetails;
}) {
  return (
    <Modal
      open={true}
      onCancel={onClose}
      footer={null}
      modalRender={(node) => (
        <div
          style={{
            backgroundImage: 'url("/background-metrics-modal-bottom.png"),url("/background-metrics-modal-top.png")',
            backgroundSize: '50%, 32%',
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundPosition: 'bottom left,top right',
            backgroundColor:'white',
          }}
          className='modal-with-background'
        >
          {node}
        </div>
      )}
      style={{
        minWidth: '95%',
        
      }}
      centered
      closeIcon={null}
    >
      
      <div
        style={{
          minWidth: '90%',
          height: 'auto',
          borderRadius: '20px',
          background: '#FFF',
          padding: '8px',
          boxShadow:
            '0px 3px 4px 0px rgba(0, 0, 0, 0.25), 0px -0.5px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Flex vertical gap={16} justify="center" align="center">
          <Flex gap={5}>
            <p style={{ fontSize: 16, color: primary, fontWeight: 700 }}>
              Escola:
            </p>
            <p style={{ fontSize: 16, color: 'black', fontWeight: 700 }}>
              {school?.name}
            </p>
          </Flex>
          <Flex gap={5}>
            <p style={{ fontSize: 16, color: primary, fontWeight: 700 }}>
              Endereço:
            </p>
            <p style={{ fontSize: 16, color: 'black', fontWeight: 700 }}>
              {' '}
              {school?.addressCity} - {school?.addressState}
            </p>
          </Flex>
          <Flex gap={5}>
            <p style={{ fontSize: 16, color: primary, fontWeight: 700 }}>
              Data da criação:
            </p>
            <p style={{ fontSize: 16, color: 'black', fontWeight: 700 }}>
              {' '}
              {moment.utc(school?.createdAt).format('DD/MM/YYYY')}
            </p>
          </Flex>
          <Flex gap={16} align="center">
            <p style={{ fontSize: 16, color: primary, fontWeight: 700 }}>
              Status:
            </p>
            <Status type={school?.active ? 'active':'inactive'} size='md' />
          </Flex>
        </Flex>

          <Divider style={{ margin: '10px 0' }} />
        <Flex vertical>
          <Button size='md' onClick={onClose}>Fechar</Button>
        </Flex>
      </div>
    </Modal>
  );
}

export default ModalSchoolInfo;
