import { useMutation } from "react-query";
import axios from "axios";
import { UserRegisterForm } from "../typings/form";

export function useRegisterUser() {
  return useMutation(
    (data: UserRegisterForm) => {
      return axios.put(`${process.env.REACT_APP_BASE_URL_API}v1/auth/pre-register`, data);
    },
    { retry: false }
  );
}
