import { useMediaQuery } from '@chakra-ui/media-query';
import { Col, Divider, Flex, Modal, Row, Spin, Table } from 'antd';
import AutographIcon from 'assets/images/icons/AutographIcon';
import CalendarIcon from 'assets/images/icons/CalendarIcon';
import ComputerIcon from 'assets/images/icons/ComputerIcon';
import HelpIcon from 'assets/images/icons/HelpIcon';
import PuzzleIcon from 'assets/images/icons/PuzzleIcon';
import Button from 'components/buttons/button';
import Status from 'components/status';
import SvgIcon from 'components/SvgIcon';
import useActivityInfo from 'core/features/activity/hooks/useActivityInfo';
import { t } from 'core/resources/strings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { MdExpandMore } from 'react-icons/md';
import './styles.css';

function ModalActivityProgress({
  onClose,
  id,
}: {
  onClose: () => void;
  id?: string;
}) {
  const { data, isLoading } = useActivityInfo(id);
  const [isMobile] = useMediaQuery('(max-width: 768px');
  const { colorPrimary } = useU4heroColors();
  return (
    <Modal
      open={true}
      onCancel={onClose}
      footer={null}
      modalRender={(node) => (
        <div
          style={{
            backgroundImage:
              'url("/background-metrics-modal-bottom.png"),url("/background-metrics-modal-top.png")',
            backgroundSize: '50%, 32%',
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundPosition: 'bottom left,top right',
            backgroundColor: 'white',
            // padding:isMobile ? '16px':'40px'
          }}
          className={'modal-with-background'}
        >
          {node}
        </div>
      )}
      width={'1044px'}
      closeIcon={null}
    >
      <div
        style={{
          height: 'auto',
          borderRadius: '20px',
          background: '#FFF',
          padding: isMobile ? '8px' : '16px 24px',
          boxShadow:
            '0px 3px 4px 0px rgba(0, 0, 0, 0.25), 0px -0.5px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        <p
          style={{
            color: colorPrimary,
            fontSize: isMobile ? '16px' : '20px',
            fontWeight: 700,
            marginBottom: isMobile ? '8px' : '16px',
          }}
        >
          {t('app.acompanhamentoDaAtividade')}
        </p>
        {!isLoading ? (
          <Row>
            <Col span={isMobile ? 24 : 8}>
              <Row justify="center" align="middle">
                <Col
                  span={12}
                  style={{
                    borderWidth: '0px 1px 1px 0px',
                    borderColor: '#C4C4C4',
                    borderStyle: 'solid',
                  }}
                >
                  <Flex
                    vertical
                    gap={8}
                    align="center"
                    justify="center"
                    style={{ minHeight: '137px' }}
                  >
                    {' '}
                    <p
                      style={{
                        color: colorPrimary,
                        fontSize: isMobile ? '16px' : '18px',
                        textAlign: 'center',
                        fontWeight: 500,
                      }}
                    >
                      {t('app.trilha')}
                    </p>
                    {data?.competence.icon ? (
                      <SvgIcon
                        url={data?.competence.icon}
                        size={isMobile ? 30 : 40}
                        color="black"
                      />
                    ) : null}
                    <p
                      style={{
                        color: 'black',
                        fontSize: isMobile ? '14px' : '18px',
                        textAlign: 'center',
                        fontWeight: 500,
                      }}
                    >
                      {data?.competence.name['pt-br']}
                    </p>
                  </Flex>
                </Col>
                <Col
                  span={12}
                  style={{
                    borderWidth: '0px 0px 1px 0px',
                    borderColor: '#C4C4C4',
                    borderStyle: 'solid',
                  }}
                >
                  <Flex
                    vertical
                    gap={8}
                    align="center"
                    justify="center"
                    style={{ minHeight: '137px' }}
                  >
                    {' '}
                    <p
                      style={{
                        color: colorPrimary,
                        fontSize: isMobile ? '16px' : '18px',
                        textAlign: 'center',
                        fontWeight: 500,
                      }}
                    >
                      {t('app.totalDePerguntas')}
                    </p>
                    <HelpIcon />
                    <p
                      style={{
                        color: 'black',
                        fontSize: isMobile ? '14px' : '18px',
                        textAlign: 'center',
                        fontWeight: 500,
                      }}
                    >
                      {data?.totalQuestions}
                    </p>
                  </Flex>
                </Col>
              </Row>
              <Row align="middle" justify="center">
                <Col
                  span={12}
                  style={{
                    borderWidth: '0px 1px 1px 0px',
                    borderColor: '#C4C4C4',
                    borderStyle: 'solid',
                  }}
                >
                  <Flex
                    vertical
                    gap={8}
                    align="center"
                    justify="center"
                    style={{ minHeight: '137px' }}
                  >
                    {' '}
                    <p
                      style={{
                        color: colorPrimary,
                        fontSize: isMobile ? '16px' : '18px',
                        textAlign: 'center',
                        fontWeight: 500,
                      }}
                    >
                      {t('app.prazo')}
                    </p>
                    <CalendarIcon size={isMobile ? 30 : 40} />
                    <p
                      style={{
                        color: 'black',
                        fontSize: isMobile ? '14px' : '18px',
                        textAlign: 'center',
                        fontWeight: 500,
                      }}
                    >
                      {data?.deadline} {t('app.dias')}
                    </p>
                  </Flex>
                </Col>
                <Col
                  span={12}
                  style={{
                    borderWidth: '0px 0px 1px 0px',
                    borderColor: '#C4C4C4',
                    borderStyle: 'solid',
                  }}
                >
                  <Flex
                    vertical
                    gap={8}
                    align="center"
                    justify="center"
                    style={{ minHeight: '137px' }}
                  >
                    {' '}
                    <p
                      style={{
                        color: colorPrimary,
                        fontSize: isMobile ? '16px' : '18px',
                        textAlign: 'center',
                        fontWeight: 500,
                      }}
                    >
                      {t('app.perguntasEducador')}
                    </p>
                    <AutographIcon size={isMobile ? 30 : 40} />
                    <p
                      style={{
                        color: 'black',
                        fontSize: isMobile ? '14px' : '18px',
                        textAlign: 'center',
                        fontWeight: 500,
                      }}
                    >
                      {data?.customQuestions}
                    </p>
                  </Flex>
                </Col>
              </Row>
              <Row align="middle" justify="center">
                <Col
                  span={12}
                  style={{
                    borderWidth: '0px 1px 1px 0px',
                    borderColor: '#C4C4C4',
                    borderStyle: 'solid',
                  }}
                >
                  <Flex
                    vertical
                    gap={8}
                    align="center"
                    justify="center"
                    style={{ minHeight: '137px' }}
                  >
                    {' '}
                    <p
                      style={{
                        color: colorPrimary,
                        fontSize: isMobile ? '16px' : '18px',
                        textAlign: 'center',
                        fontWeight: 500,
                      }}
                    >
                      {t('app.jogoEscolhido')}
                    </p>
                    <PuzzleIcon size={isMobile ? 30 : 40} />
                    <p
                      style={{
                        color: 'black',
                        fontSize: isMobile ? '14px' : '18px',
                        textAlign: 'center',
                        fontWeight: 500,
                      }}
                    >
                      {data?.game}
                    </p>
                  </Flex>
                </Col>
                <Col
                  span={12}
                  style={{
                    borderWidth: '0px 0px 1px 0px',
                    borderColor: '#C4C4C4',
                    borderStyle: 'solid',
                  }}
                >
                  <Flex
                    vertical
                    gap={8}
                    align="center"
                    justify="center"
                    style={{ minHeight: '137px' }}
                  >
                    {' '}
                    <p
                      style={{
                        color: colorPrimary,
                        fontSize: isMobile ? '14px' : '18px',
                        textAlign: 'center',
                        fontWeight: 500,
                      }}
                    >
                      {t('app.perguntasPlataforma')}
                    </p>
                    <ComputerIcon size={isMobile ? 30 : 40} />
                    <p
                      style={{
                        color: 'black',
                        fontSize: isMobile ? '14px' : '18px',
                        textAlign: 'center',
                        fontWeight: 500,
                      }}
                    >
                      {data?.questionsByU4hero}
                    </p>
                  </Flex>
                </Col>
              </Row>
            </Col>

            <Col span={isMobile ? 24 : 16} className="custom-table">
              {' '}
              <Table
                columns={[
                  {
                    title: (
                      <p style={{ fontSize: isMobile ? '16px' : '18px' }}>
                        {t('app.turmas')}
                      </p>
                    ),
                    dataIndex: 'name',
                    key: 'name',
                    render: (name: string, record: any) => (
                      <p style={{ fontSize: isMobile ? '16px' : '18px' }}>
                        {record.serieName} {name}
                      </p>
                    ),
                  },
                  {
                    title: (
                      <p style={{ fontSize: isMobile ? '16px' : '18px' }}>
                        {t('app.progressoDaTurma')}
                      </p>
                    ),
                    dataIndex: 'progress',
                    key: 'progress',
                    render: (progress: string) => (
                      <Status
                        size={isMobile ? 'sm' : 'md'}
                        type={
                          parseInt(progress) > 80
                            ? 'done'
                            : parseInt(progress) > 0 &&
                              parseInt(progress) < 80 &&
                              data &&
                              data?.deadline > 0
                            ? 'progress'
                            : data &&
                              data.deadline > 0 &&
                              parseInt(progress) === 0
                            ? 'notStarted'
                            : 'expired'
                        }
                      />
                    ),
                  },
                ]}
                expandable={{
                  expandedRowRender: (record) => (
                    <div style={{ margin: 0 }}>
                      {record.students.map((student) => (
                        <Flex
                          justify="space-between"
                          align="center"
                          style={{
                            borderBottom: '1px solid #C4C4C4',
                            padding: isMobile ? '0px' : '16px 32px',
                          }}
                        >
                          <p
                            style={{
                              fontSize: isMobile ? '14px' : '16px',
                              fontWeight: 600,
                              color: colorPrimary,
                              maxWidth: isMobile ? '90px' : '200px',
                            }}
                          >
                            {t('app.nome')}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
                            &nbsp;{' '}
                            {!isMobile ? (
                              <>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
                                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                &nbsp;
                              </>
                            ) : null}
                            <br />
                            <span
                              style={{
                                fontSize: isMobile ? '14px' : '16px',
                                fontWeight: 400,
                                color: 'black',
                              }}
                            >
                              {student.name}
                            </span>
                          </p>{' '}
                          <p
                            style={{
                              fontSize: isMobile ? '14px' : '16px',
                              fontWeight: 600,
                              color: colorPrimary,
                              alignSelf: 'center',
                              justifySelf: 'center',
                              maxWidth: isMobile ? '80px' : '100px',
                            }}
                          >
                            {t('app.progresso')}:
                            <br />
                            <span
                              style={{
                                fontSize: isMobile ? '14px' : '16px',
                                fontWeight: 400,
                                color: 'black',
                              }}
                            >
                              {student.answered}/{student.countQuestions}
                            </span>
                          </p>{' '}
                          <p
                            style={{
                              fontSize: isMobile ? '14px' : '16px',
                              fontWeight: 600,
                              color: colorPrimary,
                              maxWidth: isMobile ? '68px' : '80px',
                            }}
                          >
                            {t('app.media')}:
                            <br />
                            <span
                              style={{
                                fontSize: isMobile ? '14px' : '16px',
                                fontWeight: 600,
                                color: 'black',
                              }}
                            >
                              {student.progress}
                            </span>
                          </p>{' '}
                        </Flex>
                      ))}
                    </div>
                  ),
                  expandIcon: ({ expanded, onExpand, record }) =>
                    !expanded ? (
                      <MdExpandMore
                        onClick={(e: any) => onExpand(record, e)}
                        style={{
                          transform: 'rotate(270deg)',
                          cursor: 'pointer',
                        }}
                        size={20}
                      />
                    ) : (
                      <MdExpandMore
                        onClick={(e: any) => onExpand(record, e)}
                        size={20}
                        style={{ cursor: 'pointer' }}
                      />
                    ),
                }}
                dataSource={data?.classes ?? []}
                pagination={false}
                rowKey={(record: any) => record.id}
              />
            </Col>
          </Row>
        ) : (
          <Flex justify="center" align="center">
            <Spin size={'large'} />
          </Flex>
        )}

          <Divider style={{ margin: '10px 0' }} />
          <Flex justify='center'>
          <Button size='md' onClick={onClose} >{t('app.fechar')}</Button>
          </Flex>
      </div>
    </Modal>
  );
}

export default ModalActivityProgress;
