import { Input } from "@chakra-ui/input";
import { Box, Divider, Text } from "@chakra-ui/layout";
import { Flex, InputGroup, InputLeftElement, useMediaQuery } from "@chakra-ui/react";
import SelectSerie from "components/inputs/select-serie";
import { AiOutlineSearch } from "react-icons/ai";

function SearchSerieBar({
  onTextSearch,
  children,
  onSerieChange,
  valueSerie,
  id
}: {
  onTextSearch?: (v: string) => void;
  children?: any;
  onSerieChange?: (v: any) => void;
  valueSerie?: string;
  id?:string;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <Box shadow="lg" rounded="8px" bg="#f5f5fb" p="16px" width={isMobile ? '100vw':''} id={id}>
        <Flex justifyContent={"start"} direction={isMobile ? 'column' :'row'}>
          <Box>
            <Text>
              Nome
            </Text>
            <InputGroup width={"300px"}>

              <InputLeftElement
                pointerEvents='none'
                children={<AiOutlineSearch color='gray.300' />}
              />
              <Input
                onChange={(v) => onTextSearch && onTextSearch(v.target.value)}
                bg="white"
                placeholder='Pesquisar'
              />
            </InputGroup>
          </Box>

          <Box width={"200px"} ml={isMobile ? "0px":"15px"}>
            <Text>
              Serie
            </Text>
            <SelectSerie value={valueSerie} onChange={(v) => onSerieChange && onSerieChange(v)} />
          </Box>

        </Flex>

        {children ? <Divider /> : null}

        {children || null}
      </Box>
    </>
  );
}

export default SearchSerieBar;
