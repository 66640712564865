import { ButtonHTMLAttributes } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import classes from './style.module.css';
type Props = ButtonHTMLAttributes<HTMLButtonElement>;
function PlusButton(props: Props) {
  return (
    <button {...props}>
      <AiOutlinePlus className={classes.btn} size={25} />
    </button>
  );
}

export default PlusButton;
