import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import {
  Modal,
  Flex,
  Form,
  Input,
  Select,
  Popover,
  Radio,
  Space,
  Collapse,
} from 'antd';
import { useMediaQuery } from '@chakra-ui/media-query';
import CustomizedRequiredMark from 'components/required-mark';
import useProfiles from 'core/features/profile/hooks/useProfiles';
import { useCurrentUser } from 'core/features/user/store';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import { useSaveUser } from 'core/features/user/hooks/useSaveUser';
import { IoClose } from 'react-icons/io5';
import { UserSimpleForm } from 'core/features/user/typings/form';
import useUser from 'core/features/user/hooks/useUser';
import { useEffect } from 'react';
import UploadImagem from 'components/inputs/upload-imagem';
import { validateCpf } from 'infra/helpers/validateCpf';
import { TfiInfo } from 'react-icons/tfi';
import Text from 'components/typography/text';
import { useResendInvitation } from 'core/features/user/hooks/useResendInvitation';
import { BsSend } from 'react-icons/bs';
import { useQueryClient } from 'react-query';
import Button from 'components/buttons/button';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { Permissions } from 'core/resources/enums/permissions';
import { t } from 'core/resources/strings';

function CollaboratorModal({
  id,
  onClose,
  tenantId,
}: {
  onClose: () => void;
  id?: string;
  tenantId?: string;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { mutate: handleResend } = useResendInvitation();
  const queryClient = useQueryClient();
  const { user } = useCurrentUser();
  const { data: userData } = useUser(id);
  const { colorPrimary } = useU4heroColors();
  const { isLoading, data } = useProfiles({
    tenantId: tenantId ?? user?.tenantId,
  });
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveUser(id);
  const [form] = Form.useForm();
  const [check] = useCheckPermissions();
  const isU4Hero = check([Permissions.U4hero.Collaborators.ChangePassword]);

  useEffect(() => {
    form.resetFields();
  }, [userData, form]);

  const resend = () => {
    handleResend(
      { id: id },
      {
        onSuccess: () => {
          notificateSucess(t('app.eMailDeAcessoEnviadoComSucesso'));
        },
        onError: (err: any) => {
          notificateError(t('app.erroAoEnviarEmail'));
        },
      }
    );
  };

  const onSubmit = (values: UserSimpleForm) => {
    handleSave(
      {
        ...values,
        tenantId,
      },
      {
        onError: (err: any) => {
          if (err?.response?.data?.error === 'user have exists') {
            notificateError(t('app.colobadorJaExiste'));
          } else {
            notificateError(
              id
                ? t('app.erroAoSalvarAlteracoes')
                : t('app.erroAoCadastrarColaborador')
            );
          }
        },
        onSuccess: () => {
          notificateSucess(
            id
              ? t('app.alteracaoConcluidaComSucesso')
              : t('app.colaboradorCriadoComSucesso')
          );
          queryClient.invalidateQueries('/v2/users');
          onClose();
        },
      }
    );
  };
  return (
    <Modal
      title={
        <p style={{ color: colorPrimary, fontWeight: 700, fontSize: '24px' }}>
          {id ? t('app.editar') : t('app.criar')} {t('app.colaborador')}
        </p>
      }
      onCancel={() => {
        if (form.isFieldsTouched()) {
          openNotification({
            onClose: onClose,
            colorPrimary,
            isMobile,
          });
        } else {
          onClose();
        }
      }}
      footer={null}
      open={true}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        validateTrigger="onSubmit"
        initialValues={userData || undefined}
        requiredMark={CustomizedRequiredMark}
      >
        {id ? (
          <Form.Item
            name="imageUrl"
            rules={[
              {
                message: '',
              },
            ]}
          >
            {/* @ts-ignore */}
            <UploadImagem />
          </Form.Item>
        ) : null}

        <Form.Item
          label={id ? t('app.nomeCompleto') : t('app.nome')}
          rules={[
            {
              required: true,
              message: t('app.mensagemErroNomeColaborador'),
            },
          ]}
          name={'name'}
        >
          <Input
            id="id-tour-my-school-collaborator-modal-name-input"
            placeholder={
              id
                ? t('app.insiraONomeCompleto')
                : t('app.insiraONomeDoColaborador')
            }
          />
        </Form.Item>

        {id ? (
          <Form.Item
            label={t('app.dataDeNascimento')}
            rules={[
              {
                required: true,
                message: t('app.mensagemErroDataDeNascimento'),
              },
            ]}
            name="birthday"
          >
            <Input
              id="id-tour-my-school-modal-collaborator-input-date-of-birth"
              type="date"
            />
          </Form.Item>
        ) : null}

        <Form.Item
          label={
            id ? (
              <Flex gap={16} align="center">
                <Text>{t('app.email')}</Text>
                <Popover
                  trigger={isMobile ? 'click' : 'hover'}
                  content={
                    <div style={{ color: 'white', maxWidth: '310px' }}>
                      {t('app.mensagemAlteracaoEmail')}
                    </div>
                  }
                >
                  <Flex
                    align="center"
                    justify="center"
                    style={{
                      backgroundColor: colorPrimary,
                      borderRadius: '50px',
                      width: '18px',
                      height: '18px',
                      padding: '2px',
                    }}
                  >
                    <TfiInfo style={{ color: 'white', fontWeight: 900 }} />
                  </Flex>
                </Popover>
              </Flex>
            ) : (
              t('app.email')
            )
          }
          rules={[
            {
              required: true,
              message: t('app.mensagemErroEmail'),
            },
          ]}
          name={'email'}
        >
          <Input
            id="id-tour-my-school-collaborator-modal-email-input"
            placeholder={t('app.exemploEmail')}
            disabled={id !== undefined}
          />
        </Form.Item>

        {id ? (
          <Form.Item
            label={t('app.cpf')}
            rules={[
              {
                message: t('app.mensagemErroCpf'),
              },
              {
                validator: async (rule, value) => {
                  if (value) {
                    if (!validateCpf(value)) {
                      throw new Error('Cpf invalido!');
                    }
                  }
                },
              },
            ]}
            name="document"
          >
            <Input
              id="id-tour-my-school-modal-collaborator-input-document"
              disabled={id !== undefined}
            />
          </Form.Item>
        ) : null}

        <Form.Item
          label={t('app.perfil')}
          rules={[
            {
              required: true,
              message: t('app.mensagemErroPerfil'),
            },
          ]}
          name={'profiles'}
        >
          <Select
            id="id-tour-my-school-collaborator-modal-profile-input"
            mode="multiple"
            options={data?.map((profile) => ({
              value: profile.id,
              label: profile.name,
            }))}
            placeholder={t('app.selecioneOPerfilCorrespondente')}
          />
        </Form.Item>

        {id ? (
          <Form.Item name="gender">
            <Radio.Group id="id-tour-my-school-collaborator-modal-gender-input">
              <span style={{ fontSize: isMobile ? '14px' : '18px' }}>
                {t('app.sexo')}:&nbsp;
              </span>
              <Space direction="horizontal">
                <Radio value={'F'}>{t('app.feminino')}</Radio>
                <Radio value={'M'}>{t('app.masculino')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        ) : null}

        {isU4Hero && id ? (
          <Collapse expandIconPosition="right">
            <Collapse.Panel
              header={
                <div>
                  <Text size="lg">{t('app.alteracaoDeSenha')}</Text>
                </div>
              }
              key={'1'}
            >
              <Flex style={{ padding: '8px' }} vertical>
                <Form.Item label={t('app.novaSenha')} name={'password'}>
                  <Input
                    id="id-tour-my-school-collaborator-modal-password-input"
                    placeholder={t('app.insiraANovaSenha')}
                  />
                </Form.Item>
              </Flex>
            </Collapse.Panel>
          </Collapse>
        ) : null}
        {id ? (
          <Flex
            justify="end"
            align="center"
            gap="2px"
            onClick={resend}
            style={{
              cursor: 'pointer',
              marginBottom: '16px',
              marginTop: '16px',
            }}
            id="id-tour-my-school-collaborator-modal-button-resend-invitation"
          >
            <Text
              style={{
                textDecoration: 'underline',
                fontSize: isMobile ? '12px' : '14px',
              }}
            >
              {t('app.reenviarEmailDeAcesso')}
            </Text>
            <BsSend size={16} />
          </Flex>
        ) : null}
        <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
          <Button
            variant="outline"
            size="md"
            loading={isLoading || isLoadingSave}
            onClick={() => {
              if (form.isFieldsTouched()) {
                openNotification({
                  onClose: onClose,
                  colorPrimary,
                  isMobile,
                });
              } else {
                form.resetFields(undefined);
                onClose();
              }
            }}
            id="id-tour-create-collaborator-button-cancel"
          >
            {t('app.cancelar')}
          </Button>

          <Button
            htmlType="submit"
            size="md"
            loading={isLoadingSave}
            id="id-tour-create-collaborator-button-save"
          >
            {t('app.salvar')}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
}

export default CollaboratorModal;
