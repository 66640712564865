import { useQuery } from 'react-query'
import axios from 'infra/http'
import { PracticeDetails } from '../typings'
import { useEffect, useState } from 'react';

export interface UsePracticesParams {
  page: number;
  search?: string;
  authors?:string[];
  subjects?:string[];
  tags?:string[];
  dificulties?:string[];
  published?:boolean;
  publishDate?:string;
  limit?:number;
  age?:number;
  durations?:number[];
  modalities?:number[];
  environments?:number[];
 

}

function usePractices(params: UsePracticesParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchData = async (
    params: UsePracticesParams
  ): Promise<{ data: PracticeDetails[]; total: number }> => {
    const { data, headers } = await axios.get<PracticeDetails[]>(`/v2/practices/`, {
      params: {
        page: params.page,
        search: debouncedQuery,
        published:params.published,
        authors:params.authors,
        tags:params.tags,
        publishDate:params.publishDate,
        limit:params.limit,
        age:params.age,
        durations:params.durations,
        subjects:params.subjects,
        environments:params.environments,
        dificulties:params.dificulties,
        modalities:params.modalities,
      },
    })

    return { data: data, total: Number(headers['total']) }
  }

  return useQuery(
    [`/v2/practices`,{...params,search:debouncedQuery}],
    () => fetchData(params),
    {
      keepPreviousData: true,
    }
  )
}

export default usePractices
