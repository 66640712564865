import { useQuery } from 'react-query';
import axios from 'infra/http';
import { CompetenceDetails } from '../../questions/types/QuestionsDetails';
import { useEffect, useState } from 'react';
import { TagType } from 'core/features/competence/typings';

export interface UseTagsParams {
  tag?: string;
  competence?: boolean;
  skill?: boolean;
  search?: string;
  disableQuery?: boolean;
  type?: TagType;
  parentType?:TagType;
}

function useTagsCompetence(params: UseTagsParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchQuestions = async (
    params: UseTagsParams
  ): Promise<CompetenceDetails[]> => {
    const { data } = await axios.get<CompetenceDetails[]>(
      `/v2/tags/competence`,
      {
        params: {
          ...params,
          search: debouncedQuery,
        },
      }
    );

    return data;
  };

  return useQuery(
    [`/v2/tags/competence`, { ...params, search: debouncedQuery }],
    () => fetchQuestions(params),
    {
      keepPreviousData: true,
      enabled: !params.disableQuery,
    }
  );
}

export default useTagsCompetence;
