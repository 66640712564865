import { Flex, Spin, Tooltip } from 'antd';
import { ApexOptions } from 'apexcharts';
import SadIcon from 'components/icons/SadIcon';
import SubTitle from 'components/typography/subTitle';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useEmotionometerGraph from 'core/features/indicators/hooks/useEmotionometerGraph';
import { t } from 'core/resources/strings';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import { FiTrash2 } from 'react-icons/fi';

function EmotionalGraph({ onRemove }: { onRemove?: () => void }) {
  const { data, isLoading } = useEmotionometerGraph();

  const dates = [];
  for (let i = 0; i < 7; i++) {
    const dayOfWeek = moment().subtract(i, 'days').format('ddd');
    dates.unshift(dayOfWeek);
  }

  const totalCount = data?.data?.reduce((acc, emotion) => {
    const sumOfData = emotion.data.reduce((sum, value) => sum + value, 0);
    return acc + sumOfData;
  }, 0);

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        borderRadiusApplication: 'end', // 'around', 'end'
        borderRadiusWhenStacked: 'last', // 'all', 'last'
        dataLabels: {
          total: {
            enabled: false,
          },
        },
      },
    },
    yaxis: {
      max: 100,
    },
    xaxis: {
      type: 'category',
      categories: dates,
    },
    legend: {
      // show:false,
      position: 'top',
      formatter: function (seriesName, opts) {
        return t(`app.${seriesName}`);
      },
    },
    fill: {
      opacity: 1,
    },
    // colors: ['#F44336', '#E91E63', '#9C27B0', '#2196F3', '#00BCD4'],
  };

  return (
    <GenericCard height="100%">
      <Flex vertical style={{ width: '100%' }} justify="space-between">
        <Flex justify="space-between">
          <SubTitle primary>{t('app.emocionometro')}</SubTitle>
          {onRemove && (
            <Tooltip title={t('app.deletar')}>
              <FiTrash2
                onClick={onRemove}
                style={{
                  fontSize: 16,
                  color: 'black',
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          )}
        </Flex>
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <div>
            {totalCount === 0 ? (
              <Flex
                vertical
                align="center"
                justify="center"
                gap="16px"
                style={{ height: '100%' }}
              >
                <SadIcon color="#7B7B7B" size={60} />
                <p
                  style={{
                    fontSize: '14px',
                    color: '#7B7B7B',
                    textAlign: 'center',
                    fontWeight: 400,
                    maxWidth: '267px',
                  }}
                >
                  {t('app.emocionometroNaoPreenchido')}
                </p>
              </Flex>
            ) : (
              <ReactApexChart
                width="100%"
                options={options}
                series={data?.data?.map((item) => ({
                  name: item.name,
                  data: item.data,
                }))}
                type="bar"
                id="id-tour-emotionometer-graph"
              />
            )}
          </div>
        )}
      </Flex>
    </GenericCard>
  );
}

export default EmotionalGraph;
