import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import DynamicTable from 'components/V2/dinamic-table';
import { useState } from 'react';
import useSymptoms from 'core/features/inkluzen/hooks/useSymptoms';

function SymptomsTable({ params }: { params: any }) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const { data: processing, isLoading } = useSymptoms({
    page,
    ...params,
  });
  return (
    <DynamicTable
      itemName=""
      data={processing?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title="Apresentação de Sintomas"
      total={processing?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isLoading}
      defaultColumns={[
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>ID</p>,
          name: 'ID',
          dataIndex: 'id',
          key: 'id',
          render: (id: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{id}</p>
          ),
        },
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>Municipio</p>,
          name: 'Municipio',
          dataIndex: 'locality',
          key: 'locality',
          render: (locality: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{locality}</p>
          ),
          sorter: (a: any, b: any) => a.locality.localeCompare(b.locality),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Agitação (Contexto)
            </p>
          ),
          name: 'Agitação (Contexto)',
          dataIndex: 'agitationContext',
          key: 'agitationContext',
          render: (agitationContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {agitationContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.agitationContext - a.agitationContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Agitação (Significado)
            </p>
          ),
          name: 'Agitação (Significado)',
          dataIndex: 'agitationMeaning',
          key: 'agitationMeaning',
          render: (agitationMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {agitationMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.agitationMeaning - a.agitationMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Cansaço ou fadiga (Contexto)
            </p>
          ),
          name: 'Cansaço ou fadiga (Contexto)',
          dataIndex: 'tirednessOrFatigueContext',
          key: 'tirednessOrFatigueContext',
          render: (tirednessOrFatigueContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {tirednessOrFatigueContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.tirednessOrFatigueContext - a.tirednessOrFatigueContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Cansaço ou fadiga (Significado)
            </p>
          ),
          name: 'Cansaço ou fadiga (Significado)',
          dataIndex: 'tirednessOrFatigueMeaning',
          key: 'tirednessOrFatigueMeaning',
          render: (tirednessOrFatigueMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {tirednessOrFatigueMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.tirednessOrFatigueMeaning - a.tirednessOrFatigueMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Tristeza (Contexto)
            </p>
          ),
          name: 'Tristeza (Contexto)',
          dataIndex: 'sadnessContext',
          key: 'sadnessContext',
          render: (sadnessContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {sadnessContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.sadnessContext - a.sadnessContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
      ]}
      allColumns={[
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>ID</p>,
          name: 'ID',
          dataIndex: 'id',
          key: 'id',
          render: (id: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{id}</p>
          ),
        },
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>Municipio</p>,
          name: 'Municipio',
          dataIndex: 'locality',
          key: 'locality',
          render: (locality: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{locality}</p>
          ),
          sorter: (a: any, b: any) => a.locality.localeCompare(b.locality),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Auto-depreciação (Contexto)
            </p>
          ),
          name: 'Auto-depreciação (Contexto)',
          dataIndex: 'selfDeprecationContext',
          key: 'selfDeprecationContext',
          render: (selfDeprecationContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {selfDeprecationContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.selfDeprecationContext - a.selfDeprecationContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Auto-depreciação (Significado)
            </p>
          ),
          name: 'Auto-depreciação (Significado)',
          dataIndex: 'selfDeprecationMeaning',
          key: 'selfDeprecationMeaning',
          render: (selfDeprecationMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {selfDeprecationMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.selfDeprecationMeaning - a.selfDeprecationMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Preocupações somáticas (Contexto)
            </p>
          ),
          name: 'Preocupações somáticas (Contexto)',
          dataIndex: 'somaticConcernsContext',
          key: 'somaticConcernsContext',
          render: (somaticConcernsContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {somaticConcernsContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.somaticConcernsContext - a.somaticConcernsContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Preocupações somáticas (Significado)
            </p>
          ),
          name: 'Preocupações somáticas (Significado)',
          dataIndex: 'somaticConcernsMeaning',
          key: 'somaticConcernsMeaning',
          render: (somaticConcernsMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {somaticConcernsMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.somaticConcernsMeaning - a.somaticConcernsMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Mudança na auto-imagem (Contexto)
            </p>
          ),
          name: 'Mudança na auto-imagem	(Contexto)',
          dataIndex: 'changeInSelfImageContext',
          key: 'changeInSelfImageContext',
          render: (changeInSelfImageContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {changeInSelfImageContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.changeInSelfImageContext - a.changeInSelfImageContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Mudança na auto-imagem (Significado)
            </p>
          ),
          name: 'Mudança na auto-imagem (Significado)',
          dataIndex: 'changeInSelfImageMeaning',
          key: 'changeInSelfImageMeaning',
          render: (changeInSelfImageMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {changeInSelfImageMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.changeInSelfImageMeaning - a.changeInSelfImageMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Pessimismo (Contexto)
            </p>
          ),
          name: 'Pessimismo (Contexto)',
          dataIndex: 'pessimismContext',
          key: 'pessimismContext',
          render: (pessimismContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {pessimismContext}
            </p>
          ),
          sorter: (a: any, b: any) => b.pessimismContext - a.pessimismContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Pessimismo (Significado)
            </p>
          ),
          name: 'Pessimismo (Significado)',
          dataIndex: 'pessimismMeaning',
          key: 'pessimismMeaning',
          render: (pessimismMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {pessimismMeaning}
            </p>
          ),
          sorter: (a: any, b: any) => b.pessimismMeaning - a.pessimismMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Pensamentos ou desejos suicidas (Contexto)
            </p>
          ),
          name: 'Pensamentos ou desejos suicidas (Contexto)',
          dataIndex: 'suicidalThoughtsOrDesiresContext',
          key: 'suicidalThoughtsOrDesiresContext',
          render: (suicidalThoughtsOrDesiresContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {suicidalThoughtsOrDesiresContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.suicidalThoughtsOrDesiresContext -
            a.suicidalThoughtsOrDesiresContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Pensamentos ou desejos suicidas (Significado)
            </p>
          ),
          name: 'Pensamentos ou desejos suicidas (Significado)',
          dataIndex: 'suicidalThoughtsOrDesiresSignificado',
          key: 'suicidalThoughtsOrDesiresSignificado',
          render: (
            suicidalThoughtsOrDesiresSignificado: number,
            record: any
          ) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {suicidalThoughtsOrDesiresSignificado}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.suicidalThoughtsOrDesiresSignificado -
            a.suicidalThoughtsOrDesiresSignificado,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Perda de prazer (Contexto)
            </p>
          ),
          name: 'Perda de prazer (Contexto)',
          dataIndex: 'lossOfPleasureContext',
          key: 'lossOfPleasureContext',
          render: (lossOfPleasureContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {lossOfPleasureContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.lossOfPleasureContext - a.lossOfPleasureContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Perda de prazer (Significado)
            </p>
          ),
          name: 'Perda de prazer (Significado)',
          dataIndex: 'lossOfPleasureMeaning',
          key: 'lossOfPleasureMeaning',
          render: (lossOfPleasureMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {lossOfPleasureMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.lossOfPleasureMeaning - a.lossOfPleasureMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Sentimento de culpa (Contexto)
            </p>
          ),
          name: 'Sentimento de culpa (Contexto)',
          dataIndex: 'guiltContext',
          key: 'guiltContext',
          render: (guiltContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{guiltContext}</p>
          ),
          sorter: (a: any, b: any) => b.guiltContext - a.guiltContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Sentimento de culpa (Significado)
            </p>
          ),
          name: 'Sentimento de culpa (Significado)',
          dataIndex: 'guiltMeaning',
          key: 'guiltMeaning',
          render: (guiltMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{guiltMeaning}</p>
          ),
          sorter: (a: any, b: any) => b.guiltMeaning - a.guiltMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Tristeza (Contexto)
            </p>
          ),
          name: 'Tristeza (Contexto)',
          dataIndex: 'sadnessContext',
          key: 'sadnessContext',
          render: (sadnessContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {sadnessContext}
            </p>
          ),
          sorter: (a: any, b: any) => b.sadnessContext - a.sadnessContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Tristeza (Significado)
            </p>
          ),
          name: 'Tristeza (Significado)',
          dataIndex: 'sadnessMeaning',
          key: 'sadnessMeaning',
          render: (sadnessMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {sadnessMeaning}
            </p>
          ),
          sorter: (a: any, b: any) => b.sadnessMeaning - a.sadnessMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Dificuldade de trabalhar (Contexto)
            </p>
          ),
          name: 'Dificuldade de trabalhar (Contexto)',
          dataIndex: 'difficultyWorkingContext',
          key: 'difficultyWorkingContext',
          render: (difficultyWorkingContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {difficultyWorkingContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.difficultyWorkingContext - a.difficultyWorkingContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Dificuldade de trabalhar (Significado)
            </p>
          ),
          name: 'Dificuldade de trabalhar (Significado)',
          dataIndex: 'difficultyWorkingMeaning',
          key: 'difficultyWorkingMeaning',
          render: (difficultyWorkingMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {difficultyWorkingMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.difficultyWorkingMeaning - a.difficultyWorkingMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Dificuldade de concentração (Contexto)
            </p>
          ),
          name: 'Dificuldade de concentração (Contexto)',
          dataIndex: 'difficultyConcentratingContext',
          key: 'difficultyConcentratingContext',
          render: (difficultyConcentratingContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {difficultyConcentratingContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.difficultyConcentratingContext - a.difficultyConcentratingContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Dificuldade de concentração (Significado)
            </p>
          ),
          name: 'Dificuldade de concentração (Significado)',
          dataIndex: 'difficultyConcentratingMeaning',
          key: 'difficultyConcentratingMeaning',
          render: (difficultyConcentratingMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {difficultyConcentratingMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.difficultyConcentratingMeaning - a.difficultyConcentratingMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Irritabilidade (Contexto)
            </p>
          ),
          name: 'Irritabilidade (Contexto)',
          dataIndex: 'irritabilityContext',
          key: 'irritabilityContext',
          render: (irritabilityContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {irritabilityContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.irritabilityContext - a.irritabilityContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Irritabilidade (Significado)
            </p>
          ),
          name: 'Irritabilidade (Significado)',
          dataIndex: 'irritabilityMeaning',
          key: 'irritabilityMeaning',
          render: (irritabilityMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {irritabilityMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.irritabilityMeaning - a.irritabilityMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Fracassos passados (Contexto)
            </p>
          ),
          name: 'Fracassos passados (Contexto)',
          dataIndex: 'pastFailuresContext',
          key: 'pastFailuresContext',
          render: (pastFailuresContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {pastFailuresContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.pastFailuresContext - a.pastFailuresContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Fracassos passados (Significado)
            </p>
          ),
          name: 'Fracassos passados (Significado)',
          dataIndex: 'pastFailuresMeaning',
          key: 'pastFailuresMeaning',
          render: (pastFailuresMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {pastFailuresMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.pastFailuresMeaning - a.pastFailuresMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Sentimento de inutilidade (Contexto)
            </p>
          ),
          name: 'Sentimento de inutilidade (Contexto)',
          dataIndex: 'feelingOfUselessnessContext',
          key: 'feelingOfUselessnessContext',
          render: (feelingOfUselessnessContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {feelingOfUselessnessContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.feelingOfUselessnessContext - a.feelingOfUselessnessContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Sentimento de inutilidade (Significado)
            </p>
          ),
          name: 'Sentimento de inutilidade (Significado)',
          dataIndex: 'feelingOfUselessnessMeaning',
          key: 'feelingOfUselessnessMeaning',
          render: (feelingOfUselessnessMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {feelingOfUselessnessMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.feelingOfUselessnessMeaning - a.feelingOfUselessnessMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Agitação (Contexto)
            </p>
          ),
          name: 'Agitação (Contexto)',
          dataIndex: 'agitationContext',
          key: 'agitationContext',
          render: (agitationContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {agitationContext}
            </p>
          ),
          sorter: (a: any, b: any) => b.agitationContext - a.agitationContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Agitação (Significado)
            </p>
          ),
          name: 'Agitação (Significado)',
          dataIndex: 'agitationSignificado',
          key: 'agitationSignificado',
          render: (agitationSignificado: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {agitationSignificado}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.agitationSignificado - a.agitationSignificado,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Indecisão (Contexto)
            </p>
          ),
          name: 'Indecisão (Contexto)',
          dataIndex: 'indecisionContext',
          key: 'indecisionContext',
          render: (indecisionContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {indecisionContext}
            </p>
          ),
          sorter: (a: any, b: any) => b.indecisionContext - a.indecisionContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Indecisão (Significado)
            </p>
          ),
          name: 'Indecisão (Significado)',
          dataIndex: 'indecisionMeaning',
          key: 'indecisionMeaning',
          render: (indecisionMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {indecisionMeaning}
            </p>
          ),
          sorter: (a: any, b: any) => b.indecisionMeaning - a.indecisionMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Perda de peso (Contexto)
            </p>
          ),
          name: 'Perda de peso (Contexto)',
          dataIndex: 'weightLossContext',
          key: 'weightLossContext',
          render: (weightLossContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {weightLossContext}
            </p>
          ),
          sorter: (a: any, b: any) => b.weightLossContext - a.weightLossContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Perda de peso (Significado)
            </p>
          ),
          name: 'Perda de peso (Significado)',
          dataIndex: 'weightLossMeaning',
          key: 'weightLossMeaning',
          render: (weightLossMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {weightLossMeaning}
            </p>
          ),
          sorter: (a: any, b: any) => b.weightLossMeaning - a.weightLossMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Cansaço ou fadiga (Contexto)
            </p>
          ),
          name: 'Cansaço ou fadiga (Contexto)',
          dataIndex: 'tirednessOrFatigueContext',
          key: 'tirednessOrFatigueContext',
          render: (tirednessOrFatigueContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {tirednessOrFatigueContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.tirednessOrFatigueContext - a.tirednessOrFatigueContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Cansaço ou fadiga (Significado)
            </p>
          ),
          name: 'Cansaço ou fadiga (Significado)',
          dataIndex: 'tirednessOrFatigueMeaning',
          key: 'tirednessOrFatigueMeaning',
          render: (tirednessOrFatigueMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {tirednessOrFatigueMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.tirednessOrFatigueMeaning - a.tirednessOrFatigueMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Perda de interesse sexual (Contexto)
            </p>
          ),
          name: 'Perda de interesse sexual (Contexto)',
          dataIndex: 'lossOfSexualInterestContext',
          key: 'lossOfSexualInterestContext',
          render: (lossOfSexualInterestContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {lossOfSexualInterestContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.lossOfSexualInterestContext - a.lossOfSexualInterestContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Perda de interesse sexual (Significado)
            </p>
          ),
          name: 'Perda de interesse sexual (Significado)',
          dataIndex: 'lossOfSexualInterestMeaning',
          key: 'lossOfSexualInterestMeaning',
          render: (lossOfSexualInterestMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {lossOfSexualInterestMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.lossOfSexualInterestMeaning - a.lossOfSexualInterestMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Alteração no padrão de sono (Contexto)
            </p>
          ),
          name: 'Alteração no padrão de sono (Contexto)',
          dataIndex: 'changeInSleepPatternContext',
          key: 'changeInSleepPatternContext',
          render: (changeInSleepPatternContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {changeInSleepPatternContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.changeInSleepPatternContext - a.changeInSleepPatternContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Alteração no padrão de sono (Significado)
            </p>
          ),
          name: 'Alteração no padrão de sono (Significado)',
          dataIndex: 'changeInSleepPatternMeaning',
          key: 'changeInSleepPatternMeaning',
          render: (changeInSleepPatternMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {changeInSleepPatternMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.changeInSleepPatternMeaning - a.changeInSleepPatternMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Choro (Contexto)
            </p>
          ),
          name: 'Choro (Contexto)',
          dataIndex: 'cryContext',
          key: 'cryContext',
          render: (cryContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{cryContext}</p>
          ),
          sorter: (a: any, b: any) => b.cryContext - a.cryContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Choro (Significado)
            </p>
          ),
          name: 'Choro (Significado)',
          dataIndex: 'cryMeaning',
          key: 'cryMeaning',
          render: (cryMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{cryMeaning}</p>
          ),
          sorter: (a: any, b: any) => b.cryMeaning - a.cryMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Auto-criticismo (Contexto)
            </p>
          ),
          name: 'Auto-criticismo (Contexto)',
          dataIndex: 'selfCriticismContext',
          key: 'selfCriticismContext',
          render: (selfCriticismContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {selfCriticismContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.selfCriticismContext - a.selfCriticismContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Auto-criticismo (Significado)
            </p>
          ),
          name: 'Auto-criticismo (Significado)',
          dataIndex: 'selfCriticismMeaning',
          key: 'selfCriticismMeaning',
          render: (selfCriticismMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {selfCriticismMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.selfCriticismMeaning - a.selfCriticismMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Alteração no apetite (Contexto)
            </p>
          ),
          name: 'Alteração no apetite (Contexto)',
          dataIndex: 'changeInAppetiteContext',
          key: 'changeInAppetiteContext',
          render: (changeInAppetiteContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {changeInAppetiteContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.changeInAppetiteContext - a.changeInAppetiteContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Alteração no apetite (Significado)
            </p>
          ),
          name: 'Alteração no apetite (Significado)',
          dataIndex: 'changeInAppetiteMeaning',
          key: 'changeInAppetiteMeaning',
          render: (changeInAppetiteMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {changeInAppetiteMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.changeInAppetiteMeaning - a.changeInAppetiteMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Sentimento de punição (Contexto)
            </p>
          ),
          name: 'Sentimento de punição (Contexto)',
          dataIndex: 'feelingOfPunishmentContext',
          key: 'feelingOfPunishmentContext',
          render: (feelingOfPunishmentContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {feelingOfPunishmentContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.feelingOfPunishmentContext - a.feelingOfPunishmentContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Sentimento de punição (Significado)
            </p>
          ),
          name: 'Sentimento de punição (Significado)',
          dataIndex: 'feelingOfPunishmentMeaning',
          key: 'feelingOfPunishmentMeaning',
          render: (feelingOfPunishmentMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {feelingOfPunishmentMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.feelingOfPunishmentMeaning - a.feelingOfPunishmentMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Perda de interesse (Contexto)
            </p>
          ),
          name: 'Perda de interesse (Contexto)',
          dataIndex: 'lossOfInterestContext',
          key: 'lossOfInterestContext',
          render: (lossOfInterestContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {lossOfInterestContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.lossOfInterestContext - a.lossOfInterestContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Perda de interesse (Significado)
            </p>
          ),
          name: 'Perda de interesse (Significado)',
          dataIndex: 'lossOfInterestMeaning',
          key: 'lossOfInterestMeaning',
          render: (lossOfInterestMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {lossOfInterestMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.lossOfInterestMeaning - a.lossOfInterestMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Perda de energia (Contexto)
            </p>
          ),
          name: 'Perda de energia (Contexto)',
          dataIndex: 'lossOfenergyContext',
          key: 'lossOfenergyContext',
          render: (lossOfenergyContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {lossOfenergyContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.lossOfenergyContext - a.lossOfenergyContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Perda de energia (Significado)
            </p>
          ),
          name: 'Perda de energia (Significado)',
          dataIndex: 'lossOfenergyMeaning',
          key: 'lossOfenergyMeaning',
          render: (lossOfenergyMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {lossOfenergyMeaning}
            </p>
          ),
          sorter: (a: any, b: any) =>
            b.lossOfenergyMeaning - a.lossOfenergyMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
      ]}
    />
  );
}

export default SymptomsTable;
