import { useMediaQuery } from '@chakra-ui/react';
import {  Tabs } from 'antd';
import Layout from 'components/layout';
import { useState } from 'react';
import FeedbackTable from './components/feedbackTable';
import QuestionsTable from './components/QuestionsTable';
import { Permissions } from 'core/resources/enums/permissions';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import Title from 'components/typography/title';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { t } from 'core/resources/strings';

function Questions() {
  const [isSmallMobile] = useMediaQuery('(max-width: 380px)');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isSelected, setIsSelected] = useState('1');
  const [check] = useCheckPermissions();
  const { colorGreyBorderAndFont } = useU4heroColors();
  const isU4hero = check([
    Permissions.U4hero.Questions.Create,
    Permissions.U4hero.Questions.View,
  ]);

  const items = [
    {
      key: '1',
      label: (
        <p
          style={
            isMobile
              ? {
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '1' !== isSelected ? colorGreyBorderAndFont : '',
                }
              : {
                  fontSize: '24px',
                  fontWeight: 700,
                  color: '1' !== isSelected ? colorGreyBorderAndFont : '',
                }
          }
        >
          {t('app.perguntas').toUpperCase()}
        </p>
      ),
      children: <QuestionsTable />,
    },
    {
      key: '2',
      label: (
        <p
          style={
            isMobile
              ? {
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '2' !== isSelected ? colorGreyBorderAndFont : '',
                }
              : {
                  fontSize: '24px',
                  fontWeight: 700,
                  color: '2' !== isSelected ? colorGreyBorderAndFont : '',
                }
          }
        >
          {t('app.feedbacks').toUpperCase()}
        </p>
      ),
      children: <FeedbackTable />,
    },
  ];

  if (!isU4hero) {
    items.pop();
  }
  return (
    <Layout.Container>
      <Title primary>{t('app.centralDePerguntas')}</Title>
      <Tabs
        centered
        style={
          isSmallMobile ? { height: '100%', width: '100%' } : { height: '100%' }
        }
        onTabClick={(event) => setIsSelected(event)}
        size="large"
        defaultActiveKey={'1'}
        items={items}
      />
    </Layout.Container>
  );
}

export default Questions;
