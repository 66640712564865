import { useMutation } from "react-query";
import http from "infra/http";
import { StudentImport } from "../typings/form";

interface MultipleImport {
  items: StudentImport[],
  tenantId:string;
}

export function useSaveStudents() {
  return useMutation(
    (body: MultipleImport) => {
      return http.post(`/v1/student/import`, body);
    },
    { retry: false }
  );
}
