import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import type { RcFile } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import Swal from 'sweetalert2';
import axios from 'infra/http';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface UploadImagemProps {
  value: any;
  onChange: (arg: any[] | null) => any;
  locale: string;
  alt?: string;
  placeholderImg?: string;
  placeholderHeight?: number;
}
const UploadMultipleImages = ({
  value,
  onChange,
  locale,
}: UploadImagemProps) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (value) {
      setFileList(value);
    }
  }, [value]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    );
  };

  const handleUpload = async (file: any) => {
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      Swal.fire({
        title: 'Aviso',
        text: 'Formato de imagem inválido',
        icon: 'info',
      });
      return null;
    }

    if (file.size > 3e6) {
      Swal.fire({
        title: 'Aviso',
        text: 'Tamanho da imagem inválido',
        icon: 'info',
      });
      return null;
    }
    const formData = new FormData();

    formData.append('file', file);

    try {
      const { data }: { data: { result: string } } = await axios.post(
        `/v1/file/localization?locale=${locale}`,
        formData
      );

      const newFile: any = {
        url: data.result,
        status: 'done',
        name: file.name,
        uid: file.uid,
      };

      const list = [...fileList, newFile];
      setFileList(list);
      onChange(list);
    } finally {
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <>
      <Upload
        customRequest={(action: any) => handleUpload(action.file)}
        listType="picture-card"
        accept={'image/png, image/jpeg'}
        fileList={fileList}
        onPreview={handlePreview}
        onRemove={(file) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
          onChange(newFileList);
        }}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadMultipleImages;
