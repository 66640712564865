import fear from 'assets/images/fear.png';
import disgusted from 'assets/images/disgusted.png';
import sad from 'assets/images/sad.png';
import happy from 'assets/images/happy.png';
import prettyGood from 'assets/images/pretty-good.png';
import { Flex, Form, Input, Slider, SliderSingleProps } from 'antd';
import { useMediaQuery } from '@chakra-ui/react';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Text from 'components/typography/text';

function SelectRating({
  value,
  onChange,
}: {
  value: number;
  onChange: (v: number) => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const ratings = [
    {
      label: 'Muito Ruim',
      value: 1,
      image: disgusted,
    },
    {
      label: 'Ruim',
      value: 2,
      image: fear,
    },
    {
      label: 'Regular',
      value: 3,
      image: sad,
    },
    {
      label: 'Bom',
      value: 4,
      image: happy,
    },
    {
      label: 'Muito Bom',
      value: 5,
      image: prettyGood,
    },
  ];
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  return (
    <Flex justify="space-between">
      {ratings.map((item) => (
        <Flex
          vertical
          key={item.label}
          style={{
            border: `solid 1px ${
              value === item.value ? colorPrimary : colorGreyBorderAndFont
            }`,
            padding: isMobile ? '4px 8px' : '6px 0px',
            cursor:'pointer',
            width:isMobile ? '50px':'68px',
            borderRadius:'5px',
            boxShadow:value === item.value ? '0px -0.5px 4px 0px #7743B9':undefined
          }}
          gap={isMobile ? '12px' : '8px'}
          justify='center'
          align='center'
          onClick={()=> onChange(item.value)}
        >
          <img src={item.image} width="34px" height='32px'/>
          <Text align='center' size="sm">{item.label}</Text>
        </Flex>
      ))}
    </Flex>
  );
}

export default SelectRating;
