import { useQuery } from "react-query";
import axios from "infra/http";
import { QuestionLog } from "../types";

export interface UseQuestionsParams {
  questionId?: string;
}

function useQuestionHistory(params: UseQuestionsParams) {
 
  const fetchQuestions = async (
    params: UseQuestionsParams
  ): Promise<{ data: QuestionLog[]; total: number }> => {
    const { data, headers } = await axios.get<QuestionLog[]>(`/v2/questions/log`, {
      params: {
        questionId: params.questionId,
      },
    });

    return { data: data, total: Number(headers["total"]) };
  };

  return useQuery(
    [
      `/v2/questions/log?questionId=${params.questionId}`,
      params,
    ],
    () => fetchQuestions(params),
    {
      keepPreviousData: true,
    }
  );
}

export default useQuestionHistory;
