import { useMutation } from "react-query";
import http from "infra/http";

export function useToggleGame() {
  return useMutation(
    (param: { id: string; isActive: boolean }) => {
      return http.put(
        `/v1/game/${param.id}/${param.isActive ? "deactivate" : "active"}`,
        {
          active: param.isActive,
        }
      );
    },
    { retry: false }
  );
}
