import { Select } from 'antd';
import { useMediaQuery } from '@chakra-ui/react';

interface SelectClassificationProps {
  value: string;
  onChange: (v: string) => void;
  updateForm: (field: string, value: any) => void;
}

function SelectClassification({
  value,
  onChange,
  updateForm,
}: SelectClassificationProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const options = [
    {
      label: 'Primeiros Passos',
      value: 'Primeiros Passos',
    },
    {
      label: 'Configurações Gerais',
      value: 'Configurações Gerais',
    },
    {
      label: 'Funcionalidades',
      value: 'Funcionalidades',
    },
  ];

  return (
    <Select
      id="id-tour-create-helper-input-classification"
      placeholder="Classifique a ajuda"
      options={options}
      onChange={(v)=>{
        onChange(v);
        updateForm('subClassification',undefined)
      }
     }
      value={value}
      size={isMobile ? 'middle' : 'large'}
    />
  );
}

export default SelectClassification;
