import { useMutation } from "react-query";
import http from "infra/http";

export function useCommentSafeSpace() {
  return useMutation(
    (  data:{id:string,comment:string}) => {
      return http.post(
        `/v2/safeSpace/${data.id}/comment`,
        {
          ...data,
        }
      );
    },
    { retry: false }
  );
}
