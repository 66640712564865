import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Form, Input, Row, Select } from 'antd';
import InputMask from 'components/inputs/input-mask';
import Text from 'components/typography/text';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useAddress from 'core/features/address/hooks';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';

function AdressInfoSection({defaultZipCode}:{defaultZipCode?:string}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [zipcode, setZipcode] = useState<string>();
  const { data } = useAddress(zipcode);
  const form = Form.useFormInstance();
  const cep = Form.useWatch('addressZipCode', form);
  const { colorPrimary } = useU4heroColors();
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (cep && cep !== defaultZipCode) {
      const formattedCep = cep.replace(/\D/g, '');
      if (formattedCep.length === 8) {
        setZipcode(formattedCep);
      } else {
        setZipcode(undefined);
      }
    }
  }, [cep]);

  useEffect(() => {
    if (data && !('error' in data)) {
      form.setFieldsValue({
        addressCity: data.city,
        addressDistrict: data.neighborhood,
        addressState: data.state,
        addressStreet: data.street,
      });
      setError(undefined);
    }
    if (data && 'error' in data) {
      setError(data.error);
    }
  }, [data]);

  const isDataValid = data && !('error' in data);
  const isNeighborhoodEmpty = isDataValid && data.neighborhood === '';
  const isStreetEmpty = isDataValid && data.street === '';

  return (
    <GenericCard>
      <Flex vertical style={{ width: '100%' }}>
        <Text
          size="lg"
          color={colorPrimary}
          style={{ marginBottom: '16px', fontWeight: 700 }}
        >
          Endereço
        </Text>
        <Row gutter={[24, 0]}>
          <Col span={isMobile ? 24 : 6}>
            <Form.Item
              label="CEP"
              validateStatus={error ? 'error' : ''}
              help={error ?? ''}
              rules={[
                {
                  validator: async (rule, value) => {
                    if (value) {
                      const formattedValue = value.replace(/\D/g, '');
                      if (formattedValue.length !== 8) {
                        throw new Error('O CEP deve ter 8 digitos');
                      }
                    }
                  },
                },
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
              ]}
              name="addressZipCode"
            >
              <InputMask mask="99999-999" autoComplete="off">
                {() => (
                  <Input
                    id="id-tour-instituition-create-input-zipcode"
                    style={{ maxWidth: '345px' }}
                    placeholder='00000 - 000'
                  />
                )}
              </InputMask>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 18}>
            <Form.Item
              label="Logradouro"
              name="addressStreet"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o logradouro da escola!',
                },
              ]}
            >
              <Input
                disabled={!isDataValid || !isStreetEmpty}
                placeholder="Rua, Avenida, Travessa..."
                id="id-tour-instituition-create-input-street"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={isMobile ? 10 : 6} order={1}>
            <Form.Item
              label="Número"
              name="addressNumber"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório!',
                },
              ]}
            >
              <Input
                placeholder="Número"
                id="id-tour-instituition-create-input-number"
              />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 11} order={isMobile ? 3 : 2}>
            <Form.Item
              label="Bairro"
              name="addressDistrict"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o bairro!',
                },
              ]}
            >
              <Input
                disabled={!isDataValid || !isNeighborhoodEmpty}
                placeholder="Nome do bairro"
                id="id-tour-instituition-create-input-district"
              />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 14 : 7} order={isMobile ? 2 : 3}>
            <Form.Item label="Complemento" name="addressComplement">
              <Input placeholder="Apto, casa..." />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item
              label="Estado"
              name="addressState"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe o estado!',
                },
              ]}
            >
              <Input
                disabled
                placeholder="Estado"
                id="id-tour-instituition-create-input-state"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Cidade"
              name="addressCity"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe a cidade!',
                },
              ]}
            >
              <Input
                disabled
                placeholder="Cidade"
                id="id-tour-instituition-create-input-city"
              />
            </Form.Item>
          </Col>
        </Row>
      </Flex>
    </GenericCard>
  );
}

export default AdressInfoSection;
