import Layout from 'components/layout';
import Title from 'components/typography/title';
import useSaveSubscription from 'core/features/push/hooks/useSavePushSubscription';
import { t } from 'core/resources/strings';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import { useEffect, useState } from 'react';
import SafeSpaceTable from './components/SafeSpaceTable';

function SafeSpace() {
  // const { mutate: handleSave } = useSaveSubscription();
  // useEffect(() => {
  //   const checkSubscription = async () => {
  //     if ('serviceWorker' in navigator) {
  //       console.log('entrou no primeiro if')
  //       const sw = await navigator.serviceWorker.ready;
  //       console.log('sw->',sw)
  //       const subscription = await sw.pushManager.getSubscription();
  //       const isSubscribed = !!subscription

  //       console.log('ta inscrito ->',isSubscribed)
  //       if (!isSubscribed) {
  //         console.log('entrou no if->')
  //         let push = await sw.pushManager.subscribe({
  //           userVisibleOnly: true,
  //           applicationServerKey: process.env.VAPID_PUBLIC_KEY,
  //         });
  //         if (push) {
  //           console.log('criou o push')
  //           const response = JSON.parse(JSON.stringify(push));
  //           handleSave(
  //             {
  //               auth: response.keys.auth,
  //               endpoint: push.endpoint,
  //               p256dh: response.keys.p256dh,
  //               expirationTime: response.expirationTime,
  //             },
  //             {
  //               onSuccess: () => {
  //                 notificateSucess(`Você agora receberá noticações quando um aluno mandar uma mensagem no meu cantinho!`)
  //               },
  //               onError: (err) => {
  //                 notificateError(`Aconteceu um problema ao registrar para recber notificações`)

  //               },
  //             }
  //           );
  //         }
  //       }
  //     } else {
  //       console.log('Service Worker is not supported');
  //     }
  //   };
  //   console.log('ta rodando o ussefect')
  //   checkSubscription();
  // }, []);


  return (
    <Layout.Container>
      <Title
        primary
        style={{
          marginBottom: '16px',
        }}
      >
        {t('app.meuCantinho')}
      </Title>

      <SafeSpaceTable />
    </Layout.Container>
  );
}

export default SafeSpace;
