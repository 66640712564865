import SvgIcon from 'components/SvgIcon';

function EyesIcon({
  size = 30,
  color = 'black',
}: {
  size?: number | string;
  color?: string;
}) {
  return (
    <SvgIcon
      url="https://u4hero-files.s3.sa-east-1.amazonaws.com/svg-site/eyes_icon_256363.svg"
      color={color}
      size={size}
    />
  );
}

export default EyesIcon;
