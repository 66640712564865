import React from 'react';
import { Col, Flex, Row } from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { useMediaQuery } from '@chakra-ui/media-query';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';

interface GameCardProps {
  item: {
    id: string;
    name: string;
    coverImageUrl: string;
    minAge: number;
  };
  isSelected: boolean;
  onSelect: () => void;
}

const GameCard: React.FC<GameCardProps> = ({ item, isSelected, onSelect }) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary, colorSecondary, colorPrimaryLight } = useU4heroColors();

  return (
    <GenericCard
      height={isMobile ? "170px":"239px"}
      width="80%"
      cursor="pointer"
      hover={colorPrimaryLight}
      background={isSelected ? colorPrimaryLight : ''}
      onClick={onSelect}
      padding='8px'
    >
      <Flex vertical justify="space-between">
        <img
          style={{ borderRadius: '15px', aspectRatio: '16/9' }}
          src={item.coverImageUrl}
          alt={item.name}
        />
        <p
          style={{
            fontSize: isMobile ? '14px' : '18px',
            fontWeight: 600,
            color: colorPrimary,
            textAlign: 'center',
            marginBottom: '8px',
          }}
        >
          {item.name}
        </p>
        <Flex vertical gap="4px" justify="center" align="center">
          <p
            style={{
              fontSize: isMobile ? '12px' : '16px',
              fontWeight: 400,
              color: 'black',
              textAlign: 'center',
            }}
          >
            A partir dos {item.minAge} anos
          </p>
        </Flex>
      </Flex>
    </GenericCard>
  );
};

export default GameCard;
