import { Flex } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import React from 'react';
import SvgIcon from 'components/SvgIcon';
import { useMediaQuery } from '@chakra-ui/react';

function InfoCard({
  title,
  text,
  icon,
  iconUrl,
  type,
}: {
  title: string;
  text?: string;
  icon?: any;
  iconUrl?: string;
  type?: 'primary' | 'secondary';
}) {
  const { colorPrimary, colorSecondary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Flex
      style={{
        backgroundColor:
          type === 'primary'
            ? hex2rgba(colorPrimary, 0.5)
            : hex2rgba(colorSecondary, 0.7),
        backgroundImage: "url('/estrelas_1.png')",
        backgroundPosition: 'center',
        padding: '8px',
        height: '151px',
        borderRadius: '20px',
        width: isMobile ? '95%':'100%',
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 700,
        textAlign: 'center',
      }}
      vertical
      align="center"
      justify="space-around"
    >
      <p>{title}</p>
      {iconUrl ? <SvgIcon url={iconUrl} size={40} color="white" /> : null}
      {icon && React.cloneElement(icon, { size: '40px', color: 'white' })}
      <p>{text}</p>
    </Flex>
  );
}

export default InfoCard;
