import { useState } from 'react';
import { DatePicker as DatePickerAntd, Select, Space } from 'antd';
import SortDownIcon from 'assets/images/icons/SortDownIcon';
import { AiOutlineCalendar } from 'react-icons/ai';
const { Option } = Select;

const {RangePicker} = DatePickerAntd

type PickerType = 'date'| 'week' | 'month' | 'quarter' | 'year';
const PickerWithType = ({
  type,
  onChange,
  value
}: {
  type: PickerType;
  onChange: (v:any)=> void;
  value:any
}) => {
  return (
    <RangePicker
      format='DD/MM/YYYY'
      value={value}
      suffixIcon={<AiOutlineCalendar color="black" />}
      picker={type}
      onChange={onChange}
    />
  );
};

const DatePicker = ({value,onChange}:{value:any,onChange:(v:any)=> void}) => {
  const [type, setType] = useState<PickerType>('year');

  return (
    <Space>
      <Select
        value={type}
        onChange={setType}
        className="select"
        suffixIcon={<SortDownIcon size={15} />}
      >
        <Option value="date">Data</Option>
        <Option value="week">Semana</Option>
        <Option value="month">Mês</Option>
        <Option value="quarter">Trimestre</Option>
        <Option value="year">Anual</Option>
      </Select>
      <PickerWithType type={type} value={value} onChange={onChange} />
    </Space>
  );
};

export default DatePicker;
