import { useQuery } from 'react-query';
import axios from 'infra/http';
import { ResourceItem } from '../types';
import { useEffect, useState } from 'react';

export interface UseResourcesParams {
  page?: number;
  limit?: number;
  search?: string;
  types?: number[];
  type?: number;
}

function useResources(params: UseResourcesParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  return useQuery(
    [`/v1/resource`, { ...params, search: debouncedQuery }],
    async (): Promise<{ data: ResourceItem[]; total: number }> => {
      const { data, headers } = await axios.get(`/v1/resource`, {
        params: { ...params, search: debouncedQuery },
      });

      return { data, total: Number(headers['total']) };
    }
  );
}

export default useResources;
