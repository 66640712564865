import { useMutation } from "react-query";
import http from "infra/http";
import { UserSimpleForm } from "../typings/form";

interface MultipleImport {
  items: UserSimpleForm[],
  tenantId:string;
}

export function useSaveUsers() {
  return useMutation(
    (body: MultipleImport) => {
      return http.post(`/v2/users/import`, body);
    },
    { retry: false }
  );
}
