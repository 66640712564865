import { Flex } from "@chakra-ui/layout";

function Content({ children }: { children: any }) {
  return (
    <Flex pt="20px" flex="1" bg="#fff" minH="100vh">
      {children}
    </Flex>
  );
}

export default Content;
