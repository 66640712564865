import { useQuery } from 'react-query';
import axios from 'infra/http';
import { GameFeedback } from '../typings';
import { useEffect, useState } from 'react';

export interface UseGamesFeedbacksParams {
  page?: number;
  ratings?: string[];
  initialDate?: string;
  id?: string;
  pageSize?: number;
  gameId?: string;
  search?: string;
}

function useGamesFeedbacks(params: UseGamesFeedbacksParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchFeedbacks = async (
    params: UseGamesFeedbacksParams
  ): Promise<{ data: GameFeedback[]; total: number }> => {
    const { data, headers } = await axios.get<GameFeedback[]>(
      `/v1/game/feedback`,
      {
        params: {
          ...params,
          search: debouncedQuery,
        },
      }
    );

    return { data: data, total: Number(headers['total']) };
  };

  return useQuery(
    [`/v1/game/feedback`, { ...params, search: debouncedQuery }],
    () => fetchFeedbacks(params),
    {
      keepPreviousData: true,
    }
  );
}

export default useGamesFeedbacks;
