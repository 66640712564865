function InstitutionIcon({ size = 50 }: { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 64 64"
      version="1.1"
      xmlSpace="preserve"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
    >
      <g transform="matrix(1,0,0,1,-588,-248)">
        <g id="Artboard1" transform="matrix(1,0,0,1,420,0)">
          <rect x="0" y="0" width="400" height="400" style={{ fill: 'none' }} />
          <g>
            <g transform="matrix(0.777778,0,0,0.777778,42.5,60.9444)">
              <path d="M202.5,275.214C199.307,275.214 196.714,277.807 196.714,281C196.714,284.193 199.307,286.786 202.5,286.786C205.693,286.786 208.286,284.193 208.286,281C208.286,277.807 205.693,275.214 202.5,275.214ZM202.5,277.786C204.274,277.786 205.714,279.226 205.714,281C205.714,282.774 204.274,284.214 202.5,284.214C200.726,284.214 199.286,282.774 199.286,281C199.286,279.226 200.726,277.786 202.5,277.786Z" />
            </g>
            <g transform="matrix(0.926093,0,0,1.52662,15.9639,-149.086)">
              <path d="M223.559,285C223.559,284.638 223.075,284.345 222.479,284.345L216,284.345C215.404,284.345 214.92,284.638 214.92,285L214.92,287.62C214.92,287.982 215.404,288.275 216,288.275L222.479,288.275C223.075,288.275 223.559,287.982 223.559,287.62L223.559,285ZM221.399,285.655L221.399,286.965C221.399,286.965 217.08,286.965 217.08,286.965C217.08,286.965 217.08,285.655 217.08,285.655L221.399,285.655Z" />
            </g>
            <g transform="matrix(0.926093,0,0,1.52662,-22.0361,-149.086)">
              <path d="M223.559,285C223.559,284.638 223.075,284.345 222.479,284.345L216,284.345C215.404,284.345 214.92,284.638 214.92,285L214.92,287.62C214.92,287.982 215.404,288.275 216,288.275L222.479,288.275C223.075,288.275 223.559,287.982 223.559,287.62L223.559,285ZM221.399,285.655L221.399,286.965C221.399,286.965 217.08,286.965 217.08,286.965C217.08,286.965 217.08,285.655 217.08,285.655L221.399,285.655Z" />
            </g>
            <g transform="matrix(0.926093,0,0,1.52662,-8.03611,-148.086)">
              <path d="M223.559,285C223.559,284.638 223.075,284.345 222.479,284.345L216,284.345C215.404,284.345 214.92,284.638 214.92,285L214.92,287.62C214.92,287.982 215.404,288.275 216,288.275L222.479,288.275C223.075,288.275 223.559,287.982 223.559,287.62L223.559,285ZM221.399,285.655L221.399,286.965C221.399,286.965 217.08,286.965 217.08,286.965C217.08,286.965 217.08,285.655 217.08,285.655L221.399,285.655Z" />
            </g>
            <g transform="matrix(0.926093,0,0,1.52662,1.96389,-148.086)">
              <path d="M223.559,285C223.559,284.638 223.075,284.345 222.479,284.345L216,284.345C215.404,284.345 214.92,284.638 214.92,285L214.92,287.62C214.92,287.982 215.404,288.275 216,288.275L222.479,288.275C223.075,288.275 223.559,287.982 223.559,287.62L223.559,285ZM221.399,285.655L221.399,286.965C221.399,286.965 217.08,286.965 217.08,286.965C217.08,286.965 217.08,285.655 217.08,285.655L221.399,285.655Z" />
            </g>
            <g transform="matrix(0.926093,0,0,1.52662,15.9639,-142.086)">
              <path d="M223.559,285C223.559,284.638 223.075,284.345 222.479,284.345L216,284.345C215.404,284.345 214.92,284.638 214.92,285L214.92,287.62C214.92,287.982 215.404,288.275 216,288.275L222.479,288.275C223.075,288.275 223.559,287.982 223.559,287.62L223.559,285ZM221.399,285.655L221.399,286.965C221.399,286.965 217.08,286.965 217.08,286.965C217.08,286.965 217.08,285.655 217.08,285.655L221.399,285.655Z" />
            </g>
            <g transform="matrix(0.926093,0,0,1.52662,-22.0361,-142.086)">
              <path d="M223.559,285C223.559,284.638 223.075,284.345 222.479,284.345L216,284.345C215.404,284.345 214.92,284.638 214.92,285L214.92,287.62C214.92,287.982 215.404,288.275 216,288.275L222.479,288.275C223.075,288.275 223.559,287.982 223.559,287.62L223.559,285ZM221.399,285.655L221.399,286.965C221.399,286.965 217.08,286.965 217.08,286.965C217.08,286.965 217.08,285.655 217.08,285.655L221.399,285.655Z" />
            </g>
            <g transform="matrix(0.926093,0,0,1.52662,15.9639,-135.086)">
              <path d="M223.559,285C223.559,284.638 223.075,284.345 222.479,284.345L216,284.345C215.404,284.345 214.92,284.638 214.92,285L214.92,287.62C214.92,287.982 215.404,288.275 216,288.275L222.479,288.275C223.075,288.275 223.559,287.982 223.559,287.62L223.559,285ZM221.399,285.655L221.399,286.965C221.399,286.965 217.08,286.965 217.08,286.965C217.08,286.965 217.08,285.655 217.08,285.655L221.399,285.655Z" />
            </g>
            <g transform="matrix(0.926093,0,0,1.52662,-22.0361,-135.086)">
              <path d="M223.559,285C223.559,284.638 223.075,284.345 222.479,284.345L216,284.345C215.404,284.345 214.92,284.638 214.92,285L214.92,287.62C214.92,287.982 215.404,288.275 216,288.275L222.479,288.275C223.075,288.275 223.559,287.982 223.559,287.62L223.559,285ZM221.399,285.655L221.399,286.965C221.399,286.965 217.08,286.965 217.08,286.965C217.08,286.965 217.08,285.655 217.08,285.655L221.399,285.655Z" />
            </g>
            <g transform="matrix(1,0,0,1,-447.124,0)">
              <path d="M621.124,309L673.124,309C673.676,309 674.124,308.552 674.124,308L674.124,283L675.124,283C675.676,283 676.124,282.552 676.124,282L676.124,264C676.124,263.448 675.676,263 675.124,263L648.89,263L648.124,261.723L648.124,259L656.124,259C656.676,259 657.124,258.552 657.124,258L657.124,252C657.124,251.448 656.676,251 656.124,251L647.124,251C646.598,251 646.166,251.407 646.127,251.923L646.127,251.923C646.126,251.937 646.125,251.952 646.124,251.966L646.124,251.994L646.124,252L646.124,261.723L645.358,263L619.124,263C618.572,263 618.124,263.448 618.124,264L618.124,282C618.124,282.552 618.572,283 619.124,283L620.124,283L620.124,308C620.124,308.552 620.572,309 621.124,309ZM634.124,283L634.124,307L622.124,307C622.124,307 622.124,283 622.124,283L634.124,283ZM660.124,283L672.124,283L672.124,307L660.124,307L660.124,283ZM636.124,282.277L636.124,307L638.124,307L638.124,296C638.124,295.448 638.572,295 639.124,295L655.124,295C655.676,295 656.124,295.448 656.124,296L656.124,307L658.124,307L658.124,282.277L647.124,263.944L636.124,282.277ZM640.124,307L646.124,307L646.124,297L640.124,297L640.124,307ZM648.124,297L648.124,307L654.124,307L654.124,297L648.124,297ZM650.09,265L659.69,281L673.124,281L673.127,281L674.124,281C674.124,281 674.124,265 674.124,265L650.09,265ZM644.158,265L620.124,265C620.124,265 620.124,281 620.124,281L634.558,281L644.158,265ZM648.124,253L648.124,257L655.124,257C655.124,257 655.124,253 655.124,253L648.124,253Z" />
            </g>
            <g transform="matrix(0.75,0,0,1,44,0)">
              <path d="M176,269L180,269C180.736,269 181.333,268.552 181.333,268C181.333,267.448 180.736,267 180,267L176,267C175.264,267 174.667,267.448 174.667,268C174.667,268.552 175.264,269 176,269Z" />
            </g>
            <g transform="matrix(-0.75,0,0,1,356,0)">
              <path d="M176,267L180,267C180.736,267 181.333,267.448 181.333,268C181.333,268.552 180.736,269 180,269L176,269C175.264,269 174.667,268.552 174.667,268C174.667,267.448 175.264,267 176,267Z" />
            </g>
            <g transform="matrix(0.75,0,0,1,44,5)">
              <path d="M176,269L180,269C180.736,269 181.333,268.552 181.333,268C181.333,267.448 180.736,267 180,267L176,267C175.264,267 174.667,267.448 174.667,268C174.667,268.552 175.264,269 176,269Z" />
            </g>
            <g transform="matrix(-0.75,0,0,1,356,5)">
              <path d="M176,267L180,267C180.736,267 181.333,267.448 181.333,268C181.333,268.552 180.736,269 180,269L176,269C175.264,269 174.667,268.552 174.667,268C174.667,267.448 175.264,267 176,267Z" />
            </g>
            <g transform="matrix(0.75,0,0,1,44,10)">
              <path d="M176,269L180,269C180.736,269 181.333,268.552 181.333,268C181.333,267.448 180.736,267 180,267L176,267C175.264,267 174.667,267.448 174.667,268C174.667,268.552 175.264,269 176,269Z" />
            </g>
            <g transform="matrix(-0.75,0,0,1,356,10)">
              <path d="M176,267L180,267C180.736,267 181.333,267.448 181.333,268C181.333,268.552 180.736,269 180,269L176,269C175.264,269 174.667,268.552 174.667,268C174.667,267.448 175.264,267 176,267Z" />
            </g>
            <g transform="matrix(0.75,0,0,1,51,0)">
              <path d="M176,269L180,269C180.736,269 181.333,268.552 181.333,268C181.333,267.448 180.736,267 180,267L176,267C175.264,267 174.667,267.448 174.667,268C174.667,268.552 175.264,269 176,269Z" />
            </g>
            <g transform="matrix(-0.75,0,0,1,349,0)">
              <path d="M176,267L180,267C180.736,267 181.333,267.448 181.333,268C181.333,268.552 180.736,269 180,269L176,269C175.264,269 174.667,268.552 174.667,268C174.667,267.448 175.264,267 176,267Z" />
            </g>
            <g transform="matrix(1.25,0,0,1,-37,5)">
              <path d="M176,269L180,269C180.442,269 180.8,268.552 180.8,268C180.8,267.448 180.442,267 180,267L176,267C175.558,267 175.2,267.448 175.2,268C175.2,268.552 175.558,269 176,269Z" />
            </g>
            <g transform="matrix(-1.25,0,0,1,437,5)">
              <path d="M176,267L180,267C180.442,267 180.8,267.448 180.8,268C180.8,268.552 180.442,269 180,269L176,269C175.558,269 175.2,268.552 175.2,268C175.2,267.448 175.558,267 176,267Z" />
            </g>
            <g transform="matrix(0.5,0,0,1,95,10)">
              <path d="M176,269L180,269C181.104,269 182,268.552 182,268C182,267.448 181.104,267 180,267L176,267C174.896,267 174,267.448 174,268C174,268.552 174.896,269 176,269Z" />
            </g>
            <g transform="matrix(-0.5,0,0,1,305,10)">
              <path d="M176,267L180,267C181.104,267 182,267.448 182,268C182,268.552 181.104,269 180,269L176,269C174.896,269 174,268.552 174,268C174,267.448 174.896,267 176,267Z" />
            </g>
            <g transform="matrix(0.25,0,0,2,146,-268)">
              <path d="M176,268.5L180,268.5C182.208,268.5 184,268.276 184,268C184,267.724 182.208,267.5 180,267.5L176,267.5C173.792,267.5 172,267.724 172,268C172,268.276 173.792,268.5 176,268.5Z" />
            </g>
            <g transform="matrix(-0.25,0,0,2,254,-268)">
              <path d="M176,267.5L180,267.5C182.208,267.5 184,267.724 184,268C184,268.276 182.208,268.5 180,268.5L176,268.5C173.792,268.5 172,268.276 172,268C172,267.724 173.792,267.5 176,267.5Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default InstitutionIcon;
