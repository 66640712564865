import GenericFilterComponent from 'components/generic-filter';
import { t } from 'core/resources/strings';
import { FilterProps } from 'infra/helpers/types';
function GameFilter({ filters, onChangeFilters }: FilterProps) {
  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: t('app.classificacao'),
          filterName: 'classifications',
          options: [
            {
              label: t('app.jogosJaUtilizados'),
              value: 'used',
            },
            {
              label: t('app.jogosNaoUtilizados'),
              value: 'not-used',
            },
          ],
        },
        {
          title: t('app.duracaoDoJogo'),
          filterName: 'durations',
          options: [10, 20, 30, 40, 50].map((item) => {
            return { label: `${item} minutos`, value: item };
          }),
          type: 'number',
        },
        {
          title: t('app.faixaEtaria'),
          filterName: 'age',
          options: [3, 7, 12, 16].map((item) => {
            return {
              label: `${t('app.ate')} ${item} ${t('app.anos').toLowerCase()}`,
              value: item,
            };
          }),
          type: 'radio',
        },
        {
          title: t('app.genero'),
          filterName: 'genres',
          options: [
            {
              label: t('app.aventura'),
              value: 'Aventura',
            },
            {
              label: t('app.estrategia'),
              value: 'Estratégia',
            },
            {
              label: t('app.puzzle'),
              value: 'Puzzle',
            },
            {
              label: t('app.simulacao'),
              value: 'Simulação',
            },
          ],
        },
        {
          title: t('app.temasExtras'),
          filterName: 'types',
          options: [
            {
              label: t('app.socioemocional'),
              value: 'socioEmocional',
            },
            {
              label: t('app.geografia'),
              value: 'geografia',
            },
            {
              label: t('app.historia'),
              value: 'historia',
            },
            {
              label: t('app.portugues'),
              value: 'portugues',
            },
            {
              label: t('app.matematica'),
              value: 'matematica',
            },
          ],
        },
        {
          title: t('app.status'),
          options: [
            { label: t('app.ativo'), value: 1 },
            { label: t('app.inativo'), value: 2 },
          ],
          filterName: 'status',
          type: 'radio',
        },
        {
          title: t('app.limiteDePerguntas'),
          filterName: 'limits',
          options: [6, 8, 10].map((item) => {
            return {
              label: `${t('app.ate')} ${item} ${t(
                'app.perguntas'
              ).toLowerCase()}`,
              value: item,
            };
          }),
          type: 'number',
        },
        {
          title: t('app.favoritos'),
          filterName: 'favorite',
          options: [],
          type: 'switch',
        },
      ]}
    />
  );
}

export default GameFilter;
