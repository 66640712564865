import { useQuery } from 'react-query';
import axios from 'infra/http';

function useEmotionometerGraph() {
  const fetchData = async (): Promise<{
    data: {name:string,data:number[]}[];
  }> => {
    const { data } = await axios.get<{name:string,data:number[]}[]>(`/v2/indicators/emotionometer`);

    return { data: data };
  };

  return useQuery([`/v2/indicators/emotionometer`], () => fetchData());
}

export default useEmotionometerGraph;
