import GamesFeedbacksTable from 'ui/pages/app/games-u4hero/components/gamesFeedbacksTable';

function GamesFeedbacksTab() {
  return (
    <>
      <GamesFeedbacksTable />
    </>
  );
}

export default GamesFeedbacksTab;
