//new designer
export const primary = '#7743B9';
export const primaryHover = '#EFE8F6';
export const secondary = '#AD73EB';
export const primary400 = '#9A50E6';

export const borderColor = '#c4c4c4';
export const white = '#FFFFFF';




//old designer
export const primary100 = '#e5eaf9';
export const primary200 = '#abacde';
export const primaryVariation1 = '#6762F0';


export const purple = '#051565';
export const purple800 = '#4D4CAC';
export const purple500 = '#9698D6';
export const purple300 = '#E5E9FA';
export const purple100 = '#F5F5FB';
export const purple50 = '#F7F7FC';

export const red100 = '#FFE2E5';
export const red500 = '#DB8572';
export const red = '#F64E60';

export const blue = '#0632d4';
export const blue500 = '#5E81F4';
export const blue300 = '#ADC3F7';
export const blue400 = '#7f9af6';
export const blue100 = '#F3F6FE';

export const orange = '#F86640';
export const orange2 = '#fd8a6c';
export const orange500 = '#F8AD40';
export const orange100 = '#FBE285';

export const pink = '#FF808B';
export const pink500 = '#F9CADA';
export const pink100 = '#F7E5E9';

export const gray900 = '#3e4045';
export const gray800 = '#697B98';
export const gray500 = '#BFC8D6';
export const gray300 = '#D8DEE8';
export const gray100 = '#F2F4F7';

export const black50 = '#e2e8f0';
export const black = '#000000';

export const yellow = '#E2CC78';

export const whiteAlpha = 'rgba(255, 255, 255, .07)';

export const relationshipColor = '#FF69B4';
export const relationshipColorLight = '#FFB2C1';
export const managementColor = '#0000ff';
export const managementColorLight = '#4A9DFF';
export const socialColor = '#FFA500';
export const socialColorLight = '#FFCB4A';
export const decisionColor = '#A020F0';
export const decisionColorLight = '#C685FF';
export const bullyingColor = '#FF0000';
export const bullyingColorLight = '#FF5755';
export const awarenessColor = '#00FF00';
export const awarenessColorLight = '#55F255';
