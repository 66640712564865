import { useState } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Modal, message, Input } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import Button from 'components/buttons/button';
import { t } from 'core/resources/strings';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import empty from 'assets/images/lumi-empty.png';

interface ModalStoryProps {
  onClose: () => void;
  onSave: () => void;
}

function ModalStoryAi({ onClose, onSave }: ModalStoryProps) {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [storyPrompt, setStoryPrompt] = useState(
    'Tema da história: [inserir tema aqui] Objetivo: [o que deseja abordar] Perguntas Decisivas: [número de perguntas] Perguntas Reflexivas: [número de perguntas] Importante: - Perguntas Decisivas: Não possuem certo ou errado. Elas levam a diferentes caminhos na narrativa. - Perguntas Reflexivas: Possuem certo e errado, portanto, cada alternativa precisa de um feedback.'
  );

  return (
    <Modal
      title={<SubTitle primary>Definir Cenário</SubTitle>}
      open={true}
      onCancel={onClose}
      footer={
        <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
          <Button
            variant="outline"
            size="md"
            onClick={onClose}
            id="id-tour-my-school-student-modal-button-cancel"
          >
            {t('app.cancelar')}
          </Button>

          <Button
            htmlType="submit"
            size="md"
            onClick={onSave}
            id="id-tour-my-school-student-modal-button-save"
          >
            {t('app.salvar')}
          </Button>
        </Flex>
      }
      width={isMobile ? '90%' : 980}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Flex vertical>
        <Flex>
          <Flex vertical style={{ width: '40%' }}>
            <Text
              size="md"
              bold
              style={{
                backgroundColor: hex2rgba(colorPrimary, 0.7),
                borderRadius: '20px 20px 0px 0px',
                padding: '8px',
                width: '100%',
              }}
              color="white"
              align="center"
            >
              Criar História
            </Text>
            <Flex vertical gap="8px" style={{ padding: '16px' }}>
              <Text size="md">
                Preencha as informações abaixo para criar sua história. Use o
                modelo fornecido como guia.
              </Text>

              <Input.TextArea
                value={storyPrompt}
                onChange={(e) => setStoryPrompt(e.target.value)}
                rows={10}
              />
            </Flex>
          </Flex>
          <Flex vertical style={{ width: '60%' }}>
            <Text
              size="md"
              bold
              style={{
                backgroundColor: hex2rgba(colorPrimary, 0.7),
                borderRadius: '20px 20px 0px 0px',
                padding: '8px',
                width: '100%',
              }}
              color="white"
              align="center"
            >
              História Gerada
            </Text>
            <Flex
              vertical
              justify="center"
              align="center"
              style={{ width: '100%', height: '100%' }}
            >
              <img src={empty} />
              <Text size="md">Não há dados para serem exibidos!</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default ModalStoryAi;
