import { useMediaQuery } from '@chakra-ui/media-query';
import { Carousel, Flex, Select } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import BackButton from 'components/buttons/back-button';
import NextButton from 'components/buttons/next-button';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { useInfraestruturaEscolar } from 'core/features/qedu/hooks/useInfraestruturaEscolar';
import { useCurrentUser } from 'core/features/user/store';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import React from 'react';
import { useRef, useState, useEffect } from 'react';
import {
  PiBuildingApartmentLight,
  PiWheelchairLight,
  PiWheelchairMotionLight,
  PiForkKnifeLight,
  PiPintGlassLight,
  PiToiletThin,
  PiBooksLight,
  PiChefHatLight,
  PiLaptopLight,
  PiFlaskLight,
  PiScrollThin,
  PiDribbbleLogoLight,
  PiChalkboardSimpleLight,
  PiChalkboardTeacherLight,
  PiIdentificationBadgeLight,
  PiDropLight,
  PiDropSimpleLight,
  PiWavesLight,
  PiDropSlashLight,
  PiLightbulbFilamentLight,
  PiCarBatteryLight,
  PiWindmillThin,
  PiBatteryWarningLight,
  PiToiletPaperLight,
  PiTruckLight,
  PiCampfireLight,
  PiWarehouseLight,
  PiRecycleLight,
  PiShovelLight,
  PiTrashLight,
  PiWifiHighLight,
  PiSpeedometerLight,
  PiFilmReelLight,
  PiPrinterLight,
  PiBroadcastLight,
  PiCardsLight,
  PiProjectorScreenLight,
  PiTelevisionSimpleLight,
} from 'react-icons/pi';

function SchoolInfra() {
  const { user } = useCurrentUser();
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const carouselRef = useRef<CarouselRef>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [category, setCategory] = useState('Dependências');
  const [infos, setInfos] = useState<
    { label: string; icon: React.ReactNode; category: string }[]
  >([]);

  const categoryOptions = [
    { value: 'Acessibilidade', label: 'Acessibilidade' },
    { value: 'Alimentação', label: 'Alimentação' },
    { value: 'Dependências', label: 'Dependências' },
    { value: 'Serviços', label: 'Serviços' },
    { value: 'Tecnologia', label: 'Tecnologia' },
    { value: 'Equipamentos', label: 'Equipamentos' },
  ];
  const iconMap: {
    [key: string]: { label: string; icon: React.ReactNode; category: string };
  } = {
    acessibilidade_escola: {
      label: 'Escola com Acessibilidade',
      icon: (
        <PiBuildingApartmentLight
          size={isMobile ? 48 : 76}
          color={colorPrimary}
        />
      ),
      category: 'Acessibilidade',
    },
    acessibilidade_dependencias: {
      label: 'Dependências com Acessibilidade',
      icon: (
        <PiWheelchairLight size={isMobile ? 48 : 76} color={colorPrimary} />
      ),
      category: 'Acessibilidade',
    },
    acessibilidade_sanitario: {
      label: 'Sanitários com Acessibilidade',
      icon: (
        <PiWheelchairMotionLight
          size={isMobile ? 48 : 76}
          color={colorPrimary}
        />
      ),
      category: 'Acessibilidade',
    },
    alimentacao_fornecida: {
      label: 'Alimentação Fornecida',
      icon: <PiForkKnifeLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Alimentação',
    },
    alimentacao_agua_filtrada: {
      label: 'Água Filtrada',
      icon: <PiPintGlassLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Alimentação',
    },
    dependencias_sanitario_dentro_predio: {
      label: 'Sanitário dentro da Escola',
      icon: <PiToiletThin size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Dependências',
    },
    dependencias_biblioteca: {
      label: 'Biblioteca',
      icon: <PiBooksLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Dependências',
    },
    dependencias_cozinha: {
      label: 'Cozinha',
      icon: <PiChefHatLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Dependências',
    },
    dependencias_lab_informatica: {
      label: 'Lab. Informática',
      icon: <PiLaptopLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Dependências',
    },
    dependencias_lab_ciencias: {
      label: 'Lab. Ciências',
      icon: <PiFlaskLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Dependências',
    },
    dependencias_sala_leitura: {
      label: 'Sala de Leitura',
      icon: <PiScrollThin size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Dependências',
    },
    dependencias_quadra_esportes: {
      label: 'Quadra de Esportes',
      icon: (
        <PiDribbbleLogoLight size={isMobile ? 48 : 76} color={colorPrimary} />
      ),
      category: 'Dependências',
    },
    dependencias_sala_diretora: {
      label: 'Sala da Diretoria',
      icon: (
        <PiChalkboardSimpleLight
          size={isMobile ? 48 : 76}
          color={colorPrimary}
        />
      ),
      category: 'Dependências',
    },
    dependencias_sala_professores: {
      label: 'Sala de Educadores',
      icon: (
        <PiChalkboardTeacherLight
          size={isMobile ? 48 : 76}
          color={colorPrimary}
        />
      ),
      category: 'Dependências',
    },
    dependencias_sala_atendimento_especial: {
      label: 'Sala de Atendimento Especial',
      icon: (
        <PiIdentificationBadgeLight
          size={isMobile ? 48 : 76}
          color={colorPrimary}
        />
      ),
      category: 'Dependências',
    },
    servicos_agua_rede_publica: {
      label: 'Água tratada (rede pública)',
      icon: <PiPintGlassLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Serviços',
    },
    servicos_agua_poco_artesiano: {
      label: 'Água poço artesiano',
      icon: <PiDropLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Serviços',
    },
    servicos_agua_cacimba: {
      label: 'Água de Cacimba',
      icon: (
        <PiDropSimpleLight size={isMobile ? 48 : 76} color={colorPrimary} />
      ),
      category: 'Serviços',
    },
    servicos_agua_fonte_rio: {
      label: 'Água de fonte de rio',
      icon: <PiWavesLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Serviços',
    },
    servicos_agua_inexistente: {
      label: 'Água inexistente',
      icon: <PiDropSlashLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Serviços',
    },
    servicos_energia_rede_publica: {
      label: 'Energia Elétrica (rede pública)',
      icon: (
        <PiLightbulbFilamentLight
          size={isMobile ? 48 : 76}
          color={colorPrimary}
        />
      ),
      category: 'Serviços',
    },
    servicos_energia_gerador: {
      label: 'Energia Elétrica (gerador)',
      icon: (
        <PiCarBatteryLight size={isMobile ? 48 : 76} color={colorPrimary} />
      ),
      category: 'Serviços',
    },
    servicos_energia_outros: {
      label: 'Energia Elétrica (outros)',
      icon: <PiWindmillThin size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Serviços',
    },
    servicos_energia_inexistente: {
      label: 'Sem energia Elétrica',
      icon: (
        <PiBatteryWarningLight size={isMobile ? 48 : 76} color={colorPrimary} />
      ),
      category: 'Serviços',
    },
    servicos_esgoto_rede_publica: {
      label: 'Esgoto (rede pública)',
      icon: <PiToiletThin size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Serviços',
    },
    servicos_esgoto_fossa: {
      label: 'Esgoto (fossa)',
      icon: <PiToiletThin size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Serviços',
    },
    servicos_esgoto_inexistente: {
      label: 'Esgoto inexistente',
      icon: (
        <PiToiletPaperLight size={isMobile ? 48 : 76} color={colorPrimary} />
      ),
      category: 'Serviços',
    },
    servicos_lixo_coleta_periodica: {
      label: 'Lixo com coleta periódica',
      icon: <PiTruckLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Serviços',
    },
    servicos_lixo_queima: {
      label: 'Lixo (queima)',
      icon: <PiCampfireLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Serviços',
    },
    servicos_lixo_joga_outra_area: {
      label: 'Lixo (joga em outra área)',
      icon: <PiWarehouseLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Serviços',
    },
    servicos_lixo_recicla: {
      label: 'Lixo Reciclagem',
      icon: <PiRecycleLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Serviços',
    },
    servicos_lixo_enterra: {
      label: 'Lixo (enterra)',
      icon: <PiShovelLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Serviços',
    },
    servicos_lixo_outros: {
      label: 'Lixo (outros)',
      icon: <PiTrashLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Serviços',
    },
    tecnologia_internet: {
      label: 'Internet',
      icon: <PiWifiHighLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Tecnologia',
    },
    tecnologia_banda_larga: {
      label: 'Banda Larga',
      icon: (
        <PiSpeedometerLight size={isMobile ? 48 : 76} color={colorPrimary} />
      ),
      category: 'Tecnologia',
    },
    equipamento_dvd: {
      label: 'Aparelho DVD',
      icon: <PiFilmReelLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Equipamentos',
    },
    equipamento_impressora: {
      label: 'Impressora',
      icon: <PiPrinterLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Equipamentos',
    },
    equipamento_parabolica: {
      label: 'Parabólica',
      icon: <PiBroadcastLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Equipamentos',
    },
    equipamento_copiadora: {
      label: 'Copiadora',
      icon: <PiCardsLight size={isMobile ? 48 : 76} color={colorPrimary} />,
      category: 'Equipamentos',
    },
    equipamento_retroprojetor: {
      label: 'Retroprojetor / Projetor',
      icon: (
        <PiProjectorScreenLight
          size={isMobile ? 48 : 76}
          color={colorPrimary}
        />
      ),
      category: 'Equipamentos',
    },
    equipamento_tv: {
      label: 'TV',
      icon: (
        <PiTelevisionSimpleLight
          size={isMobile ? 48 : 76}
          color={colorPrimary}
        />
      ),
      category: 'Equipamentos',
    },
  };

  const { data, error } = useInfraestruturaEscolar({
    inep_id: user?.inep_id as number,
  });

  useEffect(() => {
    if (data) {
      const filtered = Object.entries(iconMap)
        .filter(([key]) => Number((data as any)?.[key]) > 0)
        .map(([key, { label, icon, category }]) => ({ label, icon, category }));
      setInfos(filtered);
    }
  }, [data]);

  const filteredInfos = infos.filter((item) => item.category === category);
  const totalSlides = filteredInfos.length;

  const slidesToShow = isMobile ? 1 : 5;
  const rowsToShow = isMobile ? 4 : 1;

  return (
    <Flex
      vertical
      style={{ padding: '16px', height: '100%' }}
      className="box-shadow-generic-card"
      gap={16}
    >
      <div>
        <SubTitle primary style={{ lineHeight: 'normal' }}>
          Infraestrutura da Escola
        </SubTitle>

        <Text size="sm" style={{ lineHeight: 'normal' }}>
          Instalações e recursos da escola
        </Text>
      </div>
      <Select
        size={'middle'}
        options={categoryOptions}
        value={category}
        style={{ alignSelf: 'flex-end' }}
        onChange={(e) => {
          setCategory(e);
          setCurrentSlide(0);
        }}
      />
      <div
        style={{
          position: 'relative',
        }}
      >
        {totalSlides > slidesToShow * rowsToShow && (
          <BackButton
            style={{
              position: 'absolute',
              top: '50%',
              left: -10,
              zIndex: 300,
            }}
            onClick={() => carouselRef.current?.prev()}
            disabled={currentSlide <= 0}
          />
        )}

        <Carousel
          ref={carouselRef}
          slidesToShow={slidesToShow}
          rows={rowsToShow}
          slidesToScroll={slidesToShow}
          style={{ width: '100%', height: '100%' }}
          dots={false}
          infinite={false}
          beforeChange={(curr, next) => {
            setCurrentSlide(next);
          }}
        >
          {infos
            .filter((item) => item.category === category)
            .map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Flex
                    className="box-shadow-generic-card"
                    vertical={!isMobile}
                    align="center"
                    style={{
                      padding: '16px',
                      height: 'fit-content',
                      minHeight: isMobile ? undefined : '135px',
                      marginBottom: isMobile ? '16px' : undefined,
                    }}
                    gap={isMobile ? 16 : undefined}
                    justify={isMobile ? '-moz-initial' : 'space-between'}
                  >
                    {item.icon}
                    <Text size={isMobile ? 'md' : 'sm'} bold align="center">
                      {item.label}
                    </Text>
                  </Flex>
                </React.Fragment>
              );
            })}
        </Carousel>
        {totalSlides > slidesToShow * rowsToShow && (
          <NextButton
            style={{ position: 'absolute', top: '50%', right: isMobile ? 0 : 30 }}
            onClick={() => carouselRef.current?.next()}
            disabled={
              currentSlide >=
              Math.ceil(
                infos.filter((item) => item.category === category).length /
                  rowsToShow
              ) -
                slidesToShow
            }
          />
        )}
      </div>
    </Flex>
  );
}

export default SchoolInfra;
