import { useQuery } from 'react-query';
import axios from 'infra/http';

export interface UseTenantsLocationsParams {
  parentId?: string;
  master?: boolean;
}

function useTenantsLocations(params: UseTenantsLocationsParams) {
  const fetchData = async (
    params: UseTenantsLocationsParams
  ): Promise<{ state: string }[]> => {
    const { data } = await axios.get<{ state: string }[]>(
      `/v1/tenant/locations`,
      {
        params,
      }
    );

    return data;
  };

  return useQuery([`/v1/tenant/locations`,params], () => fetchData(params));
}

export default useTenantsLocations;
