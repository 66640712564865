import { useState, useEffect } from 'react';
import { Select } from 'antd';
import useTagsCompetence from 'core/features/tags/hooks/useTagsCompetence';
import { useMediaQuery } from '@chakra-ui/react';

interface SelectCompetenceProps {
  value: string;
  onChange: (v: string) => void;
  competence2: string;
  updateForm: (field: string, value: any) => void;
  setCompetenceName:(v:string)=> void;
}

function SelectCompetence({
  value,
  onChange,
  competence2,
  updateForm,
  setCompetenceName
}: SelectCompetenceProps) {
  const { data } = useTagsCompetence({
    competence: true,
  });
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const combined = [
    {
      label: `Antibullying e Segurança educacional`,
      value: 'combination 1',
      id1: data?.find((item) => item.name['pt-br'] === 'Antibullying')?.id,
      id2: data?.find((item) => item.name['pt-br'] === 'Segurança educacional')
        ?.id,
    },
    {
      label: `Autogestão e Autoconsciência`,
      value: 'combination 2',
      id1: data?.find((item) => item.name['pt-br'] === 'Autogestão')?.id,
      id2: data?.find((item) => item.name['pt-br'] === 'Autoconsciência')?.id,
    },
    {
      label: `Consciência social e Relacionamento`,
      value: 'combination 3',
      id1: data?.find((item) => item.name['pt-br'] === 'Consciência social')
        ?.id,
      id2: data?.find((item) => item.name['pt-br'] === 'Relacionamento')?.id,
    },
  ];
  const options = [
    ...(data || []).map((item) => ({
      label: item?.name['pt-br'],
      value: item.id,
    })),
    ...combined,
  ].sort((a, b) => (a.label > b.label ? 1 : -1));
  const [internalValue, setInternalValue] = useState<string | undefined>();

  useEffect(() => {
    if (value && competence2) {
      const combination = combined.find(
        (c) => c.id1 === value && c.id2 === competence2
      );
      if (combination) {
        setInternalValue(combination.value);
        setCompetenceName(combination.label)
      }
    }
    if (value && !competence2) {
      setInternalValue(value);
      setCompetenceName(options.find((item)=> item.value === value)?.label as string);
    }
  }, [value, competence2]);

  const handleChange = (selectedValue: string) => {
    updateForm('skill', undefined);
    setInternalValue(selectedValue);
    if (selectedValue.includes('combination')) {
      const selected = combined.find((item) => item.value === selectedValue);
      onChange(selected?.id1 as string);
      updateForm('competence2', selected?.id2);
      setCompetenceName(selected?.label as string)
    } else {
      onChange(selectedValue);
      setCompetenceName(options.find((item)=> item.value === selectedValue)?.label as string);
      updateForm('competence2', undefined);
    }
  };

 
  return (
    <Select
      id="id-tour-create-practice-input-competence"
      placeholder="Selecione a(s) competência(s)"
      options={options}
      onChange={handleChange}
      value={internalValue}
      size={isMobile ? 'middle' : 'large'}
    />
  );
}

export default SelectCompetence;
