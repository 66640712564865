import { useMutation, useQuery } from "react-query";
import axios from "infra/http";
import { IAnnotation, IAnnotationCreate } from "../types";

function useAnnotation(id:string) {
  return useQuery(`/v2/inkluzen/annotations/:${id}`, async ():Promise<IAnnotation> => {
    const { data } = await axios.get<IAnnotation>(`/v2/inkluzen/annotations/${id}`);
    
    return data;
  });

}

const useCreateAnnotation = () => {
  return useMutation(
    async (annotation:IAnnotationCreate) => {
      const { data } = await axios.post('/v2/inkluzen/annotations/', annotation);
      return data;
    },
    { }
  );
};

export default useAnnotation;
export { useCreateAnnotation };