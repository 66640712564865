import { useQuery } from "react-query";
import axios from "infra/http";

function useStudentsActive() {
  const fetchData = async (): Promise<any> => {
    const { data } = await axios.get<any>(`/v2/student/active`);

    return data;
  };

  return useQuery([`/v2/student/active`], () => fetchData());
}

export default useStudentsActive;
