import useTagsCompetence from 'core/features/tags/hooks/useTagsCompetence';
import GenericFilterComponent from 'components/generic-filter';
import { FilterProps } from 'infra/helpers/types';

function SkillFilter({ filters, onChangeFilters }: FilterProps) {
  const { data } = useTagsCompetence({
    competence: true,
  });

  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: 'Competências',
          filterName: 'tags',
          options:
            data?.map((item) => {
              return { label: item.name['pt-br'], value: item.id };
            }) ?? [],
        },
      ]}
    />
  );
}

export default SkillFilter;
