import http from "infra/http";
import { useMutation } from "react-query";
import { AutomatedForm } from "../types";

export function useSaveAutomated() {
  return useMutation(
    (data: AutomatedForm) => {
      if (data._id) {
        return http.put(`${process.env.REACT_APP_AUTOMATED_URL}automated/${data._id}`, data);
      }
      return http.post(`${process.env.REACT_APP_AUTOMATED_URL}automated/`, data);
    },
    { retry: false }
  );
}
