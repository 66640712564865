import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Modal } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import image from 'assets/images/lumi-story-image.png';
import Button from 'components/buttons/button';
import { useCurrentUser } from 'core/features/user/store';

function ModalWelcomeCreateStory({ onClose }: { onClose: () => void }) {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { user, update } = useCurrentUser();
  return (
    <Modal
      title={<SubTitle primary>Bem-vindo à Criação de Histórias</SubTitle>}
      open={true}
      onCancel={onClose}
      footer={null}
      width={isMobile ? '90%' : 555}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Flex vertical gap="16px" align="center">
        <img src={image} alt="Lumi Story" />
        <Text size="md" align="justify">
          Para garantir uma criação de história fluida, recomendamos que você{' '}
          <strong>primeiro crie todas as rotas</strong> da sua história. Após a
          criação das rotas, você poderá adicionar interações e detalhes
          específicos em cada uma delas.
        </Text>
        <Button size="md" onClick={onClose}>
          Fechar
        </Button>

        <Text
          size="sm"
          color={colorGreyBorderAndFont}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => {
            if (user) {
              update({ ...user, hideWelcomeCreateStory: true });
            }
            onClose();
          }}
        >
          Não mostrar novamente!
        </Text>
      </Flex>
    </Modal>
  );
}

export default ModalWelcomeCreateStory;
