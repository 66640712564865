import React, { useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { Input } from 'antd';
import {PiMapPin} from 'react-icons/pi'
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { SearchOutlined } from '@ant-design/icons';

interface PlacesAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult) => void;
}

const PlacesAutocomplete: React.FC<PlacesAutocompleteProps> = ({ onPlaceSelect }) => {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const onLoad = (auto: google.maps.places.Autocomplete) => {
    setAutocomplete(auto);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const selectedPlace = autocomplete.getPlace();
      onPlaceSelect(selectedPlace);
      setSearchValue(selectedPlace.formatted_address || '');
    }
  };

  return (
    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
      <SearchBar onSearch={(value) => setSearchValue(value)} value={searchValue} />
    </Autocomplete>
  );
};

interface SearchBarProps {
  onSearch: (v: string) => void;
  value: string;
}

function SearchBar({ onSearch, value }: SearchBarProps) {
  const { colorPrimary,colorGreyBorderAndFont } = useU4heroColors();
  return (
    <Input
      value={value}
      prefix={<SearchOutlined  style={{color: colorPrimary, fontSize:20}} />}
			suffix={<PiMapPin color={colorGreyBorderAndFont} size={20} />}
      placeholder="Pesquisar"
      allowClear
      className="box-shadow-generic-card"
      style={{
        borderRadius: '15px',
        maxWidth: '284px',
        height: '36px',
      }}
      onChange={(e) => onSearch(e.target.value)}
    />
  );
}

export default PlacesAutocomplete;
