import { useMediaQuery } from '@chakra-ui/react';
import { Dropdown, Flex, Menu } from 'antd';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import moment from 'moment';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import DynamicTable from 'components/V2/dinamic-table';
import { FaEllipsisVertical } from 'react-icons/fa6';
import {
  notificateError,
  notificateSucess,
  showConfirm,
} from 'infra/helpers/notifications';
import useProfiles from 'core/features/profile/hooks/useProfiles';
import { ProfileItem } from 'core/features/profile/typings';
import { FiTrash2 } from 'react-icons/fi';
import { TfiPencil } from 'react-icons/tfi';
import { useDeleteProfile } from 'core/features/profile/hooks/useDeleteProfile';
import { t } from 'core/resources/strings';

function ProfilesTable() {
  const history = useHistory();
  const [check] = useCheckPermissions();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const { mutate: handleDelete } = useDeleteProfile();
  const { tenantId } = useParams<{ tenantId: string }>();

  let location = useLocation();

  const { data, isLoading, isFetching, refetch } = useProfiles({
    tenantId,
  });

  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };
  const columns = [
    {
      title: <p style={tableHeaderStyle}>{t('app.nome')}</p>,
      name: t('app.nome'),
      dataIndex: 'name',
      key: 'name',
      mobile: true,
      render: (_: string, record: ProfileItem) => (
        <p style={tableRowStyle}>{record.name}</p>
      ),
      sorter: (a: ProfileItem, b: ProfileItem) => a.name.localeCompare(b.name),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },

    {
      title: <p style={tableHeaderStyle}>{t('app.dataDeCriacao')}</p>,
      key: 'createdAt',
      dataIndex: 'createdAt',
      name: t('app.dataDeCriacao'),
      render: (_: string, record: ProfileItem) => (
        <p style={tableRowStyle}>
          {record.createdAt
            ? moment.utc(record.createdAt).format('DD/MM/YYYY')
            : '-'}
        </p>
      ),
      sorter: (a: ProfileItem, b: ProfileItem) =>
        a.createdAt.localeCompare(b.createdAt),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.editar')}</p>,
      key: 'edit',
      dataIndex: 'edit',
      name: t('app.editar'),
      render: (_: string, record: ProfileItem) => (
        <TfiPencil
          size={30}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (tenantId) {
              history.push(`/schools/${tenantId}/profiles/edit/${record.id}`);
            } else {
              if (location.pathname.includes('settings-u4hero')) {
                history.push(`/settings-u4hero/profiles/edit/${record.id}`);
              } else {
                history.push(`/my-school/profiles/edit/${record.id}`);
              }
            }
          }}
        />
      ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.deletar')}</p>,
      key: 'delete',
      dataIndex: 'delete',
      name: t('app.deletar'),
      render: (_: string, record: ProfileItem) => (
        <FiTrash2
          size={30}
          style={{ cursor: 'pointer' }}
          onClick={() => onDelete(record.id, record.name)}
        />
      ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.acoes')}</p>,
      key: 'action',
      mobile: true,
      extra: true,
      dataIndex: 'action',
      name: t('app.acoes'),
      render: (_: string, record: ProfileItem) => (
        <Dropdown
          overlay={
            <Menu
              id="id-tour-action-student-table"
              onClick={(e) => {
                if (e.key === 'edit') {
                  if (tenantId) {
                    history.push(
                      `/schools/${tenantId}/profiles/edit/${record.id}`
                    );
                  } else {
                    if (location.pathname.includes('settings-u4hero')) {
                      history.push(
                        `/settings-u4hero/profiles/edit/${record.id}`
                      );
                    } else {
                      history.push(`/my-school/profiles/edit/${record.id}`);
                    }
                  }
                }
                if (e.key === 'delete') {
                  onDelete(record.id, record.name);
                }
              }}
            >
              {check([Permissions.U4hero.Helpers.Edit]) ? (
                <Menu.Item id="edit-button" key={'edit'}>
                  {t('app.editar')}
                </Menu.Item>
              ) : null}
              {check([Permissions.U4hero.Helpers.Edit]) ? (
                <Menu.Item id="delete-button" key={'delete'}>
                  {t('app.deletar')}
                </Menu.Item>
              ) : null}
            </Menu>
          }
          trigger={['click']}
        >
          <FaEllipsisVertical
            id="id-tour-action-profiles-table"
            size={24}
            style={{ cursor: 'pointer' }}
          />
        </Dropdown>
      ),
    },
  ];

  const onDelete = (id: string, profileName: string) => {
    showConfirm({
      message: `${t(
        'app.temCertezaQueDesejaExcluirOPerfilDe'
      )} ${profileName}?`,
      colorPrimary,
      isMobile,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        handleDelete(id, {
          onSuccess: () => {
            notificateSucess(t('app.perfilExcluidoComSucesso'));
            refetch();
          },
          onError: () => {
            notificateError(t('app.erroAoExcluirPerfil'));
          },
        });
      }
    });
  };
  return (
    <DynamicTable
      id="id-tour-profiles-table"
      itemName="perfil"
      data={data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title=""
      total={data?.length ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isLoading}
      defaultColumns={
        isMobile
          ? columns.filter((column) => column.mobile)
          : columns.filter((column) => !column.extra)
      }
      allColumns={columns}
      hideCog={true}
    />
  );
}

export default ProfilesTable;
