
import axios from "axios";
import { useMutation } from "react-query";

export function useDeleteAutomatedActivity() {
  return useMutation(
    (id: string) => {
      return axios.delete(`${process.env.REACT_APP_AUTOMATED_URL}automated/${id}`);
    },
    { retry: false }
  );
}
