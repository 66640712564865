import { Col, Flex, Row, Spin } from 'antd';
import SearchBar from 'components/V2/SearchBar';
import useGames from 'core/features/games/hooks/useGames';
import { FilterType } from 'infra/helpers/types';
import { useState } from 'react';
import GameFilter from 'ui/pages/app/activities/pages/CreateActivity/components/StepGame/GameFilter';
import adventure from 'assets/images/adventure.png';
import strategy from 'assets/images/strategy.png';
import simulation from 'assets/images/simulation.png';
import puzzle from 'assets/images/puzzle.png';
import Text from 'components/typography/text';
import { HoverItem } from 'components/hover-item/hover';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useHistory } from 'react-router';
import { useMediaQuery } from '@chakra-ui/react';
import classes from './index.module.css';
import { PiLockKeyLight } from 'react-icons/pi';
import { IoMenu } from 'react-icons/io5';
import { t } from 'core/resources/strings';

function Catalog() {
  const history = useHistory();
  const [filters, setFilters] = useState<FilterType>({
    age: [],
    favorite: [],
    types: [],
  });
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [search, setSearch] = useState('');
  const [genre, setGenre] = useState<string>();

  const { data, isLoading } = useGames({
    page: 1,
    limit: 100,
    search,
    ageClassification: filters?.age?.map((item) => item.value)[0] ?? undefined,
    genres: genre ? [genre] : undefined,
    types: filters?.types.map((item) => item.value) ?? undefined,
    favorites: filters.favorite.map((item) => item.value)[0] ?? undefined,
  });

  const { colorPrimaryLight } = useU4heroColors();
  const genres = [
    {
      label: t('app.aventura'),
      image: adventure,
      value: 'Aventura',
    },
    {
      label: t('app.estrategia'),
      image: strategy,
      value: 'Estratégia',
    },
    {
      label: t('app.puzzle'),
      image: puzzle,
      value: 'Puzzle',
    },
    {
      label: t('app.simulacao'),
      image: simulation,
      value: 'Simulação',
    },
  ];

  const gameTypes: { [key: string]: string } = {
    socioEmocional: t('app.socioemocional'),
    geografia: t('app.geografia'),
    historia: t('app.historia'),
    portugues: t('app.portugues'),
    matematica: t('app.matematica'),
  };

  return (
    <Flex vertical gap={24}>
      <Flex
        align="center"
        gap="16px"
        style={{ marginBottom: '16px', width: '100%' }}
      >
        <SearchBar width="100%" value={search} onSearch={setSearch} />

        <GameFilter filters={filters} onChangeFilters={setFilters} />
      </Flex>
      <Row gutter={isMobile ? [16, 16] : [31, 31]} align="stretch">
        <Col span={24}>
          <Row gutter={isMobile ? [16, 16] : [32, 0]}>
            {genres.map((item, index) => (
              <Col md={6} xs={12} key={index}>
                <HoverItem
                  key={index}
                  hover={colorPrimaryLight}
                  style={{
                    width: '100%',
                    borderRadius: '20px',
                    background:
                      item.value === genre ? colorPrimaryLight : undefined,
                  }}
                  onClick={() => {
                    if (genre === item.value) {
                      setGenre(undefined);
                    } else {
                      setGenre(item.value);
                    }
                  }}
                >
                  <Flex
                    style={{
                      borderRadius: '20px',
                      padding: '4px 12px',
                      boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.10)',
                      width: '100%',
                    }}
                    align="center"
                    justify="space-around"
                    gap={16}
                  >
                    <img src={item.image} width={isMobile ? 54 : 80} />
                    <Flex vertical align="center">
                      <Text size="sm" align="center">
                        {item.label}
                      </Text>
                    </Flex>
                  </Flex>
                </HoverItem>
              </Col>
            ))}
          </Row>
        </Col>
        {isLoading && <Spin size="large" />}
        {data?.data.map((item) => (
          <Col key={item.id} md={4} xs={12} className={classes.imageContainer}>
            <img
              className={classes.image}
              onClick={() => history.push(`game/${item.id}`)}
              src={item.coverImageUrl}
            />
            <div className={classes.gameInfo}>
              <Flex vertical gap="4px" justify="center" align="center">
                <PiLockKeyLight color="black" size={24} />
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: 'black',
                    textAlign: 'center',
                  }}
                >
                  {item.minAge} anos +
                </p>
              </Flex>
              <Flex vertical gap="4px" justify="center" align="center">
                <IoMenu color="black" size={24} />
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: 'black',
                    textAlign: 'center',
                  }}
                >
                  {item.type ? gameTypes[item.type] : '-'}
                </p>
              </Flex>
            </div>
          </Col>
        ))}
      </Row>
    </Flex>
  );
}

export default Catalog;
