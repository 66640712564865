import GenericFilterComponent from 'components/generic-filter';
import { useCurrentUser } from 'core/features/user/store';
import { t } from 'core/resources/strings';
import { FilterProps } from 'infra/helpers/types';

function ResourcesFilter({ filters, onChangeFilters }: FilterProps) {
  const profiles = [
    {
      label: 'U4Hero',
      value: 0,
    },
    {
      label: t('app.school'),
      value: 1,
    },
    {
      label: t('app.organizacao'),
      value: 2,
    },
    {
      label: 'Inkluzen',
      value: 3,
    },
    {
      label: 'B2C',
      value: 4,
    },
  ];

  return (
    <GenericFilterComponent
      onChangeFilters={onChangeFilters}
      filters={filters}
      filterGroups={[
        {
          title: 'Permissão para perfil de',
          options: profiles,
          filterName: 'types',
          type: 'number',
        },
      ]}
    />
  );
}

export default ResourcesFilter;
