import { useMediaQuery } from '@chakra-ui/react';
import { Col, Form, Radio, Row, Space } from 'antd';
import SelectCompare from 'components/V2/selects/select-compare';
import { t } from 'core/resources/strings';

function ComparativeInput({
  index,
  updateForm,
}: {
  index: number;
  updateForm: (
    fieldName: string | number | (string | number)[],
    value: any
  ) => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const form = Form.useFormInstance();
  const graph = Form.useWatch(['graphs', index], form);
  if (graph?.graphView !== 'comparative' || (graph?.source === 'student' && graph?.comparisonMode !== 'timePeriod')) {
    return null;
  }
  return (
    <>
      <Form.Item
        rules={[
          {
            required: true,
            message: t('app.mensagemErroDefinicaoDoPeriodoDeTempo'),
          },
        ]}
        name={[index, 'comparativePeriod']}
        label={t('app.definicaoDoPeriodoDeTempo')}
      >
        <Radio.Group
          id="id-tour-my-indicators-custom-panel-graph-period-input"
          onChange={() => {
            updateForm(['graphs', index, 'comparative1'], undefined);
            updateForm(['graphs', index, 'comparative2'], undefined);
          }}
        >
          <Space direction={isMobile ? 'vertical' : 'horizontal'}>
            <Radio value={'year'}>{t('app.anual')}</Radio>
            <Radio value={'bimester'}>{t('app.bimestral')}</Radio>
            <Radio value={'trimester'}>{t('app.trimestral')}</Radio>
            <Radio value={'quadrimester'}>{t('app.quadrimestral')}</Radio>
            <Radio value={'semester'}>{t('app.semestral')}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>

      <Row gutter={[isMobile ? 20 : 40, 0]}>
        <Col span={12}>
          <Form.Item
            label={t('app.comparativo1')}
            rules={[
              {
                required: true,
                message: t('app.mensagemErroComparativo1'),
              },
            ]}
            name={[index, 'comparative1']}
            style={{ width: '100%' }}
          >
            {/* @ts-ignore */}
            <SelectCompare period={graph?.comparativePeriod} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('app.comparativo2')}
            rules={[
              {
                required: true,
                message: t('app.mensagemErroComparativo2'),
              },
            ]}
            style={{ width: '100%' }}
            name={[index, 'comparative2']}
          >
            {/* @ts-ignore */}
            <SelectCompare period={graph?.comparativePeriod} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default ComparativeInput;
