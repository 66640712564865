import { useQuery } from "react-query";
import axios from "infra/http";
import { IStudentInfo } from "../types";

function useStudentInfo(studentId:string) {
  return useQuery(`/v2/inkluzen/${studentId}/process`, async ():Promise<IStudentInfo> => {
    const { data } = await axios.get<IStudentInfo>(`/v2/inkluzen/${studentId}/process`);
    
    return data;
  });
}

export default useStudentInfo;