import { useQuery } from "react-query";
import axios from "infra/http";
import { DropdownItem } from "../typings";
export interface UseGameDropdownParams {
  search?: string;
  ageClassification?: number;
  genres?: string[];
}

function useGameDropdown(params: UseGameDropdownParams) {
  const fetchData = async (
    params: UseGameDropdownParams
  ): Promise<{ data: DropdownItem[] }> => {
    const { data } = await axios.get<DropdownItem[]>(`/v1/game/dropdown`, {
      params,
    });

    return { data: data };
  };

  return useQuery(
    [`/v1/game/dropdown`, params],
    () => fetchData(params),
    {
      keepPreviousData: true
    }


  );
}

export default useGameDropdown;
