import { useMediaQuery } from '@chakra-ui/react';
import {  Flex, Input } from 'antd';
import { useCommentSafeSpace } from 'core/features/safeSpace/hooks/useCommentSafeSpace';
import { useCurrentUser } from 'core/features/user/store';
import { useState } from 'react';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import notFound from 'assets/images/errors/erro404.png';
import Comment from '../Comment';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import { useParams } from 'react-router-dom';
import { comment } from 'core/features/safeSpace/typings';
import Button from 'components/buttons/button';
import { t } from 'core/resources/strings';

const { TextArea } = Input;

function SafespaceComment({ comments }: { comments: comment[] }) {
  const params = useParams<{ id: string }>();
  const [comentario, setComentario] = useState('');
  const { mutate: onComentar } = useCommentSafeSpace();
  const { user } = useCurrentUser();
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const handleSaveComment = () => {
    if (comentario !== '') {
      onComentar(
        { id: params.id ?? '', comment: comentario },
        {
          onError: () => {
            notificateError(t('app.mensagemErroComentario'));
          },
          onSuccess: () => {
            notificateSucess(t('app.comentarioPostadoComSucesso'));
            setComentario('');
          },
        }
      );
      comments.push({
        id: '',
        comment: comentario,
        createdAt: new Date().toString(),
        user: {
          name: user?.name,
          imageUrl: user?.imageUrl,
        },
      });
    } else {
      notificateError(t('app.comentarioNaoPodeSerVazio'));
    }
  };

  return (
    <GenericCard padding="8px" height="100%">
      <Flex
        vertical
        gap="8px"
        justify="space-between"
        style={{ width: '100%' }}
      >
        <p
          style={{
            fontSize: isMobile ? '18px' : '24px',
            color: colorPrimary,
            fontWeight: 700,
          }}
        >
          {t('app.comentarios')}
        </p>
        {comments?.length ? (
          comments.map((comment) => (
            <Comment
              key={comment.id}
              name={comment.user.name ?? ''}
              imageUrl={comment.user.imageUrl ?? undefined}
              comment={comment.comment}
              date={comment.createdAt}
            />
          ))
        ) : (
          <>
            <p
              style={{
                fontSize: isMobile ? '14px' : '16px',
                color: '#7B7B7B',
                textAlign: 'center',
                fontWeight: 400,
              }}
            >
              {t('app.nenhumComentarioAteAgora')}
            </p>
            <img
              alt="Nenhum comentário encontrado"
              src={notFound}
              width={isMobile ? '246px' : '289px'}
              style={{ alignSelf: 'center' }}
            />
          </>
        )}

        <TextArea
          id="id-tour-safe-space-input-comment"
          autoSize={{ minRows: 2 }}
          style={{
            boxShadow:
              '0px -0.5px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
          }}
          placeholder={t('app.insiraSeuComentario')}
          value={comentario}
          onChange={(v) => setComentario(v.target.value)}
        />
        <Button
          onClick={handleSaveComment}
          size='md'
          style={{
            alignSelf: 'center',
          }}
          id="id-tour-safe-space-button-save-comment"
          disabled={comentario === ''}
        >
          {t('app.comentar')}
        </Button>
      </Flex>
    </GenericCard>
  );
}

export default SafespaceComment;
