import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex, Modal} from 'antd';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import { MdCancel } from 'react-icons/md';


function ModalCharacterLore({
  onClose,
  image,
  lore,
  name,
}: {
  onClose: () => void;
  image: string;
  lore: string;
  name: string;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px');

  return (
    <>
      {isMobile ? (
        <Modal
          open={true}
          onCancel={onClose}
          footer={null}
          style={{ background: 'transparent' }}
          modalRender={(node) => (
            <div
              style={{
                background: 'transparent',
              }}
              className={'modal-with-background'}
            >
              {node}
            </div>
          )}
          width={'100%'}
          closeIcon={null}
        >
          <Flex vertical align="center">
            <img
              alt={name}  
              src={image}
              style={{
                width: '100%',
                borderRadius: '20px',
              }}
            />
            <div
              style={{
                maxWidth: '240px',
                height: '330px',
                background: 'rgba(0, 0, 0, 0.70)',
                padding: '8px',
              }}
            >
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 600,
                  color: 'white',
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              >
                {name}
              </p>
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: 400,
                  color: 'white',
                  maxWidth: '218px',
                  maxHeight: '252px',
                  overflow: 'auto',
                }}
              >
                {lore}
              </p>
              <Flex justify="center" style={{ marginTop: '8px' }}>
                <MdCancel
                  color="white"
                  size={20}
                  style={{ alignSelf: 'center' }}
                  onClick={onClose}
                />{' '}
              </Flex>
            </div>
          </Flex>
        </Modal>
      ) : (
        <Modal
          open={true}
          onCancel={onClose}
          footer={null}
          modalRender={(node) => (
            <div
              style={{
                backgroundImage: 'url("' + image + '")',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundColor: 'white',
                borderRadius: '20px',
              }}
              className={'modal-with-background'}
            >
              {node}
            </div>
          )}
          centered
          width={'1127px'}
          closeIcon={<MdCancel color="white" size={38} />}
        >
          <Flex align="center" style={{ height: '80vh' }}>
            <div
              style={{
                maxWidth: '348px',
                background: hex2rgba('#000000', 0.50),
                borderRadius:'20px',
                padding:'8px'
              }}
            >
              <p
                style={{
                  fontSize: '24px',
                  fontWeight: 600,
                  color: 'white',
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              >
                {name}
              </p>
              <p
                style={{
                  fontSize: '18px',
                  fontWeight: 400,
                  color: 'white',
                  maxWidth: '348px',
                  maxHeight: '327px',
                  overflow: 'auto',
                }}
              >
                {lore}
              </p>
            </div>
          </Flex>
        </Modal>
      )}
    </>
  );
}

export default ModalCharacterLore;
