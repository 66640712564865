import React from 'react';
import Text from 'components/typography/text';
import { IoClose } from 'react-icons/io5';
import { Flex } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { t } from 'core/resources/strings';

interface AppliedFiltersProps {
  appliedFilters: {
    [key: string]: {
      value: any;
      label: string;
    }[];
  };
  onChangeFilters: (updatedFilters: any) => void;
}

const AppliedFilters: React.FC<AppliedFiltersProps> = ({
  appliedFilters,
  onChangeFilters,
}) => {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  return (
    <>
      {Object.entries(appliedFilters || {}).some(
        ([_, items]) => items?.length > 0
      ) ? (
        <Flex gap="8px" wrap="wrap">
          <Text style={{ color: colorGreyBorderAndFont }}>
            {t('app.filtradoPor')}:
          </Text>
          {Object.entries(appliedFilters || {}).map(([key, items]) =>
            items?.map((item, index) => (
              <Flex
                key={index}
                gap="4px"
                align="center"
                style={{
                  border: `1px solid ${colorPrimary}`,
                  fontSize: '12px',
                  fontWeight: 700,
                  padding: '4px',
                  color: colorPrimary,
                  borderRadius: '5px',
                }}
              >
                {item.label}
                <IoClose
                  style={{ cursor: 'pointer' }}
                  color={colorPrimary}
                  size={15}
                  onClick={() => {
                    const updatedFilters = { ...appliedFilters };
                    updatedFilters[key] = items.filter(
                      (filter) => filter.value !== item.value
                    );
                    onChangeFilters(updatedFilters);
                  }}
                />
              </Flex>
            ))
          )}
        </Flex>
      ) : null}
    </>
  );
};

export default AppliedFilters;
