import { Col, Flex, Row } from 'antd';
import { GenericCard } from '../generic-card-styled-component/index';
import { useEffect, useState } from 'react';
import './style.css';

function TimelineCard({
  name,
  score,
  measure,
  date,
  onClick,
}: {
  name: string;
  score: number;
  measure: string;
  date: string;
  onClick?: () => void;
}) {
  const [weekDay, setWeekDay] = useState('');
  const [day, setDay] = useState(0);

  useEffect(() => {
    saveDateInfos();
  }, []);

  const saveDateInfos = (): void => {
    const dateFormated = new Date(date);
    const dateWeekDay = dateFormated.toLocaleDateString('pt-BR', {
      weekday: 'long',
    });
    setWeekDay(dateWeekDay.charAt(0).toUpperCase() + dateWeekDay.slice(1, 3));
    setDay(dateFormated.getDate());
  };

  return (
    <GenericCard className="timeline-card-row" width="100%">
      <Row onClick={onClick} style={{ height: '100%', width: '100%' }}>
        <Col flex={'15%'}>
          <Flex
            align="center"
            vertical
            style={{
              marginRight: '10px',
              height: '100%',
              borderRight: '1px solid #C4C4C4',
            }}
          >
            <p style={{ color: 'black', fontSize: '24px', fontWeight: 500 }}>
              {weekDay}
            </p>
            <p
              style={{
                color: 'black',
                fontSize: '36px',
                lineHeight: '21px',
                fontWeight: 700,
              }}
            >
              {day}
            </p>
          </Flex>
        </Col>
        <Col flex={'75%'}>
          <Flex vertical>
            <p>{name}</p>
            <p>Score: {score}</p>
            <Flex
              justify="center"
              align="center"
              className={
                measure === 'não manifesto' ? 'nao-manifesto' : measure
              }
              gap={10}
            >
              <div
                className={
                  measure === 'não manifesto' ? 'nao-manifesto' : measure
                }
                id={measure === 'não manifesto' ? 'nao-manifesto' : measure}
              ></div>
              <p style={{ fontSize: '14px', fontWeight: 600 }}>
                {measure.toUpperCase()}
              </p>
            </Flex>
          </Flex>
        </Col>
      </Row>
    </GenericCard>
  );
}

export default TimelineCard;
