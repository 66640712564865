import { Flex, Progress, Spin } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@chakra-ui/media-query';
import defaultProfile from 'assets/images/default-class-profile.png';
import Button from 'components/buttons/button';
import { PiDownloadSimpleLight } from 'react-icons/pi';
import { t } from 'core/resources/strings';
import Text from 'components/typography/text';
import { ClassDashboardInfo } from 'core/features/classes/types';

function ClassInformation({ classInfo }: { classInfo?: ClassDashboardInfo }) {
  const { colorPrimary, colorGreyCard } = useU4heroColors();

  const [emotionColor, setEmotionColor] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [emotionName, setEmotionName] = useState('');

  useEffect(() => {
    const predominantEmotion = classInfo?.predominantEmotion;

    if (predominantEmotion) {
      return classInfo?.predominantEmotion.includes('feliz')
        ? (setEmotionColor('#FCBE03'), setEmotionName('Feliz'))
        : classInfo?.predominantEmotion.includes('triste')
        ? (setEmotionColor('#4377B9'), setEmotionName('Triste'))
        : classInfo?.predominantEmotion.includes('nojo')
        ? (setEmotionColor('#77B943'), setEmotionName('Nojo'))
        : classInfo?.predominantEmotion.includes('raiva')
        ? (setEmotionColor('#B94143'), setEmotionName('Raiva'))
        : (setEmotionColor('#7743B9'), setEmotionName('Medo'));
    }
  }, [classInfo?.predominantEmotion]);

  return (
    <GenericCard
      width={'100%'}
      height="100%"
      padding={isMobile ? '8px' : '16px'}
    >
      {isMobile ? (
        <Flex gap={16} style={{ width: '100%' }} vertical>
          <Flex justify="space-between" align="center">
            <Flex align="center" justify="start" vertical>
              <img
                loading={'lazy'}
                src={defaultProfile}
                alt="Perfil da turma"
                style={{
                  width: '100px',
                  height: '100px',
                  zIndex: 1,
                  borderRadius: '50%',
                  border: `4px solid ${emotionColor}`,
                  objectFit: 'cover',
                }}
              />
              <Flex style={{ width: '100%' }} vertical>
                <Progress
                  style={{ marginTop: '-8px', zIndex: 99 }}
                  strokeWidth={20}
                  strokeColor={emotionColor}
                  trailColor="#F5F5F5"
                  status="normal"
                  percent={classInfo?.emotionPercentage}
                />
              </Flex>
              <Text bold size="md" color={emotionColor}>
                {emotionName}
              </Text>
            </Flex>
            <Flex gap={12} vertical style={{ maxWidth: 152 }}>
              <Text size="md">
                <span style={{ color: colorPrimary, fontWeight: 700 }}>
                  {t('app.sérieTurma')}:{' '}
                </span>
                {classInfo?.serie} - {classInfo?.name}
              </Text>
              <Text size="md">
                <span style={{ color: colorPrimary, fontWeight: 700 }}>
                  {t('app.turno')} :{' '}
                </span>
                {t(`app.${classInfo?.period}`)}
              </Text>
              <Text size="md">
                <span style={{ color: colorPrimary, fontWeight: 700 }}>
                  {t('app.chave')} :{' '}
                </span>
                {classInfo?.license}
              </Text>
            </Flex>
          </Flex>

          <Flex style={{ width: '100%' }} justify="space-around">
            <Button
              variant="outline"
              size="lg"
              style={{
                display: 'flex',
                gap: isMobile ? '4px' : '16px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              disabled
            >
              <PiDownloadSimpleLight
                size={isMobile ? 22 : 28}
                color={colorGreyCard}
              />
              {t('app.relatorio').toUpperCase()}
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex style={{ width: '100%' }} gap={16} justify="space-between">
          <Flex justify="center" align="center" gap={58}>
            <Flex
              align="center"
              justify="start"
              style={{ marginRight: '24px' }}
              vertical
            >
              <img
                loading={'lazy'}
                src={defaultProfile}
                alt="Perfil da turma"
                style={{
                  width: '100px',
                  height: '100px',
                  zIndex: 1,
                  borderRadius: '50%',
                  border: `4px solid ${emotionColor}`,
                  objectFit: 'cover',
                }}
              />
              <Flex style={{ width: '100%' }} vertical>
                <Progress
                  style={{ marginTop: '-8px', zIndex: 99 }}
                  strokeWidth={20}
                  status="normal"
                  strokeColor={emotionColor}
                  trailColor="#F5F5F5"
                  percent={classInfo?.emotionPercentage}
                />
              </Flex>
              <p
                style={{
                  fontWeight: 600,
                  fontSize: '18px',
                  color: emotionColor,
                }}
              >
                {emotionName}
              </p>
            </Flex>
            {classInfo ? (
              <Flex gap={12} vertical>
                <Text size="md">
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    Série/Turma:{' '}
                  </span>
                  {classInfo?.serie} - {classInfo?.name}
                </Text>
                <Text size="md">
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    Turno:{' '}
                  </span>
                  {t(`app.${classInfo?.period}`)}
                </Text>
                <Text size="md">
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    Chave:{' '}
                  </span>
                  {classInfo?.license}
                </Text>
              </Flex>
            ) : (
              <Spin />
            )}
          </Flex>
          <Flex vertical style={{ maxWidth: '284px' }} justify="space-around">
            <Button
              variant="outline"
              size="lg"
              style={{
                display: 'flex',
                gap: isMobile ? '4px' : '16px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              disabled
            >
              <PiDownloadSimpleLight
                size={isMobile ? 22 : 28}
                color={colorGreyCard}
              />
              {t('app.relatorio').toUpperCase()}
            </Button>
          </Flex>
        </Flex>
      )}
    </GenericCard>
  );
}

export default ClassInformation;
