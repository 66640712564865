import { useQuery } from 'react-query';
import { IClass } from '../types';
import axios from 'infra/http';

function useAllClassesDetails(id: string) {
  const fetchClasses = async (id: string): Promise<IClass> => {
    const { data } = await axios.get<IClass>(`/v2/class/details/${id}`, {});

    return data;
  };

  return useQuery([`/v2/class/details/${id}`], () => fetchClasses(id), {
    enabled: !!id,
  });
}

export default useAllClassesDetails;
