import create from "zustand";
import { persist } from "zustand/middleware";
import { CurrentUser } from "../typings";

interface CurrentUserStore {
  user?: CurrentUser | null;
  token?: string | null;
  refreshToken?: string | null;
  update: (user?: CurrentUser) => void;
  updateToken: (token?: string) => void;
  updateRefreshToken: (refreshToken?: string) => void;
}

export const useCurrentUser = create<CurrentUserStore>(
  persist(
    (set) => ({
      user: undefined,
      update: (user) => {
        set((state) => ({ ...state, user: user || null }));
      },
      updateToken: (token) => {
        set((state) => ({ ...state, token: token || null }));
      },
      updateRefreshToken: (refreshToken) => {
        set((state) => ({ ...state, refreshToken: refreshToken || null }));
      },
    }),
    {
      name: "@u4hero",
      getStorage: () => localStorage,
    }
  )
);
