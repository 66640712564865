import { Flex, Form } from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import Table from 'components/V2/table';
import Status from 'components/status';
import { Pluralizar } from 'infra/helpers/plural';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import DoubleList from './DoubleList';
import Button from 'components/buttons/button';
import Tag from 'components/V2/tag';
import { useMediaQuery } from '@chakra-ui/media-query';
import ModalQrCode from 'components/modals/modal-qr-code';
import { t } from 'core/resources/strings';

interface Collaborator {
  id: string;
  name: string;
  profiles: string[];
  active: boolean;
}

function PedagogicalTeam({
  collaborators,
  temporary,
}: {
  collaborators: Collaborator[];
  temporary?: boolean;
}) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const { id, tenantId, mode } = useParams<{
    id: string;
    mode: string;
    tenantId: string;
  }>();
  const { colorPrimary } = useU4heroColors();
  const history = useHistory();
  const [isModalQrCodeOpen, setIsModalQrCodeOpen] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };

  return (
    <Flex vertical>
      {isModalQrCodeOpen && (
        <ModalQrCode
          id={id}
          type="educator"
          onClose={() => {
            setIsModalQrCodeOpen(false);
          }}
        />
      )}
      {!mode && !temporary && (
        <Form.Item name="collaborators">
          {/* @ts-ignore */}
          <DoubleList />
        </Form.Item>
      )}
      {(id || temporary) && (
        <Flex vertical gap={16}>
          {temporary && (
            <Button
              style={{ alignSelf: 'flex-end' }}
              size="md"
              onClick={() => {
                setIsModalQrCodeOpen(true);
              }}
            >
              {t('app.visualizarQrCode')}
            </Button>
          )}

          <GenericCard>
            <Flex gap={44} vertical style={{ width: '100%', height: '100%' }}>
              <Flex>
                <h1
                  style={{
                    fontWeight: 700,
                    fontSize: '24px',
                    color: colorPrimary,
                  }}
                >
                  {t('app.equipePedagogica')}
                </h1>
              </Flex>
              <Table
                itemName={Pluralizar(
                  t('app.colaborador').toLowerCase(),
                  collaborators.length > 1
                )}
                data={collaborators}
                page={page}
                onChangePage={setPage}
                pageSize={pageSize}
                onChangePageSize={setPageSize}
                total={collaborators.length}
                columns={[
                  {
                    title: (
                      <p style={tableHeaderStyle}>{t('app.nomePerfil')}</p>
                    ),
                    dataIndex: 'name',
                    key: 'name',
                    render: (name: string, record: Collaborator) => (
                      <Flex vertical>
                        <p style={{ fontSize: '16px' }}>{name}</p>
                        {record.profiles.map((item) => (
                          <Tag type="filled" text={item} />
                        ))}
                      </Flex>
                    ),
                    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
                    sortIcon: (sortOrder: any) =>
                      sortOrder.sortOrder === 'descend' ? (
                        <MdExpandLess size={25} />
                      ) : (
                        <MdExpandMore size={25} />
                      ),
                  },
                  {
                    title: <p style={tableHeaderStyle}>{t('app.situacao')}</p>,
                    dataIndex: 'active',
                    key: 'active',
                    width: '25%',
                    render: (active: boolean, record: Collaborator) => (
                      <Status size="lg" type={active ? 'active' : 'inactive'} />
                    ),
                    sorter: (a: Collaborator, b: Collaborator) =>
                      a.active ? 1 : -1,
                    sortIcon: (sortOrder: any) =>
                      sortOrder.sortOrder === 'descend' ? (
                        <MdExpandLess size={25} />
                      ) : (
                        <MdExpandMore size={25} />
                      ),
                  },
                ]}
              />
              {mode && (
                <Flex align="center" justify="center" style={{ width: '100%' }}>
                  <Button
                    size="md"
                    onClick={() => {
                      if (tenantId) {
                        history.push(`/schools/${tenantId}/classes/edit/${id}`);
                      } else {
                        history.push(`/my-school/classes/${id}/edit`);
                      }
                    }}
                  >
                    {t('app.editar')}
                  </Button>
                </Flex>
              )}
            </Flex>
          </GenericCard>
        </Flex>
      )}
    </Flex>
  );
}

export default PedagogicalTeam;
