import { useMediaQuery } from '@chakra-ui/react';
import { Col, Drawer, Flex, Form, Input, Row, Typography } from 'antd';
import Layout from 'components/layout';
import CustomizedRequiredMark from 'components/required-mark';
import { useCurrentUser } from 'core/features/user/store';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { IoClose, IoSaveOutline } from 'react-icons/io5';
import { PiEyeFill } from 'react-icons/pi';
import { useHistory, useParams } from 'react-router';
import CustomEditor from 'components/customEditor';
import CustomUpload from 'components/customUpload';
import SelectCategory from './components/selectCategory';
import Button from 'components/buttons/button';
import BackButton from 'components/buttons/back-button';
import Title from 'components/typography/title';
import SubTitle from 'components/typography/subTitle';
import useBlogPost from 'core/features/blog-posts/hooks/useBlogPost';
import { useSaveBlogPost } from 'core/features/blog-posts/hooks/useSaveBlogPost';
import { BlogPostForm } from 'core/features/blog-posts/typings';
import moment from 'moment';
import BlogPostPreview from './components/blogPostPreview';

function CreateBlogPost() {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { user } = useCurrentUser();
  const params = useParams<{
    id: string;
  }>();
  const { data } = useBlogPost(params.id);
  const { mutate: handleSave, isLoading:isLoadingSave } = useSaveBlogPost();
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  const [published, setPublished] = useState<boolean>(true);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  
  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const onSubmit = (values: BlogPostForm) => {
    handleSave(
      {
        ...values,
        published: published,
        id: params.id,
        publishDate: moment(values.publishDate).format('YYYY/MM/DD'),
      },
      {
        onError: () => {
          notificateError(
            `Houve um problema ao ${
              params.id ? 'editar' : 'publicar'
            }. Verifique sua conexão e tente novamente.`
          );
        },
        onSuccess: () => {
          notificateSucess(
            `${!published ? 'Publicação salva como rascunho!':`Publicação ${params.id ? 'editada' : 'concluída'} com sucesso!`}`
          );
          setTimeout(() => {
            history.push('/blog-posts/manager');
          }, 200);
        },
      }
    );
  };

  return (
    <Layout.Container>
      <Row gutter={[16, 0]}>
        <Col span={isPreviewOpen ? 12 : 24}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onSubmit}
            requiredMark={CustomizedRequiredMark}
            validateTrigger="submit"
            initialValues={
              data
                ? {
                    ...data,
                    publishDate:moment.utc(data.publishDate).format('YYYY-MM-DD')
                  }
                : {
                    publishDate: moment().format('YYYY-MM-DD'),
                  }
            }
          >
            <Flex gap="23px" align="center">
              <BackButton
                onClick={() => {
                  if (form.isFieldsTouched()) {
                    openNotification({
                      colorPrimary,
                      isMobile,
                      onClose: () => history.push('/blog-posts/manager'),
                    });
                  } else {
                    history.push('/blog-posts/manager');
                  }
                }}
              />

              <Title primary>{params.id ? 'Editar' : 'Criar'} Publicação</Title>
            </Flex>

            <Flex
              gap="16px"
              justify="end"
              style={{ marginBottom: isMobile ? '8px' : '16px' }}
            >
              <Button
                variant="outline"
                size="md"
                id="id-tour-posts-create-button-save"
                htmlType="submit"
                onClick={() => setPublished(false)}
                loading={isLoadingSave}
              >
                <IoSaveOutline color={colorPrimary} size={24} />
              </Button>

              <Button
                variant="outline"
                size="md"
                id="id-tour-posts-create-button-preview"
                style={{
                  display: 'flex',
                  gap: isMobile ? '4px' : '16px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => setIsPreviewOpen(true)}
              >
                <PiEyeFill size={isMobile ? 16 : 28} color={colorPrimary} />{' '}
                PRÉVIA
              </Button>

              <Button
                size="md"
                id="id-tour-posts-create-button-publish"
                htmlType="submit"
                loading={isLoadingSave}
              >
                {params.id ? 'SALVAR' : 'PUBLICAR'}
              </Button>
            </Flex>

            <Form.Item
              label="Título"
              rules={[
                {
                  required: true,
                  message: 'Por favor, crie um título para a publicação!',
                },
              ]}
              name="title"
            >
              <Input
                id="id-tour-create-post-input-title"
                placeholder="Crie um título de até 30 caracteres"
                size={isMobile ? 'middle' : 'large'}
              />
            </Form.Item>
            <Form.Item
              name="bannerUrl"
              label="Banner"
              rules={[
                {
                  required: true,
                  message: 'Por favor, carregue uma imagem para banner!',
                },
              ]}
            >
              {/* @ts-ignore */}
              <CustomUpload />
            </Form.Item>

            <Row align="middle" gutter={[32, 0]}>
              <Col span={isMobile ? 24 : 12}>
                <Form.Item
                  name="tag"
                  label="Categoria"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, defina uma categoria para a publicação!',
                    },
                  ]}
                >
                  {/* @ts-ignore */}
                  <SelectCategory />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 24 : 12}>
                <Form.Item
                  label="Data da Publicação"
                  name="publishDate"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, carregue uma imagem para banner!',
                    },
                  ]}
                >
                  <Input
                    id="id-tour-create-blog-post-input-publish-date"
                    type="date"
                    disabled={data && moment(data?.publishDate).isBefore(moment(), 'day')}
                    size={isMobile ? 'middle' : 'large'}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Conteúdo"
              rules={[
                {
                  required: true,
                  message: 'Por favor, escreva o conteúdo da publicação!',
                },
              ]}
              name="content"
            >
              {/* @ts-ignore */}
              <CustomEditor
                editorId="blog-post-editor"
                folders={{ images: 'posts-images', files: 'posts-files' }}
              />
            </Form.Item>
          </Form>
        </Col>
        {!isMobile ? (
          <Col
            span={isPreviewOpen ? 12 : 0}
            style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.40)' }}
          >
            <BlogPostPreview
              {...formValues}
              onClose={() => setIsPreviewOpen(false)}
              author={user?.name}
            />
          </Col>
        ) : null}
      </Row>

      <Drawer
        title={
          <Flex justify="space-between">
            <SubTitle
              primary
              style={{
                marginBottom: '16px',
              }}
            >
              Prévia
            </SubTitle>

            <IoClose
              style={{ cursor: 'pointer', justifySelf: 'end' }}
              size={24}
              color={colorGreyBorderAndFont}
              onClick={() => setIsPreviewOpen(false)}
            />
          </Flex>
        }
        placement={'right'}
        closable={false}
        open={isPreviewOpen && isMobile}
      >
        <BlogPostPreview
          {...formValues}
          onClose={() => setIsPreviewOpen(false)}
          author={user?.name}
        />
      </Drawer>
    </Layout.Container>
  );
}

export default CreateBlogPost;
