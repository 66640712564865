import useProcessing from 'core/features/inkluzen/hooks/useProcessing';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import DynamicTable from 'components/V2/dinamic-table';
import { useState } from 'react';
import useGeographic from 'core/features/inkluzen/hooks/useGeographic';

function GeographicTable({ params }: { params: any }) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const { data: processing, isLoading } = useGeographic({
    page,
    ...params
  });
  return (
    <DynamicTable
      itemName=""
      data={processing?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title="Dados Geográficos com Estatísticas de Depressão"
      total={processing?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isLoading}
      defaultColumns={[
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>ID</p>,
          name: 'ID',
          dataIndex: 'id',
          key: 'id',
          render: (id: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{id}</p>
          ),
        },
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>Município</p>,
          name: 'Município',
          dataIndex: 'locality',
          key: 'locality',
          render: (locality: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{locality}</p>
          ),
          sorter: (a: any, b: any) => a.locality.localeCompare(b.locality),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Total de Estudantes
            </p>
          ),
          name: 'Total de Estudantes',
          dataIndex: 'totalStudents',
          key: 'totalStudents',
          render: (totalStudents: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{totalStudents}</p>
          ),
          sorter: (a: any, b: any) => b.totalStudents - a.totalStudents,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Classificação Grave (Contexto)
            </p>
          ),
          name: 'Classificação Grave (Contexto)',
          dataIndex: 'seriousClassificationContext',
          key: 'seriousClassificationContext',
          render: (seriousClassificationContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{seriousClassificationContext}</p>
          ),
          sorter: (a: any, b: any) => b.seriousClassificationContext - a.seriousClassificationContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Classificação Moderado (Contexto)
            </p>
          ),
          name: 'Classificação Moderado (Contexto)',
          dataIndex: 'moderateClassificationContext',
          key: 'moderateClassificationContext',
          render: (moderateClassificationContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{moderateClassificationContext}</p>
          ),
          sorter: (a: any, b: any) => b.moderateClassificationContext - a.moderateClassificationContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Classificação Leve (Contexto)
            </p>
          ),
          name: 'Classificação Leve (Contexto)',
          dataIndex: 'lightClassificationContext',
          key: 'lightClassificationContext',
          render: (lightClassificationContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{lightClassificationContext}</p>
          ),
          sorter: (a: any, b: any) => b.lightClassificationContext - a.lightClassificationContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Classificação Não Manifesto (Contexto)
            </p>
          ),
          name: 'Classificação Não Manifesto (Contexto)',
          dataIndex: 'noManifestClassificationContext',
          key: 'noManifestClassificationContext',
          render: (noManifestClassificationContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{noManifestClassificationContext}</p>
          ),
          sorter: (a: any, b: any) => b.noManifestClassificationContext - a.noManifestClassificationContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
      ]}
      allColumns={[
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>ID</p>,
          name: 'ID',
          dataIndex: 'id',
          key: 'id',
          render: (id: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{id}</p>
          ),
        },
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>Município</p>,
          name: 'Município',
          dataIndex: 'locality',
          key: 'locality',
          render: (locality: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{locality}</p>
          ),
          sorter: (a: any, b: any) => a.locality.localeCompare(b.locality),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Total de Estudantes
            </p>
          ),
          name: 'Total de Estudantes',
          dataIndex: 'totalStudents',
          key: 'totalStudents',
          render: (totalStudents: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{totalStudents}</p>
          ),
          sorter: (a: any, b: any) => b.totalStudents - a.totalStudents,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Classificação Grave (Contexto)
            </p>
          ),
          name: 'Classificação Grave (Contexto)',
          dataIndex: 'seriousClassificationContext',
          key: 'seriousClassificationContext',
          render: (seriousClassificationContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{seriousClassificationContext}</p>
          ),
          sorter: (a: any, b: any) => b.seriousClassificationContext - a.seriousClassificationContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Classificação Grave (Significado)
            </p>
          ),
          name: 'Classificação Grave (Significado)',
          dataIndex: 'seriousClassificationMeaning',
          key: 'seriousClassificationMeaning',
          render: (seriousClassificationMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{seriousClassificationMeaning}</p>
          ),
          sorter: (a: any, b: any) => b.seriousClassificationMeaning - a.seriousClassificationMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Classificação Moderado (Contexto)
            </p>
          ),
          name: 'Classificação Moderado (Contexto)',
          dataIndex: 'moderateClassificationContext',
          key: 'moderateClassificationContext',
          render: (moderateClassificationContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{moderateClassificationContext}</p>
          ),
          sorter: (a: any, b: any) => b.moderateClassificationContext - a.moderateClassificationContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Classificação Moderado (Significado)
            </p>
          ),
          name: 'Classificação Moderado (Significado)',
          dataIndex: 'moderateClassificationMeaning',
          key: 'moderateClassificationMeaning',
          render: (moderateClassificationMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{moderateClassificationMeaning}</p>
          ),
          sorter: (a: any, b: any) => b.moderateClassificationMeaning - a.moderateClassificationMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Classificação Leve (Contexto)
            </p>
          ),
          name: 'Classificação Leve (Contexto)',
          dataIndex: 'lightClassificationContext',
          key: 'lightClassificationContext',
          render: (lightClassificationContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{lightClassificationContext}</p>
          ),
          sorter: (a: any, b: any) => b.lightClassificationContext - a.lightClassificationContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Classificação Leve (Significado)
            </p>
          ),
          name: 'Classificação Leve (Significado)',
          dataIndex: 'lightClassificationMeaning',
          key: 'lightClassificationMeaning',
          render: (lightClassificationMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{lightClassificationMeaning}</p>
          ),
          sorter: (a: any, b: any) => b.lightClassificationMeaning - a.lightClassificationMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Classificação Não Manifesto (Contexto)
            </p>
          ),
          name: 'Classificação Não Manifesto (Contexto)',
          dataIndex: 'noManifestClassificationContext',
          key: 'noManifestClassificationContext',
          render: (noManifestClassificationContext: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{noManifestClassificationContext}</p>
          ),
          sorter: (a: any, b: any) => b.noManifestClassificationContext - a.noManifestClassificationContext,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Classificação Não Manifesto (Significado)
            </p>
          ),
          name: 'Classificação Não Manifesto (Significado)',
          dataIndex: 'noManifestClassificationMeaning',
          key: 'noManifestClassificationMeaning',
          render: (noManifestClassificationMeaning: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{noManifestClassificationMeaning}</p>
          ),
          sorter: (a: any, b: any) => b.noManifestClassificationMeaning - a.noManifestClassificationMeaning,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
       
      ]}
    />
  );
}

export default GeographicTable;
