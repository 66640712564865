import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Tabs } from 'antd';
import Layout from 'components/layout';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Title from 'components/typography/title';
import { NumberParam, useQueryParam } from 'use-query-params';
import PracticesTab from './tabs/PracticesTab';
import BackButton from 'components/buttons/back-button';
import { useHistory } from 'react-router';
import PracticesFeedbacksTab from './tabs/PracticesFeedbacksTab';
import { t } from 'core/resources/strings';

function PracticesManager() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [step, setStep] = useQueryParam('tab', NumberParam);
  const { colorGreyBorderAndFont } = useU4heroColors();
  const history = useHistory();

  if (!step) {
    setStep(1);
  }
  const items = [
    {
      key: '1',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 1 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          {t('app.inspiracoes').toUpperCase()}
        </p>
      ),
      children: <PracticesTab />,
    },
    {
      key: '2',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 2 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          {t('app.feedbacks').toUpperCase()}
        </p>
      ),
      children: <PracticesFeedbacksTab />,
    },
  ];

  return (
    <Layout.Container>
      <Flex gap="23px" align="center">
        <BackButton onClick={() => history.push('/practices')} />
        <Title primary>{t('app.gerenciarInspracoes')}</Title>
      </Flex>

      <Tabs
        centered
        onTabClick={(event) => setStep(Number(event))}
        size="large"
        defaultActiveKey={step?.toString()}
        items={items}
      />
    </Layout.Container>
  );
}

export default PracticesManager;
