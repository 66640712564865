import styled from 'styled-components';
export const HelperContainer = styled.div`
  blockquote {
    border-left: 5px solid #ccc;
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  ol,
  ul {
    padding-inline-start: 40px !important;
  }
`;
