function GraduationCapIcon({ size = 50 }: { size?: number }) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 64 64"
    style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: 2 }}
  >
    <g transform="matrix(1,0,0,1,-428,-328)">
      <g id="Artboard1" transform="matrix(1,0,0,1,420,0)">
        <rect x="0" y="0" width="400" height="400" style={{ fill: "none" }} />
        <g transform="matrix(1,0,0,1,-447.124,0)">
          <path d="M466.124,358.72L466.124,374C466.124,374.475 466.311,374.985 466.753,375.48C467.188,375.967 467.921,376.479 468.929,376.957C472.341,378.574 479.209,380 487.124,380C495.039,380 501.907,378.574 505.318,376.957C506.327,376.479 507.06,375.967 507.495,375.48C507.936,374.985 508.124,374.475 508.124,374C508.124,374 508.124,358.72 508.124,358.72L512.124,356.934L512.124,371.671C510.959,372.082 510.124,373.194 510.124,374.5L510.124,378.5C510.124,379.052 510.572,379.5 511.124,379.5L515.124,379.5C515.676,379.5 516.124,379.052 516.124,378.5L516.124,374.5C516.124,373.194 515.289,372.082 514.124,371.671L514.124,356.042L515.532,355.413C515.892,355.252 516.124,354.895 516.124,354.5C516.124,354.105 515.892,353.748 515.532,353.587L487.532,341.087C487.272,340.971 486.976,340.971 486.716,341.087L458.716,353.587C458.356,353.748 458.124,354.105 458.124,354.5C458.124,354.895 458.356,355.252 458.716,355.413L466.124,358.72ZM506.124,359.613L487.532,367.913C487.272,368.029 486.976,368.029 486.716,367.913L468.124,359.613L468.124,373.974C468.144,374.004 468.266,374.179 468.379,374.282C468.701,374.576 469.18,374.863 469.786,375.15C473.035,376.69 479.585,378 487.124,378C494.663,378 501.213,376.69 504.462,375.15C505.068,374.863 505.546,374.576 505.869,374.282C505.982,374.179 506.104,374.004 506.124,373.974L506.124,359.613ZM514.124,374.5L514.124,377.5C514.124,377.5 512.124,377.5 512.124,377.5C512.124,377.5 512.124,374.5 512.124,374.5C512.124,373.948 512.572,373.5 513.124,373.5C513.676,373.5 514.124,373.948 514.124,374.5ZM513.124,373.5M487.124,365.905L512.671,354.5L487.124,343.095L461.577,354.5L487.124,365.905Z" />
        </g>
      </g>
    </g>
  </svg>
  );
}

export default GraduationCapIcon;
