import { Select } from 'antd';
import { useMediaQuery } from '@chakra-ui/react';

interface SelectYearProps {
  value: string;
  onChange: (v: string) => void;
  updateForm?: (field: string, value: any) => void;
}

function SelectYear({
  value,
  onChange,
  updateForm,
}: SelectYearProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  let optionsYears: any = [];
  const year = new Date().getFullYear();
  for (let i = year; i > 2019; i--) {
    optionsYears.push({
      value: i,
      label: i.toString(),
    });
  }


  return (
    <Select
      id="id-tour-indicators-custom-panel-year-input"
      placeholder="Defina o ano à ser analisado"
      options={optionsYears}
      onChange={(v) => {
        onChange(v);
        // updateForm('subClassification', undefined);
      }}
      value={value}
      size={isMobile ? 'middle' : 'large'}
    />
  );
}

export default SelectYear;
