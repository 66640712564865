import {translate} from '../polyglot';

export default translate('es', {
  mensagens: {
    desativar: '¿De verdad quieres inhabilitar?',
    ativar: '¿Realmente quieres activar?',
    erro: 'Hubo un problema al realizar la operación.',
    sucesso: 'Operación realizada con éxito.',
    semPermissao: '¡No tienes permiso para usar esta función!',
    campoObrigatorio: 'Este campo es requerido.',
    valorMinimo: 'El valor mínimo para este campo es',
    trocarSenha: '¿Cambia la contraseña?',
    introPDS:
      'Complete todos los campos para acceder a los gráficos e informes.',
    bemVindoLogin: 'Olá, Preparado para usar o PDS?',
    recuperarSenhaSucesso:
      'Se envió un correo electrónico con instrucciones para recuperar la contraseña',
    usuarioNaoEncontrado: 'Usuario no encontrado',
    erroLogin: 'Error al iniciar sesión',
    testeDeHumor: '¿Prueba de humor?',
    cabecalhoRelatorio: `Los informes socioemocionales son preparados por especialistas a partir de observaciones obtenidas por el sesgo lúdico.\n
    Mientras el estudiante se divierte en las situaciones/problemas del juego, toma decisiones, se expresa y presenta su perspectiva. De esta manera, los profesores pueden entenderlo mejor a través de sus elecciones y comportamientos ,y junto con un equipo multidisciplinario dedicado llevar a cabo las posibles intervenciones.\n
    La información recogida en los juegos crea a lo largo del año escolar, una base de datos que contiene hipótesis de comportamiento para que el personal docente, obtenga evaluaciones cualitativas complementarias para enfoques más asertivos. Así todos pueden contribuir al buen progreso socioemocional de los estudiantes y a un mejor aprendizaje y manejo de sus emociones.\n
    `,

    selecioneCapitulo: 'Seleccione el capítulo',
    selecioneJogo: 'Selecciona el juego',
    informeEmail: 'Ingrese correo electrónico'
  },
});
