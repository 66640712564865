import { useQuery } from "react-query";
import axios from "infra/http";
import { TagDetails } from "../types";


function useTagV2(id?: string) {
  const fetchData = async (id: string): Promise<TagDetails> => {
    const { data } = await axios.get<TagDetails>(`/v2/tags/${id}`);

    return data;
  };

  return useQuery([`/v2/tags/${id}`], () => fetchData(id!), {
    enabled: !!id,
  });
}

export default useTagV2;
