import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Form, Modal, Row, Steps, Switch } from 'antd';
import AdressInfoSection from '../../sections/AdressInfoSection';
import ColorsSection from '../../sections/ColorsSection';
import ContactInfoSection from '../../sections/ContactInfoSection';
import SchoolInfoSection from '../../sections/SchoolInfoSection';

function SchoolInfoStep({
  tenantId,
  updateForm,
  defaultZipCode,
}: {
  tenantId?: string;
  updateForm: (v: any) => void;
  defaultZipCode?: string;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Row gutter={[32, 32]} align="stretch">
      <Col span={isMobile ? 24 : 18} order={1}>
        <Flex vertical={true} gap="32px">
          <SchoolInfoSection />
          <ContactInfoSection tenantId={tenantId} />
        </Flex>
      </Col>
      <Col span={isMobile ? 24 : 6} order={isMobile ? 3 : 2}>
        <ColorsSection updateForm={updateForm} />
      </Col>
      <Col span={24} order={isMobile ? 2 : 3}>
        <AdressInfoSection defaultZipCode={defaultZipCode} />
      </Col>
    </Row>
  );
}

export default SchoolInfoStep;
