import { GenericCard } from 'components/V2/generic-card-styled-component';
import { Flex } from 'antd';
import { IconType } from 'react-icons';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import React from 'react';

function ManageCard({ isMobile, icon, title,id }: {isMobile:boolean,icon:IconType,title:string,id:string}) {
  const {colorPrimaryLight } = useU4heroColors();
  return (
    <GenericCard padding='24px 16px' hover={colorPrimaryLight} id={id}>
      <Flex gap='8px' style={{ width: '100%' }} align='center' justify='center'>
        {React.createElement(icon, { size: isMobile ? 42 : 78, color: 'black' })}
        <p style={{ fontSize: isMobile ? '14px' : '24px', color: 'black', fontWeight: 400, width: '224px', textAlign: 'center' }}>
          {title}
        </p>
      </Flex>
    </GenericCard>
  );
}

export default ManageCard;