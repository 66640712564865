function ComputerIcon({ size = 40 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="40" height="40" fill="url(#computer)" />
      <defs>
        <pattern
          id="computer"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#computericon" transform="scale(0.01)" />
        </pattern>
        <image
          id="computericon"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADMElEQVR4nO2dv2oUURjFTwTZyio2KmiltVr75x1U0LyARjubjSkUy5CsPoPWeQKT4Aqm07yLMRKSamXkDnwuu9FmvnPuzvnBJTApvrO/787szRDuBYwxxhhjjDHGGGOM6Q8XAawAGAJY6+kYFgfLzEYMALwDcApg4oFJcTEqbtKb8cVNwLyJOM5uyvtQ/BuAO4xZIcSgOPgevGxlfmechmaczypcAY2Lg+LmJOs7ZSXMgmZWmL+5F/w8QQKvQsE+P6bmMQh+Gled8zYUNLNp/TSu6A3p2xJ4Fm4I3JB/zo59ABsLOvZrvENSwpCQciAVhoSUA6kwJKQcSIUhIeVAKgwJKQdSYUhIOZAKQ0LKgVQYElIOpMKQkHIgFYaElAOpMCSkHEiFISHlQCoMCSkHUmFISDmQCkNCyoFUGBJSDqTCkJByIBWGhJQDqTAkpBxIhSEh5UAqDAkpB1JhSEg5kApDQsqBVBgSUg6kwpCQciAVhoSUA6kwJKQcSIUhIeVAKgwJKQdSYUhIOZAKQ0LKgVQYElIOpMKQkHIgFYaElAOpMCSkHEiFISHlQCoMCSkH/xumL2MWbgjckLmzY6Nng36HvAkFlzIKVsZS8NO46pxnoeCljIKVcTn4eZpR8HYo+DqjYGXEJ8itrKLtXh/NznIPsopWwMOw297XzMI3AByGmfAZwGr4/RUAL8vPPlxbLQ5aHz8AXEcyNwEcT+3E2bJdrm335No4eDgublI5B+Dj1No/NuQFgCMAz3tybTzl4kNxlMZaKO5tYjFzm9hmt+sULgD4GZrhbWJnbxN7WFx1zqMwC7xN7NnbxDarrs4ZhoLeJvbsbWKHCm97DbTe9i4y1wDsAfgFYBfA1VoaMiqBWWPU0Wffm1re7tTSkOl1efYYd/TZj6bqNKvNKhqyqHfIbq13yCJ/h+yUO+VTTd8hfWeS2RAfVyF2XEU80OVuRsHKuB/8PM468uikFGxepvldFvnII5RVTDsLDsr7mz6/RhkUB20zmrGZHYD9t8ZEeKQfm9c2ZSs8vjzwx8Um+2mxXE4jWxf4p7UN0lgvDqhHrxpjjDHGGGOMMcYYg0R+A52yUMunF0PuAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
}

export default ComputerIcon;
