import { useQuery } from "react-query";
import axios from "infra/http";
import { StudentItem } from "../typings";

function useStudent(id?: string) {
  const fetchData = async (id: string): Promise<StudentItem> => {
    const { data } = await axios.get<StudentItem>(`/v1/student/${id}`);

    return data;
  };

  return useQuery([`/v1/student/${id}`], () => fetchData(id!), {
    enabled: !!id,
  });
}

export default useStudent;
