import { useQuery } from 'react-query';
import axios from 'infra/http';
import {  ProcessingDetails } from '../typings';
import { useEffect, useState } from 'react';

export interface UseProcessingParams {
  page:number;
  integrationId?:string;
  initialDate?: string;
  finalDate?: string;
  search?: string;
  limit?:number;
}

function useProcessing(params: UseProcessingParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);
  const fetchData = async (
    params: UseProcessingParams
  ): Promise<{ data: ProcessingDetails[]; total:number }> => {
    const { data ,headers } = await axios.get<ProcessingDetails[]>(
      `/v2/inkluzen/processing`,
      {
        params: {
          page:params.page,
          integrationId:params.integrationId,
          initialDate: params.initialDate,
          finalDate: params.finalDate,
          search: debouncedQuery,
          limit: params.limit,
        },
      }
    );

    return { data: data, total: Number(headers["total"]) };
  };

  return useQuery(
    [
      `/v2/inkluzen/processing`,
      { ...params, search: debouncedQuery },
    ],
    () => fetchData(params),
  );
}

export default useProcessing;
