import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { t } from 'core/resources/strings';

function SearchBar({
  onSearch,
  id,
  width,
  placeholder = t('app.pesquisar'),
  value
}: {
  onSearch: (v: string) => void;
  id?: string;
  width?: string;
  placeholder?: string;
  value?: string;
}) {
  const { colorPrimary } = useU4heroColors();
  return (
    <Input
      id={id ? id : 'id-tour-search-bar'}
      prefix={<SearchOutlined style={{ color: colorPrimary, fontSize: 25 }} />}
      placeholder={placeholder}
      size="large"
      value={value}
      allowClear
      className="box-shadow-generic-card"
      onChange={(e) => onSearch(e.target.value)}
      style={width ? { width: width } : { maxWidth: 300 }}
    />
  );
}

export default SearchBar;
