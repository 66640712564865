import { Flex, Form } from 'antd';
import DoubleList from './DoubleList';

function StudentsTab() {
  return (
    <Flex vertical>
      <Form.Item
        name={'students'}
      >
        {/* @ts-ignore */}
        <DoubleList />
      </Form.Item>
    </Flex>
  );
}

export default StudentsTab;
