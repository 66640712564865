import { Flex } from 'antd';
import Button from 'components/buttons/button';
import ModalTag from 'components/modals/modal-tag';
import { useState } from 'react';
import SkillsTable from '../../SkillsTable';

function SkillTab() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [id, setId] = useState<string>();
  return (
    <Flex vertical gap="16px">
      {isOpenModal ? (
        <ModalTag id={id} type="skill" onClose={() => {
          setId(undefined);
          setIsOpenModal(false);
        }} />
      ) : null}
      <Button
        id="id-tour-create-skill-button-add-skill"
        size={'md'}
        onClick={() => setIsOpenModal(true)}
        style={{
          alignSelf: 'end',
        }}
      >
        CRIAR HABILIDADE
      </Button>
      <SkillsTable
        onEdit={(v) => {
          setId(v);
          setIsOpenModal(true);
        }}
      />
    </Flex>
  );
}

export default SkillTab;
