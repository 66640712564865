import { useQuery } from "react-query";
import axios from "infra/http";
import { CountClassification} from "../typings";

export interface UseAvaliationsParams {
  initialDate?:string;
  endDate?:string;
}

function useSafeSpaceAvaliations(params: UseAvaliationsParams) {
  const fetchData = async (): Promise<CountClassification> => {
    const { data } = await axios.get<CountClassification>(`v2/safeSpace/avaliations`,{
      params:{
        initialDate:params.initialDate,
      }
    });

    return data;
  };

  return useQuery([`v2/safeSpace/avaliations`], () => fetchData() );
}

export default useSafeSpaceAvaliations;
