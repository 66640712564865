import SelectBase, { SelectBaseProps } from "../select-base";
import { useState } from "react";
import useTagsCompetence from "core/features/tags/hooks/useTagsCompetence";

export interface SelectTagProps<T = any> extends SelectBaseProps<T> {
  value: any;
  parentId?: string;
  competence?: boolean;
  onChange: (v: any) => void;
}

function SelectTag({ value, onChange, parentId, competence, ...rest }: SelectTagProps) {
  const [search, setSearch] = useState<string>("");

  const { data, isLoading, isFetching } = useTagsCompetence({tag: parentId, competence: competence });
  
  return (
    <SelectBase
      loading={isLoading || isFetching}
      value={value?.name ? value?.name['pt-br'] : value?.name}
      onChange={(ev) => {
        onChange(ev);

      }}
      searchValue={search}
      onSearch={(v) => setSearch(v)}
      options={
        data?.map((item) => ({
          label: item?.name['pt-br'],
          value: JSON.stringify(item)
        })) ?? []
      }
      {...rest}
    />
  );
}

export default SelectTag;
