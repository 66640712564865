import axios from 'axios';
import moment from 'moment';
import { useQuery } from 'react-query';
import { baseUrlQedu, DistorcaoSerie, QeduParams } from '../types';

export function useDistorcaoSerie(params: QeduParams) {
  const fetchData = async (params: QeduParams): Promise<DistorcaoSerie> => {
    let currentYear = moment().year();
    let result: DistorcaoSerie[] = [];

    while (currentYear >= 2000) {
      const { data } = await axios.get<{ data: DistorcaoSerie[] }>(
        `${baseUrlQedu}/indicador/dis/escola`,
        {
          params: { ...params, ano: currentYear },
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_QEDU_API_KEY}`,
          },
        }
      );

      if (data.data.length > 0) {
        result = data.data;
        break;
      }

      currentYear--;
    }

    return result?.[0];
  };

  return useQuery([`distorcao-serie`, params], () => fetchData(params), {
    enabled: !!params?.inep_id,
  });
}
