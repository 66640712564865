import { useQuery } from "react-query";
import axios from "infra/http";
import { GameDetails } from "../typings";
import { useEffect, useState } from "react";

export interface UseGamesParams {
  page: number;
  limit?: number;
  search?: string;
  active?:boolean;
  ageClassification?: number;
}

function useGamesv1(params: UseGamesParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchData = async (
    params: UseGamesParams
  ): Promise<{ data: GameDetails[]; total: number }> => {
    const { data, headers } = await axios.get<GameDetails[]>(`/v1/game`, {
      params: {
        ...params,
        search: debouncedQuery,
        
      },
    });

    return { data: data, total: Number(headers["total"]) };
  };

  return useQuery(
    [`/v1/game`, {...params,search:debouncedQuery}],
    () => fetchData(params),
    {
      keepPreviousData: true,
    }
  );
}

export default useGamesv1;
