import { useQuery } from "react-query";
import axios from "infra/http";
import { ResourceItem } from "../types";

export interface UseResourcesParams {
  tenantId?:string;
}
function useTenantResources(params:UseResourcesParams) {
  return useQuery(`/v2/resources/tenant`, async (): Promise<ResourceItem[]> => {
    const { data } = await axios.get(`/v2/resources/tenant`,{
      params:{
        tenantId:params?.tenantId
      }
      ,
    });

    return data;
  },
  {
    enabled:params.tenantId !== undefined
  });
}

export default useTenantResources;