import { Switch, Route } from 'react-router-dom';
import QuickAccess from 'ui/pages/auth/quick-acess';
import LoginPage from './login';
import RecoverPage from './recover';
import Register from './register';
import ResetPage from './reset';

function AuthPages() {
  return (
    <Switch>
      <Route exact path="/auth/login">
        <LoginPage />
      </Route>
      <Route exact path="/auth/recover">
        <RecoverPage />
      </Route>
      <Route exact path="/auth/recover/:token">
        <ResetPage />
      </Route>
      <Route exact path="/auth/register/:id">
        <Register />
      </Route>
      <Route exact path="/auth/quick-access/:id">
        <QuickAccess />
      </Route>
    </Switch>
  );
}

export default AuthPages;
