function IndividualIcon({ size = 50 }: { size?: number }) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 96 96"
      height={size}
      width={size}
    >
      <path d="M70.16,20.67a1,1,0,0,1-.37-1.93l9.35-3.69-31.86-11-31.86,11,8.63,3.42a1,1,0,0,1-.33,1.94h0a1,1,0,0,1-.37-.07L12.17,15.92a1,1,0,0,1,0-1.88L47,2.05a1,1,0,0,1,.65,0L82.34,14a1,1,0,0,1,.67.89,1,1,0,0,1-.55,1l-.1,0L70.52,20.6A.92.92,0,0,1,70.16,20.67Z" />
      <path d="M82,33.75a1,1,0,0,1-1-1V15a1,1,0,1,1,2,0V32.75A1,1,0,0,1,82,33.75Z" />
      <path d="M82,38.59a3.42,3.42,0,1,1,3.42-3.42A3.42,3.42,0,0,1,82,38.59Zm0-4.84a1.42,1.42,0,1,0,1.42,1.42A1.43,1.43,0,0,0,82,33.75Z" />
      <path d="M82,48.28c-1.28,0-3.42-.71-3.42-5.44A16.25,16.25,0,0,1,80,37a1,1,0,1,1,1.84.78,14.52,14.52,0,0,0-1.3,5.1c0,1.57.25,3.44,1.42,3.44s1.42-1.87,1.42-3.44a14.31,14.31,0,0,0-1.3-5.1A1,1,0,0,1,84,37a16.51,16.51,0,0,1,1.45,5.88C85.41,47.57,83.27,48.28,82,48.28Z" />
      <path d="M70.49,27.9l-.17,0a136.57,136.57,0,0,0-47.12,0,1,1,0,0,1-.81-.22A1,1,0,0,1,22,26.9c0-2.47,0-4.93,0-7.4a1,1,0,0,1,.84-1,146.08,146.08,0,0,1,48.21.3,1,1,0,0,1,.83,1q-.21,3.56-.47,7.11a1,1,0,0,1-.38.71A1,1,0,0,1,70.49,27.9ZM46.76,23.84a138.83,138.83,0,0,1,22.81,1.88l.33-5.08a143.87,143.87,0,0,0-45.84-.28c0,1.79,0,3.57,0,5.35A139,139,0,0,1,46.76,23.84Z" />
      <path d="M47,72.59c-8,0-22.89-7.86-22.89-22.9a1,1,0,0,1,2,0c0,13.72,13.6,20.9,20.89,20.9s20.89-7.18,20.89-20.9a1,1,0,0,1,2,0C69.88,64.73,55,72.59,47,72.59Z" />
      <path d="M70.44,55a3.22,3.22,0,0,1-2.31-1.11,1,1,0,1,1,1.52-1.3,1.32,1.32,0,0,0,.79.41c.66,0,1.65-1.57,1.65-4a5.16,5.16,0,0,0-1.22-3.76,1,1,0,1,1,1.25-1.55c1.22,1,2,3,2,5.31C74.09,52.43,72.52,55,70.44,55Z" />
      <path d="M23.54,55c-2.08,0-3.65-2.56-3.65-6,0-2.3.76-4.33,2-5.31a1,1,0,1,1,1.26,1.55A5.16,5.16,0,0,0,21.89,49c0,2.38,1,4,1.65,4a1.3,1.3,0,0,0,.8-.43,1,1,0,1,1,1.51,1.3A3.21,3.21,0,0,1,23.54,55Z" />
      <path d="M25.1,50.69a1,1,0,0,1-.76-.34c-.29-.34-7-8.33-2.26-23.75a1,1,0,0,1,1.91.59c-3,9.76-1.11,16.31.45,19.52.71-5.42,2.46-15.62,5.72-17.36,2.29-1.23,9.51-.27,15.16.75a9.86,9.86,0,0,0,3.34,0c5.65-1,12.87-2,15.16-.75,3.26,1.74,5,11.91,5.72,17.34,1.55-3.24,3.46-9.8.45-19.5a1,1,0,1,1,1.91-.59C76.68,42,69.93,50,69.64,50.35a1,1,0,0,1-1.06.29,1,1,0,0,1-.69-.85c-.71-7-2.74-17.46-5-18.68-1-.56-5.23-.6-13.87,1a11,11,0,0,1-4,0c-7.12-1.28-12.56-1.65-13.87-1-2.27,1.22-4.3,11.66-5,18.68a1,1,0,0,1-.69.85A.79.79,0,0,1,25.1,50.69Z" />
      <path d="M82.39,94H11.59a1,1,0,0,1-1-1,18,18,0,0,1,18-18H40.5a1,1,0,0,1,0,2H28.58a16,16,0,0,0-16,15H81.35A16,16,0,0,0,65.4,77H53.47a1,1,0,0,1,0-2H65.4a18,18,0,0,1,18,18A1,1,0,0,1,82.39,94Z" />
      <path d="M47,79.65a10.21,10.21,0,0,1-7.19-2.93A1,1,0,0,1,39.5,76v-5.8a1,1,0,0,1,2,0v5.37a8.28,8.28,0,0,0,11,0V70.21a1,1,0,0,1,2,0V76a1,1,0,0,1-.29.71A10.21,10.21,0,0,1,47,79.65Z" />
    </svg>
  );
}

export default IndividualIcon;
