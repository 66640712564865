import React from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Tooltip } from 'antd';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import { Pluralizar } from 'infra/helpers/plural';
import { FiTrash2 } from 'react-icons/fi';

interface StatCardProps {
  title: string;
  total: number;
  Icon: React.ComponentType<{ size: number }>;
  onRemove?: () => void;
}

const StatCard: React.FC<StatCardProps> = ({
  title,
  total,
  Icon,
  onRemove,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Flex
      style={{
        background: 'rgba(244, 244, 244, 0.80)',
        padding: '16px',
        borderRadius: isMobile ? '20px' : '10px',
        width: '100%',
        gap: '24px',
        position: 'relative',
      }}
    >
      {onRemove && (
        <Tooltip title="Deletar">
          <FiTrash2
            onClick={onRemove}
            style={{
              position: 'absolute',
              top: 8,
              right: 8,
              fontSize: 16,
              color: 'black',
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      )}

      <Icon size={isMobile ? 24 : 50} />
      <Flex vertical>
        <Title primary>{total}</Title>
        <Text size="sm">{Pluralizar(title, total > 1)}</Text>
      </Flex>
    </Flex>
  );
};

export default StatCard;
