import {
  ConversationContainer,
  MessageContainer,
  UserProfile,
} from '../styles';
import { Image } from '@chakra-ui/image';
import { Message } from 'core/features/chat/types';
import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import moment from 'moment';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';

const Conversation = ({ messages }: { messages: Message[] }) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary,colorSecondary } = useU4heroColors();
  return (
    <ConversationContainer>
      {messages.map((m, index) => (
        <MessageContainer
          key={index}
          incomingMessage={m.responsibleId !== m.senderId}
        >
          {m.senderId === m.responsibleId && m.responsibleImage !== null ? (
            <Image
              bg="white"
              w={isMobile ? '38px' : '48px'}
              h={isMobile ? '38px' : '48px'}
              rounded="32px"
              src={m.responsibleImage}
              mr="20px"
            />
          ) : (
            <UserProfile
              content={
                m.senderId === m.responsibleId
                  ? m.responsibleName
                  : m.studentName
              }
            />
          )}
          <Flex
            vertical
            style={{
              borderRadius: '10px',
              borderRight: m.senderId === m.responsibleId ? `3px solid ${hex2rgba(colorPrimary,0.50)} `:'',
              borderLeft: m.senderId !== m.responsibleId ? `3px solid ${hex2rgba(colorSecondary,0.50)}` :'',
              borderBottom: `3px solid ${m.senderId === m.responsibleId ? hex2rgba(colorPrimary,0.50):hex2rgba(colorSecondary,0.50)}`,
              background: m.senderId === m.responsibleId ? hex2rgba(colorPrimary,0.70): hex2rgba(colorSecondary,0.70),
              padding: '4px 8px',
              width: '100%',
            }}
          >
            <Flex justify="space-between">
              <p
                style={{
                  fontSize: isMobile ? '14px' : '16px',
                  fontWeight: 600,
                }}
              >
                {m.senderId === m.responsibleId ? 'Eu' : m.studentName}
              </p>
              <p
                style={{
                  fontSize: isMobile ? '14px' : '16px',
                  fontWeight: 600,
                }}
              >
                {moment(m.createdAt).format('HH[h]mm')}
              </p>
            </Flex>
            {m.message}
          </Flex>
        </MessageContainer>
      ))}
    </ConversationContainer>
  );
};

export default Conversation;
