import GenericFilterComponent from 'components/generic-filter';
import useToursAuthors from 'core/features/tour/hooks/useToursAuthors';
import { FilterProps } from 'infra/helpers/types';

function TourFilter({ filters, onChangeFilters }: FilterProps) {
  const { data: authors } = useToursAuthors();

  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: 'Autor',
          filterName: 'authors',
          options:
            authors?.map((item) => {
              return { label: item.name, value: item.id };
            }) ?? [],
        },
        {
          title: 'Status',
          options: [
            { label: 'Ativo', value: 1 },
            { label: 'Inativo', value: 2 },
          ],
          filterName: 'status',
          type: 'radio',
        },
        {
          title: 'Data de publicação',
          filterName: 'publishDate',
          options: [],
          type: 'date',
          open: true,
        },
      ]}
    />
  );
}

export default TourFilter;
