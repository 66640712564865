import { useMutation } from "react-query";
import http from "infra/http";
import { LoginForm } from "../typings";

export function useQuickAccess() {
  return useMutation(
    (data: {
      username: string;
      classId: string;
      isStudent:boolean;
    }) => {
      return http.post(`/v1/auth/quick-access`, data);
    },
    { retry: false }
  );
}
