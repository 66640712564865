import { useQuery } from 'react-query';
import axios from 'infra/http';
import { Game, GameHistory } from '../typings';

export interface UseGamesHistoryParams {
  startDate:string;
}

function useGamesHistory(params?:UseGamesHistoryParams) {
  const fetchData = async (params?:UseGamesHistoryParams): Promise<{ data: GameHistory[]; total: number }> => {
    const { data, headers } = await axios.get<GameHistory[]>(`/v2/game/history`,{
      params
    });

    return { data: data, total: Number(headers['total']) };
  };

  return useQuery([`/v2/game/history`], () => fetchData(params), {
    keepPreviousData: true,
  });
}

export default useGamesHistory;
