import { useMediaQuery } from '@chakra-ui/react';
import { Dropdown, Flex, Menu } from 'antd';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { Permissions } from 'core/resources/enums/permissions';
import moment from 'moment';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import DynamicTable from 'components/V2/dinamic-table';
import { FaEllipsisVertical } from 'react-icons/fa6';
import {
  notificateError,
  notificateSucess,
  showConfirm,
} from 'infra/helpers/notifications';
import { useHistory } from 'react-router';
import { PiEyeLight, PiEyeSlash } from 'react-icons/pi';
import PostFilter from '../PostFilter';
import usePosts from 'core/features/posts/hooks/usePosts';
import { PostDetails } from 'core/features/posts/typings';
import { useDeletePosts } from 'core/features/posts/hooks/useDeletePosts';
import { FilterType } from 'infra/helpers/types';

function PostsTable({onViewFeedback}:{onViewFeedback:(v:string)=> void}) {
  const [check] = useCheckPermissions();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const [filters, setFilters] = useState<FilterType>({
    authors: [],
    published: [],
    publishDate: [],
  });
  const { data, isLoading, refetch, isFetching } = usePosts({
    page,
    publishDate: filters?.publishDate.map((item) => item.value)[0] ?? undefined,
    limit: pageSize,
    search,
    authors: filters.authors.map((item) => item.value) ?? undefined,
    published: filters.published.length
      ? filters.published.map((item) => item.value)[0] === 1
      : undefined,
  });

  const { mutate: handleDelete } = useDeletePosts();
  const [detailsVisibility, setDetailsVisibility] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const isEachLoading = isLoading || isFetching;
  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };
  const columns = [
    {
      title: <p style={tableHeaderStyle}>Título</p>,
      name: 'Título',
      dataIndex: 'title',
      key: 'title',
      mobile: true,
      render: (game: string, record: PostDetails) => (
        <div>
          <p style={tableRowStyle}>{record.title}</p>
          {detailsVisibility[record.id] && (
            <Flex vertical>
              <Flex>
                <p
                  style={{ fontSize: 14, color: colorPrimary, fontWeight: 600 }}
                >
                  Autor:{' '}
                  <span style={{ color: 'black', fontWeight: 400 }}>
                    {record.author}
                  </span>
                </p>
              </Flex>
              <Flex>
                <p
                  style={{ fontSize: 14, color: colorPrimary, fontWeight: 600 }}
                >
                  Tipo:{' '}
                  <span style={{ color: 'black', fontWeight: 400 }}>
                    {record.published ? 'Publicado' : 'Rascunho'}
                  </span>
                </p>
              </Flex>
              <Flex>
                <p
                  style={{ fontSize: 14, color: colorPrimary, fontWeight: 600 }}
                >
                  Data:
                  <span style={{ color: 'black', fontWeight: 400 }}>
                    {moment.utc(record.updatedAt).format('DD/MM/YYYY')}
                  </span>
                </p>
              </Flex>
            </Flex>
          )}
        </div>
      ),
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Autor</p>,
      name: 'Autor',
      dataIndex: 'author',
      key: 'author',
      render: (_: string, record: PostDetails) => (
        <p style={tableRowStyle}>{record.author}</p>
      ),
      sorter: (a: any, b: any) => a.author.localeCompare(b.author),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Tipo</p>,
      name: 'Tipo',
      dataIndex: 'published',
      key: 'published',
      render: (_: string, record: PostDetails) => (
        <p style={tableRowStyle}>
          {record.published === true ? 'Publicado' : 'Rascunho'}
        </p>
      ),
      sorter: (a: any, b: any) => {
        if (a.published === b.published) {
          return 0;
        }
        return a.published ? -1 : 1;
      },
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Data de publicação</p>,
      name: 'Data de publicação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_: string, record: PostDetails) => (
        <p style={tableRowStyle}>
          {moment.utc(record.createdAt).format('DD/MM/YYYY')}
        </p>
      ),
      sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Última atualização</p>,
      name: 'Última atualização',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      render: (_: string, record: PostDetails) => (
        <p style={tableRowStyle}>
          {moment.utc(record.updatedAt).format('DD/MM/YYYY')}
        </p>
      ),
      sorter: (a: any, b: any) => a.updatedAt.localeCompare(b.updatedAt),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Ações</p> ,
      key: 'action',
      mobile: true,
      dataIndex: 'action',
      name: 'Ações',
      render: (_: string, record: PostDetails) => (
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => {
                if (e.key === 'edit') {
                  history.push(`/posts/edit/${record.id}`);
                }
                if (e.key === 'feedback') {
                  onViewFeedback(record.id);
                }
                if (e.key === 'delete') {
                  showConfirm({
                    message: 'Tem certeza que deseja excluir a publicação?',
                    colorPrimary,
                    isMobile,
                  }).then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      handleDelete([record.id], {
                        onSuccess: () => {
                          notificateSucess(
                            `A publicação foi excluída com sucesso!`
                          );
                          refetch();
                        },
                        onError: () => {
                          notificateError(
                            `Houve um problema ao excluir a publicação. Verifique sua conexão e tente novamente.`
                          );
                        },
                      });
                    }
                  });
                }
              }}
            >
              {check([Permissions.U4hero.Posts.Edit]) ? (
                <Menu.Item key={'edit'}>Editar</Menu.Item>
              ) : null}

              {check([Permissions.U4hero.Posts.Delete]) ? (
                <Menu.Item key={'delete'}>Deletar</Menu.Item>
              ) : null}
                <Menu.Item key={'feedback'}>Feedbacks</Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <FaEllipsisVertical size={24} style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ];

  if (isMobile) {
    columns.splice(-1, 0, {
      title: <p style={tableHeaderStyle}>Detalhes</p>,
      key: 'details',
      mobile: true,
      dataIndex: 'details',
      name: 'Detalhes',
      render: (_: string, record: PostDetails) => (
        <div style={{ width: 'fit-content' }}>
          {detailsVisibility[record.id] ? (
            <PiEyeLight size={30} onClick={() => toggleDetails(record.id)} />
          ) : (
            <PiEyeSlash size={30} onClick={() => toggleDetails(record.id)} />
          )}
        </div>
      ),
    });
  }

  const toggleDetails = (recordId: string) => {
    setDetailsVisibility((prev) => ({
      ...prev,
      [recordId]: !prev[recordId],
    }));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: string[], selectedRows: PostDetails[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const onDelete = () => {
    showConfirm({
      message: 'Tem certeza que deseja excluir as publicações?',
      colorPrimary,
      isMobile,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        handleDelete(selectedRowKeys, {
          onSuccess: () => {
            notificateSucess('As publicações foram excluídas com sucesso!');
            refetch();
          },
          onError: () => {
            notificateError(
              'Houve um problema ao excluir as publicações. Verifique sua conexão e tente novamente.'
            );
          },
        });
      }
    });
  };
  useEffect(() => {
    setPage(1);
  }, [search, filters]);
  return (
    <DynamicTable
      id="id-tour-posts-manager-posts-table"
      itemName="Publicação"
      data={data?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title=""
      total={data?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isEachLoading}
      defaultColumns={
        isMobile ? columns.filter((item) => item.mobile) : columns
      }
      allColumns={columns}
      onSearch={setSearch}
      hideCog={true}
      selected={selectedRowKeys?.length > 0}
      onDelete={onDelete}
      rowSelection={{ preserveSelectedRowKeys: true, ...rowSelection }}
      appliedFilters={filters}
      onChangeFilters={setFilters}
      filters={<PostFilter filters={filters} onChangeFilters={setFilters} />}
    ></DynamicTable>
  );
}

export default PostsTable;
