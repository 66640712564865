import Layout from 'components/layout';
import erro404 from 'assets/images/errors/404.png';
import erro404Mobile from 'assets/images/errors/404-mobile.png';
import { Flex } from 'antd';
import { useMediaQuery } from '@chakra-ui/react';
import { useHistory } from 'react-router';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import Button from 'components/buttons/button';

function NotFound() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();
  return (
    <Layout.Container>
      <Flex
        align={'center'}
        justify={'center'}
        vertical
        style={{ height: '100%' }}
        gap="16px"
      >
        <img
          src={isMobile ? erro404Mobile : erro404}
          alt="Página não encontrada"
        />
        <Title>Página Não Encontrada</Title>
        <Text
          style={{
            maxWidth: 873,
          }}
        >
          Oops! Parece que a página que você está tentando acessar não existe ou
          foi removida. Isso pode acontecer por várias razões, como um link
          quebrado ou uma URL digitada incorretamente.
        </Text>
        <Button
          size="md"
          onClick={() => history.push('/')}
        >
          VOLTAR PARA PAGINA INICIAL
        </Button>
      </Flex>
    </Layout.Container>
  );
}

export default NotFound;
