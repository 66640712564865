import { Image } from 'antd';
import { getFirstLetter } from 'infra/helpers/getInicialsName';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';

function Avatar({
  url,
  name,
  size = 'sm',
  type
}: {
  url?: string;
  name: string;
  size?: 'sm' | 'md' | 'lg';
  type:'primary' | 'secondary'
}) {
  const { colorPrimary, colorGreyCard } = useU4heroColors();

  const sizes = {
    sm:'48px',
    md:'56px',
    lg:'100px'
  }
 
  return (
    <>
      {url ? (
        <Image
          src={url}
          width={sizes[size]}
          height={sizes[size]}
          style={{ borderRadius: type === 'primary' ? '15px':'50%' }}
          preview={false}
        />
      ) : (
        <div
          style={{
            width:sizes[size],
            height:sizes[size],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: colorGreyCard,
            borderRadius: '15px',
            color: colorPrimary,
          }}
        >
          <span style={{ fontSize: '28px', fontWeight: 700 }}>
            {getFirstLetter(name)}
          </span>
        </div>
      )}
    </>
  );
}

export default Avatar;
