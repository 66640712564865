import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMediaQuery } from '@chakra-ui/react';
import { Button, Col, Flex, Form, Input, Row, Typography } from 'antd';
import Layout from 'components/layout';
import CustomizedRequiredMark from 'components/required-mark';
import usePractice from 'core/features/practices/hooks/usePractice';
import { AdaptPracticeForm } from 'core/features/practices/typings';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAdaptPractice } from 'core/features/practices/hooks/useAdaptPractice';
import { t } from 'core/resources/strings';
import InstructionsCard from 'ui/pages/app/practices/pages/CreatePractice/components/InstructionsCard';
import InclusionGuideCard from 'ui/pages/app/practices/pages/CreatePractice/components/InclusionGuideCard';

const { Title } = Typography;

function AdaptPractice() {
  const { colorPrimary } = useU4heroColors();
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const params = useParams<{
    id: string;
  }>();
  const { data } = usePractice(params.id);
  const { mutate: handleSave } = useAdaptPractice();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const onSubmit = (values: AdaptPracticeForm) => {
    handleSave(
      { ...values, practiceId: params.id },
      {
        onError: () => {
          notificateError(t('app.erroAoSalvarAlteracoes'));
        },
        onSuccess: () => {
          notificateSucess(t('app.alteracaoRealizadaComSucesso'));
          setTimeout(() => {
            history.goBack();
          }, 200);
        },
      }
    );
  };

  return (
    <Layout.Container>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onSubmit}
            requiredMark={CustomizedRequiredMark}
            validateTrigger="submit"
            initialValues={
              data
                ? {
                    content: data?.adaptation
                      ? data.adaptation.content
                      : data.content,
                    resources: data?.adaptation
                      ? data.adaptation.resources
                      : data.resources,
                    id: data.adaptation?.id ?? undefined,
                    inclusionGuide: data?.adaptation
                      ? data.adaptation.inclusionGuide
                      : data.inclusionGuide,
                  }
                : undefined
            }
          >
            <Flex gap="23px" align="center">
              <Button
                type="primary"
                size="large"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  if (form.isFieldsTouched()) {
                    openNotification({
                      colorPrimary,
                      isMobile,
                      onClose: () => history.goBack(),
                    });
                  } else {
                    history.goBack();
                  }
                }}
              />
              <Title
                level={1}
                style={{
                  color: colorPrimary,
                  fontSize: isMobile ? '24px' : '28px',
                  fontWeight: 700,
                  margin: '0px',
                }}
              >
                {t('app.adaptarInspiracao')}
              </Title>
            </Flex>

            <Flex
              gap="16px"
              justify="end"
              style={{ marginBottom: isMobile ? '8px' : '16px' }}
            >
              <Button
                type="primary"
                size="large"
                id="id-tour-practices-create-button-publish"
                style={{
                  fontWeight: 600,
                  fontSize: isMobile ? '12px' : '16px',
                }}
                htmlType="submit"
              >
                {t('app.salvar')}
              </Button>
            </Flex>

            <Form.Item hidden name="id" />
            <Form.Item
              label={t('app.materiais')}
              rules={[
                {
                  required: true,
                  message: t('app.erroMateriais'),
                },
              ]}
              name="resources"
            >
              <Input.TextArea
                id="id-tour-create-practice-input-resources"
                placeholder={t('app.placeholderRecursos')}
                rows={2}
              />
            </Form.Item>
            <Flex vertical gap={32} style={{ marginBottom: '32px' }}>
              <InstructionsCard hideUpload />
              <InclusionGuideCard />
            </Flex>
          </Form>
        </Col>
      </Row>
    </Layout.Container>
  );
}

export default AdaptPractice;
