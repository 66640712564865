import {
  Box,
  Text,
  SimpleGrid,
  Flex,
  Button,
  useMediaQuery,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Empty, Pagination } from 'antd'
import Layout from 'components/layout'
import ActivityFilterBar from '../ActivityFilterBar'
import {  showSuccess } from 'infra/helpers/alerta'
import Report from 'ui/pages/app/indicators/components/Report'
import { reportTypes } from 'core/features/report/types'
import { Pluralizar } from 'infra/helpers/plural'
import useActivitiesReport from 'core/features/activity/hooks/useActivitiesReport'
import { ActivityCard } from '../ActivityCard'
import { t } from 'core/resources/strings'



function ActivityCardList ({
  collumns,
  id,
  type,
  setStep,
  classId,
}: {
  collumns: number;
  id?: string;
  type: reportTypes;
  setStep?: (v: any) => void;
  classId?: string;
}) {
  const [page, setPage] = useState(1);
  const [publishDate, setpublishDate] = useState('0');
  const [endDate, setendDate] = useState('0');
  const [competence, setCompetence] = useState();
  const [cardSelect, setCardSelect] = useState('');
  const [isOpenReport, setIsOpenReport] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const parseSetCompetence = (entra: string) => {
    try {
      const parsed = JSON.parse(entra);
      if (parsed) {
        setCompetence(parsed.id);
      }
    } catch (e) {}
  };

  const { data, isFetching } = useActivitiesReport({
    tagId: competence,
    publishDate,
    endDate,
    page,
    // typeId: 0,
    classId: type === reportTypes.Class ? id : undefined,
    studentId: type === reportTypes.Student ? id : undefined,
  });

  let isLoaded = !isFetching;

  useEffect(() => {
    if (isLoaded) {
      setCardSelect('');
    }
  }, [isLoaded, setCardSelect]);

  useEffect(() => {
    setPage(1);
  }, [competence, publishDate, endDate]);
  return (
    <Layout.Container>
      {isOpenReport ? (
        <Report
          type={type}
          classId={type === reportTypes.Student ? classId : id}
          studentId={id}
          activityId={cardSelect}
          onClose={() => {
            setIsOpenReport(false);
          }}
        />
      ) : null}

      <Box rounded="8px">
        <ActivityFilterBar
          valueCompetence={competence}
          onPublishDate={setpublishDate}
          onEndDate={setendDate}
          onCompetence={parseSetCompetence}
          setStep={() => setStep && setStep(0)}
          type={type}
        />

        <Flex mb="16px" mt="30px" width={isMobile ? '100vw' : ''}>
          <Flex flex={1} fontSize="14px" justifyContent="space-between">
            <Box>
              <Text>
                <strong>
                  {`${data?.total} ${Pluralizar(
                    t('app.atividade'),
                    data?.total ? data?.total > 1 : false
                  )}`}{' '}
                </strong>
              </Text>
            </Box>
            <Box>
              <Button
                disabled={cardSelect === ''}
                size="sm"
                variant="outline"
                colorScheme="orange"
                onClick={() => setIsOpenReport(true)}
                id="id-tour-report-button-generate-socio-emotional-report"
              >
                {t('app.socioemocional')}
              </Button>
              <Button
                id="id-tour-report-button-generate-emotional-report"
                disabled={true}
                size="sm"
                ml="20px"
                variant="outline"
                colorScheme="orange"
                onClick={() =>
                  showSuccess(t('app.relatorioEmocaoGeradoComSucesso'))
                }
              >
                {t('app.emocao')}
              </Button>
            </Box>
          </Flex>
        </Flex>

        {data?.data.length || data ? (
          <SimpleGrid columns={isMobile ? 1 :collumns} spacing={8} justifyContent='center' width={isMobile ? '100vw':''}justifyItems={isMobile ? 'center':''} id='id-tour-report-activity-cards'>
            {data?.data.map((item,index) => (
              <ActivityCard cardSelect={cardSelect} setCardSelect={setCardSelect} item={item} isLoaded={isLoaded} index={index} />
            ))}
          </SimpleGrid>
        ) : null}

        {!data || !data?.data?.length ? (
          <Flex py="58px" flex={1} justifyContent="center" alignItems="center">
            <Empty />
          </Flex>
        ) : null}

        <Flex justifyContent="flex-end">
          <Pagination
            style={{ margin: 16 }}
            current={page ?? 1}
            total={data?.total ?? 0}
            pageSize={25 ?? 0}
            onChange={(page) => {
              setPage(page);
            }}
          />
        </Flex>
      </Box>
    </Layout.Container>
  );
}

export default ActivityCardList;
