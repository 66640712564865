import http from "infra/http";
import { useMutation } from "react-query";


export function useMarkTourComplete() {
  return useMutation(
    (id:string ) => {
      return http.post(`/v2/tour/complete/${id}`);
    },
    { retry: false }
  );
}

export default useMarkTourComplete;


