import { useQuery } from "react-query";
import axios from "infra/http";
import { HelperDetails } from "../typings";

function useHelper(id?: string) {
  const fetchData = async (id: string): Promise<HelperDetails> => {
    const { data } = await axios.get<HelperDetails>(`/v2/helpers/${id}`);

    return data;
  };

  return useQuery([`/v2/helpers/${id}`], () => fetchData(id!), {
    enabled: !!id,
  });
}

export default useHelper;
