import { useRecover } from 'core/features/auth/hooks/useRecover';
import { useHistory } from 'react-router-dom';
import bg from 'assets/images/bg.png';
import bgMobile from 'assets/images/bg-mobile.png';
import emailSent from 'assets/images/email-sent.png';
import logo from 'assets/images/logo.png';
import { showError } from 'infra/helpers/alerta';
import { useCurrentUser } from 'core/features/user/store';
import { useState } from 'react';
import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex, Form, Input, Modal, Typography } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Button from 'components/buttons/button';
import { t } from 'core/resources/strings';

const { Title } = Typography;

function RecoverPage() {
  const { mutate: handleRecover, isLoading } = useRecover();
  const [form] = Form.useForm();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();
  const { user } = useCurrentUser();
  const { colorPrimary } = useU4heroColors();
  const [isOpenModal, setIsOpenModal] = useState(false);
  if (user) {
    history.push('/');
  }
  const _handleRecover = (values: { email: string }) => {
    handleRecover(values, {
      onSuccess: (data: any) => {
        setIsOpenModal(true);
      },
      onError: (err: any) => {
        showError(err?.response?.data?.error ?? t('app.emailNaoExistente'));
      },
    });
  };

  return (
    <Flex
      style={{
        width: '100%',
        height: '100vh',
        backgroundImage: isMobile ? '' : `url(${bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      {isOpenModal ? (
        <Modal
          title={
            <p
              style={{
                fontSize: isMobile ? '18px' : '24px',
                color: colorPrimary,
                fontWeight: 700,
              }}
            >
              {t('app.emailEnviado')}
            </p>
          }
          open={true}
          centered
          onCancel={() => {
            setIsOpenModal(false);
          }}
          closeIcon={
            <div
              style={{
                background: colorPrimary,
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IoClose size={16} color="white" />
            </div>
          }
          footer={null}
          width={450}
        >
          <Flex vertical justify="center" align="center">
            <img
              alt="email enviado com sucesso"
              src={emailSent}
              width="167px"
            />
            <p
              style={{
                fontSize: isMobile ? '14px' : '18px',
                textAlign: 'justify',
                fontWeight: 400,
                marginBottom: '16px',
              }}
            >
              {t('app.mensagemErroEmailSenhaInstrucoes')}
            </p>
            <Button size="md" onClick={() => history.push('/auth/login')}>
              {t('app.concluir')}
            </Button>
          </Flex>
        </Modal>
      ) : null}
      <Form
        onFinish={(ev) => _handleRecover(ev)}
        validateTrigger="onSubmit"
        layout="vertical"
        form={form}
        requiredMark="optional"
      >
        {isMobile ? (
          <img alt="background-mobile" src={bgMobile} width="536px" />
        ) : null}
        <Flex
          style={{
            borderRadius: isMobile ? ' 20px 20px 0px 0px' : '0px 20px 20px 0px',
            background: 'white',
            width: isMobile ? '100vw' : '50vw',
            height: isMobile ? '' : '100vh',
            position: isMobile ? 'relative' : '-moz-initial',
            top: isMobile ? -20 : undefined,
          }}
          vertical
          justify="center"
          align="center"
        >
          <Flex
            vertical
            justify="center"
            style={{
              width: isMobile ? '100%' : '508px',
              padding: isMobile ? '0px 8px' : '',
            }}
          >
            <img
              alt="logo"
              src={logo}
              width={isMobile ? '100px' : '212px'}
              style={{ alignSelf: 'center' }}
            />
            <Title
              level={2}
              style={{
                maxWidth: '508px',
                color: colorPrimary,
                fontSize: isMobile ? '18px' : '28px',
                fontWeight: 700,
                marginTop: isMobile ? '' : '50px',
                textAlign: 'center',
                marginBottom: '0px',
              }}
            >
              {t('app.esqueceuSuaSenhaPergunta')}
            </Title>
            <p
              style={{
                fontSize: isMobile ? '14px' : '18px',
                textAlign: 'center',
                fontWeight: 400,
              }}
            >
              {t('app.mensagemRecuperarSenhaInstrucoes')}
            </p>
            <Form.Item
              label="E-mail"
              rules={[
                {
                  required: true,
                  message: t('app.mensagemErroEmail'),
                },
              ]}
              name="username"
            >
              <Input
                size="large"
                type="email"
                style={{
                  width: '100%',
                  height: isMobile ? '40px' : '60px',
                }}
              />
            </Form.Item>

            <Button
              size="lg"
              htmlType="submit"
              style={{
                alignSelf: 'center',
                marginTop: isMobile ? '16px' : '',
              }}
              loading={isLoading}
            >
              {t('app.recuperarSenha')}
            </Button>
          </Flex>
        </Flex>
        <Button
          icon={<ArrowLeftOutlined />}
          type="text"
          size="md"
          style={{
            position: 'absolute',
            bottom: 20,
            left: 10,
            textTransform: 'none',
          }}
          onClick={() => history.push('/auth/login')}
        >
          {t('app.voltar')}
        </Button>
      </Form>
    </Flex>
  );
}

export default RecoverPage;
