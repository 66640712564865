import { useMutation } from "react-query";
import http from "infra/http";
import { TwoFactor } from "../typings";

export function useValidate2FA() {
  return useMutation(
    (data: TwoFactor) => {
      const body: any = data;
      return http.post(`/v1/auth/validate2FA`, body);
    },
    { retry: false }
  );
}
