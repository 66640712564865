import { Col, Flex, Form, Input, Row } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useHistory, useParams } from 'react-router-dom';
import { useMediaQuery } from '@chakra-ui/react';
import logo from 'assets/images/logo.png';
import Title from 'components/typography/title';
import Button from 'components/buttons/button';
import bg from 'assets/images/background-quick-acess.png';
import bgMobile from 'assets/images/background-quick-acess-mobile.png';
import { useQuickAccess } from 'core/features/auth/hooks/useQuickAcess';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import { useCurrentUser } from 'core/features/user/store';
import { useUpdateCurrentUser } from 'core/features/user/hooks/useUpdateCurrentUser';
import { CurrentUser } from 'core/features/user/typings';
import jwt_decode from 'jwt-decode';
import { t } from 'core/resources/strings';

function QuickAccess() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();
  const { colorPrimary } = useU4heroColors();
  const { mutate: saveQuickAccess, isLoading } = useQuickAccess();
  const { mutate: handleSave } = useUpdateCurrentUser();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const {
    user,
    update: updateUser,
    updateToken,
    updateRefreshToken,
  } = useCurrentUser();

  const onSubmit = (values: any) => {
    saveQuickAccess(
      { ...values, classId: id, isStudent: false },
      {
        onSuccess: (data: any) => {
          const decoded: CurrentUser = jwt_decode(data.data.accessToken);
          login(data, decoded);
          notificateSucess(t('app.cadastroRealizadoComSucesso'));
        },
        onError: () => {
          notificateError(t('app.erroAoSalvarAcessoRapido'));
        },
      }
    );
  };

  function login(data: any, decoded: any) {
    updateUser(decoded);
    updateToken(data.data.accessToken);
    updateRefreshToken(data.data.refreshToken);
    if (!decoded.startedInitialTour) {
      handleSave(
        { ...decoded, startedInitialTour: true },
        {
          onSuccess: () => {
            history.push('/?initialtour');
          },
        }
      );
    } else {
      history.push('/');
    }
  }

  return (
    <Flex vertical={isMobile} style={{ width: '100%', height: '100vh' }}>
      {isMobile && <img src={bgMobile} alt="bg" />}
      <Flex
        vertical
        justify="center"
        align="center"
        style={{
          width: isMobile ? '100%' : '50%',
          height: '100%',
          padding: '0 40px',
        }}
      >
        <Flex
          vertical
          justify="center"
          align="center"
          gap="12px"
          style={{ marginBottom: '40px' }}
        >
          <img src={logo} alt="logo" width={'114px'} />
          <Title primary>{t('app.acessoRapido')}</Title>
        </Flex>
        <Form
          onFinish={(ev) => onSubmit(ev)}
          validateTrigger="onSubmit"
          layout="vertical"
          form={form}
          requiredMark={false}
          style={{ width: isMobile ? '100%' : '490px' }}
        >
          <Form.Item
            label={t('app.nomeOuApelido')}
            rules={[
              {
                required: true,
                message: t('app.mensagemErroNomeOuApelido'),
              },
            ]}
            name="username"
          >
            <Input
              size="large"
              type="text"
              style={{ width: '100%', height: isMobile ? '40px' : '60px' }}
            />
          </Form.Item>

          <Form.Item
            label="Perfil"
            name="profile"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input
              size="large"
              type="text"
              value={'Educador'}
              defaultValue={'Educador'}
              disabled
              style={{ width: '100%', height: isMobile ? '40px' : '60px' }}
            />
          </Form.Item>

          <Flex justify="center">
            <Button
              size="lg"
              htmlType="submit"
              style={{
                marginTop: isMobile ? '16px' : '',
              }}
              loading={isLoading}
            >
              {t('app.entrar')}
            </Button>
          </Flex>
        </Form>
      </Flex>
      <Flex
        style={{
          width: '50%',
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
          display: isMobile ? 'none' : 'flex',
        }}
      >
        <img
          src={bg}
          alt="bg"
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Flex>
    </Flex>
  );
}

export default QuickAccess;
