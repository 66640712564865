import { Box } from '@chakra-ui/react';
import { reportTypes } from 'core/features/report/types';
import { useState } from 'react';
import ActivityCardList from '../ActivityCardList';
import Periodo from '../Periodo';
import StudentList from '../StudentList';

export enum SelectorStudentTypes {
  activity = 'activity',
  period = 'period',
}

function SelectorStudent({ secType }: { secType: SelectorStudentTypes }) {
  const [step, setStep] = useState(0);
  const [studentId, setStudentId] = useState('');
  const [classId, setClassId] = useState('');

  return (
    <>
      {step === 0 ? (
        <StudentList
          setStep={setStep}
          setClass={setClassId}
          onClick={setStudentId}
        />
      ) : null}

      {step === 1 && secType === SelectorStudentTypes.activity ? (
        <ActivityCardList
          collumns={4}
          classId={classId}
          id={studentId}
          type={reportTypes.Student}
          setStep={setStep}
        />
      ) : null}

      {step === 1 && secType === SelectorStudentTypes.period ? (
        <Box pos="relative" bg="gray.50" py="16px" px="24px" rounded="8px">
          <Periodo
            id={studentId}
            classId={classId}
            type={reportTypes.Student}
            setStep={setStep}
          />
        </Box>
      ) : null}
    </>
  );
}

export default SelectorStudent;
