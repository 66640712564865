import http from "infra/http";
import { useMutation } from "react-query";

export function useDeleteClass() {
  return useMutation(
    (ids: string[]) => {
      return http.delete(`/v2/class`, {
        data: {
          ids,
        },
      });
    },
    { retry: false }
  );
}
