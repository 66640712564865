import { Select } from 'antd';
import { useMediaQuery } from '@chakra-ui/react';
import useTours from 'core/features/tour/hooks/useTours';

interface SelectTourProps {
  value: string;
  onChange: (v: string) => void;
}

function SelectTour({ value, onChange }: SelectTourProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data } = useTours({
    page: 1,
    active: true,
  });

  return (
    <Select
      id="id-tour-create-helper-input-tour"
      placeholder="Vincule a um tour"
      allowClear
      options={
        data?.data?.map((item) => ({
          label: item.name,
          value: item.id,
        })) ?? []
      }
      onChange={onChange}
      value={value}
      size={isMobile ? 'middle' : 'large'}
    />
  );
}

export default SelectTour;
