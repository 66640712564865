import { useMediaQuery } from '@chakra-ui/media-query';
import { Col, Divider, Flex, Modal, Row, Spin} from 'antd';
import Button from 'components/buttons/button';
import Dot from 'components/dot';
import SvgIcon from 'components/SvgIcon';
import useMetricsPath from 'core/features/metrics/hooks/useMetricsPath';
import { primary } from 'core/resources/theme/colors';
import { useParams } from 'react-router-dom';

import './styles.css';

function ModalPathMetrics({
  onClose,
  collaboratorId,
  startDate,
  endDate,
}: {
  onClose: () => void;
  collaboratorId?: string;
  startDate?: string;
  endDate?: string;
}) {
  const params = useParams<{ id: string }>();
  const { data, isLoading } = useMetricsPath({
    tenantId: params.id,
    collaboratorId: collaboratorId ?? undefined,
    startDate: startDate ?? undefined,
    endDate: endDate ?? undefined,
  });
  const [isMobile] = useMediaQuery('(max-width: 768px');

  return (
    <Modal
      open={true}
      onCancel={onClose}
      footer={null}
      modalRender={(node) => (
        <div
          style={{
            backgroundImage:
              'url("/background-metrics-modal-bottom.png"),url("/background-metrics-modal-top.png")',
            backgroundSize: '50%, 32%',
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundPosition: 'bottom left,top right',
            backgroundColor: 'white',
          }}
          className={'modal-with-background'}
        >
          {node}
        </div>
      )}
      width={'1040px'}
      closeIcon={null}
    >
      <div
        style={{
          height: 'auto',
          borderRadius: '20px',
          background: '#FFF',
          padding: isMobile ? '8px' : '16px 24px',
          boxShadow:
            '0px 3px 4px 0px rgba(0, 0, 0, 0.25), 0px -0.5px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        <p
          style={{
            color: primary,
            fontSize: isMobile ? '16px' : '20px',
            fontWeight: 700,
            marginBottom: isMobile ? '8px' : '16px',
          }}
        >
          Mapeamento de Competências e Habilidades
        </p>
        {!isLoading ? (
          <Row gutter={isMobile ? [10, 10] : [10, 40]}>
            {data?.data.map((item, index) => (
              <Col
                span={isMobile ? 24 : 12}
                style={{
                  borderWidth:
                    isMobile || index % 2 === 0
                      ? '0px 0px 1px 0px'
                      : '0px 0px 1px 1px',
                  borderColor: '#C4C4C4',
                  borderStyle: 'solid',
                }}
              >
                <Row justify="center" align="middle">
                  <Col span={isMobile ? 24 : 10}>
                    <Flex
                      vertical
                      gap={isMobile ? 4 : 8}
                      style={{ padding: '8px 24px' }}
                      justify="center"
                      align="center"
                    >
                      <p
                        style={{
                          fontSize: isMobile ? '14px' : '20px',
                          fontWeight: 500,
                          color: 'black',
                          textAlign: 'center',
                        }}
                      >
                        {item.name}
                      </p>
                      {item.icon ? (
                        <SvgIcon
                          url={item.icon}
                          size={isMobile ? 30 : 40}
                          color="black"
                        />
                      ) : null}
                    </Flex>
                  </Col>
                  <Col span={isMobile ? 24 : 14}>
                    <Flex vertical gap={8}>
                      {item.children.map((child) => (
                        <Flex gap={5} justify="center" align="center">
                          {' '}
                          <Dot size={isMobile ? 12 : 14} color={primary} />{' '}
                          <p
                            style={{
                              textAlign: 'center',
                              fontSize: isMobile ? '14px' : '16px',
                              color: 'black',
                            }}
                          >
                            {child.name.length > 27
                              ? child.name.substr(0, 24) + '...'
                              : child.name}
                          </p>
                        </Flex>
                      ))}
                    </Flex>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        ) : (
          <Flex justify="center" align="center">
            <Spin size={'large'} />
          </Flex>
        )}

          <Divider style={{ margin: '10px 0' }} />
        <Flex justify='center'>
          <Button size='md' onClick={onClose} >Fechar</Button>
        </Flex>
      </div>
    </Modal>
  );
}

export default ModalPathMetrics;
