import { useQuery } from "react-query";
import axios from "infra/http";
import { IStudentProcess } from "../types";

function useProcess() {
  return useQuery('/v2/inkluzen/process', async ():Promise<IStudentProcess[]> => {
    const { data } = await axios.get<IStudentProcess[]>('/v2/inkluzen/process');
    
    return data;
  });

}

export default useProcess;