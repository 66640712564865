import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex } from 'antd';
import SubTitle from 'components/typography/subTitle';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import { PiClockAfternoonLight } from 'react-icons/pi';
const moment = require('moment');
require('moment/locale/pt-br');

function BlogPostPreview({
  bannerUrl,
  author,
  title,
  description,
  content,
  onClose,
}: {
  bannerUrl?: string;
  duration?: number;
  maxAge?: number;
  author?: string;
  title?: string;
  content?: string;
  description?: string;
  onClose: () => void;
}) {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const currentDate = moment();
  currentDate.locale('pt-br');

  return (
    <div style={{ padding: isMobile ? '0px' : '16px' }}>
      {!isMobile ? (
        <>
          <Flex justify="flex-end" style={{ cursor: 'pointer' }}>
            <IoClose
              size={24}
              color={colorGreyBorderAndFont}
              onClick={onClose}
            />
          </Flex>
          <Title
            primary
            style={{
              marginBottom: '16px',
            }}
          >
            Prévia
          </Title>
        </>
      ) : null}
      <Flex vertical gap={8}>
        <img
          alt={`banner ${title}`}
          src={bannerUrl}
          style={{
            borderRadius: '10px',
            width: '100%',
            height: isMobile ? '156px' : '184px',
            objectFit: 'cover',
          }}
        />

        <Flex justify="space-between">
          <Flex gap={6}>
            <PiClockAfternoonLight size={20} />
            <Text color={colorGreyBorderAndFont} size="sm">
              tempo
            </Text>
          </Flex>
          <Text size="sm" color={colorGreyBorderAndFont}>
            {currentDate.format('DD [de] MMMM [de] YYYY')}
            <br />
            Autor: {author}
          </Text>
        </Flex>
      </Flex>

      <SubTitle
        primary
        style={{
          marginBottom: '8px',
        }}
        align="center"
      >
        {title}
      </SubTitle>

      <p
        style={{
          color: 'black',
          fontSize: isMobile ? '14px' : '18px',
          fontWeight: 400,
          textAlign: 'justify',
          margin: '0px',
          marginBottom: '8px',
        }}
      >
        {description}
      </p>
      <div
        dangerouslySetInnerHTML={{
          __html: content ?? '',
        }}
      />
    </div>
  );
}

export default BlogPostPreview;
