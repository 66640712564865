import { Flex } from "@chakra-ui/layout";
import { Box, Button, useMediaQuery } from "@chakra-ui/react";
import { orange, primary } from "core/resources/theme/colors";

function TabHeader({
  step,
  stepsLabel,
  onChange,
}: {
  step: number;
  stepsLabel: { label: string; order: number; id:string; }[];
  onChange: (v: number) => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Box pos="relative" bg="gray.50" py="16px" px="24px" rounded="8px">

      <Flex
        gridGap="32px"
        flexDir={isMobile ? 'column':"row"}
        alignItems={isMobile ? 'center':'normal'}
        justifyContent="space-between"
      >
        {stepsLabel.map(
          (item) =>
            <Button
              id={item.id}
              fontSize="18px"
              height="50px"
              width="170px"
              bgColor={primary}
              color="white"
              _hover={{
                boxShadow: `inset 0px -5px 0px ${orange}`
              }}
              _active={{
                boxShadow: `inset 0px -5px 0px ${orange}`
              }}
              onClick={() => onChange(item.order)}
              isActive={step === item.order}
            >
              {item.label}
            </Button>
        )}

      </Flex>

    </Box>
  );
}

export default TabHeader;
