import { Flex, Rate, Select } from 'antd';
import SubTitle from 'components/typography/subTitle';
import { useIdeb } from 'core/features/qedu/hooks/useIdeb';
import { useCurrentUser } from 'core/features/user/store';
import Text from 'components/typography/text';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { IdebRecord } from 'core/features/qedu/types';
import Title from 'components/typography/title';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';

function Ideb() {
  const { user } = useCurrentUser();
  const { data, error } = useIdeb({
    id: user?.inep_id as number,
  });

  const { colorGreyBorderAndFont } = useU4heroColors();
  const [current, setCurrent] = useState<IdebRecord | undefined>();

  const getStarRating = (ideb: number | undefined) => {
    if (ideb === undefined) return 0;
    if (ideb >= 8.0) return 5;
    if (ideb >= 6.7) return 4;
    if (ideb >= 5.4) return 3;
    if (ideb >= 4.6) return 2;
    return 1;
  };

  const handleSelectChange = (value: string) => {
    const selected = data?.find((item) => item.ciclo_id === value);
    setCurrent(selected);
  };

  useEffect(() => {
    if (data && data.length) {
      setCurrent(data[0]);
    }
  }, [data]);

  return (
    <Flex
      vertical
      style={{ padding: '16px', height: '100%', position: 'relative' }}
      className="box-shadow-generic-card"
      gap={8}
    >
      <div>
        <SubTitle primary style={{ lineHeight: 'normal' }}>
          IDEB
        </SubTitle>
        <Text size="md" style={{ lineHeight: 'normal' }}>
          Indicador de Qualidade da Educação Básica.
        </Text>
      </div>
      <Text
        style={{ position: 'absolute', top: 8, right: 16 }}
        size="sm"
        align="end"
        color={colorGreyBorderAndFont}
      >
        {current?.ano}
      </Text>
      <Flex justify="flex-end">
        <Select
          size={'middle'}
          onChange={handleSelectChange}
          value={current?.ciclo_id}
          options={data?.map((item) => ({
            value: item.ciclo_id,
            label: item.ciclo,
          }))}
        />
      </Flex>
      <Flex vertical justify="center" align="center" gap={8}>
        <Rate
          disabled
          value={current?.ideb ? getStarRating(Number(current?.ideb)) : 0}
        />
        <Title style={{ fontSize: '80px' }} primary>
          {current?.ideb ? parseFloat(current?.ideb).toFixed(1) : 'N/A'}
        </Title>
        <Text size="sm">
          Meta{' '}
          <strong>
            {current?.ideb_projetado
              ? parseFloat(current?.ideb_projetado).toFixed(1)
              : 'N/A'}
          </strong>
        </Text>
      </Flex>
    </Flex>
  );
}

export default Ideb;
