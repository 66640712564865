import { Button } from "@chakra-ui/button";
import { Flex, Text } from "@chakra-ui/layout";
import useCheckPermissions from "core/features/user/hooks/useCheckPermissions";
import { Pluralizar } from "infra/helpers/plural";
import { MdAdd } from "react-icons/md";
/**
 *
 * @param total Amount of items in the table
 * @param itemName itemName in the singular form
 * @param action callback function to be used on a add button if its not passed it wont create a button
 * @param id id thats gonna be put in the action button
 * @param permissions permissions thats gonna check before showing the action button
 */
function GenericHeaderTable({
  total,
  itemName,
  action,
  id,
  permissions
}: {
  total: number;
  itemName: string;
  action?: () => void;
  id?:string;
  permissions?:string[];
}) {
  const [check] = useCheckPermissions();
  const allowed = permissions ? check(permissions) : true
  return (
    <Flex mb="16px">
      <Flex flex={1} fontSize="14px">
        <Text fontWeight="bold" pr="6px">
          {total ?? 0}
        </Text>{" "}
        {Pluralizar(itemName,total > 1)}
      </Flex>
      <Flex>
        {action && allowed ? ( <Button
          borderColor="blue"
          color="blue"
          variant="outline"
          size="sm"
          onClick={() => action && action()}
          id={id}
        >
          <MdAdd /> Criar
          <Text pl="4px" fontWeight="bold">
          {itemName}
          </Text>
        </Button>):null}
       
      </Flex>
    </Flex>
  );
}

export default GenericHeaderTable;
