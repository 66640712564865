import { useQuery } from "react-query";
import axios from "infra/http";
import { TourDetails } from "../typings";

function useTour(id?: string) {
  const fetchData = async (id: string): Promise<TourDetails> => {
    const { data } = await axios.get<TourDetails>(`/v2/tour/${id}`);

    return data;
  };

  return useQuery([`/v2/tour/${id}`], () => fetchData(id!), {
    enabled: !!id,
  });
}

export default useTour;
