import { Flex } from 'antd';
import Button from 'components/buttons/button';
import ModalTag from 'components/modals/modal-tag';
import { useState } from 'react';
import TagsTable from '../../TagsTable';

function CompetenceTab() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [id, setId] = useState<string>();
  return (
    <Flex vertical gap="16px">
      {isOpenModal ? (
        <ModalTag
          id={id}
          type="tag"
          onClose={() => {
            setId(undefined);
            setIsOpenModal(false);
          }}
        />
      ) : null}
      <Button
        id="id-tour-create-competence-button-add-competence"
        size={'md'}
        onClick={() => setIsOpenModal(true)}
        style={{
          alignSelf: 'end',
        }}
      >
        CRIAR CAMPO DE CONHECIMENTO
      </Button>

      <TagsTable
        onEdit={(v) => {
          setId(v);
          setIsOpenModal(true);
        }}
      />
    </Flex>
  );
}

export default CompetenceTab;
