import { useQuery } from 'react-query'
import axios from 'infra/http'
import { useEffect, useState } from 'react';
import { StoryDetails } from 'core/features/stories/typings';

export interface UsePostsParams {
  page: number;
  search?: string;
  authors?:string[];
  tags?:string[];
  published?:boolean;
  publishDate?:string;
  limit?:number;
}

function useStories(params: UsePostsParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchData = async (
    params: UsePostsParams
  ): Promise<{ data: StoryDetails[]; total: number }> => {
    const { data, headers } = await axios.get<StoryDetails[]>(`/v2/stories/`, {
      params: {
        ...params,
        search: debouncedQuery,
      },
    })

    return { data: data, total: Number(headers['total']) }
  }

  return useQuery(
    [`/v2/stories`,{...params,search:debouncedQuery}],
    () => fetchData(params),
    {
      keepPreviousData: true,
    }
  )
}

export default useStories
