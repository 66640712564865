import CollaboratorIcon from 'assets/images/icons/CollaboratorIcon';
import useTotalCollaborators from 'core/features/indicators/hooks/useTotalCollaborators';
import { t } from 'core/resources/strings';
import StatCard from '../statCard';

function TotalCollaborators({onRemove}:{onRemove?:()=> void}) {
  const { data } = useTotalCollaborators();
  return (
    <StatCard
      title={t('app.colaborador')}
      total={data?.total ?? 0}
      Icon={CollaboratorIcon}
      onRemove={onRemove}
    />
  );
}

export default TotalCollaborators;
