import { useQuery } from "react-query";
import axios from "infra/http";
import { GameCount } from "../typings";

function UseCountGamesByResponsibleId(params: { responsibleId?: string | undefined, classes?:string[],students?:string[], enabled:boolean  }) {
  const fetchGames = async (): Promise<GameCount[]> => {
    const { data } = await axios.get<GameCount[]>(`/v2/game/count`, {
      params,
    });

    return data;
  };

  
  return useQuery(
    [`/v2/game/count?responsibleId=${params?.responsibleId}&classes=${params?.classes?.join('-')}&students=${params?.students?.join('-')}`],
    fetchGames,
    {
      enabled:params.enabled,
    }
  );
}

export default UseCountGamesByResponsibleId;
