import { Checkbox, Col, Collapse, Form, Row } from 'antd';
import Text from 'components/typography/text';
import {
  Graph,
  GraphCategory,
  GraphSource,
  GraphType,
  GraphView,
} from 'core/features/indicators-profile/typings';
import useTagsCompetence from 'core/features/tags/hooks/useTagsCompetence';
import useTenants from 'core/features/tenants/hooks/useTenants';
import { TenantDetails } from 'core/features/tenants/typings';
import { useCurrentUser } from 'core/features/user/store';
import { t } from 'core/resources/strings';
import { v4 as uuidv4 } from 'uuid';

function OrganizationOptions() {
  const { user } = useCurrentUser();
  const { data } = useTenants({
    page: 1,
    limit: 100,
    parentId: user?.tenantId,
  });
  const { data: competences } = useTagsCompetence({
    competence: true,
  });
  const year = new Date().getFullYear();

  const form = Form.useFormInstance();
  const graphs = Form.useWatch(['graphs'], form);

  const handleChange = (action: 'remove' | 'add', tenant: TenantDetails) => {
    if (action === 'add') {
      form.setFieldValue('graphs', [
        ...graphs,
        {
          id: uuidv4(),
          category: GraphCategory.Activity,
          graphView: GraphView.Year,
          source: GraphSource.School,
          tenantId: tenant.id,
          yearOfAnalysis: year,
          tags:
            competences?.map((item) => ({
              id: item.id,
              parentId: item.parentId,
            })) ?? [],
          analysisTypeId: 3,
          analysisEnvironmentId: 4,
          type: GraphType.ColumnGraph,
          name: `${t('app.atividadesNaEscola')} ${tenant.name} - ${year}`,
        },
      ]);
    }
    if (action === 'remove') {
      const names = data?.data?.map((item) => item.name);
      const newGraphs = graphs.filter(
        (item: Graph) => !names?.includes(item.name)
      );
      form.setFieldValue('graphs', newGraphs);
    }
  };

  return (
    <Collapse style={{ width: '100%' }} expandIconPosition="end">
      <Collapse.Panel
        style={{
          background: '#F5F5F5',
          borderRadius: '10px',
          width: '100%',
        }}
        header={<Text>{t('app.sugestoesParaVoce')}</Text>}
        key={'1'}
        forceRender
      >
        <Row style={{ padding: '16px' }}>
          {data?.data.map((item) => (
            <Col key={item.id} sm={24}>
              <Checkbox
                onChange={(e) =>
                  handleChange(e.target.checked ? 'add' : 'remove', item)
                }
              >
                {t('app.atividadesNaEscola')} {item.name} - {year}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
}

export default OrganizationOptions;
