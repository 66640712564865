import { Switch, Route } from 'react-router-dom';
import HomePage from './home';
import ProfilePage from './profile';
import Layout from 'components/layout';
import SettingsPage from './settings';
import ActivitiesPages from './activities';
import RouteAuthorized from 'components/route/route-authorized';
import { Permissions } from '../../../core/resources/enums/permissions';
import MetricsPage from './metrics';
import MetricsSchoolPage from './metrics/pages/MetricsSchoolPage';
import QuestionsPage from './questions';
import ReportsPage from './reports';
import GamesManager from './games-u4hero';
import NotFound from './errors/not-found';
import NoPermission from './errors/no-permission';
import Helpers from './helpers-u4hero/index';
import CreateHelper from './helpers-u4hero/pages/createHelper';
import Schools from './schools';
import SafeSpace from './safeSpace';
import SettingsU4hero from './settings-u4hero';
import CreateTour from './helpers-u4hero/pages/createTour';
import StudentInfo from './inkluzen/pages/student-mental-health-report';
import ProcessDetails from './inkluzen/pages/process-details';
import StudentProcess from './inkluzen/student-process';
import StudentPanel from './mySchool/pages/Students/components/StudentPanel';
import MacroView from './inkluzen/pages/macro-view';
import ExplorationView from './inkluzen/pages/exploration-view';
import MySchoolPage from './mySchool';
import Students from './mySchool/pages/Students';
import FloatButtonComponent from 'components/float-button';
import Classes from './mySchool/pages/Classes';
import ClassCreate from './mySchool/pages/Classes/pages/NewClass';
import ClassReenroll from './mySchool/pages/Classes/pages/Reenroll';
import Activities from './activities/pages/Activities';
import CreateActivity from './activities/pages/CreateActivity';
import Automations from './activities/pages/Automations';
import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import ListSkillsMobile from './tags/components/list-skills-mobile';
import PracticesPage from './practices';
import PracticesManager from './practices/pages/PracticesManager';
import CreatePractice from './practices/pages/CreatePractice';
import PracticePage from './practices/pages/Practice';
import PracticesSuggestions from './practices/pages/PracticesSuggestions';
import AdaptPractice from './practices/pages/AdaptPractice';
import SafeSpaceAnalysis from './safeSpace/components/SafeSpaceAnalysis';
import Maintenance from './errors/maintenance';
import CreateQuestionPage from './questions/pages/CreateQuestion';
import TestLabel from 'components/test-label';
import CollaboratorsPage from './mySchool/pages/Collaborators';
import HelpersCenter from './helpers';
import FirstSteps from './helpers/pages/FirstSteps';
import GeneralConfig from './helpers/pages/GeneralConfig';
import Funcionalities from './helpers/pages/Funcionalities';
import HelperPost from './helpers/pages/HelperPost';
import SchoolProfile from './mySchool/pages/SchoolProfile';
import InitialTourManager from './helpers-u4hero/pages/initialTourManager';
import Tags from './tags';
import Competences from './competences';
import Profiles from './mySchool/pages/Profiles';
import CreateProfile from './mySchool/pages/Profiles/pages/CreateProfile';
import CreateSchool from './schools/components/CreateSchool';
import ManagerSchoolResources from './schools/components/ManagerSchoolResources';
import PostsHub from './posts-hub';
import Posts from './posts-hub/posts';
import PostsManager from './posts-hub/posts/pages/PostsManager';
import CreatePost from './posts-hub/posts/pages/CreatePost';
import PostPage from './posts-hub/posts/pages/Post';
import BlogPostsManager from './posts-hub/blog-posts/pages/BlogPostsManager';
import CreateBlogPost from './posts-hub/blog-posts/pages/CreateBlogPost';
import IndicatorsPage from './indicators';
import ManagerStore from './manager-store';
import ClassPanel from './mySchool/pages/Classes/pages/ClassPanel';
import GamePages from './games';
import ViewGame from './games/viewGame';
import StoriesManager from 'ui/pages/app/posts-hub/stories/pages/StoriesManager';
import CreateStory from 'ui/pages/app/posts-hub/stories/pages/CreateStory';

function AppPages() {
  const [isOpen, setIsOpen] = useState(false);
  const isProd = window.location.origin === 'https://smile.u4hero.com';

  const handlers = useSwipeable({
    onSwipedLeft: (e) => {
      const targetElement = e.event.target as Element;
      const carouselElement = targetElement.closest('.ant-carousel');

      if (
        carouselElement ||
        targetElement.closest('.slick-slider') ||
        targetElement.closest('.ant-slider')
      ) {
        return;
      }
      setIsOpen(false);
    },
    onSwipedRight: (e) => {
      const targetElement = e.event.target as Element;
      const carouselElement = targetElement.closest('.ant-carousel');

      if (
        carouselElement ||
        targetElement.closest('.slick-slider') ||
        targetElement.closest('.ant-slider')
      ) {
        return;
      }

      setIsOpen(true);
    },
    preventScrollOnSwipe: false,
  });

  return (
    <div
      {...handlers}
      onMouseOut={(e) => {
        window.parent.postMessage(
          {
            type: 'leave',
            message: (e.target as Element).id,
          },
          '*'
        );
      }}
      onMouseOver={(e) => {
        window.parent.postMessage(
          {
            type: 'hover',
            message: (e.target as Element).id,
          },
          '*'
        );
      }}
    >
      <Layout>
        <Layout.Appbar open={isOpen} setOpen={setIsOpen} />
        {!isProd ? <TestLabel /> : null}
        <Layout.Content>
          <Switch>
            <RouteAuthorized
              permissions={[
                Permissions.Dashboard,
                Permissions.Org.Dashboard.View,
                Permissions.U4hero.Dashboard.View,
              ]}
              exact
              path="/"
            >
              <HomePage />
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[
                Permissions.Indicators.View,
                Permissions.Org.Indicators.View,
              ]}
              exact
              path="/indicators"
            >
              <IndicatorsPage />
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[Permissions.Report.View]}
              path="/reports"
            >
              <Switch>
                <RouteAuthorized
                  permissions={[Permissions.Report.View]}
                  path="/reports/class/:id/activity/:activityId"
                >
                  Relatório por turma e atividade
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.Report.View]}
                  path="/reports/class/:id"
                ></RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.Report.View]}
                  exact
                  path="/reports"
                >
                  <ReportsPage />
                </RouteAuthorized>
              </Switch>
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[Permissions.Games.View]}
              path="/games"
            >
              <GamePages />
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[Permissions.Games.View]}
              path="/game/:id"
            >
              <ViewGame />
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[Permissions.U4hero.Games.View]}
              exact
              path="/games-u4hero"
            >
              <GamesManager />
            </RouteAuthorized>

            <Route exact path="/profile">
              <ProfilePage />
            </Route>

            <RouteAuthorized
              permissions={[
                Permissions.CustomQuestion.View,
                Permissions.U4hero.Questions.View,
              ]}
              path="/question"
            >
              <Switch>
                <RouteAuthorized
                  permissions={[
                    Permissions.CustomQuestion.View,
                    Permissions.U4hero.Questions.View,
                  ]}
                  exact
                  path="/question"
                >
                  <QuestionsPage />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[
                    Permissions.CustomQuestion.Create,
                    Permissions.U4hero.Questions.Create,
                  ]}
                  exact
                  path="/question/create"
                >
                  <CreateQuestionPage />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[
                    Permissions.CustomQuestion.Edit,
                    Permissions.U4hero.Questions.Edit,
                  ]}
                  exact
                  path="/question/edit/:id"
                >
                  <CreateQuestionPage />
                </RouteAuthorized>
              </Switch>
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[
                Permissions.Activity.View,
                Permissions.SelfAvaliation.View,
                Permissions.Avaliation.View,
              ]}
              path="/activities"
            >
              <Switch>
                <RouteAuthorized
                  permissions={[Permissions.Activity.View]}
                  path="/activities/class"
                >
                  <Activities />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.Activity.View]}
                  path="/activities/individual"
                >
                  <Activities />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.SelfAvaliation.View]}
                  path="/activities/self-avaliation"
                >
                  <Activities />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.Avaliation.View]}
                  path="/activities/avaliation"
                >
                  <Activities />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[Permissions.Avaliation.View]}
                  path="/activities/individual-avaliation"
                >
                  <Activities />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.Activity.View]}
                  path="/activities/automate"
                >
                  <Automations />
                </RouteAuthorized>

                <Route exact path="/activities/create/:type">
                  <CreateActivity />
                </Route>
                <Route exact path="/activities/edit/:type/:id">
                  <CreateActivity />
                </Route>
                {/* <Route exact path="/activities/:typeId">
                <CreateActivity />
              </Route> */}

                <Route exact path="/activities">
                  <ActivitiesPages />
                </Route>
              </Switch>
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[Permissions.SafeSpace.View]}
              path="/safe-space"
            >
              <Switch>
                <RouteAuthorized
                  permissions={[Permissions.SafeSpace.View]}
                  exact
                  path="/safe-space"
                >
                  <SafeSpace />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.SafeSpace.View]}
                  exact
                  path="/safe-space/:id"
                >
                  <SafeSpaceAnalysis />
                </RouteAuthorized>
              </Switch>
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[
                Permissions.Practices.View,
                Permissions.U4hero.Practices.View,
              ]}
              path="/practices"
            >
              <Switch>
                <RouteAuthorized exact path="/practices">
                  <PracticesPage />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.U4hero.Practices.View]}
                  exact
                  path="/practices/manager"
                >
                  <PracticesManager />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[Permissions.U4hero.Practices.Create]}
                  exact
                  path="/practices/create"
                >
                  <CreatePractice />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[
                    Permissions.U4hero.Practices.Adapt,
                    Permissions.Practices.Adapt,
                  ]}
                  exact
                  path="/practices/adapt/:id"
                >
                  <AdaptPractice />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[
                    Permissions.U4hero.Practices.View,
                    Permissions.Practices.View,
                  ]}
                  exact
                  path="/practices/suggestions"
                >
                  <PracticesSuggestions />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[
                    Permissions.Practices.View,
                    Permissions.U4hero.Practices.View,
                  ]}
                  exact
                  path="/practices/:id"
                >
                  <PracticePage />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[Permissions.U4hero.Practices.Edit]}
                  exact
                  path="/practices/edit/:id"
                >
                  <CreatePractice />
                </RouteAuthorized>
              </Switch>
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[
                Permissions.U4hero.Posts.View,
                Permissions.Posts.View,
              ]}
              path="/posts-hub"
            >
              <PostsHub />
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[
                Permissions.U4hero.Posts.View,
                Permissions.Posts.View,
              ]}
              path="/manager-store"
            >
              <ManagerStore />
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[
                Permissions.U4hero.Posts.View,
                Permissions.Posts.View,
              ]}
              path="/posts"
            >
              <Switch>
                <RouteAuthorized
                  permissions={[
                    Permissions.Posts.View,
                    Permissions.U4hero.Posts.View,
                  ]}
                  exact
                  path="/posts"
                >
                  <Posts />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.U4hero.Posts.View]}
                  exact
                  path="/posts/manager"
                >
                  <PostsManager />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[Permissions.U4hero.Posts.Create]}
                  exact
                  path="/posts/create"
                >
                  <CreatePost />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[
                    Permissions.Posts.View,
                    Permissions.U4hero.Posts.View,
                  ]}
                  exact
                  path="/posts/:id"
                >
                  <PostPage />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.U4hero.Posts.Edit]}
                  exact
                  path="/posts/edit/:id"
                >
                  <CreatePost />
                </RouteAuthorized>
              </Switch>
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[
                Permissions.U4hero.Posts.View,
                Permissions.Posts.View,
              ]}
              path="/blog-posts"
            >
              <Switch>
                <RouteAuthorized
                  permissions={[Permissions.U4hero.Posts.View]}
                  exact
                  path="/blog-posts/manager"
                >
                  <BlogPostsManager />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[Permissions.U4hero.Posts.Create]}
                  exact
                  path="/blog-posts/create"
                >
                  <CreateBlogPost />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.U4hero.Posts.Edit]}
                  exact
                  path="/blog-posts/edit/:id"
                >
                  <CreateBlogPost />
                </RouteAuthorized>
              </Switch>
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[Permissions.U4hero.Posts.Create]}
              path="/stories"
            >
              <Switch>
                <RouteAuthorized
                  permissions={[Permissions.U4hero.Posts.View]}
                  exact
                  path="/stories/manager"
                >
                  <StoriesManager />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[Permissions.U4hero.Posts.Create]}
                  exact
                  path="/stories/create"
                >
                  <CreateStory />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.U4hero.Posts.Edit]}
                  exact
                  path="/stories/edit/:id"
                >
                  <CreateStory />
                </RouteAuthorized>
              </Switch>
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[Permissions.U4hero.Metrics.View]}
              path="/metrics"
            >
              <Switch>
                <RouteAuthorized
                  permissions={[Permissions.U4hero.Metrics.View]}
                  path="/metrics/:id"
                >
                  <MetricsSchoolPage />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.U4hero.Metrics.View]}
                  exact
                  path="/metrics"
                >
                  <MetricsPage />
                </RouteAuthorized>
              </Switch>
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[Permissions.U4hero.Questions.View]}
              path="/question"
            >
              <Switch>
                <RouteAuthorized
                  permissions={[Permissions.U4hero.Questions.Edit]}
                  path="/question/edit/:id"
                >
                  <CreateQuestionPage />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.U4hero.Questions.View]}
                  exact
                  path="/question"
                >
                  <QuestionsPage />
                </RouteAuthorized>
              </Switch>
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[
                Permissions.Inkluzen.Geographic.View,
                Permissions.Inkluzen.Macro.View,
                Permissions.Inkluzen.Symptoms.View,
                Permissions.Inkluzen.Student.View,
              ]}
              path="/inkluzen"
            >
              <Switch>
                <RouteAuthorized
                  permissions={[Permissions.Inkluzen.Macro.View]}
                  path="/inkluzen/macro-view"
                >
                  <MacroView />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.Inkluzen.Geographic.View]}
                  path="/inkluzen/exploration-view"
                >
                  <ExplorationView />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.Inkluzen.Student.View]}
                  exact
                  path="/inkluzen/students"
                >
                  <StudentInfo />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.Inkluzen.Student.View]}
                  exact
                  path="/inkluzen/students/:id/process"
                >
                  <StudentProcess />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.Inkluzen.Student.View]}
                  exact
                  path="/inkluzen/students/:id/process/:processId"
                >
                  <ProcessDetails />
                </RouteAuthorized>
              </Switch>
            </RouteAuthorized>

            <Route exact path="/settings">
              <SettingsPage />
            </Route>
            <RouteAuthorized
              permissions={[
                Permissions.Tenant.View,
                Permissions.Org.Tenant.View,
              ]}
              path="/my-school"
            >
              <Switch>
                <RouteAuthorized
                  permissions={[Permissions.Students.View]}
                  path="/my-school/students"
                >
                  <Students />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[Permissions.Class.View]}
                  exact
                  path="/my-school/classes"
                >
                  <Classes />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[Permissions.Class.View]}
                  exact
                  path="/my-school/class/dashboard/:classId"
                >
                  <ClassPanel />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[Permissions.Class.Edit]}
                  exact
                  path="/my-school/classes/:id/edit"
                >
                  <ClassCreate />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.Class.Edit]}
                  exact
                  path="/my-school/classes/:id/reenroll"
                >
                  <ClassReenroll />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.Class.View]}
                  exact
                  path="/my-school/classes/:id/:mode"
                >
                  <ClassCreate />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.Class.Create]}
                  exact
                  path="/my-school/classes/create"
                >
                  <ClassCreate />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[
                    Permissions.Collaborators.View,
                    Permissions.Org.Collaborators.View,
                  ]}
                  path="/my-school/collaborators"
                >
                  <CollaboratorsPage />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[
                    Permissions.Collaborators.View,
                    Permissions.Org.Profile.View,
                  ]}
                  exact
                  path="/my-school/profiles"
                >
                  <Profiles />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[
                    Permissions.Collaborators.Create,
                    Permissions.Org.Profile.Create,
                  ]}
                  path="/my-school/profiles/create"
                >
                  <CreateProfile />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[
                    Permissions.Collaborators.Edit,
                    Permissions.Org.Profile.Edit,
                  ]}
                  exact
                  path="/my-school/profiles/edit/:id"
                >
                  <CreateProfile />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[
                    Permissions.Tenant.Edit,
                    Permissions.Tenant.Edit,
                  ]}
                  path="/my-school/settings"
                >
                  <SchoolProfile />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[
                    Permissions.Tenant.View,
                    Permissions.Org.Tenant.View,
                  ]}
                  exact
                  path="/my-school"
                >
                  <MySchoolPage />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[Permissions.Students.View]}
                  path="/my-school/student/:id"
                >
                  <StudentPanel />
                </RouteAuthorized>
              </Switch>
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[Permissions.U4hero.Helpers.Edit]}
              path="/helpers-u4hero"
            >
              <Switch>
                <RouteAuthorized
                  permissions={[Permissions.U4hero.Helpers.Edit]}
                  exact
                  path="/helpers-u4hero"
                >
                  <Helpers />
                </RouteAuthorized>
                <RouteAuthorized
                  permissions={[Permissions.U4hero.Helpers.Edit]}
                  path="/helpers-u4hero/:id"
                >
                  <CreateHelper />
                </RouteAuthorized>
              </Switch>
            </RouteAuthorized>

            <RouteAuthorized path="/helpers">
              <Switch>
                <RouteAuthorized exact path="/helpers">
                  <HelpersCenter />
                </RouteAuthorized>
                <RouteAuthorized path="/helpers/first-steps">
                  <FirstSteps />
                </RouteAuthorized>

                <RouteAuthorized path="/helpers/general-config">
                  <GeneralConfig />
                </RouteAuthorized>
                <RouteAuthorized path="/helpers/funcionalities">
                  <Funcionalities />
                </RouteAuthorized>
                <RouteAuthorized path="/helpers/:id">
                  <HelperPost />
                </RouteAuthorized>
              </Switch>
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[Permissions.U4hero.Helpers.Edit]}
              path="/tours/initial-tour"
            >
              <InitialTourManager />
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[Permissions.U4hero.Helpers.Edit]}
              path="/tours/:id"
            >
              <CreateTour />
            </RouteAuthorized>

            <RouteAuthorized path="/tags">
              <Switch>
                <RouteAuthorized exact path="/tags">
                  <Tags />
                </RouteAuthorized>
                <RouteAuthorized path="/tags/:id">
                  <ListSkillsMobile />
                </RouteAuthorized>
              </Switch>
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[Permissions.U4hero.Metrics.View]}
              exact
              path="/competences"
            >
              <Competences />
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[Permissions.U4hero.Tenant.Edit]}
              path="/settings-u4hero"
            >
              <Switch>
                <RouteAuthorized
                  permissions={[Permissions.U4hero.Tenant.Edit]}
                  exact
                  path="/settings-u4hero"
                >
                  <SettingsU4hero />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[Permissions.U4hero.Tenant.Edit]}
                  exact
                  path="/settings-u4hero/profiles/create"
                >
                  <CreateProfile />
                </RouteAuthorized>

                <RouteAuthorized
                  permissions={[Permissions.U4hero.Tenant.Edit]}
                  exact
                  path="/settings-u4hero/profiles/edit/:id"
                >
                  <CreateProfile />
                </RouteAuthorized>
              </Switch>
            </RouteAuthorized>

            <RouteAuthorized
              permissions={[
                Permissions.U4hero.Tenant.Edit,
                Permissions.Org.Tenant.Edit,
              ]}
              path="/schools"
            >
              <Switch>
                <Route exact path="/schools">
                  <Schools />
                </Route>

                <Route exact path="/schools/create">
                  <CreateSchool />
                </Route>

                <Route exact path="/schools/edit/:id">
                  <CreateSchool />
                </Route>
                <Route exact path="/schools/:tenantId/students">
                  <Students />
                </Route>

                <Route exact path="/schools/:tenantId/student/:id">
                  <StudentPanel />
                </Route>

                <Route exact path="/schools/:tenantId/classes">
                  <Classes />
                </Route>

                <Route exact path="/schools/:tenantId/classes/create">
                  <ClassCreate />
                </Route>

                <Route exact path="/schools/:tenantId/class/dashboard/:classId">
                  <ClassPanel />
                </Route>

                <Route exact path="/schools/:tenantId/classes/edit/:id">
                  <ClassCreate />
                </Route>

                <Route exact path="/schools/:tenantId/classes/reenroll/:id">
                  <ClassReenroll />
                </Route>

                <Route exact path="/schools/:tenantId/classes/:id/:mode">
                  <ClassCreate />
                </Route>

                <Route exact path="/schools/:tenantId/profiles">
                  <Profiles />
                </Route>

                <Route exact path="/schools/:tenantId/profiles/edit/:id">
                  <CreateProfile />
                </Route>

                <Route exact path="/schools/:tenantId/profiles/create">
                  <CreateProfile />
                </Route>

                <Route exact path="/schools/:tenantId/resources">
                  <ManagerSchoolResources />
                </Route>

                <Route exact path="/schools/:tenantId/collaborators">
                  <CollaboratorsPage />
                </Route>
              </Switch>
            </RouteAuthorized>

            <Route exact path="/errors/no-permission">
              <NoPermission />
            </Route>
            <Route exact path="/errors/maintenance">
              <Maintenance />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Layout.Content>

        <FloatButtonComponent />
      </Layout>
    </div>
  );
}

export default AppPages;
