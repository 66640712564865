import useSeries from "core/features/series/hooks/useSeries";
import { Select } from "antd";
import SelectBase, { SelectBaseProps } from "../select-base";
import { UseFormSetValue } from "react-hook-form";
import { t } from "core/resources/strings";

const { Option } = Select;

export interface SelectSerieProps<T = any> extends SelectBaseProps<T> {
  value: any;
  onChange: (v: any) => void;
  setValue?:UseFormSetValue<any>;
}

function SelectSerie({ value, onChange,setValue, ...rest }: SelectSerieProps) {
  const { isLoading, data } = useSeries();

  return (
    <SelectBase
      loading={isLoading}
      placeholder={t('app.serie')}
      value={value}
      onChange={(ev) => {
        setValue && setValue('serieName', data?.find((v)=> v.id === ev )?.name)
        onChange(ev);
      }}
      {...rest}
    >
      {data?.map((item) => (
        <Option value={item.id} key={item.id}>
          {item.name}
        </Option>
      ))}
    </SelectBase>
  );
}

export default SelectSerie;
