import { useMediaQuery } from '@chakra-ui/react';
import { Col, Drawer, Flex, Form, Input, Row } from 'antd';
import Layout from 'components/layout';
import CustomizedRequiredMark from 'components/required-mark';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { HiArrowRight } from 'react-icons/hi';
import { IoClose, IoSaveOutline } from 'react-icons/io5';
import { PiEyeFill } from 'react-icons/pi';
import { useParams } from 'react-router';
import CustomEditor from 'components/customEditor';
import useHelper from 'core/features/helpers/hooks/useHelper';
import { useSaveHelper } from 'core/features/helpers/hooks/useSaveHelper';
import { HelperForm } from 'core/features/helpers/typings';
import BackButton from 'components/buttons/back-button';
import { useHistory } from 'react-router-dom';
import Title from 'components/typography/title';
import Button from 'components/buttons/button';
import SelectClassification from '../../components/selectClassification';
import SelectSubClassification from '../../components/selectSubClassification';
import SelectTour from '../../components/selectTour';
import HelperPreview from '../../components/HelperPreview/index';

function CreateHelper() {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { data, isLoading } = useHelper(
    params.id === 'new' ? undefined : params.id
  );
  const [published, setPublished] = useState<boolean>(true);
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveHelper();
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  const classification = Form.useWatch('classification', form);
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();

  const updateForm = (fieldName: string, value: any) => {
    form.setFieldValue(fieldName, value);
  };

  const onSubmit = (values: HelperForm) => {
    handleSave(
      {
        ...values,
        active: published,
        published: published,
        id: params.id !== 'new' ? params.id : undefined,
      },
      {
        onError: () => {
          notificateError(
            `Houve um problema ao ${
              params.id !== 'new' ? 'editar' : 'publicar'
            }. Verifique sua conexão e tente novamente.`
          );
        },
        onSuccess: () => {
          notificateSucess(
            `${
              params.id !== 'new'
                ? 'Ajuda editada com sucesso!'
                : `Ajuda ${
                    published ? 'publicada com sucesso' : 'salva como rascunho'
                  } !`
            }`
          );
          setTimeout(() => {
            history.goBack();
          }, 200);
        },
      }
    );
  };

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  return (
    <Layout.Container>
      <Row gutter={[16, 0]}>
        <Col span={isPreviewOpen ? 12 : 24}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onSubmit}
            requiredMark={CustomizedRequiredMark}
            validateTrigger="submit"
            initialValues={data ?? undefined}
          >
            <Flex gap={isMobile ? '12px' : '24px'} align="center">
              <BackButton
                onClick={() => {
                  if (form.isFieldsTouched()) {
                    openNotification({
                      colorPrimary,
                      isMobile,
                      onClose: () => history.goBack(),
                    });
                  } else {
                    history.goBack();
                  }
                }}
              />

              <Title primary>
                {params.id !== 'new' ? 'Editar' : 'Criar'} Ajuda
              </Title>
            </Flex>

            <Flex
              gap="16px"
              justify="end"
              align="center"
              style={{ marginBottom: isMobile ? '8px' : '16px' }}
            >
              <Button
                size="md"
                id="id-tour-helpers-create-button-save"
                variant="outline"
                htmlType="submit"
                onClick={() => setPublished(false)}
                loading={isLoadingSave}
              >
                <IoSaveOutline color={colorPrimary} size={24} />
              </Button>

              <Button
                size="md"
                id="id-tour-helpers-create-button-preview"
                variant="outline"
                style={{
                  display: 'flex',
                  gap: isMobile ? '4px' : '16px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => setIsPreviewOpen(true)}
              >
                <PiEyeFill size={isMobile ? 16 : 28} color={colorPrimary} />{' '}
                PRÉVIA
              </Button>

              <Button
                size="md"
                id="id-tour-helpers-create-button-publish"
                htmlType="submit"
                loading={isLoadingSave}
              >
                PUBLICAR
              </Button>
            </Flex>

            <Form.Item
              label="Título"
              rules={[
                {
                  required: true,
                  message: 'Por favor, crie um título para a publicação!',
                },
              ]}
              name="title"
            >
              <Input
                id="id-tour-create-helper-input-title"
                placeholder="Crie um título"
                size={isMobile ? 'middle' : 'large'}
              />
            </Form.Item>

            <Form.Item
              label="Palavras-Chave"
              rules={[
                {
                  required: true,
                  message: 'Por favor, insira palavras chaves!',
                },
              ]}
              name="keyword"
            >
              <Input
                id="id-tour-create-helper-input-keywords"
                placeholder="Insira palavras-chave para uma busca eficaz"
                size={isMobile ? 'middle' : 'large'}
              />
            </Form.Item>

            <Row align="middle">
              <Col span={isMobile ? 24 : 11}>
                <Form.Item
                  label="Classificação"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, defina a classificação!',
                    },
                  ]}
                  name="classification"
                >
                  {/* @ts-ignore */}
                  <SelectClassification updateForm={updateForm} />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 0 : 2}>
                <Flex justify="center">
                  <HiArrowRight color={colorGreyBorderAndFont} size={40} />
                </Flex>
              </Col>
              <Col span={isMobile ? 24 : 11}>
                <Form.Item
                  label="Subdivisão"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, defina a subdivisão!',
                    },
                  ]}
                  name="subClassification"
                >
                  {/* @ts-ignore */}
                  <SelectSubClassification
                    classification={classification}
                    disabled={classification === undefined}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={isMobile ? 24 : 11}>
                <Form.Item label="Vincular à URL" name="url">
                  <Input
                    id="id-tour-create-helper-input-url"
                    placeholder="Vincule a uma URL"
                    size={isMobile ? 'middle' : 'large'}
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 0 : 2}></Col>
              <Col span={isMobile ? 24 : 11}>
                <Form.Item label="Vincular à Tour" name="tourId">
                  {/* @ts-ignore */}
                  <SelectTour />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Vídeo " name="videoUrl">
              <Input
                id="id-tour-create-helper-input-videoUrl"
                placeholder=" Insira a URL do vídeo aqui"
                size={isMobile ? 'middle' : 'large'}
              />
            </Form.Item>
            <Form.Item
              label="Conteúdo"
              rules={[
                {
                  required: true,
                  message: 'Por favor, escreva o conteúdo!',
                },
              ]}
              name="md"
            >
              {/* @ts-ignore */}
              <CustomEditor
                editorId="helper-editor"
                folders={{ images: 'helpers-images', files: 'helpers-files' }}
              />
            </Form.Item>
          </Form>
        </Col>
        {!isMobile ? (
          <Col
            span={isPreviewOpen ? 12 : 0}
            style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.40)' }}
          >
            <HelperPreview
              {...formValues}
              onClose={() => setIsPreviewOpen(false)}
            />
          </Col>
        ) : null}
      </Row>

      <Drawer
        title={
          <Flex justify="space-between">
            <Title
              primary
              style={{
                marginBottom: '16px',
              }}
            >
              Prévia
            </Title>

            <IoClose
              style={{ cursor: 'pointer', justifySelf: 'end' }}
              size={24}
              color={colorGreyBorderAndFont}
              onClick={() => setIsPreviewOpen(false)}
            />
          </Flex>
        }
        placement={'right'}
        closable={false}
        open={isPreviewOpen && isMobile}
      >
        <HelperPreview
          {...formValues}
          onClose={() => setIsPreviewOpen(false)}
        />
      </Drawer>
    </Layout.Container>
  );
}

export default CreateHelper;
