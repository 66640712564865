import React from 'react';
import { Collapse, Form } from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import Text from 'components/typography/text';
import CustomEditor from 'components/customEditor';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { t } from 'core/resources/strings';

interface InstructionsCardProps {
  hideUpload?: boolean;
}

const InstructionsCard: React.FC<InstructionsCardProps> = ({ hideUpload = false }) => {
  const { colorPrimary } = useU4heroColors();

  return (
    <GenericCard>
      <Collapse
        ghost
        expandIconPosition="end"
        style={{ width: '100%' }}
        defaultActiveKey={'1'}
      >
        <Collapse.Panel
          key={'1'}
          className="remove-padding"
          header={
            <Text size="md" color={colorPrimary} bold style={{ width: '100%' }}>
              {t('app.instrucoes')}
            </Text>
          }
        >
          <Form.Item
            label=""
            rules={[
              {
                required: true,
                message: t('app.erroInstrucoes'),
              },
            ]}
            name="content"
          >
            {/* @ts-ignore */}
            <CustomEditor
              editorId="instructions-editor"
              folders={{
                images: 'practices-images',
                files: 'practices-files',
              }}
              acceptFiles={!hideUpload}
              hideUpload={hideUpload}
            />
          </Form.Item>
        </Collapse.Panel>
      </Collapse>
    </GenericCard>
  );
};

export default InstructionsCard;
