import { useQuery } from "react-query";
import axios from "infra/http";
import { SafeSpaceItem} from "../typings";

function useSafeSpace(id: string) {
  const fetchData = async (id: string): Promise<SafeSpaceItem> => {
    const { data } = await axios.get<SafeSpaceItem>(`v2/safeSpace/${id}`);

    return data;
  };

  return useQuery([`v2/safeSpace/${id}`], () => fetchData(id!), {
    enabled: !!id,
  });
}

export default useSafeSpace;
