import { extendTheme, theme as themeChakra } from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";
import {
  primary,
  primary100,
  primary200,
  blue100,
  blue300,
  blue400,
  blue500,
  orange100,
  orange500,
  pink100,
  pink500,
  purple100,
  purple300,
  purple50,
  purple500,
  purple800,
  gray100,
  gray300,
  gray500,
  gray800,
  yellow,
  gray900,
} from "core/resources/theme/colors";

const theme = extendTheme({
  ...themeChakra,
  components: {
    ...themeChakra.components,
    Steps,
  },
  colors: {
    ...themeChakra.colors,
    yellow: {
      ...themeChakra.colors.yellow,
      900: yellow,
    },
    blue: {
      ...themeChakra.colors.blue,
      100: blue100,
      300: blue300,
      400: blue400,
      500: blue500,
    },
    primary: primary,
    primary100: primary100,
    primary200: primary200,
    gray: {
      ...themeChakra.colors.gray,
      50: "#eff0f9",
      100: gray100,
      300: gray300,
      500: gray500,
      800: gray800,
      900: gray900,
    },
    orange: {
      ...themeChakra.colors.orange,
      100: orange100,
      500: orange500,
    },
    pink: {
      ...themeChakra.colors.pink,
      100: pink100,
      500: pink500,
    },
    purple: {
      ...themeChakra.colors.purple,
      100: purple100,
      300: purple300,
      50: purple50,
      500: purple500,
      800: purple800,
    },
  },
  fonts: {
    ...themeChakra.fonts,
    body: "Poppins, sans-serif",
    heading: "Poppins, serif",
    mono: "Menlo, monospace",
  },
  styles: {
    ...themeChakra.styles,
    global: {
      "html, body": {
        fontFamily: "Poppins, sans-serif",
      },
      h1:{
        display: 'block',
        fontSize: '2em',
        marginTop: '0.67em',
        marginBottom: '0.67em',
        marginLeft: '0',
        marginRight: '0',
        fontWeight: 'bold',
        fontColor:gray800
      } ,
      h2:{
        display: 'block',
        fontSize: '1.5em',
        marginTop: '0.83em',
        marginBottom: '0.83em',
        marginLeft: '0',
        marginRight: '0',
        fontWeight: 'bold',
        fontColor:gray800
      },
      h3:{
        display: 'block',
        fontSize: '1.17em',
        marginTop: '1em',
        marginBottom: '1em',
        marginLeft: '0',
        marginRight: '0',
        fontWeight: 'bold',
        fontColor:gray800
      },
      h4:{
        display: 'block',
        fontSize: '1em',
        marginTop: '1.33em',
        marginBottom: '1.33em',
        marginLeft: '0',
        marginRight: '0',
        fontWeight: 'bold',
        fontColor:gray800
      },
      h5:{
        display: 'block',
        fontSize: '.83em',
        marginTop: '1.67em',
        marginBottom: '1.67em',
        marginLeft: '0',
        marginRight: '0',
        fontWeight: 'bold',
        fontColor:gray800
      },
      h6:{
        display: 'block',
        fontSize: '.67em',
        marginTop: '2.33em',
        marginBottom: '2.33em',
        marginLeft: '0',
        marginRight: '0',
        fontWeight: 'bold',
        fontColor:gray800
      }    
     
    }
    
  },
});

export default theme;
