import { useQuery } from 'react-query'
import axios from 'infra/http'
import { HelperDetails } from '../typings'

function useHelper(url?: string) {
  const fetchData = async (id: string): Promise<HelperDetails> => {
    const { data } = await axios.get<HelperDetails>(`/v2/helpers/url`, {
      params: {
        url: url,
      },
    })

    return data
  }

  return useQuery([`/v2/helpers/url?url=${url}`], () => fetchData(url!), {
    enabled: !!url,
  })
}

export default useHelper
