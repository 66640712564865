import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Form, Input, Row } from 'antd';
import Layout from 'components/layout';
import CustomizedRequiredMark from 'components/required-mark';
import { useCurrentUser } from 'core/features/user/store';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { IoSaveOutline } from 'react-icons/io5';
import { useHistory, useParams } from 'react-router';
import Button from 'components/buttons/button';
import BackButton from 'components/buttons/back-button';
import Title from 'components/typography/title';
import useBlogPost from 'core/features/blog-posts/hooks/useBlogPost';
import { useSaveBlogPost } from 'core/features/blog-posts/hooks/useSaveBlogPost';
import { BlogPostForm } from 'core/features/blog-posts/typings';
import moment from 'moment';
import ModalWelcomeCreateStory from 'components/modals/modal-welcome-create-story';
import Text from 'components/typography/text';
import { PiImage, PiPencilThin } from 'react-icons/pi';
import ModalScenario from 'components/modals/modal-scenario';
import ModalStoryAi from 'components/modals/modal-story-ai';
import ModalRoute from 'components/modals/modal-route';
import { FiTrash2 } from 'react-icons/fi';

interface Route {
  name: string;
  characters: string[];
}

const Circle = ({
  size,
  children,
}: {
  size: number;
  children: React.ReactNode;
}) => {
  return (
    <div
      style={{
        borderRadius: '50%',
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      {children}
    </div>
  );
};

function CreateStory() {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { user } = useCurrentUser();
  const params = useParams<{
    id: string;
  }>();
  const { data } = useBlogPost(params.id);
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveBlogPost();
  const [form] = Form.useForm();
  const routes = Form.useWatch<Route[] | undefined>('routes', form);
  const [published, setPublished] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalScenarioOpen, setIsModalScenarioOpen] = useState(false);
  const [isModalStoryAiOpen, setIsModalStoryAiOpen] = useState(false);
  const [isModalRouteOpen, setIsModalRouteOpen] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const onSubmit = (values: BlogPostForm) => {
    handleSave(
      {
        ...values,
        published: published,
        id: params.id,
        publishDate: moment(values.publishDate).format('YYYY/MM/DD'),
      },
      {
        onError: () => {
          notificateError(
            `Houve um problema ao ${
              params.id ? 'editar' : 'publicar'
            } a história. Verifique sua conexão e tente novamente.`
          );
        },
        onSuccess: () => {
          notificateSucess(
            `${
              !published
                ? 'História salva como rascunho!'
                : `História ${params.id ? 'editada' : 'publicada'} com sucesso!`
            }`
          );
          setTimeout(() => {
            history.push('/stories/manager');
          }, 200);
        },
      }
    );
  };

  useEffect(() => {
    if (!user?.hideWelcomeCreateStory) {
      setIsModalOpen(true);
    }
  }, []);

  const onRemoveRoute = (index: number) => {
    console.log('removi ', index);
    //todo
  };
  return (
    <Layout.Container>
      {isModalOpen && (
        <ModalWelcomeCreateStory onClose={() => setIsModalOpen(false)} />
      )}
      {isModalScenarioOpen && (
        <ModalScenario onClose={() => setIsModalScenarioOpen(false)} />
      )}
      {isModalStoryAiOpen && (
        <ModalStoryAi
          onClose={() => setIsModalStoryAiOpen(false)}
          onSave={() => console.log('salvei')}
        />
      )}
      {isModalRouteOpen && (
        <ModalRoute
          onClose={() => setIsModalRouteOpen(false)}
          onSave={(route: Route) => {
            if (routes?.length) {
              form.setFieldValue('routes', [...routes, route]);
              return;
            }
            form.setFieldValue('routes', [route]);
          }}
        />
      )}

      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        requiredMark={CustomizedRequiredMark}
        validateTrigger="submit"
        initialValues={
          data
            ? {
                ...data,
                publishDate: moment.utc(data.publishDate).format('YYYY-MM-DD'),
              }
            : {
                publishDate: moment().format('YYYY-MM-DD'),
              }
        }
      >
        <Flex gap="23px" align="center">
          <BackButton
            onClick={() => {
              if (form.isFieldsTouched()) {
                openNotification({
                  colorPrimary,
                  isMobile,
                  onClose: () => history.push('/stories/manager'),
                });
              } else {
                history.push('/stories/manager');
              }
            }}
          />

          <Title primary>{params.id ? 'Editar' : 'Criar'} História</Title>
        </Flex>

        <Flex
          gap="16px"
          justify="end"
          style={{ marginBottom: isMobile ? '8px' : '16px' }}
        >
          <Button
            size="md"
            id="id-tour-stories-create-button-generate-story-with-ai"
            htmlType="button"
            loading={isLoadingSave}
            variant="outline"
            onClick={() => setIsModalStoryAiOpen(true)}
          >
            GERAR HISTÓRIA COM IA
          </Button>
          <Button
            variant="outline"
            size="md"
            id="id-tour-stories-create-button-save"
            htmlType="submit"
            onClick={() => setPublished(false)}
            loading={isLoadingSave}
          >
            <IoSaveOutline color={colorPrimary} size={24} />
          </Button>

          <Button
            size="md"
            id="id-tour-stories-create-button-publish"
            htmlType="submit"
            loading={isLoadingSave}
          >
            {params.id ? 'SALVAR' : 'PUBLICAR'}
          </Button>
        </Flex>

        <Row gutter={{ xs: 16, md: 24 }} align="stretch">
          <Col span={6}>
            <Flex
              vertical
              style={{
                padding: '16px',
                backgroundColor: '#F1F1F1CC',
                borderRadius: '20px',
                height: '100%',
              }}
            >
              <Text
                size="lg"
                bold
                color={colorPrimary}
                style={{ marginBottom: '8px' }}
              >
                Informações Gerais
              </Text>
              <Form.Item
                label=""
                rules={[
                  {
                    required: true,
                    message: 'Por favor, de um nome para a história!',
                  },
                ]}
                name="name"
              >
                <Input
                  id="id-tour-create-story-input-name"
                  placeholder="Insira o nome da história"
                  size={isMobile ? 'middle' : 'large'}
                />
              </Form.Item>

              <Form.Item
                label=""
                rules={[
                  {
                    required: true,
                    message: 'Por favor, descreva a história!',
                  },
                ]}
                name="description"
              >
                <Input.TextArea
                  id="id-tour-create-story-input-description"
                  placeholder="Descreva brevemente a história"
                  size={isMobile ? 'middle' : 'large'}
                  rows={4}
                />
              </Form.Item>
              <Form.Item
                name="scenario"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, selecione o cenário!',
                  },
                ]}
              >
                <Flex
                  gap="8px"
                  align="center"
                  justify="center"
                  onClick={() => setIsModalScenarioOpen(true)}
                  style={{ cursor: 'pointer' }}
                >
                  <PiImage size={28} color={colorGreyBorderAndFont} />
                  <Text
                    size="md"
                    style={{ textDecoration: 'underline' }}
                    color={colorGreyBorderAndFont}
                  >
                    Definir Cenário
                  </Text>
                </Flex>
              </Form.Item>

              <Form.Item name="routes" hidden />

              <Text
                size="lg"
                bold
                color={colorPrimary}
                style={{ marginBottom: '8px' }}
              >
                Rotas
              </Text>
              <Flex
                vertical
                gap="8px"
                justify="center"
                align="center"
                style={{ width: '100%' }}
              >
                <Button
                  size="md"
                  style={{ alignSelf: 'center' }}
                  onClick={() => setIsModalRouteOpen(true)}
                >
                  + Rotas
                </Button>
                <Flex vertical style={{ width: '100%' }}>
                  {routes?.map((route, index) => (
                    <Flex vertical gap="8px">
                      <Flex
                        style={{
                          borderRadius: '10px',
                          border: '1px solid #452392',
                          background: 'rgba(69, 35, 146, 0.20)',
                          padding: '8px',
                        }}
                        vertical
                        align="center"
                        justify="center"
                        gap="8px"
                      >
                        <Text
                          size="md"
                          bold
                          style={{
                            borderRadius: '50%',
                            width: `30px`,
                            height: `30px`,
                            background: colorPrimary,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          align="center"
                          color="white"
                        >
                          {index + 1}
                        </Text>
                        <Text size="sm" bold color={colorPrimary}>
                          {route.name}
                        </Text>
                      </Flex>
                      <Flex justify="center" align="center" gap="16px">
                        <PiPencilThin
                          size={30}
                          color={'white'}
                          style={{
                            backgroundColor: colorPrimary,
                            borderRadius: '50%',
                            padding: '4px',
                            cursor: 'pointer',
                          }}
                        />
                        <FiTrash2
                          onClick={() => onRemoveRoute(index)}
                          size={30}
                          color="white"
                          style={{
                            backgroundColor: colorPrimary,
                            borderRadius: '50%',
                            cursor: 'pointer',
                            padding: '4px',
                          }}
                        />
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </Col>

          <Col span={18}>
            <Flex
              vertical
              style={{
                padding: '16px',
                backgroundColor: '#F1F1F1CC',
                borderRadius: '20px',
                height: '100%',
              }}
            >
              <Text
                size="lg"
                bold
                color={colorPrimary}
                style={{ marginBottom: '8px' }}
              >
                Área de Criação
              </Text>

              <Button size="md" style={{ alignSelf: 'center' }}>
                + Interações
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>
    </Layout.Container>
  );
}

export default CreateStory;
