import { useQuery } from 'react-query';
import axios from 'infra/http';
import { MetricsCollaborator } from '../typings';

export interface UseMetricsCollaboratorParams {
  collaboratorId?: string;
  startDate?: string;
  endDate?: string;
}
function useMetricsCollaborator(params: UseMetricsCollaboratorParams) {
  const fetchData = async (
    params: UseMetricsCollaboratorParams
  ): Promise<MetricsCollaborator> => {
    const { data } = await axios.get<MetricsCollaborator>(
      `/v2/metrics/collaborators`,
      {
        params: {
          collaboratorId: params.collaboratorId,
          startDate: params.startDate,
          endDate: params.endDate,
        },
      }
    );

    return data;
  };

  return useQuery(
    [
      `/v2/metrics/collaborators?collaboratorId=${params.collaboratorId}&startDate=${params.startDate}&endDate=${params.endDate}`,
    ],
    () => fetchData(params),
    {
      enabled: params.collaboratorId !== undefined,
    }
  );
}

export default useMetricsCollaborator;
