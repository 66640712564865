import { Input } from '@chakra-ui/input'
import { Box,Text, Flex } from '@chakra-ui/layout'
import { Button, useMediaQuery } from '@chakra-ui/react'
import SelectTag from 'components/inputs/select-tag'
import { reportTypes } from 'core/features/report/types'
import { t } from 'core/resources/strings'
import { BiArrowBack } from 'react-icons/bi'

function ActivityFilterBar({
  onPublishDate,
  onEndDate,
  onCompetence,
  valueCompetence,
  setStep,
  type,
}: {
  onPublishDate?: (v: string) => void
  onEndDate?: (v: string) => void
  onCompetence?: (v: any) => void
  valueCompetence?: any
  setStep: (v: any) => void
  type:reportTypes
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <Box shadow="lg" rounded="8px" bg="#f5f5fb" p="16px" width={isMobile ? '100vw':''} id='id-tour-report-activity-filter'>
        <Flex direction={isMobile ? 'column':'row'}>
          <Box mt={isMobile ?'11px':"22px"}>
            {type === reportTypes.Student || type === reportTypes.Class ?  <Button
            size="sm"
            variant="outline"
            colorScheme="orange"
            leftIcon={<BiArrowBack />}
            onClick={() => setStep && setStep(0)}
          >
            {type === reportTypes.Student ? "Estudantes" : "Turmas"}
          </Button> : null}
          
          </Box>
        
          <Box width="200px" ml={isMobile ? '0px' :'20px'}>
            <Text>{t('app.trilha')}</Text>
            <SelectTag
              competence={true}
              value={valueCompetence}
              onChange={(v) => onCompetence && onCompetence(v)}
            />
          </Box>

          <Box ml={isMobile ? '0px' :'20px'}>
            <Text>{t('app.dataPublicacao')}</Text>
            <Input
              onChange={(v) => onPublishDate && onPublishDate(v.target.value)}
              bg="white"
              placeholder={t('app.dataPublicacao')}
              type="date"
              width="150px"
            />
          </Box>

          <Box ml={isMobile ? '0px' :'20px'}>
            <Text>{t('app.dataFinal')}</Text>
            <Input
              onChange={(v) => onEndDate && onEndDate(v.target.value)}
              bg="white"
              placeholder={t('app.dataFinal')}
              type="date"
              width="150px"
            />
          </Box>
        </Flex>
      </Box>
    </>
  )
}

export default ActivityFilterBar
