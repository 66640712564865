import { useQuery } from 'react-query';
import axios from 'infra/http';
import { TourDetails } from '../typings';
import { useEffect, useState } from 'react';

export interface UseToursParams {
  page?: number;
  search?: string;
  active?: boolean;
  authors?:string[];
  limit?:number;
  publishDate?:string;
  initialTour?:boolean;

}

function useTours(params: UseToursParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchTours = async (
    params: UseToursParams
  ): Promise<{ data: TourDetails[]; total: number }> => {
    const { data, headers } = await axios.get<TourDetails[]>(`/v2/tour/`, {
      params: {
        page: params.page,
        search: debouncedQuery,
        active: params.active,
        authors:params.authors,
        limit:params.limit,
        initialTour:params.initialTour,
      },
    });

    return { data: data, total: Number(headers['total']) };
  };

  return useQuery(
    [
      `/v2/tour`,
      { ...params, search: debouncedQuery },
    ],
    () => fetchTours(params),
    {
      keepPreviousData: true,
    }
  );
}

export default useTours;
