import { Image } from 'antd';
import { primary } from 'core/resources/theme/colors';
import { getFirstLetter } from 'infra/helpers/getInicialsName';

function GamePicture({
  url,
  name,
  size = 'lg',
}: {
  url?: string;
  name: string;
  size?: 'lg' | 'xl';
}) {
  return (
    <>
      {url ? (
        <Image
          src={url}
          width={size === 'lg'? 120 : 160}
          height={size === 'lg'? 68 : 90}
          style={{ borderRadius: '15px' }}
          preview={false}
        />
      ) : (
        <div
          style={{
            width: size === 'lg'? '120px' : '160px',
            height: size === 'lg'? '68px' : '90px' ,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#D9D9D9',
            borderRadius: '15px',
            color: primary,
          }}
        >
          <span style={{ fontSize: '28px', fontWeight: 700 }}>
            {getFirstLetter(name)}
          </span>
        </div>
      )}
    </>
  );
}

export default GamePicture;
