import { useMutation } from "react-query";
import http from "infra/http";

export function useToggleMessage() {
  return useMutation(
    (  ids: string[] | undefined) => {
      return http.put(
        `/v2/safeSpace/`,
        {
          ids: ids,
        }
      );
    },
    { retry: false }
  );
}
