import { useQuery } from "react-query";
import axios from "infra/http";
import { TourDetails, TourHistoryDetails } from "../typings";

function useTourHistory(id?: string) {
  const fetchData = async (id: string): Promise<TourHistoryDetails[]> => {
    const { data } = await axios.get<TourHistoryDetails[]>(`/v2/tour/history/${id}`);

    return data;
  };

  return useQuery([`/v2/tour/history/${id}`], () => fetchData(id!), {
    enabled: !!id,
  });
}

export default useTourHistory;
