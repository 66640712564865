import { useQuery } from 'react-query';
import axios from 'infra/http';
import { ActivityDetails } from '../types';
import { useEffect, useState } from 'react';

export interface UseActivitiesParams {
  page: number;
  typeId?: number;
  search?: string;
  responsible?: string;
  gameId?: string;
  classId?: string;
  disableConcat?: boolean;
  publishDate?: string;
  endDate?: string;
  tags?: string[];
  studentId?: string;
  limit?: number;
}

function useActivities(params: UseActivitiesParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchActivities = async (
    params: UseActivitiesParams
  ): Promise<{ data: ActivityDetails[]; total: number }> => {
    const { data, headers } = await axios.get<ActivityDetails[]>(
      `/v2/activities`,
      {
        params: {
          ...params,
          search: debouncedQuery,
          
        },
      }
    );
    return { data: data, total: Number(headers['total']) };
  };

  return useQuery(
    [`/v2/activities`, { ...params, search: debouncedQuery }],
    () => fetchActivities(params)
  );
}

export default useActivities;
