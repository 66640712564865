/// <reference path="./leaflet-heatmap.d.ts" />
import { useEffect, useRef } from 'react';
import L, { LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import HeatmapOverlay from 'heatmap.js/plugins/leaflet-heatmap';

function HeatMap({
  coordinates = [-15.7835, -47.8668],
  data,
  placeType='country'
}: {
  data: any[];
  coordinates?: LatLngExpression;
  placeType?:string;
}) {
  const mapRef = useRef<L.Map | null>(null);

  const types:{ [key: string]: number } = {
    street_address:18,
    route:18,
    school:18,
    neighborhood:16,
    sublocality:16,
    sublocality_level_1:16,
    locality:14,
    administrative_area_level_1:7,
    administrative_area_level_2:5,
    colloquial_area:5,
    country:4,

  }
  useEffect(() => {
    if (!mapRef.current) {
      // Create a new map only if it doesn't exist
      const map = L.map('map');
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {}).addTo(map);
  
      mapRef.current = map; // Store the map reference
    }
  
    // Access the map using mapRef.current
    const map = mapRef.current as L.Map;
  


    map.setView(coordinates, types[placeType]);
    
  
    // Clear previous layers before adding a new one
    map.eachLayer((layer) => {
      if (layer instanceof HeatmapOverlay) {
        layer.remove();
      }
    });
  
    // Create a heatmap layer using heatmap.js
    const heatmapLayer = new HeatmapOverlay({
      radius: 20,
      minOpacity: 0.5,
      blur: 0.75,
      // gradient: { 0.4: 'blue', 0.1: 'lime', 0.6: 'red' },
    });
    heatmapLayer.setData({ max: 12, data: data }); // Adjust the max value as needed
  
    heatmapLayer.addTo(map);
  }, [data, coordinates,placeType]);
  

  return (
    <div className="HeatMap">
      <div id="map" style={{ height: '324px' }}></div>
    </div>
  );
}

export default HeatMap;
