import axios from 'axios';
import moment from 'moment';
import { useQuery } from 'react-query';
import { baseUrlQedu, InfraestruturaEscolar, QeduParams } from '../types';

export function useInfraestruturaEscolar(params: QeduParams) {
  const fetchData = async (
    params: QeduParams
  ): Promise<InfraestruturaEscolar | undefined> => {
    let year = moment().year();
    let data: InfraestruturaEscolar | undefined = undefined;

    while (year >= 2000) {
      const response = await axios
        .get<{ data: InfraestruturaEscolar[] }>(`${baseUrlQedu}/censo/escola`, {
          params: {
            inep_id: params.inep_id,
            ano: year,
          },
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_QEDU_API_KEY}`,
          },
        })
        .catch(() => {});

      if (response?.data?.data.length) {
        data = response.data.data[0];
        break;
      }

      year--; 
    }

    return data;
  };

  return useQuery([`infraestrutura-escolar`, params], () => fetchData(params), {
    enabled: !!params?.inep_id,
  });
}
