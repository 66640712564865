import { useQuery } from "react-query";
import axios from "infra/http";

function useTag(id?: string) {
  const fetchActivity = async (id: string): Promise<any> => {
    const { data } = await axios.get<any>(`/v1/tag/${id}`);

    return data;
  };

  return useQuery([`/v1/tag/${id}`, id], () => fetchActivity(id!), {
    enabled: !!id,
  });
}

export default useTag;
