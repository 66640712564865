import Layout from 'components/layout';
import { useMediaQuery } from '@chakra-ui/react';
import history from 'infra/helpers/history';
import { useEffect, useState } from 'react';
import { Flex, Form } from 'antd';
import BackButton from 'components/buttons/back-button';
import Title from 'components/typography/title';
import {
  notificateError,
  notificateSucess,
  openNotification,
  showConfirm,
} from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import CustomizedRequiredMark from 'components/required-mark';
import Button from 'components/buttons/button';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import useGuideInitialTour from 'core/features/guides/hooks/useGuideInitialTour';
import { GuideForm } from 'core/features/guides/typings';
import { useSaveGuide } from 'core/features/guides/hooks/useSaveGuide';
import useTours from 'core/features/tour/hooks/useTours';
import Text from 'components/typography/text';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import { FiMove } from 'react-icons/fi';

function InitialTourManager() {
  const { data, isLoading } = useGuideInitialTour();
  const { data: dataTours, isLoading: isLoadingTours } = useTours({
    page: 1,
    limit: 200,
    initialTour: true,
  });
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveGuide();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary, colorGreyBorderAndFont, colorGreyCard } =
    useU4heroColors();
  const [form] = Form.useForm();
  const tours = Form.useWatch('tours', form);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedTours = Array.from(tours);
    const [removed] = reorderedTours.splice(result.source.index, 1);
    reorderedTours.splice(result.destination.index, 0, removed);

    form.setFieldsValue({ tours: reorderedTours });
  };

  const onSubmit = (values: GuideForm) => {
    handleSave(
      {
        ...values,
        id: data?.id,
      },
      {
        onError: () => {
          notificateError(
            `Houve um problema ao salvar a ordenação do “Tour Inicial”. Verifique sua conexão e tente novamente.`
          );
        },
        onSuccess: () => {
          notificateSucess(
            `Ordenação do “Tour Inicial” concluída com sucesso!`
          );
          setTimeout(() => {
            history.goBack();
          }, 200);
        },
      }
    );
  };

  useEffect(() => {
    form.resetFields();
  }, [data, dataTours, form]);

  return (
    <Layout.Container>
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        requiredMark={CustomizedRequiredMark}
        validateTrigger="submit"
        initialValues={
          data
            ? {
                tours: [
                  // ...data.tours, 
                  ...(dataTours?.data
                    // ?.filter(
                    //   (tour) =>
                    //     !data?.tours?.find((item) => item.id === tour.id)
                    // )
                    .map((tour) => ({
                      id: tour.id,
                      name: tour.name,
                      backgroundImage:tour.backgroundImage
                    })) ?? []), 
                ],
                name:data.name
              }
            : {
                tours:
                  dataTours?.data?.map((item) => ({
                    id: item.id,
                    name: item.name,
                  })) ?? [],
              }
        }
      >
        <Flex
          gap={isMobile ? '12px' : '24px'}
          align="center"
          style={{ marginBottom: '12px' }}
        >
          <BackButton
            onClick={() => {
              if (form.isFieldsTouched()) {
                openNotification({
                  colorPrimary,
                  isMobile,
                  onClose: () => history.goBack(),
                });
              } else {
                history.goBack();
              }
            }}
          />
          <Title primary>Gerenciar Tour Inicial</Title>
        </Flex>
        <Flex vertical gap={'24px'}>
          <Flex gap="16px" justify="end" align="center">
            <Button
              size="md"
              id="id-tour-initial-tour-manager-save-button"
              htmlType="submit"
              loading={isLoadingSave}
            >
              SALVAR
            </Button>
          </Flex>

          <Form.List name="tours">
            {(fields, { add, remove }) => (
              <Flex vertical gap="16px">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="tours">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          display: 'flex',
                          gap: '16px',
                          flexDirection: 'column',
                        }}
                      >
                        {fields.map((field, index) => (
                          <Draggable
                            key={field.key}
                            draggableId={field.key.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Flex
                                  style={{
                                    borderRadius: '10px',
                                    background: '#F5F5F5',
                                    boxShadow:
                                      '0px 3px 4px 0px rgba(0, 0, 0, 0.10)',
                                  }}
                                  align='center'
                                  justify='space-between'
                                >
                                  <Flex gap='46px' align='center'>
                                  <Flex
                                  justify='center'
                                  align='center'
                                    style={{
                                      background: hex2rgba(colorPrimary, 0.5),
                                      width:'47px',
                                      height:'44px',
                                      borderRadius:'10px 10px 10px 0px'
                                    }}
                                  >
                                    <Text
                                      size="lg"
                                      color="white"
                                      style={{ fontWeight: 600 }}
                                    >
                                      {index + 1}
                                    </Text>
                                  </Flex>
                                  <Text size="lg">{tours?.[index]?.name}</Text>
                                  </Flex>
                                  <FiMove size={isMobile ? 20 : 24} style={{marginRight:'30px'}} />
                                </Flex>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Flex>
            )}
          </Form.List>
        </Flex>
      </Form>
    </Layout.Container>
  );
}

export default InitialTourManager;
