import { Flex } from 'antd';
import Button from 'components/buttons/button';
import { useHistory } from 'react-router';
import InstitutionsTable from '../../components/InstitutionsTable';

function SchoolsTab() {
  const history = useHistory();
  return (
    <>
      {' '}
      <Flex style={{ marginTop: '16px', marginBottom: '17px' }} justify="end">
        <Button
          id="id-tour-instituitions-button-add-instituition"
          size="md"
          onClick={() => history.push('/schools/create')}
        >
          CRIAR ESCOLA
        </Button>
      </Flex>
      <InstitutionsTable
        organization={false}
        onEdit={(tenant) => history.push(`schools/edit/${tenant.id}`)}
      />
    </>
  );
}

export default SchoolsTab;
