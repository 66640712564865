import { Flex, Typography } from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { Pie } from 'react-chartjs-2';
import Dot from 'components/dot';
import { t } from 'core/resources/strings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';

const { Text } = Typography;
const pieColors: Record<string, string> = {
  serious: '#B94386',
  moderate: '#B97643',
  light: '#86B943',
  no_manifest: '#7743B9',
};
const symptomsColors =['#B94386','#B97643','#86B943','#7743B9']

function GraphCard({ title, data }: { title: string; data: any }) {
  const {colorPrimary} = useU4heroColors();
  return (
    <GenericCard>
      <Flex vertical gap="26px" style={{ width: '100%' }}>
        <Text style={{ color: colorPrimary, fontSize: '20px', fontWeight: 600 }}>
          {title}
        </Text>
        <Flex align="center" justify="space-around">
          <Flex style={{ maxWidth: '174px' }}>
            <Pie
              data={{
                datasets: [
                  {
                    data: Object.values(data ?? {}).map((item:any) => item.percent),
                    backgroundColor: Object.values(pieColors),
                    borderWidth: 0,
                  },
                ],
              }}
            />
          </Flex>
          <Flex vertical gap="12px">
            {Object.keys(data ?? {})?.map((item,index) => (
              <Flex gap="12px" align="center">
                <Dot size={12} color={pieColors[item] !== undefined ? pieColors[item]:symptomsColors[index]} />
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: '16px',
                    fontWeight: 400,
                  }}
                >
                  {t(`app.${item}`).includes('app') ? item : t(`app.${item}`)}
                </Text>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: pieColors[item] !== undefined ? pieColors[item]:symptomsColors[index],
                  }}
                >
                  {data && data[item].percent}%
                  {' ('}
                  {data && data[item].cases}
                  {')'}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </GenericCard>
  );
}

export default GraphCard;
