import Layout from 'components/layout';
import { useMediaQuery } from '@chakra-ui/react';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import { Col, Flex, Pagination, Row, Spin } from 'antd';
import AppliedFilters from 'components/applied-filters';
import { FilterType } from 'infra/helpers/types';
import { useState } from 'react';
import SearchBar from 'components/V2/SearchBar';
import Button from 'components/buttons/button';
import lumiInCar from 'assets/images/lumi-in-car.png';
import lumiSearching from 'assets/images/lumi-searching.png';
import ModalStoreItem from 'components/modals/modal-store-item';
import useStoreItems from 'core/features/store-items/hooks/useStoreItems';
import ItemCard from './components/ItemCard';
import StoreItemFilter from './components/StoreItemFilter';
import SubTitle from 'components/typography/subTitle';

function ManagerStore() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [search, setSearch] = useState('');
  const [id, setId] = useState<string>();

  const [filters, setFilters] = useState<FilterType>({
    status: [],
    types: [],
    classification: [],
  });

  const { data, isLoading } = useStoreItems({
    page,
    limit: pageSize,
    search,
    types: filters.types.map((item) => item.value) ?? undefined,
    active: filters.status?.length
      ? filters.status.map((item) => item.value)[0] === 1
      : undefined,
    classification: filters.classification?.length
      ? filters.classification.map((item) => item.value)[0]
      : undefined,
  });

  return (
    <Layout.Container>
      <Title
        primary
        style={{
          marginBottom: isMobile ? '16px' : '24px',
        }}
      >
        Gerenciador de Itens
      </Title>
      {isOpen ? (
        <ModalStoreItem
          onClose={() => {
            setIsOpen(false);
            setId(undefined);
          }}
          id={id}
        />
      ) : null}
      <Flex
        vertical
        gap="8px"
        style={{ marginBottom: isMobile ? '20px' : '40px' }}
      >
        <Flex vertical gap={16}>
          <Button
            size="md"
            style={{ alignSelf: 'flex-end' }}
            onClick={() => setIsOpen(true)}
          >
            Criar item
          </Button>
          <Flex gap="16px" align="center">
            <SearchBar
              onSearch={setSearch}
              width="100%"
              placeholder="Pesquisar item"
            />
            <StoreItemFilter filters={filters} onChangeFilters={setFilters} />
          </Flex>
        </Flex>

        <AppliedFilters appliedFilters={filters} onChangeFilters={setFilters} />
      </Flex>

      {isLoading ? <Spin size={'large'} /> : null}

      {data && data.data.length > 0 ? (
        <>
          <Row gutter={[36, isMobile ? 24 : 32]} align='stretch'>
            {data?.data.map((item) => (
              <Col key={item.id} span={isMobile ? 24 : 6}>
                <ItemCard
                  item={item}
                  onEdit={(v) => {
                    setId(v);
                    setIsOpen(true);
                  }}
                />
              </Col>
            ))}
          </Row>
          <Flex
            justify="center"
            style={{
              marginTop: isMobile ? '18px' : '26px',
              marginBottom: isMobile ? '10px' : '18px',
            }}
          >
            <Pagination
              showSizeChanger
              defaultCurrent={1}
              current={page}
              pageSize={pageSize}
              onChange={(v) => setPage(v)}
              onShowSizeChange={(v, size) => setPageSize(size)}
              total={data?.total}
            />
          </Flex>
        </>
      ) : null}
      {data && data.data.length === 0 && !search ? (
        <Flex vertical align="center" justify="center" gap={8}>
          <img src={lumiInCar} alt="loja do lumi" />
          <Title>Inventário Vazio</Title>
          <Text align="justify" size="lg">
            Atualmente, não há itens disponíveis para exibição. Comece agora a
            criar seus primeiros itens!
          </Text>
          <Button size="md" onClick={() => setIsOpen(true)}>
            Criar
          </Button>
        </Flex>
      ) : null}
      {data?.data.length === 0 && search ? (
        <Flex vertical gap={8} justify="center" align="center">
          <img src={lumiSearching} width={198} alt={'nenhum item encontrado'} />
          <SubTitle>Item não encontrado</SubTitle>
          <Text size="lg" align='center'>
            Ops, não há itens com esse nome. Por favor, verifique e tente
            novamente!
          </Text>
        </Flex>
      ) : null}
    </Layout.Container>
  );
}

export default ManagerStore;
