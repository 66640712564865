import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex } from 'antd';
import { StudentItem } from 'core/features/students/typings';
import { t } from 'core/resources/strings';
import StudentsTable from 'ui/pages/app/mySchool/pages/Students/components/StudentsTable';

function StepStudents({
  value,
  onChange,
  setMinAge,
  setMaxAge,
  setCountStudents,
}: {
  value: string[];
  onChange: (v: string[]) => void;
  setMinAge: React.Dispatch<React.SetStateAction<number | undefined>>;
  setMaxAge: React.Dispatch<React.SetStateAction<number | undefined>>;
  setCountStudents: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const handleAge = (rows: StudentItem[]) => {
    const hasSeries = rows.every((student) => student.serie);
    if (!hasSeries) {
      setMinAge(undefined);
      setMaxAge(undefined);
      return;
    }
    setMinAge(
      rows.reduce((minAge: number, currentItem) => {
        if (minAge === null || currentItem.serie.minAge < minAge) {
          return currentItem.serie.minAge;
        }
        return minAge;
      }, Infinity)
    );
    setMaxAge(
      rows.reduce((maxAge: number, currentItem) => {
        if (maxAge === null || currentItem.serie.maxAge > maxAge) {
          return currentItem.serie.maxAge;
        }
        return maxAge;
      }, -Infinity)
    );
  };
  return (
    <Flex vertical gap="8px">
      <p
        style={{
          fontSize: isMobile ? '14px' : '18px',
          fontWeight: 400,
          color: 'black',
        }}
      >
        {t('app.selecioneOsEstudantesQueATividadeSeraAplicada')}
      </p>
      <StudentsTable
        value={value}
        onSelect={(rowKeys: string[]) => {
          onChange(rowKeys);
        }}
        callback={(rows) => {
          handleAge(rows);
          setCountStudents(rows.length);
        }}
        hideColumns
      />
    </Flex>
  );
}

export default StepStudents;
