import { Select } from 'antd';
import { t } from 'core/resources/strings';
import { useEffect } from 'react';

const types = [
  {
    label: t('app.bomComportamento'),
    value: 0,
    behaviors: [
      {
        label: t('app.bomComportamento1'),
        relatedSkillId: '0df033fd-04c6-4a92-b937-74619252b04c',
        skill: 'Cooperação',
      },
      {
        label: t('app.bomComportamento2'),
        relatedSkillId: '6265a000-96d6-4a1b-95fd-90c0839cf0e1',
        skill: 'Protagonismo e autoria na vida pessoal e coletiva',
      },
      {
        label: t('app.bomComportamento3'),
        relatedSkillId: '5857a396-0925-49c8-b291-da1b3eb0411e',
        skill: 'Consciência socioambiental',
      },
      {
        label: t('app.bomComportamento4'),
        relatedSkillId: '0df033fd-04c6-4a92-b937-74619252b04c',
        skill: 'Cooperação',
      },
      {
        label: t('app.bomComportamento5'),
        relatedSkillId: '0df033fd-04c6-4a92-b937-74619252b04c',
        skill: 'Cooperação',
      },
      {
        label: t('app.bomComportamento6'),
        relatedSkillId: '94174595-e5b5-4bf3-9cfe-707ddb6e68f0',
        skill: 'Determinação',
      },
      {
        label: t('app.bomComportamento7'),
        relatedSkillId: 'a00a5146-8399-4827-8959-4a7c43060c41',
        skill: 'Persistência',
      },
      {
        label: t('app.bomComportamento8'),
        relatedSkillId: 'c50a58ef-ddfa-4693-9db9-3a3ae94ff93b',
        skill: 'Diálogo',
      },
      {
        label: t('app.bomComportamento9'),
        relatedSkillId: '50db7ec8-b6ec-48f3-9b4b-c745632dd03a',
        skill: 'Empatia',
      },
      {
        label: t('app.bomComportamento10'),
        relatedSkillId: '50db7ec8-b6ec-48f3-9b4b-c745632dd03a',
        skill: 'Empatia',
      },
      {
        label: t('app.bomComportamento11'),
        relatedSkillId: '49863672-5727-48fe-8366-cd45479c71ee',
        skill: 'Diversidade de saberes e vivências culturais',
      },
      {
        label: t('app.bomComportamento12'),
        relatedSkillId: '84dbf87f-51e1-492c-a7cf-d82236a7295f',
        skill: 'Autonomia',
      },
      {
        label: t('app.bomComportamento13'),
        relatedSkillId: '1b52507a-bdb7-4b31-8dca-b88d49d949f4',
        skill: 'Formular e resolver problemas',
      },
      {
        label: t('app.bomComportamento14'),
        relatedSkillId: '49863672-5727-48fe-8366-cd45479c71ee',
        skill: 'Diversidade de saberes e vivências culturais',
      },
      {
        label: t('app.bomComportamento15'),
        relatedSkillId: 'fb14afa9-ed10-4dd3-af01-1ca50db685ca',
        skill: 'Grupos sociais',
      },
      {
        label: t('app.bomComportamento16'),
        relatedSkillId: '5857a396-0925-49c8-b291-da1b3eb0411e',
        skill: 'Argumentação',
      },
      {
        label: t('app.bomComportamento17'),
        relatedSkillId: '41e23d16-6720-4b79-899c-a48e2eb87568',
        skill: 'Cidadania',
      },
      {
        label: t('app.bomComportamento18'),
        relatedSkillId: '41e23d16-6720-4b79-899c-a48e2eb87568',
        skill: 'Cidadania',
      },
      {
        label: t('app.bomComportamento19'),
        relatedSkillId: 'fb14afa9-ed10-4dd3-af01-1ca50db685ca',
        skill: 'Grupos sociais',
      },
      {
        label: t('app.bomComportamento20'),
        relatedSkillId: '49863672-5727-48fe-8366-cd45479c71ee',
        skill: 'Diversidade de saberes e vivências culturais',
      },
      { label: t('app.outroEspecifique'), relatedSkillId: '' },
    ],
  },
  {
    label: t('app.mauComportamento'),
    value: 1,
    behaviors: [
      {
        label: t('app.mauComportamento1'),
        relatedSkillId: '6580501f-71f6-48f6-9660-bd4718cf6e75',
        skill: 'Influenciador',
      },
      {
        label: t('app.mauComportamento2'),
        relatedSkillId: 'fb14afa9-ed10-4dd3-af01-1ca50db685ca',
        skill: 'Grupos sociais',
      },
      {
        label: t('app.mauComportamento3'),
        relatedSkillId: '9c15a847-fda2-4572-99a9-f65803c66dd5',
        skill: 'Trabalho e projeto de vida',
      },
      {
        label: t('app.mauComportamento4'),
        relatedSkillId: '1b52507a-bdb7-4b31-8dca-b88d49d949f4',
        skill: 'Formular e resolver problemas',
      },
      {
        label: t('app.mauComportamento5'),
        relatedSkillId: 'a00a5146-8399-4827-8959-4a7c43060c41',
        skill: 'Persistência',
      },
      {
        label: t('app.mauComportamento6'),
        relatedSkillId: '944a7ddb-9a35-4f78-9800-c40d360c9b1f',
        skill: 'Posicionamento ético',
      },
      {
        label: t('app.mauComportamento7'),
        relatedSkillId: '50db7ec8-b6ec-48f3-9b4b-c745632dd03a',
        skill: 'Empatia',
      },
      {
        label: t('app.mauComportamento8'),
        relatedSkillId: '1b52507a-bdb7-4b31-8dca-b88d49d949f4',
        skill: 'Formular e resolver problemas',
      },
      {
        label: t('app.mauComportamento9'),
        relatedSkillId: 'bc18cb8a-cda5-4135-b886-a1dc777be2be',
        skill: 'Autoconfiança',
      },
      {
        label: t('app.mauComportamento10'),
        relatedSkillId: 'cc34d823-a919-425c-bab6-0bdb49f88df7',
        skill: 'Autocrítica',
      },
      {
        label: t('app.mauComportamento11'),
        relatedSkillId: '94174595-e5b5-4bf3-9cfe-707ddb6e68f0',
        skill: 'Determinação',
      },
      {
        label: t('app.mauComportamento12'),
        relatedSkillId: 'c50a58ef-ddfa-4693-9db9-3a3ae94ff93b',
        skill: 'Diálogo',
      },
      {
        label: t('app.mauComportamento13'),
        relatedSkillId: '0df033fd-04c6-4a92-b937-74619252b04c',
        skill: 'Cooperação',
      },
      {
        label: t('app.mauComportamento14'),
        relatedSkillId: '944a7ddb-9a35-4f78-9800-c40d360c9b1f',
        skill: 'Posicionamento ético',
      },
      {
        label: t('app.mauComportamento15'),
        relatedSkillId: '944a7ddb-9a35-4f78-9800-c40d360c9b1f',
        skill: 'Posicionamento ético',
      },
      {
        label: t('app.mauComportamento16'),
        relatedSkillId: 'bc18cb8a-cda5-4135-b886-a1dc777be2be',
        skill: 'Autoconfiança',
      },
      {
        label: t('app.mauComportamento17'),
        relatedSkillId: 'c50a58ef-ddfa-4693-9db9-3a3ae94ff93b',
        skill: 'Diálogo',
      },
      { label: t('app.mauComportamento18'), relatedSkillId: '' },
      {
        label: t('app.mauComportamento19'),
        relatedSkillId: '944a7ddb-9a35-4f78-9800-c40d360c9b1f',
        skill: 'Posicionamento ético',
      },
      {
        label: t('app.mauComportamento20'),
        relatedSkillId: '944a7ddb-9a35-4f78-9800-c40d360c9b1f',
        skill: 'Posicionamento ético',
      },
      { label: t('app.outroEspecifique'), relatedSkillId: '' },
    ],
  },
  {
    label: t('app.bomFicarDeOlho'),
    value: 2,
    behaviors: [
      {
        label: t('app.bomFicarDeOlho1'),
        relatedSkillId: 'bc18cb8a-cda5-4135-b886-a1dc777be2be',
        skill: 'Autoconfiança',
      },
      {
        label: t('app.bomFicarDeOlho2'),
        relatedSkillId: '638e1372-64a6-4cdc-af5b-c3f30c587fd6',
        skill: 'Saúde física',
      },
      {
        label: t('app.bomFicarDeOlho3'),
        relatedSkillId: 'fb14afa9-ed10-4dd3-af01-1ca50db685ca',
        skill: 'Grupos sociais',
      },
      {
        label: t('app.bomFicarDeOlho4'),
        relatedSkillId: 'd2b61649-64e2-4ecf-850a-2f8e45102432',
        skill: 'Vítima',
      },
      {
        label: t('app.bomFicarDeOlho5'),
        relatedSkillId: '75492ded-b768-4a3e-b4b8-c35e4499b874',
        skill: 'Preconceito',
      },
      {
        label: t('app.bomFicarDeOlho6'),
        relatedSkillId: 'cf794d78-5959-4f2c-926c-532fc502b4a2',
        skill: 'Reconhecer suas emoções',
      },
      {
        label: t('app.bomFicarDeOlho7'),
        relatedSkillId: 'cf794d78-5959-4f2c-926c-532fc502b4a2',
        skill: 'Reconhecer suas emoções',
      },
      {
        label: t('app.bomFicarDeOlho8'),
        relatedSkillId: 'bda3296a-6f91-4011-9abd-7e8dc659fdab',
        skill: 'Flexibilidade',
      },
      { label: t('app.outroEspecifique'), relatedSkillId: '' },
    ],
  },
];

interface BehaviorValue {
  value: string;
  relatedSkillId: string;
}

function SelectBehaviors({
  value,
  onChange,
  type,
}: {
  type?: number;
  value: BehaviorValue[] | undefined;
  onChange: (v: BehaviorValue[] | undefined) => void;
}) {
  const behaviors = types.find((item) => item.value === type)?.behaviors;

  return (
    <Select
      maxTagCount="responsive"
      style={{ width: '90%', height: '32px' }}
      disabled={type === undefined}
      mode="multiple"
      onChange={(v) => {
        const tags = behaviors
          ?.map((item) => {
            if (v.includes(item.label)) {
              return {
                value: item.label,
                relatedSkillId: item.relatedSkillId,
              };
            }
          })
          .filter((item) => item !== undefined);
        onChange(tags as BehaviorValue[]);
      }}
      value={value?.map((item) => item.value)}
      placeholder={t('app.selecioneOsComportamentos')}
      options={behaviors?.map((item) => ({
        label: item.label,
        value: item.label,
      }))}
    />
  );
}

export default SelectBehaviors;
