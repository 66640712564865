import useProcessing from 'core/features/inkluzen/hooks/useProcessing';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import DynamicTable from 'components/V2/dinamic-table';
import { useState } from 'react';

function ProcessTable({ params }: { params: any }) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const { data: processing, isLoading } = useProcessing({
    page,
    ...params,
    limit:pageSize
  });
  return (
    <DynamicTable
      itemName="processamento"
      data={processing?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title="Processamentos"
      total={processing?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isLoading}
      defaultColumns={[
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>ID</p>,
          dataIndex: 'id',
          key: 'id',
          render: (id: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{id}</p>
          ),
        },
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>Estudante</p>,
          dataIndex: 'student',
          key: 'student',
          render: (student: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{student}</p>
          ),
          sorter: (a: any, b: any) => a.student.localeCompare(b.student),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Escore Significado
            </p>
          ),
          dataIndex: 'meaningScore',
          key: 'meaningScore',
          render: (meaningScore: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{meaningScore}</p>
          ),
          sorter: (a: any, b: any) => b.meaningScore - a.meaningScore,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Grau de Atenção Significado
            </p>
          ),
          dataIndex: 'degreeAtentionMeaning',
          key: 'degreeAtentionMeaning',
          sorter: (a: any, b: any) =>
            a.degreeAtentionMeaning.localeCompare(b.degreeAtentionMeaning),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
          render: (degreeAtentionMeaning: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {degreeAtentionMeaning}
            </p>
          ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>Escore Contexto</p>
          ),
          dataIndex: 'contextScore',
          key: 'contextScore',
          render: (contextScore: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{contextScore}</p>
          ),
          sorter: (a: any, b: any) => b.contextScore - a.contextScore,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Grau de Atenção Contexto
            </p>
          ),
          dataIndex: 'degreeAtentionContext',
          key: 'degreeAtentionContext',
          render: (degreeAtentionContext: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {degreeAtentionContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            a.degreeAtentionContext.localeCompare(b.degreeAtentionContext),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
      ]}
      allColumns={[
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>ID</p>,
          name: 'ID',
          dataIndex: 'id',
          key: 'id',
          render: (id: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{id}</p>
          ),
        },
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>Estudante</p>,
          name: 'Estudante',
          dataIndex: 'student',
          key: 'student',
          render: (student: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{student}</p>
          ),
          sorter: (a: any, b: any) => a.student.localeCompare(b.student),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Escore Significado
            </p>
          ),
          name: 'Escore Significado',
          dataIndex: 'meaningScore',
          key: 'meaningScore',
          render: (meaningScore: number, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{meaningScore}</p>
          ),
          sorter: (a: any, b: any) => b.meaningScore - a.meaningScore,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Grau de Atenção Significado
            </p>
          ),
          name: 'Grau de Atenção Significado',
          dataIndex: 'degreeAtentionMeaning',
          key: 'degreeAtentionMeaning',
          sorter: (a: any, b: any) =>
            a.degreeAtentionMeaning.localeCompare(b.degreeAtentionMeaning),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
          render: (degreeAtentionMeaning: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {degreeAtentionMeaning}
            </p>
          ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>Escore Contexto</p>
          ),
          name: 'Escore Contexto',
          dataIndex: 'contextScore',
          key: 'contextScore',
          render: (contextScore: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{contextScore}</p>
          ),
          sorter: (a: any, b: any) => b.contextScore - a.contextScore,
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Grau de Atenção Contexto
            </p>
          ),
          name: 'Grau de Atenção Contexto',
          dataIndex: 'degreeAtentionContext',
          key: 'degreeAtentionContext',
          render: (degreeAtentionContext: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>
              {degreeAtentionContext}
            </p>
          ),
          sorter: (a: any, b: any) =>
            a.degreeAtentionContext.localeCompare(b.degreeAtentionContext),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>Alerta</p>,
          name: 'Alerta',
          dataIndex: 'alert',
          key: 'alert',
          render: (alert: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{alert}</p>
          ),
          sorter: (a: any, b: any) => a.alert.localeCompare(b.alert),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>Bairro</p>,
          name: 'Bairro',
          dataIndex: 'neighborhood',
          key: 'neighborhood',
          render: (neighborhood: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{neighborhood}</p>
          ),
          sorter: (a: any, b: any) =>
            a.neighborhood.localeCompare(b.neighborhood),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>Escola</p>,
          name: 'Escola',
          dataIndex: 'school',
          key: 'school',
          render: (school: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{school}</p>
          ),
          sorter: (a: any, b: any) => a.school.localeCompare(b.school),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>Estado</p>,
          name: 'Estado',
          dataIndex: 'state',
          key: 'state',
          render: (state: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{state}</p>
          ),
          sorter: (a: any, b: any) => a.state.localeCompare(b.state),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: (
            <p style={{ fontSize: '16px', fontWeight: 500 }}>
              Fonte de Captação
            </p>
          ),
          name: 'Fonte de Captação',
          dataIndex: 'captureSource',
          key: 'captureSource',
          render: (captureSource: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{captureSource}</p>
          ),
          sorter: (a: any, b: any) =>
            a.captureSource.localeCompare(b.captureSource),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>Cidade</p>,
          name: 'Cidade',
          dataIndex: 'city',
          key: 'city',
          render: (city: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{city}</p>
          ),
          sorter: (a: any, b: any) => a.city.localeCompare(b.city),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>Região</p>,
          name: 'Região',
          dataIndex: 'region',
          key: 'region',
          render: (region: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{region}</p>
          ),
          sorter: (a: any, b: any) => a.region.localeCompare(b.region),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>Sexo</p>,
          name: 'Sexo',
          dataIndex: 'sexo',
          key: 'sexo',
          render: (sexo: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{sexo}</p>
          ),
          sorter: (a: any, b: any) => a.sexo.localeCompare(b.sexo),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
        {
          title: <p style={{ fontSize: '16px', fontWeight: 500 }}>Turma</p>,
          name: 'Turma',
          dataIndex: 'classe',
          key: 'classe',
          render: (classe: string, record: any) => (
            <p style={{ fontSize: '16px', fontWeight: 400 }}>{classe}</p>
          ),
          sorter: (a: any, b: any) => a.classe.localeCompare(b.classe),
          sortIcon: (sortOrder: any) =>
            sortOrder.sortOrder === 'descend' ? (
              <MdExpandLess size={25} />
            ) : (
              <MdExpandMore size={25} />
            ),
        },
      ]}
    />
  );
}

export default ProcessTable;
