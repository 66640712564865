import http from "infra/http";
import { useMutation } from "react-query";
export interface QuestionFeedbackForm {
    questionId:string | null;
    feedback: string;
    rating: number;
   
  }


export function useSaveQuestionFeedback() {
  return useMutation(
    (data: QuestionFeedbackForm) => {
      return http.post(`/v2/questions/feedback`, data);
    },
    { retry: false }
  );
}
