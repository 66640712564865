import { useQuery } from 'react-query'
import axios from 'infra/http'

export interface UseHelpersParams {
  page?: number
  ratings?: string[]
  initialDate?:string
  id?:string
  pageSize?:number
  search?:string
//   search?: string
}

export interface IFeedback {
  id: string;
  questionId: string;
  tenantId: string;
  userId: string;
  feedback: string;
  rating: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  question: {
      text: string;
  };
}


function useQuestionFeedbacks(params: UseHelpersParams) {
  const fetchFeedbacks = async (
    params: UseHelpersParams
  ): Promise<{ data: IFeedback[]; total: number }> => {
    const { data, headers } = await axios.get<IFeedback[]>(`/v2/questions/feedback`, {
      params: {
        page: params.page,
        ratings:params.ratings,
        initialDate: params.initialDate,
        id: params.id,
        limit: params.pageSize,
        search: params.search
      },
    })

    return { data: data, total: Number(headers['total']) }
  }

  return useQuery(
    [`/v2/questions/feedback`, params],
    () => fetchFeedbacks(params),
    {
      keepPreviousData: true,
    }
  )
}

export default useQuestionFeedbacks
