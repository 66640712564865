import { useQuery } from 'react-query';
import axios from 'infra/http';
import { ComparativeAttentionPointsDetails } from '../typings';

export interface UseAttentionPointsSchoolParams {
  startDate?: string;
  endDate?: string;
}

function useAttentionPointsComparative(params: UseAttentionPointsSchoolParams) {
  const fetchData = async (
    params: UseAttentionPointsSchoolParams
  ): Promise<{ data: ComparativeAttentionPointsDetails }> => {
    const { data } = await axios.get<ComparativeAttentionPointsDetails>(
      `/v2/indicators/attention-comparative`,
      {
        params: {
          startDate: params.startDate,
          endDate: params.endDate,
        },
      }
    );

    return { data: data };
  };

  return useQuery(
    [
      `/v2/indicators/attention-comparative?startDate=${params.startDate}&endDate=${params.endDate}`,
      params,
    ],
    () => fetchData(params),
    {
      enabled: params.startDate !== undefined && params.endDate !== undefined
    }
  );
}

export default useAttentionPointsComparative;
