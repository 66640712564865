import { useQuery } from 'react-query';
import axios from 'infra/http';
import {
  ComparativePeriod,
  GraphCategory,
  GraphSource,
  GraphView,
  TimePeriod,
} from 'core/features/indicators-profile/typings';

export interface UseIndicatorsDataParams {
  category: GraphCategory;
  graphView: GraphView;
  source: GraphSource;
  yearOfAnalysis: number;
  timePeriod?: TimePeriod;
  tenantId?: string;
  serieId?: string;
  classId?: string;
  studentId?: string;
  analysisTypeId?: number;
  analysisEnvironmentId?: number;
  comparativePeriod?: ComparativePeriod;
  comparisonMode?: 'time' | 'student-versus-class';
  comparative1?: number;
  comparative2?: number;
}

function useIndicatorsData(params: UseIndicatorsDataParams) {
  const fetchData = async (
    params: UseIndicatorsDataParams
  ): Promise<{ data: any }> => {
    const { data } = await axios.get<any[]>(`/v2/indicators/get-data`, {
      params: params,
    });
    return { data: data };
  };

  return useQuery([`/v2/indicators/get-data`, params], () => fetchData(params));
}

export default useIndicatorsData;
