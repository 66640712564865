import { getChatGPTResponse } from 'infra/helpers/chatgpt';
import { useMutation } from 'react-query';

function useChatGPTGeneric() {
  return useMutation(
    async (params: string) => {
      return await getChatGPTResponse(params);
    },
    { retry: false }
  );
}

export default useChatGPTGeneric;
