import http from 'infra/http';
import { useMutation } from 'react-query';
import { QuestionForm } from '../types/QuestionsDetails';

export function useSaveQuestion() {
  return useMutation(
    (data: QuestionForm) => {
      if (data.id) {
        return http.put(`/v2/questions/${data.id}`, data);
      }
      return http.post(`/v2/questions`, data);
    },
    { retry: false }
  );
}
