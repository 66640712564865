import { useQuery } from 'react-query';
import axios from 'infra/http';

export interface UseNotificationsParams {
  page?: number;
}

function useCountNotifications() {
  const fetchNotifications = async (): Promise<{
    data: any;
  }> => {
    const { data } = await axios.get<any>(`/v2/notifications/count`);

    return { data };
  };

  return useQuery([`/v2/notifications/count`], () => fetchNotifications(), {
    keepPreviousData: true,
  });
}

export default useCountNotifications;
