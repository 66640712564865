import GenericFilterComponent from 'components/generic-filter';
import useProfiles from 'core/features/profile/hooks/useProfiles';
import { useCurrentUser } from 'core/features/user/store';
import { t } from 'core/resources/strings';
import { FilterProps } from 'infra/helpers/types';
import { useParams } from 'react-router-dom';

function CollaboratorsFilter({ filters, onChangeFilters }: FilterProps) {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { isLoading, data } = useProfiles({ tenantId});

  return (
    <GenericFilterComponent
      onChangeFilters={onChangeFilters}
      filters={filters}
      filterGroups={[
        {
          title: t('app.perfil'),
          options:
            data?.map((item) => {
              return { label: item.name, value: item.id };
            }) ?? [],
          filterName: 'profiles',
        },
        {
          title: t('app.status'),
          options: [
            { label: t('app.ativo'), value: true },
            { label: t('app.inativo'), value: false },
          ],
          filterName: 'status',
          type: 'radio',
        },
      ]}
    />
  );
}

export default CollaboratorsFilter;
