import { useMutation } from 'react-query';
import http from 'infra/http';


export function useStatus() {
  return useMutation(
    (body: any) => {
      return http.put(`/v2/safeSpace/status`, {
        ...body
      });
    },
    { retry: false }
  );
}
