import useTagsCompetence from 'core/features/tags/hooks/useTagsCompetence';
import GenericFilterComponent from 'components/generic-filter';
import { FilterProps } from 'infra/helpers/types';
import { t } from 'core/resources/strings';

function ActivityFilter({filters,onChangeFilters}:FilterProps) {
  const { data } = useTagsCompetence({
    competence: true,
  });

  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: t('app.trilha'),
          filterName: 'tags',
          options:
            data?.map((item) => {
              return { label: item.name['pt-br'], value: item.id };
            }) ?? [],
        },
        {
          title: t('app.dataDeInicio'),
          filterName: 'initialDate',
          options: [],
          type: 'date',
          open:true
        },
        {
          title: t('app.dataDeTermino'),
          filterName: 'endDate',
          options: [],
          type: 'date',
          open:true
        },
      ]}
    />
  );
}

export default ActivityFilter;
