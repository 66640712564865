import { useMediaQuery } from '@chakra-ui/react';
import { Col, Empty, Flex, Pagination, Row, Spin } from 'antd';
import Layout from 'components/layout';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { Permissions } from 'core/resources/enums/permissions';
import { useHistory } from 'react-router';
import usePractices from 'core/features/practices/hooks/usePractices';
import PracticeCard from './components/practiceCard';
import { useEffect, useState } from 'react';
import { PracticeDetails } from 'core/features/practices/typings';
import { Link } from 'react-router-dom';
import { MdExpandMore } from 'react-icons/md';
import CustomCarousel from 'components/custom-carousel';
import './style.css';
import Title from 'components/typography/title';
import SubTitle from 'components/typography/subTitle';
import Button from 'components/buttons/button';
import { t } from 'core/resources/strings';
function PracticesPage() {
  const history = useHistory();
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [check] = useCheckPermissions();
  const { data, isLoading } = usePractices({
    page: 1,
    limit: 9,
    published: true,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginateData] = useState<PracticeDetails[]>([]);
  const [newPractices, setNewPractices] = useState<number>(0);

  const handlePageChange = (page: number) => {
    const startIndex = (page - 1) * 3;
    const endIndex = startIndex + 3;
    setCurrentPage(page);
    setPaginateData(data?.data.slice(startIndex, endIndex) ?? []);
  };

  useEffect(() => {
    if (data?.data) {
      setPaginateData(data?.data.slice(0, 3));
      const lastWeekDate = new Date();
      lastWeekDate.setDate(lastWeekDate.getDate() - 7);

      const newPracticesCount = data.data.filter(
        (item) => new Date(item.createdAt) >= lastWeekDate
      ).length;
      setNewPractices(newPracticesCount);
    }
  }, [data]);
  return (
    <Layout.Container>
      <Title
        primary
        style={{
          marginBottom: isMobile ? '16px' : '24px',
        }}
      >
        {t('app.inspiracoesPedagogicas')}
      </Title>

      <Flex
        gap="24px"
        justify="end"
        style={{ marginBottom: isMobile ? '8px' : '16px' }}
      >
        {check([Permissions.U4hero.Practices.Create]) ? (
          <Button
            size="md"
            onClick={() => history.push('/practices/manager')}
            id="id-tour-activities-button-add"
          >
            {t('app.gerenciarInspracoes')}
          </Button>
        ) : null}
      </Flex>
      <Flex gap="8px">
        <Flex
          gap="8px"
          align="center"
          style={{
            borderRadius: '10px',
            background: 'rgba(244, 244, 244, 0.80)',
            padding: '12px',
          }}
        >
          <p
            style={{
              fontSize: isMobile ? '24px' : '28px',
              color: colorPrimary,
              fontWeight: 700,
            }}
          >
            {data?.total}
          </p>
          <span
            style={{
              fontSize: isMobile ? '14px' : '16px',
              color: 'black',
              fontWeight: 400,
            }}
          >
            {t('app.inspiracoesNoTotal')}
          </span>
        </Flex>
        <Flex
          gap="8px"
          align="center"
          style={{
            borderRadius: '10px',
            background: 'rgba(244, 244, 244, 0.80)',
            padding: '12px',
          }}
        >
          <p
            style={{
              fontSize: isMobile ? '24px' : '28px',
              color: colorPrimary,
              fontWeight: 700,
            }}
          >
            {newPractices}
          </p>
          <span
            style={{
              fontSize: isMobile ? '14px' : '16px',
              color: 'black',
              fontWeight: 400,
            }}
          >
            {t('app.novasInspracoes')}
          </span>
        </Flex>
      </Flex>
      <SubTitle
        primary
        style={{
          marginTop: '16px',
        }}
      >
        {t('app.recomendacoesPedagogicas')}
      </SubTitle>

      <Flex justify="end" align="center">
        <Link to="/practices/suggestions">
          <Flex>
            <p
              style={{
                color: colorGreyBorderAndFont,
                fontSize: isMobile ? '12px' : '14px',
                fontWeight: 500,
                marginBottom: '8px',
              }}
            >
              {t('app.verTudo')}
            </p>
            <MdExpandMore
              color={colorGreyBorderAndFont}
              style={{
                transform: 'rotate(270deg)',
              }}
              size={20}
            />
          </Flex>
        </Link>
      </Flex>
      <div style={{ marginBottom: '24px' }}>
        {isLoading ? (
          <Spin size="large" />
        ) : isMobile ? (
          <CustomCarousel
            slidesToShow={1}
            slidesToScroll={1}
            className="special-carousel"
          >
            {data?.data.map((item) => (
              <PracticeCard practice={item} />
            ))}
          </CustomCarousel>
        ) : (
          <Flex vertical gap="16px">
            <Row gutter={[27, 0]} align="stretch">
              {paginatedData?.map((item) => (
                <Col span={8} key={item.id}>
                  <PracticeCard practice={item} />
                </Col>
              ))}
            </Row>
            {!isLoading && !data?.data.length ? <Empty /> : null}
            <Flex justify="center">
              <Pagination
                defaultCurrent={1}
                current={currentPage}
                pageSize={3}
                total={data?.data.length}
                onChange={handlePageChange}
              />
            </Flex>
          </Flex>
        )}
      </div>

      {/* <Title
        level={2}
        style={{
          color: colorPrimary,
          marginBottom: isMobile ? '16px' : '24px',
          fontSize: isMobile ? '18px' : '24px',
          fontWeight: 700,
        }}
      >
        Não encontrou o que estava buscando?
      </Title> */}
      {/* <Row align="stretch" gutter={[40, 0]}>
        <Col span={12}>
          <div
            style={{
              borderRadius: '72% 28% 48% 52% / 56% 42% 58% 44%',
              width: '100%',
              height: 517,
              background: colorPrimary,
            }}
          >
            <Flex
              vertical
              justify="center"
              align="center"
              style={{ width: '100%', height: '100%', fontSize: '20px' }}
            >
              <p
                style={{ color: 'white', textAlign: 'center', fontWeight: 600 }}
              >
                Olá Explorador!
              </p>
              <p
                style={{
                  color: 'white',
                  textAlign: 'justify',
                  fontWeight: 400,
                  maxWidth: '321px',
                }}
              >
                Explore novas Inspirações pedagógicas com o Lumi, nosso assistente
                espacial. Descubra sugestões específicas para turmas,
                competências ou habilidades. Prepare-se para uma jornada
                educacional extraordinária!
              </p>
            </Flex>
            <img
              src={lumi}
              style={{ position: 'absolute', bottom: 80, left: -20 }}
            />
          </div>
        </Col>
        <Col span={12}>
          <ChatBot />
        </Col>
      </Row> */}
    </Layout.Container>
  );
}

export default PracticesPage;
