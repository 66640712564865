import { Select } from "antd";
import SelectBase, { SelectBaseProps } from "../select-base";

const { Option } = Select;

export interface SelectTypeGameProps<T = any> extends SelectBaseProps<T> {
  value: any;
  onChange: (v: any) => void;
}

function SelectGenreGame({ value, onChange, ...rest }: SelectTypeGameProps) {
  return (
    <SelectBase
      placeholder="Genêro"
      value={value}
      onChange={(ev) => {
        onChange(ev);
      }}
      {...rest}
    >
      <Option value={'Aventura'}>Aventura</Option>
      <Option value={'Estratégia'}>Estratégia</Option>
      <Option value={'Puzzle'}>Puzzle</Option>
      <Option value={'Simulação'}>Simulação</Option>
    </SelectBase>
  );
}

export default SelectGenreGame;
