import http from "infra/http";
import { useMutation } from "react-query";
import { MessageForm } from "../types";

export function useSaveMessage() {
  return useMutation(
    (data: MessageForm) => {
      return http.post(`/v2/chat/`, data);
    },
    { retry: false }
  );
}