import http from "infra/http";
import { useMutation } from "react-query";
import { PracticeFeedbackForm } from "../typings";


export function useSavePracticeFeedback() {
  return useMutation(
    (data: PracticeFeedbackForm) => {
      return http.post(`/v2/practices/feedback`, data);
    },
    { retry: false }
  );
}
