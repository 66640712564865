import { getFirstLetter } from 'infra/helpers/getInicialsName';
import styled from 'styled-components';

export const Chat = styled.div`
  --vertical-padding: 3vh;

  padding: var(--vertical-padding) var(--vertical-padding) 1.5vh
    var(--vertical-padding);
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 300px;
  background: #fff;
  border-radius: 30px;

  @media (max-width: 820px) {
    margin: 0 2.5vw;
  }
`;

export const ConversationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1vh;
  flex: 1;
  padding: 20px 0;
  overflow: auto;
`;

export const MessageContent = styled.div`
  display: flex;
  font-size: 0.8em;
  font-weight: 300;
  padding: 0.8em 1em;
  width: fit-content;
  height: fit-content;
`;

interface Props {
  incomingMessage: boolean;
}
export const MessageContainer = styled.div<Props>`
  display: flex;
  gap: 18px;
  color: #fff;
  flex-direction: ${(props) => (props.incomingMessage ? 'row' : 'row-reverse')};
`;
interface UserProps {
  content: string;
  imageUrl?: string;
  isMobile?: boolean;
}
export const UserProfile = styled.div<UserProps>`
  display: flex;
  position: relative;
  height: 100%;

  &::before {
    content: '${(props) => getFirstLetter(props.content)}';
    display: grid;
    place-content: center;
    padding: 0.5em;
    width: ${(props) => (props.isMobile ? '38px' : '48px')};
    height: ${(props) => (props.isMobile ? '38px' : '48px')};
    border-radius: 50%;
    background: #373737;
    background-image: '${(props) => `url(${props.imageUrl})`}';
  }
`;
export const MessageForm = styled.form`
  padding: 0.5vw 0;
  display: flex;
  align-items: center;
  height: 20%;
  :hover {
    border: none;
  }
  border-top: 1px solid rgba(0, 0, 0, 0.08);

  & input {
    flex: 1;
    height: 100%;
    width: 100%;
    border: none;
  }
`;
export const ButtonContainer = styled.div<any>`
  display: ${(props) => (props.device === 'mobile' ? 'none' : 'flex')};
  flex: ${(props) => (props.flex ? props.flex : '1')};
  align-items: flex-end;

  @media (max-width: 820px) {
    display: flex;
  }

  & a,
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${(props) => (props.padding ? props.padding : '1.2em')};
    width: ${(props) => (props.size ? props.size : '3.9em')};
    height: ${(props) => (props.size ? 'auto' : '3.9em')};
    border: none;
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : '1.4em'};
    background: ${(props) => (props.active ? '#3c95f4' : '#373737')};
    box-shadow: ${(props) =>
      props.active ? 'rgba(32, 112, 198, 0.7) 0 0 10px' : null};
    aspect-ratio: 1/1;
    transition: 0.3s ease-in-out all;
    cursor: pointer;

    @media (max-width: 820px) {
      padding: 0.7em;
    }
  }

  & svg {
    fill: ${(props) => (props.active ? '#fff' : '#737373')};
    transition: 0.3s ease-in-out all;
  }

  & button:hover,
  a:hover {
    background: #2070c6;
    box-shadow: rgba(32, 112, 198, 0.7) 0 0 10px;
    opacity: 0.8;
  }

  & a:hover svg {
    fill: #fff;
  }
`;
