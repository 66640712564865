import React, { useEffect, useRef } from 'react';

interface SvgIconProps {
  url: string;
  color: string;
  size: number | string;
}

const SvgIcon: React.FC<SvgIconProps> = ({ url, color, size }) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    fetch(url)
      .then((response) => response.text())
      .then((svgText) => {
        const modifiedSvgContent = modifySvgContent(svgText, color, size);
        if (svgRef.current) {
          svgRef.current.innerHTML = modifiedSvgContent;
        }
      })
      .catch((error) => {
        console.error('Error fetching SVG:', error);
      });
  }, [url, color, size]);

  const modifySvgContent = (
    svgText: string,
    color: string,
    size: number | string
  ): string => {

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(svgText, 'image/svg+xml');
  
  
    const svgNode = xmlDoc.getElementsByTagName('svg')[0];
    svgNode.setAttribute('width', String(size)); 
    svgNode.setAttribute('height', String(size)); 
  
    const uniqueId = 'svg_' + Math.random().toString(36).substr(2, 9);
    svgNode.setAttribute('id', uniqueId); 
    const styleElement = xmlDoc.createElementNS('http://www.w3.org/2000/svg', 'style');
    styleElement.textContent = `#${uniqueId} path, #${uniqueId} g { fill: ${color} !important; }`; 
    svgNode.appendChild(styleElement);
  
    return new XMLSerializer().serializeToString(xmlDoc);
  };
  
  

  return <svg width={size} height={size} ref={svgRef} />;
};

export default SvgIcon;