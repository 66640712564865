import {
  Flex,
  DatePicker as DatePickerAntd,
  Progress,
  Tooltip,
  Spin,
} from 'antd';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { GenericCard } from 'components/V2/generic-card-styled-component';

function ViewCard({
  description,
  name,
  items,
  isLoading,
}: {
  description: string;
  name: string;
  items: { name: string; value: number }[];
  isLoading: boolean;
}) {
  return (
    <GenericCard height={isLoading ? '40vh' : '100%'}>
      <Flex vertical gap="8px" style={{ width: '100%' }}>
        <Tooltip title={description}>
          <SubTitle primary>{name}</SubTitle>
        </Tooltip>

        {isLoading ? (
          <Flex
            justify="center"
            align="center"
            style={{ width: '100%', height: '100%', alignSelf: 'center' }}
          >
            <Spin size="large" />
          </Flex>
        ) : (
          <Flex vertical>
            {items.map((item,index) => (
              <div key={index}>
                <Text size="md">{item.name}</Text>
                <Progress
                  status="normal"
                  percent={Math.round(item.value)}
                  percentPosition={{ align: 'end', type: 'outer' }}
                />
              </div>
            ))}
          </Flex>
        )}
      </Flex>
    </GenericCard>
  );
}

export default ViewCard;
