import { FilterOutlined, RightOutlined } from '@ant-design/icons';
import { useMediaQuery } from '@chakra-ui/react';
import {
  Flex,
  DatePicker as DatePickerAntd,
  Button,
  Dropdown,
  Menu,
  Progress,
} from 'antd';
import PapersIcon from 'assets/images/icons/PapersIcon';
import Table from 'components/V2/table';
import useActivitiesReport from 'core/features/activity/hooks/useActivitiesReport';
import { ActivityDetails } from 'core/features/activity/types';
import { reportTypes } from 'core/features/report/types';
import useStudent from 'core/features/students/hooks/useStudent';
import useTagsCompetence from 'core/features/tags/hooks/useTagsCompetence';
import dayjs from 'dayjs';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import Report from 'ui/pages/app/indicators/components/Report';
import Text from 'components/typography/text';
import totalActivities from 'assets/images/total-activity.png';
import done from 'assets/images/done.png';
import notStarted from 'assets/images/not-started.png';
import progress from 'assets/images/progress.png';
import CustomCarousel from 'components/custom-carousel';
import useActivitiesStats from 'core/features/activity/hooks/useActivitiesStats';
import moment from 'moment';
import { t } from 'core/resources/strings';

const { RangePicker } = DatePickerAntd;

function LearningRoute({ type }: { type: 'class' | 'student' }) {
  const [page, setPage] = useState(1);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [date, setDate] = useState<any>([
    dayjs().startOf('year'),
    dayjs().endOf('year'),
  ]);
  const [isOpenReport, setIsOpenReport] = useState(false);
  const [activityId, setActivityId] = useState('');
  const [tagId, setTagId] = useState<string>();
  const { colorPrimary } = useU4heroColors();
  const { id, classId, tenantId } = useParams<{
    id: string;
    classId: string;
    tenantId: string;
  }>();
  const { data: student } = useStudent(id);

  const { data: stats } = useActivitiesStats({
    classId: classId,
    userId: id,
    publishDate: date ? dayjs(date[0]).format('YYYY-MM-DD') : undefined,
    endDate: date ? dayjs(date[1]).format('YYYY-MM-DD') : undefined,
  });

  const statsItems = [
    {
      label: t('app.totalDeAtividades'),
      image: totalActivities,
      number: stats?.total ?? 0,
    },
    {
      label: t('app.concluidas'),
      image: done,
      number: stats?.completed ?? 0,
    },
    {
      label: t('app.emProgresso'),
      image: progress,
      number: stats?.inProgress ?? 0,
    },
    {
      label: t('app.naoIniciadas'),
      image: notStarted,
      number: stats?.notStarted ?? 0,
    },
  ];

  const { data: dataCompetences } = useTagsCompetence({ competence: true });

  const { data: activities, isLoading: isLoadingActivities } =
    useActivitiesReport({
      publishDate: date ? dayjs(date[0]).format('YYYY-MM-DD') : undefined,
      tagId,
      endDate: date ? dayjs(date[1]).format('YYYY-MM-DD') : undefined,
      page,
      typeIds: type === 'student' ? undefined : [0, 1, 2],
      studentId: id,
      tenantId,
      classId: classId,
      limit: 6,
    });

  useEffect(() => {
    setPage(1);
  }, [date, tagId]);

  return (
    <div>
      {isOpenReport ? (
        <Report
          type={type === 'class' ? reportTypes.Class : reportTypes.Student}
          classId={type === 'class' ? classId : student?.class?.id}
          studentId={id}
          tenantId={tenantId}
          activityId={activityId}
          onClose={() => {
            setIsOpenReport(false);
          }}
        />
      ) : null}
      <Flex
        align="center"
        justify="end"
        style={{ marginBottom: isMobile ? '8px' : '18px' }}
      >
        <RangePicker
          style={{ height: '34px' }}
          format="DD/MM/YYYY"
          separator={<HiOutlineArrowNarrowRight />}
          suffixIcon={<AiOutlineCalendar color="black" />}
          value={date}
          onChange={(v) => setDate(v)}
        />
      </Flex>
      {isMobile ? (
        <Table<ActivityDetails>
          noMargin
          itemName=""
          showTotal={false}
          data={activities?.data ?? []}
          onChangePage={setPage}
          title={() => (
            <Flex vertical gap={8}>
              <Flex justify="space-between">
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: '18px',
                    color: colorPrimary,
                  }}
                >
                  {t('app.rotaDeAprendizado')}
                </p>
                <Flex style={{ alignSelf: 'end' }}>
                  {' '}
                  <Dropdown
                    overlay={
                      <Menu
                        onClick={(e) =>
                          setTagId(e.key === 'clearFilter' ? undefined : e.key)
                        }
                      >
                        {dataCompetences?.map((item) => (
                          <Menu.Item key={item.id}>
                            {item.name['pt-br']}
                          </Menu.Item>
                        ))}
                        <Menu.Item key="clearFilter">
                          {t('app.limparFiltro')}
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <Flex
                      gap="8px"
                      align="center"
                      style={{
                        background: '#F5F5F5',
                        padding: '8px',
                        borderRadius: '10px',
                        cursor: 'pointer',
                      }}
                    >
                      <FilterOutlined
                        style={{
                          color: 'black',
                          fontSize: '20px',
                          marginRight: '8px',
                        }}
                      />
                      {tagId !== undefined
                        ? dataCompetences?.filter(
                            (item) => item.id === tagId
                          )[0]?.name['pt-br']
                        : ''}
                      <RightOutlined
                        style={{ fontSize: '10px', color: 'black' }}
                      />
                    </Flex>
                  </Dropdown>
                </Flex>
              </Flex>
              <CustomCarousel slidesToScroll={1} slidesToShow={1}>
                {statsItems.map((item) => (
                  <StatItem key={item.label} {...item} />
                ))}
              </CustomCarousel>
            </Flex>
          )}
          total={activities?.total ?? 0}
          page={page}
          pageSize={6}
          isLoading={isLoadingActivities}
          columns={[
            {
              title: <p style={{ fontSize: '16px' }}>{t('app.trilha')}</p>,
              dataIndex: 'tag',
              key: 'tag',
              render: (tag: string, record: ActivityDetails) => (
                <p style={{ fontSize: '14px' }}>
                  {record.typeId === 1
                    ? t('app.avaliacaoInicial')
                    : record.tag.name}
                </p>
              ),
            },
            {
              title: '',
              key: 'action',
              render: (_: string, record: ActivityDetails) => (
                <Button
                  type="primary"
                  size="large"
                  disabled={parseInt(record?.progressOverall, 10) === 0}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<PapersIcon />}
                  onClick={() => {
                    setActivityId(record.id);
                    setIsOpenReport(true);
                  }}
                ></Button>
              ),
            },
          ]}
        />
      ) : (
        <Table<ActivityDetails>
          noMargin
          itemName=""
          showTotal={false}
          data={activities?.data ?? []}
          onChangePage={setPage}
          title={() => (
            <Flex vertical gap={12}>
              <Flex justify="space-between">
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: '18px',
                    color: colorPrimary,
                  }}
                >
                  {t('app.rotaDeAprendizado')}
                </p>
                <Dropdown
                  overlay={
                    <Menu
                      onClick={(e) =>
                        setTagId(e.key === 'clearFilter' ? undefined : e.key)
                      }
                    >
                      {dataCompetences?.map((item) => (
                        <Menu.Item key={item.id}>
                          {item.name['pt-br']}
                        </Menu.Item>
                      ))}
                      <Menu.Item key="clearFilter">
                        {t('app.limparFiltro')}
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <Flex
                    gap="8px"
                    align="center"
                    style={{
                      background: '#F5F5F5',
                      padding: '8px',
                      borderRadius: '10px',
                      cursor: 'pointer',
                    }}
                  >
                    <FilterOutlined
                      style={{
                        color: 'black',
                        fontSize: '20px',
                        marginRight: '8px',
                      }}
                    />
                    {tagId !== undefined
                      ? dataCompetences?.filter((item) => item.id === tagId)[0]
                          ?.name['pt-br']
                      : ''}
                    <RightOutlined
                      style={{ fontSize: '10px', color: 'black' }}
                    />
                  </Flex>
                </Dropdown>
              </Flex>
              <Flex gap={16} justify="space-between">
                {statsItems.map((item) => (
                  <StatItem key={item.label} {...item} />
                ))}
              </Flex>
            </Flex>
          )}
          total={activities?.total ?? 0}
          page={page}
          pageSize={6}
          isLoading={isLoadingActivities}
          columns={[
            {
              title: <p style={{ fontSize: '16px' }}>{t('app.trilha')}</p>,
              dataIndex: 'tag',
              key: 'tag',
              render: (tag: string, record: ActivityDetails) => (
                <p style={{ fontSize: '14px' }}>
                  {record.typeId === 1
                    ? t('app.avaliacaoInicial')
                    : record.tag.name}
                </p>
              ),
              sorter: (a: any, b: any) => a.tag.name.localeCompare(b.tag.name),
              sortIcon: (sortOrder: any) =>
                sortOrder.sortOrder === 'descend' ? (
                  <MdExpandLess size={25} />
                ) : (
                  <MdExpandMore size={25} />
                ),
            },
            {
              title: <p style={{ fontSize: '16px' }}>{t('app.inicio')}</p>,
              dataIndex: 'publishDate',
              key: 'publishDate',
              render: (_: string, record: ActivityDetails) => (
                <p style={{ fontSize: '14px' }}>
                  {dayjs.utc(record.publishDate).format('DD/MM/YYYY')}
                </p>
              ),
              sorter: (a: any, b: any) =>
                a.publishDate.localeCompare(b.publishDate),
              sortIcon: (sortOrder: any) =>
                sortOrder.sortOrder === 'descend' ? (
                  <MdExpandLess size={25} />
                ) : (
                  <MdExpandMore size={25} />
                ),
            },
            {
              title: <p style={{ fontSize: '16px' }}>{t('app.termino')}</p>,
              dataIndex: 'endDate',
              key: 'endDate',
              render: (_: string, record: ActivityDetails) => (
                <p style={{ fontSize: '14px' }}>
                  {dayjs.utc(record.endDate).format('DD/MM/YYYY')}
                </p>
              ),
              sorter: (a: any, b: any) => a.endDate.localeCompare(b.endDate),
              sortIcon: (sortOrder: any) =>
                sortOrder.sortOrder === 'descend' ? (
                  <MdExpandLess size={25} />
                ) : (
                  <MdExpandMore size={25} />
                ),
            },
            {
              title: (
                <p style={{ fontSize: '16px' }}>{t('app.jogoEscolhido')}</p>
              ),
              key: 'game',
              dataIndex: 'game',
              render: (_: string, record: ActivityDetails) => (
                <p style={{ fontSize: '14px' }}>{record?.game?.name}</p>
              ),
              sorter: (a: any, b: any) =>
                a.game.name.localeCompare(b.game.name),
              sortIcon: (sortOrder: any) =>
                sortOrder.sortOrder === 'descend' ? (
                  <MdExpandLess size={25} />
                ) : (
                  <MdExpandMore size={25} />
                ),
            },
            {
              title: <p style={{ fontSize: '16px' }}>{t('app.progresso')}</p>,
              key: 'progressOverall',
              dataIndex: 'progressOverall',
              render: (_: string, record: ActivityDetails) => (
                <Flex vertical>
                  <Progress
                    status="normal"
                    strokeColor={colorPrimary}
                    percent={parseInt(record?.progressOverall, 10)}
                    style={{ fontSize: '14px' }}
                  />
                  {parseInt(record.progressOverall) === 100 && (
                    <Text size="sm">
                      {t('app.concluidoEm')} :{' '}
                      {moment(record.lastAnswerDate).format('DD/MM/YYYY')}
                    </Text>
                  )}
                </Flex>
              ),
              sorter: (a: any, b: any) => {
                return a.progressOverall.localeCompare(b.progressOverall);
              },
              sortIcon: (sortOrder: any) =>
                sortOrder.sortOrder === 'descend' ? (
                  <MdExpandLess size={25} />
                ) : (
                  <MdExpandMore size={25} />
                ),
            },
            {
              title: <p>{t('app.relatorio')}</p>,
              key: 'action',
              render: (_: string, record: ActivityDetails) => (
                <Button
                  disabled={parseInt(record?.progressOverall, 10) === 0}
                  type="primary"
                  size="large"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<PapersIcon />}
                  onClick={() => {
                    setActivityId(record.id);
                    setIsOpenReport(true);
                  }}
                ></Button>
              ),
            },
          ]}
        />
      )}
    </div>
  );
}

export default LearningRoute;

function StatItem({
  image,
  number,
  label,
}: {
  image: string;
  number: number;
  label: string;
}) {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Flex
      align="center"
      justify="space-around"
      style={{
        padding: '8px',
        borderRadius: '20px',
        background: 'rgba(244, 244, 244, 0.80)',
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.10)',
        width: isMobile ? '96%' : '257px',
        height: isMobile ? '100%' : undefined,
      }}
    >
      <img src={image} />
      <Text align="center">
        <strong style={{ fontSize: 28, color: colorPrimary }}>
          {number > 9 || number === 0 ? number : `0${number}`}
        </strong>
        <br />
        {label}
      </Text>
    </Flex>
  );
}
