const getChatGPTResponse = async (question: string) => {
  const { Configuration, OpenAIApi } = require('openai');
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const response = await openai.createChatCompletion({
    model: 'gpt-3.5-turbo',
    messages: [{ role: 'user', content: question }],
  });
  return response.data.choices[0].message.content.trim();
};

export { getChatGPTResponse };
