import { useQuery } from 'react-query'
import axios from 'infra/http'
import { useEffect, useState } from 'react';
import { StoreItemDetails } from '../typings';

export interface UseStoreItemsParams {
  page?: number
  search?: string
  active?:boolean
  types?:string[];
  classification?:string;
  limit?:number;
}

function useStoreItems(params: UseStoreItemsParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchData = async (
    params: UseStoreItemsParams
  ): Promise<{ data: StoreItemDetails[]; total: number }> => {
    const { data, headers } = await axios.get<StoreItemDetails[]>(`/v2/store-items/`, {
      params: {
        ...params,
        search: debouncedQuery,
      },
    })

    return { data: data, total: Number(headers['total']) }
  }

  return useQuery(
    [`/v2/store-items`,{...params,search:debouncedQuery}],
    () => fetchData(params),
    {
      keepPreviousData: true,
    }
  )
}

export default useStoreItems
