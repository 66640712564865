export function calcAge(dateString: string) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function formatMinMaxAge(min: number, max: number) {
  if (max < 12) {
    return "Infantil";
  }

  if (min < 12 && max >= 12) {
    return "Infanto-Juvenil";
  }

  if (min >= 12) {
    return "Juvenil";
  }

  return null;
}
