import { useMediaQuery } from '@chakra-ui/react';
import { Form, Radio, Space } from 'antd';
import { t } from 'core/resources/strings';

function SourceInput({
  index,
  updateForm,
}: {
  index: number;
  updateForm: (
    fieldName: string | number | (string | number)[],
    value: any
  ) => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Form.Item
    rules={[
      {
        required: true,
        message: t('app.mensagemErroDadosASeremExibidos'),
      },
    ]}
    name={[index,'source']}
    label={t('app.dadosASeremExibidosReferenteA')}
  >
    <Radio.Group
      id="id-tour-my-indicators-custom-panel-source-input"
      onChange={()=>{
        updateForm(['graphs', index, 'serieId'], undefined);
        updateForm(['graphs', index, 'classId'], undefined);
        updateForm(['graphs', index, 'studentId'], undefined);
      }}
    >
      <Space direction={isMobile ? 'vertical' : 'horizontal'}>
        <Radio value={'school'}>{t('app.escola')}</Radio>
        <Radio value={'serie'}>{t('app.serie')}</Radio>
        <Radio value={'class'}>{t('app.turma')}</Radio>
        <Radio value={'student'}>{t('app.estudante').slice(0, 1).toUpperCase() + t('app.estudante').slice(1)}</Radio>
      </Space>
    </Radio.Group>
  </Form.Item>
  );
}

export default SourceInput;
