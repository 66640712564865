import { useMediaQuery } from '@chakra-ui/media-query';
import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Image,
  Radio,
  Slider,
  SliderSingleProps,
} from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import SearchBar from 'components/V2/SearchBar';
import Tag from 'components/V2/tag';
import useQuestionsSimple from 'core/features/questions/hooks/useQuestionsSimple';
import { QuestionDto } from 'core/features/questions/types';
import { useCurrentUser } from 'core/features/user/store';
import { t } from 'core/resources/strings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import emptySelected from 'assets/images/empty_selected.png';
import emptyBank from 'assets/images/empty_question_bank.png';
import QuestionFilter from './QuestionFilter';
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from 'react-icons/md';
import { FilterType } from 'infra/helpers/types';
import AppliedFilters from 'components/applied-filters';
import { PiEyeLight } from 'react-icons/pi';
import ModalQuestionOptions from 'components/modals/modal-question-options';
import Text from 'components/typography/text';
import LoadingDots from 'components/V2/loading-dots';
import likert from 'assets/images/likert.png';
import likertMobile from 'assets/images/likert-mobile.png';

function StepQuestion({
  value,
  onChange,
  minAge,
  maxAge,
  typeId,
  maxQuestions,
}: {
  value: string[];
  onChange: (v: string[]) => void;
  minAge?: number;
  maxAge?: number;
  maxQuestions: number | undefined;
  typeId: string;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { user } = useCurrentUser();
  const { colorPrimary } = useU4heroColors();
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState<string>();
  const [search, setSearch] = useState<string>();
  const [filters, setFilters] = useState<FilterType>({
    analysisTypes: [],
    authors: [],
    environments: [],
  });

  const form = Form.useFormInstance();
  const tags = Form.useWatch('tags', form);
  const methodology = Form.useWatch('methodology', form);

  const { data } = useQuestionsSimple({
    tags: tags !== undefined && tags.length ? [tags[1]] : [],
    authors: filters?.authors.map((item) => item.value) ?? undefined,
    typeId: typeId === '4' ? '2' : typeId === '3' ? '0' : typeId,
    tenantId: user?.tenantId,
    minAge: minAge,
    maxAge: maxAge,
    analysisTypes:
      filters?.analysisTypes.map((item) => item.value) ?? undefined,
    environments: filters.environments.map((item) => item.value) ?? undefined,
    page: 1,
    search,
    limit: 200,
    methodology,
    enabled:
      (typeId !== '3' && typeId !== '0') ||
      (tags !== undefined && tags?.length > 0 && methodology !== undefined),
  });
  const [questionBank, setQuestionBank] = useState<QuestionDto[]>([]);
  const [selected, setSelected] = useState<QuestionDto[]>([]);

  const handleAddToSelected = (item: QuestionDto) => {
    setQuestionBank((prevQuestionBank) =>
      prevQuestionBank?.filter((q) => q.id !== item.id)
    );
    const added = [...selected, item];
    setSelected(added);
    onChange(added.map((item) => item.id));
  };

  const handleRemoveFromSelected = (item: QuestionDto) => {
    const filtered = selected.filter((q) => q.id !== item.id);
    setSelected(filtered);
    onChange(filtered.map((item) => item.id));

    setQuestionBank((prevQuestionBank) => [...prevQuestionBank, item]);
  };

  const moveAllToSelected = () => {
    const remainingCapacity =
      (typeId === '3' || typeId === '0') && maxQuestions
        ? maxQuestions - selected?.length
        : questionBank.length;
    if (remainingCapacity > 0) {
      const questionsToMove = questionBank.slice(0, remainingCapacity);
      setSelected([...selected, ...questionsToMove]);
      onChange([...selected, ...questionsToMove].map((item) => item.id));
      setQuestionBank((prevQuestionBank) =>
        prevQuestionBank.slice(remainingCapacity)
      );
    }
  };

  const moveAllToQuestionBank = () => {
    setQuestionBank((prevQuestionBank) => [...prevQuestionBank, ...selected]);
    setSelected([]);
    onChange([]);
  };

  useEffect(() => {
    if (data) {
      setQuestionBank(data.data.filter((item) => !value.includes(item.id)));
      if (
        !search &&
        filters.analysisTypes.length === 0 &&
        filters.authors.length === 0 &&
        filters.environments.length === 0
      ) {
        setSelected(data?.data.filter((item) => value.includes(item.id)));
      }
    }
  }, [data]);

  return (
    <Flex vertical gap="8px">
      <p
        style={{
          fontSize: isMobile ? '14px' : '18px',
          fontWeight: 400,
          color: 'black',
        }}
      >
        {t('app.selecione')} {typeId === '0' || typeId === '3' ? t('app.ate') : 'as'}{' '}
        {typeId === '0' || typeId === '3' ? (
          <strong>{maxQuestions}</strong>
        ) : null}{' '}
        {t('app.perguntasQueSeraoInseridasNaAtividade')}:
      </p>
      {isOpen && (
        <ModalQuestionOptions id={id} onClose={() => setIsOpen(false)} />
      )}
      <Form.Item
        label={t('app.selecioneOTipoDePerguntaDesejada')}
        name="methodology"
        rules={[
          {
            required: true,
            message: t('app.mensagemErroFormatoDaResposta'),
          },
        ]}
        style={{ marginTop: '8px' }}
      >
        <Radio.Group
          id="id-tour-question-creation-input-analysisType"
          style={{ width: '100%' }}
        >
          <Flex
            style={{ width: '100%' }}
            gap={isMobile ? 24 : '3%'}
            vertical={isMobile}
          >
            <Flex vertical gap={8} style={{ width: isMobile ? '100%' : '50%' }}>
              <Radio value={1}>{t('app.escalaLikert')}</Radio>
              <Flex
                style={{
                  background: '#F1F1F1',
                  padding: '8px',
                  paddingBottom: '16px',
                  width: '100%',
                }}
                justify="center"
              >
                <img src={isMobile ? likertMobile : likert} draggable={false} />
              </Flex>
            </Flex>
            <Flex vertical gap={8} style={{ width: isMobile ? '100%' : '50%' }}>
              <Radio value={0}>{t('app.alternativasPersonalizadas')}</Radio>
              <Flex vertical gap={6} style={{ width: '100%' }}>
                <Flex
                  style={{
                    background: '#F1F1F1',
                    borderRadius: '5px',
                    width: '100%',
                  }}
                >
                  <Text>A - </Text>
                </Flex>
                <Flex
                  style={{
                    background: '#F1F1F1',
                    borderRadius: '5px',
                    width: '100%',
                  }}
                >
                  <Text>B - </Text>
                </Flex>
                <Flex
                  style={{
                    background: '#F1F1F1',
                    borderRadius: '5px',
                    width: '100%',
                  }}
                >
                  <Text>C - </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Radio.Group>
      </Form.Item>
      <Row
        id="id-tour-question-step-row"
        gutter={[24, isMobile ? 8 : 0]}
        align="stretch"
      >
        <Col span={isMobile ? 24 : 11}>
          <Flex vertical gap="8px" style={{ height: '100%' }}>
            <p
              style={{
                fontSize: isMobile ? '18px' : '24px',
                color: colorPrimary,
                fontWeight: 700,
              }}
            >
              {t('app.bancoDePerguntas')}
            </p>
            <GenericCard height="100%">
              <Flex vertical gap="16px" style={{ width: '100%' }}>
                <Flex justify="space-between">
                  <SearchBar onSearch={setSearch} width="100%" />
                  <QuestionFilter
                    filters={filters}
                    onChangeFilters={setFilters}
                  />
                </Flex>
                <AppliedFilters
                  appliedFilters={filters}
                  onChangeFilters={setFilters}
                />

                {questionBank.length ? (
                  <Flex
                    id="id-tour-question-bank-list"
                    vertical
                    style={{
                      height: isMobile ? '' : '60vh',
                      overflowY: 'scroll',
                      paddingRight: '16px',
                    }}
                  >
                    {questionBank.map((item) => (
                      <div key={item.id}>
                        <Flex justify="space-between" align="center">
                          <p
                            style={{
                              color: 'black',
                              fontSize: isMobile ? '14px' : '18px',
                              fontWeight: 400,
                            }}
                          >
                            {item.text}
                          </p>
                          <Flex vertical gap="16px">
                            <Button
                              type="primary"
                              size="small"
                              style={{ borderRadius: '8px' }}
                              onClick={() => handleAddToSelected(item)}
                              disabled={
                                (typeId === '3' || typeId === '0') &&
                                maxQuestions
                                  ? selected?.length >= maxQuestions
                                  : false
                              }
                            >
                              <IoIosArrowForward
                                style={{
                                  transform: isMobile ? 'rotate(90deg)' : '',
                                }}
                                size={16}
                              />
                            </Button>

                            <Button
                              type="default"
                              size="small"
                              style={{
                                borderRadius: '8px',
                                border: `2px solid ${colorPrimary}`,
                              }}
                              onClick={() => {
                                setId(item.id);
                                setIsOpen(true);
                              }}
                            >
                              <PiEyeLight
                                style={{ fontSize: '16px' }}
                                color={colorPrimary}
                              />
                            </Button>
                          </Flex>
                        </Flex>
                        <Flex
                          vertical={isMobile}
                          gap={isMobile ? '4px' : '16px'}
                        >
                          <Tag
                            text={`${t('app.analise')}: ${t(
                              `app.${item.analysisType.name}`
                            )}`}
                          />
                          <Tag
                            text={`${t('app.ambiente')}: ${t(
                              `app.${item.analysisEnvironment.name}`
                            )}`}
                            color="secondary"
                            type="outline"
                          />

                          <Tag
                            text={`${t('app.autor')}: ${item.author.name}`}
                            type="outline"
                          />
                        </Flex>

                        <Divider
                          style={{ marginTop: '8px', marginBottom: '8px' }}
                        />
                      </div>
                    ))}
                  </Flex>
                ) : (
                  <Flex
                    vertical
                    justify="center"
                    align="center"
                    style={{ width: '100%', height: '100%' }}
                    gap={16}
                  >
                    {methodology !== undefined ? (
                      <>
                        <Image
                          preview={false}
                          src={emptyBank}
                          alt={'Banco de perguntas vazio'}
                        />
                        <p
                          style={{
                            fontSize: '14px',
                            color: '#7B7B7B',
                            textAlign: 'center',
                            fontWeight: 400,
                            maxWidth: '313px',
                          }}
                        >
                          {t('app.bancoDePerguntasVazio')}
                        </p>
                      </>
                    ) : (
                      <>
                        <LoadingDots />
                        <Text size="sm" color={colorPrimary}>
                          {t('app.selecioneOTipoDePerguntaParaVisualizarAsOpcoesDisponiveis')}
                        </Text>
                      </>
                    )}
                  </Flex>
                )}
              </Flex>
            </GenericCard>
          </Flex>
        </Col>
        <Col span={isMobile ? 24 : 2} style={{ alignSelf: 'center' }}>
          <Flex
            vertical={!isMobile}
            align="center"
            justify="center"
            gap={isMobile ? '32px' : '48px'}
          >
            <Button
              id="id-tour-move-all-to-select-button"
              type="default"
              style={{
                borderRadius: '8px',
                border: `2px solid ${colorPrimary}`,
                padding: '8px',
                height: '100%',
              }}
              onClick={moveAllToSelected}
              disabled={
                (typeId === '3' || typeId === '0') && maxQuestions
                  ? selected?.length >= maxQuestions
                  : false
              }
            >
              <Flex vertical={isMobile}>
                <MdKeyboardDoubleArrowRight
                  style={{ transform: isMobile ? 'rotate(90deg)' : '' }}
                  color={colorPrimary}
                  size={20}
                />
              </Flex>
            </Button>
            <Button
              id="id-tour-move-all-to-question-bank"
              type="default"
              style={{
                borderRadius: '8px',
                border: `2px solid ${colorPrimary}`,
                padding: '8px',
                height: '100%',
              }}
              disabled={selected?.length === 0}
              onClick={moveAllToQuestionBank}
            >
              <Flex vertical={isMobile}>
                <MdKeyboardDoubleArrowLeft
                  style={{ transform: isMobile ? 'rotate(90deg)' : '' }}
                  color={colorPrimary}
                  size={20}
                />
              </Flex>
            </Button>
          </Flex>
        </Col>
        <Col span={isMobile ? 24 : 11}>
          <Flex vertical gap="8px" style={{ height: '100%' }}>
            <p
              style={{
                fontSize: isMobile ? '18px' : '24px',
                color: colorPrimary,
                fontWeight: 700,
              }}
            >
              {t('app.selecionados')}
            </p>
            <GenericCard height="100%">
              {selected?.length ? (
                <Flex
                  id="id-tour-selected-questions-list"
                  vertical
                  style={{
                    height: isMobile ? '' : '60vh',
                    overflowY: 'scroll',
                    paddingRight: '16px',
                    width: '100%',
                  }}
                >
                  {selected?.map((item: any) => (
                    <div key={item.id}>
                      <Flex justify="space-between" align="center" gap="35px">
                        <Flex vertical gap="16px">
                          <Button
                            type="primary"
                            size="small"
                            style={{ borderRadius: '8px' }}
                            onClick={() => handleRemoveFromSelected(item)}
                          >
                            <IoIosArrowBack
                              style={{
                                transform: isMobile ? 'rotate(90deg)' : '',
                              }}
                              size={16}
                            />
                          </Button>

                          <Button
                            type="default"
                            size="small"
                            style={{
                              borderRadius: '8px',
                              border: `2px solid ${colorPrimary}`,
                            }}
                            onClick={() => {
                              setId(item.id);
                              setIsOpen(true);
                            }}
                          >
                            <PiEyeLight
                              style={{ fontSize: '16px' }}
                              color={colorPrimary}
                            />
                          </Button>
                        </Flex>

                        <p
                          style={{
                            color: 'black',
                            fontSize: isMobile ? '14px' : '18px',
                            fontWeight: 400,
                          }}
                        >
                          {item.text}
                        </p>
                      </Flex>
                      <Flex
                        vertical={isMobile}
                        justify="end"
                        align="end"
                        gap={isMobile ? '4px' : '16px'}
                      >
                        <Tag
                          text={`${t('app.analise')}: ${t(
                            `app.${item.analysisType.name}`
                          )}`}
                        />
                        <Tag
                          text={`${t('app.ambiente')}: ${t(
                            `app.${item.analysisEnvironment.name}`
                          )}`}
                          color="secondary"
                          type="outline"
                        />

                        <Tag
                          text={`${t('app.autor')}: ${item.author.name}`}
                          type="outline"
                        />
                      </Flex>

                      <Divider />
                    </div>
                  ))}
                </Flex>
              ) : (
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  style={{ width: '100%' }}
                >
                  <Image
                    id="id-tour-empty-question-selected-image"
                    preview={false}
                    src={emptySelected}
                    alt={'Banco de perguntas vazio'}
                  />
                  <p
                    id="id-tour-empty-question-selected-text"
                    style={{
                      fontSize: '14px',
                      color: '#7B7B7B',
                      textAlign: 'center',
                      fontWeight: 400,
                      maxWidth: '313px',
                    }}
                  >
                    {t('app.ateAgoraNenhumaPerguntaFoiEscolhida')}
                  </p>
                </Flex>
              )}
            </GenericCard>
          </Flex>
        </Col>
      </Row>
    </Flex>
  );
}

export default StepQuestion;
