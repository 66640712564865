import { useEffect } from "react";
import Swal from "sweetalert2";

export function showSuccess(
  message = "Operação realizada com sucesso",
  title = "Sucesso"
) {
  return Swal.fire(title, message, "success");
}

export function showError(
  message = "Houve um problema ao realizar esta operação",
  title = "Erro"
) {
  return Swal.fire(title, message, "error");
}

export function showConfirm(
  message = "Deseja realmente realizar esta operação?",
  config = {}
) {
  return Swal.fire({
    showCancelButton: true,
    imageUrl:'https://media.discordapp.net/attachments/1159543405131616316/1198965785197019227/Group_1.png?ex=65c0d2e6&is=65ae5de6&hm=d6d02bf78f2f4effb34ebf7c2cbda5a4e6368da6f2e6cf4884435cf087b39ef6&=&format=webp&quality=lossless',
    cancelButtonText: "Cancelar",
    title:'Você tem certeza?',
    showCloseButton:true,
    // icon: null,
    text: message,
    customClass: {
      title: 'my-title-class',
      image: 'my-image-class',
      actions:'my-actions-class',
      htmlContainer:'my-container-class'
    },
    ...config,
  });
}

export function showLoading() {
  Swal.showLoading();
}

export function hideLoading() {
  Swal.close();
}

export function useLoading(bool: boolean) {
  useEffect(() => {
    if (bool) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [bool]);
}
