import { useMediaQuery } from '@chakra-ui/react';
import { Dropdown,  Menu } from 'antd';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import moment from 'moment';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import DynamicTable from 'components/V2/dinamic-table';
import { FaEllipsisVertical } from 'react-icons/fa6';
import {
  notificateError,
  notificateSucess,
  showConfirm,
} from 'infra/helpers/notifications';
import Tag from 'components/V2/tag';
import useTags from 'core/features/competence/hooks/useTags';
import { CompetenceDetails } from 'core/features/competence/typings';
import SvgIcon from 'components/SvgIcon';
import SkillFilter from '../SkillFilter';
import { FilterType } from 'infra/helpers/types';
import { useDeleteTag } from 'core/features/competence/hooks/useDeleteTag';

function SkillsTable({onEdit}:{onEdit:(id:string)=> void}) {
  const history = useHistory();
  const [check] = useCheckPermissions();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const { mutate: handleDelete } = useDeleteTag();

  const [filters, setFilters] = useState<FilterType>({
    tags: [],
  });

  const { data, isLoading, refetch } = useTags({
    page,
    search: search,
    limit:pageSize,
    skill: 'true',
    tags: filters?.tags.map((item) => item.value) ?? undefined,
  });

  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };
  const columns = [
    {
      title: <p style={tableHeaderStyle}></p>,
      dataIndex: 'icon',
      key: 'icon',
      mobile: true,
      render: (_: string, record: CompetenceDetails) => (
        <SvgIcon url={record.icon} color={'black'} size={40} />
      ),
    },
    {
      title: <p style={tableHeaderStyle}>Habilidade</p>,
      name: 'Habilidade',
      dataIndex: 'skill',
      key: 'skill',
      mobile: true,
      render: (_: string, record: CompetenceDetails) => (
        <p style={tableRowStyle}>{record._name}</p>
      ),
      sorter: (a: CompetenceDetails, b: CompetenceDetails) =>
        a._name.localeCompare(b._name),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Campo de Conhecimento</p>,
      name: 'Campo de Conhecimento',
      dataIndex: 'tag',
      key: 'tag',
      mobile: true,
      render: (name: string, record: CompetenceDetails) => (
        <Tag text={record.parent?.name['pt-br'] as string} />
      ),
      sorter: (a: CompetenceDetails, b: CompetenceDetails) =>
        (a.parent?.name['pt-br'] as string).localeCompare(
          b.parent?.name['pt-br'] as string
        ),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Data de criação</p>,
      key: 'createdAt',
      dataIndex: 'createdAt',
      name: 'Data de criação',
      render: (_: string, record: CompetenceDetails) => (
        <p style={tableRowStyle}>
          {record.createdAt
            ? moment.utc(record.createdAt).format('DD/MM/YYYY')
            : '-'}
        </p>
      ),
      sorter: (a: CompetenceDetails, b: CompetenceDetails) =>
        a.createdAt.localeCompare(b.createdAt),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Data de edição</p>,
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      name: 'Data de edição',
      render: (_: string, record: CompetenceDetails) => (
        <p style={tableRowStyle}>
          {record.updatedAt
            ? moment.utc(record.updatedAt).format('DD/MM/YYYY')
            : '-'}
        </p>
      ),
      sorter: (a: CompetenceDetails, b: CompetenceDetails) =>
        a.updatedAt.localeCompare(b.updatedAt),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Ações</p>,
      key: 'action',
      mobile: true,
      dataIndex: 'action',
      name: 'Ações',
      render: (_: string, record: CompetenceDetails) => (
        <Dropdown
          overlay={
            <Menu
              id="id-tour-action-student-table"
              onClick={(e) => {
                if (e.key === 'edit') {
                  onEdit(record.id);
                }
                if (e.key === 'delete') {
                  showConfirm({
                    message: `Tem certeza que deseja excluir a habilidade?`,
                    colorPrimary,
                    isMobile,
                  }).then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      handleDelete(record.id, {
                        onSuccess: () => {
                          notificateSucess(
                            `A habilidade foi excluída com sucesso!`
                          );
                          setPage(1);
                          refetch();
                        },
                        onError: () => {
                          notificateError(
                            `Houve um problema ao excluir a habilidade. Verifique sua conexão e tente novamente.`
                          );
                        },
                      });
                    }
                  });
                }
              }}
            >
              {check([Permissions.U4hero.Helpers.Edit]) ? (
                <Menu.Item id="edit-button" key={'edit'}>
                  Editar
                </Menu.Item>
              ) : null}
              {check([Permissions.U4hero.Helpers.Edit]) ? (
                <Menu.Item id="delete-button" key={'delete'}>
                  Deletar
                </Menu.Item>
              ) : null}
            </Menu>
          }
          trigger={['click']}
        >
          <FaEllipsisVertical
            id="id-tour-action-helpers-table"
            size={24}
            style={{ cursor: 'pointer' }}
          />
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    setPage(1);
  }, [search, filters]);

  return (
    <DynamicTable
      id="id-tour-skills-table"
      itemName="habilidade"
      data={data?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title=""
      total={data?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isLoading}
      defaultColumns={
        isMobile ? columns.filter((column) => column.mobile) : columns
      }
      allColumns={columns}
      onSearch={setSearch}
      hideCog={true}
      appliedFilters={filters}
      onChangeFilters={setFilters}
      filters={<SkillFilter filters={filters} onChangeFilters={setFilters} />}
    />
  );
}

export default SkillsTable;
