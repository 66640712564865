export const getFirstLetter = (name:string) => {
    const lettersArray = name
        .split(' ')
        .map(word => word[0]);
    

    if(lettersArray.length === 1) {
        return lettersArray[0]
        .toString()
        .toUpperCase();
    }

    const firstLetters = [lettersArray[0], lettersArray[lettersArray.length - 1]]
    .join('')
    .toUpperCase();

    return firstLetters;
}