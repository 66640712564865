import GenericFilterComponent from 'components/generic-filter';
import { t } from 'core/resources/strings';
import { FilterProps } from 'infra/helpers/types';

function FeedbackFilter({ filters, onChangeFilters }: FilterProps) {
    const ratings = [
        {
          label: t('app.muitoRuim'),
          value: 1,
        },
        {
          label: t('app.ruim'),
          value: 2,
        },
        {
          label: t('app.regular'),
          value: 3,
        },
        {
          label: t('app.bom'),
          value: 4,
        },
        {
          label: t('app.muitoBom'),
          value: 5,
        },
      ];

  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: t('app.avaliacao'),
          filterName: 'ratings',
          type:'number',
          options: ratings?.map((item) => {
            return { label: item.label, value: item.value };
          }),
        },
        {
          title: t('app.aPartirDe'),
          filterName: 'initialDate',
          options: [],
          type: 'date',
          open: true,
        },
      ]}
    />
  );
}

export default FeedbackFilter;
