import { useState } from 'react';
import { useHistory } from 'react-router';
import { useMediaQuery } from '@chakra-ui/react';
import useTenants from 'core/features/tenants/hooks/useTenants';
import moment from 'moment';
import SearchBar from '../../../../../components/V2/SearchBar';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import Table from 'components/V2/table';
import { TenantDetails } from 'core/features/tenants/typings';
import Avatar from 'components/avatar';
import Card from 'components/cards/cardMobile';
import { Pagination, Flex } from 'antd';
import ModalSchoolInfo from 'components/modals/modal-school-info-metrics';
import Status from 'components/status';

function ListSchools() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [pageSize, setPageSize] = useState(25);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [school, setSchool] = useState<TenantDetails>();

  const { data, isLoading, isFetching } = useTenants({
    page,
    search,
    disableConcat: true,
    limit: pageSize,
  });

  const isEachLoading = isLoading || isFetching;

  return (
    <Flex vertical gap={18} >
      {isOpenModal ? (
        <ModalSchoolInfo
          school={school}
          onClose={() => {
            setIsOpenModal(false);
          }}
        />
      ) : null}

      <Flex justify={isMobile ?'' :'end'}>
        <SearchBar onSearch={setSearch} />
      </Flex>

      {isMobile ? (
        <>
          {data?.data.map((item, index: number) => (
            <Card
              name={item.name}
              url={item.imageUrl}
              onClick={() => history.push(`/metrics/${item.id}`)}
              onClickButton={() => {
                setSchool(data?.data[index]);
                setIsOpenModal(true);
              }}
            />
          ))}
          <Pagination
            style={{ alignSelf: 'center' }}
            showSizeChanger
            showLessItems
            pageSize={pageSize}
            current={page}
            onChange={(page, size) => {
              setPage(page);
              setPageSize(size);
            }}
            total={data?.total ?? 0}
          />
        </>
      ) : (
        <Table<TenantDetails>
          itemName="escola"
          data={data?.data ?? []}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          total={data?.total ?? 0}
          page={page}
          pageSize={pageSize}
          isLoading={isEachLoading}
          onClickRow={(item) => history.push(`/metrics/${item.id}`)}
          columns={[
            {
              title: '',
              dataIndex: 'imageUrl',
              key: 'imageUrl',
              render: (url: string, record: TenantDetails) => (
                <Avatar url={url} name={record.name} type='secondary' />
              ),
            },
            {
              title: <p style={{ fontSize: "24px" }}>Nome</p>,
              dataIndex: 'name',
              key: 'name',
              render: (name: string) => <p style={{ fontSize: "20px" }}>{name}</p>,
            },
            {
              title: <p style={{ fontSize: "24px" }}>Endereço</p>,
              dataIndex: 'address',
              key: 'address',
              width: '300px',
              render: (_: string, record: TenantDetails) => (
                <p style={{ fontSize: "20px" }}>
                  {record.addressCity} - {record.addressState}
                </p>
              ),
            },
            {
              title: <p style={{ fontSize: "24px" }}>Status</p>,
              dataIndex: 'status',
              key: 'status',
              width: '200px',
              sorter: (a: TenantDetails, b: TenantDetails) => {
                return a.active === b.active ? 0 : a.active ? -1 : 1;
              },
              sortIcon: (sortOrder: any) =>
                sortOrder.sortOrder === 'descend' ? (
                  <MdExpandLess size={25} />
                ) : (
                  <MdExpandMore size={25} />
                ),
              render: (_: string, record: TenantDetails) => (
                <Status type={record.active ? 'active' : 'inactive'} />
              ),
            },
            {
              title: <p style={{ fontSize: "24px" }}>Data da criação</p>,
              key: 'createdAt',
              dataIndex: 'createdAt',
              width: '250px',
              render: (createdAt: string) => (
                <p style={{ fontSize: "20px" }}>
                  {moment.utc(createdAt).format('DD/MM/YYYY')}
                </p>
              ),
            },
          ]}
        />
      )}
    </Flex>
  );
}

export default ListSchools;
