import { useQuery } from 'react-query';
import axios from 'infra/http';

function usePracticesAuthors() {
  const fetchData = async (): Promise<{ id: string; name: string }[]> => {
    const { data } = await axios.get<{ id: string; name: string }[]>(
      `/v2/practices/authors`
    );

    return data;
  };

  return useQuery([`/v2/practices/authors`], () => fetchData());
}

export default usePracticesAuthors;
