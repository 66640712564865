import http from "infra/http";
import { useMutation } from "react-query";
import { GuideForm } from "../typings";


export function useSaveGuide() {
  return useMutation(
    (data: GuideForm) => {
      if (data.id) {
        return http.put(`/v2/guides`, data);
      }
      return http.post(`/v2/guides`, data);
    },
    { retry: false }
  );
}
