import axios from 'axios';
import moment from 'moment';
import { useQuery } from 'react-query';
import { baseUrlQedu, QeduParams, RendimentoEscolar } from '../types';

export function useRendimentoEscolar(params: QeduParams) {
  const fetchData = async (
    params: QeduParams
  ): Promise<RendimentoEscolar[]> => {
    let year = moment().year();
    let data: RendimentoEscolar[] = [];

    while (year >= 2000) {
      try {
        const response = await axios.get<{ data: RendimentoEscolar[] }>(
          `${baseUrlQedu}/indicador/tr/escola`,
          {
            params: {
              ...params,
              id: params.inep_id,
              ano: year,
            },
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_QEDU_API_KEY}`,
            },
          }
        );

        if (response.data.data.length > 0) {
          data = response.data.data;
          break;
        }
      } catch (error) {}

      year--;
    }

    return data;
  };

  return useQuery([`rendimento-escolar`, params], () => fetchData(params), {
    enabled: !!params?.inep_id,
  });
}
