import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Modal } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import Text from 'components/typography/text';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { useRef, useState } from 'react';
import SubTitle from 'components/typography/subTitle';
import { useValidate2FaPrivate } from 'core/features/auth/hooks/useValidate2FaPrivate';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import useToggle2Fa from 'core/features/auth/hooks/useToggle2Fa';
import Button from 'components/buttons/button';
import { useCurrentUser } from 'core/features/user/store';
import { t } from 'core/resources/strings';

function Modal2Fa({
  activate,
  onClose,
  onSucess
}: {
  activate: boolean;
  onClose: () => void;
  onSucess: () => void;
}) {
  const AuthInputRef = useRef<AuthCodeRef>(null);
  const {user} = useCurrentUser();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const { mutate: handle2Fa,isLoading } = useValidate2FaPrivate();
  const [authCode, setAuthCode] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const { mutate: toggle2Fa } = useToggle2Fa();
  const _handle2Fa = () => {
    handle2Fa(
      {
        code: authCode,
      },
      {
        onSuccess: (data: any) => {
          if (data.data.success) {
            setAuthCode('');
            setIsInvalid(false);
            notificateSucess(
              `${t('app.autenticacaoDeDoisFatoresFoi')} ${
                activate ? t('app.ativada') : t('app.desativada')
              } ${t('app.comSucesso')}`
            );
            onSucess();
            onClose();

          }else{
            setAuthCode('');
            setIsInvalid(true);
            notificateError(
              `${t('app.codigoAutenticacaoInvalido')}`
            );
          }
        
        },
        onError: () => {
          notificateError(
            `${t('app.a').toUpperCase()} ${
              activate ? t('app.ativacao') : t('app.desativacao')
            } ${t('app.daAutenticacaoDeDoisFatoresFalhouPor favorInserirOCódigoEnviadoPor')}`
          );
        },
      }
    );
  };
  const _handleToggle = (isActive: boolean) => {
    toggle2Fa(isActive, {
      onSuccess: (data: any) => {
        notificateSucess(t('app.teEnviamosUmNovoCodigoChequeSeuEmail'));
      },
      onError: () => {
        notificateError(t('app.erroAoEnviarCodigo'));
      },
    });
  };
  return (
    <Modal
      title={
        <SubTitle primary>
          {activate ? t('app.segurancaReforcada') : t('app.desativacaoDaAutenticacao')}
        </SubTitle>
      }
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
      open={true}
      centered
      onCancel={onClose}
      footer={null}
      width={450}
    >
      <Flex vertical gap="8px">
        <Text>
          {activate
            ? t('app.mensagem2FaAtivado')
            : t('app.mensagem2FaDesativado')}
        </Text>
        <Text>{t('app.digiteAquiOCodigoQueTeEnviamosPeloEmail')}</Text>
        <AuthCode
          inputClassName="input-auth"
          containerClassName="container-auth"
          allowedCharacters="numeric"
          onChange={(res) => setAuthCode(res)}
          ref={AuthInputRef}
        />
        {isInvalid ? (
          <Text color="red">{t('app.codigoAutenticacaoInvalido')}</Text>
        ) : null}
        <Button
          size="md"
          style={{ justifySelf: 'center', alignSelf: 'center' }}
          onClick={_handle2Fa}
          disabled={authCode.length < 6}
          loading={isLoading}
        >
          {t('app.verificar')}
        </Button>
        <Text
          style={{
            fontSize: isMobile ? '14px' : '18px',
            fontWeight: 400,
            color: 'black',
          }}
        >
          {t('app.recebimentoDoSeuCodigoPodeLevarAteUmMinuto')}
          <button
            style={{ textDecoration: 'underline', color: 'black' }}
            onClick={() => {
              _handleToggle(activate);
              setIsInvalid(false);
            }}
          >
            {t('app.cliqueAquiParaSolicitarUmNovoCodigo')}
          </button>
        </Text>
      </Flex>
    </Modal>
  );
}

export default Modal2Fa;
