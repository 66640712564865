import axios from 'axios';
import { notificateError } from 'infra/helpers/notifications';
import { useQuery } from 'react-query';

interface Address {
  cep: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  service: string;
}
function useAddress(zipcode?: string) {
  const fetchData = async (
    zipcode: string
  ): Promise<Address | { error: string }> => {
    try {
      const { data } = await axios.get<Address>(
        `https://brasilapi.com.br/api/cep/v1/${zipcode}`
      );

      return data;
    } catch (error) {
      // notificateError('CEP não encontrado');
      return {
        error: 'CEP inválido',
      };
    }
  };

  return useQuery([`/cep/v1`, zipcode], () => fetchData(zipcode!), {
    enabled: !!zipcode,
  });
}

export default useAddress;
