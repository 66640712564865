import { useMutation } from "react-query";
import http from "infra/http";
import { StudentForm } from "../typings/form";

export function useSaveStudent(id?: string) {
  return useMutation(
    (data: StudentForm) => {
      const body: any = { ...data };

      delete body.id;
      delete body.class;
      delete body.student;
      delete body.school;
      delete body.external;
      delete body.languageLocale;
      delete body.github;
      delete body.license;
      delete body.serie;
      delete body.master;
      delete body.userId;

      if (id) {
        return http.put(`/v1/student/${id}`, body);
      }

      return http.post(`v1/student`, body);
    },
    { retry: false }
  );
}
