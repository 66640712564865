import { useMediaQuery } from '@chakra-ui/react';
import DynamicTable from 'components/V2/dinamic-table';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import { Dropdown, Flex, Menu } from 'antd';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { FaEllipsisVertical } from 'react-icons/fa6';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import {
  notificateSucess,
  notificateError,
  showConfirm,
} from 'infra/helpers/notifications';
import Status from 'components/status';
import { FilterType } from 'infra/helpers/types';
import { useCurrentUser } from 'core/features/user/store';
import Tag from 'components/V2/tag';
import InstituitionsFilter from '../InstitutionsFilter';
import moment from 'moment';
import useTenants from 'core/features/tenants/hooks/useTenants';
import { TenantDetails } from 'core/features/tenants/typings';
import defaultInstituition from 'assets/images/default-instituition.png';
import Text from 'components/typography/text';
import useToggleTenant from 'core/features/tenants/hooks/useToggleTenant';

function InstitutionsTable({
  onEdit,
  organization,
}: {
  onEdit: (v: TenantDetails) => void;
  organization: boolean;
}) {
  const history = useHistory();
  const [check] = useCheckPermissions();
  if (!check([Permissions.U4hero.Tenant.View, Permissions.Org.Tenant.View])) {
    history.push('/errors/no-permission');
  }

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const { mutate: handleToggle, isLoading: isLoadingToggle } =
    useToggleTenant();
  const { colorPrimary } = useU4heroColors();
  const { user } = useCurrentUser();
  const [filters, setFilters] = useState<FilterType>(
    organization
      ? { locations: [], status: [], createdAt: [] }
      : {
          locations: [],
          tenants: [],
          status: [],
          createdAt: [],
        }
  );
  const isU4Hero = !!user?.resources.includes(Permissions.U4hero.Tenant.View);

  const { data, isLoading, isFetching, refetch } = useTenants({
    page,
    search,
    parentId: isU4Hero ? undefined : user?.tenantId,
    disableConcat: true,
    master: organization,
    limit: pageSize,
    active: filters?.status?.map((item) => item.value)[0] ?? undefined,
    tenants: filters?.tenants?.map((item) => item.value) ?? undefined,
    createdAt: filters?.createdAt?.map((item) => item.value)[0] ?? undefined,
    states:filters?.locations?.map((item)=> item.value) ?? undefined
  });

  const isEachLoading = isLoading || isFetching;

  useEffect(() => {
    setPage(1);
  }, [filters, search]);

  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };

  const columns = [
    {
      title: <p style={tableHeaderStyle}></p>,
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (_: string, record: TenantDetails) => (
        <img
          src={record.imageUrl ?? defaultInstituition}
          color={'black'}
          width="56px"
        />
      ),
    },
    {
      title: <p style={tableHeaderStyle}>Nome</p>,
      name: 'Nome',
      dataIndex: 'name',
      key: 'name',
      mobile: true,
      render: (_: string, record: TenantDetails) => (
        <p style={tableRowStyle}>{record.name}</p>
      ),
      sorter: (a: TenantDetails, b: TenantDetails) =>
        a.name.localeCompare(b.name),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Localização</p>,
      name: 'Localização',
      dataIndex: 'localization',
      key: 'localization',
      mobile: true,
      render: (_: string, record: TenantDetails) => (
        <Flex vertical>
          <p style={tableRowStyle}>
            {record.addressCity} - {record.addressState}
          </p>
          <Tag text={'Brasil'} />
        </Flex>
      ),
      sorter: (a: TenantDetails, b: TenantDetails) =>
        a.name.localeCompare(b.name),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Data de criação</p>,
      key: 'createdAt',
      dataIndex: 'createdAt',
      name: 'Data de criação',
      render: (_: string, record: TenantDetails) => (
        <p style={tableRowStyle}>
          {record.createdAt
            ? moment.utc(record.createdAt).format('DD/MM/YYYY')
            : '-'}
        </p>
      ),
      sorter: (a: TenantDetails, b: TenantDetails) =>
        a.createdAt.localeCompare(b.createdAt),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Status</p>,
      name: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_: string, record: TenantDetails) => (
        <div>
          {record.active ? (
            <Status type={'active'} />
          ) : (
            <Status type={'inactive'} />
          )}
        </div>
      ),
      sorter: (a: any, b: any) => a.active - b.active,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Ações</p>,
      key: 'action',
      mobile: true,
      dataIndex: 'action',
      name: 'Ações',
      render: (_: string, record: TenantDetails) => (
        <Dropdown
          overlay={
            <Menu
              onClick={(event) => {
                if (event.key === 'edit') {
                  onEdit(record);
                }
                if (event.key === 'manage-students') {
                  history.push(`/schools/${record.id}/students`);
                }
                if (event.key === 'manage-classes') {
                  history.push(`/schools/${record.id}/classes`);
                }
                if (event.key === 'manage-collaborators') {
                  history.push(`/schools/${record.id}/collaborators`);
                }
                if (event.key === 'manage-profiles') {
                  history.push(`/schools/${record.id}/profiles`);
                }
                if (event.key === 'manage-resources') {
                  history.push(`/schools/${record.id}/resources`);
                }
                if (event.key === 'toggle') {
                  showConfirm({
                    message: `Tem certeza que deseja ${
                      record.active ? 'desativar' : 'ativar'
                    } a ${record.master ? 'organização' : 'escola'} ?`,
                    colorPrimary,
                    isMobile,
                  }).then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      if (
                        check([
                          Permissions.U4hero.Tenant.Activate,
                          Permissions.Org.Tenant.Activate,
                        ])
                      ) {
                        handleToggle(
                          {
                            id: record.id,
                            isActive: record.active,
                          },
                          {
                            onSuccess: () => {
                              refetch();
                              notificateSucess(
                                `${record.master ? 'Organização' : 'Escola'} ${
                                  record.active ? 'desativada' : 'ativada'
                                } com sucesso!`
                              );
                            },
                            onError: () =>
                              notificateError(
                                `Houve um problema ao ${
                                  record.active ? 'desativar' : 'ativar'
                                } a ${
                                  record.master ? 'Organização' : 'Escola'
                                } .`
                              ),
                          }
                        );
                      } else {
                        notificateError(
                          `Você não tem permissão para ${
                            record.active ? 'desativar' : 'ativar'
                          } a ${record.master ? 'Organização' : 'Escola'}!`
                        );
                      }
                    }
                  });
                }
              }}
            >
              <Menu.Item key="edit">
                <Text size="lg">Editar</Text>
              </Menu.Item>

              {!record.master ? (
                <>
                  <Menu.Item key="manage-students">
                    <Text size="lg">Gerenciar Estudantes</Text>
                  </Menu.Item>
                  <Menu.Item key="manage-classes">
                    <Text size="lg">Gerenciar Turmas</Text>
                  </Menu.Item>
                </>
              ) : null}

              <Menu.Item key="manage-collaborators">
                <Text size="lg">Gerenciar Colaboradores</Text>
              </Menu.Item>
              <Menu.Item key="manage-profiles">
                <Text size="lg">Gerenciar Perfis</Text>
              </Menu.Item>
              <Menu.Item key="manage-resources">
                <Text size="lg">Gerenciar Recursos</Text>
              </Menu.Item>
              <Menu.Item key={'toggle'}>
                <Text size="lg">{record.active ? 'Desativar' : 'Ativar'}</Text>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <FaEllipsisVertical
            size={24}
            style={{ cursor: 'pointer' }}
            id="id-tour-action-tenant-table"
          />
        </Dropdown>
      ),
    },
  ];

  return (
    <Flex gap={8} vertical>
      <DynamicTable
        id="id-tour-instituitions-table"
        itemName={organization ? 'organização' : 'escola'}
        data={data?.data ?? []}
        onChangePage={setPage}
        appliedFilters={filters}
        onChangeFilters={setFilters}
        filters={
          <InstituitionsFilter filters={filters} onChangeFilters={setFilters} />
        }
        onChangePageSize={setPageSize}
        title=""
        total={data?.total ?? 0}
        page={page}
        pageSize={pageSize}
        isLoading={isEachLoading}
        defaultColumns={
          isMobile ? columns.filter((column) => column.mobile) : columns
        }
        allColumns={columns}
        onSearch={setSearch}
        hideCog={true}
      />
    </Flex>
  );
}

export default InstitutionsTable;
