import { useQuery } from 'react-query';
import axios from 'infra/http';
import { FeedbackDetails } from '../typings';

export interface UsePracticeFeedbackParams {
  page?: number;
  ratings?: string[];
  initialDate?: string;
  id?: string;
  pageSize?: number;
  search?: string
}

function usePraticeFeedbacks(params: UsePracticeFeedbackParams) {
  const fetchFeedbacks = async (
    params: UsePracticeFeedbackParams
  ): Promise<{ data: FeedbackDetails[]; total: number }> => {
    const { data, headers } = await axios.get<FeedbackDetails[]>(
      `/v2/practices/feedback`,
      {
        params: {
          page: params.page,
          ratings: params.ratings,
          initialDate: params.initialDate,
          id: params.id,
          limit: params.pageSize,
          search:params.search
        },
      }
    );

    return { data: data, total: Number(headers['total']) };
  };

  return useQuery(
    [
      `/v2/practices/feedback`,
      params,
    ],
    () => fetchFeedbacks(params),
    {
      keepPreviousData: true,
    }
  );
}

export default usePraticeFeedbacks;
