import http from "infra/http";
import { useMutation } from "react-query";

export function useDeletePracticeAdaptation() {
  return useMutation(
    (id: string) => {
      return http.delete(`/v2/practices/adapt/${id}`);
    },
    { retry: false }
  );
}
