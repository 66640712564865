import { Box } from "@chakra-ui/react";
import { reportTypes } from "core/features/report/types";
import ActivityCardList from "../ActivityCardList";


function TabAtividade() {
  return (
    <Box mt="15px">
      <ActivityCardList collumns={5} type={reportTypes.Activity} />
    </Box>
  );
}

export default TabAtividade;
