import Text from 'components/typography/text';
import { Divider, Flex, } from 'antd';
import { Link,  } from 'react-router-dom';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import SubTitle from 'components/typography/subTitle';
import { MdExpandMore } from 'react-icons/md';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import { HoverItem } from '../hover-item/hover';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useMediaQuery } from '@chakra-ui/media-query';

function LinkBox({
  title,
  items,
}: {
  title: string;
  items: {
    id: string;
    title: string;
    link: string;
  }[];
}) {
  const { colorPrimary, colorPrimaryLight } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <GenericCard width="100%">
      <Flex vertical style={{ width: '100%' }}>
        <SubTitle>{title}</SubTitle>
        <Divider
          style={{
            marginTop: isMobile ? '8px' : '16px',
            marginBottom: isMobile ? '8px' : '16px',
          }}
        />
        <Flex vertical gap={isMobile ? '8px' : '16px'}>
          {items.map((item) => (
            <Link to={item.link} key={item.id}>
              <HoverItem hover={hex2rgba(colorPrimary, 0.3)}>
                <Flex
                  key={item.id}
                  style={{
                    width: '100%',
                    padding: '8px',
                  }}
                  justify="space-between"
                >
                  <Text size="lg">{item.title}</Text>
                  <MdExpandMore
                    size={24}
                    style={{
                      cursor: 'pointer',
                      transform: 'rotate(-90deg)',
                    }}
                  />
                </Flex>
              </HoverItem>
            </Link>
          ))}
        </Flex>
      </Flex>
    </GenericCard>
  );
}

export default LinkBox;
