import { useMediaQuery } from '@chakra-ui/react';
import {
  Modal,
  DatePicker as DatePickerAntd,
  Flex,
  Pagination,
  Spin,
} from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import SubTitle from 'components/typography/subTitle';
import useStudentLogins from 'core/features/students/hooks/useStudentLogins';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { AiOutlineCalendar } from 'react-icons/ai';
import { PiCalendar, PiClockAfternoonLight } from 'react-icons/pi';
import Text from 'components/typography/text';
import lumiEmpty from 'assets/images/lumi-empty-logins-modal.png';

const { RangePicker } = DatePickerAntd;

function ModalStudentLogins({
  onClose,
  userId,
}: {
  userId?: string;
  onClose: () => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [date, setDate] = useState<any>([
    dayjs().startOf('year'),
    dayjs().endOf('year'),
  ]);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const { data, isLoading } = useStudentLogins({
    page: page,
    limit: pageSize,
    userId,
    startDate: date ? dayjs(date[0]).format('YYYY-MM-DD') : undefined,
    endDate: date ? dayjs(date[1]).format('YYYY-MM-DD') : undefined,
  });
  const { colorGreyCard, colorPrimary } = useU4heroColors();

  useEffect(() => {
    setPage(1);
  }, [pageSize, date]);

  return (
    <Modal
      title={<SubTitle primary>Histórico de Acessos</SubTitle>}
      open={true}
      onCancel={onClose}
      footer={null}
      width={isMobile ? '90%' : 570}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Flex vertical gap={20}>
        <Flex align="center" justify="end">
          <RangePicker
            style={{ height: '34px' }}
            format="DD/MM/YYYY"
            separator={<HiOutlineArrowNarrowRight />}
            suffixIcon={<AiOutlineCalendar color="black" />}
            value={date}
            onChange={(v) => setDate(v)}
          />
        </Flex>

        {isLoading ? (
          <Spin size="large" />
        ) : (
          <Flex vertical gap={16}>
            {data && data?.data?.length > 0 ? (
              data.data.map((item) => (
                <Flex
                  key={item.id}
                  style={{
                    padding: isMobile ? '8px':'16px',
                    border: `2px solid ${colorGreyCard}`,
                    borderRadius: '20px',
                  }}
                  align={isMobile ? 'self-start':"center"}
                  justify="space-between"
									gap={isMobile ? 12 : undefined}
									vertical={isMobile}
                >
                  <Flex gap={14} align="center">
                    <PiCalendar size={30} color={colorPrimary} />
                    <Text>
                      {dayjs(item.createdAt).format('DD/MM/YYYY - dddd')}
                    </Text>
                  </Flex>
                  <Flex gap={14} align="center">
                    <PiClockAfternoonLight color={colorPrimary} size={30} />
                    <Text>{dayjs(item.createdAt).format('HH[h]mm[min]')}</Text>
                  </Flex>
                </Flex>
              ))
            ) : (
              <Flex align="center" justify="center" vertical gap={22}>
                <img src={lumiEmpty} alt="Lumi com calendário" />
                <Text>
                  Nenhum acesso foi registrado para este estudante até agora.
                  Pode ser importante verificar com ele o que está acontecendo.
                </Text>
              </Flex>
            )}
          </Flex>
        )}

        {data && data?.data.length > 0 && (
          <Flex justify="center" style={{ marginBottom: '18px' }}>
            <Pagination
              defaultCurrent={1}
              showSizeChanger
              current={page}
              pageSize={pageSize}
              total={data?.total}
              onChange={(v, size) => {
                setPage(v);
                setPageSize(size);
              }}
            />
          </Flex>
        )}
      </Flex>
    </Modal>
  );
}

export default ModalStudentLogins;
