import { useQuery } from 'react-query';
import axios from 'infra/http';
import { GravityDetails } from '../typings';
import { useEffect, useState } from 'react';

export interface UseDegreeParams {
  integrationId?: string;
  initialDate?: string;
  finalDate?: string;
  search?: string;
}
function useDegree(params: UseDegreeParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchData = async (
    params: UseDegreeParams
  ): Promise<GravityDetails> => {
    const { data } = await axios.get<GravityDetails>(`/v2/inkluzen/degree`, {
      params: {
        integrationId: params.integrationId,
        initialDate: params.initialDate,
        finalDate: params.finalDate,
        search: debouncedQuery,
      },
    });

    return data;
  };

  return useQuery(
    [`/v2/inkluzen/degree`, { ...params, search: debouncedQuery }],
    () => fetchData(params)
  );
}

export default useDegree;
