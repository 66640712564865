import { Flex, Modal } from 'antd';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { t } from 'core/resources/strings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';

function ModalStartTour({
  title,
  backgroundImage,
  onClose,
  onStart,
}: {
  title: string;
  backgroundImage: string;
  onClose: () => void;
  onStart: () => void;
}) {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  return (
    <Modal
      centered
      open={true}
      footer={null}
      title={<SubTitle primary>{title}</SubTitle>}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
      onCancel={onClose}
      zIndex={1002}
    >
      <Flex vertical gap="16px">
        <img src={backgroundImage} width="100%" />
        <Text size="lg">
          {t('app.desejaIniciarOTour')} "{title}" ?
        </Text>

        <Flex
          gap="16px"
          justify="center"
          style={{ padding: '0px 16px 16px 16px' }}
        >
          <Button variant="outline" size="md" onClick={onClose}>
            {t('app.nao')}
          </Button>
          <Button size="md" onClick={onStart}>
            {t('app.sim')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default ModalStartTour;
