import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex } from 'antd';
import BackButton from 'components/buttons/back-button';
import GroupButton from 'components/buttons/group-button';
import Layout from 'components/layout';
import Title from 'components/typography/title';
import useGame from 'core/features/games/hooks/useGame';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import GameInfo from './tabs/gameInfo';
import GameActivities from './tabs/gameActivities';
import { t } from 'core/resources/strings';

function ViewGame() {
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [tab, setTab] = useState('info');
  const { id } = useParams<{ id: string }>();
  const { data } = useGame(id);

  return (
    <Layout.Container>
      <Flex gap={isMobile ? '12px' : '24px'} align="center">
        <BackButton onClick={() => history.goBack()} />
        <Title primary>{data?.name}</Title>
      </Flex>
      <Flex
        justify="flex-end"
        style={{ marginTop: '8px', marginBottom: '20px' }}
      >
        <GroupButton
          options={[
            { label: t('app.informacoesDoJogo'), value: 'info' },
            { label: t('app.atividades'), value: 'activities' },
          ]}
          value={tab}
          onChange={(v) => setTab(v as string)}
        />
      </Flex>
      {tab === 'info' && data && <GameInfo {...data} />}
      {tab === 'activities' && data && <GameActivities {...data} />}
    </Layout.Container>
  );
}

export default ViewGame;
