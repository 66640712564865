import RouteAuthorized from 'components/route/route-authorized';
import { Switch, Route } from 'react-router-dom';
import AppPages from './app';
import AuthPages from './auth';

function Pages() {
  return (
    <Switch>
      <Route path="/auth">
        <AuthPages />
      </Route>
      <RouteAuthorized path="/">
        <AppPages />
      </RouteAuthorized>
     
    </Switch>
  );
}

export default Pages;
