import ModalFinishTour from 'components/modals/modal-finish-tour';
import ModalFinishTourLater from 'components/modals/modal-finish-tour-later';
import ModalStartInitialTour from 'components/modals/modal-start-initial-tour';
import useGuideInitialTour from 'core/features/guides/hooks/useGuideInitialTour';
import useTour from 'core/features/tour/hooks/useTour';
import Tour from 'components/tour';
import { useEffect, useState } from 'react';
import useMarkTourComplete from 'core/features/tour/hooks/useMarkTourComplete';
import { useHistory, useLocation } from 'react-router';
import ModalCompleteInitialTour from 'components/modals/modal-complete-initial-tour';

function InitialTour({ onClose, step }: { onClose: () => void, step?:number }) {
  const { data, isLoading } = useGuideInitialTour();
  const history = useHistory();
  let location = useLocation();
  const [current, setCurrent] = useState(step ?? 0);
  const { mutate: handleComplete } = useMarkTourComplete();
  const { data: tour } = useTour(data?.tours[current].id);
  const [isOpenStart, setIsOpenStart] = useState(step !== undefined ? false : true);
  const [isOpenFinishLater, setIsOpenFinishLater] = useState(false);
  const [isOpenFinish, setIsOpenFinish] = useState(false);
  const [isOpenComplete, setIsOpenComplete] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(step!== undefined ? true : false);

  useEffect(() => {
    if (tour) {
      if (tour.steps[0].url !== location.pathname) {
        history.push(tour.steps[0].url);
      }
    }
  }, [tour]);
  return (
    <>
      {isOpenStart ? (
        <ModalStartInitialTour
          onClose={() => {
            setIsOpenStart(false);
            setIsOpenFinishLater(true);
          }}
          onStart={() => {
            setIsOpenStart(false);
            setIsTourOpen(true);
          }}
        />
      ) : null}
      {isOpenFinishLater ? (
        <ModalFinishTourLater
          onClose={() => {
            setIsOpenFinishLater(false);
            onClose();
          }}
        />
      ) : null}

      {isOpenFinish ? (
        <ModalFinishTour
          title={tour?.name as string}
          message={tour?.message as string}
          backgroundImage={tour?.backgroundImage as string}
          congratsImage={tour?.congratsImage as string}
          current={current + 1}
          total={data?.tours.length ?? 0}
          onNext={() => {
            setCurrent(current + 1);
            setIsOpenFinish(false);
            setTimeout(() => {
              setIsTourOpen(true);
            }, 1000);
          }}
          onComplete={() => {
            setIsOpenFinish(false);
            setIsOpenComplete(true);
          }}
          onClose={() => {
            setIsOpenFinish(false);
            setIsOpenFinishLater(true);
          }}
        />
      ) : null}
      {isOpenComplete ? (
        <ModalCompleteInitialTour
          onClose={() => {
            setIsOpenComplete(false);
            onClose();
          }}
        />
      ) : null}
      {isTourOpen ? (
        <Tour
          stepsPreview={tour?.steps}
          onClose={(finished) => {
						if(!finished){
							setIsOpenFinishLater(true);
						}
					}}
          onFinish={() => {
            setIsTourOpen(false);
            handleComplete(data?.tours[current].id as string);
            setIsOpenFinish(true);
          }}
        />
      ) : null}
    </>
  );
}

export default InitialTour;
