import { useQuery } from 'react-query';
import axios from 'infra/http';
import { GravityDetails } from '../typings';
import { useEffect, useState } from 'react';

export interface UseGravityParams {
  tenantId?: string;
  initialDate?: string;
  finalDate?: string;
  search?: string;
  method?: string;
}
function useGravity(params: UseGravityParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchData = async (params: UseGravityParams): Promise<GravityDetails> => {
    const { data } = await axios.get<GravityDetails>(`/v2/inkluzen/gravity`, {
      params: {
        tenantId: params.tenantId,
        initialDate: params.initialDate,
        finalDate: params.initialDate,
        search: debouncedQuery,
        method: params.method,
      },
    });

    return data;
  };

  return useQuery(
    [
      `/v2/inkluzen/gravity`,
      { ...params, search: debouncedQuery }
    ],
    () => fetchData(params),
    {
      enabled: params.tenantId !== undefined,
    }
  );
}

export default useGravity;
