interface AttachmentInfo {
  fileName: string;
  url: string;
}

export function extractNonImageUrls(content: string): AttachmentInfo[] {
  const urlRegex = /(https?:\/\/[^\s"'<>]+)(?![^<]*>|[^<>]*<\/)/g;
  const imageRegex = /\.(jpeg|jpg|gif|png|svg)$/i;

  // Remove HTML tags
  const textContent = content.replace(/<[^>]*>/g, ' ');

  const allUrls = textContent.match(urlRegex) || [];
  
  return allUrls
    .filter(url => !imageRegex.test(url))
    .map(url => {
      // Extract file name from URL
      const fileName = url.split('/').pop() || '';
      // Decode URL-encoded characters
      return {
        fileName: decodeURIComponent(fileName),
        url: url
      };
    });
}
