import { useQuery } from 'react-query'
import axios from 'infra/http'
import { HelperDetails } from '../typings'
import { useEffect, useState } from 'react';

export interface UseHelpersParams {
  page?: number
  search?: string
  active?:boolean
  limit?:number;
  subClassifications?:string[];
  classifications?:string[];
  authors?:string[];
  published?:boolean;
  publishDate?:string;
  disabled?:boolean;
}

function useHelpers(params: UseHelpersParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchHelpers = async (
    params: UseHelpersParams
  ): Promise<{ data: HelperDetails[]; total: number }> => {
    const { data, headers } = await axios.get<HelperDetails[]>(`/v2/helpers/`, {
      params: {
        ...params,
        search: debouncedQuery,
      },
    })

    return { data: data, total: Number(headers['total']) }
  }

  return useQuery(
    [`/v2/helpers`,{...params,search:debouncedQuery}],
    () => fetchHelpers(params),
    {
      keepPreviousData: true,
      enabled:!params.disabled
    }
  )
}

export default useHelpers
