function BookIcon({ size = 40 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="40" height="40" fill="url(#book)" />
      <defs>
        <pattern
          id="book"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#book2" transform="scale(0.01)" />
        </pattern>
        <image
          id="book2"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACvElEQVR4nO3dP2sUQRzG8a9ipXaixSWNgoJiCsFeK7EQfAG+AMWAgmAp+A7E1kLByhRBbNTaxtZUooWVlUFINIL/RxZWCMMexD13fs/ePB+Yeubmm9vbbC4MmJmZaVgEloFnwGtgC0iVjxAHgbvAD4ENSGKjuAvApsALT6KjqKvAT4EXnYRH0XfGr44FrAHXgBPAPuqTIoJMOi5TX4HLwG7qliKC3OuIcabU5OKKB1nsuJtq3hkWFGS54zOj9stUaJCn2YTNB7gFBnmTTdjcTVlgkM/ZhPtLTDoixYOE/eIzEg4iZlRBjgKrwCeBRxpph6NZ6wqwUGB/euk7YRPjo8AGp55jfYdRRhNkVWBT04zj0YD701vfCcd0mUpTxsaA+9Nb3wmLL/Q/+dd1O8jAHESMg4hxEDEOIsZBxDiImLkNMg/f3doYcH966zvhisCGphnHXD06WWgf0KWRjvX2609D7U9vs0w4aX/KxnT52mzXPCmwP70Un3BkHESMg4hxEDEOIsZBxDiIGAcR4yBiHESMg4hxEDEOIsZBxDiIGAcR4yBiHESMg4hxEDEOIsZBxDiIGAcR4yBiHESMg4hxEDEOIsZBxDiIGAcR4yBiHESMg4hxEDEOIsZBxDiIGAcRIxXkGHATeA68A74I/ON/Ch4hQU4BT6YcgVT7GFw+4W3gu8ALT6KjeBAPdIM0l6zHwCXgCLC3xIJqNy3GK2ApenE16orxotIzCyXkMZoTDw5FL6pmeZBb0QuqXR7kZPSCapcH8Z1UsPyUnAPRC6rdWhbkbPSCavcgC3I/ekG1O58FaQ64Px29qJrt6Th+9T1wOHphNTvXcbf1AbgYvbCa3ZnyGOUlcAU47scpZTVnqD/043fC/x6y3S7gOvDNYZAI8tdSe5Lnb4dBIsj2825vtF9yeAtsOZCZmRlF/AGSqHQUzRQM5gAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
}

export default BookIcon;
