import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import React, { useRef, useState } from 'react';
import { MdOutlineCloudUpload } from 'react-icons/md';
import Swal from 'sweetalert2';
import axios from 'infra/http';

import './style.css';
import { IoClose } from 'react-icons/io5';
function CustomUpload({
  value,
  onChange,
  placeholder = 'Carregue PNG/JPG: 1MB, 1920x1080 px.',
}: {
  value: string;
  onChange: (v?: string) => void;
  placeholder?: string;
}) {
  const { colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState(
    value ?? placeholder
  );
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadAndAddImage = async (file: any) => {
    if (file !== undefined) {
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        Swal.fire({
          title: 'Aviso',
          text: 'Formato de imagem inválido',
          icon: 'info',
        });
        return null;
      }

      if (file.size > 1e6) {
        Swal.fire({
          title: 'Aviso',
          text: 'Tamanho da imagem inválido',
          icon: 'info',
        });
        return null;
      }
      const formData = new FormData();

      formData.append('file', file);

      try {
        const { data }: { data: { result: string } } = await axios.post(
          `/v1/file/generic-upload?folderKey=helpers/&bucketName=u4hero-files`,
          formData
        );
        onChange(data.result);
        setFileName(data.result);
      } finally {
      }
    }
  };

  return (
    <div className="custom-input" style={{height:isMobile ? '30px':'40px'}} onClick={handleButtonClick}>
      <input
        type="file"
        accept="image/png, image/jpeg"
        ref={fileInputRef}
        style={{ display: 'none', height: isMobile ? '30px' : '40px' }}
        onChange={(e) => uploadAndAddImage(e.target.files?.[0])}
      />
      <Flex gap={isMobile ? '8px' : '16px'} align={isMobile ? 'center' : 'end'}>
        <MdOutlineCloudUpload color="gray" size={24} />{' '}
        <p
          style={{
            color: fileName === placeholder ? colorGreyBorderAndFont : 'black',
            fontSize: isMobile ? '12px' : '16px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth:'70vw',
            textOverflow:'ellipsis'
          }}
        >
          {fileName}
        </p>
        {
          value ? <IoClose size={16} color="black" style={{alignSelf:'center'}} onClick={(e)=> {
            e.stopPropagation();
            onChange(undefined);
            setFileName(placeholder)
          }} /> : null
        }
      </Flex>
    </div>
  );
}

export default CustomUpload;
