import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Form, Tabs } from 'antd';
import Layout from 'components/layout';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import BackButton from 'components/buttons/back-button';
import Title from 'components/typography/title';
import InstitutionHeader from 'components/institution-header';
import { useEditTenantResources } from 'core/features/resources/hooks/useEditTenantResources';
import useResources from 'core/features/resources/hooks/useResources';
import useTenantResources from 'core/features/resources/hooks/useTenantResources';
import { NumberParam, useQueryParam } from 'use-query-params';
import ResourcesTab from 'ui/pages/app/mySchool/pages/Profiles/components/ResourcesTab';
import { useTenant } from 'core/features/tenants/hooks/useTenant';

interface Resource {
  id: string;
  name: {
    'pt-br': string;
    en: string;
    es: string;
  };
  description: {
    'pt-br': string;
    en: string;
    es: string;
  };
}

interface Group {
  name: string;
  children: Resource[];
}
interface Category {
  name: string;
  groups: Group[];
}

function ManagerSchoolResources() {
  const history = useHistory();
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [step, setStep] = useQueryParam('tab', NumberParam);
  const { tenantId } = useParams<{ tenantId: string }>();
  const { mutate: handleSave, isLoading: isLoadingSave } =
    useEditTenantResources();
  const { data: resourcesData, isLoading: isLoadingResources } = useResources({
    page: 1,
    limit: 1000,
  });
  const { data, isLoading } = useTenantResources({ tenantId });
  const { data: tenant } = useTenant(tenantId);
  const [items, setItems] = useState<any>();
  const [resources, setResources] = useState<string[]>([]);
  const [update, setUpdate] = useState<boolean>(false);

  const categories: Category[] = [
    {
      name: 'U4Hero',
      groups: [],
    },
    {
      name: 'Escola',
      groups: [],
    },
    {
      name: 'Organização',
      groups: [],
    },
    {
      name: 'Inkluzen',
      groups: [],
    },
    {
      name: 'B2C',
      groups: [],
    },
  ];

  useEffect(() => {
    if (resourcesData) {
      for (const resource of resourcesData.data) {
        const names = resource.tag.split('.');
        if (names.length === 3) {
          names.shift();
        }

        const group = categories[resource.type].groups.find(
          (item) => item.name === names[0]
        );
        if (!group) {
          categories[resource.type].groups.push({
            name: names[0],
            children: [
              {
                name: resource.name,
                id: resource.id,
                description: resource.description,
              },
            ],
          });
        } else {
          group.children.push({
            name: resource.name,
            id: resource.id,
            description: resource.description,
          });
        }
      }
      setItems(
        categories
          .filter((item) => item.groups.length > 0)
          .map((category, index) => ({
            key: index + 1,
            label: (
              <p
                style={{
                  fontSize: isMobile ? '14px' : '24px',
                  fontWeight: 700,
                  color:
                    index + 1 !== step ? colorGreyBorderAndFont : colorPrimary,
                  textTransform: 'uppercase',
                }}
              >
                {category.name}
              </p>
            ),
            children: (
              <ResourcesTab
                setResources={setResources}
                resources={resources}
                setUpdate={setUpdate}
                groups={category.groups}
              />
            ),
          }))
      );
    }
  }, [resourcesData, resources, step]);

  useEffect(() => {
    if (data) {
      setResources(data.map((item) => item?.id));
    }
  }, [data]);

  useEffect(() => {
    if (update && resources) {
      handleSave({
        id: tenantId,
        resources,
      });
      setUpdate(false);
    }
  }, [update, resources]);

  return (
    <Layout.Container>
      <Flex gap={isMobile ? '12px' : '24px'} align="center">
        <BackButton
          onClick={() => history.push(`/schools?tab=${tenant?.master ? 1 : 2}`)}
        />
        <Title primary>Recursos</Title>
      </Flex>
      {tenant ? (
        <InstitutionHeader image={'/lumi-lendo.png'} tenantName={tenant.name} />
      ) : null}

      <Tabs
        centered
        onTabClick={(event) => setStep(Number(event))}
        size="large"
        defaultActiveKey={step?.toString()}
        items={items}
      />
    </Layout.Container>
  );
}

export default ManagerSchoolResources;
