import { Col, Divider, Flex, Progress, Row, Select } from 'antd';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { useAprendizadoAdequado } from 'core/features/qedu/hooks/useAprendizadoAdequado';
import { useCurrentUser } from 'core/features/user/store';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import React from 'react';

function ProperLearning() {
  const { user } = useCurrentUser();
  const { data } = useAprendizadoAdequado({
    inep_id: user?.inep_id,
  });
  const { colorGreyBorderAndFont, colorPrimary } = useU4heroColors();

  return (
    <Flex
      vertical
      style={{ padding: '16px', height: '100%', position: 'relative' }}
      className="box-shadow-generic-card"
      justify="space-between"
    >
      <div>
        <Text
          style={{ position: 'absolute', top: 8, right: 16 }}
          size="sm"
          align="end"
          color={colorGreyBorderAndFont}
        >
          {data?.[0].ano}
        </Text>
        <SubTitle primary style={{ lineHeight: 'normal' }}>
          Aprendizado Adequado
        </SubTitle>

        <Text size="md" style={{ lineHeight: 'normal' }}>
          Percentuais de estudantes com aprendizado adequado.
        </Text>
      </div>
      <Flex gap={24} style={{ width: '100%' }} justify="center">
        {data?.map((item, index) => (
          <React.Fragment key={index}>
            <Flex vertical style={{ width: '50%' }}>
              <Text size="md" bold align="center" color={colorPrimary}>
                {item.ciclo_id === 'AF'
                  ? 'Anos Finais'
                  : item.ciclo_id === 'EM'
                  ? 'Ensino Médio'
                  : ''}
              </Text>
              <Text size="md">Português</Text>
              <Progress
                status="normal"
                percent={Math.ceil(parseFloat(item.lp_adequado) * 100)}
                percentPosition={{ align: 'end', type: 'outer' }}
              />
              <Text size="md">Matemática</Text>
              <Progress
                status="normal"
                percent={Math.ceil(parseFloat(item.mt_adequado) * 100)}
                percentPosition={{ align: 'end', type: 'outer' }}
              />
            </Flex>
            {index % 2 === 0 && index < data.length - 1 && (
              <Divider type="vertical" style={{ margin: 0, height: '100%' }} />
            )}
          </React.Fragment>
        ))}
      </Flex>
    </Flex>
  );
}

export default ProperLearning;
