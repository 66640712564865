import { useMediaQuery } from '@chakra-ui/react';
import { Col, Divider, Flex, Row, Button, Form, Spin, Image, List } from 'antd';
import SearchBar from 'components/V2/SearchBar';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useStudentsV2 from 'core/features/students/hooks/useStudentsV2';
import { StudentItem } from 'core/features/students/typings';
import { useCurrentUser } from 'core/features/user/store';
import emptyImage from 'assets/images/empty_selected.png';
import emptyStudents from 'assets/images/empty_question_bank.png';
import { Pluralizar } from 'infra/helpers/plural';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { t } from 'core/resources/strings';

function DoubleList({
  value,
  onChange,
}: {
  value: string[];
  onChange: (v: string[]) => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const [search, setSearch] = useState<string>('');
  const [selectedSearch, setSelectedSearch] = useState<string>('');
  const [selected, setSelected] = useState<StudentItem[]>([]);
  const [allStudents, setAllStudents] = useState<StudentItem[]>([]);
  const { tenantId } = useParams<{ tenantId: string }>();
  const { data, isLoading } = useStudentsV2({
    search,
    all: true,
    page: 1,
    tenantId,
  });

  useEffect(() => {
    if (data) {
      setAllStudents(data.data.filter((item) => !value.includes(item.id)));
      if (!search) {
        setSelected(data?.data.filter((item) => value.includes(item.id)));
      }
    }
  }, [data]);

  const handleAddToSelected = (item: StudentItem) => {
    setAllStudents((prevStudents: any) =>
      prevStudents?.filter((q: any) => q.id !== item.id)
    );
    const added = [...selected, item];
    setSelected(added);
    onChange(added.map((item) => item.id));
  };

  const handleRemoveFromSelected = (item: StudentItem) => {
    const filtered = selected.filter((q) => q.id !== item.id);
    setSelected(filtered);
    onChange(filtered.map((item) => item.id));

    setAllStudents((prevAllStudents: any) => [...prevAllStudents, item]);
  };

  const moveAllToSelected = () => {
    setSelected([...selected, ...allStudents]);
    onChange([...selected, ...allStudents].map((item) => item.id));
    setAllStudents([]);
  };

  const moveAllToQuestionBank = () => {
    setAllStudents((prevAllStudents: any) => [...prevAllStudents, ...selected]);
    setSelected([]);
    onChange([]);
  };

  return (
    <Row gutter={[24, isMobile ? 8 : 0]} align="stretch">
      <Col span={isMobile ? 24 : 11}>
        <Flex vertical gap="8px" style={{ height: '100%' }}>
          <p
            style={{
              fontSize: isMobile ? '18px' : '24px',
              color: colorPrimary,
              fontWeight: 700,
            }}
          >
            {t('app.todosOsEstudantes')}
          </p>
          <GenericCard height="100%">
            <Flex vertical gap="16px" style={{ width: '100%' }}>
              <Flex justify="space-between">
                <SearchBar
                  width="100%"
                  id="id-tour-create-class-search-all-students-list"
                  onSearch={setSearch}
                />
              </Flex>
              {allStudents.length > 0 && (
                <Flex justify="end">
                  <p>{`${allStudents.length} ${Pluralizar(
                    t('app.estudante'),
                    allStudents.length > 1
                  )}`}</p>
                </Flex>
              )}
              {allStudents.length ? (
                isMobile ? (
                  <List
                    itemLayout="vertical"
                    pagination={{
                      pageSize: 5,
                      position: 'bottom',
                      // @ts-ignore
                      align: 'center',
                    }}
                    dataSource={allStudents}
                    renderItem={(item) => (
                      <List.Item
                        key={item.id}
                        title={item.name}
                        extra={
                          <Button
                            type="primary"
                            size="small"
                            style={{ borderRadius: '8px' }}
                            onClick={() => handleAddToSelected(item)}
                          >
                            <IoIosArrowForward
                              style={{
                                transform: isMobile ? 'rotate(90deg)' : '',
                              }}
                              size={16}
                            />
                          </Button>
                        }
                      >
                        <List.Item.Meta
                          title={
                            <p
                              style={{
                                color: 'black',
                                fontSize: isMobile ? '14px' : '18px',
                                fontWeight: 400,
                              }}
                            >
                              {item.name}
                            </p>
                          }
                        />
                      </List.Item>
                    )}
                  />
                ) : (
                  <Flex
                    vertical
                    style={{
                      height: isMobile ? '40vh' : '60vh',
                      overflowY: 'scroll',
                      paddingRight: '16px',
                    }}
                    id="id-tour-create-class-all-students-list"
                  >
                    {allStudents?.map((item) => (
                      <Flex vertical>
                        <Flex justify="space-between" align="center">
                          <p
                            style={{
                              color: 'black',
                              fontSize: isMobile ? '14px' : '18px',
                              fontWeight: 400,
                            }}
                          >
                            {item.name}
                          </p>
                          <Button
                            type="primary"
                            size="small"
                            style={{ borderRadius: '8px' }}
                            onClick={() => handleAddToSelected(item)}
                          >
                            <IoIosArrowForward
                              style={{
                                transform: isMobile ? 'rotate(90deg)' : '',
                              }}
                              size={16}
                            />
                          </Button>
                        </Flex>
                        <Divider />
                      </Flex>
                    ))}
                  </Flex>
                )
              ) : isLoading ? (
                <Spin />
              ) : (
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  style={{ width: '100%' }}
                >
                  <Image
                    preview={false}
                    src={emptyStudents}
                    alt={'Lista de estudantes vazia'}
                  />
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#7B7B7B',
                      textAlign: 'center',
                      fontWeight: 400,
                      maxWidth: '313px',
                    }}
                  >
                    {t('app.naoExistemMaisEstudantesParaExibicao')}
                  </p>
                </Flex>
              )}
            </Flex>
          </GenericCard>
        </Flex>
      </Col>
      <Col span={isMobile ? 24 : 2} style={{ alignSelf: 'center' }}>
        <Flex
          vertical={!isMobile}
          align="center"
          justify="center"
          gap={isMobile ? '32px' : '48px'}
        >
          <Button
            type="default"
            style={{
              borderRadius: '8px',
              border: `2px solid ${colorPrimary}`,
              padding: '8px',
              height: '100%',
            }}
            onClick={moveAllToSelected}
            id="id-tour-create-class-move-all-to-selected-button"
          >
            <MdKeyboardDoubleArrowRight
              style={{ transform: isMobile ? 'rotate(90deg)' : '' }}
              color={colorPrimary}
              size={20}
            />
          </Button>
          <Button
            type="default"
            style={{
              borderRadius: '8px',
              border: `2px solid ${colorPrimary}`,
              padding: '8px',
              height: '100%',
            }}
            disabled={selected?.length === 0}
            onClick={moveAllToQuestionBank}
            id="id-tour-create-class-move-all-to-unselected-button"
          >
            <MdKeyboardDoubleArrowLeft
              style={{ transform: isMobile ? 'rotate(90deg)' : '' }}
              color={colorPrimary}
              size={20}
            />
          </Button>
        </Flex>
      </Col>
      <Col span={isMobile ? 24 : 11}>
        <Flex vertical gap="8px" style={{ height: '100%' }}>
          <p
            style={{
              fontSize: isMobile ? '18px' : '24px',
              color: colorPrimary,
              fontWeight: 700,
            }}
          >
            {t('app.selecionados')}
          </p>
          <GenericCard height="100%">
            <Flex vertical gap="16px" style={{ width: '100%' }}>
              <Flex justify="space-between">
                <SearchBar
                  width="100%"
                  id="id-tour-create-class-search-selected-students-list"
                  onSearch={setSelectedSearch}
                />
              </Flex>
              {selected.length > 0 && (
                <Flex justify="end">
                  <p>{`${selected.length} ${Pluralizar(
                    t('app.estudante'),
                    selected.length > 1
                  )}`}</p>
                </Flex>
              )}
              {selected?.length ? (
                <Flex
                  vertical
                  style={{
                    height: isMobile ? '' : '60vh',
                    overflowY: 'scroll',
                    paddingRight: '16px',
                  }}
                  id="id-tour-create-class-selected-students-list"
                >
                  {selectedSearch
                    ? selected
                        ?.filter((item) =>
                          item.name
                            .toLowerCase()
                            .includes(selectedSearch.toLowerCase())
                        )
                        .map((item: any) => (
                          <Flex vertical>
                            <Flex
                              justify="space-between"
                              align="center"
                              gap="35px"
                            >
                              <Button
                                type="primary"
                                size="small"
                                style={{ borderRadius: '8px' }}
                                onClick={() => handleRemoveFromSelected(item)}
                              >
                                <IoIosArrowBack
                                  style={{
                                    transform: isMobile ? 'rotate(90deg)' : '',
                                  }}
                                  size={16}
                                />
                              </Button>
                              <p
                                style={{
                                  color: 'black',
                                  fontSize: isMobile ? '14px' : '18px',
                                  fontWeight: 400,
                                }}
                              >
                                {item.name}
                              </p>
                            </Flex>
                            <Divider />
                          </Flex>
                        ))
                    : selected?.map((item: any) => (
                        <Flex vertical>
                          <Flex
                            justify="space-between"
                            align="center"
                            gap="35px"
                          >
                            <Button
                              type="primary"
                              size="small"
                              style={{ borderRadius: '8px' }}
                              onClick={() => handleRemoveFromSelected(item)}
                            >
                              <IoIosArrowBack
                                style={{
                                  transform: isMobile ? 'rotate(90deg)' : '',
                                }}
                                size={16}
                              />
                            </Button>
                            <p
                              style={{
                                color: 'black',
                                fontSize: isMobile ? '14px' : '18px',
                                fontWeight: 400,
                              }}
                            >
                              {item.name}
                            </p>
                          </Flex>
                          <Divider />
                        </Flex>
                      ))}
                </Flex>
              ) : (
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  style={{ width: '100%' }}
                >
                  <Image
                    preview={false}
                    src={emptyImage}
                    alt="Lumi empurrando um ponto de interrogação"
                  />
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#7B7B7B',
                      textAlign: 'center',
                      fontWeight: 400,
                      maxWidth: '313px',
                    }}
                  >
                    {t('app.ateAgoraNenhumEstudanteEscolhido')}
                  </p>
                </Flex>
              )}
            </Flex>
          </GenericCard>
        </Flex>
      </Col>
    </Row>
  );
}
export default DoubleList;
