import { useQuery } from 'react-query';
import axios from 'infra/http';

function useHelpersAuthors() {
  const fetchData = async (): Promise<{ id: string; name: string }[]> => {
    const { data } = await axios.get<{ id: string; name: string }[]>(
      `/v2/helpers/authors`
    );

    return data;
  };

  return useQuery([`/v2/helpers/authors`], () => fetchData());
}

export default useHelpersAuthors;
