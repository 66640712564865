import { useQuery } from "react-query";
import axios from "infra/http";
import { ProfileItem } from "../typings";

export interface UseProfilesParams {
  tenantId?:string;
}
function useProfiles(params:UseProfilesParams) {
  return useQuery(`/v1/profile`, async (): Promise<ProfileItem[]> => {
    const { data } = await axios.get(`/v1/profile`,{
      params:{
        tenantId:params?.tenantId
      }
    });

    return data;
  });
}

export default useProfiles;
