import http from "infra/http";
import { useMutation } from "react-query";
import { PracticeForm } from "../typings";


export function useSavePractice() {
  return useMutation(
    (data: PracticeForm) => {
      if (data.id) {
        return http.put(`/v2/practices/`, data);
      }
      return http.post(`/v2/practices`, data);
    },
    { retry: false }
  );
}
