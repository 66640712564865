import { useCallback } from 'react';
import { useCurrentUser } from "core/features/user/store";

export default function useCheckPermissions() {

    const { user } = useCurrentUser();

    const check = useCallback((permissao:string[]) => {

        const permissoes = user?.resources ?? []; 
        const possuiPermissao = permissao.filter((item:any)=> permissoes.includes(item)).length;       
        return possuiPermissao > 0;

    }, [user]);

    return [check];
}