import { useQuery } from 'react-query';
import axios from 'infra/http';
import { GravityDetails } from '../typings';
import { useEffect, useState } from 'react';

export interface UseFrequentSymptomsParams {
  integrationId?: string;
  initialDate?: string;
  finalDate?: string;
  search?: string;
}
function useFrequentSymptoms(params: UseFrequentSymptomsParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchData = async (
    params: UseFrequentSymptomsParams
  ): Promise<GravityDetails> => {
    const { data } = await axios.get<GravityDetails>(
      `/v2/inkluzen/frequent-symptoms`,
      {
        params: {
          integrationId: params.integrationId,
          initialDate: params.initialDate,
          finalDate: params.finalDate,
          search: debouncedQuery,
        },
      }
    );

    return data;
  };

  return useQuery(
    [`/v2/inkluzen/frequent-symptoms`, { ...params, search: debouncedQuery }],
    () => fetchData(params)
  );
}

export default useFrequentSymptoms;
