import { useState } from 'react';
import { notificateError } from 'infra/helpers/notifications';
import { PostFeedbackForm } from 'core/features/posts/typings';
import Feedback from 'components/feedback';
import { useSavePostFeedback } from 'core/features/posts/hooks/useSavePostFeedback';
import { t } from 'core/resources/strings';

function PostFeedback({ id }: { id: string }) {
  const [submited, setSubmited] = useState(false);
  const { mutate: handleSave, isLoading } = useSavePostFeedback();

  const onSubmit = (values: PostFeedbackForm) => {
    handleSave(
      { ...values, postId: id },
      {
        onSuccess: () => {
          setSubmited(true);
        },
        onError: (err: any) => {
          notificateError(t('app.erroAoSalvarFeedback'));
        },
      }
    );
  };
  return (
    <Feedback
      onSubmit={onSubmit}
      askMessage={t('app.oQueAchouDasNovidades')}
      askForTextMessage={t('app.paraNosAjudarAMelhorarPorFavorExpliqueOMotivoDaSuaAvaliacao')}
      placeholderTextArea={t('app.expliqueOMotivoDaSuaAvaliacaoComOMaximoDeDetalhesPossivel')}
      submited={submited}
      isLoadingSubmit={isLoading}
      requireText
    />
  );
}

export default PostFeedback;
