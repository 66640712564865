import { Select } from 'antd';
import { useMediaQuery } from '@chakra-ui/react';

interface SelectCategoryProps {
  value: string;
  onChange: (v: string) => void;
}

function SelectCategory({ value, onChange }: SelectCategoryProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Select
      id="id-tour-create-blog-post-input-category"
      placeholder="Defina uma categoria que se aplique ao assunto"
      options={[
        {
          label: 'Educação',
          value: 'Educação',
        },
        {
          label: 'Jogos',
          value: 'Jogos',
        },
        {
          label: 'Socioemocional',
          value: 'Socioemocional',
        },
        {
          label: 'Saúde Mental',
          value: 'Saúde Mental',
        },
        {
          label: 'Tecnologia',
          value: 'Tecnologia',
        },
      ]}
      onChange={onChange}
      value={value}
      size={isMobile ? 'middle' : 'large'}
    />
  );
}

export default SelectCategory;
