import { useMutation } from "react-query";
import http from "infra/http";
import { UserSimpleForm } from "../typings/form";

export function useSaveUser(id?:string) {
  return useMutation(
    (data: UserSimpleForm) => {
      const body: UserSimpleForm = {
        birthday: data.birthday || null,
        document: data.document || null,
        email: data.email,
        gender: data.gender || null,
        name: data.name,
        profiles: data.profiles,
        imageUrl: data.imageUrl || null,
        tenantId:data.tenantId || undefined,
        password: data.password || undefined
      };

      if (id) {
        return http.put(`/v2/users/${id}`, body);
      }

      return http.post(`v2/users`, body);
    },
    { retry: false }
  );
}
