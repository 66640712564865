import { Tour as TourAntd, TourProps } from 'antd';
import useMarkTourComplete from 'core/features/tour/hooks/useMarkTourComplete';
import useTour from 'core/features/tour/hooks/useTour';
import { Step, TourDetails } from 'core/features/tour/typings';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

function Tour({
  id,
  onClose,
  onFinish,
  stepsPreview,
}: {
  id?: string;
  onClose: (finished:boolean) => void;
  onFinish?: () => void;
  stepsPreview?: Step[];
}) {
  const { data } = useTour(id);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState<TourProps['steps']>([]);
  const [refresh, setRefresh] = useState(false);
  const { mutate: handleComplete } = useMarkTourComplete();

  useEffect(() => {
    if (data) {
      prepareSteps(data.steps);
      setOpen(true);
    }
  }, [data]);

  useEffect(() => {
    if (stepsPreview) {
      prepareSteps(stepsPreview);
      setOpen(true);
    }
  }, [stepsPreview]);

  const prepareSteps = (steps: Step[]) => {
    const newSteps = steps.map((item, index) => ({
      title: item.title,
      scrollIntoViewOptions: false,
      description: item.description,
      target: document.getElementById(item.referenceId),
      cover: item.image ? <img src={item.image} /> : null,
      nextButtonProps: {
        onClick: () => {
          const nextStep = steps?.[index + 1];
          if (nextStep && nextStep.url !== item.url) {
            history.push(nextStep.url);
            setOpen(false);
            setTimeout(() => {
              setRefresh(true);
              setOpen(true);
            }, 1000);
          }
        },
      },
      prevButtonProps: {
        onClick: () => {
          const prevStep = steps?.[index - 1];
          if (prevStep && prevStep.url !== item.url) {
            history.goBack();
            setOpen(false);
            setTimeout(() => {
              setRefresh(true);
              setOpen(true);
            }, 1000);
          }
        },
      },
    }));
    setSteps(newSteps);
  };
  useEffect(() => {
    if (refresh) {
      if (data) {
        prepareSteps(data.steps as Step[]);
      }
      if (stepsPreview) {
        prepareSteps(stepsPreview);
      }
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <>
      <TourAntd
        open={open}
        onClose={() => {
          onClose(step + 1 === steps?.length);
          setOpen(false);
          setStep(0);
        }}
        steps={steps}
        onChange={(current) => {
          setStep(current);
        }}
        onFinish={() => {
          if (!stepsPreview && id) {
            handleComplete(id);
          }
          onFinish && onFinish();
        }}
        current={step}
        // scrollIntoViewOptions={true}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />
      
    </>
  );
}

export default Tour;
