import { useQuery } from "react-query";
import axios from "infra/http";
import { PostDetails } from "../typings";

function usePost(id?: string) {
  const fetchData = async (id: string): Promise<PostDetails> => {
    const { data } = await axios.get<PostDetails>(`/v2/posts/${id}`);

    return data;
  };

  return useQuery([`/v2/posts/${id}`], () => fetchData(id!), {
    enabled: !!id,
  });
}

export default usePost;
