import { Flex} from 'antd';
import Button from 'components/buttons/button';
import { useHistory } from 'react-router';
import ProfilesTable from 'ui/pages/app/mySchool/pages/Profiles/components/ProfilesTable';

function ProfilesTab() {
  const history = useHistory();
  return (
    <>
      {' '}
      <Flex style={{ marginTop: '16px', marginBottom: '17px' }} justify="end">
        <Button
          id="id-tour-settings-u4hero-create-profile-button-add-profile"
          size="md"
          onClick={() => history.push('/settings-u4hero/profiles/create')}
        >
          CRIAR PERFIL
        </Button>
      </Flex>
      <ProfilesTable />
    </>
  );
}

export default ProfilesTab;
