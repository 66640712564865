import { Flex, Modal } from 'antd';
import { useMediaQuery } from '@chakra-ui/media-query';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import backgroundQrCode from 'assets/images/background-qr-code.png';
import qrTextBox from 'assets/images/qr-text-box.png';
import instructionsQrCode from 'assets/images/instructions-qr-code.png';
import Title from 'components/typography/title';
import './index.css';
import Text from 'components/typography/text';
import { IoClose } from 'react-icons/io5';
import { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode';
import Button from 'components/buttons/button';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import { t } from 'core/resources/strings';

function ModalQrCode({
  onClose,
  type,
  id,
}: {
  onClose: () => void;
  type: 'educator' | 'student';
  id?: string;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const url =
    type === 'educator'
      ? window.location.origin
      : process.env.REACT_APP_U4PLAY_URL;

  useEffect(() => {
    if (id && canvasRef.current) {
      QRCode.toCanvas(
        canvasRef.current,
        `${url}/auth/quick-access/${id}`,
        { width: 280 },
        (error) => {
          if (error) console.error(error);
          console.log('QR code generated!');
        }
      );

      QRCode.toDataURL(`${url}/auth/quick-access/${id}`, {
        width: 280,
        margin: 1,
        errorCorrectionLevel: 'H',
      })
        .then((url) => {
          setQrCodeUrl(url);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [id, url]);

  const handleDownload = () => {
    if (qrCodeUrl) {
      const link = document.createElement('a');
      link.href = qrCodeUrl;
      link.download = `qrcode-${type}-${id}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Modal
      open={true}
      onCancel={onClose}
      closeIcon={null}
      width={isMobile ? '90%' : 1003}
      height={isMobile ? '90%' : 652}
      footer={[
        <Flex align="center" justify="center">
          <Button
            id="id-tour-modal-practice-suggestions-close-button"
            size="md"
            onClick={onClose}
          >
            {t('app.fechar')}
          </Button>
        </Flex>,
      ]}
      style={{
        backgroundColor: '#8A5FC4',
        backgroundImage: `url(${backgroundQrCode})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        borderRadius: '10px',
      }}
      bodyStyle={{
        padding: 0,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className="modal-qr-code"
    >
      <Title
        color="white"
        style={{
          borderRadius: 10,
          borderRight: isMobile ? undefined : '2px solid #FFF',
          borderBottom: isMobile ? undefined : '2px solid #FFF',
          borderLeft: isMobile ? undefined : '2px solid #FFF',
          padding: isMobile ? '0px' : '12px 24px',
          position: 'absolute',
          top: isMobile ? '8px' : '0',
          left: isMobile ? '8px' : '50%',
          transform: isMobile ? undefined : 'translateX(-50%)',
          whiteSpace: 'nowrap',
        }}
      >
        {t('app.perfilDe')} {type === 'educator' ? t('app.educador').toUpperCase() : t('app.estudante').toUpperCase()}
      </Title>
      <div
        style={{
          background: colorPrimary,
          borderRadius: '50%',
          width: isMobile ? '20px' : '30px',
          height: isMobile ? '20px' : '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '8px',
          right: '8px',
          cursor: 'pointer',
        }}
        onClick={onClose}
      >
        <IoClose size={22} color="white" />
      </div>

      <Flex
        gap={'75px'}
        justify={'center'}
        align={'center'}
        style={{
          width: '100%',
          height: '100%',
          paddingTop: '60px',
        }}
      >
        <Flex vertical align={'center'} justify={'center'} gap="16px">
          <img src={qrTextBox} width="323px" alt="qr-text-box" />
          <canvas ref={canvasRef} style={{ background: 'white' }} />
          <Text
            size="md"
            color="white"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={handleDownload}
          >
            {t('app.baixarQrCode')}
          </Text>
        </Flex>
        {!isMobile && (
          <Flex
            vertical
            align={'center'}
            gap="12px"
            style={{
              maxWidth: '382px',
              border: '2px solid white',
              borderRadius: '20px',
              padding: '12px 16px',
            }}
          >
            <img
              src={instructionsQrCode}
              alt="instructions-qr-code"
              width="219px"
            />
            <Text size="md" color="white" style={{ width: '100%' }}>
              {t('app.instrucoesQrCode')}
              <ol
                style={{
                  paddingLeft: '20px',
                  marginTop: '8px',
                  marginBottom: '0',
                }}
              >
                <li style={{ marginBottom: '4px' }}>{t('app.instrucao1')}</li>
                <li>{t('app.instrucao2')}</li>
                <li>{t('app.instrucao3')}</li>
              </ol>
            </Text>
          </Flex>
        )}
      </Flex>
    </Modal>
  );
}

export default ModalQrCode;
