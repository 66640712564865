import React, { useRef, useState, ReactNode } from 'react';
import {
  Button,
  Carousel as AntdCarousel,
  CarouselProps,
  Checkbox,
  Divider,
  Flex,
} from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { FaLeftLong, FaRightLong } from 'react-icons/fa6';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Text from 'components/typography/text';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useMediaQuery } from '@chakra-ui/react';
import './index.css';

interface GameCarouselProps extends CarouselProps {
  name: string;
  onClickCheckbox:(e:CheckboxChangeEvent)=> void;
  checked:boolean;
  children?: ReactNode;
}

const GameCarousel: React.FC<GameCarouselProps> = ({
  children,
  name,
  onClickCheckbox,
  checked,
  ...props
}) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef<CarouselRef>(null);
  const { colorPrimary, colorGreyCard } = useU4heroColors();

  const handleBeforeChange = (current: number, next: number) => {
    setCurrentSlide(next);
  };

  const totalSlides = React.Children.count(children);
  const slidesToScroll = isMobile ? 2:5;
  return (
    <Flex vertical gap="12px">
      <Flex gap="16px" align="center" justify="center">
        <Checkbox onChange={onClickCheckbox} checked={checked}>
          <Text size="lg" color={colorPrimary} style={{ fontWeight: 700 }}>
            {name}
          </Text>
        </Checkbox>
        <div
          style={{
            width: '100%',
            height: '1px',
            background: colorPrimary,
            strokeWidth: '1px',
          }}
        ></div>
        <Flex gap="16px">
          <Button
            style={{ background: colorGreyCard }}
            disabled={currentSlide === 0}
            onClick={() => {
              carouselRef.current?.prev();
            }}
          >
            <FaLeftLong color="white" />
          </Button>
          <Button
            style={{ background: colorGreyCard }}
            onClick={() => carouselRef.current?.next()}
            disabled={currentSlide + slidesToScroll  >= totalSlides}
          >
            <FaRightLong color="white" />
          </Button>
        </Flex>
      </Flex>
      <AntdCarousel
        ref={carouselRef}
        slidesToShow={slidesToScroll}
        slidesToScroll={slidesToScroll}
        beforeChange={handleBeforeChange}
        {...props}
        dots={false}
        infinite={false}
        className='game-carousel'
  
      >
        {children}
      </AntdCarousel>
    </Flex>
  );
};

export default GameCarousel;
