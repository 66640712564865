import { Flex } from 'antd';
import Text from 'components/typography/text';
import { ReactNode } from 'react';
function PracticeDetail({
  icon,
  label,
  text,
}: {
  icon: string;
  label: string;
  text: string | ReactNode;
}) {
  return (
    <Flex
      vertical
      className="box-shadow-generic-card"
      style={{ padding: '16px 8px', minHeight: '100%' }}
      gap="8px"
    >
      <img src={icon} alt="icon" width="80px" height="80px" />
      <Flex gap={10}>
        <div
          style={{ width: '3px', height: '28px', backgroundColor: '#ED810F' }}
        />
        <Text size="md" bold>
          {label}
        </Text>
      </Flex>
      <Text size="md">{text}</Text>
    </Flex>
  );
}

export default PracticeDetail;
