import { useMediaQuery } from '@chakra-ui/media-query';
import { Anchor, Col, Collapse, Divider, Flex, Popover, Row } from 'antd';
import Star from 'components/star';
import usePractice from 'core/features/practices/hooks/usePractice';
import { t } from 'core/resources/strings';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { forwardRef, useState } from 'react';
import { GoAlertFill } from 'react-icons/go';
import {
  PiClockAfternoonLight,
  PiDownloadSimple,
  PiFileDocLight,
  PiHashLight,
  PiPencilThin,
  PiUsersFourLight,
} from 'react-icons/pi';
import { useHistory, useParams } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import './style.css';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useDeletePracticeAdaptation } from 'core/features/practices/hooks/useDeletePracticeAdaptation';
import {
  notificateError,
  notificateSucess,
  showConfirm,
} from 'infra/helpers/notifications';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import goalIcon from 'assets/images/goals-icon.png';
import observationIcon from 'assets/images/observation-icon.png';
import clock from 'assets/images/practice-details/clock.png';
import backpack from 'assets/images/practice-details/backpack.png';
import brain from 'assets/images/practice-details/brain.png';
import hashtag from 'assets/images/practice-details/hashtag.png';
import children from 'assets/images/practice-details/children.png';
import folder from 'assets/images/practice-details/folder.png';
import rocket from 'assets/images/practice-details/rocket.png';
import weights from 'assets/images/practice-details/weights.png';
import CustomCarousel from 'components/custom-carousel';
import PracticeDetail from 'ui/pages/app/practices/pages/Practice/components/practiceDetail';
import { TfiInfo } from 'react-icons/tfi';
import { extractNonImageUrls } from 'infra/helpers/urlExtractor'; // Assume this function exists
import { MdExpandMore } from 'react-icons/md';

const moment = require('moment');
require('moment/locale/pt-br');

const Practice = forwardRef<HTMLDivElement>((props, ref) => {
  const {
    colorPrimary,
    colorGreyBorderAndFont,
    colorSecondary,
    colorPrimaryMedium,
  } = useU4heroColors();
  const history = useHistory();
  const [check] = useCheckPermissions();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const params = useParams<{ id: string }>();
  const { data, refetch } = usePractice(params.id);
  const createdAt = moment(data?.createdAt);
  const { mutate: handleDelete } = useDeletePracticeAdaptation();
  const [attachmentsCount, setAttachmentsCount] = useState(3);

  const onDelete = (id: string) => {
    showConfirm({
      message: t('app.temCertezaQueDesejaDesfazerAsAlteracoes'),
      colorPrimary,
      isMobile,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        handleDelete(id, {
          onSuccess: () => {
            notificateSucess(t('app.alteracoesDesfeitasComSucesso'));
            refetch();
          },
          onError: () => {
            notificateError(t('app.erroAoDesfazerAlteracoes'));
          },
        });
      }
    });
  };

  const anchorItems = [
    {
      key: 'pre-requisites',
      href: '#pre-requisites',
      title: 'Pré-Requisitos',
    },
    {
      key: 'previous-context',
      href: '#previous-context',
      title: 'Contexto Prévio',
    },
    {
      key: 'title',
      href: '#title',
      title: data?.title ?? '',
    },
    {
      key: 'goal',
      href: '#goal',
      title: 'Objetivo',
    },
    {
      key: 'observation-points',
      href: '#observation-points',
      title: 'Pontos de Observação',
    },
    {
      key: 'inclusion-guide',
      href: '#inclusion-guide',
      title: 'Orientações para Inclusão',
    },
    {
      key: 'details',
      href: '#details',
      title: 'Detalhes',
    },
    {
      key: 'attachments',
      href: '#attachments',
      title: 'Anexos',
    },
  ];

  const detailItems = [
    {
      label: t('app.duracao'),
      text: `${t('app.previstoParaDurar')} ${data?.duration} ${t(
        'app.minutos'
      )}.`,
      icon: clock,
    },
    {
      label: t('app.faixaEtaria'),
      text: `${t('app.aPartirDe')} ${data?.maxAge} ${t(
        'app.anos'
      ).toLowerCase()}.`,
      icon: children,
    },
    {
      label: t('app.codigoBNCC'),
      text: (
        <Flex gap="8px">
          <Text size="md">{`(${data?.bnccCode})`}</Text>
          <Popover
            content={
              <Text size="md" color="white">
                {data?.bnccCode}
              </Text>
            }
          >
            <Flex
              align="center"
              justify="center"
              style={{
                backgroundColor: colorPrimary,
                borderRadius: '50px',
                width: '18px',
                height: '18px',
                padding: '2px',
              }}
            >
              <TfiInfo style={{ color: 'white', fontWeight: 900 }} />
            </Flex>
          </Popover>
        </Flex>
      ),
      icon: hashtag,
    },
    {
      label: t('app.materiais'),
      text: `${data?.resources}`,
      icon: backpack,
    },
    {
      label: t('app.competencia'),
      text: `${data?.competence1.name['pt-br']}`,
      icon: brain,
    },
    {
      label: t('app.dificuldade'),
      text: `${data?.dificulty}`,
      icon: weights,
    },
    {
      label: t('app.componenteCurricular'),
      text: `${data?.subject}`,
      icon: rocket,
    },
    {
      label: t('app.trilhaSugerida'),
      text: `${data?.suggestedTag}`,
      icon: folder,
    },
  ];

  const content = data?.adaptation
    ? data.adaptation.content
    : data?.content ?? '';
  const attachments = extractNonImageUrls(content);

  const cleanContent = (content: string) => {
    let cleanedContent = content;
    attachments.forEach((attachment) => {
      cleanedContent = cleanedContent.replace(attachment.url, '');
    });
    return cleanedContent;
  };

  return (
    <div ref={ref} className="practice-container">
      <div style={{ position: 'relative' }}>
        <img
          alt={`banner ${data?.title ?? ''}`}
          src={data?.banner}
          style={{
            borderRadius: '10px',
            width: '100%',
            height: isMobile ? '156px' : '275px',
            objectFit: 'cover',
          }}
        />
        {!isMobile ? (
          <div style={{ position: 'absolute', bottom: -10, left: -20 }}>
            <Star color={colorPrimary} size={'70px'} fontSize="16px">
              {t(`app.${data?.analysisEnvironment.name}`)}
            </Star>
          </div>
        ) : (
          <div
            style={{
              borderRadius: '10px 5px 5px 0px',
              fontSize: isMobile ? '12px' : '16px',
              position: 'absolute',
              paddingLeft: isMobile ? '14px' : '16px',
              paddingRight: isMobile ? '14px' : '16px',
              background: colorPrimary,
              color: 'white',
              top: 0,
            }}
          >
            {t(`app.${data?.analysisEnvironment.name}`)}
          </div>
        )}
        {!isMobile && (
          <Flex
            gap={8}
            style={{
              position: 'absolute',
              top: 8,
              right: 0,
            }}
          >
            <Flex
              style={{
                background: colorPrimary,
                borderRadius: '5px',
                padding: '8px',
              }}
              gap={8}
            >
              <PiUsersFourLight size={24} color="white" />
              <Text size="md" color="white">{`${data?.maxAge} ${t(
                'app.anosMais'
              )}`}</Text>
            </Flex>
            <Flex
              style={{
                background: colorPrimary,
                borderRadius: '5px',
                padding: '8px',
              }}
              gap={8}
            >
              <PiHashLight size={24} color="white" />
              <Text size="md" color="white">
                {data?.bnccCode}
              </Text>
            </Flex>
            <Flex
              style={{
                background: colorPrimary,
                borderRadius: '5px',
                padding: '8px',
              }}
              gap={8}
            >
              <PiClockAfternoonLight size={24} color="white" />
              <Text size="md" color="white">
                {data?.duration} min
              </Text>
            </Flex>
          </Flex>
        )}

        <Flex
          style={{
            width: '100%',
            position: 'relative',
            bottom: isMobile ? 18 : 10,
          }}
          justify="center"
        >
          <div
            style={{
              borderRadius: '5px 5px 0px 0px',
              fontSize: isMobile ? '12px' : '16px',
              paddingLeft: isMobile ? '14px' : '16px',
              paddingRight: isMobile ? '14px' : '16px',
              background: colorSecondary,
              fontWeight: 700,
              color: 'white',
              textTransform: 'uppercase',
            }}
          >
            {data?.target} - {t(`app.${data?.practiceModality.name}`)}
          </div>
        </Flex>
      </div>

      <p
        style={{
          fontSize: isMobile ? '14px' : '18px',
          color: colorGreyBorderAndFont,
          textAlign: 'end',
        }}
      >
        {createdAt.format('DD [de] MMMM [de] YYYY')}
        <br />
        {t('app.autor')}: {data?.author}
      </p>

      <Row gutter={[24, 0]}>
        <Col span={isMobile ? 24 : 19}>
          <Flex vertical gap={8}>
            <SubTitle primary align="center" id="pre-requisites">
              Pré-Requisitos
            </SubTitle>
            <Text size="md">{data?.preRequisites}</Text>
            <SubTitle primary align="center" id="previous-context">
              Contexto Prévio
            </SubTitle>
            <Text size="md">{data?.previousContext}</Text>
            <SubTitle primary align="center" id="title">
              {data?.title}
            </SubTitle>
          </Flex>
          <div
            dangerouslySetInnerHTML={{
              __html: cleanContent(content ?? ''),
            }}
          />
          <Flex vertical gap={32} style={{ marginTop: '32px' }}>
            <Flex
              style={{
                background: '#F5F5F5',
                padding: '16px',
                borderRadius: '10px',
              }}
              vertical={isMobile}
            >
              <Flex vertical gap={24}>
                <Flex align="center" gap={12}>
                  <img src={goalIcon} />
                  <SubTitle primary id="goal">
                    Objetivo
                  </SubTitle>
                </Flex>
                <ul style={{ paddingLeft: '20px', margin: 0 }}>
                  {data?.goal
                    .split('.')
                    .filter((item) => item.trim())
                    .map((item, index) => (
                      <li key={index} style={{ marginBottom: '8px' }}>
                        <Text size="md" style={{ display: 'inline' }}>
                          {item}.
                        </Text>
                      </li>
                    ))}
                </ul>
              </Flex>

              <Divider
                type={isMobile ? 'horizontal' : 'vertical'}
                style={{ minHeight: isMobile ? '1px' : '200px' }}
              />
              <Flex vertical gap={24}>
                <Flex align="center" gap={12}>
                  <img src={observationIcon} />
                  <SubTitle primary id="observation-points">
                    {t('app.pontosDeObservacao')}
                  </SubTitle>
                </Flex>
                <ul style={{ paddingLeft: '20px', margin: 0 }}>
                  {data?.observationPoints
                    .split('.')
                    .filter((item) => item.trim())
                    .map((item, index) => (
                      <li key={index} style={{ marginBottom: '8px' }}>
                        <Text size="md" style={{ display: 'inline' }}>
                          {item}.
                        </Text>
                      </li>
                    ))}
                </ul>
              </Flex>
            </Flex>

            <Collapse
              expandIconPosition="end"
              style={{ width: '100%' }}
              defaultActiveKey={'1'}
            >
              <Collapse.Panel
                key={'1'}
                header={
                  <Text size="md" style={{ width: '100%' }}>
                    {t('app.orientacoesParaInclusao')}
                  </Text>
                }
              >
                <div
                  id="inclusion-guide"
                  style={{ padding: '16px' }}
                  dangerouslySetInnerHTML={{
                    __html: data?.adaptation
                      ? data.adaptation.inclusionGuide
                      : data?.inclusionGuide ?? '',
                  }}
                />
              </Collapse.Panel>
            </Collapse>
          </Flex>
          <Flex vertical gap={8} style={{ marginTop: '32px' }}>
            <SubTitle primary align="center" id="details">
              {t('app.detalhes')}
            </SubTitle>
            <CustomCarousel
              slidesToScroll={isMobile ? 2 : 3}
              slidesToShow={isMobile ? 2 : 3}
              className="special-carousel"
            >
              {detailItems.map((item) => (
                <PracticeDetail key={item.label} {...item} />
              ))}
            </CustomCarousel>
          </Flex>

          <Flex vertical gap={8} style={{ marginTop: '32px' }}>
            <SubTitle primary align="center" id="attachments">
              {t('app.anexos')}
            </SubTitle>
            <Row gutter={[16, 16]}>
              {attachments
                .slice(0, attachmentsCount)
                .map((attachment, index) => (
                  <Col key={index} xs={24} md={8}>
                    <Flex
                      gap="12px"
                      style={{
                        background: 'rgba(241, 241, 241, 0.50)',
                        borderRadius: '10px',
                        padding: '16px',
                      }}
                      align="center"
                      justify="space-around"
                    >
                      <PiFileDocLight size={40} />
                      <Text size="sm" color={colorGreyBorderAndFont}>
                        {attachment.fileName}
                      </Text>
                      <a
                        href={attachment.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        <PiDownloadSimple size={30} />
                      </a>
                    </Flex>
                  </Col>
                ))}
              {attachments.length > attachmentsCount && (
                <Flex
                  justify="center"
                  align="center"
                  style={{ width: '100%', cursor: 'pointer' }}
                  onClick={() => setAttachmentsCount(attachmentsCount + 3)}
                >
                  <Text style={{ color: colorGreyBorderAndFont }}>
                    {t('app.mostrarMais')}
                  </Text>
                  <MdExpandMore color={colorGreyBorderAndFont} size={22} />
                </Flex>
              )}
            </Row>
          </Flex>
        </Col>
        <Col span={isMobile ? 0 : 3}>
          <SubTitle primary>{t('app.conteudo')}</SubTitle>
          <Anchor replace items={anchorItems} />
        </Col>
      </Row>
      {check([
        Permissions.U4hero.Practices.Adapt,
        Permissions.Practices.Adapt,
      ]) ? (
        <Flex
          vertical
          gap="16px"
          style={{ marginBottom: '16px', marginTop: '16px' }}
          className="adapt"
        >
          <Flex
            justify="space-between"
            align="center"
            style={{
              padding: '24px 16px',
              background: hex2rgba(colorPrimaryMedium, 0.4),
              borderRadius: '16px',
            }}
          >
            <Flex gap={'16px'} align="center">
              <PiPencilThin size={isMobile ? 38 : 50} color="white" />
              <p
                style={{
                  color: 'white',
                  fontWeight: 700,
                  fontSize: isMobile ? '14px' : '24px',
                  maxWidth: isMobile ? '112px' : undefined,
                }}
              >
                {t('app.personalizeEssaPraticaDeAcordoComSeuContexto')}
              </p>
            </Flex>
            <Button
              size="md"
              onClick={() => history.push(`/practices/adapt/${params.id}`)}
              id="id-tour-practices-button-adapt"
            >
              {t('app.adaptarInspiracao')}
            </Button>
          </Flex>
          {data?.adaptation && (
            <Flex gap={8} align="center">
              <GoAlertFill color={colorGreyBorderAndFont} size={24} />
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: colorGreyBorderAndFont,
                }}
              >
                {t('app.essaPraticaPedagogicaFoiAlterada')},{' '}
                <span
                  style={{
                    textDecoration: 'underline',
                    fontWeight: 600,
                    cursor: 'pointer',
                  }}
                  onClick={() => onDelete(data.adaptation?.id as string)}
                >
                  {t('app.cliqueAqui')}
                </span>{' '}
                {t(
                  'app.seDesejaDesfazerAsAlteracoesEDesfazerParaAPraticaOriginal'
                )}
              </p>
            </Flex>
          )}
        </Flex>
      ) : null}
    </div>
  );
});

export default Practice;
