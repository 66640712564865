function DocumentsIcon({ size = 50 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect x="0.5" width="50" height="50" fill="url(#documents)" />
      <defs>
        <pattern
          id="documents"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#docs" transform="scale(0.01)" />
        </pattern>
        <image
          id="docs"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADfElEQVR4nO2cvW4UMRSFj7QlSwWkhADp6YBU/EihRUS8CkIpIECeAAogTwEijwEIUFKmANJENCGBMuEiSzfSyrKymxl7fG2fT3Lh3ZFn7TPXd3xmxwAhhBBCCCGEEEIIIYRUyByANQBfAPwFIInLRz0nCfAQwMEAIvhlk6KExfinA/QOwC0AZxJftsdiUBSPuYnIeJRYBF8Qd26K4rE2ERlD4s7poCgeX3Vw3DTlMw9gHcAOgG+JBKEoHgc6OGPv83sAdgE8AXAFwCihIBTFGxjxBueyinEjsggnCUJRThBkXSMDAwviaD6nSGBwdnSayiFI86JIYHAOE+SMUO7y8xZFQXjKOunqjYWzZ65POabJ6UsyCfICwMsZjitOlFINwQsAfgG4VpMopRuCywB+1iJKLYbgskbKKwA3T5HoXaSaoTZD8DyA5wA+A/hzikg1Q+uGoFgTpHVDUKwJ0rohKNYEad0QlBIEackQlBIEsWQISqBsTRy7NeNxRQvSkiEoJQjSkiEoFMSWIUhBjBmCFCSCIThrkq8mqQ/5A5cHNgS3vToFyWwIhi4+RkhP+gziZsS2qpyyuhBzEClI5kFkDklAn7ss5pAEMKlPMfFkhjKrITjL+oGCGEN4l2UL4V2WLaTDNHkM77ISwBxyiqS+1eFpnn8ck3rgCrTEtldnhGRGAnWaixnZ9OpcqRtDGCF52fbqFCSCdSI97rKYQ2ALCdSZ1A0KIh1W6rTfIxDzsSsFSUBXQUb6N9kYbWV/pt7VBpGeCT9mhCwA+B6preyC5EQC9S6/cRXA60htJaMVQRb1JaSLEdpKSs2CjHSaWlUxlrzvz2o7+zBEyYLIlOIS+A+dpvzIcNzV49w/JqtK6tIhwedaqU+yoe08gyFKjpA+rGgbewDOwRAtCTLWaeo4Mo4A3IcxShZEepQ9i2K0JsgBgE+aM0xNUymTuvRI9EMndZOUHCFVkus99aoMwZiEOudexLwKGyyUYAjGJNS5twPsdVKVIRiTUOfm1ftxb8XmZLEUQ3CI/bKWdDCe6vQ1VE4ZlWgIxt5zxHXwduC7SwDeqEF3GGlBJjUagrH3HHEdfI8y2LBoCMbec2RfO/kYtlmZsD3MrrRjbW9xpJ39AODOlC0uhmRcgiGYggcAfg+UJ6Q2Q9DaniOSsBRhCBJCCCGEEEIIIYQQQgi68h9KSAAwh7fiFgAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
}

export default DocumentsIcon;
