import axios from "axios";
import { useQuery } from "react-query";

export interface UseActivitiesParams {
  id: string;
}

function useValidate(params: UseActivitiesParams) {

  const validate = async (
    params: UseActivitiesParams
  ): Promise<{ data: any; }> => {
    const { data} = await axios.get<string>(`${process.env.REACT_APP_BASE_URL_API}v1/auth/validate`, {
      params: {
        id: params.id,
      },
    });

    return { data};
  };

  return useQuery(
    [`${process.env.urlApi}/v1/auth/validate?id=${params.id}`, params],
    () => validate(params),
    {
      keepPreviousData: true,
    }
  );
}

export default useValidate;
