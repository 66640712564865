import { useMutation } from "react-query";
import http from "infra/http";
import history from "infra/helpers/history";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function useReset() {
  const params = useParams<{ token?: string }>();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (!params.token) {
          history.push("/auth/login");
        }

        setIsLoading(true);

        await http.post("/v1/auth/valid-password", {
          token: params.token,
        });
      } catch (e) {
        Swal.fire({
          title: "Aviso",
          text: "Este pedido de recuperar senha expirou, faça outro pedido de recuperação de senha!",
          icon: "info",
        }).then(() => {
          history.push("/auth/login");
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [params]);

  const res = useMutation(
    (data: { password: string; passwordConfirmation: string }) => {
      return http.post(`/v1/auth/reset-password`, {
        token: params.token,
        password: data.password,
        passwordConfirmation: data.passwordConfirmation,
      });
    },
    { retry: false }
  );

  return { ...res, isLoading: res.isLoading || isLoading };
}
