import { Spinner, useMediaQuery } from '@chakra-ui/react';
import {
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
} from 'antd';
import { useSaveStudent } from 'core/features/students/hooks/useSaveStudent';
import useStudent from 'core/features/students/hooks/useStudent';
import { StudentForm } from 'core/features/students/typings/form';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import { useQueryClient } from 'react-query';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import CustomizedRequiredMark from 'components/required-mark';
import UploadImagem from 'components/inputs/upload-imagem';
import useStoreItem from 'core/features/store-items/hooks/useStoreItem';
import { useSaveStoreItem } from 'core/features/store-items/hooks/useSaveStoreItem';
import { StoreItemForm } from 'core/features/store-items/typings';
import dayjs from 'dayjs';
import { storeItemTypes } from 'infra/helpers/types';

function ModalStoreItem({ onClose, id }: { id?: string; onClose: () => void }) {
  const { data, isLoading } = useStoreItem(id);
  const queryClient = useQueryClient();
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveStoreItem();
  const [form] = Form.useForm();
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const _handleSave = (values: StoreItemForm) => {
    handleSave(
      {
        ...values,
        endDate: values.endDate === '' ? undefined : values.endDate,
        active: true,
        id,
      },
      {
        onSuccess: () => {
          notificateSucess(
            `${id ? 'Item atualizado' : 'Item cadastrado'} com sucesso!`
          );
          queryClient.invalidateQueries('/v2/store-items');
          onClose();
        },
        onError: (err: any) => {
          notificateError(
            `Ops, aconteceu algum problema ao tentar ${
              id ? 'atualizar' : 'cadastrar'
            } o item, tente novamente!`
          );
        },
      }
    );
  };

  return (
    <Modal
      title={<SubTitle primary>{id ? 'Criar' : 'Editar'} Item</SubTitle>}
      open={true}
      onCancel={() => {
        if (form.isFieldsTouched()) {
          openNotification({ onClose, colorPrimary, isMobile });
        } else {
          onClose();
        }
      }}
      footer={null}
      width={isMobile ? 288 : 470}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Form
        id="id-tour-create-store-item"
        onFinish={(ev) => _handleSave(ev)}
        validateTrigger="onSubmit"
        layout="vertical"
        requiredMark={CustomizedRequiredMark}
        form={form}
        initialValues={
          data
            ? {
                ...data,
                initialDate: dayjs.utc(data.initialDate).format('YYYY-MM-DD'),
                endDate: data.endDate
                  ? dayjs.utc(data.endDate).format('YYYY-MM-DD')
                  : undefined,
              }
            : undefined
        }
      >
        <Form.Item
          name="imageUrl"
          rules={[
            {
              required: true,
              message: '"Por favor, carregue a imagem do item!',
            },
          ]}
        >
          {/* @ts-ignore */}
          <UploadImagem
            placeholderImg="storeIcon"
            formats={['png']}
            sizeLimitMB={1}
            apiPath="/v1/file/generic-upload?bucketName=u4hero-files&folder=store"
          />
        </Form.Item>

        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: 'Por favor, atribua um nome ao item',
            },
          ]}
        >
          <Input
            id="id-tour-create-store-item-modal-input-name"
            placeholder="Insira o nome do item"
            size={isMobile ? 'middle' : 'large'}
          />
        </Form.Item>

        <Form.Item
          label="Descrição"
          rules={[
            {
              required: true,
              message: 'Por favor, crie uma descrição!',
            },
            {
              max: 200,
              message: 'A descrição pode ter no máximo 200 caracteres!',
            },
          ]}
          name="description"
        >
          <Input.TextArea
            id="id-tour-create-store-item-modal-input-description"
            placeholder="Crie um breve descrição do item"
            maxLength={200}
            rows={3}
          />
        </Form.Item>

        <Form.Item
          label="Valor"
          name="price"
          rules={[
            {
              required: true,
              message: 'Por favor, defina um valor',
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            id="id-tour-create-store-item-modal-input-name"
            placeholder="Defina um valor"
            size={isMobile ? 'middle' : 'large'}
            min={0}
          />
        </Form.Item>
        <Row gutter={[isMobile ? 24 : 33, 0]}>
          <Col span={12}>
            <Form.Item
              label="Data Inicial"
              rules={[
                {
                  required: true,
                  message: 'Por favor, defina a data inicial',
                },
              ]}
              name="initialDate"
            >
              <Input
                id="id-tour-create-store-item-modal-input-initial-date"
                type="date"
                size={isMobile ? 'middle' : 'large'}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Data Final" name="endDate">
              <Input
                id="id-tour-create-store-item-modal-input-end-date"
                type="date"
                size={isMobile ? 'middle' : 'large'}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'Por favor, defina o tipo do item',
            },
          ]}
          name="type"
          label="Tipo"
        >
          <Select
            options={storeItemTypes}
            size={isMobile ? 'middle' : 'large'}
            id="id-tour-create-store-item-modal-input-type"
          />
        </Form.Item>

        <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
          <Button
            variant="outline"
            size="md"
            disabled={isLoading || isLoadingSave}
            onClick={() => {
              if (form.isFieldsTouched()) {
                openNotification({ onClose, colorPrimary, isMobile });
              } else {
                onClose();
              }
            }}
            id="id-tour-my-school-student-modal-button-cancel"
          >
            CANCELAR
          </Button>

          <Button
            htmlType="submit"
            size="md"
            loading={isLoading || isLoadingSave}
            id="id-tour-my-school-student-modal-button-save"
          >
            SALVAR
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
}

export default ModalStoreItem;
