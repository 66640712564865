import { Flex, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { Upload } from 'antd';
import readXlsxFile from 'read-excel-file';
import { useSaveStudents } from 'core/features/students/hooks/useSaveStudents';
import moment from 'moment';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import { FiDownload, FiUpload } from 'react-icons/fi';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import Text from 'components/typography/text';
import Button from 'components/buttons/button';
import { useQueryClient } from 'react-query';
import { t } from 'core/resources/strings';
const { Dragger } = Upload;
const schema = {
  Nome: {
    prop: 'nome',
    type: String,
    required: true,
  },
  Matrícula: {
    prop: 'documento',
    type: String,
    required: true,
  },
  'Data Nascimento': {
    prop: 'dataNascimento',
    type: (v: any) => {
      return moment(v).format('DD/MM/YYYY');
    },
    required: true,
  },
  Série: {
    prop: 'serie',
    type: String,
    oneOf: [
      '1º Ano (EF)',
      '2º Ano (EF)',
      '3º Ano (EF)',
      '4º Ano (EF)',
      '5º Ano (EF)',
      '6º Ano (EF)',
      '7º Ano (EF)',
      '8º Ano (EF)',
      '9º Ano (EF)',
      '1º Ano (E.M)',
      '2º Ano (E.M)',
      '3º Ano (E.M)',
    ],
  },
  Turma: {
    prop: 'turma',
    type: String,
  },
  Turno: {
    prop: 'turno',
    type: String,
    oneOf: ['morning', 'afternoon'],
  },
  Educadores: {
    prop: 'educadores',
    type: String,
  },
  Sexo: {
    prop: 'sexo',
    type: String,
    oneOf: ['M', 'F'],
    required: true,
  },
};

function ModalImportStudent({
  onClose,
  tenantId,
}: {
  tenantId: string;
  onClose: () => void;
}) {
  const [file, setFile] = useState();
  const [data, setData] = useState<any>([]);
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveStudents();
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const queryClient = useQueryClient();

  const [isMobile] = useMediaQuery('(max-width: 768px)');
  useEffect(() => {
    (async () => {
      if (file) {
        try {
          const rows = await readXlsxFile(file, { schema });
          setData(rows.rows);
        } catch (error) {
          console.log(error);
        }
      } else {
        setData([]);
      }
    })();
  }, [file]);

  const uploadProps = {
    beforeUpload: (file: any) => {
      setFile(file);
      return false;
    },
  };

  const handleImport = () => {
    const items = data
      ?.map((item: any) => {
        const requiredProperties = [
          'nome',
          'dataNascimento',
          'documento',
          'sexo',
        ];
        if (requiredProperties.every((prop) => item.hasOwnProperty(prop))) {
          return item;
        }
        return null;
      })
      .filter(Boolean);

    handleSave(
      { items, tenantId },
      {
        onSuccess: () => {
          notificateSucess(t('app.importacaoRealizadaComSucesso'));
          queryClient.invalidateQueries('/v2/student');
          onClose();
        },
        onError: (err: any) => {
          notificateError(t('app.mensagemErroImportacao'));
        },
      }
    );
  };

  return (
    <Modal
      title={
        <p
          style={{
            fontSize: isMobile ? '18px' : '24px',
            color: colorPrimary,
            fontWeight: 700,
          }}
        >
          {t('app.importacao')}
        </p>
      }
      open={true}
      onCancel={() => {
        if (file) {
          openNotification({
            message: t('app.mensagemVerificarImportacao'),
            onClose,
            colorPrimary,
            isMobile,
          });
        } else {
          onClose();
        }
      }}
      footer={null}
      width={isMobile ? 288 : 651}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Text>{t('app.mensagemImportacao')}</Text>

      <a
        href="/modelo.xlsx"
        download="modelo planilha"
        target="_blank"
        rel="noreferrer"
      >
        <Flex
          gap="8px"
          justify="center"
          align="center"
          style={{ marginTop: '4px' }}
        >
          <FiDownload
            color={colorGreyBorderAndFont}
            size={isMobile ? 16 : 24}
          />
          <p
            style={{
              color: colorGreyBorderAndFont,
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: 400,
            }}
          >
            {t('app.baixarModelo')}
          </p>
        </Flex>
      </a>

      <Dragger
        {...uploadProps}
        style={{ marginTop: '20px', marginBottom: '20px' }}
        accept=".xlsx"
        id="id-tour-my-school-import-student-modal-upload"
        onRemove={() => setFile(undefined)}
        maxCount={1}
      >
        <Flex
          justify="center"
          align="center"
          gap={isMobile ? '8px' : '16px'}
          vertical
        >
          <FiUpload color={colorGreyBorderAndFont} size={40} />
          <p
            style={{
              color: colorGreyBorderAndFont,
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: 400,
              maxWidth: isMobile ? '181px' : '265px',
            }}
          >
            {t('app.cliqueAquiOuArrasteAplanilha')}
          </p>
        </Flex>
      </Dragger>
      <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
        <Button
          size="md"
          variant="outline"
          onClick={() => {
            if (file) {
              openNotification({
                onClose,
                colorPrimary,
                isMobile,
              });
            } else {
              onClose();
            }
          }}
          loading={isLoadingSave}
          id="id-tour-my-school-import-student-modal-button-cancel"
        >
          {t('app.cancelar')}
        </Button>

        <Button
          size="md"
          onClick={handleImport}
          style={{
            fontWeight: 600,
            fontSize: isMobile ? '12px' : '16px',
          }}
          id="id-tour-my-school-import-student-modal-button-import"
          loading={isLoadingSave}
        >
          {t('app.importar')}
        </Button>
      </Flex>
    </Modal>
  );
}

export default ModalImportStudent;
