function NewsIcon({ size = 50 }: { size?: number }) {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 792 792"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <path
              d="M324.082,693.799c-6.832,0-12.401-5.543-12.401-12.375s5.543-12.375,12.401-12.375h143.86
         c6.832,0,12.375,5.543,12.375,12.375s-5.543,12.375-12.375,12.375H324.082z"
            />
            <path
              d="M340.066,742.912c-6.832,0-12.375-5.543-12.375-12.375s5.569-12.375,12.375-12.375h111.891
         c6.832,0,12.375,5.543,12.375,12.375s-5.543,12.375-12.375,12.375H340.066z"
            />
            <path
              d="M384.358,571.828c0,6.807-5.517,12.323-12.323,12.323c-6.806,0-12.323-5.518-12.323-12.323
         c-0.052-52.104-1.547-91.033-8.74-127.076c-7.115-35.629-19.852-68.655-42.461-109.39c-3.3-5.955-1.16-13.458,4.77-16.758
         c5.956-3.3,13.458-1.16,16.784,4.77c24.002,43.261,37.589,78.479,45.168,116.558
         C382.734,477.521,384.307,517.894,384.358,571.828z"
            />
            <path
              d="M432.312,571.828c0,6.807-5.518,12.323-12.323,12.323s-12.323-5.518-12.323-12.323
         c0.053-53.936,1.624-94.282,9.126-131.896c7.58-38.079,21.167-73.295,45.169-116.558c3.301-5.955,10.828-8.095,16.759-4.77
         c5.956,3.3,8.068,10.828,4.77,16.758c-22.61,40.734-35.346,73.787-42.462,109.39
         C433.859,480.795,432.339,519.725,432.312,571.828z"
            />
            <path
              d="M315.523,341.112c-6.497-2.063-10.055-9.023-7.992-15.521c2.088-6.497,9.023-10.055,15.521-7.992
         c8.611,2.785,16.062,6.523,22.945,9.978l0.67,0.361c10.75,5.414,19.697,9.642,26.348,4.228l15.211-12.401
         c5.285-4.279,13.073-3.48,17.351,1.805c4.28,5.285,3.48,13.071-1.805,17.351l-15.211,12.401
         c-18.949,15.417-34.444,7.915-53.109-1.444l-0.567-0.258C328.852,346.577,322.303,343.303,315.523,341.112z"
            />
            <path
              d="M408.388,60.328c0,6.832-5.543,12.375-12.376,12.375c-6.832,0-12.375-5.543-12.375-12.375V12.375
         C383.637,5.543,389.18,0,396.012,0c6.834,0,12.376,5.543,12.376,12.375V60.328z"
            />
            <path
              d="M468.974,317.599c6.497-2.088,13.458,1.496,15.521,7.992c2.088,6.497-1.495,13.458-7.992,15.521
         c-6.779,2.191-13.328,5.491-19.388,8.534l-0.566,0.258c-18.641,9.358-34.16,16.887-53.109,1.443l-15.211-12.4
         c-5.285-4.28-6.084-12.066-1.805-17.351c4.28-5.285,12.066-6.084,17.352-1.805l15.211,12.401
         c6.652,5.414,15.572,1.16,26.349-4.228l0.672-0.361C452.912,324.122,460.362,320.384,468.974,317.599z"
            />
            <path
              d="M551.706,192.354c39.832,39.832,64.505,94.901,64.505,155.693c0,23.771-3.815,46.741-10.828,68.27
         c-7.244,22.249-17.995,43.003-31.53,61.515c-22.713,31.066-41.146,51.459-55.455,67.313
         c-25.756,28.488-37.048,40.968-37.048,82.63c0,6.832-5.543,12.375-12.375,12.375h-0.311h-72.11h-72.445
         c-6.832,0-12.401-5.543-12.401-12.375c0-41.25-11.524-53.857-37.099-81.882l-0.026-0.025l-0.026,0.025
         c-14.463-15.829-33.077-36.223-56.358-68.062c-13.535-18.512-24.286-39.291-31.53-61.54
         c-7.013-21.502-10.828-44.499-10.828-68.243c0-29.751,5.956-58.214,16.732-84.202l0.232-0.49
         c11.189-26.813,27.483-50.944,47.566-71.001c20.213-20.212,44.473-36.558,71.492-47.747v-0.026
         c25.987-10.776,54.45-16.732,84.202-16.732c29.702,0,58.137,5.956,84.151,16.732
         C507.182,155.771,531.493,172.142,551.706,192.354L551.706,192.354z M591.435,348.047c0-53.96-21.89-102.815-57.234-138.188
         c-18.071-18.073-39.6-32.613-63.422-42.462c-22.945-9.513-48.211-14.772-74.767-14.772c-26.529,0-51.794,5.259-74.714,14.772
         v-0.025l-0.052,0.025c-23.822,9.849-45.323,24.389-63.396,42.462c-17.892,17.892-32.355,39.239-42.23,62.803l-0.258,0.67
         c-9.487,22.919-14.772,48.185-14.772,74.714c0,21.192,3.377,41.611,9.565,60.611c6.471,19.773,15.984,38.183,27.947,54.579
         c22.353,30.576,40.554,50.506,54.708,66.025l-0.026,0.025c27.534,30.087,41.224,45.143,43.364,86.135h60.354h60.354
         c2.114-41.275,15.546-56.177,43.209-86.754c13.819-15.288,31.582-34.959,53.832-65.381
         c11.962-16.371,21.476-34.779,27.921-54.553C588.058,389.658,591.435,369.265,591.435,348.047z"
            />
            <path
              d="M356.051,792c-6.832,0-12.375-5.543-12.375-12.375s5.543-12.375,12.375-12.375h79.923
         c6.832,0,12.375,5.543,12.375,12.375S442.806,792,435.974,792H356.051z"
            />
            <path
              d="M547.839,104.285c-3.379,5.878-10.933,7.915-16.837,4.538c-5.878-3.377-7.913-10.931-4.536-16.835l23.977-41.533
         c3.377-5.878,10.932-7.915,16.835-4.538c5.904,3.377,7.915,10.931,4.538,16.835L547.839,104.285z"
            />
            <path
              d="M265.533,92.013c3.377,5.878,1.367,13.432-4.538,16.81c-5.878,3.377-13.432,1.34-16.809-4.538l-23.977-41.534
         c-3.377-5.878-1.341-13.432,4.538-16.809c5.904-3.377,13.458-1.341,16.835,4.537L265.533,92.013z"
            />
            <path
              d="M157.69,190.781c5.878,3.377,7.915,10.931,4.538,16.835c-3.377,5.878-10.932,7.915-16.81,4.538l-41.534-23.977
         c-5.904-3.377-7.94-10.931-4.563-16.809c3.377-5.904,10.931-7.915,16.835-4.538L157.69,190.781z"
            />
            <path
              d="M646.606,212.154c-5.904,3.377-13.458,1.341-16.835-4.538c-3.378-5.904-1.341-13.458,4.537-16.835l41.534-23.977
         c5.902-3.377,13.456-1.341,16.835,4.538c3.377,5.878,1.341,13.458-4.538,16.835L646.606,212.154z"
            />
            <path
              d="M267.441,396.954c2.63,6.265-0.309,13.509-6.574,16.139c-6.265,2.63-13.509-0.31-16.139-6.574
         c-4.203-9.95-7.477-20.443-9.643-31.271c-2.14-10.699-3.3-21.63-3.3-32.613c0-6.832,5.569-12.375,12.375-12.375
         c6.832,0,12.375,5.569,12.375,12.375c0,9.668,0.954,18.975,2.733,27.792C261.099,379.603,263.883,388.523,267.441,396.954
         L267.441,396.954z M267.441,288.286c-2.629,6.265-9.874,9.204-16.139,6.574c-6.265-2.63-9.204-9.875-6.574-16.139
         c4.15-9.823,9.229-19.13,15.03-27.767l0.026-0.026l-0.026-0.025c5.956-8.817,12.71-17.016,20.109-24.415
         c7.322-7.322,15.521-14.077,24.44-20.083v-0.026c8.56-5.775,17.918-10.854,27.818-15.03c9.926-4.203,20.393-7.477,31.247-9.643
         c10.699-2.166,21.682-3.3,32.639-3.3c6.834,0,12.376,5.543,12.376,12.375c0,6.832-5.543,12.375-12.376,12.375
         c-9.694,0-19.026,0.954-27.818,2.707c-9.204,1.831-18.099,4.641-26.503,8.198c-8.172,3.455-16.062,7.76-23.564,12.813l0,0H318.1
         c-7.296,4.898-14.257,10.673-20.702,17.119c-6.368,6.368-12.143,13.355-17.119,20.754l-0.026-0.026
         C275.227,272.173,270.921,280.062,267.441,288.286z"
            />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}

export default NewsIcon;
