import { Flex } from "@chakra-ui/layout";
import { Button, useMediaQuery } from "@chakra-ui/react";
import { orange, primary } from "core/resources/theme/colors";

function SubMenuLateral({
  step,
  stepsLabel,
  onChange,
}: {
  step: number;
  stepsLabel: { label: string; order: number ; id:string }[];
  onChange: (v: number) => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Flex
      gridGap="32px"
      flexDir="column"
      justifyContent="space-between"
      alignItems={isMobile ? 'center':'normal'}
    >
      {stepsLabel.map(
        (item) =>
          <Button
            id={item.id}
            fontSize="18px"
            height="50px"
            width="170px"
            bgColor={primary}
            color="white"
            _hover={{
              boxShadow: `inset 0px -5px 0px ${orange}`
            }}
            _active={{
              boxShadow: `inset 0px -5px 0px ${orange}`
            }}
            onClick={() => onChange(item.order)}
            isActive={step === item.order}
          >
            {item.label}
          </Button>
      )}
    </Flex>
  );
}

export default SubMenuLateral;
