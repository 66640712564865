import { useQuery } from "react-query";
import axios from "infra/http";
import { ResourceItem } from "../types";


function useResource(resourceId?: string) {
  return useQuery(`/v1/resource/${resourceId}`, async (): Promise<ResourceItem> => {
    const { data } = await axios.get(`/v1/resource/${resourceId}`);

    return data;
  }, {
    enabled: !!resourceId,
  });
}

export default useResource;