import Layout from 'components/layout';
import ListSchools from './pages/ListSchools';
import { Typography } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useMediaQuery } from '@chakra-ui/react';

const { Title } = Typography;

function MetricsPage() {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Layout.Container>
      <Title level={1}
       style={{
        color: colorPrimary,
        fontSize: isMobile ? '24px':'28px',
      }}>Escolas</Title>
      <ListSchools />
    </Layout.Container>
  );
}

export default MetricsPage;
