import { useQuery } from "react-query";
import axios from "infra/http";
import { AnalysisEnvironment} from "../types/QuestionsDetails";

function useEnvironments() {
  return useQuery(`/v2/questions/environments`, async (): Promise<AnalysisEnvironment[]> => {
    const { data } = await axios.get(`/v2/questions/environments`);

    return data;
  });
}

export default useEnvironments;