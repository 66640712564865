import { useMediaQuery } from '@chakra-ui/react';
import {  Dropdown, Flex, Menu } from 'antd';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import moment from 'moment';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import DynamicTable from 'components/V2/dinamic-table';
import { FaEllipsisVertical } from 'react-icons/fa6';
import HelperFilter from '../HelperFilter';
import Status from 'components/status';

import {
  notificateError,
  notificateSucess,
  showConfirm,
} from 'infra/helpers/notifications';
import { FilterType } from 'infra/helpers/types';
import useToggleHelper from 'core/features/helpers/hooks/useToggleHelper';
import { useDeleteHelper } from 'core/features/helpers/hooks/useDeleteHelper';
import useHelpers from 'core/features/helpers/hooks/useHelpers';
import { HelperDetails } from 'core/features/helpers/typings';
import Tag from 'components/V2/tag';

function HelpersTable({onViewFeedback}:{onViewFeedback:(v:string)=> void}) {
  const history = useHistory();
  const [check] = useCheckPermissions();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { mutate: handleToggle } = useToggleHelper();
  const { mutate: handleDelete } = useDeleteHelper();
  const { colorPrimary } = useU4heroColors();
  const [filters, setFilters] = useState<FilterType>({
    classifications: [],
    authors: [],
    published: [],
    status: [],
    publishDate: [],
  });

  const { data, isLoading, refetch } = useHelpers({
    page,
    search,
    limit: pageSize,
    subClassifications:
      filters.classifications.map((item) => item.value) ?? undefined,
    publishDate: filters?.publishDate.map((item) => item.value)[0] ?? undefined,
    authors: filters.authors.map((item) => item.value) ?? undefined,
    published: filters.published?.length
      ? filters.published.map((item) => item.value)[0] === 1
      : undefined,
    active: filters.status?.length
      ? filters.status.map((item) => item.value)[0] === 1
      : undefined,
  });

  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };
  const columns = [
    {
      title: <p style={tableHeaderStyle}>Título</p>,
      name: 'Título',
      dataIndex: 'title',
      key: 'title',
      mobile: true,
      render: (name: string, record: HelperDetails) => (
        <p style={tableRowStyle}>{record.title}</p>
      ),
      sorter: (a: HelperDetails, b: HelperDetails) =>
        a.title.localeCompare(b.title),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Classificação</p>,
      name: 'Classificação',
      dataIndex: 'classification',
      key: 'classification',
      mobile: true,
      render: (_: string, record: HelperDetails) => (
        <Flex vertical>
          {record.classification ? (
            <>
              <p style={tableRowStyle}>{record.classification}</p>
              <Tag text={record.subClassification} />
            </>
          ) : (
            '-'
          )}
        </Flex>
      ),
      sorter: (a: HelperDetails, b: HelperDetails) =>
        a.classification.localeCompare(b.classification),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Palavras-chaves</p>,
      name: 'Palavras-chaves',
      dataIndex: 'keyword',
      key: 'keyword',
      extra: true,
      render: (_: string, record: HelperDetails) => (
        <p style={tableRowStyle}>{record.keyword}</p>
      ),
      sorter: (a: HelperDetails, b: HelperDetails) =>
        a.keyword.localeCompare(b.keyword),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Url</p>,
      name: 'Url',
      dataIndex: 'url',
      key: 'url',
      render: (_: string, record: HelperDetails) => (
        <p style={tableRowStyle}>{record.url ? record.url : '-'}</p>
      ),
      sorter: (a: HelperDetails, b: HelperDetails) =>
        a.url.localeCompare(b.url),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Autor</p>,
      dataIndex: 'author',
      key: 'author',
      name: 'Autor',
      render: (_: string, record: HelperDetails) => (
        <p style={tableRowStyle}>{record.author}</p>
      ),
      sorter: (a: HelperDetails, b: HelperDetails) =>
        a.author.localeCompare(b.author),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Tipo</p>,
      dataIndex: 'published',
      key: 'published',
      name: 'Tipo',
      extra: true,
      render: (_: string, record: HelperDetails) => (
        <p style={tableRowStyle}>
          {record.published ? 'Publicado' : 'Rascunho'}
        </p>
      ),
      sorter: (a: HelperDetails, b: HelperDetails) => (a.published ? 1 : -1),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Data de criação</p>,
      key: 'createdAt',
      dataIndex: 'createdAt',
      name: 'Data de criação',
      render: (_: string, record: HelperDetails) => (
        <p style={tableRowStyle}>
          {record.createdAt
            ? moment.utc(record.createdAt).format('DD/MM/YYYY')
            : '-'}
        </p>
      ),
      sorter: (a: HelperDetails, b: HelperDetails) =>
        a.createdAt.localeCompare(b.createdAt),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Data de edição</p>,
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      name: 'Data de edição',
      extra: true,
      render: (_: string, record: HelperDetails) => (
        <p style={tableRowStyle}>
          {record.updatedAt
            ? moment.utc(record.updatedAt).format('DD/MM/YYYY')
            : '-'}
        </p>
      ),
      sorter: (a: HelperDetails, b: HelperDetails) =>
        a.updatedAt.localeCompare(b.updatedAt),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Status</p>,
      dataIndex: 'active',
      key: 'active',
      name: 'Status',
      extra: true,
      render: (_: string, record: HelperDetails) => (
        <Status type={record.active ? 'active' : 'inactive'} size="md" />
      ),
      sorter: (a: HelperDetails, b: HelperDetails) => (a.active ? 1 : -1),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Ações</p>,
      key: 'action',
      mobile: true,
      dataIndex: 'action',
      name: 'Ações',
      render: (_: string, record: HelperDetails) => (
        <Dropdown
          overlay={
            <Menu
              id="id-tour-action-student-table"
              onClick={(e) => {
                if (e.key === 'view') {
                    history.push(`/helpers/${record.id}`)
                }
                if (e.key === 'feedback') {
                  onViewFeedback(record.id);
                }
                if (e.key === 'toggle') {
                  handleToggle(
                    {
                      id: record.id,
                      isActive: record.active,
                    },
                    {
                      onSuccess: () => {
                        notificateSucess(
                          `A ajuda “${record.title}” foi ${
                            record.active ? 'desativada' : 'ativada'
                          } com sucesso!`
                        );
                        refetch();
                      },
                      onError: () => {
                        notificateSucess(
                          `Houve um problema ao ${
                            record.active ? 'desativar' : 'ativar'
                          } a ajuda “${
                            record.title
                          }” Verifique sua conexão e tente novamente.`
                        );
                      },
                    }
                  );
                }
                if (e.key === 'edit') {
                  history.push(`/helpers-u4hero/${record.id}`);
                }
                if (e.key === 'delete') {
                  showConfirm({
                    message: `Deseja realmente deletar a ajuda "${record.title}"?`,
                    colorPrimary,
                    isMobile,
                  }).then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      handleDelete(record.id, {
                        onSuccess: () => {
                          notificateSucess(
                            `A ajuda “${record.title}” foi excluída com sucesso!`
                          );
                          refetch();
                        },
                        onError: () => {
                          notificateError(
                            `Houve um problema ao excluir a ajuda “${record.title}”. Verifique sua conexão e tente novamente.`
                          );
                        },
                      });
                    }
                  });
                }
              }}
            >
              {check([Permissions.U4hero.Helpers.View]) ? (
                <Menu.Item id="view-button" key={'view'}>
                  Visualizar
                </Menu.Item>
              ) : null}
              {check([Permissions.U4hero.Helpers.Edit]) ? (
                <Menu.Item id="edit-button" key={'edit'}>
                  Editar
                </Menu.Item>
              ) : null}
              {check([Permissions.U4hero.Helpers.Edit]) ? (
                <Menu.Item id="toggle-button" key={'toggle'}>
                  {record.active ? 'Desativar' : 'Ativar'}
                </Menu.Item>
              ) : null}
              {check([Permissions.U4hero.Helpers.Edit]) ? (
                <Menu.Item id="delete-button" key={'delete'}>
                  Deletar
                </Menu.Item>
              ) : null}
              <Menu.Item key={'feedback'}>Feedbacks</Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <FaEllipsisVertical
            id="id-tour-action-helpers-table"
            size={24}
            style={{ cursor: 'pointer' }}
          />
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    setPage(1);
  }, [search, filters]);

  return (
    <DynamicTable
      id="id-tour-helpers-table"
      itemName="ajuda"
      data={data?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title=""
      total={data?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isLoading}
      defaultColumns={
        isMobile
          ? columns.filter((column) => column.mobile)
          : columns.filter((column) => !column.extra)
      }
      allColumns={columns}
      onSearch={setSearch}
      appliedFilters={filters}
      onChangeFilters={setFilters}
      filters={<HelperFilter filters={filters} onChangeFilters={setFilters} />}
    />
  );
}

export default HelpersTable;
