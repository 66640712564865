import { useQuery } from 'react-query';
import axios from 'infra/http';
import { GuideDetails } from '../typings';

function useGuideInitialTour() {
  const fetchData = async (): Promise<GuideDetails> => {
    const { data } = await axios.get<GuideDetails>(`/v2/guides/initial-tour`);

    return data;
  };

  return useQuery([`/v2/guides/initial-tour`], () => fetchData());
}

export default useGuideInitialTour;
