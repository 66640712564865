import { Box, useMediaQuery} from "@chakra-ui/react";
import { reportTypes } from "core/features/report/types";
import { useState } from "react";
import ActivityCardList from "../ActivityCardList";
import ClassList from "../ClassList";
import Periodo from "../Periodo";

export enum SelectorClassTypes {
  activity = "activity",
  period = "period",
}

function SelectorClass({ secType }: { secType: SelectorClassTypes }) {
  const [step, setStep] = useState(0);
  const [classId, setclassId] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    < >
      {step === 0 ? <ClassList setStep={setStep} onClick={setclassId} /> : null}

    
      {step === 1 && secType === SelectorClassTypes.activity ?
        <ActivityCardList id={classId} collumns={4} type={reportTypes.Class} setStep={setStep}/>
        : null}

      {step === 1 && secType === SelectorClassTypes.period ?
        <Box pos="relative" bg="gray.50" py="16px" px="24px" rounded="8px" width={isMobile ? '100vw' : ''}>
          <Periodo id={classId} type={reportTypes.Class} setStep={setStep}/>
        </Box>
        : null}
    </>
  );
}

export default SelectorClass;
