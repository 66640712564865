import { Select } from 'antd';
import { useMediaQuery } from '@chakra-ui/react';
import useStudents from 'core/features/students/hooks/useStudents';
import { StudentItem } from 'core/features/students/typings';
import { t } from 'core/resources/strings';

interface SelectStudentProps {
  value: string;
  onChange: (v: string, student: StudentItem) => void;
  cleanDependentFields?: () => void;
  classId?: string;
  tenantId?: string;
  id: string;
}

function SelectStudent({
  value,
  onChange,
  cleanDependentFields,
  classId,
  tenantId,
  id,
}: SelectStudentProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data } = useStudents({
    classId,
    tenantId,
    disableConcat: true,
    page: 1,
    limit: 50,
  });
  return (
    <Select
      id={id}
      placeholder={t('app.selecioneOEstudante')}
      disabled={!classId}
      options={data?.data.map((student) => ({
        label: student.name,
        value: student.userId,
      }))}
      onChange={(v) => {
        const student = data?.data.find(
          (item) => item.userId === v
        ) as StudentItem;
        onChange(v, student);
        cleanDependentFields && cleanDependentFields();
      }}
      value={value}
      size={isMobile ? 'middle' : 'large'}
    />
  );
}

export default SelectStudent;
