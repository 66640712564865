import { useQuery } from 'react-query';
import axios from 'infra/http';
import { UserLogin } from '../typings';

export interface UseStudentLoginsParams {
  page: number;
  userId?: string;
  startDate?: string;
  endDate?: string;
  limit?: number;
}

function useStudentLogins(params: UseStudentLoginsParams) {
  const fetchData = async (
    params: UseStudentLoginsParams
  ): Promise<{ data: UserLogin[]; total: number }> => {
    const { data, headers } = await axios.get<UserLogin[]>(
      `/v2/student/logins`,
      {
        params,
      }
    );

    return { data: data, total: Number(headers['total']) };
  };

  return useQuery([`/v2/student/logins`, params], () => fetchData(params), {
    enabled: !!params.userId,
  });
}

export default useStudentLogins;
