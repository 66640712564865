import { useMediaQuery } from '@chakra-ui/media-query';
import { Modal, Flex } from 'antd';
import DynamicTable from 'components/V2/dinamic-table';
import useQuestionFeedbacks, {
  IFeedback,
} from 'core/features/questions/hooks/useQuestionFeedbacks';
import { useState } from 'react';
import { PiEyeLight } from 'react-icons/pi';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { PiEyeClosedLight } from 'react-icons/pi';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import dayjs from 'dayjs';
import { FilterType } from 'infra/helpers/types';
import Button from 'components/buttons/button';
import useHelperFeedbacks from 'core/features/helpers/hooks/useHelperFeedbacks';
import background from 'assets/images/background-feedback.png';
import bgMobile from 'assets/images/background-feedback-mobile.png';
import Text from 'components/typography/text';
import SubTitle from 'components/typography/subTitle';
import FeedbackFilter from 'components/feedback-filter';
import useHelper from 'core/features/helpers/hooks/useHelper';

function HelpersFeedbackModal({ onClose, id }: { onClose: () => void; id?: string }) {
  const { data } = useHelper(id);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();

  const customExpandIcon = (props: any) => {
    if (props.expanded) {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <PiEyeLight style={{ fontSize: '22px' }} />
        </a>
      );
    } else {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <PiEyeClosedLight style={{ fontSize: '22px' }} />
        </a>
      );
    }
  };

  const ratings = [
    {
      label: 'Muito Ruim',
      value: 1,
    },
    {
      label: 'Ruim',
      value: 2,
    },
    {
      label: 'Regular',
      value: 3,
    },
    {
      label: 'Bom',
      value: 4,
    },
    {
      label: 'Muito Bom',
      value: 5,
    },
  ];
  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };

  const [filters, setFilters] = useState<FilterType>({
    initialDate: [],
    ratings: [],
  });

  const { data: feedbackData, isLoading } = useHelperFeedbacks({
    id: id,
    page: page,
    ratings: filters?.ratings.map((item) => item.value) ?? undefined,
    initialDate: filters?.initialDate.map((item) => item.value)[0] ?? undefined,
  });

  const columns = [
    {
      title: <p style={tableHeaderStyle}>Categoria</p>,
      name: 'Categoria',
      dataIndex: 'category',
      key: 'category',
      render: (_: string, record: IFeedback) => (
        <p style={tableRowStyle}>{ratings.find((item)=> item.value === record.rating)?.label}</p>
      ),
      sorter: (a: any, b: any) => a.rating - b.rating,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Data</p>,
      name: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: (_: string, record: IFeedback) => (
        <p style={tableRowStyle}>
          {dayjs(record.createdAt).format('DD/MM/YYYY')}
        </p>
      ),
      sorter: (a: any, b: any) => a.createdAt - b.createdAt,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Feedback</p>,
      name: 'Feedback',
      dataIndex: 'feedback',
      key: 'feedback',
      render: (_: string, record: IFeedback) => (
        <p style={tableRowStyle}>{record.feedback}</p>
      ),
    },
    // {
    //   title: <p style={tableHeaderStyle}>Feedback</p>,
    //   name: "expand",
    //   dataIndex: "expand",
    //   key: "expand",
    //   render: (_: string, record: IFeedback) => (<PiEyeLight style={{ fontSize: "22px" }} />),
    // },
  ];

  return (
    <Modal
      footer={[
        <Flex align="center" justify="center">
          <Button size="md" onClick={onClose}>
            Fechar
          </Button>
        </Flex>,
      ]}
      title={<SubTitle primary>Feedback</SubTitle>}
      open={true}
      onCancel={onClose}
      width={isMobile ? '100%' : '666px'}
    >
      <Flex vertical gap={16}>
        <Flex
          style={{
            background: `url(${isMobile ? bgMobile : background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '10px',
            backgroundColor: colorPrimary,
            padding: '16px',
          }}
          gap={isMobile ? '8px' : '16px'}
          vertical
        >
          <Text size="lg" color="white">
            <strong>Título:</strong> {data?.title}
          </Text>
          <Text size="lg" color="white">
            <strong>Categoria:</strong> {data?.classification} /{' '}
            {data?.subClassification}
          </Text>
          <Text size="lg" color="white">
            <strong>Autor:</strong> {data?.author}
          </Text>
        </Flex>
        <DynamicTable
          id="id-tour-helpers-feedback-table"
          itemName={feedbackData && feedbackData?.total > 1 ? 'feedbacks' : 'feedback'}
          data={feedbackData?.data ?? []}
          page={page}
          onChangePage={setPage}
          isLoading={isLoading}
          allColumns={columns}
          defaultColumns={columns}
          hideCog={true}
          pageSize={pageSize}
          onChangePageSize={setPageSize}
          total={feedbackData?.total ?? 0}
          appliedFilters={filters}
          onChangeFilters={setFilters}
          filters={
            <FeedbackFilter filters={filters} onChangeFilters={setFilters} />
          }
          expandable={
            isMobile
              ? {
                  expandedRowRender: (record: IFeedback) => (
                    <p style={{ margin: 0, fontWeight: 600 }}>
                      <span style={{ color: colorPrimary, fontWeight: 600 }}>
                        Feedback:{' '}
                      </span>
                      {record.feedback}
                    </p>
                  ),
                  rowExpandable: (record: IFeedback) => record.feedback !== '',
                  expandIcon: (props: any) => customExpandIcon(props),
                }
              : null
          }
        />
      </Flex>
    </Modal>
  );
}

export default HelpersFeedbackModal;
