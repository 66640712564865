import { useQuery } from "react-query";
import axios from "axios";
import { AutomatedDetails } from "../types";

function useAutomatedActivity(id?: string) {
  const fetchAutomated = async (id: string): Promise<AutomatedDetails> => {
    const { data } = await axios.get<AutomatedDetails>(`${process.env.REACT_APP_AUTOMATED_URL}automated/${id}`);

    return data;
  };

  return useQuery([`${process.env.REACT_APP_AUTOMATED_URL}automated/${id}`, id], () => fetchAutomated(id!), {
    enabled: !!id,
  });
}

export default useAutomatedActivity;
