import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Typography } from 'antd';
import SvgIcon from 'components/SvgIcon';

const { Title } = Typography;
function SkillItem({
  icon,
  name,
  description,
}: {
  icon?: string | undefined;
  name: string;
  description: string;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Flex
      vertical
      style={{
        padding: isMobile ? '8px' : '16px',
        borderRadius: '10px',
        boxShadow:
          '0px -0.5px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
        height: isMobile ? '' : '280px',
      }}
      gap='8px'
    >
      <Flex vertical gap={8}>
      <SvgIcon url={icon ?? ''} color="black" size={isMobile ? 30 : 40} />
      <Title level={4} style={{margin:0}}>{name}</Title>

      </Flex>
  
      <div style={{ maxHeight: isMobile ? '' : '200px', overflowY: 'auto' }}>
        <p
          style={{
            fontSize: isMobile ? '14px' : '18px',
            fontWeight: 400,
            textAlign: 'justify',
            paddingRight: isMobile ? '' : '8px',
            color: 'black',
          }}
        >
          {description}
        </p>
      </div>
    </Flex>
  );
}

export default SkillItem;
