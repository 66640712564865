import Layout from 'components/layout';
import erro403 from 'assets/images/errors/403.png';
import erro403Mobile from 'assets/images/errors/403-mobile.png';
import {  Flex } from 'antd';
import { useMediaQuery } from '@chakra-ui/react';
import { useHistory } from 'react-router';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import Button from 'components/buttons/button';

function NoPermission() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();
  return (
    <Layout.Container>
      <Flex
        align={'center'}
        justify={'center'}
        vertical
        style={{ height: '100%' }}
        gap="16px"
      >
        <img
          src={isMobile ? erro403Mobile : erro403}
          alt="Sem permissão"
        />
        <Title>Acesso Proibido</Title>
        <Text
          style={{
            maxWidth: 873,
          }}
        >
         Ops! Parece que você não possui permissão para acessar esta página. Por favor, verifique suas credenciais ou contate o suporte para mais informações.
        </Text>
        <Button
          size="md"
          onClick={() => history.push('/')}
        >
          VOLTAR PARA PAGINA INICIAL
        </Button>
      </Flex>
    </Layout.Container>
  );
}

export default NoPermission;
