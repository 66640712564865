function CollaboratorIcon({ size = 50 }: { size?: number }) {
  return (
    <svg id="Line" width={size} height={size} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.881,149.062H84.335V94.442h0v0H29.879v54.62h0ZM47.969,114.4a9.137,9.137,0,1,1,9.185,9.134l-.048,0-.047,0A9.145,9.145,0,0,1,47.969,114.4Zm9.137,13.061.048,0A11.1,11.1,0,0,1,68.193,138.54v6.594H46.019V138.54a11.1,11.1,0,0,1,11.04-11.084Zm23.3,17.676H72.117V138.54a14.871,14.871,0,0,0-4.4-10.61,15.092,15.092,0,0,0-3.484-2.6,13.061,13.061,0,1,0-14.25,0A15.024,15.024,0,0,0,42.1,138.54v6.594H33.806V98.367h46.6Z" />
      <path d="M181.915,52.238a11.654,11.654,0,0,0-6.835-2.193H128.372A24.925,24.925,0,0,0,103.535,27.1H96.8A24.889,24.889,0,0,0,71.959,50.045H25.249A11.751,11.751,0,0,0,13.5,61.789V161.68a11.76,11.76,0,0,0,11.753,11.754H175.08a11.755,11.755,0,0,0,11.754-11.754V61.789A11.776,11.776,0,0,0,181.915,52.238ZM17.42,61.789a7.829,7.829,0,0,1,7.829-7.819H75.808V52.007A20.982,20.982,0,0,1,96.8,31.021h6.74a20.993,20.993,0,0,1,20.987,20.986V53.97H175.08a7.744,7.744,0,0,1,4.547,1.457h0a7.845,7.845,0,0,1,3.281,6.361V76.144H17.42ZM182.91,161.68a7.83,7.83,0,0,1-7.83,7.829H25.249a7.836,7.836,0,0,1-7.829-7.829V80.068H182.91Z" />
      <path d="M100.17,41.038a10.965,10.965,0,1,0,10.959,10.969A10.982,10.982,0,0,0,100.17,41.038Zm0,18a7.04,7.04,0,1,1,7.035-7.035A7.046,7.046,0,0,1,100.17,59.042Z" />
      <rect height="3.925" width="75.142" x="93.479" y="96.237" />
      <rect height="3.925" width="43.364" x="93.479" y="119.034" />
      <rect height="3.925" width="43.364" x="93.479" y="143.032" />
    </svg>
  );
}

export default CollaboratorIcon;
