import { useMediaQuery } from '@chakra-ui/react';
import { Carousel, Flex, Modal, Spin } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import Button from 'components/buttons/button';
import PracticeCard from './components/practiceCard';
import BackButton from 'components/buttons/back-button';
import NextButton from 'components/buttons/next-button';
import { CarouselRef } from 'antd/es/carousel';
import { useRef, useState } from 'react';
import usePractices from 'core/features/practices/hooks/usePractices';
import './style.css';

function ModalPracticeSuggestions({
  tags,
  onClose,
}: {
  tags?: string[];
  onClose: () => void;
}) {
  const { colorPrimary, colorHover } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const carouselRef = useRef<CarouselRef>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const { data, isLoading } = usePractices({
    page: 1,
    limit: 100,
    published: true,
    tags: tags,
  });

  const slidesToShow = isMobile ? 1 : 3;
  const totalSlides = data?.total ? data.total : 0;

  return (
    <Modal
      title={<SubTitle primary>Sugestão de Práticas Pedagógicas</SubTitle>}
      footer={[
        <Flex align="center" justify="center">
          <Button
            id="id-tour-modal-practice-suggestions-close-button"
            size="md"
            onClick={onClose}
          >
            Fechar
          </Button>
        </Flex>,
      ]}
      open={true}
      onCancel={onClose}
      width={isMobile ? '100%' : 1000}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Text>
        Com base na sua observação deixada ao estudante, seguem indicações de
        práticas pedagógicas recomendadas.
      </Text>
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <div
          style={{
            width: '100%',
            position: 'relative',
          }}
        >
          {totalSlides > slidesToShow && (
            <BackButton
              style={{
                position: 'absolute',
                top: '50%',
                left: -25,
                zIndex: 300,
              }}
              onClick={() => carouselRef.current?.prev()}
              disabled={currentSlide <= 0}
            />
          )}

          <Carousel
            ref={carouselRef}
            slidesToShow={slidesToShow}
            slidesToScroll={isMobile ? 1 : 3}
            style={{ width: '100%', height: '100%' }}
            dots={false}
            infinite={false}
            className='extra-padding-top'
            beforeChange={(curr, next) => setCurrentSlide(next)}
          >
            {data?.data.map((item) => (
              <PracticeCard key={item.id} practice={item} />
            ))}
          </Carousel>
          {totalSlides > slidesToShow && (
            <NextButton
              style={{ position: 'absolute', top: '50%', right: 0 }}
              onClick={() => carouselRef.current?.next()}
              disabled={currentSlide >= totalSlides - slidesToShow}
            />
          )}
        </div>
      )}
    </Modal>
  );
}

export default ModalPracticeSuggestions;
