import { useMediaQuery } from '@chakra-ui/react';
import { Carousel, Flex } from 'antd';
import { CarouselProps, CarouselRef } from 'antd/es/carousel';
import { ReactNode, useRef, useState } from 'react';
import BackButton from 'components/buttons/back-button';
import NextButton from 'components/buttons/next-button';

interface CustomCarouselProps extends CarouselProps {
  children: ReactNode;
  slidesToShow: number;
  totalSlides: number;
}

function CarouselV2({
  children,
  slidesToShow,
  totalSlides,
  ...rest
}: CustomCarouselProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const carouselRef = useRef<CarouselRef>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const rows = rest.rows || 1;
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      {currentSlide > 0 && (
        <BackButton
          style={{
            position: 'absolute',
            top: '40%',
            left: -10,
            zIndex: 300,
          }}
          onClick={() => carouselRef.current?.prev()}
        />
      )}

      <Carousel
        ref={carouselRef}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToShow}
        style={{ width: '100%', height: '100%' }}
        dots={false}
        infinite={false}
        {...rest}
        beforeChange={(curr, next) => {
          setCurrentSlide(next);
        }}
      >
        {children}
      </Carousel>

      {currentSlide < totalSlides - slidesToShow * rows && (
        <NextButton
          style={{
            position: 'absolute',
            top: '40%',
            right: 0,
          }}
          onClick={() => carouselRef.current?.next()}
        />
      )}
    </div>
  );
}

export default CarouselV2;
