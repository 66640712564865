import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Row, Spin } from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useEmotionalStats from 'core/features/graphs/hooks/useEmotionalGraphV2';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import moment from 'moment';
import { t } from 'core/resources/strings';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import bad from 'assets/images/sad.png';
import good from 'assets/images/happy.png';
import fear from 'assets/images/fear.png';
import disgusted from 'assets/images/disgusted.png';
import verybad from 'assets/images/angry.png';
import SadIcon from 'components/icons/SadIcon';
import { useState } from 'react';
import ModalEmotions from 'components/modals/modal-emotions';
import { useCallback } from 'react';

const colors = [
  {
    color: '#FFBC00',
    name: t('app.feliz'),
    img: good,
    id: '3e48867c-4975-4cc4-9231-dcda0a180c2e',
  },
  {
    color: '#8C6B84',
    name: t('app.medo'),
    img: fear,
    id: '443ccb0a-aa0b-4d30-b99e-3c35d43144e3',
  },
  {
    color: '#216EB4',
    name: t('app.triste'),
    img: bad,
    id: '6ce19a69-5adc-40e1-b691-a77f5c64e554',
  },
  {
    color: '#00B8B8',
    name: t('app.nojo'),
    img: disgusted,
    id: 'd62e3af7-4f9a-4641-b9eb-c8bd8a3d0fee',
  },
  {
    color: '#E35809',
    name: t('app.raiva'),
    img: verybad,
    id: 'ff450cf1-f299-459d-a71d-114e7cf57c11',
  },
];

function Emotion() {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const initialDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
  const endDate = moment().add(1, 'day').format('YYYY-MM-DD');
  const { data, isLoading: isLoadingStats } = useEmotionalStats({
    initialDate,
    endDate,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [emotion, setEmotion] = useState<any>();
  const totalCount = data?.data?.reduce(
    (total, item) => total + item?.percent,
    0
  );

  const handleDataPointSelection = useCallback(
    (event, chartContext, config) => {
      const selectedIndex = config.dataPointIndex;
      const selectedLabel = config.w.config.labels[selectedIndex];
      const emotion = colors.find((item) => item.name === selectedLabel);
      setIsOpen(true);
      setEmotion(emotion);
    },
    []
  );

  const options: ApexOptions = {
    chart: {
      type: 'donut',
      events: {
        dataPointSelection: handleDataPointSelection,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    labels: data?.data?.map((item) => t(`app.${item.name}`)),
    colors: colors.map((item) => item.color),
    legend: {
      show: false,
    },
  };
  return (
    <GenericCard padding="8px" height="100%" id="id-tour-section-emotionometer">
      {isOpen && (
        <ModalEmotions
          emotion={emotion}
          initialDate={initialDate}
          endDate={endDate}
          onClose={() => setIsOpen(false)}
        />
      )}
      <Flex vertical style={{ width: '100%', height: '100%' }}>
        <p
          style={{
            color: colorPrimary,
            fontSize: isMobile ? '18px' : '24px',
            fontWeight: 700,
          }}
        >
          {t('app.emocionometro')}
        </p>
        {totalCount !== 0 && (
          <p
            style={{
              color: colorGreyBorderAndFont,
              fontSize: isMobile ? '12px' : '14px',
              fontWeight: 500,
              alignSelf: 'end',
            }}
          >
            {t(`app.${data?.period}`)}
          </p>
        )}

        {isLoadingStats ? (
          <Spin size="large" />
        ) : (
          <div>
            {totalCount === 0 ? (
              <Flex
                vertical
                align="center"
                justify="center"
                gap="16px"
                style={{ height: '100%' }}
              >
                <SadIcon color="#7B7B7B" size={60} />
                <p
                  style={{
                    fontSize: '14px',
                    color: '#7B7B7B',
                    textAlign: 'center',
                    fontWeight: 400,
                    maxWidth: '267px',
                  }}
                >
                  {t('app.emocionometroNaoPreenchido')}
                </p>
              </Flex>
            ) : (
              <Row align="middle" justify="center" style={{ width: '100%' }}>
                <Col span={isMobile ? 24 : 14}>
                  <ReactApexChart
                    width="100%"
                    height={isMobile ? '235px' : '250px'}
                    options={options}
                    series={data?.data.map((item) =>
                      parseFloat(item.percent.toFixed(1))
                    )}
                    type="donut"
                    style={{ display: 'flex', justifyContent: 'center' }}
                    id="id-tour-emotionometer-graph"
                  />
                </Col>
                <Col span={isMobile ? 24 : 10}>
                  <Flex vertical={!isMobile} wrap={'wrap'} gap="16px">
                    {colors.map((item, index) => (
                      <Flex
                        gap="8px"
                        key={index}
                        align="center"
                        id={`id-tour-legend-item-${index}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setIsOpen(true);
                          setEmotion(item);
                        }}
                      >
                        <img
                          alt={item.name}
                          src={item.img}
                          width={isMobile ? '24px' : '30px'}
                        />
                        <p
                          style={{
                            fontSize: isMobile ? '14px' : '16px',
                            fontWeight: 400,
                            color: 'black',
                            textAlign: 'right',
                          }}
                        >
                          {item.name}
                        </p>
                      </Flex>
                    ))}
                  </Flex>
                </Col>
              </Row>
            )}
          </div>
        )}
      </Flex>
    </GenericCard>
  );
}

export default Emotion;
