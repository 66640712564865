import { useQuery } from "react-query";
import axios from "infra/http";
import { PathMetrics } from "../typings";

export interface UseMetricsPathParams {
  tenantId: string;
  collaboratorId?:string;
  startDate?:string;
  endDate?:string;
}

function useMetricsPath(params: UseMetricsPathParams) {

  const fetchMetrics = async (
    params: UseMetricsPathParams
  ): Promise<{ data: PathMetrics[] }> => {
    const response = await axios.get<PathMetrics[]>(`/v2/metrics/path`, {
      params: {
        tenantId: params.tenantId,
        collaboratorId: params.collaboratorId,
        startDate: params.startDate,
        endDate: params.endDate,
     
      },
    });

    return { data: response.data};
  };

  return useQuery(
    [`/v2/metrics/path?tenantId=${params.tenantId}&collaboratorId=${params.collaboratorId}&startDate=${params.startDate}&endDate=${params.endDate}`, params],
    () => fetchMetrics(params),
    {
      keepPreviousData: true,
    }
  );
}

export default useMetricsPath;
