import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Form, Steps } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import { useQueryClient } from 'react-query';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import CustomizedRequiredMark from 'components/required-mark';

import { useSaveTenant } from 'core/features/tenants/hooks/useSaveTenant';
import { TenantForm } from 'core/features/tenants/typings';
import { useTenantV1 } from 'core/features/tenants/hooks/useTenantV1';
import { useHistory, useParams } from 'react-router';
import CloseButton from 'components/buttons/close-button';
import Layout from 'components/layout';
import { LiaUniversitySolid } from 'react-icons/lia';
import { PiGameControllerThin } from 'react-icons/pi';
import { FaCheckCircle } from 'react-icons/fa';
import React from 'react';
import { Permissions } from 'core/resources/enums/permissions';
import useCheckPermission from 'core/features/user/hooks/useCheckPermission';
import SchoolInfoStep from './steps/SchoolInfoStep';
import PickGamesStep from './steps/PickGamesStep';
const TENANTID_U4HERO = '6720d230-7a46-49e5-acc0-bbb249d5f68c';

function CreateSchool() {
  const params = useParams<{ id: string }>();
  const { data, isLoading } = useTenantV1(params.id);
  const queryClient = useQueryClient();
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveTenant();
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [current, setCurrent] = useState(0);
  const [check] = useCheckPermission();
  const isU4Hero = check(Permissions.U4hero.Tenant.Create);

  const history = useHistory();
  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const _handleSave = (values: TenantForm) => {
    handleSave(
      { ...values, id: params.id, master: false },
      {
        onSuccess: () => {
          notificateSucess(
            `Escola ${params.id ? 'atualizada' : 'criada'} com sucesso!`
          );
          history.push('/schools?tab=2');
          queryClient.invalidateQueries('/v1/tenant');
        },
        onError: (err: any) => {
          notificateError(
            `Houve um problema ao ${
              params.id ? 'editar' : 'criar'
            } a escola. Verifique sua conexão e tente novamente.`
          );
        },
      }
    );
  };

  const onClose = () => history.push('/schools?tab=2');

  const updateForm = (fields: any) => {
    form.setFieldsValue(fields);
  };

  const checkFields = (fields: string[]) => {
    for (const field of fields) {
      if (formValues?.hasOwnProperty(field)) {
        if (!formValues[field]) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  };

  const steps = [
    {
      title: 'Informações da Escola',
      content: (
        <SchoolInfoStep
          updateForm={updateForm}
          tenantId={data?.id}
          defaultZipCode={data?.addressZipCode}
        />
      ),
      icon: <LiaUniversitySolid size={32} />,
    },
    {
      title: 'Atribuição de Jogos',
      content: (
        <Form.Item name="games">
          {/* @ts-ignore */}
          <PickGamesStep />
        </Form.Item>
      ),
      icon: <PiGameControllerThin size={32} />,
    },
  ];

  const items = steps.map((item, index) => ({
    key: item.title,
    title: isMobile ? undefined : (
      <span
        id={`id-tour-activity-create-step-${item.title.toLowerCase()}`}
        style={{
          fontSize: '16px',
          fontWeight: 700,
          color: current >= index ? colorPrimary : colorGreyBorderAndFont,
        }}
      >
        {item.title}
      </span>
    ),
    icon:
      current > index ? (
        <FaCheckCircle
          size={32}
          color={colorPrimary}
          onClick={() => {
            setCurrent(index);
          }}
          cursor="pointer"
        />
      ) : (
        React.cloneElement(item.icon, {
          color: current === index ? colorPrimary : colorGreyBorderAndFont,
          onClick: () => {
            if (params.id) {
              setCurrent(index);
            } else {
              if (current === index) {
                setCurrent(index);
              }
            }
          },
          cursor: params.id
            ? 'pointer'
            : // : isStepEnabled(current + 1, index)
            current === index
            ? 'pointer'
            : 'not-allowed',
        })
      ),
  }));

  return (
    <Layout.Container>
      <Form
        onFinish={(ev) => _handleSave(ev)}
        validateTrigger="onSubmit"
        layout="vertical"
        requiredMark={CustomizedRequiredMark}
        form={form}
        initialValues={
          data || {
            name: undefined,
            document: undefined,
            phone: undefined,
            site: undefined,
            adminEmail: undefined,
            adminName: undefined,
            addressCountryId: undefined,
            addressState: undefined,
            addressCity: undefined,
            addressDistrict: undefined,
            addressZipCode: undefined,
            addressStreet: undefined,
            addressNumber: undefined,
            addressComplement: undefined,
            games: [],
            imageUrl: undefined,
            colorPrimary: undefined,
            colorPrimaryMedium: undefined,
            colorPrimaryLight: undefined,
            colorSecondary: undefined,
            colorSecondaryMedium: undefined,
            colorSecondaryLight: undefined,
            whiteLabel: false,
            parentId: isU4Hero ? TENANTID_U4HERO : undefined,
          }
        }
      >
        <Flex vertical gap="12px" style={{ marginBottom: '24px' }}>
          <Flex gap="24px" align="center">
            <CloseButton
              onClick={() => {
                if (form.isFieldsTouched()) {
                  openNotification({ onClose, colorPrimary, isMobile });
                } else {
                  onClose();
                }
              }}
            />
            <SubTitle primary>{params.id ? 'Editar' : 'Criar'} Escola</SubTitle>
          </Flex>

          <Steps
            responsive={false}
            current={current}
            items={items}
            style={{ marginBottom: '16px' }}
          />
          <Flex justify="flex-end" gap={isMobile ? '12px' : '24px'}>
            {current === 1 ? (
              <Button
                size="sm"
                id="id-tour-institution-create-button-prev"
                onClick={() => setCurrent(current - 1)}
                variant="outline"
              >
                Anterior
              </Button>
            ) : (
              <Button
                variant="outline"
                size="sm"
                disabled={isLoading || isLoadingSave}
                onClick={() => {
                  if (form.isFieldsTouched()) {
                    openNotification({ onClose, colorPrimary, isMobile });
                  } else {
                    onClose();
                  }
                }}
                id="id-tour-institution-create-button-cancel"
              >
                CANCELAR
              </Button>
            )}

            {current === 1 ? (
              <Button
                size="sm"
                htmlType="submit"
                loading={isLoading || isLoadingSave}
                id="id-tour-institution-create-button-save"
              >
                Salvar
              </Button>
            ) : (
              <Button
                size="sm"
                id="id-tour-institution-create-button-next"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrent(current + 1);
                }}
                htmlType="button"
                disabled={
                  !checkFields([
                    'name',
                    'adminName',
                    'adminEmail',
                    'addressZipCode',
                    'addressStreet',
                    'addressNumber',
                    'addressDistrict',
                    'addressState',
                    'addressCity',
                    'addressNumber',
                  ])
                }
              >
                Próximo
              </Button>
            )}
          </Flex>

          {steps.map((item, index) => (
            <div
              key={index}
              style={{
                marginBottom: '16px',
                display: current === index ? '' : 'none',
              }}
            >
              {item.content}
            </div>
          ))}
        </Flex>
      </Form>
    </Layout.Container>
  );
}

export default CreateSchool;
