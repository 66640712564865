import { useMediaQuery } from '@chakra-ui/media-query';
import { Carousel, Flex } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import BackButton from 'components/buttons/back-button';
import NextButton from 'components/buttons/next-button';
import CustomCarousel from 'components/custom-carousel';
import SubTitle from 'components/typography/subTitle';
import usePractices from 'core/features/practices/hooks/usePractices';
import { t } from 'core/resources/strings';
import { useRef, useState } from 'react';
import PracticeCard from '../../pages/app/mySchool/pages/Students/components/StudentPanel/components/practiceCard';

function InspirationsRecomendations({ lowest }: { lowest?: string }) {
  const carouselRef = useRef<CarouselRef>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const { data, isLoading } = usePractices({
    page: 1,
    limit: 100,
    published: true,
    // tags: lowest ? [lowest] : undefined,
  });
  const totalSlides = data?.total ?? 0;
  const slidesToShow = 3;
  if (data?.total === 0) {
    return <></>;
  }

  return (
    <Flex vertical gap={16}>
      <SubTitle primary align={isMobile ? 'center' : 'left'}>
        {t('app.recomendacoesPedagogicas')}
      </SubTitle>
      {isMobile ? (
        <CustomCarousel slidesToScroll={1} slidesToShow={1} infinite={false}>
          {' '}
          {data?.data.map((item) => (
            <PracticeCard key={item.id} practice={item} />
          ))}
        </CustomCarousel>
      ) : (
        <>
          <Carousel
            ref={carouselRef}
            slidesToShow={slidesToShow}
            slidesToScroll={3}
            style={{ width: '100%', height: '100%' }}
            dots={false}
            infinite={false}
            rtl
            beforeChange={(curr, next) => setCurrentSlide(next)}
          >
            {data?.data.map((item) => (
              <PracticeCard key={item.id} practice={item} />
            ))}
          </Carousel>
          <Flex style={{ alignSelf: 'flex-end' }} gap={16}>
            <BackButton
              onClick={() => carouselRef.current?.prev()}
              disabled={currentSlide <= 0}
            />
            <NextButton
              onClick={() => carouselRef.current?.next()}
              disabled={currentSlide >= totalSlides - slidesToShow}
            />
          </Flex>
        </>
      )}
    </Flex>
  );
}

export default InspirationsRecomendations;
