import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useHistory } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import Button from 'components/buttons/button';
import StoriesPostsTable from './components/StoriesTable';
import Layout from 'components/layout';
import BackButton from 'components/buttons/back-button';
import Title from 'components/typography/title';

function StoriesManager() {
  const history = useHistory();
  const [check] = useCheckPermissions();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Layout.Container>
      <Flex gap={isMobile ? '12px' : '24px'} align="center">
        <BackButton onClick={() => history.goBack()} />
        <Title primary>Modo História</Title>
      </Flex>
      <Flex justify="end" style={{ marginBottom: isMobile ? '8px' : '16px' }}>
        {check([Permissions.U4hero.Posts.Create]) ? (
          <Button
            size="md"
            onClick={() => history.push('/stories/create')}
            id="id-tour-blog-posts-button-add"
          >
            CRIAR HISTÓRIA
          </Button>
        ) : null}
      </Flex>
      <StoriesPostsTable />
    </Layout.Container>
  );
}

export default StoriesManager;
