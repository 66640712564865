import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useHistory } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import Button from 'components/buttons/button';
import BlogPostsTable from '../../components/BlogPostsTable';

function BlogPostsTab() {
  const history = useHistory();
  const [check] = useCheckPermissions();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <>
      
      <Flex justify="end" style={{ marginBottom: isMobile ? '8px' : '16px' }}>
        {check([Permissions.U4hero.Posts.Create]) ? (
          <Button
            size="md"
            onClick={() => history.push('/blog-posts/create')}
            id="id-tour-blog-posts-button-add"
          >
            CRIAR PUBLICAÇÃO
          </Button>
        ) : null}
      </Flex>
      <BlogPostsTable/>
    </>
  );
}

export default BlogPostsTab;
