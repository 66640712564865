import GenericFilterComponent from 'components/generic-filter';
import useHelpersAuthors from 'core/features/helpers/hooks/useHelpersAuthors';
import { FilterProps } from 'infra/helpers/types';

function HelperFilter({ filters, onChangeFilters }: FilterProps) {
  const { data: authors } = useHelpersAuthors();

  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: 'Classificação',
          filterName: 'classifications',
          options: [],
          children: [
            {
              title: 'Primeiros Passos',
              options: [
                {
                  label: 'Conceitos Básicos',
                  value: 'Conceitos Básicos',
                },
              ],
            },
            {
              title: 'Configurações Gerais',
              options: [
                {
                  label: 'Minha Conta',
                  value: 'Minha Conta',
                },
                {
                  label: 'Perfis e Permissões',
                  value: 'Perfis e Permissões',
                },
              ],
            },
            {
              title: 'Funcionalidades',
              options: [
                {
                  label: 'Início',
                  value: 'Início',
                },
                {
                  label: 'Minha Escola',
                  value: 'Minha Escola',
                },
                {
                  label: 'Perguntas',
                  value: 'Perguntas',
                },
                {
                  label: 'Jogos',
                  value: 'Jogos',
                },
                {
                  label: 'Atividades',
                  value: 'Atividades',
                },
                {
                  label: 'Relatórios',
                  value: 'Relatórios',
                },
                {
                  label: 'Indicadores',
                  value: 'Indicadores',
                },
                {
                  label: 'Meu Cantinho',
                  value: 'Meu Cantinho',
                },
              ],
            },
          ],
        },
        {
          title: 'Autor',
          filterName: 'authors',
          options:
            authors?.map((item) => {
              return { label: item.name, value: item.id };
            }) ?? [],
        },
        {
          title: 'Tipo',
          filterName: 'published',
          options: [
            {
              label: 'Publicado',
              value: 1,
            },
            {
              label: 'Rascunho',
              value: 2,
            },
          ],
          type: 'radio',
        },
        {
          title: 'Status',
          options: [
            { label: 'Ativo', value: 1 },
            { label: 'Inativo', value: 2 },
          ],
          filterName: 'status',
          type: 'radio',
        },
        {
          title: 'Data de publicação',
          filterName: 'publishDate',
          options: [],
          type: 'date',
          open: true,
        },
      ]}
    />
  );
}

export default HelperFilter;
