import { useMutation } from 'react-query';
import http from 'infra/http';

export function useNewStudentKey() {
  return useMutation(
    (userId: string) => {
      return http.put(`/v2/student/new-key/${userId}`);
    },
    { retry: false }
  );
}
