import ClassRoomIcon from 'assets/images/icons/ClassRoomIcon';
import useTotalClasses from 'core/features/indicators/hooks/useTotalClasses';
import { t } from 'core/resources/strings';
import StatCard from '../statCard';

function TotalClasses({onRemove}:{onRemove?:()=> void}) {
  const { data } = useTotalClasses();
  return (
    <StatCard title={t('app.turma')} total={data?.total ?? 0} Icon={ClassRoomIcon} onRemove={onRemove} />
  );
}

export default TotalClasses;
