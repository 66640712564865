import styled from 'styled-components';

export const GenericQuestionAnswerCard = styled.div<any>`
  max-width: ${({ maxWidth }) => ( maxWidth ? maxWidth : 'none' )};
  max-height: ${({ maxHeigth }) => ( maxHeigth ? maxHeigth : 'none' )};
  width: ${({ width }) => ( width ? width : 'none' )};
  heigth: ${({ heigth }) => ( heigth ? heigth : 'none' )};
  border-radius: 20px 10px 10px 20px;
  box-shadow: 0px -0.5px 4px 0px rgba(0, 0, 0, 0.25),
    0px 3px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
`;