import { useQuery } from "react-query";
import axios from "infra/http";
import { SerieItem } from "../typings";

function useSeries() {
  return useQuery(`/v2/serie`, async (): Promise<SerieItem[]> => {
    const { data } = await axios.get(`/v2/serie`);

    return data;
  });
}

export default useSeries;
