import { Col, Flex, Row } from 'antd';
import Button from 'components/buttons/button';
import ModalCharacterLore from 'components/modals/modal-character-lore';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { t } from 'core/resources/strings';
import { useState } from 'react';

const characters = [
  {
    name: 'Rotori',
    image: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/lores/Rotori.png',
    lore: t('app.rotoriLore'),
  },
  {
    name: 'Na e Bo',
    image: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/lores/Na+e+Bo.png',
    lore: t('app.naeBoLore'),
  },
  {
    name: 'Ibá',
    image: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/lores/Ib%C3%A1.png',
    lore: t('app.ibaLore'),
  },
  {
    name: 'Coepi',
    image: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/lores/Coepi.png',
    lore: t('app.coepiLore'),
  },
  {
    name: 'Tagon',
    image: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/lores/Tagon.png',
    lore: t('app.tagonLore'),
  },
  {
    name: 'Tery',
    image: 'https://u4hero-files.s3.sa-east-1.amazonaws.com/lores/Tery.png',
    lore: t('app.terryLore'),
  },
];

function CharacterLore() {
  const [currentCharacter] = useState(
    characters[Math.floor(Math.random() * characters.length)]
  );
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {isOpen ? (
        <ModalCharacterLore
          name={currentCharacter.name}
          image={currentCharacter.image}
          lore={currentCharacter.lore}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      ) : null}
      <GenericCard
        padding="2px"
        width="100%"
        height="100%"
        id="id-tour-section-check-character-lore"
      >
        <Row justify="space-between" style={{ width: '100%' }}>
          <Col span={12}>
            <img
              alt="imagem do personagem"
              src={currentCharacter.image}
              style={{
                height: '140px',
                borderRadius: '20px',
                objectFit: 'cover',
                aspectRatio: '16/9',
              }}
            />
          </Col>
          <Col span={12}>
            <Flex
              vertical
              gap="12px"
              style={{ height: '100%' }}
              justify="center"
              align="center"
            >
              <p
                style={{
                  fontSize: '18px',
                  color: 'black',
                  textAlign: 'center',
                  maxWidth: '170px',
                  fontWeight: 400,
                }}
              >
                {t('app.conhecaHistoria')} {currentCharacter.name}
              </p>

              <Button
                size="sm"
                id="id-tour-button-read-now-character-lore"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                {t('app.leiaAgora')}
              </Button>
            </Flex>
          </Col>
        </Row>
      </GenericCard>
    </>
  );
}

export default CharacterLore;
