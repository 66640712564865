import { Spinner, useMediaQuery } from '@chakra-ui/react';
import { Flex, Form, Input, Modal, Select } from 'antd';
import { StudentForm } from 'core/features/students/typings/form';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import { useQueryClient } from 'react-query';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import { t } from 'core/resources/strings';
import { useSaveResource } from 'core/features/resources/hooks/useSaveResource';
import useResource from 'core/features/resources/hooks/useResource';
import CustomizedRequiredMark from 'components/required-mark';
import GroupButton from 'components/buttons/group-button';
import { StringParam, useQueryParam } from 'use-query-params';
import Text from 'components/typography/text';
import React from 'react';
const { TextArea } = Input;

function ModalResource({
  onClose,
  resourceId,
}: {
  resourceId?: string;
  onClose: () => void;
}) {
  const { data, isLoading } = useResource(resourceId);
  const queryClient = useQueryClient();
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveResource();
  const [form] = Form.useForm();
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [errorMessage, setErrorMessage] = useState('');
  const [language, setLanguage] = useQueryParam('language', StringParam);

  if (!language) {
    setLanguage('pt-br');
  }

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const _handleSave = (values: any) => {
    const languages = ['pt-br', 'en', 'es'];
    const missingLanguages = languages.filter(
      (lang) => !values.name?.[lang] || !values.description?.[lang]
    );

    if (missingLanguages.length > 0) {
      setErrorMessage(
        'Por favor, crie os recursos em todas as línguas disponíveis.'
      );
      return;
    }

    setErrorMessage('');
    handleSave(
      { ...values, id: resourceId },
      {
        onSuccess: () => {
          notificateSucess(
            `${
              resourceId
                ? t('app.alteracoesSalvas')
                : t('app.recursoCadastrado')
            } ${t('app.comSucesso')}`
          );
          queryClient.invalidateQueries('/v1/resource');
          onClose();
        },
        onError: (err: any) => {
          notificateError(t('app.mensagemErroCadastro'));
        },
      }
    );
  };

  const options = [
    {
      label: t('app.portugues'),
      value: 'pt-br',
    },
    {
      label: t('app.ingles'),
      value: 'en',
    },
    {
      label: t('app.espanhol'),
      value: 'es',
    },
  ];

  const profiles = [
    {
      label: 'U4Hero',
      value: 0,
    },
    {
      label: t('app.school'),
      value: 1,
    },
    {
      label: t('app.organizacao'),
      value: 2,
    },
    {
      label: 'Inkluzen',
      value: 3,
    },
    {
      label: 'B2C',
      value: 4,
    },
  ];

  return (
    <Modal
      title={
        <SubTitle primary>
          {resourceId ? t('app.editar') : t('app.criar')} {t('app.recurso')}
        </SubTitle>
      }
      open={true}
      onCancel={() => {
        if (form.isFieldsTouched()) {
          openNotification({ onClose, colorPrimary, isMobile });
        } else {
          onClose();
        }
      }}
      footer={null}
      width={isMobile ? '90%' : 598}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Form
        id="resource-creation-modal"
        onFinish={_handleSave}
        validateTrigger="onSubmit"
        layout="vertical"
        requiredMark={CustomizedRequiredMark}
        form={form}
        initialValues={data || undefined}
      >
        {isLoadingSave ? <Spinner /> : null}
        <Flex justify="flex-end">
          <Flex vertical>
            <GroupButton
              options={options}
              value={language as string}
              onChange={(v) => setLanguage(v as string)}
            />
            {errorMessage && (
              <p style={{ fontSize: '14px', color: 'red' }}>{errorMessage}</p>
            )}
          </Flex>
        </Flex>

        {['pt-br', 'en', 'es'].map((lang) => (
          <React.Fragment key={lang}>
            <Form.Item
              label={t('app.nome')}
              name={['name', lang]}
              hidden={language !== lang}
            >
              <Input
                id={`id-tour-my-school-resource-modal-input-name-${lang}`}
                size={isMobile ? 'middle' : 'large'}
                placeholder={t('app.placeholderNomeRecurso')}
              />
            </Form.Item>

            <Form.Item
              label={t('app.descricao')}
              name={['description', lang]}
              hidden={language !== lang}
            >
              <TextArea
                id={`id-tour-my-school-resource-modal-input-description-${lang}`}
                size={isMobile ? 'middle' : 'large'}
                placeholder={t('app.placeholderDescricaoRecurso')}
                rows={3}
              />
            </Form.Item>
          </React.Fragment>
        ))}

        <Flex
          justify="space-between"
          vertical={isMobile}
          style={{ width: '100%' }}
          gap={isMobile ? '0px' : '24px'}
        >
          <Form.Item
            label={'Tag'}
            rules={[
              {
                required: true,
                message: 'Defina a tag',
              },
            ]}
            name="tag"
            style={{ width: '100%' }}
          >
            <Input
              id="id-tour-my-school-resource-modal-input-name"
              size={isMobile ? 'middle' : 'large'}
              placeholder={t('app.placeholderNomeRecurso')}
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item
            label={'Permissão para perfil de:'}
            rules={[
              {
                required: true,
                message: 'Por favor, atribua um perfil',
              },
            ]}
            name="type"
            style={{ width: '100%' }}
          >
            <Select
              id="id-tour-my-school-resource-modal-input-name"
              placeholder={'Selecione um perfil'}
              options={profiles}
              size={isMobile ? 'middle' : 'large'}
            />
          </Form.Item>
        </Flex>

        <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
          <Button
            variant="outline"
            size="md"
            disabled={isLoading || isLoadingSave}
            onClick={() => {
              if (form.isFieldsTouched()) {
                openNotification({ onClose, colorPrimary, isMobile });
              } else {
                onClose();
              }
            }}
            id="id-tour-my-school-resource-modal-button-cancel"
          >
            {t('app.cancelar')}
          </Button>

          <Button
            htmlType="submit"
            size="md"
            loading={isLoading || isLoadingSave}
            id="id-tour-my-school-resource-modal-button-save"
          >
            {t('app.salvar')}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
}

export default ModalResource;
