import { useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Flex, Upload } from 'antd';
import Swal from 'sweetalert2';
import axios from 'infra/http';
import SvgIcon from 'components/SvgIcon';
import Button from 'components/buttons/button';
import { FiTrash2 } from 'react-icons/fi';

const UploadImage = ({
  value,
  onChange,
  locale,
  apiPath = `/v1/file/localization?locale=${locale}`,
  eSvg,
  id,
}: {
  value: any;
  onChange: (arg: string | null) => any;
  locale?: string;
  apiPath?: string;
  eSvg?: boolean;
  id?: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [isSvg, setIsSvg] = useState(eSvg);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleUpload = async (file: any) => {
    setIsSvg(file.type === 'image/svg+xml');

    if (
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/svg+xml'
    ) {
      Swal.fire({
        title: 'Aviso',
        text: 'Formato de imagem inválido',
        icon: 'info',
      });
      return null;
    }

    if (file.size > 3e6) {
      Swal.fire({
        title: 'Aviso',
        text: 'Tamanho da imagem inválido',
        icon: 'info',
      });
      return null;
    }
    const formData = new FormData();

    formData.append('file', file);

    try {
      setLoading(true);
      const { data }: { data: { result: string } } = await axios.post(
        apiPath,
        formData
      );
      onChange(data.result);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex>
      <Upload
        id={id}
        customRequest={(action: any) => handleUpload(action.file)}
        accept={'image/png, image/jpeg, image/svg+xml'}
        name="logo"
        listType="picture-card"
      >
        {value ? (
          isSvg ? (
            <SvgIcon url={value} color="black" size={60} />
          ) : (
            <img src={value} alt="logo" style={{ width: '100%' }} />
          )
        ) : (
          uploadButton
        )}
      </Upload>
      {value ? (
        <Button
          icon={<FiTrash2 />}
          loading={loading}
          size="md"
          onClick={() => onChange(null)}
        >
          Remover
        </Button>
      ) : null}
    </Flex>
  );
};

export default UploadImage;
