import { DatePicker, Drawer, Dropdown, Flex, Form, Select, Spin } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import { useParams } from 'react-router';
import type { MenuProps } from 'antd';
import lumiProfile from 'assets/images/lumi-profile.png';
import dayjs from 'dayjs';
import { Input } from 'antd';
import useObservations from 'core/features/observation/hooks/useObservations';
import { useCreateObservation } from 'core/features/observation/hooks/useObservations';
import { useCurrentUser } from 'core/features/user/store';
import FilterOutlined from '@ant-design/icons/lib/icons/FilterOutlined';
import useStudentInfo from 'core/features/students/hooks/useStudentInfo';
import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import ExpansibleCommentCard from 'components/V2/expansible-comment-card';
import { observationType } from 'core/features/observation/enums';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import { useMediaQuery } from '@chakra-ui/react';
import Button from 'components/buttons/button';
import ModalPracticeSuggestions from 'components/modals/modal-practice-suggestions';
import { IObservationCreate } from 'core/features/observation/typings';
import SelectBehaviors from '../SelectBehaviors';
import { t } from 'core/resources/strings';

interface RouteParams {
  id: string;
}

function ObservationDrawer({ onClose }: { onClose: () => void }) {
  const { id } = useParams<RouteParams>();
  const student = useStudentInfo(id);
  const { colorPrimary } = useU4heroColors();
  const [form] = Form.useForm();
  const [dateFilter, setDateFilter] = useState<any>([
    dayjs().startOf('year'),
    dayjs().endOf('year'),
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const { TextArea } = Input;
  const { RangePicker } = DatePicker;
  const [filterValue, setFilterValue] = useState<number>();
  const { data, refetch } = useObservations({
    userId: id,
    startDate: dateFilter
      ? dayjs(dateFilter[0]).format('YYYY-MM-DD')
      : undefined,
    endDate: dateFilter ? dayjs(dateFilter[1]).format('YYYY-MM-DD') : undefined,
    observationType: filterValue === 9 ? undefined : filterValue,
  });
  const { mutate: handleSave, isLoading } = useCreateObservation();
  const { user } = useCurrentUser();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const type = Form.useWatch('observationType', form);
  const behaviors = Form.useWatch('studentBehavior', form);

  const onSubmit = (values: IObservationCreate) => {
    handleSave(
      {
        ...values,
        studentBehavior: values.studentBehavior.map((item) => item.value),
        date: dayjs(values.date).format('YYYY-MM-DD'),
        responsibleId: user?.id as string,
        responsibleImage: user?.imageUrl ?? '',
        studentId: id,
      },
      {
        onError: (err: any) =>
          notificateError(
            'Não foi possível salvar sua observação. Verifique sua conexão e tente novamente.'
          ),
        onSuccess: () => {
          notificateSucess('Observação salva com sucesso!');
          setIsOpen(true);
          refetch();
        },
      }
    );
  };

  const close = () => {
    if (form.isFieldsTouched()) {
      openNotification({ onClose, colorPrimary, isMobile });
      return;
    }
    onClose();
  };

  const onClick: MenuProps['onClick'] = ({ key }) => {
    setFilterValue(parseInt(key, 10));
  };

  const items: MenuProps['items'] = [
    {
      label: <p>{t('app.bomComportamento')}</p>,
      key: '0',
    },
    {
      label: <p>{t('app.mauComportamento')}</p>,
      key: '1',
    },
    {
      label: <p>{t('app.bomFicarDeOlho')}</p>,
      key: '2',
    },
    {
      label: <p>{t('app.todos')}</p>,
      key: '9',
    },
  ];

  return (
    <Drawer
      width={589}
      title={
        <Flex style={{ width: '100%' }} align="center" gap={26}>
          <img
            style={{
              borderRadius: '15px',
              left: '986px',
              top: '8px',
              width: '56px',
              height: '56px',
            }}
            src={lumiProfile}
            alt="lumi-profile"
          />
          <p style={{ fontSize: '18px', fontWeight: 400, color: 'black' }}>
            {student.data?.name}
          </p>
        </Flex>
      }
      onClose={close}
      closable={false}
      open={true}
    >
      {isOpen && (
        <ModalPracticeSuggestions
          tags={behaviors?.map((item: any) => item.relatedSkillId)}
          onClose={() => {
            setIsOpen(false);
            form.resetFields();
          }}
        />
      )}
      <Button
        type="text"
        shape="circle"
        size="sm"
        icon={<CloseOutlined />}
        onClick={close}
        style={{
          backgroundColor: 'transparent',
          position: 'absolute',
          top: '10px',
          right: '10px',
        }}
      />
      <Form
        id="id-tour-observation-drawer-form"
        onFinish={(ev) => onSubmit(ev)}
        validateTrigger="onSubmit"
        layout="vertical"
        initialValues={{
          date: dayjs(),
          studentBehavior: [],
        }}
        form={form}
      >
        <Flex gap={8} vertical>
          <p
            style={{
              fontWeight: 600,
              fontSize: '18px',
              color: colorPrimary,
            }}
          >
            {t('app.criar')} {t('app.observacao')}
          </p>
          <Flex vertical gap={8}>
            <Flex align="center" gap={'20px'} style={{ width: '100%' }}>
              <Form.Item
                name="date"
                rules={[
                  {
                    required: true,
                    message: 'Selecione a data',
                  },
                ]}
                style={{ marginBottom: '0px' }}
              >
                <DatePicker format="DD/MM/YYYY" maxDate={dayjs()} />
              </Form.Item>

              <Form.Item
                name="observationType"
                style={{ width: '100%', marginBottom: '0px' }}
                rules={[
                  {
                    required: true,
                    message: t('app.selecioneOsComportamentos'),
                  },
                ]}
              >
                <Select
                  onChange={() => {
                    form.setFieldValue('studentBehavior', []);
                  }}
                  placeholder={t('app.tipoDeObservacao')}
                  style={{ width: '100%', height: '32px', marginBottom: '0px' }}
                  options={[
                    { value: 0, label: t('app.bomComportamento') },
                    { value: 1, label: t('app.mauComportamento') },
                    { value: 2, label: t('app.bomFicarDeOlho') },
                  ]}
                />
              </Form.Item>
            </Flex>
          </Flex>
          <Flex vertical>
            <Form.Item
              name="studentBehavior"
              rules={[
                {
                  required: true,
                  message: t('app.selecioneOsComportamentos'),
                },
              ]}
              style={{ marginBottom: '0px' }}
            >
              {/* @ts-ignore */}
              <SelectBehaviors type={type} />
            </Form.Item>
          </Flex>
          <Form.Item name="observation" style={{ marginBottom: '0px' }}>
            <TextArea
              placeholder={t('app.observacoesAdicionaisOpcional')}
              style={{ height: '260px' }}
              rows={6}
            />
          </Form.Item>

          <Flex
            align="center"
            justify="end"
            gap="8px"
            style={{ borderBottom: '4px', borderTop: '6px', width: '100%' }}
          >
            <Button loading={isLoading} htmlType="submit" size="sm">
              {t('app.salvar')}
            </Button>
            <Button onClick={close} variant="outline" size="sm">
              {t('app.cancelar')}
            </Button>
          </Flex>
        </Flex>
      </Form>
      <Flex gap={8} vertical>
        <p
          style={{
            marginTop: '12px',
            marginBottom: '12px',
            fontWeight: 600,
            fontSize: '20px',
            color: colorPrimary,
          }}
        >
          {t('app.historicoDeObservacoes')}
        </p>
        <Flex vertical style={{ height: 'fit-content' }} gap={12}>
          <RangePicker
            value={dateFilter}
            style={{ height: '34px' }}
            size={'small'}
            format="DD/MM/YYYY"
            onChange={(value) => setDateFilter(value)}
          />
          <Flex justify="end">
            <Dropdown menu={{ items, onClick }} trigger={['click']}>
              <Flex
                gap="8px"
                align="center"
                style={{
                  background: '#F5F5F5',
                  padding: '8px',
                  borderRadius: '10px',
                  cursor: 'pointer',
                }}
              >
                <FilterOutlined
                  style={{
                    color: 'black',
                    fontSize: '20px',
                    marginRight: '8px',
                  }}
                />
                <RightOutlined style={{ fontSize: '10px', color: 'black' }} />
              </Flex>
            </Dropdown>
          </Flex>
        </Flex>
        {data ? (
          data?.map((observation) => (
            <ExpansibleCommentCard
              type={observationType[observation.observationType]}
              content={observation.observation}
              date={observation.date}
              imgSrc={observation.responsibleImage}
              imgAlt="user image"
              extra={observation.studentBehavior}
            />
          ))
        ) : (
          <Spin />
        )}
      </Flex>
    </Drawer>
  );
}

export default ObservationDrawer;
