// import {_plural}from 'pluralize-ptbr'
import 'pluralize-ptbr'
const p = require('pluralize-ptbr')
/**
 * Return the words you passed in the plural if condition is true.
 *
 * 
 */

export function Pluralizar(words:string, condition:boolean) {
    if (true === condition) {
        return p(words)
    }
    return words
}
