import { useMediaQuery } from "@chakra-ui/react";
import { Flex } from "antd";
import { Pluralizar } from "infra/helpers/plural";
import useU4heroColors from "infra/packages/antd/u4heroTokens";

function ViewCard() {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { colorPrimary } = useU4heroColors();

  return (
    <Flex
      style={{
        justifyContent: "space-between",
        marginTop: isMobile ? "8px" : "24px",
        borderRadius: "10px",
        width: "100%",
        background: colorPrimary,
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            "url('https://u4hero-files.s3.sa-east-1.amazonaws.com/helpers/faa8a4a9-76fe-4211-8c68-5c3744f5cd5b.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "10px",
        }}
      ></div>
      <Flex justify={'space-between'} style={{ width: "100%", minHeight: '97px' }}>
      <Flex style={{ height: "100%" }} align="start">
          <img
            style={{ zIndex: 0 }}
            height={"50%"}
            src="https://u4hero-files.s3.sa-east-1.amazonaws.com/helpers/e3b6280a-88a8-4a52-9115-e94e8a98ed31.png"
            alt="Lumi com binoculo no rosto"
          />
        </Flex>
        <Flex style={{ height: "100%" }} align="end">
          <img
            style={{ zIndex: 0 }}
            height={"50%"}
            src="https://u4hero-files.s3.sa-east-1.amazonaws.com/helpers/84001cf8-6d47-48cb-ae42-38ba485acb4c.png"
            alt="planetas no espaço observados pelo lumi"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ViewCard;
