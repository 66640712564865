import { Flex, Input } from 'antd';
import { showError } from 'infra/helpers/alerta';
import { useState } from 'react';
import useChatGPTGeneric from 'core/features/chatgpt/hooks/useChatGptGeneric';
import { IoIosSend } from 'react-icons/io';
import Button from 'components/buttons/button';
import WinkIcon from 'components/icons/WinkIcon';
import { useMediaQuery } from '@chakra-ui/media-query';
import Text from 'components/typography/text';

const { TextArea } = Input;
function PromptTab() {
  const [response, setResponse] = useState<any>();
  const [question, setQuestion] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { mutate, isLoading } = useChatGPTGeneric();
  const _handleChat = (values: any) => {
    mutate(values, {
      onSuccess: (v: any) => {
        setResponse(v);
      },
      onError: (err: any) => {
        showError(err?.response?.data?.details[0]?.message);
      },
    });
  };
  return (
    <>
      <Flex vertical style={{ height: '75vh' }} justify="space-between">
        {response ? (
          <Text size="lg" align='justify'>{response}</Text>
        ) : (
          <Flex
            vertical
            align="center"
            justify="center"
            gap="16px"
            style={{ height: '100%' }}
          >
            <WinkIcon color="#7B7B7B" size={60} />
            <p
              style={{
                fontSize: '14px',
                color: '#7B7B7B',
                textAlign: 'center',
                fontWeight: 400,
                maxWidth: isMobile ? '' : '267px',
              }}
            >
              Teste-me! Estou ansioso para demonstrar minhas habilidades.
              Aguardo seus comandos.
            </p>
          </Flex>
        )}

        <Flex gap="12px" vertical>
          <TextArea
            value={question}
            onChange={(v) => setQuestion(v.target.value)}
            rows={4}
            placeholder="Insira sua mensagem"
          />
          <Flex justify="center">
            <Button
              disabled={question === ''}
              onClick={() => _handleChat(question)}
              size="md"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                gap: '8px',
              }}
              id="id-tour-safe-space-button-send-chat-message"
              loading={isLoading}
            >
              <IoIosSend color="white" size={20} />
              ENVIAR
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

export default PromptTab;
