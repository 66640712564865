import {  Form } from 'antd';
import DoubleList from './DoubleList';

function PedagogicalTeam() {
  return (
      <Form.Item
        name={'collaborators'}
      >
        {/* @ts-ignore */}
        <DoubleList />
      </Form.Item>
  );
}

export default PedagogicalTeam;
