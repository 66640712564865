import { useQuery } from 'react-query';
import axios from 'infra/http';
import { ActivityDetails } from '../types';

export interface UseActivitiesBySchoolParams {
  page: number;
  tenantId: string;
  collaboratorId?: string;
  disableConcat?: boolean;
  startDate?: string;
  endDate?: string;
  limit?: number;
}

function useActivitiesBySchool(params: UseActivitiesBySchoolParams) {
  const fetchActivities = async (
    params: UseActivitiesBySchoolParams
  ): Promise<{ data: ActivityDetails[]; total: number }> => {
    const { data, headers } = await axios.get<ActivityDetails[]>(
      `/v2/activities/school`,
      {
        params: {
          page: params.page,
          startDate: params.startDate,
          endDate: params.endDate,
          tenantId: params.tenantId,
          collaboratorId: params.collaboratorId,
          limit: params.limit,
        },
      }
    );

    let returnData: ActivityDetails[] = [];

    returnData = [...data];

    return { data: returnData, total: Number(headers['total']) };
  };

  return useQuery(
    [
      `/v2/activities/school?page=${params.page}&publishDate=${params.startDate}&endDate=${params.endDate}&tenantId=${params.tenantId}&collaboratorId=${params.collaboratorId}&limit=${params.limit}`,
      params,
    ],
    () => fetchActivities(params),
    {
      keepPreviousData: true,
    }
  );
}

export default useActivitiesBySchool;
