import { useQuery } from "react-query";
import axios from "infra/http";
import { UserSimpleDetails } from "../typings";

function useUser(id?: string) {
  const fetchData = async (id: string): Promise<UserSimpleDetails> => {
    const { data } = await axios.get<UserSimpleDetails>(`/v2/users/${id}`);

    return data;
  };

  return useQuery([`/v2/users/${id}`], () => fetchData(id!), {
    enabled: !!id
  });
}

export default useUser;
