import { useCurrentUser } from 'core/features/user/store'
import axios from 'infra/http'
import { useState, useEffect, useCallback } from 'react'
import { reportTypes } from '../types'

export default function useReport(params: {
  userId?: string
  classId?: string
  initialDate?: string
  finalDate?: string
  activityId?: string
  tenantId?: string
  type:reportTypes
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingComment, setIsLoadingComment] = useState(false)
  const [data, setData] = useState<any>({ items: [] })

  const { user } = useCurrentUser()

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        
        const { data: result } = await axios.get(
          params.type === reportTypes.Activity ? '/v1/report/activity':
          params.type === reportTypes.School ? '/v1/report/school' : 
          params.type === reportTypes.Class ? '/v1/report/class' :'/v1/report', {
          params: {
            userId: params.userId,
            classId: params.classId,
            initialDate: params.initialDate,
            finalDate: params.finalDate,
            activityId: params.activityId,
            tenantId:params.tenantId
          },
        })

        setData(result)
      } catch (e) {
      } finally {
        setIsLoading(false)
      }
    })()
  }, [
    params.classId,
    params.userId,
    params.activityId,
    params.initialDate,
    params.finalDate,
    params.type
  ])

  const onComentar = useCallback(
    async (id, comment, commentId?: string) => {
      try {
        setIsLoadingComment(true)

        if (!commentId) {
          const { data: result } = await axios.post<any>(
            `/v2/answer/${id}/comment`,
            {
              answerId: id,
              comment,
              studentId: params.userId,
            }
          )

          data.items.forEach((item: any) => {
            if (item.id === id) {
              item.comments.push({
                id: result.data,
                comment,
                createdAt: new Date(),
                studentId: params.userId,
                user: {
                  name: user?.name,
                  imageUrl: user?.imageUrl,
                },
              })
            }
          })
        } else {
          await axios.put(`/v2/answer/${id}/comments/${commentId}`, {
            comment,
          })
        }
      } catch (e) {
      } finally {
        setIsLoadingComment(false)
      }
    },
    [params, data, user]
  )

  const onEnviarEmail = useCallback(
    async ({ email, invisibleComments = [], options = { params: {} } }) => {
      options.params = options.params ?? {}

      if (email) {
        options.params = { email }
      }

      options.params.invisibleComments = invisibleComments ?? []

      return axios.get(
        `/v1/report/pdf/${params.classId}/${params.userId}?initialDate=${
          params.initialDate ? params.initialDate : ''
        }&finalDate=${params.finalDate ? params.finalDate : ''}&activityId=${
          params.activityId ? params.activityId : ''
        }`,
        {
          ...options,
          invisibleComments,
        }
      )
    },
    [params]
  )

  return { isLoading, isLoadingComment, data, onEnviarEmail, onComentar }
}
