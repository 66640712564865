import { useQuery } from "react-query";
import axios from "infra/http";
import { TenantDetails } from "../typings";

export function useTenantV1(id?: string) {
  const fetchData = async (id: string): Promise<TenantDetails> => {
    const { data } = await axios.get<TenantDetails>(`/v1/tenant/${id}`);

    return data;
  };

  return useQuery([`/v1/tenant/${id}`], () => fetchData(id!), {
    enabled: !!id,
  });
}
