import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

function PieGraph({
  categories,
  series,
  colors,
}: {
  categories: string[];
  series: ApexAxisChartSeries | number[];
  colors?: string[];
}) {
  const options: ApexOptions = {
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: categories,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    colors: colors,
  };

  return (
    <ReactApexChart options={options} series={series} type="pie" height={380} />
  );
}

export default PieGraph;
