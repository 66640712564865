import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

function BarGraph({
  categories,
  series,
  colors,
}: {
  categories: string[];
  series: ApexAxisChartSeries;
  colors?: string[];
}) {
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
    },
    colors: colors,
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />
  );
}

export default BarGraph;
