import { Col, Flex, Row } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Text from 'components/typography/text';
import Title from 'components/typography/title';
import lumiPlaying from 'assets/images/lumi-playing.png';
import googles from 'assets/images/googles.png';
import controller from 'assets/images/controller.png';
import SubTitle from 'components/typography/subTitle';
import { PiWarningDiamond } from 'react-icons/pi';
import { useMediaQuery } from '@chakra-ui/react';
import GamesCarousels from '../../gameCarousels';
import useGamesBeingUsed from 'core/features/games/hooks/useGamesBeingUsed';
import moment from 'moment';
import { t } from 'core/resources/strings';

function Overview() {
  const { colorPrimary, colorGreyBorderAndFont, colorPrimaryMedium } =
    useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data } = useGamesBeingUsed({
    startDate: moment().startOf('year').format('YYYY-MM-DD'),
    endDate: moment().endOf('year').format('YYYY-MM-DD'),
  });

  const currentYear = moment().year();

  return (
    <Row gutter={[24, 24]} style={{ minWidth: '100%' }} align="stretch">
      <Col md={10} xs={24}>
        <Flex
          style={{
            background: colorPrimary,
            borderRadius: '20px',
            padding: '16px',
            position: 'relative',
            height: isMobile ? '107px' : '100%',
            marginTop: isMobile ? '30px' : undefined,
          }}
          gap={12}
        >
          <Flex
            style={{ position: 'absolute', bottom: 0, left: 28 }}
            gap={12}
            align="center"
          >
            <img src={lumiPlaying} />
            <Text
              size="md"
              align="center"
              color="white"
              style={{ maxWidth: '302px', marginTop: '36px' }}
            >
              {t('app.acompanheOsIndicadores')}
            </Text>
          </Flex>
        </Flex>
      </Col>
      <Col md={7} xs={24}>
        <Flex
          style={{
            background: '#F4F4F4',
            borderRadius: '20px',
            padding: '4px 12px',
            boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.10)',
          }}
          align="center"
          justify="space-around"
          gap={16}
        >
          <img src={controller} />
          <Flex vertical align="center">
            <Title primary>
              {' '}
              {(data && data?.total > 9) || data?.total === 0
                ? data?.total
                : `0${data?.total}`}
            </Title>
            <Text size="sm" align="center">
              {t('app.jogosSendoUtilizados')}
            </Text>
          </Flex>
        </Flex>
      </Col>

      <Col md={7} xs={24}>
        <Flex
          style={{
            background: '#F4F4F4',
            borderRadius: '20px',
            padding: '4px 12px',
            boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.10)',
          }}
          gap={16}
          align="center"
          justify="space-around"
        >
          <img src={googles} />
          <Flex vertical align="center">
            <Title primary>{t('app.emBreve')}</Title>
            <Text size="sm" align="center">
              {t('app.taxaDeParticipacao')}
            </Text>
          </Flex>
        </Flex>
      </Col>
      <Col md={17} xs={24}>
        <GamesCarousels />
      </Col>
      <Col md={7} xs={24}>
        <Flex
          className="box-shadow-generic-card"
          style={{ padding: '16px', position: 'relative', height: '100%' }}
          vertical
        >
          <SubTitle primary>{t('app.ultimosAcessos')}</SubTitle>
          <Text
            color={colorGreyBorderAndFont}
            style={{ position: 'absolute', top: 8, right: 16 }}
          >
            {currentYear}
          </Text>
          <Flex
            vertical
            align="center"
            justify="center"
            gap={16}
            style={{ height: '100%' }}
          >
            <PiWarningDiamond color={colorGreyBorderAndFont} size={56} />
            <Text color={colorGreyBorderAndFont}>
              {t('app.emBreve')}
              {/* Não há informações para serem exibidas! */}
            </Text>
          </Flex>
        </Flex>
      </Col>
    </Row>
  );
}

export default Overview;
