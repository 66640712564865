import { Flex, Modal } from 'antd';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { t } from 'core/resources/strings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { MdExpandMore } from 'react-icons/md';

function ModalFinishTour({
  title,
  message,
  backgroundImage,
  congratsImage,
  onClose,
  onNext,
  onComplete,
  current,
  total,
}: {
  title: string;
  message: string;
  backgroundImage: string;
  congratsImage: string;
  onClose: () => void;
  onNext: () => void;
  onComplete: () => void;
  current: number;
  total: number;
}) {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  return (
    <Modal
      centered
      maskClosable={false}
      open={true}
      footer={null}
      closeIcon={null}
      wrapClassName="custom-modal-initial-tour"
    >
      <div
        style={{ height: '420px', overflow: 'hidden', position: 'relative' }}
      >
        <img src={backgroundImage} width="100%" />
        <div
          style={{
            width: '100%',
            height: '400px',
            borderRadius: '50% 50% 50% 49% / 42% 43% 0% 0%',
            background:
              'linear-gradient(180deg, rgba(255, 255, 255, 0.42) 0%, #FFF 24.5%)',
            marginTop: '-130px',
            zIndex: 30,
            position: 'relative',
          }}
        ></div>

        <Flex
          justify="center"
          align="end"
          style={{
            position: 'absolute',
            top: 0,
            zIndex: 200,
            height: '100%',
            width: '100%',
          }}
        >
          <img src={congratsImage} />
        </Flex>
      </div>

      <Flex vertical gap="8px" style={{ padding: '0px 16px 16px 16px' }}>
        <SubTitle primary>{title}</SubTitle>
        <Text size="lg" align="justify">
          {message}
        </Text>

        {current !== total ? (
          <Flex
            vertical
            justify="center"
            align="center"
            gap="8px"
            style={{ marginTop: '12px' }}
          >
            <Button size="md" onClick={onNext}>
              {t('app.continuar')}
            </Button>
            <Flex
              align="center"
              style={{ cursor: 'pointer' }}
              onClick={onClose}
            >
              <Text size="sm" color={colorGreyBorderAndFont}>
                {t('app.pular')}
              </Text>
              <MdExpandMore
                color={colorGreyBorderAndFont}
                style={{
                  transform: 'rotate(270deg)',
                }}
                size={20}
              />
            </Flex>
          </Flex>
        ) : (
          <Flex
            vertical
            justify="center"
            align="center"
            gap="8px"
            style={{ marginTop: '12px' }}
          >
            <Button size="md" onClick={onComplete}>
              {t('app.terminar')}
            </Button>
          </Flex>
        )}

        <Text size="lg" align="end">
          <span style={{ color: colorPrimary, fontWeight: 700 }}>
            {current}
          </span>
          /{total}
        </Text>
      </Flex>
    </Modal>
  );
}

export default ModalFinishTour;
