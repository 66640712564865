import { Carousel } from 'antd';
import styled from 'styled-components';

export const CarouselWrapper = styled(Carousel)`
  > .slick-dots li button {
    width: 12px;
    height: 12px;
    border-radius: 100%;
  }
  > .slick-dots li.slick-active button {
    width: 12px;
    height: 12px;
    border-radius: 100%;
  }
`;
