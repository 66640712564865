import { useMutation } from "react-query";
import http from "infra/http";

export function useUpdatePassword() {
  return useMutation(
    (data: { password: string, newPassword:string }) => {
      return http.put(`/v1/user/current-password`, data);
    },
    { retry: false }
  );
}
