import { useQuery } from "react-query";
import axios from "infra/http";
import { IStudentInfo } from "../types";

function useStudents() {
  return useQuery('/v2/inkluzen/students', async ():Promise<IStudentInfo[]> => {
    const { data } = await axios.get<IStudentInfo[]>('/v2/inkluzen/students');
    
    return data;
  });

}

export default useStudents;