import { Box, Grid, GridItem, useMediaQuery } from "@chakra-ui/react";
import { t } from "core/resources/strings";
import { useState } from "react";
import SelectorClass, { SelectorClassTypes } from "../SelectorClass";
import SubMenuLateral from "../SubMenuLateral";

export interface ReportSubMenuSteps {
  label: string;
  order: number;
  id: string;
}

export const reportSubMenuSteps: ReportSubMenuSteps[] = [
  {
    label: t('app.atividades'),
    order: 0,
    id:'id-tour-report-class-button-activities'
  },
  {
    label: t('app.periodo'),
    order: 1,
    id:'id-tour-report-class-button-period'
  }
]

function TabTurma() {
  const [tab, setTab] = useState(0);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <Grid
        mt="15px"
        gap={4}
        templateRows='repeat(1fr)'
        templateColumns='repeat(7, 1fr)'>

        <GridItem colSpan={ isMobile ? 8:1} >
          <Box pos="relative" bg="gray.50" py="16px" px="24px" rounded="8px" >

            <SubMenuLateral
              step={tab!}
              stepsLabel={reportSubMenuSteps}
              onChange={(v) => setTab(v)} />

          </Box>
        </GridItem>

        <GridItem colSpan={6}>
          {tab === 0 ? <SelectorClass secType={SelectorClassTypes.activity}  /> : null}
          {tab === 1 ? <SelectorClass secType={SelectorClassTypes.period}  /> : null}
        </GridItem>
      </Grid>
    </>
  );
}

export default TabTurma;
