import { useQuery } from 'react-query';
import axios from 'infra/http';
import { ActivitySimpleDetails } from 'core/features/students/typings';

function useActivitySimpleDetails(id?: string) {
  const fetchData = async (id: string): Promise<ActivitySimpleDetails> => {
    const { data } = await axios.get<ActivitySimpleDetails>(
      `/v2/activities/simple-details/${id}`
    );

    return data;
  };

  return useQuery(
    [`/v2/activities/simple-details/${id}`, id],
    () => fetchData(id!),
    {
      enabled: !!id,
    }
  );
}

export default useActivitySimpleDetails;
