import { useMediaQuery } from '@chakra-ui/react';
import { Col, Collapse, Flex, Row, Spin } from 'antd';
import BackButton from 'components/buttons/back-button';
import Layout from 'components/layout';
import SvgIcon from 'components/SvgIcon';
import useTagsWithoutParent from 'core/features/tags/hooks/useTagsWithoutParent';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useHistory } from 'react-router';
import CompetenceItem from './components/competence-item';
import SkillItem from './components/skill-item';
import Text from 'components/typography/text';
import Title from 'components/typography/title';
import { TagDetails } from 'core/features/tags/types';
import React from 'react';
import { TagType } from 'core/features/competence/typings';

function Tags() {
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data, isLoading } = useTagsWithoutParent();
  const { colorPrimary } = useU4heroColors();

  const groupedTags = data?.reduce((acc, tag) => {
    if (!acc[tag.type]) {
      acc[tag.type] = [];
    }
    acc[tag.type].push(tag);
    return acc;
  }, {} as Record<number, TagDetails[]>);

  const renderTagsByType = (type: number) => (
    <React.Fragment key={type}>
      <Title primary>{TagType[type]}</Title>
      {isMobile ? (
        <Flex vertical gap="16px" style={{ marginBottom: '16px' }}>
          {groupedTags?.[type]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <CompetenceItem
                key={item.id}
                name={item.name}
                icon={item.icon ?? undefined}
                description={item.description ? item.description['pt-br'] : ''}
                id={item.id}
              />
            ))}
        </Flex>
      ) : (
        groupedTags?.[type]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item) => (
            <Collapse
              key={item.id}
              items={[
                {
                  key: item.id,
                  label: (
                    <Flex vertical gap="4px">
                      <Flex gap="16px" align="center">
                        <SvgIcon url={item.icon ?? ''} color="#000" size={50} />
                        <p
                          style={{
                            fontSize: '18px',
                            color: 'black',
                            fontWeight: 400,
                          }}
                        >
                          {item.name}
                        </p>
                      </Flex>
                      <p
                        style={{
                          fontSize: isMobile ? '14px' : '18px',
                          fontWeight: 400,
                          textAlign: 'justify',
                        }}
                      >
                        {item.description ? item.description['pt-br'] : ''}
                      </p>
                    </Flex>
                  ),
                  children: (
                    <Row gutter={[24, 16]} style={{ marginTop: '16px' }}>
                      {item.children.map((child) => (
                        <Col span={8} key={child.id}>
                          <SkillItem
                            name={child.name}
                            description={
                              child.description
                                ? child.description['pt-br']
                                : ''
                            }
                            icon={child.icon ?? undefined}
                          />
                        </Col>
                      ))}
                    </Row>
                  ),
                },
              ]}
              expandIconPosition="end"
            />
          ))
      )}
    </React.Fragment>
  );
  return (
    <Layout.Container>
      <Flex vertical gap="24px">
        <Flex gap={isMobile ? '12px' : '24px'} align="center">
          <BackButton onClick={() => history.push('/')} />
          <Title primary style={{ width: 'fit-content' }}>
            Campos de Conhecimentos e Habilidades
          </Title>
        </Flex>
        <Flex
          justify="space-between"
          style={{
            background: colorPrimary,
            borderRadius: '20px',
            position: 'relative',
          }}
        >
          {isMobile ? (
            <Flex vertical>
              <Flex justify="space-between" align="center">
                <Title
                  color="white"
                  align="center"
                  style={{ lineHeight: 'normal' }}
                >
                  Campos de Conhecimentos e Habilidades
                </Title>
              </Flex>
              <Text style={{ padding: '8px' }} color="white" align="justify">
                Os "campos de conhecimento" abrangem diversas áreas essenciais
                para o desenvolvimento dos estudantes, como competências, clima
                escolar, treinamentos e pesquisas, que são denominadas áreas de
                análise. Esses campos ajudam a promover o crescimento integral
                dos estudantes, identificando oportunidades de melhoria e
                fortalecendo suas habilidades.
              </Text>
            </Flex>
          ) : (
            <>
              <Flex
                vertical
                gap="12px"
                style={{
                  paddingLeft: '50px',
                  paddingTop: '18px',
                  paddingBottom: '16px',
                }}
                justify="center"
              >
                <Title
                  color="white"
                  style={{
                    maxWidth: '570px',
                  }}
                >
                  Campos de Conhecimentos e Habilidades
                </Title>
                <p
                  style={{
                    color: 'white',
                    fontSize: '18px',
                    fontWeight: 400,
                    textAlign: 'justify',
                    maxWidth: '574px',
                  }}
                >
                  Os "campos de conhecimento" abrangem diversas áreas essenciais
                  para o desenvolvimento dos estudantes, como competências,
                  clima escolar, treinamentos e pesquisas, que são denominadas
                  áreas de análise. Esses campos ajudam a promover o crescimento
                  integral dos estudantes, identificando oportunidades de
                  melhoria e fortalecendo suas habilidades.
                </p>
              </Flex>
              <img
                alt="lumi-lendo"
                src="/lumi-lendo.png"
                style={{
                  width: '366px',
                  height: '335px',
                  borderRadius: '20px',
                  objectFit: 'contain',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                }}
              />
            </>
          )}
        </Flex>
        {isLoading && <Spin size="large" />}

        {groupedTags &&
          Object.keys(groupedTags).map((type) =>
            renderTagsByType(Number(type))
          )}
      </Flex>
    </Layout.Container>
  );
}

export default Tags;
