import { useQuery } from 'react-query';
import axios from 'infra/http';
import { TenantDetails } from '../typings';
import { useEffect, useState } from 'react';

export interface UseTenantsParams {
  page: number;
  limit?: number;
  search?: string;
  parentId?: string;
  tenants?: string[];
  active?:boolean;
  disableConcat?: boolean;
  states?:string[];
  master?: boolean;
  createdAt?:string;
}

function useTenants(params: UseTenantsParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchTenants = async (
    params: UseTenantsParams
  ): Promise<{ data: TenantDetails[]; total: number }> => {
    const { data, headers } = await axios.get<TenantDetails[]>(`/v1/tenant`, {
      params: {
        page: params.page,
        search: debouncedQuery,
        limit: params.limit,
        parentId: params.parentId,
        master: params.master,
        active:params.active,
        tenants:params.tenants,
        createdAt:params.createdAt,
        states:params.states
      },
    });

    return { data: data, total: Number(headers['total']) };
  };

  return useQuery(
    [
      `/v1/tenant`,
      {...params,search:debouncedQuery},
    ],
    () => fetchTenants(params),
    {
      keepPreviousData: true,
    }
  );
}

export default useTenants;
