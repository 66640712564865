import { useMediaQuery } from '@chakra-ui/media-query';
import PlusButton from 'components/plus-button';
import { ReactNode } from 'react';
import { StyledBox } from './styles';

function InfoBox({
  icon,
  title,
  info,
  button,
  action,
}: {
  icon: ReactNode;
  title: string;
  info: string | number | undefined;
  button?: boolean;
  action?: () => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <StyledBox isMobile={isMobile}>
      {button ? (
        <PlusButton style={{ position: 'absolute', top: 8, right: 8 }} onClick={action}/>
      ) : null}
      {icon}
      <div>
      <p
        style={{
          fontSize: 14,
          textAlign: 'center',
          fontWeight: 400,
        }}
      >
        {title}
      </p>
      <p
        style={{
          fontSize: typeof(info) === 'number' ? 24:20,
          fontWeight: 600,
          textAlign: 'center',
        }}
      >
        {info}
      </p>
      </div>
    
    </StyledBox>
  );
}

export default InfoBox;
