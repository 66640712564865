import Layout from "components/layout";
import StudentInformation from "../components/student-information";
import { Button, DatePicker, Flex, Typography } from "antd";
import useU4heroColors from "infra/packages/antd/u4heroTokens";
import { GenericCard } from "components/V2/generic-card-styled-component/index";
import AnotationDrawer from "../components/annotation-drawer";
import { useState } from "react";
import StudentProcessGraph from "../pages/student-process-graph";
import StudentProcessTimeline from "../pages/student-process-timeline";
import { UnorderedListOutlined } from "@ant-design/icons";
import { BarChartOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useMediaQuery } from "@chakra-ui/media-query";
import { ArrowLeftOutlined } from '@ant-design/icons';
import FilterButton from "./component/element-filter-button";

function StudentProcess() {
  const { colorPrimary, colorSecondaryLight } = useU4heroColors();
  const { RangePicker } = DatePicker;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [tabSelected, setTabSelected] = useState('graph');
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { Title } = Typography;

  const showDrawer = () => {
    setIsDrawerOpen(true);
  }

  const onClose = (emptyForm: () => void) => {
    setIsDrawerOpen(false);
    emptyForm();
  }

  return (
    <Layout.Container>
      <Flex style={{ marginBottom: '15px' }} gap={isMobile ? '19px' : '40px'} align='center'>
        <Button
          type="primary"
          size={isMobile ? 'middle' : 'large'}
          icon={<ArrowLeftOutlined  />}
          onClick={() => history.push('/inkluzen/students')}
        />
        <Title
          level={1}
          style={{
            color: colorPrimary,
            fontSize: isMobile ? '24px' : '28px',
            fontWeight: 700,
            margin: '0px'
          }}
        >
          Detalhamento do Estudante
        </Title>
      </Flex>
      <Flex gap={32}>
        <GenericCard width='80%'>
          <Flex style={{ width: '100%', height: '100%' }} vertical>
            <Flex justify="space-between" style={{ width: '100%', height: '36px' }}>
              <h3 style={
                {
                  color: colorPrimary,
                  fontSize: 24,
                  fontWeight: 600,
                }
              }>{tabSelected === 'graph' ? 'Gráfico de Processamentos' : 'Linha do Tempo de Processamentos'  }</h3>
              <RangePicker style={{ width: '236px' }} size="small" />
            </Flex>
            <Flex vertical style={{ width: '100%', height: '100%' }}>
              <Flex
                style={{
                  borderRadius: '10px',
                  background: colorSecondaryLight,
                  fontSize: '18px',
                  fontWeight: 400,
                  padding: '4px 8px',
                  marginRight: '15px',
                  alignSelf: 'end',
                  marginTop:'16px',
                  marginBottom:'16px'
                }}
                gap="8px"
              >
                <FilterButton
                  name={<BarChartOutlined />}
                  value="graph"
                  state={tabSelected}
                  onChange={setTabSelected}
                />
                <FilterButton
                  name={<UnorderedListOutlined />}
                  value="list"
                  state={tabSelected}
                  onChange={setTabSelected}
                />
              </Flex>
              { tabSelected === 'graph' ? <StudentProcessGraph /> : <StudentProcessTimeline /> }
            </Flex>
          </Flex>
        </GenericCard>
        <StudentInformation type="student" fixed={false} showDrawer={showDrawer} />
        {isDrawerOpen && <AnotationDrawer pageType="student" onClose={onClose} />}

      </Flex>
    </Layout.Container>
  );
}

export default StudentProcess;