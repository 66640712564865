import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Tabs } from 'antd';
import Layout from 'components/layout';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { NumberParam, useQueryParam } from 'use-query-params';
import OrganizationTab from './Tabs/OrganizationTab';
import SchoolsTab from './Tabs/SchoolTab';
import cosmo from 'assets/images/cosmo-with-world-and-books.png';
import useCheckPermission from 'core/features/user/hooks/useCheckPermission';
import { Permissions } from 'core/resources/enums/permissions';

function Schools() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [step, setStep] = useQueryParam('tab', NumberParam);
  const { colorGreyBorderAndFont, colorPrimary } = useU4heroColors();
  const [check] = useCheckPermission();
  const isU4Hero = check(Permissions.U4hero.Tenant.View);
  if (!step) {
    setStep(!isU4Hero ? 2 : 1);
  }

  const items = [
    {
      key: '1',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 1 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          ORGANIZAÇÕES
        </p>
      ),
      children: <OrganizationTab />,
    },
    {
      key: '2',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 2 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          ESCOLAS
        </p>
      ),
      children: <SchoolsTab />,
    },
  ];
  if(!isU4Hero){
    items.shift();
  }
  return (
    <Layout.Container>
      <Title primary>Instituições</Title>
      <div
        style={{
          backgroundImage: "url('/estrelas_1.png')",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: colorPrimary,
          borderRadius: '20px',
          position: 'relative',
          height: isMobile ? '139px' : '113px',
          padding: '16px',
          marginBottom: '24px',
          marginTop: '12px',
        }}
      >
        <Flex
          vertical
          gap={isMobile ? '16px' : '8px'}
          style={{ maxWidth: 509 }}
        >
          {isMobile ? (
            <Flex justify="space-between">
              <img
                src={cosmo}
                style={{
                  position: 'absolute',
                  top: -54,
                  right: 0,
                  overflow: 'visible',
                }}
                width={'90px'}
              />
            </Flex>
          ) : null}
          <Text size="lg" color="white" align="justify">
            Aqui, você terá a facilidade de gerenciar suas organizações e escolas, mantendo tudo organizado de forma simples e eficiente.
          </Text>
        </Flex>
        {!isMobile && (
          <img
            src={cosmo}
            style={{
              position: 'absolute',
              bottom: 0,
              top: undefined,
              right: 54,
              overflow: 'visible',
            }}
          />
        )}
      </div>
      <Tabs
        centered
        onTabClick={(event) => setStep(Number(event))}
        size="large"
        defaultActiveKey={step?.toString()}
        items={items}
      />
    </Layout.Container>
  );
}

export default Schools;
