import { useQuery } from 'react-query';
import axios from 'infra/http';
import { GravityDetails } from '../typings';

export interface UseGravityParams {
  tenantId?: string;
  initialDate?: string;
  finalDate?: string;
  location?: string;
}
function useGravityByLocation(params: UseGravityParams) {
  const fetchData = async (params: UseGravityParams): Promise<GravityDetails> => {
    const { data } = await axios.get<GravityDetails>(`/v2/inkluzen/gravity-location`, {
      params: {
        tenantId: params.tenantId,
        initialDate: params.initialDate,
        finalDate: params.finalDate,
        location: params.location,
      },
    });

    return data;
  };

  return useQuery(
    [
      `/v2/inkluzen/gravity-location?tenantId=${params.tenantId}&initialDate=${params.initialDate}&finalDate=${params.finalDate}&location=${params.location}`,
    ],
    () => fetchData(params),
    {
      enabled: params.tenantId !== undefined,
    }
  );
}

export default useGravityByLocation;
