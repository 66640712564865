import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex,Typography } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
const moment = require('moment');
require('moment/locale/pt-br');

const { Title } = Typography;

function PostPreview({
  banner,
  author,
  title,
  description,
  content,
  onClose,
}: {
  banner?: string;
  duration?: number;
  maxAge?: number;
  author?: string;
  title?: string;
  content?: string;
  description?: string;
  onClose: () => void;
}) {
  const { colorPrimary, colorGreyBorderAndFont } =
    useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const currentDate = moment();
  currentDate.locale('pt-br');

  return (
    <div style={{ padding: isMobile ? '0px' : '16px' }}>
      {!isMobile ? (
        <>
          <Flex justify="flex-end" style={{ cursor: 'pointer' }}>
            <IoClose
              size={24}
              color={colorGreyBorderAndFont}
              onClick={onClose}
            />
          </Flex>
          <Title
            level={1}
            style={{
              color: colorPrimary,
              fontSize: isMobile ? '24px' : '28px',
              fontWeight: 700,
              margin: '0px',
              marginBottom: '16px',
            }}
          >
            Prévia
          </Title>
        </>
      ) : null}

      <div style={{ position: 'relative' }}>
        <img
        alt={`banner ${title}`}
          src={banner}
          style={{
            borderRadius: '10px',
            width: '100%',
            height: isMobile ? '156px' : '184px',
            objectFit: 'cover',
          }}
        />
      </div>

      <p
        style={{
          fontSize: isMobile ? '14px' : '18px',
          color: colorGreyBorderAndFont,
          textAlign: 'end',
        }}
      >
        {currentDate.format('DD [de] MMMM [de] YYYY')}
        <br />
        Autor: {author}
      </p>

      <Title
        level={2}
        style={{
          color: colorPrimary,
          fontSize: isMobile ? '16px' : '20px',
          fontWeight: 700,
          textAlign: 'justify',
          margin: '0px',
          marginBottom: '8px',
        }}
      >
        {title}
      </Title>

      <p
        style={{
          color: 'black',
          fontSize: isMobile ? '14px' : '18px',
          fontWeight: 400,
          textAlign: 'justify',
          margin: '0px',
          marginBottom: '8px',
        }}
      >
        {description}
      </p>
      <div
        dangerouslySetInnerHTML={{
          __html: content ?? '',
        }}
      />
    </div>
  );
}

export default PostPreview;
