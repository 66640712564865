import { useQuery } from "react-query";
import axios from "infra/http";
import { GroupedMessage} from "../types";

function useMessages(id?: string) {
  const fetchData = async (id: string): Promise<GroupedMessage[]> => {
    const { data } = await axios.get<GroupedMessage[]>(`/v2/chat/${id}`);

    return data;
  };

  return useQuery([`/v2/chat/${id}`], () => fetchData(id!), {
    enabled: !!id,
  });
}

export default useMessages;
