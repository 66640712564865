import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

function DonutGraph({
  categories,
  series,
  colors,
}: {
  categories: string[];
  series: ApexAxisChartSeries | number[];
  colors?: string[];
}) {
  const options: ApexOptions = {
    chart: {
      type: 'donut',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    labels: categories,
    colors: colors,
    legend: {
      show: false,
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height={380}
    />
  );
}

export default DonutGraph;
