import { Divider, Flex, Switch, Checkbox } from 'antd';
import Text from 'components/typography/text';
import SearchBar from 'components/V2/SearchBar';
import GameFilter from 'ui/pages/app/activities/pages/CreateActivity/components/StepGame/GameFilter';
import AppliedFilters from 'components/applied-filters';
import { useEffect, useState } from 'react';
import { FilterType } from 'infra/helpers/types';
import useGameDropdown from 'core/features/games/hooks/useGameDropdown';
import { DropdownItem } from 'core/features/games/typings';
import GameCard from './GameCard';
import GameCarousel from './GameCarousel';

interface Genre {
  name: string;
  games: DropdownItem[];
}
function PickGamesStep({
  value,
  onChange,
}: {
  value: string[];
  onChange: (v: string[]) => void;
}) {
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<FilterType>({
    age: [],
    genres: [],
  });
  const [genres, setGenres] = useState<Genre[]>([]);
  const { isLoading, data } = useGameDropdown({
    genres: filters.genres.map((item) => item.value) ?? undefined,
    search,
    ageClassification: filters?.age?.map((item) => item.value)[0] ?? undefined,
  });


  useEffect(() => {
    if (data) {
      const genreMap: { [key: string]: DropdownItem[] } = {};
      for (const game of data.data) {
        if (game.genre) {
          if (genreMap[game.genre]) {
            genreMap[game.genre].push(game);
          } else {
            genreMap[game.genre] = [game];
          }
        }
      }
      const updatedGenres = Object.keys(genreMap).map((key) => ({
        name: key,
        games: genreMap[key],
      }));
      setGenres(updatedGenres);
    }
  }, [data]);

  return (
    <Flex vertical gap="18px">
      <Text size="lg">
        Por favor, selecione os jogos que você gostaria de atribuir à escola.
      </Text>
      <Flex vertical>
        <Flex
          align="center"
          gap="16px"
          style={{ marginBottom: '16px', width: '100%' }}
        >
          <SearchBar
            placeholder="Pesquisar Jogo"
            width="100%"
            value={search}
            onSearch={setSearch}
          />
          <GameFilter filters={filters} onChangeFilters={setFilters} />
        </Flex>
        <AppliedFilters appliedFilters={filters} onChangeFilters={setFilters} />
        <Flex gap="24px" align="center" justify="flex-end">
          <Text size="lg">Atribuir todos os jogos</Text>
          <Switch
            onChange={(checked) => {
              if (checked) {
                onChange(data?.data.map((item) => item.id) as string[]);
              } else {
                onChange([]);
              }
            }}
            checked={data?.data.every((g) => value.includes(g.id))}
          />
        </Flex>
      </Flex>
      <Flex vertical gap="24px">
        {genres.map((item) => (
          <GameCarousel
            key={item.name}
            name={item.name}
            onClickCheckbox={(e) => {
              if (e.target.checked) {
                const newValues = value.filter(
                  (v) => !item.games.map((g) => g.id).includes(v)
                );
                onChange([...newValues, ...item.games.map((item) => item.id)]);
              } else {
                const newValues = value.filter(
                  (v) => !item.games.map((g) => g.id).includes(v)
                );
                onChange(newValues);
              }
            }}
            checked={item.games.every((g) => value.includes(g.id))}
          >
            {item.games.map((game) => (
              <GameCard
              key={game.id}
                item={game}
                isSelected={value.includes(game.id)}
                onSelect={() => {
                  if (value.includes(game.id)) {
                    onChange(value.filter((item) => item !== game.id));
                  } else {
                    onChange([...value, game.id]);
                  }
                }}
              />
            ))}
          </GameCarousel>
        ))}
      </Flex>
    </Flex>
  );
}

export default PickGamesStep;
