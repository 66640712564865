import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { AutomatedDetails } from "../types";

export interface useAutomatedActivitiesParams {
  page: number;
  responsibleId?: string ;
  tenantId?: string ;
  startSchedule?: string;
  endSchedule?: string;
  classes?: string[];
  series?: string[];
  limit?:number;
  search?:string;
}

interface AutomatedApiResponse {
  data: AutomatedDetails[];
  total: number;
}
function useAutomatedActivities(params: useAutomatedActivitiesParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);
  const fetchAutomated = async (
    params: useAutomatedActivitiesParams
  ): Promise<AutomatedApiResponse> => {
    const { data } = await axios.get<AutomatedApiResponse>(`${process.env.REACT_APP_AUTOMATED_URL}automated`, {
      params: {
        page: params.page,
        responsibleId: params.responsibleId,
        tenantId: params.tenantId,
        classes: params.classes,
        series: params.series,
        startSchedule: params.startSchedule,
        endSchedule: params.endSchedule,
        search: debouncedQuery,
        limit: params.limit,
      },
    });
    return { data:data.data, total:data.total};
  };

  return useQuery(
    [`get-automated`, {...params,search:debouncedQuery}],
    () => fetchAutomated(params),
    {
      keepPreviousData: true,
    }
  );
}

export default useAutomatedActivities;
