function ChoiceIcon({ size = 24 }: { size?: number }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="24" height="24" fill="url(#choice)" />
      <defs>
        <pattern
          id="choice"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#choiceicon" transform="scale(0.01)" />
        </pattern>
        <image
          id="choiceicon"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADZklEQVR4nO2dvW4TQRSFj0C4I+lA/NTQ+jGgIUK8RKKkAiHeAMtdeApES3gFIIJAB3QUDhIVTQyiQRq00iCF0Sben9k9Z67vJ10pUjb2mXO8s/ZcbwYol9sAngH4BOBXrOrnfQC32OLWiYsAZgD+AAhnVPW7p/FYZ0AuAHhxThBpPY9/4wzEkxZh/KvHnsYwXAXwMzG7umbcA3A51haAz8kxSwBXPJT8PKoJY6PmuE0AX5JjH65TIBMAewAOa17BQ9bWOZruj6ijGvNbALvRCyrXAXwccfDhVFVT1FlskDR9AHANJCbEMBYN9B2TtB0BuAQCe8Qw7jTQdzcey9C4AwKHiYiXcQprSzqYsen7/DcAHCSP8QYElomIShjWMJCKm8ljnIBALiMtBKIwDjMDCUbGYWYgwcg4zAwkGBlHNgEnmd4csC/GZgJJ3z4fjBRKFcarjG9XzQSyW/NYgVTbAn7QBUziGlAg17ueSx5mAkFckDsihvE+w6KgqUAQX507AF7XrAIMUcv4XNuZFgPNBVI6dD/oAsSg+0EXIAbdD7oAMeh+0AWIQfeDLkAMuh90AWLQ/aALEIPuB12AGHQ/6ALEoPtBFyAG3Q+6ADHofnQV8ADAjwaLf8fxy26lUGwg3xuEEVp8ZVSFYgNpGkYobCqk6/ZAjAbS9XHnAH53OOPa1teG17K1DmQ6QhCn69uAfmTDA/mftQ4E8V716p8F+JQV8Yu60TNkVZUCXXdXAW3u/VugHIoNpOm9f4uG9xKqUGwgVglsP+gCxKD7QRcgBt0PugAx6H50FeD9ELFAvB8yEP7B0MiU5Z/UjS4uzr0fohPItMOZ1qe8HwIPpLgpa+b9kGECWVWlQNftgRgJxPshYoF4P8TqKSoG3Q+6ADHoftAFiEH3gy5ADLofXQV4P0QsEO+HDIR/MDQyZfnSidHFxbn3Q3QCmXY40/qU90PggRQ3Zc28HzJMIKuqFOi6PRAjgXg/RCwQ74dYPUXFoPtBFyAG3Q+6ADHoftAFiEH3gy5ADLofdAFi0P2gCxCD7gddgBh0P+gCxKD7QRcgBt0PugAx6H7QBYhB94O5Q6caEtt3s3boVCP3jqEmdugMYtVnx9Did+gMYtV3x9Cid+gMYpVjx9DidugMYpV7x1DHcRzHQRf+AtKdQ6wKY64LAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
}

export default ChoiceIcon;
