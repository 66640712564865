import useCheckPermission from "core/features/user/hooks/useCheckPermission";
import { useCurrentUser } from "core/features/user/store";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

export interface RouteAuthorizedProps extends RouteProps {
  permissions?: any;
}

const RouteAuthorized: React.FC<RouteAuthorizedProps> = ({
  permissions,
  ...props
}) => {
  const { user } = useCurrentUser();
  const [check] = useCheckPermission();

  if (!user) {
    return <Redirect to="/auth/login" />;
  }
  
  if (permissions) {

    const possuiPermissao = permissions.filter((item:any)=> check(item) === true ).length;

    if (!possuiPermissao) {
      return <Redirect to="/errors/no-permission" />;
    }
  }

  //   if (permissions !== undefined && !hasPermission) {
  //     return <Redirect to="/errors/sem-permissao" />;
  //   }
  return <Route {...props} />;
};

export default RouteAuthorized;
