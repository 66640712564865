import { Flex } from 'antd';
import SvgIcon from 'components/SvgIcon';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { MdExpandMore } from 'react-icons/md';
import { useHistory } from 'react-router';

function CompetenceItem({
  name,
  icon,
  description,
  id,
}: {
  name: string;
  icon: string | undefined;
  description: string;
  id: string;
}) {
  const history = useHistory();
  const { colorGreyBorderAndFont } = useU4heroColors();

  return (
    <Flex
      vertical
      gap="4px"
      style={{
        background: '#F5F5F5',
        borderRadius: '10px',
        padding: '8px',
      }}
    >
      <Flex gap="16px" align="center">
        <SvgIcon url={icon ?? ''} color='#000' size={30} />
        <p
          style={{
            fontSize: '16px',
            color: 'black',
            fontWeight: 400,
          }}
        >
          {name}
        </p>
      </Flex>
      <p
        style={{
          fontSize: '14px',
          fontWeight: 400,
          textAlign: 'justify',
          color: 'black',
        }}
      >
        {description}
      </p>
      <Flex
        justify="center"
        align="center"
        gap="8px"
        onClick={() => history.push(`/tags/${id}`)}
      >
        <p
          style={{
            color: colorGreyBorderAndFont,
            fontSize: '12px',
            fontWeight: 500,
          }}
        >
          Habilidades
        </p>
        <MdExpandMore
          style={{
            transform: 'rotate(-90deg)',
          }}
          color={colorGreyBorderAndFont}
          size={15}
        />
      </Flex>
    </Flex>
  );
}

export default CompetenceItem;
