import { useMediaQuery } from '@chakra-ui/react';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import {
  Option,
  OptionNumber,
  OptionDescription,
  OptionPercent,
} from './styles';

function QuestionNormal({ option, answer, index, optionClass }: any) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary, colorPrimaryMedium, colorSecondary } =
    useU4heroColors();
  return (
    <Option>
      <OptionNumber
        isMobile={isMobile}
        color={
          option.id === answer?.id
            ? hex2rgba(colorSecondary, 0.5)
            : hex2rgba(colorPrimaryMedium, 0.5)
        }
      >
        {['A', 'B', 'C', 'D', 'E'][index]}
      </OptionNumber>
      <OptionDescription
      isMobile={isMobile}
      >
        {option.name}
      </OptionDescription>

      <OptionPercent color={hex2rgba(colorPrimary,0.5)}  isMobile={isMobile}>
        {optionClass?.percentage}
      </OptionPercent>
    </Option>
  );
}

export default QuestionNormal;
