import React, { useState } from 'react';
import { Menu, Dropdown, Checkbox, Flex } from 'antd';
import Table from '../table';
import { FiSettings } from 'react-icons/fi';
import { MdCancel, MdExpandMore } from 'react-icons/md';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import SearchBar from 'components/V2/SearchBar';
import TrashCan from '../trash-can';
import AppliedFilters from 'components/applied-filters';
import Button from 'components/buttons/button';

interface Row {
  id?: string;
  [key: string]: any;
}

interface DynamicTableProps {
  data: Row[];
  defaultColumns: any[];
  allColumns: any[];
  onChangePage: (v: number) => void;
  onChangePageSize: (v: number) => void;
  total: number;
  page: number;
  pageSize: number;
  itemName: string;
  isLoading?: boolean;
  onClickRow?: (item: any) => void;
  onSearch?: (item: any) => void;
  search?: string;
  title?: any;
  filters?: any;
  id?: string;
  rowSelection?: any;
  selected?: boolean;
  onDelete?: () => void;
  hideCog?: boolean;
  appliedFilters?: {
    [key: string]: {
      value: any;
      label: string;
    }[];
  };
  onChangeFilters?: (v: any) => void;
  expandable?: any;
  searchBarPlaceholder?:string;
}
const DynamicTable: React.FC<DynamicTableProps> = ({
  data,
  defaultColumns,
  allColumns,
  onChangePage,
  onChangePageSize,
  total,
  page,
  pageSize,
  itemName,
  isLoading,
  onClickRow,
  onSearch,
  search,
  title,
  filters,
  id,
  rowSelection,
  selected,
  onDelete,
  hideCog,
  appliedFilters,
  onChangeFilters,
  expandable,
  searchBarPlaceholder
}) => {
  const [visibleColumns, setVisibleColumns] = useState<string[]>(
    defaultColumns.map((column) => column.key)
  );
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const handleMenuClick = (e: any) => {
    const key = e.key;
    toggleColumn(key);
  };

  const toggleColumn = (columnKey: string) => {
    if (visibleColumns.includes(columnKey)) {
      setVisibleColumns((prevColumns) =>
        prevColumns.filter((key) => key !== columnKey)
      );
    } else {
      setVisibleColumns((prevColumns) => [...prevColumns, columnKey]);
    }
  };

  const sortedMenuItems = allColumns
    .filter((column) => column.name)
    .slice()
    .map((column) => (
      <Menu.Item key={column.key}>
        <Checkbox
          checked={visibleColumns.includes(column.key)}
        >
          <label>{column.name}</label>
        </Checkbox>
      </Menu.Item>
    ));

  const handleSelectAll = () => {
    const allColumnKeys = allColumns.map((column) => column.key);
    setVisibleColumns(
      allColumns.length === visibleColumns.length ? [] : allColumnKeys
    );
  };

  const visibleColumnsData = allColumns.filter((column) =>
    visibleColumns.includes(column.key)
  );

  return (
    <Table
      id={id}
      data={data}
      columns={visibleColumnsData}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      total={total}
      page={page}
      pageSize={pageSize}
      itemName={itemName}
      isLoading={isLoading}
      onClickRow={onClickRow}
      rowSelection={rowSelection}
      expandable={expandable}
      rowKey={(record: any) => (record.id ? record.id : record._id)}
      title={() => (
        <Flex vertical gap="8px">
          <Flex justify="space-between">
            <p
              style={{
                fontWeight: 600,
                fontSize: '24px',
                color: colorPrimary,
              }}
            >
              {title}
            </p>
            <Flex
              style={{ width: title !== ''  ? '' : '100%' }}
              align="center"
              gap="16px"
            >
              {onSearch ? (
                <SearchBar placeholder={searchBarPlaceholder} width="100%" value={search} onSearch={onSearch} />
              ) : null}
              {filters ? filters : null}
              {onDelete && selected && <TrashCan onClick={onDelete} />}
              {!hideCog ? (
                <Dropdown
                  overlay={
                    <Menu style={{ maxHeight: '500px', overflowY: 'auto' }}>
                      <Flex justify="end">
                        <Button
                          onClick={() => handleSelectAll()}
                          size='sm'
                          type="link"
                        >
                          Selecionar todos
                        </Button>
                      </Flex>
                      <Menu onClick={handleMenuClick}>{sortedMenuItems}</Menu>
                    </Menu>
                  }
                  trigger={['click']}
                  open={dropdownVisible}
                  onOpenChange={(visible) => setDropdownVisible(visible)}
                >
                  <Flex
                    id="cog-filter"
                    gap="8px"
                    align="center"
                    style={{
                      background: '#F5F5F5',
                      padding: '8px',
                      borderRadius: '10px',
                      cursor: 'pointer',
                    }}
                  >
                    <FiSettings size={26} />
                    <MdExpandMore
                      style={{
                        transform: dropdownVisible ? undefined :'rotate(270deg)',
                      }}
                      color="black"
                      size={16}
                    />
                  </Flex>
                </Dropdown>
              ) : null}
            </Flex>
          </Flex>
          {appliedFilters && onChangeFilters && (
            <AppliedFilters
              appliedFilters={appliedFilters}
              onChangeFilters={onChangeFilters}
            />
          )}
        </Flex>
      )}
    />
  );
};

export default DynamicTable;
