import { useQuery } from 'react-query';
import axios from 'infra/http';
import { IndicatorsProfileForm } from '../typings';

function useIndicatorsProfile(userId?: string) {
  const fetchData = async (userId: string): Promise<IndicatorsProfileForm> => {
    const { data } = await axios.get<IndicatorsProfileForm>(`/v2/indicators-profile/${userId}`);
    return data;
  };

  return useQuery(
    [`/v2/indicators-profile/`,userId],
    () => fetchData(userId!),
    {
      enabled: !!userId,
    }
  );
}

export default useIndicatorsProfile;
