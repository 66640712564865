import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import YouTube from 'react-youtube';
import { FaArrowRightLong } from 'react-icons/fa6';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { HoverItem } from 'components/hover-item/hover';
import { hex2rgba } from 'infra/helpers/hex2Rgba';

function HelperPreview({
  videoUrl,
  onClose,
  md,
}: {
  videoUrl?: string;
  md?: string;
  onClose: () => void;
}) {
  const { colorPrimary, colorGreyBorderAndFont, colorSecondary } =
    useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const re =
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
  const videoId = videoUrl ? re.exec(videoUrl) : undefined;

  return (
    <Flex vertical gap="16px" style={{ padding: isMobile ? '0px' : '16px' }}>
      {!isMobile ? (
        <>
          <Flex justify="flex-end" style={{ cursor: 'pointer' }}>
            <IoClose
              size={24}
              color={colorGreyBorderAndFont}
              onClick={onClose}
            />
          </Flex>
          <Title primary>Prévia</Title>
        </>
      ) : null}
      {videoId && (
        <YouTube
          style={{ width: '100%' }}
          videoId={videoId[1]}
          opts={{ width: '100%', height: isMobile ? 160 : 275 }}
        />
      )}
      <HoverItem
        style={{
          bordeRadius: isMobile ? '10px' : '16px',
        }}
        background="#E9E9E9"
        hover={hex2rgba(colorPrimary, 0.3)}
      >
        <Flex
          justify="space-between"
          style={{
            padding: isMobile ? '8px' : '16px',
          }}
        >
          <Text color="black" size="lg">
            Inicie o tour explicativo dessa tela
          </Text>{' '}
          <HiArrowNarrowRight color={'black'} size={isMobile ? 20 : 28} />
        </Flex>
      </HoverItem>

      <div
        dangerouslySetInnerHTML={{
          __html: md ?? '',
        }}
      />
    </Flex>
  );
}

export default HelperPreview;
