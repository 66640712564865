import { Flex } from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import Button from 'components/buttons/button';
import { useMediaQuery } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { t } from 'core/resources/strings';

function News() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();

  if (isMobile) {
    return (
      <GenericCard padding="8px" id="id-tour-section-news-mobile">
        <Flex vertical justify="space-around">
          <p
            style={{
              fontSize: '16px',
              color: 'black',
              alignSelf: 'center',
              maxWidth: '186px',
            }}
          >
            {t('app.fiquePorDentro')}
          </p>
          <Button
            style={{ alignSelf: 'center' }}
            size="sm"
            onClick={() => history.push('/posts')}
            id="id-tour-button-news-mobile"
          >
            {t('app.saibaMais')}
          </Button>
        </Flex>

        <img
          alt="lumi com tablet"
          src={'/lumi-com-tablet.png'}
          width={'96px'}
          style={{ transform: 'scaleX(-1)' }}
        />
      </GenericCard>
    );
  }
  return (
    <GenericCard padding="2px" id="id-tour-section-news">
      <Flex
        justify="space-between"
        align="center"
        style={{ width: '100%', paddingRight: '16px' }}
      >
        <Flex gap={16}>
          <img
            alt="lumi com tablet"
            src={'/lumi-com-tablet.png'}
            style={{ objectFit: 'contain' }}
            width={isMobile ? '96px' : '55px'}
          />
          <p
            style={{
              fontSize: isMobile ? '16px' : '18px',
              color: 'black',
              alignSelf: 'center',
              maxWidth: isMobile ? '186px' : '199px',
            }}
          >
            {t('app.fiquePorDentro')}
          </p>
        </Flex>

        <Button
          size="sm"
          onClick={() => history.push('/posts')}
          id="id-tour-button-news"
        >
          {t('app.saibaMais')}
        </Button>
      </Flex>
    </GenericCard>
  );
}

export default News;
