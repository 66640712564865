import { useQuery } from "react-query";
import axios from "infra/http";
import { PracticeDetails } from "../typings";

function usePractice(id?: string) {
  const fetchData = async (id: string): Promise<PracticeDetails> => {
    const { data } = await axios.get<PracticeDetails>(`/v2/practices/${id}`);

    return data;
  };

  return useQuery([`/v2/practices/${id}`], () => fetchData(id!), {
    enabled: !!id,
  });
}

export default usePractice;
