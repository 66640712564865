import { Flex } from "@chakra-ui/layout";
import Checkbox from "components/inputs/checkbox";
import { useState, useEffect } from "react";

function useSelectableColumn<T extends { id: string }>(
  fullData?: T[],
  onSelected?: (items: T[]) => void
) {
  const [selected, setSelected] = useState<T[]>([]);

  useEffect(() => {
    if (typeof onSelected === "function") {
      onSelected(selected);
    }
  }, [onSelected, selected]);

  const isAllSelected = selected.length === fullData?.length;

  const handleSelect = (e: T) => {
    setSelected((prevSelected) => [...prevSelected, e]);
  };

  const handleUnselect = (e: T) => {
    setSelected((state) => state.filter((s) => s.id !== e.id));
  };

  const handleSelectAll = () => {
    fullData && setSelected(fullData);
  };
  const handleUnselectAll = () => {
    setSelected([]);
  };

  return {
    selected,
    column: {
      disableSortBy: true,
      width: 50,
      Header: () =>
        fullData?.length ? (
          <Checkbox
            isChecked={isAllSelected}
            isIndeterminate={
              !!(selected.length && selected.length !== fullData.length)
            }
            onChange={() =>
              !isAllSelected ? handleSelectAll() : handleUnselectAll()
            }
          />
        ) : null,
      accessor: "id",
      disableClick: true,
      Cell: ({ row: { original } }: { row: { original: T } }) => {
        const isCheck = !!selected.find((s) => s.id === original.id);

        return (
          <Flex>
            <Checkbox
              isChecked={isCheck}
              onChange={() =>
                !isCheck ? handleSelect(original) : handleUnselect(original)
              }
            />
          </Flex>
        );
      },
    },
  };
}

export default useSelectableColumn;
