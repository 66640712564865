import { Flex } from 'antd';
import ChoiceIcon from 'assets/images/icons/ChoiceIcon';
import Select from 'components/select';
import useMetricsCollaborators from 'core/features/metrics/hooks/UseMetricsCollaborators';

function SelectCollaboratorsMetrics({
  tenantId,
  value,
  onChange,
  startDate,
  endDate
}: {
  tenantId: string;
  value: any;
  onChange: (v: any) => void;
  startDate?:string;
  endDate?:string;
}) {
  const { data } = useMetricsCollaborators({
    tenantId: tenantId,
    startDate:startDate ?? undefined,
    endDate:endDate ?? undefined,
  });

  return (
    <Select
      style={{ width: '300px' }}
      placeholder={'Educador'}
      value={value}
      onChange={onChange}
      options={
        data?.data?.map((item) => ({
          label: (
            <Flex justify="space-between">
              <p>{item.name}</p>{' '}
              <Flex gap={16}>
                <p>{item.countActivities}</p>
                <ChoiceIcon />
              </Flex>
            </Flex>
          ),
          value: item.id,
        })) ?? []
      }
    />
  );
}

export default SelectCollaboratorsMetrics;
