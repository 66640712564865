import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex, Modal } from 'antd';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { MdExpandMore } from 'react-icons/md';
import stars from 'assets/images/estrelas_modal.png';
import cosmoWithPencil from 'assets/images/cosmo-with-pencil.png';
import './style.css';
import { t } from 'core/resources/strings';

function ModalStartInitialTour({onClose,onStart}:{onStart:()=> void ; onClose:()=> void}) {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <Modal
        centered
        maskClosable={false}
        open={true}
        footer={null}
        closeIcon={null}
        wrapClassName="custom-modal-initial-tour"
      >
        <div
          style={{ height: '420px', overflow: 'hidden', position: 'relative' }}
        >
          <img src={stars} style={{ backgroundColor: colorPrimary }} />
          <div
            style={{
              width: '100%',
              height: '400px',
              borderRadius: '50% 50% 50% 49% / 42% 43% 0% 0%',
              background: 'white',
              marginTop: '-160px',
            }}
          ></div>

          <Flex
            justify="center"
            align="center"
            style={{
              position: 'absolute',
              top: 0,
              zIndex: 200,
              height: '100%',
              width: '100%',
            }}
          >
            <img src={cosmoWithPencil} className="shake-effect" />
          </Flex>
        </div>
        <Flex vertical style={{ padding: '0px 16px 16px 16px' }}>
          <SubTitle primary>{t('app.sejaBemVindoÀU4Hero')}</SubTitle>
          <Text size="lg" align='justify'>
            {t('app.introducaoU4hero')}
          </Text>
          <Flex
            vertical
            justify="center"
            align="center"
            gap="8px"
            style={{ marginTop: '40px' }}
          >
            <Button size="md" onClick={onStart}>{t('app.vamosLa')}</Button>
            <Flex
              align="center"
              style={{ cursor: 'pointer' }}
              onClick={onClose}
            >
              <Text size="sm" color={colorGreyBorderAndFont}>
                {t('app.jaConheco')}
              </Text>
              <MdExpandMore
                color={colorGreyBorderAndFont}
                style={{
                  transform: 'rotate(270deg)',
                }}
                size={20}
              />
            </Flex>
          </Flex>
        </Flex>
      </Modal>

      
    </>
  );
}

export default ModalStartInitialTour;
