import { Flex, Menu, Dropdown, Collapse, Switch, Radio, Divider } from 'antd';
import Text from 'components/typography/text';
import { useState } from 'react';
import SelectClass from 'components/V2/selects/select-class';
import SelectStudent from 'components/V2/selects/select-student';
import Button from 'components/buttons/button';
import { ComparativeItem } from 'infra/helpers/types';
import { t } from 'core/resources/strings';

function ComparativeMode({
  value,
  onChange,
  classId,
  serieId,
  tenantId,
}: {
  value: ComparativeItem;
  onChange: (v: ComparativeItem) => void;
  classId: string;
  serieId?: string;
  tenantId?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState<string>();

  return (
    <Dropdown
      overlay={
        <Menu
          style={{
            maxHeight: '515px',
            width: '247px',
            overflowY: 'auto',
            background: '#F5F5F5',
            padding: '8px',
            marginTop: '8px',
          }}
        >
          <Flex vertical gap={8}>
            <Text>{t('app.tipoDeComparação')}:</Text>
            <Radio.Group
              onChange={(e) => setType(e.target.value)}
              value={type}
              style={{ marginBottom: '8px' }}
            >
              <Radio value={'class'}>{t('app.turma')}</Radio>
              <Radio value={'student'}>{t('app.estudante')}</Radio>
            </Radio.Group>
            {type === 'class' && (
              <SelectClass
                id="id-tour-comparative-mode-select-class"
                serieId={serieId}
                tenantId={tenantId}
                value={value.type === 'class' ? value.id : ''}
                onChange={(v, classe) => {
                  onChange({ type: 'class', id: v, name: classe.name });
                  setIsOpen(false);
                }}
              />
            )}
            {type === 'student' && (
              <SelectStudent
                id="id-tour-comparative-mode-select-student"
                classId={classId}
                tenantId={tenantId}
                value={value.type === 'student' ? value.id : ''}
                onChange={(v, student) => {
                  onChange({ type: 'student', id: v, name: student.name });
                  setIsOpen(false);
                }}
              />
            )}
            <Divider />
            <Flex
              justify="space-between"
              style={{ paddingLeft: '16px', paddingRight: '24px' }}
            >
              <Button
                size="sm"
                type="default"
                disabled={value.id === '' && value.name === ''}
                onClick={() => onChange({ name: '', id: '', type: 'student' })}
              >
                {t('app.limpar')}
              </Button>
              <Button size="sm" type="primary" onClick={() => setIsOpen(false)}>
                OK
              </Button>
            </Flex>
          </Flex>
        </Menu>
      }
      open={isOpen}
    >
      <Flex
        gap="16px"
        align="center"
        style={{
          background: '#F5F5F5',
          padding: '8px',
          borderRadius: '10px',
        }}
      >
        <Text size="sm">{t('app.modoComparativo')}</Text>
        <Switch
          onChange={(checked) => {
            setIsOpen(checked);
            if (!checked) {
              onChange({ name: '', id: '', type: 'student' });
            }
          }}
        />
      </Flex>
    </Dropdown>
  );
}

export default ComparativeMode;
