import { useMutation } from "react-query";
import http from "infra/http";

export function useReClass() {
  return useMutation(
    ( id: string | undefined ) => {
    return http.put(
      `/v2/safeSpace/reClass`,
      {
        id: id,
      }
    );
  },
    { retry: false }
  );
}
