import { useQuery } from "react-query";
import axios from "infra/http";

export interface emotional {
  optionId: string;
  count: number;
  percent: number;
  name:string;
}
interface EmotionalData {
  period:string;
  data:{
    optionId: string;
    count: number;
    percent: number;
    name:string;
  }[];
 
}

export interface UseEmotionalParams {
  classId?: string,
  userId?: string
  initialDate?:string;
  endDate?:string;
  tenantId?:string;
  disableGreedy?:boolean;
  optionIds?:string[];
  disable?:boolean;
}

function useEmotionalStats(params: UseEmotionalParams) {
 
  const fetchData = async (
    params: UseEmotionalParams
  ): Promise<EmotionalData> => {
    const { data } = await axios.get<EmotionalData>(`/v2/graphs/emotional/new`, {
      params: {
        classId: params.classId,
        userId: params.userId,
        initialDate:params.initialDate,
        endDate:params.endDate,
        disableGreedy:params.disableGreedy,
        optionIds:params.optionIds,
        tenantId:params.tenantId
      },
    });

    return data;
  };

  return useQuery(
    [
      `/v2/graphs/emotional/new`,
      params,
    ],
    () => fetchData(params),
    {
      keepPreviousData: true,
      enabled:!params?.disable
    }
  );
}

export default useEmotionalStats;
