import GraduationCapIcon from 'assets/images/icons/GraduationCapIcon';
import useTotalStudents from 'core/features/indicators/hooks/useTotalStudents';
import { t } from 'core/resources/strings';
import StatCard from '../statCard';

function TotalStudents({ onRemove }: { onRemove?: () => void }) {
  const { data } = useTotalStudents();
  return (
    <StatCard
      title={
        t('app.estudante').slice(0, 1).toUpperCase() +
        t('app.estudante').slice(1)
      }
      total={data?.total ?? 0}
      Icon={GraduationCapIcon}
      onRemove={onRemove}
    />
  );
}

export default TotalStudents;
