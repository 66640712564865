import useStudents from "core/features/inkluzen/hooks/useStudents";
import { useEffect, useState } from "react";
import { IStudentInfo } from "core/features/inkluzen/types";
import Table from "components/V2/table";
import { useHistory } from "react-router-dom";
import SearchBar from "components/V2/SearchBar";
import "./style.css"
import Layout from "components/layout";
import { Flex, Spin } from "antd";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import useU4heroColors from "infra/packages/antd/u4heroTokens";

function StudentMentalHealth() {
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [filteredStudents, setFilteredStudents] = useState<IStudentInfo[]>([]);
  const history = useHistory();
  const students = useStudents();
  const { colorPrimary } = useU4heroColors();

  useEffect(() => {
    filterStudent();
  }, [search]);

  const filterStudent = (): void => {


    const studentsArrayFiltered = (students?.data || []).filter((student) => {
      const studentNameLowerCase = student.student.toLowerCase();
      const studentCityLowerCase = student.city.toLowerCase();
      const studentSchoolLowerCase = student.school.toLowerCase();
      const searchLowerCase = search.toLowerCase();

      return (
        studentNameLowerCase.includes(searchLowerCase) ||
        student.grade.includes(searchLowerCase) ||
        studentCityLowerCase.includes(searchLowerCase) ||
        studentSchoolLowerCase.includes(searchLowerCase)
      );
    });

    setFilteredStudents(studentsArrayFiltered);
  }


  return (
    <Layout.Container>
      <Flex vertical gap={30}>
        <h1 style=
          {
            {
              color: colorPrimary,
              fontSize: 32,
              fontWeight: 700,
            }
          }>
          Estudantes
        </h1>
        <div className="title-search">
          <SearchBar onSearch={setSearch} />
        </div>
        {students.data ?
          <Table<IStudentInfo>
            itemName="estudantes"
            data={search === '' ? students?.data ?? [] : filteredStudents}
            onChangePage={setPage}
            onChangePageSize={setPageSize}
            total={students?.data?.length ?? 0}
            page={page}
            pageSize={pageSize}
            isLoading={isLoading}
            onClickRow={(item) => history.push(`/inkluzen/students/${item.RA}/process`)}
            columns={[
              {
                title: <p className="row" style={{ fontSize: "24px" }}>RA</p>,
                dataIndex: 'RA',
                key: 'RA',
                render: (RA: number) => <p style={{ fontSize: "20px" }}>{RA}</p>,
                sorter: (a: any, b: any) => a.student.localeCompare(b.student),
                sortIcon: (sortOrder: any) =>
                  sortOrder.sortOrder === 'descend' ? (
                    <MdExpandLess size={25} />
                  ) : (
                    <MdExpandMore size={25} />
                  ),
              },
              {
                title: <p style={{ fontSize: "24px" }}>Estudante</p>,
                dataIndex: 'student',
                key: 'student',
                render: (student: string) => <p style={{ fontSize: "20px" }}>{student}</p>,
                sorter: (a: any, b: any) => a.student.localeCompare(b.student),
                sortIcon: (sortOrder: any) =>
                  sortOrder.sortOrder === 'descend' ? (
                    <MdExpandLess size={25} />
                  ) : (
                    <MdExpandMore size={25} />
                  ),
              },
              {
                title: <p style={{ fontSize: "24px" }}>Turma/Série</p>,
                dataIndex: 'grade',
                key: 'grade',
                render: (grade: string) => <p style={{ fontSize: "20px" }}>{grade}</p>,
                sorter: (a: any, b: any) => a.student.localeCompare(b.student),
                sortIcon: (sortOrder: any) =>
                  sortOrder.sortOrder === 'descend' ? (
                    <MdExpandLess size={25} />
                  ) : (
                    <MdExpandMore size={25} />
                  ),
              },
              {
                title: <p style={{ fontSize: "24px" }}>Município</p>,
                dataIndex: 'city',
                key: 'city',
                render: (city: string) => <p style={{ fontSize: "20px" }}>{city}</p>,
                sorter: (a: any, b: any) => a.student.localeCompare(b.student),
                sortIcon: (sortOrder: any) =>
                  sortOrder.sortOrder === 'descend' ? (
                    <MdExpandLess size={25} />
                  ) : (
                    <MdExpandMore size={25} />
                  ),
              },
              {
                title: <p style={{ fontSize: "24px" }}>Escola</p>,
                dataIndex: 'school',
                key: 'school',
                render: (school: string) => <p style={{ fontSize: "20px" }}>{school}</p>,
                sorter: (a: any, b: any) => a.student.localeCompare(b.student),
                sortIcon: (sortOrder: any) =>
                  sortOrder.sortOrder === 'descend' ? (
                    <MdExpandLess size={25} />
                  ) : (
                    <MdExpandMore size={25} />
                  ),
              },
            ]}
          />
          : <Spin size={'large'} />
        }
      </Flex>
    </Layout.Container>
  );
}

export default StudentMentalHealth;