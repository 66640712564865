import { useTable, useSortBy, Column } from "react-table";
import { Table, TableContainer, TableProps, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import { gray900, purple50 } from "core/resources/theme/colors";
import { Text } from "@chakra-ui/layout";
import { useMediaQuery } from "@chakra-ui/react";

export function TableComponent({
  columns,
  data,
  onClickRow,
  tableProps,
}: {
  columns: Column[];
  data: any[];
  onClickRow?: (index: number) => void;
  tableProps?: TableProps;
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  const firstPageRows = rows.slice(0, 25);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  if(isMobile){
    return (
      <TableContainer >
        <Table {...tableProps} {...getTableProps()}>
          <Thead bgColor={purple50}>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => {
                  return (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        width: column.width ?? undefined,
                      }}
                    >
                      <Text letterSpacing="0.2px" color="purple.500">
                        {column.render("Header")}
                      </Text>
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {firstPageRows.map((row, i) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const cellProps = { ...cell.getCellProps() };
  
                    const col: any = cell.column;
  
                    let onClick: any = undefined;
  
                    if (!col.disableClick) {
                      if (typeof col.onClick === "function") {
                        onClick = col.onClick;
                      } else if (typeof onClickRow === "function") {
                        onClick = onClickRow;
                      }
                    }
  
                    const isClicked = typeof onClick === "function";
  
                    return (
                      <Td
                        onClick={() => {
                          if (isClicked) {
                            onClick(i);
                          }
                        }}
                        style={{
                          width: cell.column.width,
                          cursor: isClicked ? "pointer" : "default",
                          color: gray900,
                        }}
                        {...cellProps}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    );
  }
  return (
    <>
      <Table {...tableProps} {...getTableProps()}>
        <Thead bgColor={purple50}>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                return (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      width: column.width ?? undefined,
                    }}
                  >
                    <Text letterSpacing="0.2px" color="purple.500">
                      {column.render("Header")}
                    </Text>
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const cellProps = { ...cell.getCellProps() };

                  const col: any = cell.column;

                  let onClick: any = undefined;

                  if (!col.disableClick) {
                    if (typeof col.onClick === "function") {
                      onClick = col.onClick;
                    } else if (typeof onClickRow === "function") {
                      onClick = onClickRow;
                    }
                  }

                  const isClicked = typeof onClick === "function";

                  return (
                    <Td
                      onClick={() => {
                        if (isClicked) {
                          onClick(i);
                        }
                      }}
                      style={{
                        width: cell.column.width,
                        cursor: isClicked ? "pointer" : "default",
                        color: gray900,
                      }}
                      {...cellProps}
                    >
                      {cell.render("Cell")}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
}
