import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Modal } from 'antd';
import Title from 'components/typography/title';
import { Step } from 'core/features/tour/typings';
import { useEffect, useState } from 'react';

function TourPreview({
  onClose,
  steps,
  url
}: {
  onClose: () => void;
  steps: Step[];
  url:string
}) {
	const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
	useEffect(()=>{	
		if(isIframeLoaded){
			//@ts-ignore
  		const iframeWindow = document.getElementById('iFrame-preview-tour').contentWindow;
			iframeWindow.postMessage(
				{
					type: 'tour-steps',
					message: steps,
				},
				'*'
			);
		}
	},[isIframeLoaded])

  return (
    <Modal
      open={true}
      title={
        <Flex justify="space-between">
          <Title primary>Prévia</Title>{' '}
        </Flex>
      }
      width={isMobile ? '100%':'80%'}
      onCancel={() => {
        onClose();
      }}
      centered
      footer={null}
    >
      <iframe
			 onLoad={() => setIsIframeLoaded(true)}
        id="iFrame-preview-tour"
        src={`${window.location.origin}${url}`}
        width="100%"
        style={{
          height: '700px',
        }}
      />
    </Modal>
  );
}

export default TourPreview;
