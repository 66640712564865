import useGraphTagAvg from 'core/features/graphs/hooks/useGraphTagAvg';

interface CommonParams {
  tags: string[];
  tenantId: string;
  startDate: string;
  endDate: string;
  classId?: string;
  userId?: string;
  tagType?:number;
}

const useGetViewData = (commonParams: CommonParams) => {
  const { data: comprehension, isLoading: isLoadingComprehension } =
    useGraphTagAvg(
      commonParams.tags,
      commonParams.tenantId,
      commonParams.startDate,
      commonParams.endDate,
      'General',
      false,
      {
        classId: commonParams.classId,
        userId: commonParams.userId,
        analysisTypeId: 0,
      }
    );

  const { data: decision, isLoading: isLoadingDecision } = useGraphTagAvg(
    commonParams.tags,
    commonParams.tenantId,
    commonParams.startDate,
    commonParams.endDate,
    'General',
    false,
    {
      classId: commonParams.classId,
      userId: commonParams.userId,
      analysisTypeId: 1,
    }
  );

  const { data: perception, isLoading: isLoadingPerception } = useGraphTagAvg(
    commonParams.tags,
    commonParams.tenantId,
    commonParams.startDate,
    commonParams.endDate,
    'General',
    false,
    {
      classId: commonParams.classId,
      userId: commonParams.userId,
      analysisTypeId: 2,
    }
  );

  return {
    comprehension,
    isLoadingComprehension,
    decision,
    isLoadingDecision,
    perception,
    isLoadingPerception,
  };
};

export default useGetViewData;
