import { Flex } from 'antd';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import Title from 'components/typography/title';
import { useDistorcaoSerie } from 'core/features/qedu/hooks/useDistorcaoSerie';
import { useCurrentUser } from 'core/features/user/store';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import empty from 'assets/images/lumi-empty.png';
import { useMediaQuery } from '@chakra-ui/media-query';
import { series } from 'ui/pages/app/home/components/qedu/ageGradeDistortion';

function QeduInfo({ serie }: { serie: string }) {
  const { colorGreyBorderAndFont, colorSecondary } = useU4heroColors();
  const { user } = useCurrentUser();

  const { data: dataDistortion } = useDistorcaoSerie({
    inep_id: user?.inep_id,
  });
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const serieValue = series.find((item) => item.serie === serie)?.value;

  let distortion;
  if (serieValue) {
    distortion = (dataDistortion as any)?.[serieValue];
  }

  return (
    <Flex
      vertical
      className="box-shadow-generic-card"
      style={{ padding: '16px', position: 'relative', height: '100%' }}
    >
      <div>
        <SubTitle primary style={{ lineHeight: 'normal' }}>
          Distorção de Idade-Série
        </SubTitle>
        {distortion && (
          <>
            <Text
              size="sm"
              style={{
                position: 'absolute',
                padding: '4px 8px',
                top: isMobile ? undefined : 0,
                right: 0,
                bottom: isMobile ? 0 : undefined,
                background: colorSecondary,
                borderRadius: isMobile
                  ? '10px 0px 20px 0px'
                  : '0px 20px 0px 10px',
              }}
              color={'white'}
              bold
            >
              Fonte: QEDU,[{dataDistortion?.ano}]
            </Text>
            <Text size="md" style={{ lineHeight: 'normal' }}>
              Porcentagem de estudantes que estão com atraso escolar.
            </Text>
          </>
        )}
      </div>

      {distortion ? (
        <Title
          style={{ fontSize: isMobile ? '50px' : '60px' }}
          primary
          align="center"
        >
          {parseFloat(distortion).toFixed(1)}%
        </Title>
      ) : (
        <Flex
          vertical
          justify="center"
          align="center"
          style={{ width: '100%' }}
        >
          <img src={empty} />
          <Text size="md">Não há dados para serem exibidos!</Text>
        </Flex>
      )}
    </Flex>
  );
}

export default QeduInfo;
