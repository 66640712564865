import { useMediaQuery } from '@chakra-ui/react';
import { Dropdown, Menu } from 'antd';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import { Permissions } from 'core/resources/enums/permissions';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import DynamicTable from 'components/V2/dinamic-table';
import { FaEllipsisVertical } from 'react-icons/fa6';
import {
  notificateError,
  notificateSucess,
  showConfirm,
} from 'infra/helpers/notifications';
import { ResourceItem } from 'core/features/resources/types';
import { FiTrash2 } from 'react-icons/fi';
import { TfiPencil } from 'react-icons/tfi';
import { t } from 'core/resources/strings';
import useResources from 'core/features/resources/hooks/useResources';
import Tag from 'components/V2/tag';
import { currentLocale } from 'core/resources/strings/polyglot';
import ResourcesFilter from 'ui/pages/app/settings-u4hero/tabs/ResourcesTab/ResourcesFilter';
import { FilterType } from 'infra/helpers/types';
import { useDeleteResource } from 'core/features/resources/hooks/useDeleteResource';

function ResourcesTable({ onEdit }: { onEdit: (resourceId: string) => void }) {
  const [check] = useCheckPermissions();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const { mutate: handleDelete } = useDeleteResource();
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<FilterType>({
    types: [],
  });

  const { data, isLoading, isFetching, refetch } = useResources({
    page,
    limit: pageSize,
    search,
    types: filters.types.map((type) => type.value) ?? [],
  });

  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };

  const types = {
    0: 'U4Hero',
    1: 'Escola',
    2: 'Organização',
    3: 'Inkluzen',
    4: 'B2C',
  };

  const columns = [
    {
      title: <p style={tableHeaderStyle}>{t('app.nome')}</p>,
      name: t('app.nome'),
      dataIndex: 'name',
      key: 'name',
      mobile: true,
      render: (_: string, record: ResourceItem) => (
        <p style={tableRowStyle}>
          {record?.name?.[currentLocale as keyof typeof record.name]}
        </p>
      ),
      sorter: (a: ResourceItem, b: ResourceItem) =>
        a.name[currentLocale as keyof typeof a.name].localeCompare(
          b.name[currentLocale as keyof typeof b.name]
        ),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.permissaoPara')}</p>,
      name: t('app.permissaoPara'),
      dataIndex: 'type',
      key: 'type',
      mobile: true,
      render: (_: string, record: ResourceItem) => (
        <p style={tableRowStyle}>
          <Tag text={types[record.type as keyof typeof types]} />
        </p>
      ),
      sorter: (a: ResourceItem, b: ResourceItem) => a.type - b.type,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.tag')}</p>,
      name: t('app.tag'),
      dataIndex: 'tag',
      key: 'tag',
      render: (_: string, record: ResourceItem) => (
        <p style={tableRowStyle}>{record?.tag}</p>
      ),
      sorter: (a: ResourceItem, b: ResourceItem) => a.tag.localeCompare(b.tag),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.editar')}</p>,
      key: 'edit',
      dataIndex: 'edit',
      name: t('app.editar'),
      render: (_: string, record: ResourceItem) => (
        <TfiPencil
          size={30}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onEdit(record.id);
          }}
        />
      ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.deletar')}</p>,
      key: 'delete',
      dataIndex: 'delete',
      name: t('app.deletar'),
      render: (_: string, record: ResourceItem) => (
        <FiTrash2
          size={30}
          style={{ cursor: 'pointer' }}
          onClick={() =>
            onDelete(
              record.id,
              record.name[currentLocale as keyof typeof record.name]
            )
          }
        />
      ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.acoes')}</p>,
      key: 'action',
      mobile: true,
      extra: true,
      dataIndex: 'action',
      name: t('app.acoes'),
      render: (_: string, record: ResourceItem) => (
        <Dropdown
          overlay={
            <Menu
              id="id-tour-action-student-table"
              onClick={(e) => {
                if (e.key === 'edit') {
                  onEdit(record.id);
                }
                if (e.key === 'delete') {
                  onDelete(
                    record.id,
                    record.name[currentLocale as keyof typeof record.name]
                  );
                }
              }}
            >
              {check([Permissions.U4hero.Helpers.Edit]) ? (
                <Menu.Item id="edit-button" key={'edit'}>
                  {t('app.editar')}
                </Menu.Item>
              ) : null}
              {check([Permissions.U4hero.Helpers.Edit]) ? (
                <Menu.Item id="delete-button" key={'delete'}>
                  {t('app.deletar')}
                </Menu.Item>
              ) : null}
            </Menu>
          }
          trigger={['click']}
        >
          <FaEllipsisVertical
            id="id-tour-action-resources-table"
            size={24}
            style={{ cursor: 'pointer' }}
          />
        </Dropdown>
      ),
    },
  ];

  const onDelete = (id: string, resourceName: string) => {
    showConfirm({
      message: `${t(
        'app.temCertezaQueDesejaExcluirORecurso'
      )} ${resourceName}?`,
      colorPrimary,
      isMobile,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        handleDelete(id, {
          onSuccess: () => {
            notificateSucess(t('app.recursoExcluidoComSucesso'));
            refetch();
          },
          onError: () => {
            notificateError(t('app.erroAoExcluirRecurso'));
          },
        });
      }
    });
  };
  return (
    <DynamicTable
      id="id-tour-resources-table"
      itemName="recurso"
      data={data?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title=""
      total={data?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isLoading}
      search={search}
      onSearch={setSearch}
      appliedFilters={filters}
      onChangeFilters={setFilters}
      filters={
        <ResourcesFilter filters={filters} onChangeFilters={setFilters} />
      }
      defaultColumns={
        isMobile
          ? columns.filter((column) => column.mobile)
          : columns.filter((column) => !column.extra)
      }
      allColumns={columns}
      hideCog={true}
    />
  );
}

export default ResourcesTable;
