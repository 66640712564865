import {
  Flex,
  Form,
  Input,
} from 'antd';
import SubTitle from 'components/typography/subTitle';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import InputMask from 'components/inputs/input-mask';
import { t } from 'core/resources/strings';

function ContactInfo() {
  return (
    <GenericCard>
      <Flex vertical style={{ width: '100%' }}>
        <SubTitle primary>{t('app.contato')}</SubTitle>
        <Form.Item
          label={t('app.email')}
          rules={[
            {
              required: true,
              message: t('app.mensagemErroEmail'),
            },
          ]}
          name="email"
        >
          <Input
            type="email"
            disabled={true}
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
        <Form.Item
          label={t('app.telefone')}
          name="phone"
          rules={[
            {
              validator: async (rule, value) => {
                if (value) {
                  const formattedValue = value.replace(/\D/g, '');
                  if (formattedValue.length < 10) {
                    throw new Error(t('app.mensagemErroTelefone'));
                   
                  }
                }
              },
            },
          ]}
        >
          <InputMask mask="(99) 99999-9999" autoComplete="off">
            {() => (
              <Input
                id="id-tour-my-profile-input-phone"
                style={{ maxWidth: '345px' }}
              />
            )}
          </InputMask>
        </Form.Item>
      </Flex>
    </GenericCard>
  );
}

export default ContactInfo;
