import http from "infra/http";
import { useMutation } from "react-query";

export function useSaveResource() {
  return useMutation(
    (data: any) => {
      if (data.id) {
        return http.put(`/v1/resource/${data.id}`, data);
      }
      return http.post(`/v1/resource/`, data);
    },
    { retry: false }
  );
}
