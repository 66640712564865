import { useMediaQuery } from '@chakra-ui/media-query';
import { Modal, Flex, Image, Rate } from 'antd';
import DynamicTable from 'components/V2/dinamic-table';
import { IFeedback } from 'core/features/questions/hooks/useQuestionFeedbacks';
import { useState } from 'react';
import { PiEyeLight } from 'react-icons/pi';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { PiEyeClosedLight } from 'react-icons/pi';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import dayjs from 'dayjs';
import { FilterType } from 'infra/helpers/types';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import FeedbackFilter from 'components/feedback-filter';
import useGame from 'core/features/games/hooks/useGame';
import useGamesFeedbacks from 'core/features/games/hooks/useGamesFeedbacks';

function GamesFeedbackModal({
  onClose,
  id,
}: {
  onClose: () => void;
  id?: string;
}) {
  const { data: gameData } = useGame(id);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();

  const customExpandIcon = (props: any) => {
    if (props.expanded) {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <PiEyeLight style={{ fontSize: '22px' }} />
        </a>
      );
    } else {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <PiEyeClosedLight style={{ fontSize: '22px' }} />
        </a>
      );
    }
  };

  const ratings = [
    {
      label: 'Muito Ruim',
      value: 1,
    },
    {
      label: 'Ruim',
      value: 2,
    },
    {
      label: 'Regular',
      value: 3,
    },
    {
      label: 'Bom',
      value: 4,
    },
    {
      label: 'Muito Bom',
      value: 5,
    },
  ];
  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };

  const [filters, setFilters] = useState<FilterType>({
    initialDate: [],
    ratings: [],
  });

  const { data: feedbackData, isLoading } = useGamesFeedbacks({
    id: id,
    page: page,
    ratings: filters?.ratings.map((item) => item.value) ?? undefined,
    initialDate: filters?.initialDate.map((item) => item.value)[0] ?? undefined,
  });

  const columns = [
    {
      title: <p style={tableHeaderStyle}>Avaliação</p>,
      name: 'Avaliação',
      dataIndex: 'rating',
      key: 'rating',
      render: (_: string, record: IFeedback) => (
        <p style={tableRowStyle}>
          <Rate disabled defaultValue={record.rating} />
        </p>
      ),
      sorter: (a: any, b: any) => a.rating - b.rating,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Data</p>,
      name: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: (_: string, record: IFeedback) => (
        <p style={tableRowStyle}>
          {dayjs(record.createdAt).format('DD/MM/YYYY')}
        </p>
      ),
      sorter: (a: any, b: any) => a.createdAt - b.createdAt,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Feedback</p>,
      name: 'Feedback',
      dataIndex: 'feedback',
      key: 'feedback',
      render: (_: string, record: IFeedback) => (
        <p style={tableRowStyle}>{record.feedback}</p>
      ),
    },
    // {
    //   title: <p style={tableHeaderStyle}>Feedback</p>,
    //   name: "expand",
    //   dataIndex: "expand",
    //   key: "expand",
    //   render: (_: string, record: IFeedback) => (<PiEyeLight style={{ fontSize: "22px" }} />),
    // },
  ];

  return (
    <Modal
      footer={[
        <Flex align="center" justify="center">
          <Button size="md" onClick={onClose}>
            Fechar
          </Button>
        </Flex>,
      ]}
      title={<SubTitle primary>Feedback</SubTitle>}
      open={true}
      onCancel={onClose}
      width={isMobile ? '100%' : '666px'}
    >
      <Flex vertical gap={16}>
        <Flex align="center" vertical>
          <Image
            src={gameData?.coverImageUrl}
            alt={gameData?.name}
            style={{ objectFit: 'cover', borderRadius: '20px' }}
            preview={false}
          />
          <Flex
            style={{
              borderRadius: '10px',
              background: colorPrimary,
              padding: '4px 10px',
              marginTop: isMobile ? '-15px' : '-30px',
              zIndex: 100,
            }}
          >
            <Rate
              disabled
              value={gameData?.averageRating}
              style={{ fontSize: isMobile ? '20px' : '36px' }}
              allowHalf
            />
          </Flex>
        </Flex>

        <DynamicTable
          id="id-tour-games-feedback-table"
          itemName={
            feedbackData && feedbackData?.total > 1 ? 'feedbacks' : 'feedback'
          }
          data={feedbackData?.data ?? []}
          page={page}
          onChangePage={setPage}
          isLoading={isLoading}
          allColumns={columns}
          defaultColumns={columns}
          hideCog={true}
          pageSize={pageSize}
          onChangePageSize={setPageSize}
          total={feedbackData?.total ?? 0}
          appliedFilters={filters}
          onChangeFilters={setFilters}
          filters={
            <FeedbackFilter filters={filters} onChangeFilters={setFilters} />
          }
          expandable={
            isMobile
              ? {
                  expandedRowRender: (record: IFeedback) => (
                    <p style={{ margin: 0, fontWeight: 600 }}>
                      <span style={{ color: colorPrimary, fontWeight: 600 }}>
                        Feedback:{' '}
                      </span>
                      {record.feedback}
                    </p>
                  ),
                  rowExpandable: (record: IFeedback) => record.feedback !== '',
                  expandIcon: (props: any) => customExpandIcon(props),
                }
              : null
          }
        />
      </Flex>
    </Modal>
  );
}

export default GamesFeedbackModal;
