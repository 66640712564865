import usePostsAuthors from 'core/features/posts/hooks/usePostsAuthors';
import { FilterProps } from 'infra/helpers/types';
import GenericFilterComponent from 'components/generic-filter';

function PostFilter({ filters, onChangeFilters }: FilterProps) {
  const { data: authors } = usePostsAuthors();
  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: 'Autor',
          filterName: 'authors',
          options: authors?.map((item) => {
            return { label: item.name, value: item.id };
          }) ?? [],
        },
        {
          title: 'Tipo',
          filterName: 'published',
          options: [
            {
              label: 'Publicado',
              value: 1,
            },
            {
              label: 'Rascunho',
              value: 2,
            },
          ],
          type: 'radio',
        },
        {
          title: 'Data de publicação',
          filterName: 'publishDate',
          options: [],
          type: 'date',
          open:true
        },
      ]}
    />
  );
}

export default PostFilter;
