import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

function AreaGraph({
  categories,
  series,
  colors,
}: {
  categories: string[];
  series: ApexAxisChartSeries;
  colors?: string[];
}) {
  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'category',
      categories: categories,
    },
    colors: colors,
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height={350}
    />
  );
}

export default AreaGraph;
