import { useCallback } from 'react';
import { useCurrentUser } from "core/features/user/store";

export default function useCheckPermission() {

    const { user } = useCurrentUser();

    const check = useCallback((permissao:string) => {

        const permissoes = user?.resources ?? [];        
        const possuiPermissao = permissoes.includes(permissao);
        return possuiPermissao;

    }, [user]);

    return [check];
}