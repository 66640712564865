import axios from 'axios';
import { useQuery } from 'react-query';
import { AprendizadoAdequado, baseUrlQedu, QeduParams } from '../types';

export function useAprendizadoAdequado(params: QeduParams) {
  const fetchData = async (
    params: QeduParams
  ): Promise<AprendizadoAdequado[]> => {
    const years = [2019, 2017, 2015];
    let data: AprendizadoAdequado[] = [];

    for (const year of years) {
      const response = await axios.get<{ data: AprendizadoAdequado[] }>(
        `${baseUrlQedu}/saeb/aprendizado/escolas`,
        {
          params: { ...params, ano: year }, // Update params with the current year
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_QEDU_API_KEY}`,
          },
        }
      );

      data = response.data.data;

      if (data.length >= 1) {
        break; // Break the loop if data is found
      }
    }

    return data.length > 0 ? data : []; // Return the found data or an empty array
  };

  return useQuery([`aprendizado-adequado`, params], () => fetchData(params), {
    enabled: !!params?.inep_id,
  });
}
