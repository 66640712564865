import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';

const BackButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      type="primary"
      size="large"
      id="id-tour-back-button"
      icon={<ArrowLeftOutlined />}
      {...props} 
    ></Button>
  );
};

export default BackButton;
