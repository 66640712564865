import { getChatGPTResponse } from 'infra/helpers/chatgpt';
import http from 'infra/http';
import { useMutation } from 'react-query';

export interface useChatGptParams {
  question: string;
  answer: string;
  skill: string;
  competence: string;
  minAge: number | undefined;
  maxAge: number | undefined;
  isHypothesis: boolean;
}
function useChatGPT() {
  return useMutation(
    async (params: useChatGptParams) => {
      let question;
      if (params.isHypothesis) {
        question = `Gere cinco hipóteses sobre o comportamento de um aluno de faixa etária de "${params.minAge}" a "${params.maxAge}" anos que para a pergunta "${params.question}" respondeu "${params.answer}" não inclua hipóteses relacionadas a transtornos ou traumas.`;
      } else {
        question = `Conceitue em linguagem formal pedagógica em até 4 linhas o que é a "${params.skill}" no contexto de "${params.competence}", descreva como ela impacta na aprendizagem e nas relações entre os estudantes dentro e fora do ambiente escolar.

Gere três dinâmicas para turmas a partir de 10 alunos de faixa etária "${params.minAge}" a "${params.maxAge}" anos baseadas na resposta "${params.answer}" da pergunta "${params.question}". escreva o objetivo de cada uma delas, o passo a passo das dinâmicas e uma consideração final para cada dinâmica. Não aborde temas relacionados a transtornos ou traumas. Siga o exemplo de estrutura abaixo:

"conceito de 4 linhas"

Dinâmica 1: "nome dinânica"
Objetivo:

Passo a passo:

Conclusão:

Dinâmica 2: "nome dinânica"
Objetivo:

Passo a passo:

Conclusão:

Dinâmica 3: "nome dinânica"
Objetivo:

Passo a passo:

Conclusão:`;
      }

      const response = await http.post('/v2/generate', { question: question });
      return response;
    },
    { retry: false }
  );
}

export default useChatGPT;
