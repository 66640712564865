import { useMutation } from "react-query";
import http from "infra/http";
import {  GameForm } from "../typings";

export function useSaveGame(id?: string) {
  return useMutation(
    (data: GameForm) => {
      if (id) {
        return http.put(`/v1/game/${id}`, data);
      }

      return http.post(`v1/game`, data);
    },
    { retry: false }
  );
}
