import { useQuery } from "react-query";
import axios from "infra/http";
import { ActivityProgress } from "core/features/students/typings";

function useActivityProgress(id?: string) {
  const fetchActivity = async (id: string): Promise<ActivityProgress> => {
    const { data } = await axios.get<ActivityProgress>(`/v2/activities/progress/${id}`);

    return data;
  };

  return useQuery([`/v2/activities/progress/${id}`, id], () => fetchActivity(id!), {
    enabled: !!id,
  });

}

export default useActivityProgress;
