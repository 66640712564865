import { Flex } from 'antd';
import { useState } from 'react';
import { GenericCard } from '../generic-card-styled-component';
import dayjs from 'dayjs';
import { DownOutlined } from '@ant-design/icons';
import { UpOutlined } from '@ant-design/icons';
import { t } from 'core/resources/strings';

function ExpansibleCommentCard({
  type,
  content,
  date,
  imgSrc,
  imgAlt,
  extra,
}: {
  type: string | number;
  content?: string;
  date: string;
  imgSrc?: string;
  imgAlt?: string;
  extra?: string | string[];
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const preview =
    content?.length > 65 ? content?.slice(0, 65) + '...' : content;
  const dateFormatted = dayjs(date).format('DD/MM/YYYY');
  const extraFormatted =
    typeof extra === 'object' ? extra.join(' ').replace(',', '.') : extra;

  const handleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <GenericCard heigth={'80px'}>
      <Flex gap={10} style={{ width: '100%' }} vertical>
        <h4 style={{ color: '#000000', fontWeight: 500, fontSize: '16px' }}>
          <strong>{type}</strong>
        </h4>
        {extra && content !== null ? (
          <Flex gap={8} vertical style={{ width: '100%' }}>
            <p
              style={{
                color: '#000000',
                fontWeight: 400,
                fontSize: '14px',
              }}
            >
              <strong>{t('app.comportamentosSelecionados')}: </strong>{' '}
              {extraFormatted}
            </p>

            {isExpanded && content !== null && (
              <p
                style={{
                  color: '#000000',
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                <strong>{t('app.observacaoAdicional')}: </strong> {content}
              </p>
            )}

            {content !== null && (
              <button onClick={handleExpansion}>
                {isExpanded ? (
                  <span>
                    {' '}
                    {t('app.mostrarMenos')} <UpOutlined />{' '}
                  </span>
                ) : (
                  <span>
                    {' '}
                    {t('app.lerMais')} <DownOutlined />{' '}
                  </span>
                )}
              </button>
            )}
          </Flex>
        ) : extra ? (
          <p
            style={{
              color: '#000000',
              fontWeight: 400,
              fontSize: '14px',
            }}
          >
            <strong>{t('app.comportamentosSelecionados')}: </strong>{' '}
            {extraFormatted}
          </p>
        ) : (
          <Flex gap={10} vertical style={{ width: '100%' }}>
            <p
              style={{
                color: '#000000',
                fontWeight: 400,
                fontSize: '14px',
              }}
            >
              {isExpanded ? content : preview}
            </p>
            {content?.length > 65 && (
              <button onClick={handleExpansion}>
                {isExpanded ? (
                  <span>
                    {' '}
                    {t('app.mostrarMenos')} <UpOutlined />{' '}
                  </span>
                ) : (
                  <span>
                    {' '}
                    {t('app.lerMais')} <DownOutlined />{' '}
                  </span>
                )}
              </button>
            )}
          </Flex>
        )}

        <Flex gap={10} justify="end">
          <p style={{ color: '#9D9D9D', fontWeight: 500, fontSize: '12px' }}>
            {dateFormatted}
          </p>
          <img
            style={{ width: '20px', height: '20px', borderRadius: '5px' }}
            src={imgSrc}
            alt={imgAlt}
          />
        </Flex>
      </Flex>
    </GenericCard>
  );
}

export default ExpansibleCommentCard;
