import useSeries from 'core/features/series/hooks/useSeries';
import { TfiInfo } from 'react-icons/tfi';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import useSerie from 'core/features/series/hooks/useSerie';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import useQuestions from 'core/features/questions/hooks/useQuestions';
import { Pluralizar } from 'infra/helpers/plural';
import { useEffect, useState } from 'react';
import useAllClassesDetails from 'core/features/classes/hooks/useAllClassesDetails';
import { useMediaQuery } from '@chakra-ui/react';
import { GoAlertFill } from 'react-icons/go';
import './style.css';
import { Flex, Form, Input, Popover, Radio, Select, Switch } from 'antd';
import Text from 'components/typography/text';
import { IClass } from 'core/features/classes/types';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { Permissions } from 'core/resources/enums/permissions';
import { t } from 'core/resources/strings';

function GeneralDataTab({ classDetails }: { classDetails?: IClass }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const form = useFormInstance();
  const [isExpired, setIsExpired] = useState(false);
  const params = useParams<{ id: string; mode: string }>();
  const { data: allSeries } = useSeries();
  const { colorPrimary } = useU4heroColors();
  const { id } = useParams<{ id: string }>();
  const { data: serie } = useSerie(Form.useWatch('serieId', form));
  const [check] = useCheckPermissions();
  const [demonstrativeMode, setDemonstrativeMode] = useState(false);
  const { data } = useQuestions({
    page: 1,
    types: ['1'],
    active: true,
    tenantId: params.id,
    minAge: serie ? serie.minAge : undefined,
    maxAge: serie ? serie.maxAge : undefined,
  });

  const expirateDate = Form.useWatch('expirateDate', form);

  useEffect(() => {
    const createdDate = moment(classDetails?.createdAt);
    if (moment().diff(createdDate, 'days') >= 30 || params.mode) {
      setIsExpired(true);
    }
  }, [classDetails?.createdAt, params.mode]);

  const groupByCompetence = (arr: any[] | undefined) => {
    if (!arr) {
      return;
    }
    const result: any = [];
    for (const item of arr) {
      const index = result.findIndex(
        (i: any) => i.competence === item.competence.name
      );
      if (index !== -1) {
        result[index].quantity += 1;
      } else {
        result.push({
          competence: item?.competence?.name,
          quantity: 1,
        });
      }
    }
    return result;
  };

  const content = (
    <div style={{ color: 'white', marginTop: '8px' }}>
      {t('app.aAvaliacaoInicialÉCompostaPor')} {data?.data?.length}
      {Pluralizar(t('app.pergunta').toLowerCase(), (data?.data?.length || 0) > 1 )}
      {t('app.sendoElas')}:
      <div style={{ marginTop: '8px' }}>
        {groupByCompetence(data?.data)?.map((item: any) => (
          <p>
            {`${item.quantity} ${Pluralizar(
              'pergunta',
              item?.quantity > 1
            )} ${t('app.daCompetencia')} ${item?.competence} `}
          </p>
        ))}
      </div>
    </div>
  );

  const year = moment().year();
  let options = [];
  for (let i = 0; i < 4; i++) {
    options.push({ value: year - i, label: year - i });
  }

  useEffect(() => {
    if (expirateDate) {
      setDemonstrativeMode(true);
    }
  }, [expirateDate]);
  return (
    <Flex style={{ width: '100%', height: '100%' }} vertical>
      <Flex
        style={{ width: '100%', height: '100%' }}
        vertical
        justify="space-between"
        align="space-between"
      >
        {check([Permissions.U4hero.Demo.Create]) && (
          <Flex gap="8px" align="center" style={{ marginBottom: '16px' }}>
            <Text size="md">{t('app.modoDemonstrativo')}:</Text>
            <Switch
              onChange={(e) => {
                setDemonstrativeMode(e);
                if (!e) {
                  form.setFieldValue('expirateDate', undefined);
                }
              }}
              value={demonstrativeMode}
            />
          </Flex>
        )}

        <Flex
          style={{ height: '100%' }}
          vertical={isMobile}
          gap={isMobile ? '2px' : '90px'}
        >
          <Flex
            style={{ width: '100%', height: '100%' }}
            vertical={isMobile && true}
          >
            <Flex
              style={{ width: '100%', height: '100%' }}
              vertical
              justify="space-between"
              align="space-between"
            >
              <Flex vertical>
                <Flex
                  style={{ height: '100%' }}
                  vertical={isMobile}
                  gap={isMobile ? '2px' : '90px'}
                >
                  <Form.Item
                    label={t('app.anoLetivo')}
                    name="year"
                    rules={[
                      {
                        required: true,
                        message: t('app.mensagemDeErroAnoLetivo'),
                      },
                    ]}
                  >
                    <Select
                      id="id-tour-create-class-input-year"
                      style={
                        isMobile
                          ? { width: '130px', height: '30px' }
                          : { width: '160px', height: '40px' }
                      }
                      options={options}
                      disabled={isExpired}
                    />
                  </Form.Item>
                  <Form.Item
                    name="serieId"
                    label={t('app.serie')}
                    rules={[
                      {
                        required: true,
                        message: t('app.mensagemDeErroSerie'),
                      },
                    ]}
                  >
                    <Select
                      id="id-tour-create-class-input-serie"
                      style={
                        isMobile
                          ? { width: '130px', height: '30px' }
                          : { width: '160px', height: '40px' }
                      }
                      options={allSeries?.map((serie) => ({
                        value: serie.id,
                        label: serie.name,
                      }))}
                      disabled={isExpired}
                    />
                  </Form.Item>
                  <Form.Item
                    name="period"
                    label={t('app.turno')}
                    rules={[
                      {
                        required: true,
                        message: t('app.mensagemDeErroTurno'),
                      },
                    ]}
                  >
                    <Select
                      id="id-tour-create-class-input-period"
                      style={
                        isMobile
                          ? { width: '130px', height: '30px' }
                          : { width: '160px', height: '40px' }
                      }
                      disabled={isExpired}
                      options={[
                        { value: 'morning', label: t('app.matutino') },
                        { value: 'afternoon', label: t('app.vespertino') },
                        { value: 'fulltime', label: t('app.integral') },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label={t('app.nome')}
                    rules={[
                      {
                        required: true,
                        message: t('app.mensagemDeErroNomeDaTurma'),
                      },
                    ]}
                  >
                    <Input
                      id="id-tour-create-class-input-name"
                      style={
                        isMobile
                          ? { width: '284px', height: '30px' }
                          : { width: '390px', height: '40px' }
                      }
                    />
                  </Form.Item>
                </Flex>
                <Flex
                  gap={isMobile ? '16px' : '90px'}
                  align={isMobile ? 'self-start' : 'center'}
                  vertical={isMobile}
                >
                  <Form.Item
                    name="expirateDate"
                    label={t('app.dataDeExpiracao')}
                    rules={[
                      {
                        required: demonstrativeMode,
                        message: t('app.mensagemDeErroDataDeExpiracao'),
                      },
                    ]}
                    hidden={!demonstrativeMode}
                  >
                    <Input
                      type="date"
                      id="id-tour-create-class-input-expiration-date"
                      style={
                        isMobile
                          ? { width: '284px', height: '30px' }
                          : { width: '390px', height: '40px' }
                      }
                    />
                  </Form.Item>

                  {id ? null : (
                    <Form.Item
                      name="initialAvaliation"
                      label={
                        <Flex gap={17}>
                          <p>{t('app.desejaCriarUmaAvaliacaoInicial')}</p>
                          <Popover content={content}>
                            <Flex
                              align="center"
                              justify="center"
                              style={{
                                backgroundColor: colorPrimary,
                                borderRadius: '50px',
                                width: '18px',
                                height: '18px',
                                padding: '2px',
                              }}
                            >
                              <TfiInfo
                                style={{ color: 'white', fontWeight: 900 }}
                              />
                            </Flex>
                          </Popover>
                        </Flex>
                      }
                      rules={[
                        {
                          required: true,
                          message: t('app.mensagemDeErroAvaliacaoInicial'),
                        },
                      ]}
                    >
                      <Flex align="center">
                        <Radio.Group id="id-tour-create-class-input-initialAvaliation">
                          <Radio value={true}>{t('app.sim')}</Radio>
                          <Radio value={false}>{t('app.nao')}</Radio>
                        </Radio.Group>
                      </Flex>
                    </Form.Item>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {id && (
        <Flex gap={8} align="center">
          <GoAlertFill style={{ color: '#9D9D9D', fontSize: '17px' }} />
          <p style={{ fontSize: '16px', fontWeight: 400, color: '#9D9D9D' }}>
            {t('app.aviso30DiasTurma')}
          </p>
        </Flex>
      )}
    </Flex>
  );
}
export default GeneralDataTab;
