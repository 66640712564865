import { useQuery } from 'react-query';
import axios from 'infra/http';

export interface UseGetStudentLowestCompetenceParams {
  startDate?: string;
  endDate?: string;
  userId?: string;
}

function useGetStudentLowestCompetence(
  params: UseGetStudentLowestCompetenceParams
) {
  const fetchData = async (
    params: UseGetStudentLowestCompetenceParams
  ): Promise<{ id: string; name: any; average: number }> => {
    const { data } = await axios.get<{
      id: string;
      name: any;
      average: number;
    }>(`/v2/student/lowest-competence`, {
      params,
    });

    return data;
  };

  return useQuery(
    [`/v2/student/lowest-competence`, params],
    () => fetchData(params),
    {
      enabled: !!params.userId,
    }
  );
}

export default useGetStudentLowestCompetence;
