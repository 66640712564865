import {Flex, Typography } from 'antd';
import BackButton from 'components/buttons/back-button';
import Layout from 'components/layout';
import useTagV2 from 'core/features/tags/hooks/useTagV2';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useHistory, useParams } from 'react-router';
import SkillItem from '../skill-item';
const { Title } = Typography;

function ListSkillsMobile() {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { colorPrimary } = useU4heroColors();
  const { data } = useTagV2(params.id);
  return (
    <Layout.Container>
      <Flex gap="12px" align="center" style={{marginBottom:'16px'}}>
        <BackButton onClick={() => history.push('/tags')}/>
        <Title
          level={1}
          style={{
            color: colorPrimary,
            fontSize: '24px',
            fontWeight: 700,
            margin: '0px',
          }}
        >
          {data?.name}
        </Title>
      </Flex>
      <Flex vertical gap='16px' style={{marginBottom:'16px'}}>
      {data?.children.sort((a, b) => a.name.localeCompare(b.name)).map((child) => (
        <SkillItem
          name={child.name}
          description={child.description ? child.description['pt-br'] : ''}
          icon={child.icon ?? undefined}
        />
      ))}
      </Flex>
     
    </Layout.Container>
  );
}

export default ListSkillsMobile;
