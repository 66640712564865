import {
  Box,
  Button,
  Center,
  Container,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react'
import {  primary} from 'core/resources/theme/colors'
import { showSuccess } from 'infra/helpers/alerta'
import { useState } from 'react'
import Report from 'ui/pages/app/indicators/components/Report'
import { BiArrowBack } from 'react-icons/bi'
import { reportTypes } from 'core/features/report/types'
import { t } from 'core/resources/strings'

function Periodo({
  id,
  type,
  setStep,
  classId
}: {
  id?: string
  type: reportTypes
  setStep?: (v: any) => void
  classId?: string
}) {
  const [isOpenReport, setIsOpenReport] = useState(false)
  const [initialDate, setInitialDate] = useState("")
  const [finalDate, setFinalDate] = useState("")

  return (
    <>
    {type !== reportTypes.School ?  <Button
        size="sm"
        ml="20px"
        variant="outline"
        colorScheme="orange"
        leftIcon={<BiArrowBack />}
        onClick={() => setStep && setStep(0)}
      >
       {type === reportTypes.Student ? t('app.Estudantes'): t('app.turmas')} 
      </Button> : null}
     
      <VStack spacing="24px">
        {isOpenReport ? (
          <Report
            type={type}
            classId={type === reportTypes.Student ? classId:id}
            studentId={id}
            initialDate ={initialDate}
            finalDate={finalDate}
            onClose={() => {
              setIsOpenReport(false)
            }}
          />
        ) : null}
        <Container textAlign="center">
          <Text fontSize="16px" color={primary} as="b">
            {t('app.selecioneOPeriodoRelatorio')}
          </Text>
        </Container>
        <Container>
          <Center>
            <Box ml="20px">
              <Text>{t('app.dataDeInicio')}</Text>
              <Input
                onChange={(v) => setInitialDate && setInitialDate(v.target.value)}
                bg="white"
                placeholder={t('app.dataDeInicio')}
                type="date"
                width="150px"
              />
            </Box>
            <Box ml="20px">
              <Text>{t('app.dataFinal')}</Text>
              <Input
                onChange={(v) => setFinalDate && setFinalDate(v.target.value)}
                bg="white"
                placeholder={t('app.dataFinal')}
                type="date"
                width="150px"
              />
            </Box>
          </Center>
        </Container>
        <Container textAlign="center">
          <Text fontSize="16px" color={primary} as="b">
            {t('app.selecioneORelatorioQueDesejaGerar')}
          </Text>
        </Container>
        <Container>
          <Center>
            <Button
              // disabled={cardSelect === ''}
              size="sm"
              variant="outline"
              colorScheme="orange"
              onClick={() => setIsOpenReport(true)}
            >
              {t('app.socioemocional')}
            </Button>
            <Button
              // disabled={cardSelect === ''}
              size="sm"
              ml="20px"
              variant="outline"
              colorScheme="orange"
              onClick={() =>
                showSuccess(t('app.relatorioEmocaoGeradoComSucesso'))
              }
              disabled={true}
            >
              {t('app.emocao')}
            </Button>
          </Center>
        </Container>
      </VStack>
    </>
  )
}

export default Periodo
