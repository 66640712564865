import http from "infra/http";
import { useMutation } from "react-query";
import { BlogPostForm } from "../typings";


export function useSaveBlogPost() {
  return useMutation(
    (data: BlogPostForm) => {
      if (data.id) {
        return http.put(`/v2/blog-posts/`, data);
      }
      return http.post(`/v2/blog-posts`, data);
    },
    { retry: false }
  );
}
