import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex } from 'antd';
import BackButton from 'components/buttons/back-button';
import Button from 'components/buttons/button';
import Layout from 'components/layout';
import { useRef } from 'react';
import { TfiPrinter } from 'react-icons/tfi';
import { useHistory} from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import Practice from './components/practice';
import PracticeFeedback from './components/practiceFeedback';

function PracticePage() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <Layout.Container>
      <BackButton onClick={() => history.goBack()}/>
      <Flex justify="flex-end" style={{ marginBottom: '12px' }}>
        <Button size='md' onClick={handlePrint}>
          <TfiPrinter size={20} color={'white'} />
        </Button>
      </Flex>
      <Practice ref={componentRef} />
      <PracticeFeedback />
    </Layout.Container>
  );
}

export default PracticePage;
