import styled from 'styled-components';

export const GenericCard = styled.div<any>`
  max-width: ${({ maxWidth }) => ( maxWidth ? maxWidth : 'none' )};
  max-height: ${({ maxHeight }) => ( maxHeight ? maxHeight : 'none' )};
  min-height: ${({ minHeight }) => ( minHeight ? minHeight : 'none' )};
  width: ${({ width }) => ( width ? width : 'none' )};
  height: ${({ height }) => ( height ? height : 'none' )};
  border-radius: 20px;
  background: ${({ background }) => (  background ? background : '#fff')};
  box-shadow: 0px -0.5px 4px 0px rgba(0, 0, 0, 0.25),
    0px 3px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  padding: ${({ padding,isMobile }) => ( padding ? padding : isMobile ? '30px 27px' : '16px')};
  overflow: ${({ overflow }) => (  overflow ? overflow : '')};
  cursor: ${({ cursor }) => (  cursor ? cursor : '')};
  &:hover {
    background: ${({ hover }) => (  hover ? hover : '')};
  }
`;