import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Tabs } from 'antd';
import Layout from 'components/layout';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Title from 'components/typography/title';
import { NumberParam, useQueryParam } from 'use-query-params';
import PostsTab from './tabs/PostsTab';
import PostsFeedbacksTab from './tabs/PostsFeedbacksTab';
import BackButton from 'components/buttons/back-button';
import { useHistory } from 'react-router';

function PostsManager() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [step, setStep] = useQueryParam('tab', NumberParam);
  const { colorGreyBorderAndFont, colorPrimary } = useU4heroColors();
  const history = useHistory();
  if (!step) {
    setStep(1);
  }
  const items = [
    {
      key: '1',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 1 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          PUBLICAÇÕES
        </p>
      ),
      children: <PostsTab />,
    },
    {
      key: '2',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 2 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          FEEDBACKS
        </p>
      ),
      children: <PostsFeedbacksTab />,
    },
  ];

  return (
    <Layout.Container>
      <Flex gap={isMobile ? '12px' : '24px'} align="center">
        <BackButton onClick={() => history.push('/posts-hub')} />
        <Title primary>Novidades da Plataforma</Title>
      </Flex>
      <Tabs
        centered
        onTabClick={(event) => setStep(Number(event))}
        size="large"
        defaultActiveKey={step?.toString()}
        items={items}
      />
    </Layout.Container>
  );
}

export default PostsManager;
