import { useQuery } from 'react-query';
import axios from 'infra/http';
import { MetricsSchool } from '../typings';
// import { ActivityForm } from "../types";

export interface UseMetricsSchoolParams {
  tenantId?: string;
  startDate?: string;
  endDate?: string;
}
function useMetricsSchool(params: UseMetricsSchoolParams) {
  const fetchData = async (
    params: UseMetricsSchoolParams
  ): Promise<MetricsSchool> => {
    const { data } = await axios.get<MetricsSchool>(`/v2/metrics/school`, {
      params: {
        tenantId: params.tenantId,
        startDate: params.startDate,
        endDate: params.endDate,
      },
    });

    return data;
  };

  return useQuery(
    [
      `/v2/metrics/school?tenantId=${params.tenantId}&startDate=${params.startDate}&endDate=${params.endDate}`,
    ],
    () => fetchData(params),
    {
      enabled: params.tenantId !== undefined,
    }
  );
}

export default useMetricsSchool;
