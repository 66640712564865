import { Flex, Form, Input } from 'antd';
import Button from 'components/buttons/button';
import Text from 'components/typography/text';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import ChangeDirectorModal from 'ui/pages/app/mySchool/pages/SchoolProfile/components/ChangeDiretorModal';

function ContactInfoSection({ tenantId }: { tenantId?: string }) {
  const { colorPrimary } = useU4heroColors();
  const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <>
      {isOpenModal ? (
        <ChangeDirectorModal tenantId={tenantId} onClose={() => setIsOpenModal(false)} />
      ) : null}
      <GenericCard style={{ position: 'relative' }}>
        {tenantId ? (
          <Button
            size="sm"
            style={{ position: 'absolute', right: 16, top: 16 }}
            variant="outline"
            onClick={() => setIsOpenModal(true)}
          >
            TROCAR DIREÇÃO
          </Button>
        ) : null}

        <Flex vertical style={{ width: '100%' }}>
          <Text
            size="lg"
            color={colorPrimary}
            style={{ marginBottom: '16px', fontWeight: 700 }}
          >
            Direção
          </Text>
          <Form.Item
            label="Nome do Diretor"
            name="adminName"
            rules={[
              {
                required: true,
                message: 'Por favor, insira o nome do diretor!',
              },
            ]}
          >
            <Input
              id="id-tour-instituitions-create-input-admin-name"
              placeholder="Insira o nome do diretor"
              disabled={tenantId !== undefined}
            />
          </Form.Item>
          <Form.Item
            label="E-mail"
            rules={[
              {
                required: true,
                message: 'Por favor, insira o e-mail do diretor!',
              },
            ]}
            name="adminEmail"
          >
            <Input
              type="email"
              style={{
                width: '100%',
              }}
              id="id-tour-instituitions-create-input-admin-email"
              placeholder="Insira o e-mail do diretor"
              disabled={tenantId !== undefined}
            />
          </Form.Item>
        </Flex>
      </GenericCard>
    </>
  );
}

export default ContactInfoSection;
