import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Form, Modal, Pagination, Spin } from 'antd';
import AppliedFilters from 'components/applied-filters';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import SearchBar from 'components/V2/SearchBar';
import Tag from 'components/V2/tag';
import useUsers from 'core/features/user/hooks/useUsers';
import { useCurrentUser } from 'core/features/user/store';
import { t } from 'core/resources/strings';
import { FilterType } from 'infra/helpers/types';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import CollaboratorsFilter from '../../../Collaborators/components/CollaboratorsFilter';

function ChangeDirectorModal({
  onClose,
  tenantId,
}: {
  onClose: () => void;
  tenantId?: string;
}) {
  const { colorPrimary, colorPrimaryLight } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const isLoadingSave = false;
  const [search, setSearch] = useState<string>();
  const [filters, setFilters] = useState<FilterType>({
    profiles: [],
  });
  const [page, setPage] = useState(1);

  const form = Form.useFormInstance();
  const adminEmail = Form.useWatch('adminEmail', form);
  const { user } = useCurrentUser();
  const { data, isLoading } = useUsers({
    page,
    profiles: filters?.profiles?.map((item) => item.value) ?? undefined,
    search,
    tenantId: tenantId ?? user?.tenantId,
    active: filters?.status?.map((item) => item.value)[0] ?? undefined,
    limit: 5,
  });
  return (
    <Modal
      title={<SubTitle primary>{t('app.trocarDirecao')}</SubTitle>}
      open={true}
      onCancel={onClose}
      footer={null}
      width={isMobile ? '100%' : 741}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Flex vertical gap="16px">
        <Text size="lg">
          {t('app.consulteAListaDeColaboradoresCadastrados')}:
        </Text>

        <Flex gap="16px" style={{ width: '100%' }}>
          <SearchBar onSearch={setSearch} value={search} width={'100%'} />
          <CollaboratorsFilter filters={filters} onChangeFilters={setFilters} />
        </Flex>
        <AppliedFilters appliedFilters={filters} onChangeFilters={setFilters} />
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <Flex vertical gap="16px">
            {data?.data.map((item) => (
              <GenericCard
                key={item.id}
                background={
                  item.email === adminEmail ? colorPrimaryLight : '#fff'
                }
                hover={colorPrimaryLight}
                cursor="pointer"
                onClick={() => {
                  form.setFieldsValue({
                    adminEmail: item.email,
                    adminName: item.name,
                  });
                }}
              >
                <Flex justify="space-between" style={{ width: '100%' }}>
                  <Text size="lg">{item.name}</Text>
                  {item.profiles.map((profile) => (
                    <Tag key={profile.id} text={profile.name} />
                  ))}
                </Flex>
              </GenericCard>
            ))}
          </Flex>
        )}

        <Flex justify="center">
          <Pagination
            defaultCurrent={1}
            current={page}
            pageSize={5}
            total={data?.total}
            onChange={(page) => setPage(page)}
          />
        </Flex>
        <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
          <Button
            variant="outline"
            size="md"
            disabled={isLoadingSave}
            onClick={onClose}
            id="id-tour-my-school-settings-modal-button-cancel"
          >
            {t('app.cancelar')}
          </Button>

          <Button
            htmlType="submit"
            size="md"
            loading={isLoadingSave}
            id="id-tour-my-school-settings-modal-button-save"
            onClick={() => {
              onClose();
              form.submit();
            }}
          >
            {t('app.salvar')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default ChangeDirectorModal;
