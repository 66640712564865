import { useQuery } from 'react-query';
import axios from 'axios';
import { baseUrlQedu, IdebRecord, QeduParams } from '../types';
import moment from 'moment';

export function useIdeb(params: { id: number }) {
  const fetchData = async (params: { id: number }): Promise<IdebRecord[]> => {
    let year = moment().year();
    let data: IdebRecord[] = [];
    while (year >= 2000) {
      const response = await axios
        .get<{ data: IdebRecord[] }>(`${baseUrlQedu}/ideb`, {
          params: {
            id: params.id,
            ano: year,
          },
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_QEDU_API_KEY}`,
          },
        })
        .catch((e) => {});
      data = response?.data?.data ?? [];

      if (data.length >= 1) {
        break;
      }

      year--;
    }
    return data;
  };

  return useQuery([`ideb`, params], () => fetchData(params), {
    enabled: !!params.id,
  });
}
