import { useMediaQuery } from '@chakra-ui/react';
import { Select, SelectProps, Tooltip } from 'antd';


export interface SelectBaseProps<T = any> extends SelectProps<T> {
  isInvalid?: boolean;
  tooltip?: string;
}

function SelectBase({ isInvalid, tooltip, ...props }: SelectBaseProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <>
      {tooltip ? (
        <Tooltip title={props.disabled ? tooltip : ''}>
          <Select
            style={{ width: '100%' }}
            allowClear
            placeholder="Selecione"
            size={isMobile ? 'middle':'large'}
            {...props}
            filterOption={(input, option) =>
              (option?.props?.children as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {props.children}
          </Select>
        </Tooltip>
      ) : (
        <Select
          style={{ width: '100%' }}
          allowClear
          placeholder="Selecione"
          {...props}
          size={isMobile ? 'middle':'large'}
          filterOption={(input, option) =>
            (option?.props?.children as string)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {props.children}
        </Select>
      )}
    </>
  );
}

export default SelectBase;
