import {
  Modal,
  DatePicker,
  Form,
  InputNumber,
  Flex,
  Popover
} from 'antd';
import { useEffect } from 'react';
import { useCurrentUser } from 'core/features/user/store';
import { useSaveAutomated } from 'core/features/activity/hooks/useSaveAutomated';
import { AutomatedForm } from 'core/features/activity/types';
import useAutomatedActivity from 'core/features/activity/hooks/useAutomatedActivity';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useMediaQuery } from '@chakra-ui/react';
import { IoClose, IoInformationCircle } from 'react-icons/io5';
import { AiOutlineCalendar } from 'react-icons/ai';
import SelectBase from 'components/inputs/select-base';
import useQuestionTypes from 'core/features/questions/hooks/useQuestionTypes';
import SelectAllClass from 'components/select-class';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import CustomizedRequiredMark from 'components/required-mark';
import Button from 'components/buttons/button';

const dayjs = require('dayjs');
const { RangePicker } = DatePicker;

function ModalAutomated({ onClose, id }: { id?: string; onClose: () => void }) {
  const { data, isLoading } = useAutomatedActivity(id);
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveAutomated();
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const currentUser = useCurrentUser();
  const questionTypes = useQuestionTypes();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const onRangePickerChange = (dates: any, dateStrings: [string, string]) => {
    form.setFieldsValue({
      startSchedule: dayjs(dateStrings[0], 'DD/MM/YYYY').format('YYYY/MM/DD'),
      endSchedule: dayjs(dateStrings[1], 'DD/MM/YYYY').format('YYYY/MM/DD'),
    });
  };

  const onSubmit = (values: AutomatedForm) => {
    delete values.date;
    handleSave(
      {
        ...values,
        responsibleId: currentUser.user?.id,
        responsibleName: currentUser.user?.name,
        tenantId: currentUser.user?.tenantId,
        locale: 'pt-br',
      },
      {
        onSuccess: () => {
          notificateSucess(
            `${id ? 'Automação atualizada' : 'Automação criada'} com sucesso!`
          );
          onClose();
        },
        onError: (err: any) => {
          notificateError(
            `Houve um problema ao ${
              id ? 'editar' : 'criar'
            } a automação. Verifique sua conexão e tente novamente.`
          );
        },
      }
    );
  };

  return (
    <Modal
      title={
        <p
          style={{
            fontSize: isMobile ? '18px' : '24px',
            color: colorPrimary,
            fontWeight: 700,
          }}
        >
          {id ? 'Editar' : 'Nova'} Automação
        </p>
      }
      width={isMobile ? 288 : 416}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
      open={true}
      onCancel={() => {
        if (form.isFieldsTouched()) {
          openNotification({ onClose, colorPrimary, isMobile });
        } else {
          onClose();
        }
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        initialValues={{
          date: data
            ? [dayjs(data.startSchedule), dayjs(data.endSchedule)]
            : undefined,
          classId: data ? data.classId : undefined,
          serieId: data ? data?.serieId : undefined,
          className: data ? data.className : undefined,
          period: data ? data.period : undefined,
          duration: data ? data.duration : undefined,
          questionAmount: data ? data.questionAmount : undefined,
          typeId: data ? data.typeId : '0',
          startSchedule: data ? data.startSchedule : undefined,
          endSchedule: data ? data.endSchedule : undefined,
        }}
        requiredMark={CustomizedRequiredMark}
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Por favor, informe o período de agendamento!',
            },
          ]}
          name="date"
          id="id-tour-activities-automated-activity-modal-input-date"
          label="Período de agendamento"
        >
          <RangePicker
            style={{ width: '100%' }}
            format="DD/MM/YYYY"
            suffixIcon={<AiOutlineCalendar color="black" />}
            picker={'date'}
            onChange={onRangePickerChange}
          />
        </Form.Item>

        <Form.Item name="startSchedule" hidden />
        <Form.Item name="endSchedule" hidden />
        <Form.Item name="serieId" hidden />
        <Form.Item name="className" hidden />
        <Form.Item
          id="id-tour-activities-automated-activity-modal-input-period"
          label={
            <Flex gap="16px" align="center" style={{fontSize: isMobile ? '14px':'18px',color:'black'}}>
              Frequência (dias){' '}
              <Popover
                content={
                  <div
                    style={{
                      background: colorPrimary,
                      borderRadius: '10px',
                      color: 'white',
                      fontSize: '14px',
                    }}
                  >
                    Intervalo de criação de atividades (dias).
                  </div>
                }
                trigger={isMobile ? 'click' : 'hover'}
              >
                <IoInformationCircle color={colorPrimary} size={20} />
              </Popover>
            </Flex>
          }
          name="period"
          rules={[
            {
              required: true,
              message: 'Por favor, defina a frequência em dias!',
              type: 'number',
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={1}
            placeholder="Frequência Atividades (dias)"
          />
        </Form.Item>

        <Form.Item
          id="id-tour-activities-automated-activity-modal-input-duration"
          label={
            <Flex gap="16px" align="center" style={{fontSize: isMobile ? '14px':'18px',color:'black'}}>
              Prazo (dias){' '}
              <Popover
                content={
                  <div
                    style={{
                      background: colorPrimary,
                      borderRadius: '10px',
                      color: 'white',
                      fontSize: '14px',
                    }}
                  >
                    Prazo para atividade automática (dias).
                  </div>
                }
                trigger={isMobile ? 'click' : 'hover'}
              >
                <IoInformationCircle color={colorPrimary} size={20} />
              </Popover>
            </Flex>
          }
          name="duration"
          rules={[
            {
              required: true,
              message: 'Por favor, defina o prazo em dias!',
              type: 'number',
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={1}
            placeholder="Prazo Atividades (dias)"
          />
        </Form.Item>

        <Form.Item
          id="id-tour-activities-automated-activity-modal-input-question-amount"
          label="Quantidade de perguntas"
          name="questionAmount"
          rules={[
            {
              required: true,
              message: 'Por favor, defina a quantidade de perguntas!',
              type: 'number',
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={1}
            max={6}
            placeholder="De 1 à 6"
          />
        </Form.Item>

        <Form.Item
          id="id-tour-activities-automated-activity-modal-input-class"
          label="Turma"
          name="classId"
          rules={[
            {
              required: true,
              message: 'Por favor, escolha a turma!',
            },
          ]}
        >
          {/* @ts-ignore */}
          <SelectAllClass
            callback={(v) => {
              if (v !== undefined) {
                form.setFieldsValue({
                  className: v.name,
                  serieId: v.serie.id,
                });
              }
            }}
          />
        </Form.Item>

        <Form.Item
          id="id-tour-activities-automated-activity-modal-input-type"
          label="Tipo"
          name="typeId"
          rules={[
            {
              required: true,
              message: 'Por favor, selecione o tipo de atividade',
              type: 'string',
            },
          ]}
        >
          <SelectBase
            disabled={true}
            options={
              questionTypes?.data?.map((item) => ({
                label: item.name,
                value: item.id.toString(),
              })) ?? []
            }
          />
        </Form.Item>

        <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
          <Button
           variant='outline'
            size="md"
            disabled={isLoading || isLoadingSave}
            onClick={() => {
              if (form.isFieldsTouched()) {
                openNotification({ onClose, colorPrimary, isMobile });
              } else {
                onClose();
              }
            }}
            id="id-tour-activities-automated-activity-modal-button-cancel"
          >
            CANCELAR
          </Button>

          <Button
            htmlType="submit"
            size='md'
            disabled={isLoading || isLoadingSave}
            style={{
              fontWeight: 600,
              fontSize: isMobile ? '12px' : '16px',
            }}
            id="id-tour-activities-automated-activity-modal-button-save"
          >
            SALVAR
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
}

export default ModalAutomated;
