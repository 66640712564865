import { Flex } from 'antd';
import Button from 'components/buttons/button';
import { useState } from 'react';
import { useHistory } from 'react-router';
import HelpersFeedbackModal from '../../helpersFeedbackModal';
import HelpersTable from '../../HelpersTable';

function HelpersTab() {
  const history = useHistory();
  const [isOpenModalFeedback, setIsOpenModalFeedback] = useState(false);
  const [helperId, setHelperId] = useState<string>();
  return (
    <Flex vertical gap='16px'>
       {isOpenModalFeedback ? (
        <HelpersFeedbackModal
          id={helperId}
          onClose={() => {
            setIsOpenModalFeedback(false);
            setHelperId(undefined);
          } }
        />
      ) : null}
      <Button
        id="id-tour-create-helper-button-add-helper"
        size={'md'}
        onClick={() => history.push('/helpers-u4hero/new')}
        style={{
          alignSelf: 'end',
        }}
      >
        Criar ajuda
      </Button>
      <HelpersTable onViewFeedback={(id)=>{
          setHelperId(id);
          setIsOpenModalFeedback(true);
      }} />
    </Flex>
  );
}

export default HelpersTab;
