import { Divider } from 'antd';
import { forwardRef } from 'react';
import { StudentBasic } from '../DownloadStudentKeys';
import StudentKeyItem from '../StudentKeyItem';
import './style.css';

interface StudentKeysProps {
  students: StudentBasic[];
}

const StudentKeysNotes = forwardRef<HTMLDivElement, StudentKeysProps>(
  ({ students }, ref) => {
    return (
      <div ref={ref} className="student-keys-notes-container">
        {students.map((student, index) => (
          <div
            key={student.id}
            className={`student-key-item-wrapper ${
              index % 4 === 3 ? 'page-break' : ''
            }`}
          >
            <StudentKeyItem
              school={student.tenantName}
              name={student.name}
              license={student.license}
            />
            <Divider style={{ background: 'black', margin: '0px' }} />
          </div>
        ))}
      </div>
    );
  }
);

export default StudentKeysNotes;
