import { useMutation, useQuery } from "react-query";
import axios from "infra/http";
import { IObservation, IObservationCreate, IUseObservationParams } from "../typings";

function useObservations(params: IUseObservationParams) {

  const fetchData = async (
    params: IUseObservationParams
  ): Promise<IObservation[]> => {
    const { data } = await axios.get<IObservation[]>(`/v2/observation/${params.userId}`, {
      params: {
        userId: params.userId,
        startDate: params.startDate,
        endDate: params.endDate,
        observationType: params.observationType,
      }
    });

    return data;
  }

  return useQuery(
    [
      `/v2/observation/${params.userId}?startDate=${params.startDate}&endDate=${params.endDate}&observationType=${params.observationType}`,
      params,
    ],
    () => fetchData(params),
    {
      enabled: !!params.userId
    }
  );
}

function useCreateObservation() {
  return useMutation(
    async (observation:IObservationCreate) => {
      const { data } = await axios.post('/v2/observation', observation);
      return data;
    }
  )
}

export default useObservations;
export { useCreateObservation }