import { Flex } from 'antd';
import Button from 'components/buttons/button';
import ModalResource from 'components/modals/modal-resource';
import { useState } from 'react';
import ResourcesTable from 'ui/pages/app/settings-u4hero/tabs/ResourcesTab/ResourcesTable';

function ResourcesTab() {
  const [isOpen, setIsOpen] = useState(false);
  const [resourceId, setResourceId] = useState<string | undefined>(undefined);
  return (
    <>
      {isOpen && <ModalResource onClose={() => {
        setResourceId(undefined);
        setIsOpen(false);
      }} resourceId={resourceId} />}
      <Flex style={{ marginTop: '16px', marginBottom: '17px' }} justify="end">
        <Button
          id="id-tour-settings-u4hero-create-resource-button-add-resource"
          size="md"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          CRIAR RECURSO
        </Button>
      </Flex>
      <ResourcesTable onEdit={(resourceId) => {
        setResourceId(resourceId);
        setIsOpen(true);
      }} />
    </>
  );
}

export default ResourcesTab;
