import { useMediaQuery } from '@chakra-ui/react';
import { Dropdown, Flex, Menu } from 'antd';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useEffect, useState } from 'react';
import { Permissions } from 'core/resources/enums/permissions';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import DynamicTable from 'components/V2/dinamic-table';
import { FaEllipsisVertical } from 'react-icons/fa6';
import Status from 'components/status';
import { notificateSucess } from 'infra/helpers/notifications';
import { FilterType } from 'infra/helpers/types';
import { Game } from 'core/features/games/typings';
import useGamesv1 from 'core/features/games/hooks/useGamesv1';
import GameFilter from 'ui/pages/app/activities/pages/CreateActivity/components/StepGame/GameFilter';
import defaultGameImg from 'assets/images/default-game.png';
import { useToggleGame } from 'core/features/games/hooks/useToggleStudent';

function GamesTable({
  onViewFeedback,
  onEdit,
}: {
  onViewFeedback: (v: string) => void;
  onEdit: (v: string) => void;
}) {
  const [check] = useCheckPermissions();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { mutate: handleToggle } = useToggleGame();

  const [filters, setFilters] = useState<FilterType>({
    age: [],
    status: [],
  });

  const { data, isLoading, refetch } = useGamesv1({
    page,
    search,
    limit: pageSize,
    active: filters.status?.length
      ? filters.status.map((item) => item.value)[0] === 1
      : undefined,
    ageClassification: filters?.age?.map((item) => item.value)[0] ?? undefined,
  });

  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };
  const columns = [
    {
      title: <p style={tableHeaderStyle}></p>,
      dataIndex: 'game.midia',
      key: 'game.midia',
      mobile: true,
      render: (game: string, record: Game) => (
        <img
          alt={record.name}
          width="80px"
          style={{ borderRadius: '5px', aspectRatio: '16/9' }}
          src={record.coverImageUrl ?? defaultGameImg}
        />
      ),
    },
    {
      title: <p style={tableHeaderStyle}>Jogo</p>,
      name: 'Jogo',
      dataIndex: 'name',
      key: 'name',
      mobile: true,
      render: (name: string, record: Game) => (
        <p style={tableRowStyle}>{record.name}</p>
      ),
      sorter: (a: Game, b: Game) => a.name.localeCompare(b.name),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Faixa Etária</p>,
      name: 'Faixa Etária',
      dataIndex: 'age',
      key: 'age',
      render: (_: string, record: Game) => (
        <Flex vertical style={tableRowStyle}>
          A partir de {record.minAge} anos
        </Flex>
      ),
      sorter: (a: Game, b: Game) => a.minAge - b.minAge,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    // {
    //   title: <p style={tableHeaderStyle}>Avaliação</p>,
    //   name: 'Avaliação',
    //   dataIndex: 'rating',
    //   key: 'rating',
    //   render: (_: string, record: Game) => <p style={tableRowStyle}>4.5</p>,
    //   // trocar sorter
    //   sorter: (a: Game, b: Game) => a.name.localeCompare(b.name),
    //   sortIcon: (sortOrder: any) =>
    //     sortOrder.sortOrder === 'descend' ? (
    //       <MdExpandLess size={25} />
    //     ) : (
    //       <MdExpandMore size={25} />
    //     ),
    // },
    {
      title: <p style={tableHeaderStyle}>Status</p>,
      dataIndex: 'active',
      key: 'active',
      name: 'Status',
      render: (_: string, record: Game) => (
        <Status type={record.active ? 'active' : 'inactive'} size="md" />
      ),
      sorter: (a: Game, b: Game) => (a.active ? 1 : -1),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Ações</p>,
      key: 'action',
      mobile: true,
      dataIndex: 'action',
      name: 'Ações',
      render: (_: string, record: Game) => (
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => {
                if (e.key === 'feedback') {
                  onViewFeedback(record.id);
                }
                if (e.key === 'toggle') {
                  handleToggle(
                    {
                      id: record.id,
                      isActive: record.active,
                    },
                    {
                      onSuccess: () => {
                        notificateSucess(
                          `O jogo “${record.name}” foi ${
                            record.active ? 'desativado' : 'ativado'
                          } com sucesso!`
                        );
                        refetch();
                      },
                      onError: () => {
                        notificateSucess(
                          `Houve um problema ao ${
                            record.active ? 'desativar' : 'ativar'
                          } o jogo “${
                            record.name
                          }” Verifique sua conexão e tente novamente.`
                        );
                      },
                    }
                  );
                }
                if (e.key === 'edit') {
                  onEdit(record.id);
                }
              }}
            >
              {check([Permissions.U4hero.Helpers.Edit]) ? (
                <Menu.Item id="edit-button" key={'edit'}>
                  Editar
                </Menu.Item>
              ) : null}
              {check([Permissions.U4hero.Helpers.Edit]) ? (
                <Menu.Item id="toggle-button" key={'toggle'}>
                  {record.active ? 'Desativar' : 'Ativar'}
                </Menu.Item>
              ) : null}
              <Menu.Item key={'feedback'}>Feedbacks</Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <FaEllipsisVertical
            id="id-tour-action-games-table"
            size={24}
            style={{ cursor: 'pointer' }}
          />
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    setPage(1);
  }, [search, filters]);

  return (
    <DynamicTable
      id="id-tour-games-table"
      itemName="jogo"
      data={data?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title=""
      total={data?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isLoading}
      defaultColumns={
        isMobile ? columns.filter((column) => column.mobile) : columns
      }
      allColumns={columns}
      onSearch={setSearch}
      appliedFilters={filters}
      onChangeFilters={setFilters}
      filters={<GameFilter filters={filters} onChangeFilters={setFilters} />}
    />
  );
}

export default GamesTable;
