import { useQuery } from "react-query";
import axios from "infra/http";
import { ClassDashboardInfo } from "../types";

function useClassDashboardInfo(id:string) {
  return useQuery(`/v2/students/${id}`, async ():Promise<ClassDashboardInfo> => {
    const { data } = await axios.get<ClassDashboardInfo>(`/v2/class/dashboard/${id}`);
    return data;
  });
}

export default useClassDashboardInfo;