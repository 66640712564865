import { useMediaQuery } from '@chakra-ui/media-query';
import { Col, Flex, Row } from 'antd';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { useRendimentoEscolar } from 'core/features/qedu/hooks/useRendimentoEscolar';
import { useCurrentUser } from 'core/features/user/store';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';

function AcademicPerformance() {
  const { user } = useCurrentUser();
  const { data } = useRendimentoEscolar({ inep_id: user?.inep_id });
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorGreyBorderAndFont, colorPrimary, colorSecondary } =
    useU4heroColors();
  const series = [
    {
      id: 15,
      serie: 'Anos Finais',
    },
    {
      id: 16,
      serie: 'Ensino Médio',
    },
  ];
  const filteredData =
    data?.filter((item) =>
      series.some((serie) => serie.id === item.serie_id)
    ) || [];

  const tableData = filteredData.map((item) => ({
    nome:
      series.find((serie) => serie.id === item.serie_id)?.serie || 'Unknown',
    reprovados:
      item.reprovados !== null
        ? `${parseFloat(item.reprovados).toFixed(1)}%`
        : 'Sem dados',
    abandonos:
      item.abandonos !== null
        ? `${parseFloat(item.abandonos).toFixed(1)}%`
        : 'Sem dados',
    aprovados:
      item.aprovados !== null
        ? `${parseFloat(item.aprovados).toFixed(1)}%`
        : 'Sem dados',
  }));

  return (
    <Flex
      vertical
      style={{ padding: '16px', height: '100%', position: 'relative' }}
      className="box-shadow-generic-card"
      gap={16}
    >
      <div>
        <Text
          style={{ position: 'absolute', top: 8, right: 16 }}
          size="sm"
          align="end"
          color={colorGreyBorderAndFont}
        >
          {data?.[0]?.ano}
        </Text>
        <SubTitle primary style={{ lineHeight: 'normal' }}>
          Taxas de Rendimento Escolar
        </SubTitle>
        <Text size="md" style={{ lineHeight: 'normal' }}>
          Percentual de estudantes reprovados, aprovados e que abandonaram a
          escola durante o ano letivo
        </Text>
      </div>

      {isMobile ? (
        <Flex vertical gap={16}>
          {tableData.map((item, index) => (
            <Flex key={index} gap={8} vertical>
              <div style={{ textAlign: 'center' }}>
                <Text
                  size="md"
                  style={{
                    display: 'inline-block',
                    position: 'relative',
                  }}
                >
                  {item.nome}
                  <span
                    style={{
                      position: 'absolute',
                      bottom: '-4px',
                      left: 0,
                      width: '100%',
                      height: '2px',
                      backgroundColor: colorSecondary,
                    }}
                  />
                </Text>
              </div>
              <Flex
                style={{
                  borderRadius: '10px',
                  border: `1px solid ${hex2rgba(colorGreyBorderAndFont, 0.3)}`,
                  padding: '12px 16px',
                }}
                justify="center"
              >
                <Text size="md">
                  Reprovação:{' '}
                  <span style={{ color: colorSecondary, fontSize: '18px' }}>
                    {item.reprovados}
                  </span>
                </Text>
              </Flex>
              <Flex
                style={{
                  borderRadius: '10px',
                  border: `1px solid ${hex2rgba(colorGreyBorderAndFont, 0.3)}`,
                  padding: '12px 16px',
                }}
                justify="center"
              >
                <Text size="md">
                  Abandonos:{' '}
                  <span style={{ color: colorSecondary, fontSize: '18px' }}>
                    {item.abandonos}
                  </span>
                </Text>
              </Flex>
              <Flex
                style={{
                  borderRadius: '10px',
                  border: `1px solid ${hex2rgba(colorGreyBorderAndFont, 0.3)}`,
                  padding: '12px 16px',
                }}
                justify="center"
              >
                <Text size="md">
                  Aprovação:{' '}
                  <span style={{ color: colorPrimary, fontSize: '18px' }}>
                    {item.aprovados}
                  </span>
                </Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      ) : (
        <Row gutter={[0, 16]}>
          <Col span={4}></Col>
          <Col span={4}>
            <Text size="md">Reprovação</Text>
          </Col>
          <Col span={4}>
            <Text size="md">Abandono</Text>
          </Col>
          <Col span={4}>
            <Text size="md">Aprovação</Text>
          </Col>

          {tableData.map((item, index) => (
            <Col
              key={index}
              span={24}
              style={{
                borderRadius: '10px',
                border: `1px solid ${hex2rgba(colorGreyBorderAndFont, 0.3)}`,
                padding: '12px 16px',
              }}
            >
              <Row>
                <Col span={4}>
                  <Text size="md">{item.nome}</Text>
                </Col>
                <Col span={4}>
                  <Text size="lg" bold color={colorSecondary}>
                    {item.reprovados}
                  </Text>
                </Col>
                <Col span={4}>
                  <Text size="lg" bold color={colorSecondary}>
                    {item.abandonos}
                  </Text>
                </Col>
                <Col span={4}>
                  <Text size="lg" bold color={colorPrimary}>
                    {item.aprovados}
                  </Text>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      )}
    </Flex>
  );
}

export default AcademicPerformance;
