import { primary } from "core/resources/theme/colors";
import styled from "styled-components";

export const InputContainer = styled.div`
  .chakra-input:hover,
  .chakra-input:focus {
    border-color: ${primary} !important;
    box-shadow: 0 0 0 2px ${primary} !important;
    outline-color: ${primary};
    transition: all 0.2s;
  }

  .chakra-input {
    border-radius: 6px;
    padding: 10px 16px;
    width: 100%;
  }
`;
