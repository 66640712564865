import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Popover, Row, Spin } from 'antd';
import GaugeMultipleIndicators from 'components/GaugeMultipleIndicators';
import EyesIcon from 'components/icons/EyesIcon';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useAttentionPointsClass from 'core/features/indicators/hooks/useAttentionPointsClass';
import useAttentionPointsComparative from 'core/features/indicators/hooks/useAttentionPointsComparative';
import useAttentionPointsSchool from 'core/features/indicators/hooks/useAttentionPointsSchool';
import { t } from 'core/resources/strings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import moment from 'moment';
import { IoInformationCircle } from 'react-icons/io5';
import { useHistory } from 'react-router';

function AttentionPoints() {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
  const endDate = moment().add(1, 'day').format('YYYY-MM-DD');
  const history = useHistory();
  const { data, isLoading } = useAttentionPointsSchool({
    startDate,
    endDate,
  });
  const { data: dataClass } = useAttentionPointsClass({
    startDate: data?.data.startDate,
    endDate: data?.data.endDate,
  });
  const { data: dataComparative } = useAttentionPointsComparative({
    startDate: data?.data.startDate,
    endDate: data?.data.endDate,
  });

  return (
    <Flex
      vertical
      style={{ width: '100%' }}
      id="id-tour-section-attention-points"
    >
      <p
        style={{
          color: colorPrimary,
          fontSize: isMobile ? '18px' : '24px',
          fontWeight: 700,
        }}
      >
        {t('app.pontosAtenção')}
      </p>

      <p
        style={{
          color: colorGreyBorderAndFont,
          fontSize: isMobile ? '12px' : '14px',
          fontWeight: 500,
          alignSelf: 'end',
        }}
      >
        {t(`app.${data?.data.period}`)}
      </p>
      {data?.data ? (
        <Flex
          justify="center"
          gap="16px"
          align="center"
          style={{ marginBottom: '8px' }}
          id="id-tour-attention-points-general-info"
        >
          <p
            style={{
              color: colorPrimary,
              fontSize: isMobile ? '16px' : '22px',
              fontWeight: 700,
            }}
          >
            {t('app.geral')}
          </p>
          <Popover
            content={
              <div
                style={{
                  background: colorPrimary,
                  borderRadius: '10px',
                  color: 'white',
                  fontSize: '14px',
                  maxWidth: '311px',
                }}
              >
                {t('app.explicacaoGeral')}
              </div>
            }
            trigger={isMobile ? 'click' : 'hover'}
          >
            <IoInformationCircle color={colorPrimary} size={25} />
          </Popover>
        </Flex>
      ) : null}

      <Flex vertical gap={isMobile ? '16px' : '24px'}>
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <Row gutter={isMobile ? [0, 8] : [16, 0]} justify="center">
            {data?.data && data?.data.competences.length === 4 ? (
              data.data.competences.map((item, index) => (
                <Col
                  sm={isMobile ? 24 : 6}
                  key={index}
                  style={{ width: '100%' }}
                >
                  <GaugeMultipleIndicators
                    onAct={() => {
                      history.push(`/practices/suggestions?tagId=${item.id}`);
                    }}
                    onExplore={() => {
                      history.push(`/indicators/?tab=3`);
                    }}
                    id={`id-tour-general-card-${index}`}
                    tag={item.name['pt-br']}
                    positive={index > 1}
                    average={item.average}
                    data={[
                      item.comprehension,
                      item.spontaneousDecision,
                      item.thirdParty,
                    ]}
                  />
                </Col>
              ))
            ) : (
              <Flex
                vertical
                align="center"
                justify="center"
                gap="16px"
                id="id-tour-no-info-general"
              >
                <EyesIcon color="#7B7B7B" size={60} />
                <p
                  style={{
                    fontSize: '14px',
                    color: '#7B7B7B',
                    textAlign: 'center',
                    fontWeight: 400,
                    maxWidth: isMobile ? '' : '513px',
                  }}
                >
                  {t('app.naoHaInformacoesSuficientes')}
                </p>
              </Flex>
            )}
          </Row>
        )}

        {data?.data &&
        data?.data.competences.length === 4 &&
        dataClass?.data &&
        dataComparative?.data ? (
          <Row gutter={isMobile ? [0, 16] : [32, 0]} align="stretch">
            <Col sm={isMobile ? 24 : 12}>
              <Flex vertical gap="8px">
                <Flex
                  justify="center"
                  gap="16px"
                  align="center"
                  id="id-tour-attention-points-class-info"
                >
                  <p
                    style={{
                      color: colorPrimary,
                      fontSize: isMobile ? '16px' : '22px',
                      fontWeight: 700,
                    }}
                  >
                    {t('app.turma')}
                  </p>
                  <Popover
                    content={
                      <div
                        style={{
                          background: colorPrimary,
                          borderRadius: '10px',
                          color: 'white',
                          fontSize: '14px',
                          maxWidth: '311px',
                        }}
                      >
                        {t('app.explicacaoTurma')}
                      </div>
                    }
                    trigger={isMobile ? 'click' : 'hover'}
                  >
                    <IoInformationCircle color={colorPrimary} size={25} />
                  </Popover>
                </Flex>
                <p
                  style={{
                    color: colorPrimary,
                    fontSize: isMobile ? '16px' : '22px',
                    fontWeight: 700,
                    textAlign: 'center',
                  }}
                >
                  {dataClass?.data
                    ? ` ${dataClass?.data.serie} ${dataClass?.data.className} - ${dataClass?.data.year}`
                    : ''}
                </p>

                <Row gutter={isMobile ? [0, 8] : [8, 0]} justify="center">
                  <Col sm={isMobile ? 24 : 12} style={{ width: '100%' }}>
                    <GaugeMultipleIndicators
                      onAct={() => {
                        history.push(
                          `/practices/suggestions?tagId=${dataClass?.data.lowestCompetence.tagId}`
                        );
                      }}
                      onExplore={() => {
                        history.push(
                          `/my-school/class/dashboard/${dataClass.data.classId}`
                        );
                      }}
                      id={`id-tour-class-card-lowest-competence`}
                      tag={dataClass?.data.lowestCompetence?.name['pt-br']}
                      positive={false}
                      average={
                        dataClass?.data.lowestCompetence.average as number
                      }
                      data={
                        dataClass?.data
                          ? [
                              dataClass?.data.lowestCompetence?.comprehension,
                              dataClass?.data.lowestCompetence
                                ?.spontaneousDecision,
                              dataClass?.data.lowestCompetence?.thirdParty,
                            ]
                          : [
                              { totalResponses: 0, percentage: 0 },
                              { totalResponses: 0, percentage: 0 },
                              { totalResponses: 0, percentage: 0 },
                            ]
                      }
                    />
                  </Col>
                  <Col sm={isMobile ? 24 : 12} style={{ width: '100%' }}>
                    <GaugeMultipleIndicators
                      onAct={() => {
                        history.push(
                          `/practices/suggestions?tagId=${dataClass?.data.highestCompetence?.tagId}`
                        );
                      }}
                      onExplore={() => {
                        history.push(
                          `/my-school/class/dashboard/${dataClass.data.classId}`
                        );
                      }}
                      id={`id-tour-class-card-highest-competence`}
                      tag={dataClass?.data.highestCompetence?.name['pt-br']}
                      positive={true}
                      average={
                        dataClass?.data.highestCompetence.average as number
                      }
                      data={
                        dataClass?.data
                          ? [
                              dataClass?.data.highestCompetence?.comprehension,
                              dataClass?.data.highestCompetence
                                ?.spontaneousDecision,
                              dataClass?.data.highestCompetence?.thirdParty,
                            ]
                          : [
                              { totalResponses: 0, percentage: 0 },
                              { totalResponses: 0, percentage: 0 },
                              { totalResponses: 0, percentage: 0 },
                            ]
                      }
                    />
                  </Col>
                </Row>
              </Flex>
            </Col>
            <Col sm={isMobile ? 24 : 12}>
              <Flex vertical gap="8px">
                <Flex
                  justify="center"
                  gap="16px"
                  align="center"
                  id="id-tour-attention-points-comparative-info"
                >
                  <p
                    style={{
                      color: colorPrimary,
                      fontSize: isMobile ? '16px' : '22px',
                      fontWeight: 700,
                    }}
                  >
                    {t('app.comparativo')}
                  </p>
                  <Popover
                    content={
                      <div
                        style={{
                          background: colorPrimary,
                          borderRadius: '10px',
                          color: 'white',
                          fontSize: '14px',
                          maxWidth: '311px',
                        }}
                      >
                        {t('app.explicacaoComparativo')}
                      </div>
                    }
                    trigger={isMobile ? 'click' : 'hover'}
                  >
                    <IoInformationCircle color={colorPrimary} size={25} />
                  </Popover>
                </Flex>

                <Row gutter={isMobile ? [0, 8] : [8, 0]} align="stretch">
                  <Col
                    sm={isMobile ? 24 : 12}
                    style={{ width: '100%', height: '100%' }}
                  >
                    <p
                      style={{
                        color: colorPrimary,
                        fontSize: isMobile ? '16px' : '22px',
                        fontWeight: 700,
                        textAlign: 'center',
                        marginBottom: '8px',
                      }}
                    >
                      {dataComparative?.data
                        ? dataComparative?.data.studentName
                        : null}
                    </p>
                    <GaugeMultipleIndicators
                      onAct={() => {
                        history.push(
                          `/practices/suggestions?tagId=${dataComparative?.data.competence.id}`
                        );
                      }}
                      onExplore={() => {
                        history.push(
                          `/my-school/student/${dataComparative.data.userId}`
                        );
                      }}
                      id={`id-tour-comparative-card-lowest-student`}
                      tag={dataComparative?.data?.competence?.name['pt-br']}
                      positive={false}
                      average={
                        dataComparative?.data.competence.average as number
                      }
                      data={
                        dataComparative?.data
                          ? [
                              dataComparative?.data.competence.comprehension,
                              dataComparative?.data.competence
                                .spontaneousDecision,
                              dataComparative?.data.competence.thirdParty,
                            ]
                          : [
                              { totalResponses: 0, percentage: 0 },
                              { totalResponses: 0, percentage: 0 },
                              { totalResponses: 0, percentage: 0 },
                            ]
                      }
                    />
                  </Col>
                  <Col
                    sm={isMobile ? 24 : 12}
                    style={{ width: '100%', height: '100%' }}
                  >
                    <p
                      style={{
                        color: colorPrimary,
                        fontSize: isMobile ? '16px' : '22px',
                        fontWeight: 700,
                        textAlign: 'center',
                        marginBottom: '8px',
                      }}
                    >
                      {dataComparative?.data
                        ? `${dataComparative?.data.serie} ${dataComparative?.data.className} - ${dataComparative?.data.year}`
                        : null}
                    </p>
                    <GaugeMultipleIndicators
                      onAct={() => {
                        history.push(`/practices/suggestions`);
                      }}
                      onExplore={() => {
                        history.push(
                          `/my-school/class/dashboard/${dataComparative.data.classId}`
                        );
                      }}
                      id={`id-tour-comparative-card-lowest-student-class`}
                      tag={dataComparative?.data?.competence?.name['pt-br']}
                      positive={true}
                      average={
                        dataComparative?.data.classResult.average as number
                      }
                      data={
                        dataComparative?.data
                          ? [
                              dataComparative?.data.classResult.comprehension,
                              dataComparative?.data.classResult
                                .spontaneousDecision,
                              dataComparative?.data.classResult.thirdParty,
                            ]
                          : [
                              { totalResponses: 0, percentage: 0 },
                              { totalResponses: 0, percentage: 0 },
                              { totalResponses: 0, percentage: 0 },
                            ]
                      }
                    />
                  </Col>
                </Row>
              </Flex>
            </Col>
          </Row>
        ) : null}
      </Flex>
    </Flex>
  );
}

export default AttentionPoints;
