import { Button, Collapse, Flex, Slider, Typography } from "antd";
import { GenericCard } from "components/V2/generic-card-styled-component/index";
import Layout from "components/layout";
import StudentInformation from "../../components/student-information";
import { useEffect, useState } from "react";
import useU4heroColors from "infra/packages/antd/u4heroTokens";
import AnotationDrawer from "../../components/annotation-drawer";
import Table from "components/V2/table";
import useManifestations from "core/features/inkluzen/hooks/useManifestations";
import { IManifestation } from "core/features/inkluzen/types";
import QuestionAnswerCard from "../../components/questions-answer-card";
import type { CollapseProps } from 'antd';
import type { CSSProperties } from 'react';
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined';
import { Col, Row } from 'antd';
import { useHistory, useParams } from "react-router";
import { useMediaQuery } from "@chakra-ui/media-query";
import { ArrowLeftOutlined } from '@ant-design/icons';

interface RouteParams {
  id: string;
}

function ProcessDetails() {

  const { id } = useParams<RouteParams>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { colorPrimary } = useU4heroColors();
  const manifestations = useManifestations();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { Title } = Typography;
  const questionsAnswers = [
    {
      question: 'Algum aborrecimento?',
      answer: 'tenho lidado com um aborrecimento devido a problemas de comunicação com meus familiares.'
    },
    {
      question: 'Algum nojo?',
      answer: 'O comportamento dele em relação aos outros me causa um profundo sentimento de nojo.'
    },
    {
      question: 'Algum fracasso?',
      answer: 'Apesar de todos os meus esforços, a sensação de fracasso persiste, e é difícil lidar com ela.'
    },
  ]
  const collapseItens: (panelStyle: CSSProperties) => CollapseProps['items'] = (panelStyle) => [
    {
      key: '1',
      label: 'Grave',
      children: <p>Quando alguém é classificado como tendo um problema de saúde mental "grave", isso significa que seus sintomas são intensos, persistentes e têm um impacto significativo em sua vida diária.
        Os sintomas podem ser incapacitantes e interferir substancialmente nas atividades cotidianas, relacionamentos e funcionamento geral.
        Os transtornos de saúde mental graves podem incluir esquizofrenia, transtorno bipolar grave, transtorno de personalidade borderline e transtorno depressivo maior grave</p>,
      style: panelStyle,
    },
    {
      key: '2',
      label: 'Moderado',
      children: <p>Teste</p>,
      style: panelStyle,
    },
    {
      key: '3',
      label: 'Leve',
      children: <p>Teste</p>,
      style: panelStyle,
    },
    {
      key: '4',
      label: 'Não Manifesto',
      children: <p>Teste</p>,
      style: panelStyle,
    },
  ];
  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: '#F5F5F5',
    borderRadius: '20px',
    border: 'none',
  };

  const [symptoms, setSymptons] = useState({
    anedonia: 30,
    humorDeprimido: 80,
    insonia: 50,
    perdaDeApetite: 70,
    autoDepreciacao: 657,
    preocupacoesSomaticas: 656,
    mudancaNaAutoImagem: 628,
    pessimismo: 506,
    pensamentosOuDesejosSuicidas: 467,
    perdaDePrazer: 389,
    sentimentoDeCulpa: 472,
    tristeza: 473,
    dificuldadeDeTrabalhar: 480,
    dificuldadeDeConcentracao: 414,
    irritabilidade: 413,
    fracassosPassados: 381,
    sentimentoDeInutilidade: 379,
    agitacao: 368,
    indecisao: 358,
    perdaDePeso: 335,
    cansacoOuFadiga: 318,
    perdaDeInteresseSexual: 310,
    alteracaoNoPadraoDeSono: 264,
    choro: 262,
    autoCriticismo: 229,
    alteracaoNoApetite: 205,
    sentimentoDePunicao: 169,
    perdaDeInteresse: 164,
    perdaDeEnergia: 67,
  });
  const [maxSliderValue, setMaxSliderValue] = useState(0);

  const camelCaseToNormal = (text: string, lowercaseWords: Set<string> = new Set()): string => {
    let result: string[] = [text[0].toUpperCase()];

    for (let i = 1; i < text.length; i++) {
      let char = text[i];
      if (char.toUpperCase() === char) {
        result.push(' ');
        result.push(char.toLowerCase());
      } else {
        result.push(char);
      }
    }

    let resultString = result.join('');
    let resultArray = resultString.split(' ');

    for (let i = 1; i < resultArray.length; i++) {
      let word = resultArray[i];
      if (!lowercaseWords.has(word.toLowerCase())) {
        resultArray[i] = word.charAt(0).toUpperCase() + word.slice(1);
      }
    }

    return resultArray.join(' ');
  };

  useEffect(() => {
    const maxValue = Object.values(symptoms).sort((a, b) => b - a)[0];
    setMaxSliderValue(maxValue);
  }, [symptoms]);

  const showDrawer = () => {
    setIsDrawerOpen(true);
  }

  const onClose = (emptyForm: () => void) => {
    emptyForm();
    setIsDrawerOpen(false);
  }

  return (
    <Layout.Container>
      <Flex style={{marginBottom:'15px'}} gap={isMobile ? '19px':'40px'} align='center'>
        <Button
          type="primary"
          size={isMobile ? 'middle' : 'large'}
          icon={<ArrowLeftOutlined  />}
          onClick={() => history.push(`/inkluzen/students/${id}/process`)}
        />
        <Title
          level={1}
          style={{
            color: colorPrimary,
            fontSize:isMobile ?'24px':'28px',
            fontWeight:700,
            margin:'0px'
          }}
        >
          Detalhamento do Processamento
        </Title>
      </Flex>
      <Flex style={{ width: '100%' }} gap={56}>
        <Flex style={{ width: 'calc(100% - 332px)' }} gap={48} vertical>
          <GenericCard width='95%' heigth='fit-content' >
            <Flex style={{ width: '100%' }} vertical>
              <h2 style={{
                fontWeight: 600,
                fontSize: '24px',
                color: colorPrimary,
              }}>Sintomas</h2>
              <Flex wrap="wrap" style={{ width: '100%', height: '100%' }} gap={40}>
                {Object.keys(symptoms).map((symptom, index) =>

                (
                  <Row>
                    <Col span={8}>
                      <Flex vertical style={{ width: '336px' }}>
                        <p style={{ fontWeight: 400, fontSize: '18px' }}>{camelCaseToNormal(symptom, new Set(["de", "na"]))}</p>
                        <Slider
                          style={{ width: '100%' }}
                          min={0}
                          max={maxSliderValue}
                          value={symptoms[symptom]}
                          disabled
                          tooltip={{ open: true }}
                        />
                      </Flex>
                    </Col>
                  </Row>
                )

                )}
                
              </Flex>
            </Flex>
          </GenericCard>
          <GenericCard width='95%' heigth='fit-content'>
            <Flex vertical>
              <Flex justify="space-between">
                <h2 style={{
                  fontWeight: 600,
                  fontSize: '24px',
                  color: colorPrimary,
                }}>Manifestações</h2>
                <Flex gap={16} align="center" justify="space-arround">
                  <p>31/02/2023</p>
                  <button style={{
                    border: `3px solid ${colorPrimary}`,
                    width: '170px',
                    height: '37px',
                    fontSize: '14px',
                    fontWeight: 600,
                    color: colorPrimary,
                    borderRadius: '10px',
                  }}><DownloadOutlined style={{ fontSize: '16px' }} /> TEXTO COMPLETO</button>
                </Flex>
              </Flex>
              <Flex>
                <Table<IManifestation>
                  itemName="manifestações"
                  data={manifestations.data ?? []}
                  onChangePage={setPage}
                  onChangePageSize={setPageSize}
                  total={manifestations.data?.length ?? 0}
                  page={page}
                  pageSize={pageSize}
                  columns={[
                    {
                      title: <p className="row" style={{ fontWeight: 400, fontSize: "24px" }}>Manifestação</p>,
                      dataIndex: 'manifestation',
                      key: 'manifestation',
                      render: (manifestation: string) => <p style={{ fontSize: "18px" }}>{manifestation}</p>,
                    },
                    {
                      title: <p style={{ fontWeight: 400, fontSize: "24px" }}>Alerta</p>,
                      dataIndex: 'alert',
                      key: 'alert',
                      render: (alert: number) => <p style={{ fontSize: "22px" }}>{alert}</p>,
                    },
                    {
                      title: <p style={{ fontWeight: 400, fontSize: "24px" }}>Sintomas</p>,
                      dataIndex: 'symptoms',
                      key: 'symptoms',
                      render: (symptoms: string) => <p style={{ fontSize: "18px" }}>{symptoms}</p>,
                    },
                  ]}
                />
              </Flex>
            </Flex>
          </GenericCard>
          <GenericCard maxWidth='95%'>
            <Flex style={{ width: '100%' }} vertical>
              <h2 style={{
                fontWeight: 600,
                fontSize: '24px',
                color: colorPrimary,
              }}>Relação entre Perguntas e Respostas</h2>
              <Flex gap={16} vertical>
                {questionsAnswers.map((question) => (
                  <QuestionAnswerCard
                    question={question.question}
                    answer={question.answer}
                  />
                )
                )}
              </Flex>
            </Flex>
          </GenericCard>
          <GenericCard maxWidth='95%'>
            <Flex style={{ width: '100%' }} vertical>
              <h2 style={{
                fontWeight: 600,
                fontSize: '24px',
                color: colorPrimary,
              }}>Contextualização da Classificação</h2>

              <Collapse
                accordion
                bordered={false}
                style={{ width: '100%', background: 'white' }}
                size="large"
                items={collapseItens(panelStyle)}
              />
            </Flex>
          </GenericCard>
        </Flex>
        <StudentInformation
          fixed={true}
          type="process"
          showDrawer={showDrawer}
        />
        {isDrawerOpen && <AnotationDrawer pageType="process" onClose={onClose} /> }
        
      </Flex>
    </Layout.Container>
  )
};

export default ProcessDetails;