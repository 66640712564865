import { useMediaQuery } from '@chakra-ui/media-query';
import { Col, Flex, Row } from 'antd';
import { MdCancel, MdCheckCircle } from 'react-icons/md';

function PasswordRequirements({ password }: { password: string }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  function containsCapitalLetter(str: string) {
    return /[A-Z]/.test(str);
  }
  function containsLowercaseLetter(str: string) {
    return /[a-z]/.test(str);
  }
  function containsNumber(str: string) {
    return /\d/.test(str);
  }
  function containsSpecialCharacter(str: string) {
    return /[!@#$%^&*()]/.test(str);
  }
  return (
    <Row>
      <Col span={12}>
        <Flex gap="4px" align="center">
          {password?.length >= 12 ? (
            <MdCheckCircle color="green" />
          ) : (
            <MdCancel color="red" />
          )}
          <p style={{ fontSize: isMobile ? '12px' : '14px' }}>
            Mínimo 12 caracteres
          </p>
        </Flex>
      </Col>
      <Col span={12}>
        <Flex gap="4px" align="center">
          {containsNumber(password) ? (
            <MdCheckCircle color="green" />
          ) : (
            <MdCancel color="red" />
          )}
          <p style={{ fontSize: isMobile ? '12px' : '14px' }}>Número</p>
        </Flex>
      </Col>
      <Col span={12}>
        <Flex gap="4px" align="center">
          {containsLowercaseLetter(password) ? (
            <MdCheckCircle color="green" />
          ) : (
            <MdCancel color="red" />
          )}
          <p style={{ fontSize: isMobile ? '12px' : '14px' }}>
            Letra minúscula
          </p>
        </Flex>
      </Col>
      <Col span={12}>
        <Flex gap="4px" align="center">
          {containsSpecialCharacter(password) ? (
            <MdCheckCircle color="green" />
          ) : (
            <MdCancel color="red" />
          )}
          <p style={{ fontSize: isMobile ? '12px' : '14px' }}>
            Caractere especial
          </p>
        </Flex>
      </Col>
      <Col span={12}>
        <Flex gap="4px" align="center">
          {containsCapitalLetter(password) ? (
            <MdCheckCircle color="green" />
          ) : (
            <MdCancel color="red" />
          )}
          <p style={{ fontSize: isMobile ? '12px' : '14px' }}>
            Letra maiúscula
          </p>
        </Flex>
      </Col>
    </Row>
  );
}

export default PasswordRequirements;
