import { useMediaQuery } from '@chakra-ui/react';
import { Form, Radio, Space } from 'antd';
import { t } from 'core/resources/strings';

function CategoryInput({
  index,
  updateForm,
}: {
  index: number;
  updateForm: (
    fieldName: string | number | (string | number)[],
    value: any
  ) => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: t('app.mensagemErroCategoriaASerAAnalizada'),
        },
      ]}
      name={[index, 'category']}
      label={t('app.categoriaASerAAnalizada')}
    >
      <Radio.Group
        id="id-tour-indicators-custom-panel-analysis-category-input"
        onChange={() => {
          updateForm(['graphs', index, 'graphView'], undefined);
        }}
      >
        <Space direction={isMobile ? 'vertical' : 'horizontal'}>
          <Radio value={'activity'}>{t('app.atividades')}</Radio>
          <Radio value={'avaliation'}>{t('app.avaliacoes')}</Radio>
          <Radio value={'selfAvaliation'}>{t('app.avaliacoesIniciais')}</Radio>
          <Radio value={'emotion'}>{t('app.emocionômetro')}</Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  );
}

export default CategoryInput;
