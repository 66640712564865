import { primary } from 'core/resources/theme/colors';
import styled from 'styled-components';

export const Header = styled.div`
  background-color: ${primary};
  text-align: center;
  color: white;
  padding: 12px 0;
  margin-bottom: 16px;
  font-size: 22px;
`;

export const ContainerButton = styled.div`
  @media print {
    display: none !important;
  }
  display:flex;
  width:100%;
  justify-content: space-between;

`;



export const SubTitle = styled.div<OptionPercentageType>`
  font-size: ${(props) => (props.isMobile ? '14px':'18px')};
  margin: 8px 0;
  color: #000000;
  font-weight: 400;
  font-family:'Poppins';
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

export const Option = styled.div`
  margin: 8px 0px;
  display: grid;
  gap:4px;
  grid-template-columns: 40px 1fr;
  grid-template-areas:
    'Percentage Percentage Percentage'
    'Number Description Percent';
`;

interface OptionNumberType {
  active?: boolean;
  isMobile?: boolean;
  color?: string;
}
interface OptionPercentageType {
  color?: string;
  percentage?: string;
  isMobile?: boolean;
}

export const OptionPercentage = styled.div<OptionPercentageType>`
  height: 10px;
  border-radius: 10px;
  background: #555;
  grid-area: Percentage;
  text-align: end;
  width: ${(props) => props?.percentage};
  background-color: ${(props) => (props.color)};
  margin-left: 20px;
  min-width: 8%;
`;
export const OptionPercent = styled.div<OptionPercentageType>`
  grid-area: Percent;
  text-align: end;
  border-radius:100px;
  height:fit-content;
  align-self:'center';
  color:black;
  font-size: ${(props) => (props.isMobile ? '12px':'16px')};
  background:${(props) => (props.color)};
  padding:4px;
  font-weight: 400;
`;

export const OptionNumber = styled.div<OptionNumberType>`
  width: ${(props) => (props.isMobile ? '30px' : '34px')};
  height: ${(props) => (props.isMobile ? '30px' : '34px')};
  font-size:${(props) => (props.isMobile ? '12px' : '18px')};
  border-radius: 50%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.color)};
  grid-area: Number;
  border: 2px solid white;
`;

export const OptionImage = styled.div<OptionNumberType>`
  img {
    display: table;
    margin: auto;
    width: 70px;
  }
`;

export const OptionDescription = styled.div<OptionNumberType>`
  display: flex;
  align-items: center;
  color: black;
  font-weight: 400;
  border-radius: 6px;
  font-size: ${(props) => (props.isMobile ? '14px' : '18px')};
  grid-area: Description;
`;

export const SubHeader = styled.div`
  background-color: ${primary};
  color: white;
  padding: 12px 16px;
  margin: 32px 0;
  font-size: 22px;
  text-transform: uppercase;
  text-align: center;
`;

export const PageBreak = styled.div`
  @media print {
    page-break-after: always;
    display: block;
  }

  @page {
    size: auto;
    margin: 5mm;
  }
  min-height: 850px;
  padding:16px;
`;





