import { useQuery } from "react-query";
import axios from "infra/http";
import { ProfileDetails } from "../typings";

export function useProfile(id?: string) {
  const fetchData = async (id: string): Promise<ProfileDetails> => {
    const { data } = await axios.get<ProfileDetails>(`/v1/profile/${id}`);

    return data;
  };

  return useQuery([`/v1/profile/${id}`], () => fetchData(id!), {
    enabled: !!id,
  });
}
