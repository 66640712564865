import { hex2rgba } from 'infra/helpers/hex2Rgba';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';

function Tag({
  text,
  type = 'filled',
  color = 'primary',
}: {
  text: string;
  type?: 'outline' | 'filled';
  color?: 'primary' | 'secondary';
}) {
  const { colorPrimary,colorSecondary } = useU4heroColors();
  return (
    <div
      style={{
        background: type === 'filled' ? hex2rgba(colorPrimary, 0.5) :'',
        fontSize: '12px',
        fontWeight: 700,
        color: color === 'primary' ? colorPrimary:colorSecondary,
        width: 'fit-content',
        padding: '4px',
        borderRadius: '5px',
        border:type === 'outline' ? `1px solid ${color === 'primary' ? colorPrimary:colorSecondary}` :''
      }}
    >
      {text}
    </div>
  );
}

export default Tag;
