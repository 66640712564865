import { Spinner, useMediaQuery } from '@chakra-ui/react';
import {
  Flex,
  Form,
  Modal,
} from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import { useQueryClient } from 'react-query';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import CustomizedRequiredMark from 'components/required-mark';
import ContactInfoSection from './ContactInfoSection';
import SchoolInfoSection from './SchoolInfoSection';
import AdressInfoSection from './AdressInfoSection';
import ColorsSection from './ColorsSection';
import { useSaveTenant } from 'core/features/tenants/hooks/useSaveTenant';
import { TenantForm } from 'core/features/tenants/typings';
import { useTenantV1 } from 'core/features/tenants/hooks/useTenantV1';

function ModalOrganization({
  onClose,
  id,
}: {
  id?: string;
  onClose: () => void;
}) {
  const { data, isLoading } = useTenantV1(id);
  const queryClient = useQueryClient();
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveTenant();
  const [form] = Form.useForm();
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const _handleSave = (values: TenantForm) => {
    handleSave({...values,id:id,master:true }, {
      onSuccess: () => {
        notificateSucess(
          `Organização ${id ? 'atualizada' : 'criada'} com sucesso!`
        );
        queryClient.invalidateQueries('/v1/tenant');
        onClose();
      },
      onError: (err: any) => {
        notificateError(
          `Houve um problema ao ${
            id ? 'editar' : 'criar'
          } uma organização. Verifique sua conexão e tente novamente.`
        );
      },
    });
  };


 

  return (
    <Modal
      title={<SubTitle primary>{id ? 'Editar' : 'Criar'} Organização</SubTitle>}
      open={true}
      onCancel={() => {
        if (form.isFieldsTouched()) {
          openNotification({ onClose, colorPrimary, isMobile });
        } else {
          onClose();
        }
      }}
      footer={null}
      width={isMobile ? '100%' : 806}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Form
        onFinish={(ev) => _handleSave(ev)}
        validateTrigger="onSubmit"
        layout="vertical"
        requiredMark={CustomizedRequiredMark}
        form={form}
        initialValues={data || undefined}
      >
        <Flex vertical gap="24px">
          <SchoolInfoSection />
          <ContactInfoSection tenantId={data ? data.id : undefined} />
          <AdressInfoSection defaultZipCode={data ? data.addressZipCode : undefined} />
          <ColorsSection />
          <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
            <Button
              variant="outline"
              size="md"
              disabled={isLoading || isLoadingSave}
              onClick={() => {
                if (form.isFieldsTouched()) {
                  openNotification({ onClose, colorPrimary, isMobile });
                } else {
                  onClose();
                }
              }}
              id="id-tour-institution-create-modal-button-cancel"
            >
              CANCELAR
            </Button>

            <Button
              htmlType="submit"
              size="md"
              loading={isLoading || isLoadingSave}
              id="id-tour-institution-create-modal-button-save"
            >
              SALVAR
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  );
}

export default ModalOrganization;
