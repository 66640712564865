function SelfAvaliationIcon({ size = 50 }: { size?: number }) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 96 96"
      width={size}
      height={size}
    >
      <path d="M55.3,79.49a1.11,1.11,0,0,1-.46-.11,1,1,0,0,1-.53-1l.93-10.28a1.21,1.21,0,0,1,.11-.37L86.76,7.12a4.9,4.9,0,0,1,9,.77,4.87,4.87,0,0,1-.32,3.74L64.05,72.26a1.13,1.13,0,0,1-.24.3L56,79.25A1,1,0,0,1,55.3,79.49Zm1.92-11-.7,7.64,5.84-5L93.67,10.71a2.84,2.84,0,0,0,.19-2.21A2.9,2.9,0,0,0,88.53,8Zm5.94,3.3h0Z" />
      <path d="M92.07,17a.91.91,0,0,1-.45-.11L84.7,13.27a1,1,0,1,1,.92-1.77l6.92,3.58A1,1,0,0,1,92.07,17Z" />
      <path d="M89.29,22.34a1,1,0,0,1-.46-.11l-6.92-3.58a1,1,0,0,1-.42-1.35,1,1,0,0,1,1.34-.43l6.92,3.58a1,1,0,0,1-.46,1.89Z" />
      <path d="M86.51,27.71a1,1,0,0,1-.46-.11L79.13,24a1,1,0,0,1-.42-1.35,1,1,0,0,1,1.34-.43L87,25.82a1,1,0,0,1-.46,1.89Z" />
      <path d="M63.41,72.3a.91.91,0,0,1-.45-.11L56,68.61A1,1,0,0,1,57,66.83l6.92,3.58a1,1,0,0,1-.47,1.89Z" />
      <path d="M76.08,92.71H10.32a1,1,0,0,1-1-1V6.18a1,1,0,0,1,1-1H76.08a1,1,0,0,1,1,1V29.91a1,1,0,0,1-2,0V7.18H11.32V90.71H75.08V46.85a1,1,0,0,1,2,0V91.71A1,1,0,0,1,76.08,92.71Z" />
      <path d="M10.32,21.76C6.83,21.76,4,19.55,4,16.82s2.83-4.93,6.32-4.93a1,1,0,0,1,0,2C8,13.89,6,15.23,6,16.82s2,2.94,4.32,2.94,4.31-1.34,4.31-2.94a1,1,0,1,1,2,0C16.63,19.55,13.8,21.76,10.32,21.76Z" />
      <path d="M10.32,37.82C6.83,37.82,4,35.61,4,32.88S6.83,28,10.32,28a1,1,0,0,1,0,2C8,30,6,31.29,6,32.88s2,2.94,4.32,2.94,4.31-1.34,4.31-2.94a1,1,0,0,1,2,0C16.63,35.61,13.8,37.82,10.32,37.82Z" />
      <path d="M10.32,53.88C6.83,53.88,4,51.67,4,48.94S6.83,44,10.32,44a1,1,0,0,1,0,2C8,46,6,47.35,6,48.94s2,2.94,4.32,2.94,4.31-1.35,4.31-2.94a1,1,0,1,1,2,0C16.63,51.67,13.8,53.88,10.32,53.88Z" />
      <path d="M10.32,69.94C6.83,69.94,4,67.72,4,65s2.83-4.94,6.32-4.94a1,1,0,0,1,0,2C8,62.06,6,63.41,6,65s2,2.94,4.32,2.94,4.31-1.35,4.31-2.94a1,1,0,1,1,2,0C16.63,67.72,13.8,69.94,10.32,69.94Z" />
      <path d="M10.32,86C6.83,86,4,83.78,4,81.06s2.83-4.94,6.32-4.94a1,1,0,0,1,0,2C8,78.12,6,79.47,6,81.06S8,84,10.32,84s4.31-1.35,4.31-2.94a1,1,0,1,1,2,0C16.63,83.78,13.8,86,10.32,86Z" />
      <path d="M66.91,20.4h-43a1,1,0,0,1,0-2H66.91a1,1,0,0,1,0,2Z" />
      <path d="M66.91,35.17h-43a1,1,0,0,1,0-2H66.91a1,1,0,0,1,0,2Z" />
      <path d="M66.22,49.94H23.86a1,1,0,0,1,0-2H66.22a1,1,0,0,1,0,2Z" />
      <path d="M58.57,64.72H23.86a1,1,0,0,1,0-2H58.57a1,1,0,0,1,0,2Z" />
      <path d="M55.3,79.49H23.86a1,1,0,0,1,0-2H55.3a1,1,0,0,1,0,2Z" />
    </svg>
  );
}

export default SelfAvaliationIcon;
