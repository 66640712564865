import { useQuery } from 'react-query';
import axios from 'infra/http';
import { SchoolAttentionPointsDetails } from '../typings';

export interface UseAttentionPointsSchoolParams {
  startDate?: string;
  endDate?: string;
}

function useAttentionPointsSchool(params: UseAttentionPointsSchoolParams) {
  const fetchData = async (
    params: UseAttentionPointsSchoolParams
  ): Promise<{ data: SchoolAttentionPointsDetails }> => {
    const { data } = await axios.get<SchoolAttentionPointsDetails>(
      `/v2/indicators/attention-school`,
      {
        params: {
          startDate: params.startDate,
          endDate: params.endDate,
        },
      }
    );

    return { data: data };
  };

  return useQuery(
    [
      `/v2/indicators/attention-school?startDate=${params.startDate}&endDate=${params.endDate}`,
      params,
    ],
    () => fetchData(params),
  );
}

export default useAttentionPointsSchool;
