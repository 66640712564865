function SortDownIcon({ size = 20 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="15" height="15" fill="url(#sortdown)" />
      <defs>
        <pattern
          id="sortdown"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#sortdown1" transform="scale(0.01)" />
        </pattern>
        <image
          id="sortdown1"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABOElEQVR4nO3avy4EYRTG4Zd+3QCXIqiFjkShlyg1blGQcCFcgZOMbKLQ6Hbt+WaeJznVVN/5zZ9mEgAAAAAAAAAAAAAAAAAAFmE/yXmShySPg87DzxnWZxnaUZL3JNNM5i3JYQa1vpueGyxx2vA8JdnLgE4aLG/a0hxnQPcNFjdtae4yoOsGi5u2NFcZ0EGSzwbLmzY8H0lWGdRlkmqwxGlDsz7LRQa3fnV9zSTGbWZi9Cg1pxijR6k5xhg1Ss05xmhRagkxRolSS4rRPUotMUbXKLXkGN2ilBh9opQYfZ6UEqPP66vE6PNNKTH6fOhLjD5RSow+UUqMPlFKjD5RSow+UUqMPlFKjD5RSow+UUqM/3fzR5Svn2vswFmS118xXpKcKrF7q5H/JgQAAAAAAAAAAAAAgAzlG2w3In/MlHdDAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
}

export default SortDownIcon;
