import http from "infra/http";
import { useMutation } from "react-query";
import { StoreItemForm } from "../typings";


export function useSaveStoreItem() {
  return useMutation(
    (data: StoreItemForm) => {
      if (data.id) {
        return http.put(`/v2/store-items/${data.id}`, data);
      }
      return http.post(`/v2/store-items`, data);
    },
    { retry: false }
  );
}
