import Text from 'components/typography/text';
import { Flex } from 'antd';
import { GoArrowUpRight } from 'react-icons/go';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@chakra-ui/react';

function TestLabel() {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Flex justify="space-around" style={{ background: colorPrimary , position:'sticky' ,top:0,zIndex:1000}}>
      <Text color="white">Modo de teste</Text>
      {!isMobile ? (
        <>
          {' '}
          <Text color="white">
            Você está usando dados de teste. Acesse sua conta no ambiente de
            produção para acessar dados reais.
          </Text>
          <a href={'https://smile.u4hero.com/'} target="_blank">
            <Flex gap="8px" align="center">
              <Text color="white">Produção</Text>
              <GoArrowUpRight color="white" size={18} />
            </Flex>
          </a>
        </>
      ) : null}
    </Flex>
  );
}

export default TestLabel;
