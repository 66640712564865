import useProcess from "core/features/inkluzen/hooks/useProcess";
import Layout from "components/layout";
import TimelineCard from "components/V2/timeline-card";
import { Flex, Spin } from "antd";
import { useHistory, useParams } from "react-router-dom";

interface RouteParams {
  id: string;
}

function StudentProcessTimeline() {
  const process = useProcess();
  const history = useHistory();
  const { id } = useParams<RouteParams>();

  return (
    <Layout.Container>
      <Flex gap={'32px'}>
        { process.data?
          <Flex style={{ cursor: 'pointer', width: '100%' }} gap={'14px'} vertical>
            {process?.data?.map((process) => <TimelineCard
              onClick={() => history.push(`/inkluzen/students/${id}/process/3812382`)}
              name={process.name}
              score={process.score}
              measure={process.measure}
              date={process.date}
            />)}
          </Flex>
          : <Spin size={'large'} />
        }
      </Flex>
    </Layout.Container>
  );
}

export default StudentProcessTimeline;