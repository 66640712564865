import { ActivityDetails } from "core/features/activity/types";
import {
  Box,
  Image,
  Flex,
  Badge,
  Skeleton,
  useMediaQuery,
  AspectRatio,
} from "@chakra-ui/react";
import useU4heroColors from "infra/packages/antd/u4heroTokens";
import { primary100, white } from "core/resources/theme/colors";
import moment from "moment";
import { t } from "core/resources/strings";

export function ActivityCard ({
  item,
  isLoaded,
  index,
  setCardSelect,
  cardSelect,
}: {
  item: ActivityDetails;
  isLoaded: boolean;
  index: number;
  cardSelect: string;
  setCardSelect: (item: string) => void;
}) {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { colorPrimary, colorSecondary } = useU4heroColors();

  return (
    <Skeleton isLoaded={isLoaded} width={isMobile ? "80%" : ""}>
      <Box
        maxW="sm"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        _hover={{ borderColor: colorPrimary }}
        borderColor={cardSelect === item.id ? colorPrimary : "none"}
        bg={cardSelect === item.id ? primary100 : "none"}
        onClick={() => setCardSelect(item.id)}
        cursor="pointer"
        id={`id-tour-report-activity-card-${index + 1}`}
      >
        <AspectRatio ratio={16 / 9}>
          <Image
            src={
              item?.game?.coverImageUrl
                ? item?.game?.coverImageUrl
                : item.typeId === 1
                ? "/avaliação-inicial.png"
                : "avaliação.png"
            }
            width="220px"
            height="130px"
          />
        </AspectRatio>

        <Box p="4">
          <Box display="flex" alignItems="baseline">
            {item.typeId === 3 ? (
              <Badge borderRadius="full" px="2" bg={colorSecondary} color="white">
                {item?.countStudents} {t('app.estudantesS')}
              </Badge>
            ) : (
              <Badge borderRadius="full" px="2" bg={colorPrimary} color="white">
                {item?.countClasses} {t('app.turmasS')}
              </Badge>
            )}
          </Box>
          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            noOfLines={1}
          >
            {item?.tag?.name}
          </Box>

          <Box fontSize="12px">
            {moment.utc(item?.publishDate).format("DD/MM/YYYY")} &bull;{" "}
            {moment.utc(item?.endDate).format("DD/MM/YYYY")}
          </Box>

          <Box display="flex" mt="2" alignItems="center">
            <Box as="span" color="gray.600" fontSize="sm">
              {item?.responsible?.name}
            </Box>
          </Box>
        </Box>
        {item?.progressOverall && (
            <Flex
              style={
                parseInt(item?.progressOverall) === 0
                  ? { backgroundColor: "#D9D9D9", color: white, borderRadius: '5px 5px 7px 7px' }
                  : parseInt(item?.progressOverall) === 100
                  ? { backgroundColor: colorPrimary, color: white, borderRadius: '5px 5px 7px 7px' }
                  : { backgroundColor: colorSecondary, color: white, borderRadius: '5px 5px 7px 7px' }
              }
              align={"center"}
              justify={"center"}
              width={"100%"}
              height={'30px'}
            >
              <p style={{fontSize: '12px', fontWeight: 600, textAlign:'center'}}>{parseInt(item?.progressOverall) === 0
                  ? t('app.naoIniciado')
                  : parseInt(item?.progressOverall) === 100
                  ? `${t('app.concluido')} - 100% `
                  : `${t('app.emAndamento')} - ${parseInt(item?.progressOverall)}% ` }</p>
            </Flex>
          )}
      </Box>
    </Skeleton>
  );
}
