import { Flex } from 'antd';
import Dot from 'components/dot';
import { t } from 'core/resources/strings';

const sizes = {
  sm: {
    padding: '4px 8px',
    fontSize: '12px',
    dot: 6,
  },
  md: {
    padding: '4px 8px',
    fontSize: '14px',
    dot: 8,
  },
  lg: {
    padding: '8px 8px',
    fontSize: '16px',
    dot: 10,
  },
};
const types = {
  active: {
    bg: '#CEEACF',
    color: '#336732',
    text: t('app.ativo').toUpperCase(),
  },
  inactive: {
    bg: '#FFCDD3',
    color: '#BB191E',
    text: t('app.inativo').toUpperCase(),
  },
  progress: {
    bg: '#B2EBFE',
    color: '#0067AA',
    text: t('app.emAndamento').toUpperCase(),
  },
  notStarted: {
    bg: '#FFE4A9',
    color: '#8A4C00',
    text: t('app.naoIniciada').toUpperCase(),
  },
  done: {
    bg: '#CEEACF',
    color: '#336732',
    text: t('app.concluido').toUpperCase(),
  },
  expired: {
    bg: '#FFCDD3',
    color: '#BB191E',
    text: t('app.expirada').toUpperCase(),
  },
  student: {
    bg: '#CEEACF',
    color: '#336732',
    text: t('app.estudante').toUpperCase(),
  },
  exStudent: {
    bg: '#FFCDD3',
    color: '#BB191E',
    text: t('app.exEstudante').toUpperCase(),
  },
};
function Status({
  type,
  size = 'lg',
}: {
  type:
    | 'active'
    | 'inactive'
    | 'progress'
    | 'notStarted'
    | 'done'
    | 'expired'
    | 'exStudent'
    | 'student';
  size?: 'sm' | 'md' | 'lg';
}) {
  return (
    <Flex
      align="center"
      style={{
        fontWeight: 'bold',
        background: types[type].bg,
        padding: sizes[size].padding,
        borderRadius: '4px',
        width: 'fit-content',
        gap: 8,
      }}
    >
      <Dot size={sizes[size].dot} color={types[type].color} />
      <p
        style={{
          fontSize: sizes[size].fontSize,
          fontWeight: 600,
          color: types[type].color,
        }}
      >
        {types[type].text}
      </p>
    </Flex>
  );
}

export default Status;
