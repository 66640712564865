import { useQuery } from 'react-query';
import axios from 'infra/http';
import { ClassDetails } from '../types';
import { useEffect, useState } from 'react';

export interface UseClassesParams {
  page: number;
  search?: string;
  series?: string[];
  tenantId?:string;
  disableConcat?: boolean;
  years?:string[];
  limit?:number;
}

function useClasses(params: UseClassesParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchClasses = async (
    params: UseClassesParams
  ): Promise<{ data: ClassDetails[]; total: number }> => {
    const { data, headers } = await axios.get<ClassDetails[]>(`/v2/class`, {
      params: {
        page: params.page,
        search: debouncedQuery,
        years: params.years,
        series: params.series,
        tenantId: params.tenantId,
        limit: params.limit
      },
    });
    return { data: data, total: Number(headers['total']) };
  };

  return useQuery(
    [
      `/v2/class`,
      {...params,search:debouncedQuery},
    ],
    () => fetchClasses(params),
    {
      keepPreviousData: true,
    }
  );
}

export default useClasses;
