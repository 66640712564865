import { Flex } from 'antd';
import SvgIcon from 'components/SvgIcon';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useAttentionPointsSchool from 'core/features/indicators/hooks/useAttentionPointsSchool';
import { t } from 'core/resources/strings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import moment from 'moment';
import {
  BsFillHandThumbsDownFill,
  BsFillHandThumbsUpFill,
} from 'react-icons/bs';
import { MdExpandMore } from 'react-icons/md';
import { Link } from 'react-scroll';

function AttentionPointsPreview() {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const { data } = useAttentionPointsSchool({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  });

  return (
    <GenericCard padding="8px" id="id-tour-section-attention-points-preview">
      <Flex vertical gap="8px" style={{ width: '100%' }}>
        <p
          style={{
            color: colorPrimary,
            fontSize: '18px',
            fontWeight: 700,
          }}
        >
          {t('app.previaPontosAtenção')}
        </p>
        <Flex vertical gap="8px">
          <Flex gap="8px">
            <p
              style={{
                color: 'black',
                fontSize: '16px',
                fontWeight: 600,
              }}
            >
              {t('app.areasMelhoria')}
            </p>
            <BsFillHandThumbsDownFill color="#B94143" size={24} />
          </Flex>
          <Flex justify="space-between" style={{ width: '100%' }}>
            <Flex vertical gap="4px" align="center">
              <p
                style={{
                  color: 'black',
                  fontSize: '14px',
                  fontWeight: 400,
                }}
              >
                {data?.data.competences[0]?.name['pt-br']}
              </p>
              <SvgIcon
                url={data?.data.competences[0]?.icon ?? ''}
                size={30}
                color="black"
              />
            </Flex>

            <Flex vertical gap="4px" align="center">
              <p
                style={{
                  color: 'black',
                  fontSize: '14px',
                  fontWeight: 400,
                }}
              >
                {data?.data.competences[1]?.name['pt-br']}
              </p>
              <SvgIcon
                url={data?.data.competences[1]?.icon ?? ''}
                size={30}
                color="black"
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex vertical gap="8px">
          <Flex gap="8px">
            <p
              style={{
                color: 'black',
                fontSize: '16px',
                fontWeight: 600,
              }}
            >
              {t('app.pontosFortes')}
            </p>
            <BsFillHandThumbsUpFill color="#77B943" size={24} />
          </Flex>
          <Flex justify="space-between" style={{ width: '100%' }}>
            <Flex vertical gap="4px" align="center">
              <p
                style={{
                  color: 'black',
                  fontSize: '14px',
                  fontWeight: 400,
                }}
              >
                {data?.data.competences[2]?.name['pt-br']}
              </p>
              <SvgIcon
                url={data?.data.competences[2]?.icon ?? ''}
                size={30}
                color="black"
              />
            </Flex>

            <Flex vertical gap="4px" align="center">
              <p
                style={{
                  color: 'black',
                  fontSize: '14px',
                  fontWeight: 400,
                }}
              >
                {data?.data.competences[3]?.name['pt-br']}
              </p>
              <SvgIcon
                url={data?.data.competences[3]?.icon ?? ''}
                size={30}
                color="black"
              />
            </Flex>
          </Flex>
        </Flex>

        <Link
          to="attentionPoints"
          smooth={true}
          duration={500}
          id="id-tour-button-go-to-attention-points"
        >
          <Flex justify="center">
            <p
              style={{
                color: colorGreyBorderAndFont,
                fontSize: '12px',
                fontWeight: 500,
              }}
            >
              {t('app.maisDetalhes')}
            </p>
            <MdExpandMore color={colorGreyBorderAndFont} size={15} />
          </Flex>
        </Link>
      </Flex>
    </GenericCard>
  );
}

export default AttentionPointsPreview;
