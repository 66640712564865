import { useMutation } from "react-query";
import http from "infra/http";

export function useValidate2FaPrivate() {
  return useMutation(
    (data:{code:string}) => {
      return http.post(`/v1/user/validate2FA`, data);
    },
    { retry: false }
  );
}
