import { useMediaQuery } from '@chakra-ui/media-query';
import { Modal, Flex } from 'antd';
import DynamicTable from 'components/V2/dinamic-table';
import useQuestion from 'core/features/questions/hooks/useQuestion';
import useQuestionFeedbacks, {
  IFeedback,
} from 'core/features/questions/hooks/useQuestionFeedbacks';
import { useState } from 'react';
import { PiEyeLight } from 'react-icons/pi';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { PiEyeClosedLight } from 'react-icons/pi';
import QuestionCard from '../questionCard';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import dayjs from 'dayjs';
import { FilterType } from 'infra/helpers/types';
import Button from 'components/buttons/button';
import FeedbackFilter from 'components/feedback-filter';
import { t } from 'core/resources/strings';

function QuestionsFeedbackModal({
  onClose,
  id,
}: {
  onClose: () => void;
  id: string;
}) {
  const { data } = useQuestion(id);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();

  const customExpandIcon = (props: any) => {
    if (props.expanded) {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <PiEyeLight style={{ fontSize: '22px' }} />
        </a>
      );
    } else {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <PiEyeClosedLight style={{ fontSize: '22px' }} />
        </a>
      );
    }
  };

  const ratingsMeaning: { [key: number]: string } = {
    0: t('app.ruim'),
    1: t('app.regular'),
    2: t('app.boa'),
  };
  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };

  const [filters, setFilters] = useState<FilterType>({
    initialDate: [],
    ratings: [],
  });

  const { data: feedbackData, isLoading } = useQuestionFeedbacks({
    id: id,
    page: page,
    ratings: filters?.ratings.map((item) => item.value) ?? undefined,
    initialDate: filters?.initialDate.map((item) => item.value)[0] ?? undefined,
  });

  const columns = [
    {
      title: <p style={tableHeaderStyle}>{t('app.categoria')}</p>,
      name: t('app.categoria'),
      dataIndex: 'category',
      key: 'category',
      render: (_: string, record: IFeedback) => (
        <p style={tableRowStyle}>{ratingsMeaning[record.rating]}</p>
      ),
      sorter: (a: any, b: any) => a.rating.localeCompare(b.rating),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.data')}</p>,
      name: t('app.data'),
      dataIndex: 'date',
      key: 'date',
      render: (_: string, record: IFeedback) => (
        <p style={tableRowStyle}>
          {dayjs(record.createdAt).format('DD/MM/YYYY')}
        </p>
      ),
      sorter: (a: any, b: any) => a.createdAt - b.createdAt,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.feedback')}</p>,
      name: t('app.feedback'),
      dataIndex: 'feedback',
      key: 'feedback',
      render: (_: string, record: IFeedback) => (
        <p style={tableRowStyle}>{record.feedback}</p>
      ),
    },
    // {
    //   title: <p style={tableHeaderStyle}>Feedback</p>,
    //   name: "expand",
    //   dataIndex: "expand",
    //   key: "expand",
    //   render: (_: string, record: IFeedback) => (<PiEyeLight style={{ fontSize: "22px" }} />),
    // },
  ];

  return (
    <Modal
      footer={[
        <Flex align="center" justify="center">
          <Button size="md" onClick={onClose}>
            {t('app.fechar')}
          </Button>
        </Flex>,
      ]}
      title={
        <p style={{ color: colorPrimary, fontWeight: 700, fontSize: '24px' }}>
          {t('app.feedback')}
        </p>
      }
      open={true}
      onCancel={onClose}
      width={isMobile ? '100%' : '666px'}
    >
      <Flex vertical gap={16}>
        <Flex>
          <QuestionCard question={data?.text ?? ''} />
        </Flex>
        <DynamicTable
          id="id-tour-my-school-selection-feedback-table"
          itemName={feedbackData && feedbackData?.total > 1 ? 'feedbacks' : 'feedback'}
          data={feedbackData?.data ?? []}
          page={page}
          onChangePage={setPage}
          isLoading={isLoading}
          allColumns={columns}
          defaultColumns={columns}
          hideCog={true}
          pageSize={pageSize}
          onChangePageSize={setPageSize}
          total={feedbackData?.total ?? 0}
          appliedFilters={filters}
          onChangeFilters={setFilters}
          filters={
            <FeedbackFilter filters={filters} onChangeFilters={setFilters} />
          }
          expandable={{
            expandedRowRender: (record: IFeedback) => (
              <p style={{ margin: 0, fontWeight: 600 }}>
                <span style={{ color: colorPrimary, fontWeight: 600 }}>
                  {t('app.feedback')}:{' '}
                </span>
                {record.feedback}
              </p>
            ),
            rowExpandable: (record: IFeedback) => record.feedback !== '',
            expandIcon: (props: any) => customExpandIcon(props),
          }}
        />
      </Flex>
    </Modal>
  );
}

export default QuestionsFeedbackModal;
