import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import Button from 'components/buttons/button';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { FiSettings } from 'react-icons/fi';
import { useHistory } from 'react-router';
import ToursTable from '../../ToursTable';

function ToursTab() {
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  return (
    <Flex vertical gap="16px">
      <Flex
        style={{
          alignSelf: 'end',
        }}
        gap='16px'
      >
        <Button
          size="md"
          id="id-tour-create-tour-initial-tour"
          variant="outline"
          style={{
            display: 'flex',
            gap: isMobile ? '4px' : '16px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => history.push('/tours/initial-tour')}
        >
          <FiSettings size={isMobile ? 16 : 28} color={colorPrimary} /> TOUR
          INICIAL
        </Button>
        <Button
          id="id-tour-create-tour-button-add-tour"
          size={'md'}
          onClick={() => history.push('/tours/new')}
        >
          CRIAR TOUR
        </Button>
      </Flex>

      <ToursTable />
    </Flex>
  );
}

export default ToursTab;
