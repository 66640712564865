import { hex2rgba } from 'infra/helpers/hex2Rgba';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import './style.css';

function LoadingDots() {
  const { colorPrimary } = useU4heroColors();
  return (
    <div className="col-3">
      <div className="snippet" data-title="dot-pulse">
        <div className="stage">
          <div
            className="dot-pulse"
            style={{
              backgroundColor: colorPrimary,
              color: hex2rgba(colorPrimary, 0.7),
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default LoadingDots;
