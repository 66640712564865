import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Modal } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import SubTitle from 'components/typography/subTitle';
import Button from 'components/buttons/button';
import lumi from 'assets/images/lumi-with-lock.png';
import Title from 'components/typography/title';

function ModalNewKey({
  onClose,
  license,
}: {
  license?: string;
  onClose: () => void;
}) {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Modal
      title={<SubTitle primary>Nova Chave</SubTitle>}
      open={true}
      onCancel={onClose}
      footer={[
        <Flex align="center" justify="center">
          <Button
            id="id-tour-modal-new-student-key-close-button"
            size="md"
            onClick={onClose}
          >
            Fechar
          </Button>
        </Flex>,
      ]}
      width={isMobile ? '100%' : 470}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Flex
        style={{
          backgroundImage: "url('/estrelas_1.png')",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: colorPrimary,
          borderRadius:'20px',
          height:'114px',
          position:'relative',paddingLeft:'36px'
        }}
        align='center'
      >
        <Title style={{color:'white'}}>{license}</Title>
        <img src={lumi} style={{position:'absolute',right:0,bottom:-10}}/>
      </Flex>
    </Modal>
  );
}

export default ModalNewKey;
