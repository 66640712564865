import {translate} from '../polyglot';

export default translate('en', {
  mensagens: {
    desativar: 'Do you really want to disable?',
    ativar: 'Do you really want to activate?',
    erro: 'There was a problem performing the operation.',
    sucesso: 'Operation performed successfully.',
    semPermissao: 'You do not have permission to use this feature!',
    campoObrigatorio: 'This field is required.',
    valorMinimo: 'The minimum value for this field is',
    trocarSenha: 'Change password?',
    introPDS: 'Fill in all the fields to access the graphs and reports.',
    bemVindoLogin: 'Olá, Ready to use or PDS?',
    recuperarSenhaSucesso:
      'An email was sent with password recovery instructions',
    usuarioNaoEncontrado: 'User not found',
    erroLogin: 'Error while logging in',
    testeDeHumor: 'Mood test?',
    cabecalhoRelatorio: `The socio-emotional reports are elaborated by specialists from observations obtained by the ludic bias.\n
    While the student has fun in the situations/problems of the game, he makes decisions, expresses himself and presents his perspective. This way, teachers can understand the students better through their choices and manage their emotions.\n
    The information collected in the games creates, throughout the school year, a database containing behavioral hypotheses for the faculty to obtain evaluations complementary quality for more assertive approaches. Thus, everyone can contribute to the good and socio-emotional progress of students and to a better learning and managing their emotions.`,


    selecioneCapitulo: 'Select the chapter',
    selecioneJogo: 'Select the game',
    informeEmail: 'Enter email'
  },
});
