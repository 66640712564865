import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useCurrentUser } from "core/features/user/store";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const refreshAuthLogic = (failedRequest: AxiosError) => {
  const { updateRefreshToken, refreshToken, updateToken, update } =
    useCurrentUser.getState();

  return axios
    .post(`${process.env.REACT_APP_BASE_URL_API}/v1/auth/refresh-token`, {
      refreshToken,
    })
    .catch(() => {
      updateRefreshToken(undefined);
      update(undefined);
      updateToken(undefined);
      window.location.reload();
    })
    .then((response: any) => {
      if (response.data) {
        updateToken(response.data.accessToken);
        updateRefreshToken(response.data.refreshToken);
        window.location.reload();
        if (failedRequest.response?.config?.headers) {
          failedRequest.response.config.headers["Authorization"] =
            "Bearer " + response.data.accessToken;
        }
      }
      return Promise.resolve();
    });
};

const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
});

createAuthRefreshInterceptor(http, refreshAuthLogic);

http.interceptors.request.use(
  async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const { token } = useCurrentUser.getState();

    if (token && config?.headers && !config.headers["authorization"]) {
      config.headers["authorization"] = `Bearer ${token}`;
    }

    if (config.headers) {
      config.headers["locale"] = "pt-br";
    }

    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

export default http;
