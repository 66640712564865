import { theme } from 'antd';
import { useCurrentUser } from 'core/features/user/store';

function useU4heroColors() {
    const { user } = useCurrentUser();
    const { useToken } = theme;
    const { token } = useToken();
    return {
        ...token,
        colorPrimaryMedium: user?.whiteLabel && user.colorPrimaryMedium ? user.colorPrimaryMedium : '#9A50E6',
        colorPrimaryLight: user?.whiteLabel && user.colorPrimaryLight ? user.colorPrimaryLight : '#EFE8F6',
        colorSecondary: user?.whiteLabel && user.colorSecondary? user.colorSecondary : '#ED810F',
        colorSecondaryMedium: user?.whiteLabel && user.colorSecondaryMedium ? user.colorSecondaryMedium : '#9A50E6',
        colorSecondaryLight: user?.whiteLabel && user.colorSecondaryLight ? user.colorSecondaryLight : '#EFE8F6',

        colorGreyCard: '#D9D9D9',
        colorHover:'#c4c4c4',
        colorGreyBorderAndFont:'#9D9D9D',
        colorGreen:'#336732',
        colorRed:'#BB191E'
    };
}

export default useU4heroColors;
