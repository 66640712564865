import http from "infra/http";
import { useMutation } from "react-query";
import { TagForm } from "../typings";

export function useSaveTag() {
  return useMutation(
    (data: TagForm) => {
      if (data.id) {
        return http.put(`/v1/tag/${data.id}`, data);
      }
      return http.post(`/v1/tag/`, data);
    },
    { retry: false }
  );
}
