import { t } from 'core/resources/strings';
import { FilterProps, storeItemTypes } from 'infra/helpers/types';
import GenericFilterComponent from 'components/generic-filter';

function StoreItemFilter({ filters, onChangeFilters }: FilterProps) {
  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: 'Classificação',
          filterName: 'classification',
          type: 'radio',
          options: [
            {
              label: 'Clássico',
              value: 'classic',
            },
            {
              label: 'Raro',
              value: 'rare',
            },
          ],
        },
        {
          title: 'Tipo',
          filterName: 'types',
          options: storeItemTypes,
        },
        {
          title: 'Status',
          filterName: 'status',
          options: [
            {
              label: 'Ativo',
              value: 1,
            },
            {
              label: 'Desativado',
              value: 0,
            },
          ],
          type: 'radio',
        },
      ]}
    />
  );
}

export default StoreItemFilter;
