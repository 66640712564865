import { useMediaQuery } from '@chakra-ui/react';
import { Dropdown, Menu } from 'antd';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import moment from 'moment';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import DynamicTable from 'components/V2/dinamic-table';
import { FaEllipsisVertical } from 'react-icons/fa6';
import Status from 'components/status';
import {
  notificateError,
  notificateSucess,
  showConfirm,
} from 'infra/helpers/notifications';
import { FilterType } from 'infra/helpers/types';
import useToggleHelper from 'core/features/helpers/hooks/useToggleHelper';
import { useDeleteHelper } from 'core/features/helpers/hooks/useDeleteHelper';
import useTours from 'core/features/tour/hooks/useTours';
import { TourDetails } from 'core/features/tour/typings';
import TourFilter from '../TourFilter';
import { useDeleteTour } from 'core/features/tour/hooks/useDeleteTour';
import useToggleTour from 'core/features/tour/hooks/useToggleTour';

function ToursTable() {
  const history = useHistory();
  const [check] = useCheckPermissions();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { mutate: handleToggle } = useToggleTour();
  const { mutate: handleDelete } = useDeleteTour();
  const { colorPrimary } = useU4heroColors();
  const [filters, setFilters] = useState<FilterType>({
    authors: [],
    status: [],
    publishDate: [],
  });

  const { data, isLoading, refetch } = useTours({
    page,
    search,
    limit: pageSize,
    publishDate: filters?.publishDate.map((item) => item.value)[0] ?? undefined,
    authors: filters.authors.map((item) => item.value) ?? undefined,
    active: filters.status?.length
      ? filters.status.map((item) => item.value)[0] === 1
      : undefined,
  });

  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };
  const columns = [
    {
      title: <p style={tableHeaderStyle}>Título</p>,
      name: 'Nome',
      dataIndex: 'name',
      key: 'name',
      mobile: true,
      render: (name: string, record: TourDetails) => (
        <p style={tableRowStyle}>{record.name}</p>
      ),
      sorter: (a: TourDetails, b: TourDetails) => a.name.localeCompare(b.name),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Autor</p>,
      name: 'Autor',
      dataIndex: 'author',
      key: 'author',
      mobile: true,
      render: (_: string, record: TourDetails) => (
        <p style={tableRowStyle}>{record.author}</p>
      ),
      sorter: (a: TourDetails, b: TourDetails) => a.author.localeCompare(b.author),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Data de criação</p>,
      key: 'createdAt',
      dataIndex: 'createdAt',
      name: 'Data de criação',
      render: (_: string, record: TourDetails) => (
        <p style={tableRowStyle}>
          {record.createdAt
            ? moment.utc(record.createdAt).format('DD/MM/YYYY')
            : '-'}
        </p>
      ),
      sorter: (a: TourDetails, b: TourDetails) =>
        a.createdAt.localeCompare(b.createdAt),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Data de edição</p>,
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      name: 'Data de edição',
      render: (_: string, record: TourDetails) => (
        <p style={tableRowStyle}>
          {record.updatedAt
            ? moment.utc(record.updatedAt).format('DD/MM/YYYY')
            : '-'}
        </p>
      ),
      sorter: (a: TourDetails, b: TourDetails) =>
        a.updatedAt.localeCompare(b.updatedAt),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Status</p>,
      dataIndex: 'active',
      key: 'active',
      name: 'Status',
      extra: true,
      render: (_: string, record: TourDetails) => (
        <Status type={record.active ? 'active' : 'inactive'} size="md" />
      ),
      sorter: (a: TourDetails, b: TourDetails) => (a.active ? 1 : -1),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Ações</p>,
      key: 'action',
      dataIndex: 'action',
      name: 'Ações',
      render: (_: string, record: TourDetails) => (
        <Dropdown
          overlay={
            <Menu
              id="id-tour-action-student-table"
              onClick={(e) => {
                if (e.key === 'toggle') {
                  handleToggle(
                    {
                      id: record.id,
                      isActive: record.active,
                    },
                    {
                      onSuccess: () => {
                        notificateSucess(
                          `O tour “${record.name}” foi ${
                            record.active ? 'desativado' : 'ativado'
                          } com sucesso!`
                        );
                        refetch();
                      },
                      onError: () => {
                        notificateSucess(
                          `Houve um problema ao ${
                            record.active ? 'desativar' : 'ativar'
                          } o tour “${
                            record.name
                          }” Verifique sua conexão e tente novamente.`
                        );
                      },
                    }
                  );
                }
                if (e.key === 'edit') {
                  history.push(`/tours/${record.id}`);
                }
                if (e.key === 'delete') {
                  showConfirm({
                    message: `Deseja realmente exluir o tour "${record.name}"?`,
                    colorPrimary,
                    isMobile,
                  }).then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      handleDelete(record.id, {
                        onSuccess: () => {
                          notificateSucess(
                            `O tour “${record.name}” foi excluído com sucesso!`
                          );
                          refetch();
                        },
                        onError: () => {
                          notificateError(
                            `Houve um problema ao excluir o tour “${record.name}”. Verifique sua conexão e tente novamente.`
                          );
                        },
                      });
                    }
                  });
                }
              }}
            >
             
              {check([Permissions.U4hero.Helpers.Edit]) ? (
                <Menu.Item id="edit-button" key={'edit'}>
                  Editar
                </Menu.Item>
              ) : null}
              {check([Permissions.U4hero.Helpers.Edit]) ? (
                <Menu.Item id="toggle-button" key={'toggle'}>
                  {record.active ? 'Desativar' : 'Ativar'}
                </Menu.Item>
              ) : null}
              {check([Permissions.U4hero.Helpers.Edit]) ? (
                <Menu.Item id="delete-button" key={'delete'}>
                  Deletar
                </Menu.Item>
              ) : null}
            </Menu>
          }
          trigger={['click']}
        >
          <FaEllipsisVertical
            id="id-tour-action-helpers-table"
            size={24}
            style={{ cursor: 'pointer' }}
          />
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    setPage(1);
  }, [search, filters]);

  return (
    <DynamicTable
      id="id-tour-tours-table"
      itemName={data && data?.total > 1 ? 'tours' : 'tour'}
      data={data?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title=""
      total={data?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isLoading}
      defaultColumns={
        isMobile ? columns.filter((column) => column.mobile) : columns
      }
      allColumns={columns}
      onSearch={setSearch}
      appliedFilters={filters}
      onChangeFilters={setFilters}
      filters={<TourFilter filters={filters} onChangeFilters={setFilters} />}
    />
  );
}

export default ToursTable;
