import { useTimeout } from '@chakra-ui/hooks';
import { Flex, Modal, Switch } from 'antd';
import Button from 'components/buttons/button';
import Title from 'components/typography/title';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import Text from 'components/typography/text';
import { useMediaQuery } from '@chakra-ui/react';

function ModalSelectArea({
  value,
  onChange,
  onClose,
  url,
  updateUrl,
}: {
  value?: string;
  onChange: (v: string) => void;
  onClose: () => void;
  url?: string;
  updateUrl: (url: string) => void;
}) {
  const { colorSecondary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [preventDefault, setPreventDefault] = useState(false);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [selected, setSelected] = useState(value);
  const [selectedUrl, setSelectedUrl] = useState(url);
  const baseUrl = window.location.origin;
  const [iframeUrl, setIframeUrl] = useState<string>();
  useEffect(() => {
    const handler = (ev: MessageEvent<{ type: string; message: any }>) => {
      let element: any;
      const newId = ev.data.message;
      //@ts-ignore
      const iframeWindow = document.getElementById('myIFrame').contentWindow;
      element = iframeWindow.document.getElementById(newId);
      const onClickElement = (e: Event) => {
        if (preventDefault) {
          e.stopPropagation();
          e.preventDefault();
        }
        //@ts-ignore
        const oldElement = (element =
          iframeWindow.document.getElementById(selected));
        if (selected && selected !== newId && element) {
          oldElement.style.border = '';
          oldElement.style.cursor = '';
          oldElement.removeEventListener('click', onClickElement);
        }
        setSelected(newId);
      };

      if (ev.data.type === 'leave' && element && newId !== selected) {
        element.style.border = '';
        element.style.cursor = '';
        element.removeEventListener('click', onClickElement);
      }

      if (ev.data.type === 'hover' && element && newId !== selected) {
        element.style.border = '5px dashed #BB191E';
        element.style.cursor = 'pointer';
        element.addEventListener('click', onClickElement);
      }
    };

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, [selected, preventDefault]);

//   useEffect(() => {
//     if (isIframeLoaded) {
//       setTimeout(() => {
//         //@ts-ignore
//         const iframeWindow = document.getElementById('myIFrame').contentWindow;
//         const style = iframeWindow.document.createElement('style');
//         style.textContent = `
//     * {
//       padding:8px;
//     }
// `;
//         iframeWindow.document.head.appendChild(style);
//       }, 2000);
//     }
//   }, [isIframeLoaded]);

  useEffect(() => {
    if (selected && isIframeLoaded && iframeUrl) {
      handleSelect(0);
    }
  }, [selected, isIframeLoaded, iframeUrl]);

  const handleSelect = (timeout: number) => {
    setTimeout(() => {
      //@ts-ignore
      const iframeWindow = document.getElementById('myIFrame').contentWindow;
      const element = iframeWindow.document.getElementById(selected);
      if (element) {
        element.style.border = `4px solid ${colorSecondary}`;
        setSelectedUrl(iframeWindow.location.pathname);
      }
    }, timeout);
  };

  useEffect(() => {
    if (url !== undefined && value) {
      setIframeUrl(`${baseUrl}${url}`);
      handleSelect(1000);
    } else {
      setIframeUrl(baseUrl);
    }
  }, []);

  return (
    <Modal
      open={true}
      title={
        <Flex justify="space-between">
          <Title primary>Selecionar Área</Title>{' '}
          <Flex style={{ marginRight: '30px' }} gap="16px">
            <Text size="lg">Bloquear Ações</Text>
            <Switch onChange={(v) => setPreventDefault(v)} />
          </Flex>
        </Flex>
      }
      width={isMobile ? '100%' : '80%'}
      onCancel={() => {
        setIsIframeLoaded(false);
        setIframeUrl(undefined);
        onClose();
      }}
      centered
      footer={null}
    >
      <iframe
        id="myIFrame"
        onLoad={() => setIsIframeLoaded(true)}
        src={iframeUrl}
        width="100%"
        style={{
          height: '700px',
        }}
      />
      <Flex justify="center" style={{ marginTop: '16px' }}>
        <Button
          size="md"
          disabled={selected === undefined}
          onClick={() => {
            onChange(selected as string);
            updateUrl(selectedUrl as string);
            onClose();
          }}
        >
          CONFIRMAR SELEÇÃO
        </Button>
      </Flex>
    </Modal>
  );
}

export default ModalSelectArea;
