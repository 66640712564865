import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Modal, message, Input, Row, Col, Checkbox } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import Button from 'components/buttons/button';
import { t } from 'core/resources/strings';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import empty from 'assets/images/lumi-empty.png';
import useStoreItems from 'core/features/store-items/hooks/useStoreItems';
import { useState } from 'react';

interface ModalRouteProps {
  onClose: () => void;
  onSave: (route: any) => void;
}

function ModalRoute({ onClose, onSave }: ModalRouteProps) {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data } = useStoreItems({
    page: 1,
    limit: 1000,
    types: ['character'],
  });

  const [selectedCharacters, setSelectedCharacters] = useState<string[]>([]);
  const [name, setName] = useState('');
  const handleCharacterSelect = (id: string, checked: boolean) => {
    if (checked) {
      setSelectedCharacters([...selectedCharacters, id]);
    } else {
      setSelectedCharacters(
        selectedCharacters.filter((charId) => charId !== id)
      );
    }
  };

  return (
    <Modal
      title={<SubTitle primary>Nova Rota</SubTitle>}
      open={true}
      onCancel={onClose}
      footer={
        <Flex
          vertical
          justify="center"
          align="center"
          gap={isMobile ? '12px' : '24px'}
        >
          <Button
            disabled={!name || !selectedCharacters.length}
            htmlType="submit"
            size="md"
            onClick={() => {
              onSave({ name, characters: selectedCharacters });
              onClose();
            }}
            id="id-tour-modal-button-save"
          >
            {t('app.salvar')}
          </Button>
          <Button
            variant="outline"
            size="md"
            onClick={onClose}
            id="id-tour-modal-button-save-and-create-new-route"
          >
            SALVAR E CRIAR NOVA ROTA
          </Button>
        </Flex>
      }
      width={isMobile ? '90%' : 640}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Text size="md">Nome da Rota: *</Text>
      <Input value={name} onChange={(e) => setName(e.target.value)} />
      <Text size="md">Personagens:</Text>
      <Row gutter={[16, 16]} style={{ maxHeight: '300px', overflowY: 'auto' }}>
        {data?.data?.map((item) => (
          <Col xs={12} md={8} key={item.id}>
            <Checkbox
              onChange={(e) => handleCharacterSelect(item.id, e.target.checked)}
              checked={selectedCharacters.includes(item.id)}
            >
              <img
                src={item.imageUrl}
                alt={item.name}
                style={{
                  width: '100%',
                  height: '150px',
                  objectFit: 'cover',
                  borderRadius: '10px',
                  backgroundColor: '#4891e0',
                }}
              />
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Modal>
  );
}

export default ModalRoute;
