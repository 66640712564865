import { Form, Collapse, Input } from 'antd';
import Text from 'components/typography/text';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useMediaQuery } from '@chakra-ui/react';
import CustomUpload from 'components/customUpload';

function InitialInfo() {
  const { colorPrimary } = useU4heroColors();
  const form = Form.useFormInstance();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <GenericCard>
      <Collapse
        ghost
        expandIconPosition="end"
        style={{ width: '100%' }}
        defaultActiveKey={'1'}
      >
        <Collapse.Panel
          key={'1'}
          className="remove-padding"
          header={
            <Text size="md" color={colorPrimary} bold style={{ width: '100%' }}>
              Informações Iniciais
            </Text>
          }
        >
          <Form.Item
            label="Título"
            rules={[
              {
                required: true,
                message: 'Por favor, crie um título para a publicação!',
              },
              {
                max: 30,
                message: 'O título pode ter no máximo 30 caracteres!',
              },
            ]}
            name="title"
          >
            <Input
              id="id-tour-create-practice-input-title"
              placeholder="Crie um título de até 30 caracteres"
              maxLength={30}
              size={isMobile ? 'middle' : 'large'}
            />
          </Form.Item>

          <Form.Item
            name="banner"
            label="Banner"
            rules={[
              {
                required: true,
                message: 'Por favor, carregue uma imagem para banner!',
              },
            ]}
          >
            {/* @ts-ignore */}
            <CustomUpload />
          </Form.Item>

          <Form.Item
            label="Pré-Requisitos"
            rules={[
              {
                required: true,
                message: 'Por favor, descreva os pré-requisitos!',
              },
            ]}
            name="preRequisites"
          >
            <Input.TextArea
              id="id-tour-create-practice-input-title"
              placeholder="Insira os pré-requisitos"
              size={isMobile ? 'middle' : 'large'}
              rows={2}
            />
          </Form.Item>

          <Form.Item
            label="Contexto Prévio"
            rules={[
              {
                required: true,
                message: 'Por favor, descreva o contexto!',
              },
            ]}
            name="previousContext"
          >
            <Input.TextArea
              id="id-tour-create-practice-input-title"
              placeholder="Descreva o contexto"
              size={isMobile ? 'middle' : 'large'}
              rows={2}
            />
          </Form.Item>
        </Collapse.Panel>
      </Collapse>
    </GenericCard>
  );
}

export default InitialInfo;
