import { useMediaQuery } from '@chakra-ui/react';
import { Tabs } from 'antd';
import Layout from 'components/layout';
import Title from 'components/typography/title';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import HelpersTab from './components/tabs/HelpersTab';
import HelperFeedbackTable from './components/tabs/FeedbacksTab';
import ToursTab from './components/tabs/ToursTab';
import { NumberParam, useQueryParam } from 'use-query-params';

function Helpers() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [step, setStep] = useQueryParam('tab', NumberParam);
  const { colorGreyBorderAndFont } = useU4heroColors();

  if (!step) {
    setStep(1);
  }

  const items = [
    {
      key: '1',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 1 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          AJUDAS
        </p>
      ),
      children: <HelpersTab />,
    },
    {
      key: '2',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 2 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          FEEDBACKS
        </p>
      ),
      children: <HelperFeedbackTable />,
    },
    {
      key: '3',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 3 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          TOUR
        </p>
      ),
      children: <ToursTab />,
    },
  ];

  return (
    <Layout.Container>
      <Title primary>Central de Ajuda</Title>

      <Tabs
        centered
        onTabClick={(event) => setStep(Number(event))}
        size="large"
        defaultActiveKey={step?.toString()}
        items={items}
      />
    </Layout.Container>
  );
}

export default Helpers;
