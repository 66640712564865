import {translate} from '../polyglot';

export default translate('pt-br', {
  mensagens: {
    desativar: 'Deseja realmente desativar?',
    ativar: 'Deseja realmente ativar?',
    erro: 'Houve um problema ao realizar a operação.',
    sucesso: 'Operação realizada com sucesso.',
    semPermissao: 'Você não possui permissão para usar este recurso!',
    campoObrigatorio: 'Este campo é obrigatório.',
    valorMinimo: 'O valor minímo para este campo é',
    trocarSenha: 'Trocar de senha?',
    introPDS:
      'Preencha todos os campos para ter acesso aos gráficos e relatórios.',
    bemVindoLogin: 'Olá, Preparado para usar o PDS ?',
    recuperarSenhaSucesso:
      'Foi enviado um e-mail com as instruções de recuperação de senha',
    usuarioNaoEncontrado: 'Usuário não encontrado',
    erroLogin: 'Erro ao realizar o login',
    testeDeHumor: 'Teste de humor?',
    cabecalhoRelatorio: `Os relatórios socioemocionais são elaborados por especialistas a partir de observações obtidas pelo viés lúdico.\n
    Enquanto o estudante se diverte nas situações/problema do jogo, ele toma decisões, expressa-se e apresenta sua perspectiva. Dessa forma, os educadores podem compreendê-lo melhor por meio de suas escolhas e comportamentos e, juntamente com uma equipe multidisciplinar dedicada, realizar as intervenções possíveis.\n
    As informações coletadas nos jogos criam, ao longo do ano letivo, uma base de dados contendo hipóteses comportamentais para que o corpo docente obtenha avaliações qualitativas complementares para abordagens mais assertivas. \n
    Assim, todos podem contribuir para o bem e progresso socioemocional dos estudantes e para um melhor aprendizado e gerenciamento de suas emoções.`,
    
    selecioneCapitulo: 'Selecione o capítulo',
    selecioneJogo: 'Selecione o jogo',
    informeEmail: 'Informe o email'
  },
});
