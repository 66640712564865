function GroupAvaliationIcon({ size = 50 }: { size?: number }) {
  return (
    <svg
      data-name="Layer 1"
      id="Layer_1"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <title />
      <path d="M473,400.15,425.76,381.2a27.11,27.11,0,0,0,14.58-23.94V313.65a27.19,27.19,0,0,0-27.16-27.16H388.06a27.19,27.19,0,0,0-27.16,27.16v43.61a27.08,27.08,0,0,0,13.43,23.31l-46.7,18.72L282.49,381.2a27.09,27.09,0,0,0,14.58-23.94V313.65a27.18,27.18,0,0,0-27.15-27.16H244.8a27.19,27.19,0,0,0-27.16,27.16v43.61a27.08,27.08,0,0,0,13.43,23.31l-46.7,18.72L139.23,381.2a27.11,27.11,0,0,0,14.58-23.94V313.65a27.19,27.19,0,0,0-27.16-27.16H101.54a27.19,27.19,0,0,0-27.17,27.16v43.61A27.1,27.1,0,0,0,87.8,380.57L39,400.15a5.76,5.76,0,0,0-3.61,5.35v59.41a5.76,5.76,0,0,0,11.51,0V409.38l58.9-23.61a18.89,18.89,0,0,1,6.93-1.34h.08a18.58,18.58,0,0,1,6.94,1.34l58.91,23.62v55.52a5.76,5.76,0,0,0,11.51,0V409.38L249,385.77a18.78,18.78,0,0,1,6.94-1.34H256a18.69,18.69,0,0,1,6.93,1.34l58.9,23.61v55.53a5.76,5.76,0,0,0,11.51,0V409.39l58.91-23.62a18.69,18.69,0,0,1,6.93-1.34h.08a18.69,18.69,0,0,1,6.93,1.34l58.91,23.61v55.53a5.76,5.76,0,0,0,11.51,0V405.5A5.76,5.76,0,0,0,473,400.15ZM85.89,313.65A15.67,15.67,0,0,1,101.54,298h25.11a15.67,15.67,0,0,1,15.65,15.65v43.61a15.67,15.67,0,0,1-15.65,15.66H101.54a15.67,15.67,0,0,1-15.65-15.66Zm143.26,0A15.67,15.67,0,0,1,244.8,298h25.12a15.66,15.66,0,0,1,15.64,15.65v43.61a15.67,15.67,0,0,1-15.64,15.66H244.8a15.67,15.67,0,0,1-15.65-15.66Zm143.26,0A15.67,15.67,0,0,1,388.06,298h25.12a15.67,15.67,0,0,1,15.65,15.65v43.61a15.67,15.67,0,0,1-15.65,15.66H388.06a15.67,15.67,0,0,1-15.65-15.66Z" />
      <path d="M476.8,41.34H35.2a5.75,5.75,0,0,0-5.76,5.75V320.53a5.76,5.76,0,0,0,11.51,0V52.85h430.1V320.53a5.76,5.76,0,0,0,11.51,0V47.09A5.75,5.75,0,0,0,476.8,41.34Z" />
      <path d="M151.91,236.62V277.7a5.76,5.76,0,0,0,11.51,0V240l50.32-28.77,37.4,58.69a5.77,5.77,0,0,0,9.72,0l37.41-58.69L348.59,240V277.7a5.76,5.76,0,0,0,11.51,0V236.62a5.76,5.76,0,0,0-2.9-5l-58-33.15a5.77,5.77,0,0,0-7.71,1.91L256,256.07l-35.5-55.69a5.78,5.78,0,0,0-7.71-1.91l-58,33.15A5.76,5.76,0,0,0,151.91,236.62Z" />
      <path d="M243.61,184.72h24.78a34.15,34.15,0,0,0,34.12-34.12V106.92A34.15,34.15,0,0,0,268.39,72.8H243.61a34.15,34.15,0,0,0-34.12,34.12V150.6A34.15,34.15,0,0,0,243.61,184.72ZM221,106.92a22.63,22.63,0,0,1,22.61-22.61h24.78A22.63,22.63,0,0,1,291,106.92V150.6a22.63,22.63,0,0,1-22.61,22.61H243.61A22.63,22.63,0,0,1,221,150.6Z" />
    </svg>
  );
}

export default GroupAvaliationIcon;
