import { Flex} from 'antd';
import Button from 'components/buttons/button';
import ModalOrganization from 'components/modals/modal-organization';
import { useState } from 'react';
import InstitutionsTable from '../../components/InstitutionsTable';

function OrganizationsTab() {
  const [isOpenModal,setIsOpenModal] = useState(false);
  const [id,setId] = useState<string>();
  return (
    <>
       {isOpenModal ? (
        <ModalOrganization
          id={id}
          onClose={() => {
            setId(undefined);
            setIsOpenModal(false);
          }}
        />
      ) : null}
      {' '}
      <Flex style={{ marginTop: '16px', marginBottom: '17px' }} justify="end">
        <Button
          id="id-tour-instituitions-button-add-instituition"
          size="md"
          onClick={() => setIsOpenModal(true)}
        >
          CRIAR ORGANIZAÇÃO
        </Button>
      </Flex>
      <InstitutionsTable organization={true} onEdit={(tenant)=> {
        setId(tenant.id);
        setIsOpenModal(true);
      }} />
    </>
  );
}

export default OrganizationsTab;
