import { useSavePracticeFeedback } from 'core/features/practices/hooks/useSavePracticeFeedback';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PracticeFeedbackForm } from 'core/features/practices/typings';
import { notificateError } from 'infra/helpers/notifications';
import Feedback from 'components/feedback';
import { t } from 'core/resources/strings';

function PracticeFeedback() {
  const params = useParams<{ id: string }>();
  const [submited, setSubmited] = useState(false);
  const { mutate: handleSave, isLoading } = useSavePracticeFeedback();

  const onSubmit = (values: PracticeFeedbackForm) => {
    handleSave(
      { ...values, practiceId: params.id },
      {
        onSuccess: () => {
          setSubmited(true);
        },
        onError: (err: any) => {
          notificateError(t('app.erroAoSalvarFeedback'));
        },
      }
    );
  };
  return (
    <Feedback
      onSubmit={onSubmit}
      askMessage={t('app.comoVoceAvaliaEssaPraticaPedagogica')}
      submited={submited}
      isLoadingSubmit={isLoading}
    />
  );
}

export default PracticeFeedback;
