import React from 'react';

interface DotProps extends React.HTMLAttributes<HTMLDivElement> {
  color: string;
  size?: number;
  pointer?: boolean;
}

const Dot: React.FC<DotProps> = ({
  color,
  size = 8,
  pointer = false,
  ...props
}) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        background: color,
        cursor: pointer ? 'pointer' : 'default',
      }}
      {...props}
    ></div>
  );
};

export default Dot;
