import { Select } from "antd";
import SelectBase, { SelectBaseProps } from "../select-base";

const { Option } = Select;

export interface SelectTypeGameProps<T = any> extends SelectBaseProps<T> {
  value: any;
  onChange: (v: any) => void;
}

function SelectTypeGame({ value, onChange, ...rest }: SelectTypeGameProps) {
  return (
    <SelectBase
      placeholder="Selecione o tema abordado no jogo"
      value={value}
      onChange={(ev) => {
        onChange(ev);
      }}
      {...rest}
    >
      <Option value={'socioEmocional'}>Socioemocional</Option>
      <Option value={'geografia'}>Geografia</Option>
      <Option value={'historia'}>História</Option>
      <Option value={'portugues'}>Português</Option>
      <Option value={'matematica'}>Matemática</Option>
    </SelectBase>
  );
}

export default SelectTypeGame;
