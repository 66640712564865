export interface IdebRecord {
  id: number; // Código IBGE ou INEP informado na consulta.
  tipo: string; // Tipo (ex: territorio)
  dependencia_id: number; // Código de Dependência Administrativa (0 - Total, 1 - Federal, 2 - Estadual, 3 - Municipal, 4 - Privada, 5 - Pública)
  dependencia:string;
  ciclo: string; // Identifica a etapa de ensino (ex: AI)
  ciclo_id: string; // Identifica a etapa de ensino (ex: AI)
  ano: number; // Ano que foi realizada a avaliação
  ideb: string; // Resultado do IDEB
  ideb_crescimento: string; // Quanto o IDEB cresceu desde a última avaliação
  aprendizado: string; // Nota do aprendizado (Valor de 0 a 10)
  nota_mt: string; // Nota do aprendizado em matemática
  nota_lp: string; // Nota do aprendizado em língua portuguesa
  ideb_projetado: string; // Nota projetada para o IDEB
  ideb_aumentou: boolean; // Valida se o IDEB teve aumento em relação à edição anterior
  aprovacao: string; // Taxa de aprovação
}

export interface AprendizadoAdequado {
  inep_id: number;
  ano: number;
  ciclo_id: string;
  lp_adequado: string;
  mt_adequado: string;
  lp_insuficiente: string;
  lp_basico: string;
  lp_proficiente: string;
  lp_avancado: string;
  mt_insuficiente: string;
  mt_basico: string;
  mt_proficiente: string;
  mt_avancado: string;
  matriculas: number;
  lp_insuficiente_alunos: number;
  lp_basico_alunos: number;
  lp_proficiente_alunos: number;
  lp_avancado_alunos: number;
  mt_insuficiente_alunos: number;
  mt_basico_alunos: number;
  mt_proficiente_alunos: number;
  mt_avancado_alunos: number;
}

export interface DistorcaoSerie {
  ano: number;
  inep_id: number;
  dependencia_id: number;
  localizacao_id: number;
  ef_1ano: string | null;
  ef_2ano: string | null;
  ef_3ano: string | null;
  ef_4ano: string | null;
  ef_5ano: string | null;
  ef_6ano: string | null;
  ef_7ano: string | null;
  ef_8ano: string | null;
  ef_9ano: string | null;
  ef_total_ai: string | null;
  ef_total_af: string | null;
  ef_total: string | null;
  em_1ano: string | null;
  em_2ano: string | null;
  em_3ano: string | null;
  em_4ano: string | null;
  em_total: string | null;
}
export interface RendimentoEscolar {
  inep_id: number;
  ano: number;
  dependencia_id: number;
  localizacao_id: number;
  serie_id: number;
  matriculas: number | null;
  aprovados: string | null;
  reprovados: string | null;
  abandonos: string | null;
}

export interface InfraestruturaEscolar {
  inep_id: number;
  ano: number;
  educacao_basica: number | null;
  acessibilidade_escola: number | null;
  acessibilidade_dependencias: number | null;
  acessibilidade_sanitario: number | null;
  alimentacao_fornecida: number | null;
  alimentacao_agua_filtrada: number | null;
  dependencias_sanitario_dentro_predio: number | null;
  dependencias_sanitario_fora_predio: number | null ;
  dependencias_biblioteca: number | null;
  dependencias_cozinha: number | null;
  dependencias_lab_informatica: number | null;
  dependencias_lab_ciencias: number | null;
  dependencias_sala_leitura: number | null;
  dependencias_quadra_esportes: number | null;
  dependencias_sala_diretora: number | null;
  dependencias_sala_professores: number | null;
  dependencias_sala_atendimento_especial: number | null;
  servicos_agua_rede_publica: number | null;
  servicos_agua_poco_artesiano: number | null;
  servicos_agua_cacimba: number | null;
  servicos_agua_fonte_rio: number | null;
  servicos_agua_inexistente: number | null;
  servicos_energia_rede_publica: number | null;
  servicos_energia_gerador: number | null;
  servicos_energia_outros: number | null;
  servicos_energia_inexistente: number | null;
  servicos_esgoto_rede_publica: number | null;
  servicos_esgoto_fossa: number | null;
  servicos_esgoto_inexistente: number | null;
  servicos_lixo_coleta_periodica: number | null;
  servicos_lixo_queima: number | null;
  servicos_lixo_joga_outra_area: number | null;
  servicos_lixo_recicla: number | null;
  servicos_lixo_enterra: number | null;
  servicos_lixo_outros: number | null;
  tecnologia_internet: number | null;
  tecnologia_banda_larga: number | null;
  tecnologia_computadores_alunos: number | null;
  tecnologia_computadores_administrativos: number | null ;
  equipamento_dvd: number | null;
  equipamento_impressora: number | null;
  equipamento_parabolica: number | null;
  equipamento_copiadora: number | null;
  equipamento_retroprojetor: number | null;
  equipamento_tv: number | null;
  outros_num_funcionarios: number | null;
  outros_organizacao_ciclos: number | null;
  matriculas_creche: number | null;
  matriculas_pre_escolar: number | null;
  matriculas_anos_iniciais: number | null;
  matriculas_anos_finais: number | null;
  matriculas_ensino_medio: number | null;
  matriculas_eja: number | null;
  matriculas_educacao_especial: number | null;
  matriculas_1ano: number | null;
  matriculas_2ano: number | null;
  matriculas_3ano: number | null;
  matriculas_4ano: number | null;
  matriculas_5ano: number | null;
  matriculas_6ano: number | null;
  matriculas_7ano: number | null;
  matriculas_8ano: number | null;
  matriculas_9ano: number | null;
  matriculas_em_1ano: number | null;
  matriculas_em_2ano: number | null;
  matriculas_em_3ano: number | null;
  matriculas_em_outros: number | null;
}

export const baseUrlQedu = 'https://api.qedu.org.br/v1';

export interface QeduParams {
  inep_id?: number;
  ano?: number;
}