import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import Layout from 'components/layout';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useCurrentUser } from 'core/features/user/store';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import { StudentItem } from 'core/features/students/typings';
import ModalStudent from 'components/modals/modal-student';
import ModalImportStudent from 'components/modals/modal-import-student';
import { BiImport } from 'react-icons/bi';
import StudentsTable from './components/StudentsTable';
import BackButton from 'components/buttons/back-button';
import Button from 'components/buttons/button';
import Title from 'components/typography/title';
import InstitutionHeader from 'components/institution-header';
import HeaderImage from 'assets/images/students-header-image.png';
import { useTenant } from 'core/features/tenants/hooks/useTenant';
import ModalNewKey from 'components/modals/modal-new-key';
import { useNewStudentKey } from 'core/features/students/hooks/useNewStudentKey';
import { useQueryClient } from 'react-query';
import DownloadStudentKeys, {
  StudentBasic,
} from '../../../../../components/DownloadStudentKeys';
import { t } from 'core/resources/strings';
function Students() {
  const history = useHistory();
  const [check] = useCheckPermissions();
  if (
    !check([
      Permissions.Students.View,
      Permissions.U4hero.Tenant.Edit,
      Permissions.Org.Tenant.Edit,
    ])
  ) {
    history.push('/errors/no-permission');
  }
  const [itemEdit, setItemEdit] = useState<StudentItem | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalNewKey, setIsOpenModalNewKey] = useState(false);
  const [isOpenModalImport, setIsOpenModalImport] = useState(false);
  const [license, setLicense] = useState('Carregando');
  const queryClient = useQueryClient();

  const { mutate: handleNewKey } = useNewStudentKey();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  let location = useLocation();
  const { tenantId } = useParams<{ tenantId: string }>();
  const { data: tenant } = useTenant(tenantId);
  const { user } = useCurrentUser();
  const { colorPrimary } = useU4heroColors();
  const [students, setStudents] = useState<StudentBasic[]>([]);
  const isAllowed = check([
    Permissions.U4hero.Students.Import,
    Permissions.Org.Students.Import,
    Permissions.Students.Import,
  ]);

  useEffect(() => {
    if (
      location.search.includes('new') &&
      check([Permissions.Students.Create])
    ) {
      setIsOpenModal(true);
    }
  }, [location]);

  return (
    <Layout.Container>
      {isOpenModal ? (
        <ModalStudent
          studentId={itemEdit?.id}
          userId={itemEdit?.userId}
          tenantId={tenantId}
          onClose={() => {
            setItemEdit(null);
            setIsOpenModal(false);
          }}
        />
      ) : null}
      {isOpenModalImport ? (
        <ModalImportStudent
          tenantId={tenantId ?? user?.tenantId}
          onClose={() => {
            setIsOpenModalImport(false);
          }}
        />
      ) : null}

      {isOpenModalNewKey ? (
        <ModalNewKey
          license={license}
          onClose={() => {
            setIsOpenModalNewKey(false);
          }}
        />
      ) : null}
      <Flex gap={isMobile ? '12px' : '24px'} align="center">
        <BackButton
          onClick={() => {
            if (tenant) {
              history.push(`/schools?tab=${tenant?.master ? 1 : 2}`);
            } else {
              history.push('/my-school');
            }
          }}
        />
        <Title primary id="students-title">
          {t('app.Estudantes')}
        </Title>
      </Flex>

      {tenant ? (
        <InstitutionHeader image={HeaderImage} tenantName={tenant?.name} />
      ) : null}
      <Flex
        gap="24px"
        justify="end"
        style={{ marginBottom: isMobile ? '8px' : '16px' }}
      >
        {students.length > 0 && <DownloadStudentKeys students={students} />}

        {isAllowed ? (
          <Button
            onClick={() => setIsOpenModalImport(true)}
            size="md"
            id="id-tour-my-school-button-import-student"
            variant="outline"
            style={{
              display: 'flex',
              gap: isMobile ? '4px' : '16px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BiImport size={isMobile ? 22 : 28} color={colorPrimary} />{' '}
            {t('app.importar')}
          </Button>
        ) : null}

        {check([
          Permissions.Students.Create,
          Permissions.U4hero.Students.Create,
          Permissions.Org.Students.Create,
        ]) ? (
          <Button
            size="md"
            onClick={() => setIsOpenModal(true)}
            id="id-tour-my-school-button-add-student"
          >
            {t('app.criar')} {t('app.estudante')}
          </Button>
        ) : null}
      </Flex>

      <StudentsTable
        onEdit={(v) => {
          setItemEdit(v);
          setIsOpenModal(true);
        }}
        onCreateNewKey={(v) => {
          setIsOpenModalNewKey(true);
          handleNewKey(v.userId, {
            onSuccess: (data) => {
              setLicense(data.data);
              queryClient.invalidateQueries('/v2/student');
            },
          });
        }}
        onSelect={(rows) => {}}
        callback={(rows) => {
          if (rows.length) {
            setStudents(
              rows.map((student) => ({
                id: student.id,
                name: student.name,
                birthday: student.birthday,
                license: student.license,
                tenantName: student.school.name,
                classDetails: { ...student.class, serie: student.serie.name },
              }))
            );
          } else {
            setStudents([]);
          }
        }}
      />
    </Layout.Container>
  );
}

export default Students;
