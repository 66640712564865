import { useState } from 'react';
import {
  Menu,
  Dropdown,
  Checkbox,
  Flex,
  CollapseProps,
  Collapse,
  Divider,
  Input,
  Radio,
  Space,
  Switch,
} from 'antd';
import { MdExpandMore } from 'react-icons/md';
import { FilterOutlined } from '@ant-design/icons';
import './style.css';
import { useMediaQuery } from '@chakra-ui/media-query';
import Button from 'components/buttons/button';

interface FilterOption {
  label: string;
  value: any;
}

interface FilterGroup {
  title: string;
  filterName: string;
  type?: 'number' | 'radio' | 'date' | 'switch' | 'range';
  open?: boolean;
  options: FilterOption[];
  children?: {
    title: string;
    options: FilterOption[];
  }[];
}

interface FilterComponentProps {
  filters: {
    [key: string]: FilterOption[];
  };
  onChangeFilters: (value: any) => void;
  filterGroups: FilterGroup[];
}

function GenericFilterComponent({
  filters,
  onChangeFilters,
  filterGroups,
}: FilterComponentProps) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const toggleFilter = (key: string, field: FilterOption) => {
    const currentFilter = filters[key];
    const group = filterGroups.find((item) => item.filterName === key);
    let updatedFilter;

    if (currentFilter.map((item) => item.value).includes(field.value)) {
      // If the field value is already in the current filter, remove it
      updatedFilter = currentFilter.filter(
        (item) => item.value !== field.value
      );
    } else {
      // If the field value is not in the current filter, add it based on the group type
      if (group?.type === 'date') {
        // For date type, add only if the field value is truthy
        updatedFilter = field.value ? [field] : [];
      } else if (group?.type === 'radio') {
        // For radio type, replace the filter with the field
        updatedFilter = [field];
      } else if (group?.type === 'switch') {
        // For switch type, add only if the field value is truthy
        updatedFilter = field.value ? [field] : [];
      } else if (group?.type === 'range') {
        const current = currentFilter.find(
          (item) => item.label === field.label
        );
        if (!current) {
          updatedFilter = [...currentFilter, field];
        } else {
          if (field.value === undefined) {
            updatedFilter = currentFilter.filter(
              (item) => item.label !== field.label
            );
          } else {
            updatedFilter = currentFilter.map((item) => {
              if (item.label === field.label) {
                return {
                  label: item.label,
                  value: field.value,
                };
              } else {
                return item;
              }
            });
          }
        }
      } else {
        // For other types, add the field to the current filter
        updatedFilter = [...currentFilter, field];
      }
    }

    // Update the filters state with the new filter for the specified key
    onChangeFilters({
      ...filters,
      [key]: updatedFilter,
    });
  };

  const handleOkClick = () => {
    setDropdownVisible(false);
  };

  const clear = () => {
    const clearedFilters: { [key: string]: FilterOption[] } = {};
    filterGroups?.forEach((group) => {
      if (filters.hasOwnProperty(group.filterName)) {
        clearedFilters[group.filterName] = [];
      }
    });
    onChangeFilters(clearedFilters);
  };

  const renderChildren = (group: FilterGroup) => {
    if (group.type === 'range') {
      return (
        <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Flex gap="2px">
            <Input
              onChange={(e) => {
                toggleFilter(group.filterName, {
                  label: group.options[0].label,
                  value: !e.target.value ? undefined : e.target.value,
                });
              }}
              value={filters[group.filterName].length > 0 ? filters[group.filterName][0].value : undefined}
              style={{ width: '120px', fontSize: '12px' }}
              placeholder={group.options[0].label || ''}
            />
            -
            <Input
              onChange={(e) => {
                toggleFilter(group.filterName, {
                  label: group.options[1].label,
                  value:  !e.target.value ? undefined : e.target.value,
                });
              }}
              value={filters[group.filterName].length > 1 ? filters[group.filterName][1].value : undefined}
              style={{ width: '120px', fontSize: '12px' }}
              placeholder={group.options[1].label || ''}
            />
          </Flex>
        </div>
      );
    }
    if (group.children) {
      return (
        <Collapse
          style={{ paddingLeft: '8px' }}
          ghost={true}
          expandIconPosition="end"
        >
          {group.children.map((childGroup) => (
            <Collapse.Panel
              header={
                <p
                  style={{
                    fontSize: isMobile ? '14px' : '18px',
                    fontWeight: 500,
                  }}
                >
                  {childGroup.title}
                </p>
              }
              key={childGroup.title}
            >
              <Menu
                onClick={(e) => {
                  const option = childGroup.options.find(
                    (opt) => opt.value === e.key
                  );
                  toggleFilter(group.filterName, option!);
                }}
              >
                {(childGroup.options ?? []).map((option) => (
                  <Menu.Item key={option.value}>
                    <Checkbox
                      checked={filters[group.filterName]
                        ?.map((item) => item.value)
                        .includes(option.value)}
                    >
                      <label>{option.label}</label>
                    </Checkbox>
                  </Menu.Item>
                ))}
              </Menu>
            </Collapse.Panel>
          ))}
        </Collapse>
      );
    }
    if (group.type === 'date') {
      return (
        <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Input
            value={filters[group.filterName]?.[0]?.value || undefined}
            onChange={(e) => {
              toggleFilter(group.filterName, {
                label: group.title,
                value: e.target.value,
              });
            }}
            type="date"
          />
        </div>
      );
    }
    if (group.type === 'radio') {
      return (
        <Menu>
          <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
            <Radio.Group
              onChange={(e) => {
                const option = group.options?.find(
                  (item) => item.value === e.target.value
                );
                toggleFilter(group.filterName, option!);
              }}
              value={filters[group.filterName]?.[0]?.value}
            >
              <Space direction="vertical">
                {group.options.map((option) => (
                  <Radio key={option.value} value={option.value}>
                    {option.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </div>
        </Menu>
      );
    }

    return (
      <Menu
        onClick={(e) => {
          const key =
            group.type && group.type === 'number' ? Number(e.key) : e.key;
          const option = group.options.find((item) => item.value === key);
          toggleFilter(group.filterName, option!);
        }}
      >
        {(group.options ?? []).map((option) => (
          <Menu.Item key={option.value}>
            <Checkbox
              checked={filters[group.filterName]
                ?.map((item) => item.value)
                .includes(option.value)}
            >
              <label>{option.label}</label>
            </Checkbox>
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  const items: CollapseProps['items'] = (filterGroups ?? [])
    .filter(
      (group) =>
        filters.hasOwnProperty(group.filterName) && group?.type !== 'switch'
    )
    .map((group, index) => ({
      key: index.toString(),
      label: (
        <p style={{ fontSize: isMobile ? '14px' : '18px', fontWeight: 500 }}>
          {group.title}
        </p>
      ),
      children: renderChildren(group),
    }));

  const openFilterIndices = filterGroups
    .filter((group) => filters.hasOwnProperty(group.filterName))
    .reduce((accumulator, group, index) => {
      if (group.open) {
        accumulator.push(index.toString());
      }
      return accumulator;
    }, [] as string[]);
  return (
    <Dropdown
      overlay={
        <Menu
          style={{
            maxHeight: isMobile ? '384px' : '500px',
            overflowY: 'auto',
            minWidth: '245px',
          }}
        >
          <Collapse
            ghost={true}
            items={items}
            defaultActiveKey={
              openFilterIndices.length ? openFilterIndices : undefined
            }
            expandIconPosition="end"
          />
          {filterGroups
            .filter(
              (group) =>
                filters.hasOwnProperty(group.filterName) &&
                group?.type === 'switch'
            )
            .map((item) => (
              <Flex
                gap="8px"
                key={item.title}
                style={{ paddingLeft: '16px', paddingRight: '' }}
              >
                <p
                  style={{
                    fontSize: isMobile ? '14px' : '18px',
                    fontWeight: 500,
                  }}
                >
                  {item.title}
                </p>
                <Switch
                  checked={filters[item.filterName]?.[0]?.value}
                  onChange={(e) => {
                    toggleFilter(item.filterName, {
                      label: item.title,
                      value: e,
                    });
                  }}
                />
              </Flex>
            ))}
          <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
          <Flex
            justify="space-between"
            style={{ paddingLeft: '16px', paddingRight: '24px' }}
          >
            <Button
              disabled={
                !Object.entries(filters).some(([_, items]) => items?.length > 0)
              }
              onClick={clear}
              size='sm'
              type='default'
            >
              LIMPAR
            </Button>
            <Button size='md' onClick={handleOkClick}>
              OK
            </Button>
          </Flex>
        </Menu>
      }
      trigger={['click']}
      open={dropdownVisible}
      onOpenChange={(visible) => setDropdownVisible(visible)}
      placement="bottom"
    >
      <Flex
        gap="8px"
        align="center"
        style={{
          background: '#F5F5F5',
          padding: '8px',
          borderRadius: '10px',
          cursor: 'pointer',
        }}
        id="id-tour-filter"
      >
        <FilterOutlined
          style={{
            color: 'black',
            fontSize: '26px',
          }}
        />
        <MdExpandMore
          style={{
            transform: dropdownVisible ? undefined :'rotate(270deg)',
          }}
          color="black"
          size={16}
        />
      </Flex>
    </Dropdown>
  );
}

export default GenericFilterComponent;
