import { useMediaQuery } from "@chakra-ui/react";

function Container({ children }: { children: any }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <div style={{width:'100%',paddingLeft:isMobile ? '18px':'116px',paddingRight:isMobile ? '18px':'54px'}} >
      {children}
    </div>
  );
}

export default Container;
