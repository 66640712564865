import { notificateError } from 'infra/helpers/notifications';
import { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: 'always',
      onError(err: any) {
        notificateError();
      },
    },
    mutations: {
      onError(err: any) {
        try {
          if (typeof err.response.message === 'string') {
            notificateError(err.response.message);
          } else {
            if (err.status === 502) {
              notificateError(
                'Oops! Algo deu errado do nosso lado. Por favor, aguarde um momento e tente novamente.'
              );
            }
            if (err.status === 504) {
              notificateError(
                'Oops, nosso servidor não respondeu a tempo.Tente novamente!'
              );
            }
          }
        } catch {
          notificateError();
        }
      },
    },
  },
});

export const ReactQueryProvider: FC = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
