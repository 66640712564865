import React, { useRef } from 'react';
import Layout from 'components/layout';
import useProcess from 'core/features/inkluzen/hooks/useProcess';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Bar, getElementAtEvent, getElementsAtEvent } from 'react-chartjs-2';
import dayjs from 'dayjs';
import useAnnotations from 'core/features/inkluzen/hooks/useAnnotations';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Chart,
} from 'chart.js';
import { Flex, Spin } from 'antd';

// Register the required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface RouteParams {
  id: string;
}

function StudentProcessGraph() {
  const process = useProcess();
  const history = useHistory();
  const { id } = useParams<RouteParams>();
  const annotation = useAnnotations(id);
  const [labels, setLabels] = useState<string[] | undefined>([]);
  const chartRef = useRef<Chart | null>(null);
  const [hoveredBar, setHoveredBar] = useState(null);

  useEffect(() => {
    const labelString = process.data?.map((process) => {
      const date = dayjs(process.date);
      const formatedDate = date.format('DD/MM/YYYY');
      return formatedDate;
    });
    setLabels(labelString);
  }, [process.data]);

  const data = {
    labels,
    datasets: [
      {
        label: 'Processamento de Significado',
        data: labels?.map((label) => {
          const processData = process.data?.find(
            (p) =>
              p.name === 'Processamento de Significado' &&
              dayjs(p.date).format('DD/MM/YYYY') === label
          );
          return processData
            ? { x: label, y: processData.score }
            : { x: label, y: 0 };
        }),
        backgroundColor: '#D5C7EA',
        borderColor: '#7743B9',
        borderWidth: 2,
        borderRadius: 4,
        barThickness: 40,
        datalabels: {
          anchor: 'end',
          align: 'top',
          formatter: (value: any) => value.y,
        },
      },
      {
        label: 'Processamento de Contexto',
        data: labels?.map((label) => {
          const processData = process.data?.find(
            (p) =>
              p.name === 'Processamento de Contexto' &&
              dayjs(p.date).format('DD/MM/YYYY') === label
          );
          return processData
            ? { x: label, y: processData.score }
            : { x: label, y: 0 };
        }),
        backgroundColor: '#EFC3E1',
        borderColor: '#B94386',
        borderWidth: 2,
        borderRadius: 4,
        barThickness: 40,
        datalabels: {
          anchor: 'end',
          align: 'top',
          formatter: (value: any) => value.y,
        },
      },
      {
        label: 'Anotação',
        data: labels?.map((label) => {
          const annotationCount = annotation.data?.filter(
            (a) => dayjs(a.date).format('DD/MM/YYYY') === label
          ).length;
          return annotationCount !== undefined ? annotationCount : 0;
        }),
        backgroundColor: '#C0DDF4',
        borderColor: '#4377B9',
        borderWidth: 2,
        borderRadius: 4,
        barThickness: 40,
        datalabels: {
          anchor: 'end',
          align: 'top',
          formatter: (value: any) => value.y,
        },
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'category',
        // other x-axis options...
      },
      y: {
        type: 'linear',
        // other y-axis options...
      },
    },
    plugins: {
      datalabels: {
        display: true,
        color: 'black',
        anchor: 'end',
        align: 'top',
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: 'black',
        },
        borderRadius: 5,
      },
      title: {
        display: false,
      },
    },
  };

  const onClick = (event: any) => {
    if (getElementAtEvent(chartRef.current!, event).length > 0) {
      const barElement = chartRef.current?.tooltip?.body[0].lines[0];
      const dataPoint = getElementsAtEvent(chartRef.current!, event)[0].index;
      const date = labels?.[dataPoint];

      history.push(`/inkluzen/students/${id}/process/3812382`);
    }
  };

  const handleBarHover = (event: any, chartElement: any) => {
    setHoveredBar(chartElement[0] ? chartElement[0]._index : null);
  };

  return (
    <Layout.Container>
      <Flex style={{ width: '100%', height: '100%' }} gap={32}>
        <Flex style={{ width: '100%', height: '100%' }} vertical>
          <Flex style={{ width: '100%', height: '100%' }} vertical>
            {process.data ? (
              <Bar
                options={options as any}
                data={data as any}
                width={854}
                height={'500px'}
                ref={chartRef as any}
                onClick={onClick}
                onHover={(event: any, chartElement: any) =>
                  handleBarHover(event, chartElement)
                }
                style={{ cursor: hoveredBar !== null ? 'pointer' : 'default' }}
              />
            ) : (
              <Spin size={'large'} />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Layout.Container>
  );
}

export default StudentProcessGraph;
