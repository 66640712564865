import { useMediaQuery } from '@chakra-ui/react';
import { Col, Divider, Flex, Row } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { useParams } from 'react-router-dom';
import useSafeSpaceAvaliation from 'core/features/safeSpace/hooks/useSafeSpaceAvaliation';
import { CiHeart } from 'react-icons/ci';
import EyesIcon from 'components/icons/EyesIcon';
import { PiWarningDiamond } from 'react-icons/pi';
import { LiaBombSolid } from 'react-icons/lia';
import { safeSpaceFlags } from 'infra/helpers/types';
import { t } from 'core/resources/strings';

function SafeSpaceAvaliation() {
  const params = useParams<{ id: string }>();
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data: avalation } = useSafeSpaceAvaliation(params.id);

  return (
    <GenericCard height="100%">
      <Flex vertical style={{ width: '100%' }}>
        <p
          style={{
            fontSize: isMobile ? '18px' : '24px',
            color: colorPrimary,
            fontWeight: 700,
            marginBottom: '8px',
          }}
        >
          {t('app.classificacaoSocioemocional')}
        </p>
        <Flex vertical gap="16px">
          <p
            style={{
              fontSize: isMobile ? '14px' : '18px',
              color: 'black',
            }}
          >
            <strong>{t('app.classificacaoDoMeuCantinho')}: </strong>
            {
              safeSpaceFlags.find(
                (item) =>
                  item.value ===
                  (Number(avalation?.safeSpaceClassification) ?? 0)
              )?.label
            }
          </p>
          <p
            style={{
              fontSize: isMobile ? '14px' : '18px',
              color: 'black',
            }}
          >
            <strong>{t('app.justificativaDaClassificacao')}: </strong>
            {avalation?.classificationJustification}
          </p>
          <Row style={{ width: '100%' }}>
            <Col span={isMobile ? 8 : 4}>
              <Flex vertical gap="4px" align="center">
                <p
                  style={{
                    fontSize: isMobile ? '14px' : '18px',
                    fontWeight: 400,
                    textAlign: 'center',
                    color: 'black',
                  }}
                >
                  {t('app.coracaoQuentinho')}
                </p>
                <CiHeart color="black" size={isMobile ? '30px' : '34px'} />
                <p
                  style={{
                    fontSize: isMobile ? '18px' : '22px',
                    fontWeight: 700,
                    color: 'black',
                  }}
                >
                  {
                    avalation?.quantityOfContextualizedTextualExpressions
                      ?.Coracao_quentinho
                  }
                </p>
              </Flex>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: '100%' }} />
            </Col>
            <Col span={isMobile ? 15 : 19}>
              <p
                style={{
                  fontSize: isMobile ? '14px' : '18px',
                  fontWeight: 600,
                  textAlign: 'justify',
                  color: 'black',
                }}
              >
                {t('app.expressoesCoracaoQuentinho')}:
              </p>
              {avalation?.quantityOfContextualizedTextualExpressions?.Expressoes_Coracao_quentinho?.map(
                (item, index) => (
                  <p
                    style={{
                      fontSize: isMobile ? '14px' : '18px',
                      fontWeight: 400,
                      color: 'black',
                      textAlign: 'justify',
                    }}
                  >
                    "{item}"
                    {index !==
                    avalation?.quantityOfContextualizedTextualExpressions
                      ?.Coracao_quentinho -
                      1
                      ? ','
                      : ''}
                  </p>
                )
              )}
            </Col>
            <Divider style={{ marginBottom: '8px', marginTop: '8px' }} />
            <Col span={isMobile ? 8 : 4}>
              <Flex vertical gap="4px" align="center">
                <p
                  style={{
                    fontSize: isMobile ? '14px' : '18px',
                    fontWeight: 400,
                    textAlign: 'center',
                    color: 'black',
                  }}
                >
                  {t('app.bomFicarDeOlho')}
                </p>
                <EyesIcon size={isMobile ? 34 : 30} color="black" />
                <p
                  style={{
                    fontSize: isMobile ? '18px' : '22px',
                    fontWeight: 700,
                    color: 'black',
                  }}
                >
                  {
                    avalation?.quantityOfContextualizedTextualExpressions
                      ?.E_bom_ficar_de_olho
                  }
                </p>
              </Flex>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: '100%' }} />
            </Col>
            <Col span={isMobile ? 15 : 19}>
              <p
                style={{
                  fontSize: isMobile ? '14px' : '18px',
                  fontWeight: 600,
                  textAlign: 'justify',
                  color: 'black',
                }}
              >
                {t('app.expressoesBomFicarDeOlho')}:
              </p>
              {avalation?.quantityOfContextualizedTextualExpressions?.Expressoes_E_bom_ficar_de_olho?.map(
                (item, index) => (
                  <p
                    style={{
                      fontSize: isMobile ? '14px' : '18px',
                      fontWeight: 400,
                      color: 'black',
                      textAlign: 'justify',
                    }}
                  >
                    "{item}"
                    {index !==
                    avalation?.quantityOfContextualizedTextualExpressions
                      ?.E_bom_ficar_de_olho -
                      1
                      ? ','
                      : ''}
                  </p>
                )
              )}
            </Col>
            <Divider style={{ marginBottom: '8px', marginTop: '8px' }} />
            <Col span={isMobile ? 8 : 4}>
              <Flex vertical gap="4px" align="center">
                <p
                  style={{
                    fontSize: isMobile ? '14px' : '18px',
                    fontWeight: 400,
                    textAlign: 'center',
                    color: 'black',
                  }}
                >
                  {t('app.redobreAtencao')}
                </p>
                <PiWarningDiamond
                  color="black"
                  size={isMobile ? '30px' : '34px'}
                />
                <p
                  style={{
                    fontSize: isMobile ? '18px' : '22px',
                    fontWeight: 700,
                    color: 'black',
                  }}
                >
                  {
                    avalation?.quantityOfContextualizedTextualExpressions
                      ?.Redobre_a_atencao
                  }
                </p>
              </Flex>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: '100%' }} />
            </Col>
            <Col span={isMobile ? 15 : 19}>
              <p
                style={{
                  fontSize: isMobile ? '14px' : '18px',
                  fontWeight: 600,
                  textAlign: 'justify',
                  color: 'black',
                }}
              >
                {t('app.expressoesRedobreAAtencao')}:
              </p>
              {avalation?.quantityOfContextualizedTextualExpressions?.Expressoes_Redobre_a_atencao?.map(
                (item, index) => (
                  <p
                    style={{
                      fontSize: isMobile ? '14px' : '18px',
                      fontWeight: 400,
                      color: 'black',
                      textAlign: 'justify',
                    }}
                  >
                    "{item}"
                    {index !==
                    avalation?.quantityOfContextualizedTextualExpressions
                      ?.Redobre_a_atencao -
                      1
                      ? ','
                      : ''}
                  </p>
                )
              )}
            </Col>
            <Divider style={{ marginBottom: '8px', marginTop: '8px' }} />
            <Col span={isMobile ? 8 : 4}>
              <Flex vertical gap="4px" align="center">
                <p
                  style={{
                    fontSize: isMobile ? '14px' : '18px',
                    fontWeight: 400,
                    textAlign: 'center',
                    color: 'black',
                  }}
                >
                  {t('app.problemaSerio')}
                </p>
                <LiaBombSolid color="black" size={isMobile ? '30px' : '34px'} />
                <p
                  style={{
                    fontSize: isMobile ? '18px' : '22px',
                    fontWeight: 700,
                    color: 'black',
                  }}
                >
                  {
                    avalation?.quantityOfContextualizedTextualExpressions
                      ?.Problema_serio
                  }
                </p>
              </Flex>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: '100%' }} />
            </Col>
            <Col span={isMobile ? 15 : 19}>
              <p
                style={{
                  fontSize: isMobile ? '14px' : '18px',
                  fontWeight: 600,
                  textAlign: 'justify',
                  color: 'black',
                }}
              >
                {t('app.expressoesProblemaSerio')}:
              </p>
              {avalation?.quantityOfContextualizedTextualExpressions?.Expressoes_Problema_serio?.map(
                (item, index) => (
                  <p
                    style={{
                      fontSize: isMobile ? '14px' : '18px',
                      fontWeight: 400,
                      color: 'black',
                      textAlign: 'justify',
                    }}
                  >
                    "{item}"
                    {index !==
                    avalation?.quantityOfContextualizedTextualExpressions
                      ?.Problema_serio -
                      1
                      ? ','
                      : ''}
                  </p>
                )
              )}
            </Col>
          </Row>
        </Flex>
      </Flex>
    </GenericCard>
  );
}

export default SafeSpaceAvaliation;
