import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useHistory } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import Button from 'components/buttons/button';
import PostsTable from '../../components/PostsTable';
import { useState } from 'react';
import PostsFeedbackModal from 'ui/pages/app/posts-hub/blog-posts/components/postsFeedbackModal';

function PostsTab() {
  const history = useHistory();
  const [check] = useCheckPermissions();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isOpenModalFeedback,setIsOpenModalFeedback] = useState(false);
  const [postId, setPostId] = useState<string>();
  return (
    <>
      {isOpenModalFeedback ? (
        <PostsFeedbackModal
          id={postId}
          onClose={() => {
            setIsOpenModalFeedback(false);
            setPostId(undefined);
          } }
        />
      ) : null}
      <Flex justify="end" style={{ marginBottom: isMobile ? '8px' : '16px' }}>
        {check([Permissions.U4hero.Posts.Create]) ? (
          <Button
            size="md"
            onClick={() => history.push('/posts/create')}
            id="id-tour-posts-button-add"
          >
            CRIAR PUBLICAÇÃO
          </Button>
        ) : null}
      </Flex>
      <PostsTable onViewFeedback={(id)=>{
          setPostId(id);
          setIsOpenModalFeedback(true);
      }}/>
    </>
  );
}

export default PostsTab;
