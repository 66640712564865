import { useMutation } from "react-query";
import http from "infra/http";

export function useToggleQuestion() {
  return useMutation(
    (param: { id: string; status: boolean }) => {
      return http.put(
        `/v2/questions/change-status?id=${param.id}&status=${param.status ? false : true}`,
        {
          active: param.status ? false : true,
        }
      );
    },
    { retry: false }
  );
}
