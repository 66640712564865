import { useQuery } from "react-query";
import axios from "infra/http";
import { SafeSpaceAvaliationItem } from "../typings";

function useSafeSpaceAvaliation(id?: string) {
  const fetchData = async (id: string): Promise<SafeSpaceAvaliationItem> => {
    const { data } = await axios.get<SafeSpaceAvaliationItem>(`/v2/safeSpace/${id}/avaliation`);

    return data;
  };

  return useQuery([`/v2/safeSpace/${id}/avaliation`], () => fetchData(id!), {
    enabled: !!id,
  });
}

export default useSafeSpaceAvaliation;
