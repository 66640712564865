import React from 'react';
import { Col, Flex, Row } from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import { useMediaQuery } from '@chakra-ui/media-query';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { Pluralizar } from 'infra/helpers/plural';
import {
  PiClockAfternoonLight,
  PiLockKeyLight,
  PiSealWarningLight,
} from 'react-icons/pi';
import { t } from 'core/resources/strings';

interface GameCardProps {
  item: {
    id: string;
    name: string;
    coverImageUrl: string;
    minAge: number;
    count?: number;
    maxQuestions: number;
    averageTime: number;
  };
  isSelected: boolean;
  onSelect: () => void;
}

const GameCard: React.FC<GameCardProps> = ({ item, isSelected, onSelect }) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary, colorSecondary, colorPrimaryLight } = useU4heroColors();

  return (
    <GenericCard
      padding="0px"
      height="100%"
      cursor="pointer"
      hover={colorPrimaryLight}
      background={isSelected ? colorPrimaryLight : ''}
      onClick={onSelect}
    >
      {!isMobile ? (
        <Flex vertical justify="space-between">
          <div style={{ padding: '8px 8px 0px 8px' }}>
            <img
              style={{ borderRadius: '15px', aspectRatio: '16/9' }}
              src={item.coverImageUrl}
              alt={item.name}
            />
            <p
              style={{
                fontSize: isMobile ? '14px' : '18px',
                fontWeight: 600,
                color: colorPrimary,
                textAlign: 'center',
                marginBottom: '8px',
              }}
            >
              {item.name}
            </p>
            <Flex justify="space-around">
              <Flex vertical gap="4px" justify="center" align="center">
                <PiClockAfternoonLight color="black" size={24} />
                <p
                  style={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: 'black',
                    textAlign: 'center',
                  }}
                >
                  {item.averageTime} {t('app.minutos')}
                </p>
              </Flex>
              <Flex vertical gap="4px" justify="center" align="center">
                <PiLockKeyLight color="black" size={24} />
                <p
                  style={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: 'black',
                    textAlign: 'center',
                  }}
                >
                  {item.minAge} {t('app.anosMais')}
                </p>
              </Flex>
            </Flex>

            <Flex
              gap="4px"
              justify="center"
              align="center"
              style={{ marginTop: '8px', marginBottom: '8px' }}
            >
              <PiSealWarningLight color="black" size={24} />
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: 'black',
                  textAlign: 'center',
                }}
              >
                {item.maxQuestions} {t('app.perguntas').toLowerCase()}
              </p>
            </Flex>
          </div>

          <div
            style={{
              borderRadius: '5px 5px 20px 20px',
              background: isSelected
                ? colorPrimary
                : hex2rgba(colorSecondary, 0.8),
              width: '100%',
              alignSelf: 'end',
            }}
          >
            <p
              style={{
                fontSize: isMobile ? '14px' : '18px',
                fontWeight: 600,
                color: 'white',
                textAlign: 'center',
              }}
            >
              {item?.count
                ? `${t('app.utilizado')} ${item.count} ${Pluralizar(
                    t('app.vez'),
                    item.count > 1
                  )}`
                : t('app.naoUtilizado')}
            </p>
          </div>
        </Flex>
      ) : (
        <Row>
          <Col span={12} style={{ width: '100%', padding: '8px' }}>
            <img
              style={{
                borderRadius: '15px',
                aspectRatio: '16/9',
                height: '100%',
                objectFit: 'cover',
              }}
              src={item.coverImageUrl ?? ''}
              alt={item.name}
            />
          </Col>
          <Col span={12} style={{ width: '100%', padding: '8px' }}>
            <Flex vertical gap="10px">
              <Flex justify="space-around">
                <Flex vertical gap="4px" justify="center" align="center">
                  <PiClockAfternoonLight color="black" size={24} />
                  <p
                    style={{
                      fontSize: '12px',
                      fontWeight: 400,
                      color: 'black',
                      textAlign: 'center',
                    }}
                  >
                    {item.averageTime} {t('app.minutos')}
                  </p>
                </Flex>
                <Flex vertical gap="4px" justify="center" align="center">
                  <PiLockKeyLight color="black" size={24} />
                  <p
                    style={{
                      fontSize: '12px',
                      fontWeight: 400,
                      color: 'black',
                      textAlign: 'center',
                    }}
                  >
                    {item.minAge} {t('app.anosMais')}
                  </p>
                </Flex>
              </Flex>

              <Flex gap="4px" justify="center" align="center">
                <PiSealWarningLight color="black" size={24} />
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: 400,
                    color: 'black',
                    textAlign: 'center',
                  }}
                >
                  {item.maxQuestions} {t('app.perguntas').toLowerCase()}
                </p>
              </Flex>
            </Flex>
            <div
              style={{
                borderRadius: '10px 10px 0px 0px',
                color: 'white',
                background: isSelected
                  ? colorPrimary
                  : hex2rgba(colorSecondary, 0.8),
                padding: '4px',
                fontSize: '12px',
                position: 'relative',
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                marginTop: '14px',
              }}
            >
              {item?.count
                ? `${t('app.utilizado')} ${item.count} ${Pluralizar(
                    t('app.vez'),
                    item.count > 1
                  )}`
                : t('app.naoUtilizado')}
            </div>
          </Col>
        </Row>
      )}
    </GenericCard>
  );
};

export default GameCard;
