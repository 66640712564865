import http from "infra/http";
import { useMutation } from "react-query";

export function useSaveClassV2() {
  return useMutation(
    (data: any) => {
      if(data.id) {
        return http.put(`/v2/class/update`, data);
      } 
      return http.post(`/v2/class/create`, data);
    },
    { retry: false }
  );
}