import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import Layout from 'components/layout';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useHistory, useParams } from 'react-router-dom';
import CollaboratorsTable from './components/CollaboratorsTable';
import { useState } from 'react';
import ModalImportCollaborator from 'components/modals/modal-import-collaborator';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { Permissions } from 'core/resources/enums/permissions';
import { BiImport } from 'react-icons/bi';
import CollaboratorModal from './components/CollaboratorModal';
import BackButton from 'components/buttons/back-button';
import Button from 'components/buttons/button';
import Title from 'components/typography/title';
import InstitutionHeader from 'components/institution-header';
import HeaderImage from 'assets/images/collaborators-header-image.png';
import { useTenant } from 'core/features/tenants/hooks/useTenant';
import { t } from 'core/resources/strings';

function Collaborators() {
  const history = useHistory();
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isOpenModalImport, setIsOpenModalImport] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [check] = useCheckPermissions();
  const { tenantId } = useParams<{ tenantId: string }>();
  const { data: tenant } = useTenant(tenantId);
  const [id, setId] = useState<string>();

  const isAllowed = check([
    Permissions.U4hero.Collaborators.Import,
    Permissions.Org.Collaborators.Import,
    Permissions.Collaborators.Import,
  ]);

  return (
    <Layout.Container>
      {isOpenModal ? (
        <CollaboratorModal
          tenantId={tenantId}
          id={id}
          onClose={() => {
            setId(undefined);
            setIsOpenModal(false);
          }}
        />
      ) : null}

      {isOpenModalImport ? (
        <ModalImportCollaborator
          tenantId={tenantId}
          onClose={() => {
            setIsOpenModalImport(false);
          }}
        />
      ) : null}
      <Flex gap={isMobile ? '12px' : '24px'} align="center">
        <BackButton
           onClick={() => {
            if (tenant) {
              history.push(`/schools?tab=${tenant?.master ? 1 : 2}`);
            } else {
              history.push('/my-school');
            }
          }}
        />
        <Title primary>{t('app.colaboradores')}</Title>
      </Flex>
      {tenant ? (
        <InstitutionHeader image={HeaderImage} tenantName={tenant.name} />
      ) : null}

      <Flex
        gap={isMobile ? '8px' : '24px'}
        justify="end"
        style={{
          marginBottom: isMobile ? '8px' : '16px',
          marginTop: isMobile ? '8px' : '16px',
        }}
      >
        {isAllowed ? (
          <Button
            variant="outline"
            onClick={() => setIsOpenModalImport(true)}
            size="md"
            id="id-tour-my-school-button-import-collaborators"
            style={{
              display: 'flex',
              gap: isMobile ? '4px' : '16px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BiImport size={isMobile ? 22 : 28} color={colorPrimary} /> {t('app.importar')}
          </Button>
        ) : null}

        {check([
          Permissions.Collaborators.Create,
          Permissions.U4hero.Collaborators.Create,
          Permissions.Org.Collaborators.Create,
        ]) ? (
          <Button
            size="md"
            onClick={() => setIsOpenModal(true)}
            id="id-tour-my-school-button-add-collaborator"
          >
            {t('app.criar')} {t('app.colaborador')}
          </Button>
        ) : null}
      </Flex>

      <CollaboratorsTable
        onEdit={(user) => {
          setId(user.id);
          setIsOpenModal(true);
        }}
      />
    </Layout.Container>
  );
}

export default Collaborators;
