import { useQuery } from "react-query";
import axios from "infra/http";
import { BlogPostDetails } from "../typings";

function useBlogPost(id?: string) {
  const fetchData = async (id: string): Promise<BlogPostDetails> => {
    const { data } = await axios.get<BlogPostDetails>(`/v2/blog-posts/${id}`);

    return data;
  };

  return useQuery([`/v2/blog-posts/${id}`], () => fetchData(id!), {
    enabled: !!id,
  });
}

export default useBlogPost;
