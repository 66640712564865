import { FilterProps } from 'infra/helpers/types';
import GenericFilterComponent from 'components/generic-filter';
import useBlogPostsAuthors from 'core/features/blog-posts/hooks/useBlogPostsAuthors';

function StoriesFilter({ filters, onChangeFilters }: FilterProps) {
  const { data: authors } = useBlogPostsAuthors();
  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: 'Autor',
          filterName: 'authors',
          options:
            authors?.map((item) => {
              return { label: item.name, value: item.id };
            }) ?? [],
        },
        {
          title: 'Tipo',
          filterName: 'published',
          options: [
            {
              label: 'Publicado',
              value: 1,
            },
            {
              label: 'Rascunho',
              value: 2,
            },
          ],
          type: 'radio',
        },
        {
          title: 'Categoria',
          filterName: 'tags',
          options: [
            {
              label: 'Educação',
              value: 'Educação',
            },
            {
              label: 'Jogos',
              value: 'Jogos',
            },
            {
              label: 'Socioemocional',
              value: 'Socioemocional',
            },
            {
              label: 'Saúde Mental',
              value: 'Saúde Mental',
            },
            {
              label: 'Tecnologia',
              value: 'Tecnologia',
            },
          ],
        },
        {
          title: 'Data de publicação',
          filterName: 'publishDate',
          options: [],
          type: 'date',
          open: true,
        },
      ]}
    />
  );
}

export default StoriesFilter;
