import { useQuery } from 'react-query';
import axios from 'infra/http';

function useCountByClassification(classification: string) {
  const fetchHelpers = async (
    classification: string
  ): Promise<{ total: number }> => {
    const { data } = await axios.get<number>(
      `/v2/helpers/count/${classification}`
    );

    return { total: data };
  };

  return useQuery(
    [`/v2/helpers/count`, classification],
    () => fetchHelpers(classification),
    {
      keepPreviousData: true,
    }
  );
}

export default useCountByClassification;
