import { useQuery } from "react-query";
import axios from "infra/http";

function usePracticeFeebackAvg(id?: string) {
  const fetchData = async (id: string): Promise<{average:number}> => {
    const { data } = await axios.get<{average:number}>(`/v2/practices/feedback-avg/${id}`);

    return data;
  };

  return useQuery([`/v2/practices/feedback-avg/${id}`], () => fetchData(id!), {
    enabled: !!id,
  });
}

export default usePracticeFeebackAvg;
