import { Flex } from 'antd';
import Layout from 'components/layout';
import Title from 'components/typography/title';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useState } from 'react';
import CustomPanel from './tabs/custom-panel';
import EducatorView from './tabs/educatorView';
import ManagerView from './tabs/managerView';
import OrganizationView from './tabs/organizationView';
import { Permissions } from 'core/resources/enums/permissions';
import GroupButton from 'components/buttons/group-button';
import { NumberParam, useQueryParam } from 'use-query-params';
import { t } from 'core/resources/strings';

function IndicatorsPage() {
  const [check] = useCheckPermissions();
  const [step, setStep] = useQueryParam('tab', NumberParam);

  if (!step) {
    setStep(1);
  }
  const views = [
    {
      label: t('app.meuPainel'),
      value: 1,
      show: true,
    },
    {
      label: t('app.visaoEducador'),
      value: 2,
      show: check([Permissions.Indicators.View]),
    },
    {
      label: t('app.visaoGestor'),
      value: 3,
      show: check([Permissions.Indicators.ViewAll]),
    },
    {
      label: t('app.visaoOrganizacao'),
      value: 4,
      show: check([Permissions.Org.Indicators.View]),
    },
  ];

  return (
    <Layout.Container>
      <Flex vertical gap={16}>
        <Title primary>{t('app.painelDeIndicadores')}</Title>
        <Flex justify="flex-end">
          <GroupButton
            options={views.filter((item) => item.show)}
            value={step as number}
            onChange={(v) => setStep(v as number)}
          />
        </Flex>
        {step === 1 ? <CustomPanel /> : null}
        {step === 2 ? <EducatorView /> : null}
        {step === 3 ? <ManagerView /> : null}
        {step === 4 ? <OrganizationView /> : null}
      </Flex>
    </Layout.Container>
  );
}

export default IndicatorsPage;
