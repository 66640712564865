import { useMediaQuery } from '@chakra-ui/react';
import {
  Flex,
  Typography,
  DatePicker as DatePickerAntd,
  Menu,
  Dropdown,
  Row,
  Col,
  Image,
  Spin,
  Checkbox,
} from 'antd';
import { GenericCard } from 'components/V2/generic-card-styled-component/index';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import useEmotionalStats from 'core/features/graphs/hooks/useEmotionalGraphV2';
import { useParams } from 'react-router-dom';
import Table from 'components/V2/table';
import useEmotions from 'core/features/graphs/hooks/useEmotions';
import { FilterOutlined, RightOutlined } from '@ant-design/icons';
import useQuestionHumor from 'core/features/questions/hooks/useQuestionHumor';
import { t } from 'core/resources/strings';
import ReactApexChart from 'react-apexcharts';
import bad from 'assets/images/sad.png';
import good from 'assets/images/happy.png';
import fear from 'assets/images/fear.png';
import disgusted from 'assets/images/disgusted.png';
import verybad from 'assets/images/angry.png';
import { ApexOptions } from 'apexcharts';
import SadIcon from 'components/icons/SadIcon';
import dayjs from 'dayjs';
import ComparativeMode from 'components/comparativeMode';
import { ComparativeItem } from 'infra/helpers/types';

const { Text } = Typography;
const { RangePicker } = DatePickerAntd;

const colors = [
  {
    color: '#FFBC00',
    name: t('app.feliz'),
    img: good,
    id: '3e48867c-4975-4cc4-9231-dcda0a180c2e',
  },
  {
    color: '#8C6B84',
    name: t('app.triste'),
    img: bad,
    id: '6ce19a69-5adc-40e1-b691-a77f5c64e554',
  },
  {
    color: '#216EB4',
    name: t('app.medo'),
    img: fear,
    id: '443ccb0a-aa0b-4d30-b99e-3c35d43144e3',
  },
  {
    color: '#00B8B8',
    name: t('app.nojo'),
    img: disgusted,
    id: 'd62e3af7-4f9a-4641-b9eb-c8bd8a3d0fee',
  },
  {
    color: '#E35809',
    name: t('app.raiva'),
    img: verybad,
    id: 'ff450cf1-f299-459d-a71d-114e7cf57c11',
  },
];

function Emotionometer({
  serieId,
  allowCompare,
  className,
}: {
  serieId?: string;
  allowCompare?: boolean;
  className?: string;
}) {
  const [date, setDate] = useState<any>([
    dayjs().startOf('year'),
    dayjs().endOf('year'),
  ]);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary, colorGreyCard } = useU4heroColors();
  const { id, classId, tenantId } = useParams<{
    id: string;
    tenantId: string;
    classId: string;
  }>();
  const [page, setPage] = useState(1);
  const [optionIds, setOptionIds] = useState<string[]>([]);

  const [comparative, setComparative] = useState<ComparativeItem>({
    type: 'student',
    id: '',
    name: '',
  });

  const { data, isLoading: isLoadingStats } = useEmotionalStats({
    userId: id,
    classId,
    initialDate: date ? dayjs(date[0]).format('YYYY-MM-DD') : undefined,
    endDate: date ? dayjs(date[1]).format('YYYY-MM-DD') : undefined,
    disableGreedy: true,
    optionIds,
    tenantId,
  });

  const { data: data2, isLoading: isLoadingStats2 } = useEmotionalStats({
    userId: comparative.type === 'student' ? comparative.id : undefined,
    classId: comparative.type === 'class' ? comparative.id : classId,
    initialDate: date ? dayjs(date[0]).format('YYYY-MM-DD') : undefined,
    endDate: date ? dayjs(date[1]).format('YYYY-MM-DD') : undefined,
    disableGreedy: true,
    optionIds,
    tenantId,
    disable: comparative.id === '',
  });

  const { data: emotions, isLoading: isLoadingEmotions } = useEmotions({
    page: page,
    pageSize:5,
    userId: id,
    classId,
    initialDate: date ? dayjs(date[0]).format('YYYY-MM-DD') : undefined,
    endDate: date ? dayjs(date[1]).format('YYYY-MM-DD') : undefined,
    optionIds,
    tenantId,
  });

  const isLoading = isLoadingStats || isLoadingEmotions;

  const totalCount = data?.data?.reduce(
    (total, item) => total + item?.percent,
    0
  );
  const totalCount2 = data2?.data?.reduce(
    (total, item) => total + item?.percent,
    0
  );

  const options: ApexOptions = {
    chart: {
      type: 'donut',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    labels: data?.data?.map((item) => t(`app.${item.name}`)),
    colors: colors.map((item) => item.color),
    legend: {
      show: false,
    },
  };

  const toggleOptions = (optionId: string) => {
    if (optionIds.includes(optionId)) {
      setOptionIds((prev) => prev.filter((key) => key !== optionId));
    } else {
      setOptionIds((prev) => [...prev, optionId]);
    }
  };
  return (
    <Flex vertical>
      <Flex
        align="center"
        justify="end"
        gap={24}
        style={{ marginBottom: isMobile ? '8px' : '18px' }}
      >
        <RangePicker
          style={{ height: '34px' }}
          format="DD/MM/YYYY"
          separator={<HiOutlineArrowNarrowRight />}
          suffixIcon={<AiOutlineCalendar color="black" />}
          value={date}
          onChange={(v) => setDate(v)}
        />
        {allowCompare && (
          <ComparativeMode
            classId={classId}
            serieId={serieId}
            value={comparative}
            tenantId={tenantId}
            onChange={(v) => setComparative(v)}
          />
        )}
      </Flex>
      {!isLoading ? (
        <GenericCard>
          <Row style={{ width: '100%' }} gutter={isMobile ? [0, 0] : [50, 0]}>
            <Col span={isMobile ? 24 : 12}>
              <Flex justify="space-between">
                <Text
                  style={{
                    color: colorPrimary,
                    fontSize: isMobile ? '18px' : '24px',
                    fontWeight: 700,
                  }}
                >
                  {comparative.id !== ''
                    ? `${t('app.emocionometro')} - ${t(
                        'app.turma'
                      )}: ${className}`
                    : t('app.emocionometro')}
                </Text>
              </Flex>
              {isLoadingStats ? (
                <Spin size="large" />
              ) : (
                <div>
                  {totalCount === 0 ? (
                    <Flex
                      vertical
                      align="center"
                      justify="center"
                      gap="16px"
                      style={{ height: '100%' }}
                    >
                      <SadIcon color="#7B7B7B" size={60} />
                      <p
                        style={{
                          fontSize: '14px',
                          color: '#7B7B7B',
                          textAlign: 'center',
                          fontWeight: 400,
                          maxWidth: '267px',
                        }}
                      >
                        {t('app.emocionometroNaoPreenchido')}
                      </p>
                    </Flex>
                  ) : (
                    <Flex align="center" justify="center" vertical>
                      <ReactApexChart
                        width="100%"
                        height={isMobile ? '235px' : '340px'}
                        options={options}
                        series={data?.data.map((item) =>
                          parseFloat(item.percent.toFixed(1))
                        )}
                        type="donut"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      />
                      <Flex wrap={'wrap'} gap="16px" justify="center">
                        {colors.map((item, index) => (
                          <Flex gap="8px" key={index} align="center">
                            <img
                              alt={item.name}
                              src={item.img}
                              width={isMobile ? '24px' : '30px'}
                            />
                            <p
                              style={{
                                fontSize: isMobile ? '14px' : '16px',
                                fontWeight: 400,
                                color: 'black',
                                textAlign: 'right',
                              }}
                            >
                              {item.name}
                            </p>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  )}
                </div>
              )}
            </Col>

            <Col span={comparative.id === '' ? 0 : isMobile ? 24 : 12}>
              <Flex justify="space-between">
                <Text
                  style={{
                    color: colorPrimary,
                    fontSize: isMobile ? '18px' : '24px',
                    fontWeight: 700,
                  }}
                >
                  {t('app.emocionometro')} -{' '}
                  {comparative.type === 'student'
                    ? t('app.estudante') + ': '
                    : t('app.turma') + ': '}
                  {comparative.name}
                </Text>
              </Flex>
              {isLoadingStats ? (
                <Spin size="large" />
              ) : (
                <div>
                  {totalCount2 === 0 ? (
                    <Flex
                      vertical
                      align="center"
                      justify="center"
                      gap="16px"
                      style={{ height: '100%' }}
                    >
                      <SadIcon color="#7B7B7B" size={60} />
                      <p
                        style={{
                          fontSize: '14px',
                          color: '#7B7B7B',
                          textAlign: 'center',
                          fontWeight: 400,
                          maxWidth: '267px',
                        }}
                      >
                        {t('app.emocionometroNaoPreenchido')}
                      </p>
                    </Flex>
                  ) : (
                    <Flex align="center" justify="center" vertical>
                      <ReactApexChart
                        width="100%"
                        height={isMobile ? '235px' : '340px'}
                        options={options}
                        series={data2?.data.map((item) =>
                          parseFloat(item.percent.toFixed(1))
                        )}
                        type="donut"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      />
                      <Flex wrap={'wrap'} gap="16px" justify="center">
                        {colors.map((item, index) => (
                          <Flex gap="8px" key={index} align="center">
                            <img
                              alt={item.name}
                              src={item.img}
                              width={isMobile ? '24px' : '30px'}
                            />
                            <p
                              style={{
                                fontSize: isMobile ? '14px' : '16px',
                                fontWeight: 400,
                                color: 'black',
                                textAlign: 'right',
                              }}
                            >
                              {item.name}
                            </p>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  )}
                </div>
              )}
            </Col>

            <Col
              span={comparative.id !== '' ? 0 : isMobile ? 24 : 12}
              style={{ width: '100%' }}
            >
              <Table
                className="small-gap-table"
                noMargin={true}
                itemName=""
                showTotal={false}
                onChangePage={setPage}
                total={emotions?.total ?? 0}
                page={page}
                pageSize={5}
                isLoading={isLoadingEmotions}
                showHeader={false}
                shadow={false}
                title={() => (
                  <Flex justify="space-between">
                    <Text
                      style={{
                        color: colorPrimary,
                        fontSize: isMobile ? '18px' : '24px',
                        fontWeight: 700,
                        fontFamily: 'Lato',
                      }}
                    >
                      {t('app.historico')}
                    </Text>
                    <Dropdown
                      overlay={
                        <Menu
                          onClick={(e) => {
                            toggleOptions(e.key);
                          }}
                        >
                          {colors.map((item) => (
                            <Menu.Item key={item.id}>
                              <Checkbox checked={optionIds?.includes(item.id)}>
                                {item.name}
                              </Checkbox>
                            </Menu.Item>
                          ))}
                        </Menu>
                      }
                      trigger={['click']}
                    >
                      <Flex
                        gap="8px"
                        align="center"
                        style={{
                          background: '#F5F5F5',
                          padding: '8px',
                          borderRadius: '10px',
                          cursor: 'pointer',
                        }}
                      >
                        <FilterOutlined
                          style={{
                            color: 'black',
                            fontSize: '20px',
                            marginRight: '8px',
                          }}
                        />
                        <RightOutlined
                          style={{ fontSize: '10px', color: 'black' }}
                        />
                      </Flex>
                    </Dropdown>
                  </Flex>
                )}
                columns={[
                  {
                    title: '',
                    dataIndex: 'image',
                    key: 'image',
                    render: (url: string, record: any) => (
                      <Image
                        src={url}
                        width={isMobile ? 24 : 34}
                        height={isMobile ? 24 : 34}
                        preview={false}
                      />
                    ),
                  },
                  {
                    title: '',
                    dataIndex: 'name',
                    key: 'name',
                    render: (name: string, record: any) => (
                      <p style={{ fontSize: isMobile ? '14px' : '18px' }}>
                        {t(`app.${name}`)}
                      </p>
                    ),
                  },
                  {
                    title: (
                      <p style={{ fontSize: isMobile ? '14px' : '18px' }}>
                        Data
                      </p>
                    ),
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    render: (createdAt: string) => (
                      <p style={{ fontSize: isMobile ? '14px' : '18px' }}>
                        {dayjs(createdAt).format('DD/MM/YYYY')}
                      </p>
                    ),
                  },
                  {
                    title: (
                      <p style={{ fontSize: isMobile ? '14px' : '18px' }}>
                        Data
                      </p>
                    ),
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    render: (createdAt: string) => (
                      <p style={{ fontSize: isMobile ? '14px' : '18px' }}>
                        {dayjs(createdAt).format('HH[h]mm')}
                      </p>
                    ),
                  },
                ]}
                data={emotions?.data ?? []}
              />{' '}
            </Col>
          </Row>
        </GenericCard>
      ) : (
        <GenericCard width="80vw" height="30vh">
          <Flex justify="center" align="center" style={{ width: '100%' }}>
            <Spin size={'large'} />
          </Flex>
        </GenericCard>
      )}
    </Flex>
  );
}

export default Emotionometer;
