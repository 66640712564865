import { useMutation } from "react-query";
import http from "infra/http";

export function useResendInvitation() {
  return useMutation(
    (data: { id:any}) => {
      return http.get(`/v2/users/resend/${data.id}`);
    },
    { retry: false }
  );
}
