import SelectBase, { SelectBaseProps } from "components/inputs/select-base";
import useAllClasses from "core/features/classes/hooks/useAllClasses";
import { ClassDetails } from "core/features/classes/types";
import { useState } from "react";

export interface SelectClassProps<T = any> extends SelectBaseProps<T> {
  value: any;
  serieId?: any;
  onChange: (v: any) => void;
  tenantId?:string;
  callback?:(v:ClassDetails | undefined)=> void;
}

function SelectAllClass({ value, onChange, serieId,callback,tenantId, ...rest }: SelectClassProps) {
  const [search, setSearch] = useState<string>("");

  const { isLoading, isFetching, data } = useAllClasses({
    series: serieId ? Array.isArray(serieId) ? serieId :[serieId] : undefined,
    search,
    disableConcat: true,
    tenantId
  });

  return (
    <SelectBase
      loading={isLoading || isFetching}
      placeholder="Turma"
      value={value}
      onChange={(ev) => {
        onChange(ev);
        callback && callback(data?.data.find((item)=> item.id === ev))
      }}
      searchValue={search}
      onSearch={(v) => setSearch(v)}
      options={
        data?.data?.map((item) => ({
          label: item.name,
          value: item.id,
        })) ?? []
      }
      {...rest}
    />
  );
}

export default SelectAllClass;
