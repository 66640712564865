import { useQuery } from "react-query";
import axios from "infra/http";
import {  StudentProgress } from "../typings";
import { useEffect, useState } from "react";

export interface UseStudentsByActivityParams {
  activityId: string;
  search: string;
  series?:string[];
  classes?:string[];
  progress?:string[];
  limit:number;
  page:number;
}

function useStudentsByActivity(params: UseStudentsByActivityParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchStudents = async (
    params: UseStudentsByActivityParams
  ): Promise<{ data: StudentProgress[]; total: number }> => {
    const { data, headers } = await axios.get<StudentProgress[]>(`/v1/student/progress`, {
      params: {
        ...params,
        search: debouncedQuery
       ,
      },
    });

    return { data: data, total: Number(headers["total"]) };
  };

  return useQuery(
    [
      `/v1/student/progress`,
      {...params,search:debouncedQuery},
    ],
    () => fetchStudents(params),
  
  );
}

export default useStudentsByActivity;
