function DataIcon({ size = 50 }: { size?: number }) {
  return (
    <svg
      id="Line"
      viewBox="0 0 200 200"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M184.3,137.975H166.179a8.605,8.605,0,0,0,1.439-4.768V47a8.661,8.661,0,0,0-8.65-8.65H41.108A8.661,8.661,0,0,0,32.458,47v86.21a8.6,8.6,0,0,0,1.44,4.768H15.768a2,2,0,0,0-2,2v5.152a16.566,16.566,0,0,0,16.567,16.567h139.4a16.649,16.649,0,0,0,13.293-6.679,16.424,16.424,0,0,0,3.275-9.888v-5.152A2,2,0,0,0,184.3,137.975Zm-97.4,4h26.27v7.462H86.9ZM36.458,47a4.655,4.655,0,0,1,4.65-4.65h117.86a4.655,4.655,0,0,1,4.65,4.65v86.21a4.655,4.655,0,0,1-4.65,4.65H41.108a4.655,4.655,0,0,1-4.65-4.65ZM182.3,145.127a12.568,12.568,0,0,1-12.568,12.567H30.335a12.566,12.566,0,0,1-12.567-12.567v-3.152H82.9v9.462a2,2,0,0,0,2,2h30.27a2,2,0,0,0,2-2v-9.462H182.3Z" />
      <path d="M43,132.457h114.08a2,2,0,0,0,2-2V49.747a2,2,0,0,0-2-2H43a2,2,0,0,0-2,2v80.71A2,2,0,0,0,43,132.457Zm2-80.71h110.08v76.71H45Z" />
      <path d="M144.634,61.975h-89.2a2,2,0,0,0-2,2v9.5a2,2,0,0,0,2,2h89.2a2,2,0,0,0,2-2v-9.5A2,2,0,0,0,144.634,61.975Zm-2,9.5h-85.2v-5.5h85.2Z" />
      <path d="M85.161,102.892H56.27a2,2,0,0,0,0,4H85.161a2,2,0,0,0,0-4Z" />
      <path d="M85.161,91.72H56.27a2,2,0,0,0,0,4H85.161a2,2,0,0,0,0-4Z" />
      <path d="M85.161,114.892H56.27a2,2,0,0,0,0,4H85.161a2,2,0,0,0,0-4Z" />
      <path d="M143.161,102.892H114.27a2,2,0,1,0,0,4h28.891a2,2,0,0,0,0-4Z" />
      <path d="M143.161,91.72H114.27a2,2,0,0,0,0,4h28.891a2,2,0,0,0,0-4Z" />
      <path d="M143.161,114.892H114.27a2,2,0,1,0,0,4h28.891a2,2,0,0,0,0-4Z" />
    </svg>
  );
}

export default DataIcon;
