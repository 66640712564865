import { useMediaQuery } from '@chakra-ui/react';
import { useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import DynamicTable from 'components/V2/dinamic-table';
import useStudentsByActivity from 'core/features/students/hooks/useStudentsByActivity';
import { StudentProgress } from 'core/features/students/typings';
import { Flex, Progress } from 'antd';
import Tag from 'components/V2/tag';
import StudentFilter from 'ui/pages/app/mySchool/pages/Students/components/StudentFilter';
import { FilterType } from 'infra/helpers/types';
import Text from 'components/typography/text';
import moment from 'moment';

function ActivityStudentsTable({ activityId }: { activityId: string }) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [filters, setFilters] = useState<FilterType>({
    series: [],
    classes: [],
    progress: [],
  });
  const { data, isLoading } = useStudentsByActivity({
    activityId: activityId,
    page,
    limit: pageSize,
    search,
    series: filters?.series?.map((item: any) => item.value) ?? undefined,
    classes: filters?.classes?.map((item: any) => item.value) ?? undefined,
    progress: filters?.progress?.map((item: any) => item.value) ?? undefined,
  });
  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };
  const columns = [
    {
      title: (
        <p style={tableHeaderStyle}>Nome/{isMobile ? <br /> : null}Turma</p>
      ),
      dataIndex: 'name',
      key: 'name',
      mobile: true,
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
      render: (_: string, record: StudentProgress) => (
        <Flex vertical style={tableRowStyle}>
          <p>{record.name}</p>
          {record.class ? <Tag text={record.class} /> : null}
        </Flex>
      ),
    },
    {
      title: <p style={tableHeaderStyle}>Progresso</p>,
      dataIndex: 'progress',
      key: 'progress',
      mobile: true,
      render: (_: string, record: StudentProgress) => (
        <Flex vertical>
          <Progress
            status="normal"
            percent={Number(Number(record.progress).toFixed(1))}
          />
          {parseInt(record.progress) === 100 && (
            <Text size="sm">
              Concluído em: {moment(record.lastAnswerDate).format('DD/MM/YYYY')}
            </Text>
          )}
        </Flex>
      ),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Respostas</p>,
      dataIndex: 'answers',
      key: 'answers',
      mobile: true,
      render: (_: string, record: StudentProgress) => (
        <p style={tableRowStyle}>
          {record.answersCount}/{record.countQuestions}
        </p>
      ),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
  ];

  return (
    <DynamicTable
      id="id-tour-activities-modal-progress-section-students-table"
      itemName="estudante"
      data={data?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title=""
      total={data?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isLoading}
      defaultColumns={
        isMobile ? columns.filter((item) => item.mobile) : columns
      }
      allColumns={columns}
      onSearch={setSearch}
      hideCog={true}
      appliedFilters={filters}
      onChangeFilters={setFilters}
      filters={<StudentFilter filters={filters} onChangeFilters={setFilters} />}
    />
  );
}

export default ActivityStudentsTable;
