import { Box} from '@chakra-ui/layout'
import Table from 'components/table'
import useClasses from 'core/features/classes/hooks/useClasses'
import { ClassDetails } from 'core/features/classes/types'
import { purple500 } from 'core/resources/theme/colors'
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { t } from 'core/resources/strings'
import { Tag } from '@chakra-ui/react'
import SearchSerieBar from '../SearchSerieBar'
import GenericHeaderTable from 'components/genericHeaderTable'

function ClassList({
  setStep,
  onClick,
}: {
  setStep: (v: number) => void
  onClick: (v: any) => void
}) {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [series, setSeries] = useState()

  const { data, isLoading, isFetching } = useClasses({
    page,
    series: series ? [series] : undefined,
    search,
  })

  const isEachLoading = isLoading || isFetching

  useEffect(() => {
    setPage(1);
  }, [series,search]);
  return (
    <Box>
      <Row>
        <Col sm={12}>
          <SearchSerieBar
            onTextSearch={setSearch}
            valueSerie={series}
            onSerieChange={setSeries}
            id='id-tour-report-class-section-filters'
          />

          <Box pl="24px" flex={1} mt="30px">
            <GenericHeaderTable itemName={t('app.turma')} total={data?.total ?? 0} />

            <Table<ClassDetails>
              id='id-tour-report-class-section-table'
              data={data?.data ?? []}
              loading={isEachLoading}
              onClickRow={(item) => {
                setStep(1)
                onClick(item.id)
              }}
              pagination={{
                total: data?.total ?? 0,
                onChangePage: (p) => setPage(p),
                page: page,
                perPage: 25,
              }}
              columns={[
                {
                  accessor: 'name',
                  Header: () => <Box color={purple500}>{t('app.nome')}</Box>,
                  Cell: ({
                    row: { original },
                  }: {
                    row: { original: ClassDetails }
                  }) => (
                    <Box>
                      <Box>{original.name}</Box>
                      <Tag colorScheme={'purple'} fontSize={11}>
                        Série: {original.serie.name}
                      </Tag>
                    </Box>
                  ),
                },
                {
                  accessor: 'user',
                  Header: () => <Box color={purple500}>{t('app.chave')}</Box>,
                  Cell: ({
                    row: { original },
                  }: {
                    row: { original: ClassDetails }
                  }) => (
                    <Box
                      fontWeight="bold"
                      fontFamily={'Consolas'}
                      fontSize="16"
                    >
                      {original?.user?.email}
                    </Box>
                  ),
                },
                {
                  accessor: 'year',
                  Header: () => <Box color={purple500}>{t('app.anoLetivo')}</Box>,
                },
                {
                  accessor: 'period',
                  Header: () => <Box color={purple500}>{t('app.turno')}</Box>,
                  Cell: ({
                    row: { original },
                  }: {
                    row: { original: ClassDetails }
                  }) => <Box>{t(`app.${original.period}`)}</Box>,
                },
                {
                  accessor: 'countStudents',
                  Header: () => <Box color={purple500}>{t('app.nEstudantes')}</Box>,
                },
              ]}
            />
          </Box>
        </Col>
      </Row>
    </Box>
  )
}

export default ClassList
