import { useQuery } from 'react-query';
import axios from 'infra/http';

export interface UseNotificationsParams {
  page?: number;
}

function useNotifications() {
  const fetchNotifications = async (): Promise<{
    data: any[];
    total: number;
  }> => {
    const { data, headers } = await axios.get<any[]>(`/v2/notifications/`);

    return { data: data, total: Number(headers['total']) };
  };

  return useQuery([`/v2/notifications`], () => fetchNotifications(), {
    keepPreviousData: true,
  });
}

export default useNotifications;
