import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex, Popover } from 'antd';
import map from 'assets/images/mapa.png';
import mapMobile from 'assets/images/mapa-mobile.png';
import Button from 'components/buttons/button';
import Layout from 'components/layout';
import ModalStartTour from 'components/modals/modal-start-tour';
import useGuideInitialTour from 'core/features/guides/hooks/useGuideInitialTour';
import useTourHistory from 'core/features/tour/hooks/useTourHistory';
import { useCurrentUser } from 'core/features/user/store';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import { FaCheck, FaCircleCheck } from 'react-icons/fa6';
import { TiStarFullOutline } from 'react-icons/ti';
import { IoClose } from 'react-icons/io5';
import { useHistory } from 'react-router';

function TourMap({ onClose }: { onClose: () => void }) {
  const { data } = useGuideInitialTour();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const history = useHistory();
  const { user } = useCurrentUser();
  const { data: userHistory } = useTourHistory(user?.id);

  const [selected, setSelected] = useState<number>();
  const dots = [
    [850, 462],
    [472, 452],
    [655, 267],
    [727, 98],
    [638, 56],
    [534, 87],
    [356, 101],
    [492, -87],
    [316, -32],
  ];

  const dotsMobile = [
    [192, 428],
    [271, 348],
    [169, 314],
    [219, 188],
    [110, 194],
    [76, 105],
    [158, 51],
    [181, -55],
    [265, -79],
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState<number>(0);

  return (
    <>
      <div
        style={{
          width: isMobile ? '90%' : '1110px',
          height: isMobile ? '90%' : '670px',
          position: 'fixed',
          backgroundImage: `url(${isMobile ? mapMobile : map})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          top: '50%',
          left: isMobile ? undefined : '50%',
          right: isMobile ? '-40%' : undefined,
          transform: 'translate(-50%, -50%)',
          zIndex:1001
        }}
      >
        <Button
          size="sm"
          style={{
            position: 'absolute',
            top: isMobile ? 35 : 30,
            right: isMobile ? 34 : 56,
          }}
          onClick={onClose}
        >
          <IoClose size={30} color="white" />
        </Button>
        {data?.tours.map((tour, index) =>
          isMobile ? (
            <div
              style={{
                position: 'relative',
                left: isMobile ? dotsMobile[index][0] : dots[index][0],
                top: isMobile ? dotsMobile[index][1] : dots[index][1],
                width: '30px',
                height: '30px',
                backgroundColor: 'transparent',
                borderRadius: '50%',
                zIndex: 20,
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
              }}
              onClick={() => {
                setSelected(index);
                setIsOpen(true);
                setCurrent(index);
              }}
            >
              {selected === index ? (
                <TiStarFullOutline color="yellow" size={24} />
              ) : null}
              {userHistory?.find((item) => item.tourId === tour.id) &&
              selected !== index ? (
                <FaCheck size={24} color="green" />
              ) : null}
            </div>
          ) : (
            <Popover
              key={tour.id}
              content={
                <div
                  style={{
                    background: colorPrimary,
                    borderRadius: '10px',
                    color: 'white',
                    fontSize: '14px',
                  }}
                >
                  {data?.tours[index].name}
                </div>
              }
              trigger={'hover'}
            >
              <div
                style={{
                  position: 'relative',
                  left: isMobile ? dotsMobile[index][0] : dots[index][0],
                  top: isMobile ? dotsMobile[index][1] : dots[index][1],
                  width: '30px',
                  height: '30px',
                  backgroundColor: 'transparent',
                  borderRadius: '50%',
                  zIndex: 20,
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => {
                  setSelected(index);
                  setIsOpen(true);
                  setCurrent(index);
                }}
              >
                {selected === index ? (
                  <TiStarFullOutline color="yellow" size={24} />
                ) : null}
                {userHistory?.find((item) => item.tourId === tour.id) &&
                selected !== index ? (
                  <FaCheck size={24} color="green" />
                ) : null}
              </div>
            </Popover>
          )
        )}

        
      </div>

      {isOpen ? (
        <ModalStartTour
          backgroundImage={data?.tours?.[current].backgroundImage ?? ''}
          title={data?.tours?.[current].name ?? ''}
          onClose={() => setIsOpen(false)}
          onStart={() => {
            setIsOpen(false);
            onClose();
            history.push(`/?initialtour&step=${current}`);
          } }
        />
      ) : null}
    </>
  );
}

export default TourMap;
