import styled from 'styled-components'
// import Pagination from "./pagination";
import { TableComponent } from './TableComponent'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { Column } from 'react-table'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu'

import useSelectableColumn from './useSelectableColumn'
import { TableProps } from '@chakra-ui/table'
import { FaEllipsisH } from 'react-icons/fa'
import { blue } from 'core/resources/theme/colors'
import { Empty, Pagination, Skeleton } from 'antd'

const Styles = styled.div`
  background-color: white;
  /* box-shadow: 0 0 16px 3px rgba(0, 0, 0, 0.05); */
  /* border-radius: 10px; */
  overflow: hidden;
  width: 100%;

  tbody td {
    font-size: 0.9em;
  }
`

const HeaderStyle = styled.div`
  padding: 16px;
`

function Table<T = any>({
  columns,
  data,
  header,
  loading,
  onClickRow,
  actions,
  enableSelected,
  onSelected,
  tableProps,
  pagination,
  checkEdit,
  id

}: {
  columns: Column[]
  data: T[] | undefined
  header?: any
  loading?: boolean
  onClickRow?: (item: T) => void
  actions?: {
    title: any
    icon?: any
    viewMode?:boolean
    onClick: (item: T) => any
  }[]
  enableSelected?: boolean
  onSelected?: (items: any[]) => void
  tableProps?: TableProps
  pagination?: {
    page: number
    total: number
    perPage: number
    onChangePage: (p: number) => void
  }
  checkEdit?: (item: T) => any
  id?:string
}) {
  const { column: selectableColumn } = useSelectableColumn<any>(
    data,
    onSelected
  )

  let _columns: Column[] = []

  if (actions?.length) {
    _columns = [
      enableSelected ? selectableColumn : undefined,
      ...columns,
      {
        Header: '',
        accessor: 'menu',
        width: 80,
        maxWidth: 80,
        minWidth: 80,
        disableClick: true,
        Cell: ({ row: { original } }: { row: { original: T } }) => {
          return (
            <Flex justifyContent="flex-end">
              <Menu placement="left-start">
                <MenuButton>
                  <FaEllipsisH color={blue} />
                </MenuButton>
                <MenuList>
                  {actions.map((action, index) => (
                    <MenuItem
                      key={index}
                      fontSize="14px"
                      style={
                        checkEdit ?
                        !(checkEdit && checkEdit(original)) && !action.viewMode
                          ? { color: 'lightgrey', cursor: 'not-allowed' }
                          : undefined : undefined
                      }

                      onClick={() => checkEdit ? (checkEdit && checkEdit(original)) || action.viewMode  ? action.onClick(original) : null : action.onClick(original) }
                    >
                      {action.icon ? (
                        <Text mr="4px">
                          {typeof action.icon === 'function'
                            ? action.icon(original)
                            : action.icon}
                        </Text>
                      ) : null}

                      {typeof action.title === 'function'
                        ? action.title(original)
                        : action.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Flex>
          )
        },
      } as any,
    ].filter((item: any) => item)
  } else {
    _columns = (
      [enableSelected ? selectableColumn : undefined, ...columns] as any
    ).filter((item: any) => item)
  }

  return (
    <Styles>
      {typeof header === 'function' ? (
        <HeaderStyle>{header()}</HeaderStyle>
      ) : null}

      <Box pb="16px" id={id}>
        <TableComponent
          {...tableProps}
          onClickRow={
            typeof onClickRow === 'function' && data
              ? (index: number) => {
                  onClickRow(data[index])
                }
              : undefined
          }
          columns={_columns}
          data={loading ? [] : data ?? []}
        />
      </Box>

      {loading ? (
        <Flex justifyContent="center" alignItems="center">
          <Skeleton active />
        </Flex>
      ) : null}

      {!loading && !data?.length ? (
        <Box my="32px">
          <Empty />
        </Box>
      ) : null}

      {pagination && pagination.total ? (
        <Flex justifyContent="flex-end">
          <Pagination
            style={{ margin: 16 }}
            current={pagination.page ?? 1}
            total={pagination.total ?? 0}
            pageSize={pagination.perPage ?? 0}
            onChange={(page) => {
              pagination.onChangePage(page)
            }}
          />
        </Flex>
      ) : null}
    </Styles>
  )
}

export default Table
