import { useQuery } from "react-query";
import axios from "infra/http";
import { ActivityInfo } from "core/features/students/typings";

function useActivityInfo(id?: string) {
  const fetchActivity = async (id: string): Promise<ActivityInfo> => {
    const { data } = await axios.get<ActivityInfo>(`/v2/activities/info`,{
      params: {
        activityId: id,
      },
    });

    return data;
  };

  return useQuery([`/v2/activities/info?activityId=${id}`, id], () => fetchActivity(id!), {
    enabled: !!id,
  });

}

export default useActivityInfo;
