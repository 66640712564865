import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

function StackedGraph({
  categories,
  series,
  colors,
}: {
  categories: string[];
  series: ApexAxisChartSeries;
  colors?: string[];
}) {
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        borderRadiusApplication: 'end', // 'around', 'end'
        borderRadiusWhenStacked: 'last', // 'all', 'last'
        dataLabels: {
          total: {
            enabled: false,
          },
        },
      },
    },
    yaxis: {
      max: 100,
    },
    xaxis: {
      type: 'category',
      categories: categories,
    },
    legend: {
      position: 'top',
    },
    fill: {
      opacity: 1,
    },
    colors: colors,
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />
  );
}

export default StackedGraph;
