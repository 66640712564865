import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Tabs, Typography, Form } from 'antd';
import Layout from 'components/layout';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useHistory, useParams } from 'react-router-dom';
import GeneralDataTab from './Tabs/GeneralDataTab';
import PedagogicalTeam from './Tabs/PedagogicalTeamTab';
import StudentsTab from './Tabs/StudentsTab';
import { useEffect, useState } from 'react';
import { useSaveClassV2 } from 'core/features/classes/hooks/useSaveClassV2';
import useAllClassesDetails from 'core/features/classes/hooks/useAllClassesDetails';
import { IClassInfo } from '../NewClass';
import useSeries from 'core/features/series/hooks/useSeries';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import CustomizedRequiredMark from 'components/required-mark';
import BackButton from 'components/buttons/back-button';
import Button from 'components/buttons/button';
import { NumberParam, useQueryParam } from 'use-query-params';
import { t } from 'core/resources/strings';

function ClassReenroll() {
  const { Title } = Typography;
  const history = useHistory();
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isSmallMobile] = useMediaQuery('(max-width: 380px)');
  const [step, setStep] = useQueryParam('tab', NumberParam);
  const [form] = Form.useForm();
  const [isFirstPartComplete, setIsFirstPartComplete] =
    useState<boolean>(false);
  const { mutate: handleClassSave, isLoading } = useSaveClassV2();
  const { id, tenantId } = useParams<{ id: string; tenantId: string }>();
  const { data: allSeries } = useSeries();
  const { data } = useAllClassesDetails(id);
  const name = Form.useWatch('name', form);

  if (!step) {
    setStep(1);
  }

  const checkIsFirstPartComplete = () => {
    const isComplete =
      form.isFieldTouched('period') &&
      form.isFieldTouched('initialAvaliation') &&
      name !== '' &&
      name !== undefined;
    if (isComplete) {
      setIsFirstPartComplete(true);
    } else {
      setIsFirstPartComplete(false);
    }
  };

  const actionButtons = (
    <Flex
      gap="16px"
      style={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'end',
        marginBottom: isMobile ? '8px' : '16px',
        marginTop: isMobile ? '8px' : '16px',
      }}
    >
      <Button
        variant="outline"
        size="sm"
        onClick={() => handleBack()}
        id="id-tour-reenroll-class-button-cancel"
      >
        {t('app.cancelar')}
      </Button>
      <Button
        size="sm"
        htmlType="submit"
        id="id-tour-reenroll-class-button-save"
        loading={isLoading}
      >
        {step === 3 ? 'FINALIZAR' : 'SALVAR'}
      </Button>
    </Flex>
  );
  const items = [
    {
      key: '1',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 1 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          {t('app.dadosGerais').toUpperCase()}
        </p>
      ),
      children: (
        <Flex vertical>
          {actionButtons}
          <GeneralDataTab />
        </Flex>
      ),
      forceRender: true,
    },
    {
      key: '2',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 2 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          {isSmallMobile ? (
            <p style={{ textAlign: 'center' }}>
              {t('app.equipe').toUpperCase()} <br />{' '}
              {t('app.pedagogica').toUpperCase()}
            </p>
          ) : (
            t('app.equipePedagogica').toUpperCase()
          )}
        </p>
      ),
      children: (
        <Flex vertical>
          {actionButtons}
          <PedagogicalTeam />
        </Flex>
      ),
      forceRender: true,
      disabled: !isFirstPartComplete,
    },
    {
      key: '3',
      label: (
        <p
          style={{
            fontSize: isMobile ? '14px' : '24px',
            fontWeight: 700,
            color: 3 !== step ? colorGreyBorderAndFont : '',
          }}
        >
          {t('app.estudantes').toUpperCase()}
        </p>
      ),
      children: (
        <Flex vertical>
          {actionButtons}
          <StudentsTab />
        </Flex>
      ),
      forceRender: true,
      disabled: !isFirstPartComplete,
    },
  ];

  const handleBack = () => {
    if (form.isFieldsTouched()) {
      openNotification({ onClose: handleOk, colorPrimary, isMobile });
    } else {
      handleOk();
    }
  };

  const getNextSerie = () => {
    if (allSeries) {
      const currentSerieIndex = allSeries?.findIndex(
        (serie) => serie.id === data?.serie.id
      );
      if (currentSerieIndex === allSeries.length - 1) {
        return allSeries[currentSerieIndex].id;
      }
      return allSeries[currentSerieIndex + 1].id;
    }
  };

  const saveClass = (values: IClassInfo) => {
    handleClassSave(
      {
        ...values,
        tenantId,
      },
      {
        onSuccess: () => {
          notificateSucess(t('app.rematriculaEfetuadaComSucesso'));
          handleOk();
        },
        onError: (err: any) => {
          notificateError(
            t('app.mensagemDeErroRematricula')
          );
        },
      }
    );
  };

  const handleOk = () => {
    if (tenantId) {
      history.push(`/schools/${tenantId}/classes`);
    } else {
      history.push('/my-school/classes');
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  return (
    <Layout.Container>
      <Flex gap="23px">
        <BackButton onClick={handleBack} />
        <Title
          level={1}
          style={{
            color: colorPrimary,
            fontSize: isMobile ? '24px' : '28px',
            fontWeight: 700,
            margin: '0px',
          }}
        >
          {t('app.turmas').toUpperCase()}
        </Title>
      </Flex>
      <Form
        onFinish={saveClass}
        layout="vertical"
        onFieldsChange={() => checkIsFirstPartComplete()}
        form={form}
        initialValues={{
          year: data ? data.year + 1 : undefined,
          serieId: data ? getNextSerie() : undefined,
          students: data ? data.students.map((item) => item.id) : [],
          collaborators: data
            ? data.teacherClasses.map((item) => item.user.id)
            : [],
          initialAvaliation: undefined,
        }}
        requiredMark={CustomizedRequiredMark}
      >
        <Tabs
          centered
          style={{ height: '80%' }}
          onTabClick={(event) => setStep(Number(event))}
          size="large"
          defaultActiveKey="1"
          activeKey={step?.toString()}
          items={items}
        />
      </Form>
    </Layout.Container>
  );
}

export default ClassReenroll;
