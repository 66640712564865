import { useMediaQuery } from '@chakra-ui/media-query';
import { Anchor, Button, Col, Divider, Flex, Row } from 'antd';
import Layout from 'components/layout';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import Text from 'components/typography/text';
import SubTitle from 'components/typography/subTitle';
import styles from './style.module.css';
import useHelper from 'core/features/helpers/hooks/useHelper';
import YouTube from 'react-youtube';
import BackButton from 'components/buttons/back-button';
import Title from 'components/typography/title';
import { HiArrowNarrowRight } from 'react-icons/hi';
import useHelpers from 'core/features/helpers/hooks/useHelpers';
import HelperFeedback from '../components/HelperFeedback';
import { HoverItem } from 'components/hover-item/hover';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import LinkBox from 'components/link-box';
import cosmo from 'assets/images/cosmo-with-paper-and-pen.png';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { Permissions } from 'core/resources/enums/permissions';

interface AnchorItem {
  key: string;
  href: string;
  title: string;
  children?: AnchorItem[];
}

function HelperPost() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();
  const { colorGreyBorderAndFont } = useU4heroColors();
  const params = useParams<{ id: string }>();
  const { data } = useHelper(params.id);
  const { colorPrimary } = useU4heroColors();
  const { data: helpers, isLoading } = useHelpers({
    page: 1,
    limit: 4,
    subClassifications: [data?.subClassification as string],
  });

  const extractHeadings = (htmlContent: string): AnchorItem[] => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const headings = Array.from(doc.querySelectorAll('h1, h2'));
    const items: AnchorItem[] = [];

    let currentH1: AnchorItem | null = null;

    headings.forEach((heading, index) => {
      if (heading.tagName === 'H1') {
        currentH1 = {
          key: `h1-${index + 1}`,
          href: `#${heading.id}`,
          title: heading.textContent || '',
          children: [],
        };
        items.push(currentH1);
      } else if (currentH1 && heading.tagName === 'H2') {
        currentH1.children?.push({
          key: `h2-${index + 1}`,
          href: `#${heading.id}`,
          title: heading.textContent || '',
        });
      }
    });

    return items;
  };

  const anchorItems = data ? extractHeadings(data.md) : [];
  const [check] = useCheckPermissions();
  const isU4Hero = check([Permissions.U4hero.Helpers.View]);

  const re =
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
  const videoUrl = data?.videoUrl;
  const videoId = videoUrl ? re.exec(videoUrl) : undefined;

  return (
    <Layout.Container>
      <Flex vertical gap={isMobile ? '8px' : '16px'}>
        <Flex gap={isMobile ? '12px' : '24px'} align="center">
          <BackButton onClick={() => history.goBack()} />
          <Title primary>{data?.title}</Title>
        </Flex>
        {videoId && (
          <YouTube
            style={{ width: '100%' }}
            videoId={videoId[1]}
            opts={{ width: '100%', height: isMobile ? 160 : 381 }}
          />
        )}

        <HoverItem
          style={{
            bordeRadius: isMobile ? '10px' : '16px',
          }}
          background="#E9E9E9"
          hover={hex2rgba(colorPrimary, 0.3)}
        >
          <Flex
            justify="space-between"
            style={{
              padding: isMobile ? '8px' : '16px',
            }}
          >
            <Text color="black" size="lg">
              Inicie o tour explicativo dessa tela
            </Text>{' '}
            <HiArrowNarrowRight color={'black'} size={isMobile ? 20 : 28} />
          </Flex>
        </HoverItem>

        <Row justify="end" style={{ marginBottom: '16px' }}>
          <Col
            span={isMobile ? 24 : 19}
            style={{ paddingRight: isMobile ? '0px' : '65px' }}
          >
            <Flex vertical gap="16px">
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{
                  __html: data?.md ?? '',
                }}
              />
              <Text
                style={{
                  color: colorGreyBorderAndFont,
                }}
                align="end"
                size="md"
              >
                {moment.utc(data?.createdAt).format('DD [de] MMMM [de] YYYY')}
                <br />
                Autor: {data?.author}
              </Text>
              {helpers && helpers?.data.length > 1 && !isU4Hero && (
                <LinkBox
                  title={'Conteúdos Relacionados'}
                  items={
                    helpers?.data
                      .filter((item) => item.id !== params.id)
                      .map((item) => ({
                        id: item.id,
                        title: item.title,
                        link: `/helpers/${item.id}`,
                      })) ?? []
                  }
                />
              )}
            </Flex>
          </Col>
          <Col span={isMobile ? 0 : 3}>
            <SubTitle primary>Conteúdo</SubTitle>
            <Anchor replace items={anchorItems} />
          </Col>

          <Col span={3}></Col>
        </Row>
        <HelperFeedback id={params.id}/>
        {isU4Hero ? (
          <>
            <SubTitle primary>Dados Preenchidos</SubTitle>
            <Flex
              justify="space-between"
              align="center"
              style={{
                width: '100%',
                background: 'rgba(244, 244, 244, 0.80)',
                padding: isMobile ? '8px' : '16px 27px',
                borderRadius: '20px',
                marginBottom: '24px',
                overflow: 'visible',
                height: '200px',
                position: 'relative',
              }}
            >
              <Flex vertical gap="8px">
                <Text size="lg" color="black">
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    Título:{' '}
                  </span>{' '}
                  {data?.title}
                </Text>
                <Text size="lg" color="black">
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    Palavras-Chaves:{' '}
                  </span>{' '}
                  {data?.keyword}
                </Text>
                <Text size="lg" color="black">
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    Classificação:{' '}
                  </span>{' '}
                  {data?.classification}/{data?.subClassification}
                </Text>
                <Text size="lg" color="black">
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    Vinculado à URL:{' '}
                  </span>{' '}
                  {data?.url}
                </Text>
                <Text size="lg" color="black">
                  <span style={{ color: colorPrimary, fontWeight: 700 }}>
                    Vinculado ao Tour:{' '}
                  </span>{' '}
                  --
                </Text>
              </Flex>
              <img
                src={cosmo}
                style={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  display: isMobile ? 'none' : 'block',
                }}
              />
            </Flex>
          </>
        ) : null}
      </Flex>
    </Layout.Container>
  );
}

export default HelperPost;
