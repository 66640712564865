import useSeries from 'core/features/series/hooks/useSeries';
import useAllClasses from 'core/features/classes/hooks/useAllClasses';
import { FilterProps, safeSpaceFlags } from 'infra/helpers/types';
import GenericFilterComponent from 'components/generic-filter';
import { t } from 'core/resources/strings';

const internalTypes = [
  {
    label: t('app.anonimo'),
    value: 'anonymous',
  },
  {
    label: t('app.lidas'),
    value: 'seen',
  },
  {
    label: t('app.naoLidas'),
    value: 'unseen',
  },
];
const status = [
  {
    value: 0,
    label: t('app.naoIniciado'),
  },
  {
    value: 1,
    label: t('app.emAndamento'),
  },
  {
    value: 2,
    label: t('app.concluido'),
  },
];
function SafeSpaceFilter({ filters, onChangeFilters }: FilterProps) {
  const { data } = useSeries();
  const { data: dataClasses } = useAllClasses({});

  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: t('app.tipo'),
          filterName: 'types',
          options: internalTypes,
        },
        {
          title: t('app.classificacao'),
          filterName: 'flags',
          options: safeSpaceFlags,
          type: 'number',
        },
        {
          title: t('app.serie'),
          filterName: 'series',
          options:
            data?.map((item) => {
              return { label: item.name, value: item.id };
            }) ?? [],
        },
        {
          title: t('app.turma'),
          filterName: 'classes',
          options:
            dataClasses?.data.map((item) => {
              return {
                label:
                  `${item.name} - ${item.serie.name}`.length > 20
                    ? `${`${item.name} - ${item.serie.name}`.substr(0, 17)}...`
                    : `${item.name} - ${item.serie.name}`,
                value: item.id,
              };
            }) ?? [],
        },
        {
          title: t('app.status'),
          filterName: 'status',
          options: status,
          type: 'number',
        },
      ]}
    />
  );
}

export default SafeSpaceFilter;
