import React, { FC } from 'react';
import './style.css';

interface StarProps extends React.HTMLAttributes<HTMLDivElement> {
  color: string;
  size: string;
  fontSize: string;
}

const Star: FC<StarProps> = ({ color, size, fontSize, children, ...rest }) => {
  const style: React.CSSProperties = {
    backgroundColor: color,
    fontSize: fontSize,
    height: size,
    width: size,
  };

  return (
    <div className="star" style={style} {...rest}>
      <div className="star-content">
        <p>{children}</p>
      </div>
    </div>
  );
};

export default Star;
