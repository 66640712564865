import { useQuery } from 'react-query';
import axios from 'infra/http';
import { StudentItem } from '../typings';
import { useEffect, useState } from 'react';

export interface UseStudentsParams {
  page: number;
  limit?: number;
  search?: string;
  classId?: string;
  disableConcat?: boolean;
  disableQuery?: boolean;
  series?: string[];
  classes?: string[];
  tenantId?: string;
  deleted?: boolean;
  active?: boolean;
  all?: boolean;
  lastAccess?:string;

}

function useStudentsV2(params: UseStudentsParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchStudents = async (
    params: UseStudentsParams
  ): Promise<{ data: StudentItem[]; total: number }> => {
    const { data, headers } = await axios.get<StudentItem[]>(`/v2/student`, {
      params: {
        page: params.page,
        limit: params.limit,
        search: debouncedQuery,
        classId: params.classId,
        series: params.series,
        classes: params.classes,
        tenantId: params.tenantId,
        deleted: params.deleted,
        active: params.active,
        all: params.all,
        lastAccess:params.lastAccess
      },
    });
    return { data: data, total: Number(headers['total']) };
  };

  return useQuery(
    [`/v2/student`, { ...params, search: debouncedQuery }],
    () => fetchStudents(params),
    {
      keepPreviousData: true,
      enabled: !params.disableQuery,
    }
  );
}

export default useStudentsV2;
