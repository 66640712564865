import { Select } from 'antd';
import useTenants from 'core/features/tenants/hooks/useTenants';

function SelectOrganization({
  value,
  onChange,
  id,
}: {
  value: string;
  onChange: (v: string) => void;
  id?: string;
}) {
  const { isLoading, isFetching, data } = useTenants({
    page: 1,
    master: true,
    limit: 200,
  });

  return (
    <Select
      id={id}
      loading={isLoading || isFetching}
      placeholder="Selecione a Organização"
      value={value}
      onChange={(ev) => {
        onChange(ev);
      }}
      options={
        data?.data?.map((item) => ({
          label: item.name,
          value: item.id,
        })) ?? []
      }
    />
  );
}

export default SelectOrganization;
