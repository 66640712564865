import { useQuery } from "react-query";
import axios from "infra/http";
import { useEffect,useState } from "react";
import { SafeSpaceItem } from "../typings";

export interface SafeSpaceParams {
  page: number;
  limit?: number;
  search?: string;
  anonymous?:boolean;
  unseen?:boolean;
  seen?:boolean;
  classId?: string;
  userId?: string;
  disableConcat?: boolean;
  disableQuery?: boolean;
  series?: string[];
  classes?: string[];
  flags?: number[];
  status?: number[];
  initialDate?:string;
  endDate?:string;
  tenantId?:string;
}

function useSafespaces(params: SafeSpaceParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchMessages = async (
    params: SafeSpaceParams
  ): Promise<{ data: SafeSpaceItem[]; total: number }> => {
    const { data, headers } = await axios.get<SafeSpaceItem[]>(`/v2/safeSpace/`, {
      params: {
        ...params,
        search: debouncedQuery,
      },
    });

 

    return { data: data, total: Number(headers["total"]) };
  };

  return useQuery(
    [
      `/v2/safeSpace/`,
      { ...params, search: debouncedQuery },
    ],
    () => fetchMessages(params),
    {
      keepPreviousData: true,
      enabled: !params.disableQuery,
    }
  );
}

export default useSafespaces;
