import http from "infra/http";
import { useMutation } from "react-query";
import { IndicatorsProfileForm } from "../typings";


export function useSaveIndicatorsProfile() {
  return useMutation(
    (data: IndicatorsProfileForm) => {
      if (data.id) {
        return http.put(`/v2/indicators-profile/${data.id}`, data);
      }
      return http.post(`/v2/indicators-profile`, data);
    },
    { retry: false }
  );
}
