import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Modal, Pagination, Row, Spin } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import defaultImg from 'assets/images/default-profile.png';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import useEmotions from 'core/features/graphs/hooks/useEmotions';
import dayjs from 'dayjs';
import { t } from 'core/resources/strings';

function ModalEmotions({
  onClose,
  emotion,
  initialDate,
  endDate,
}: {
  emotion?: {
    color: string;
    name: string;
    img: string;
    id: string;
  };
  initialDate?: string;
  endDate?: string;
  onClose: () => void;
}) {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);

  const { data: emotions, isLoading: isLoadingEmotions } = useEmotions({
    page: page,
    pageSize: pageSize,
    initialDate: initialDate
      ? dayjs(initialDate).format('YYYY-MM-DD')
      : undefined,
    endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
    optionIds: emotion?.id ? [emotion?.id] : [],
  });

  return (
    <Modal
      title={
        <SubTitle primary style={{ maxWidth: isMobile ? '238px' : undefined }}>
          {t('app.detalhamentoDeEstudantesPorEmoções')} - {emotion?.name}
        </SubTitle>
      }
      open={true}
      onCancel={onClose}
      footer={null}
      width={isMobile ? '95%' : 1091}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      {isLoadingEmotions ? (
        <Flex justify="center">
          <Spin size="large" />
        </Flex>
      ) : (
        <Row gutter={[18, 18]} style={{ marginTop: 16 }}>
          {emotions?.data?.map((emotion, key) => (
            <Col xs={24} md={8} key={key}>
              <Flex
                className="box-shadow-generic-card"
                style={{ padding: '16px 18px', position: 'relative' }}
                gap={'12px'}
              >
                <img
                  src={emotion?.user?.imageUrl ?? defaultImg}
                  width={isMobile ? 92 : 102}
                  height={isMobile ? 92 : 102}
                />
                <Flex vertical gap="16px">
                  <Text size="md">{emotion?.user?.name}</Text>
                  <Text size="md">
                    {emotion?.class?.serie?.name} - {emotion?.class?.name}
                  </Text>
                </Flex>
                <Text
                  size="sm"
                  style={{ position: 'absolute', bottom: 4, right: 16 }}
                  color={colorGreyBorderAndFont}
                >
                  {dayjs(emotion.createdAt).format('DD/MM/YYYY')}
                </Text>
              </Flex>
            </Col>
          ))}
        </Row>
      )}

      <Flex justify="center">
        <Pagination
          style={{ margin: 16 }}
          current={page ?? 1}
          total={emotions?.total ?? 0}
          pageSize={pageSize ?? 0}
          onChange={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />
      </Flex>
    </Modal>
  );
}

export default ModalEmotions;
