import { useMutation } from "react-query";
import http from "infra/http";
import { TenantForm } from "../typings/form";

export function useUpdateCurrentTenant(id:string) {
  return useMutation(
    (data: TenantForm) => {
      delete (data as any).id;
      delete (data as any).active;
      delete (data as any).master;
      delete (data as any).profile;
      return http.put(`/v2/tenants/${id}`, data);
    },
    { retry: false }
  );
}
