import { useMediaQuery } from '@chakra-ui/media-query';
import { Col, Divider, Flex, Modal, Row, Spin } from 'antd';
import Button from 'components/buttons/button';
import GamePicture from 'components/game-picture';
import useMetricsGame from 'core/features/metrics/hooks/useMetricsGame';
import { primary } from 'core/resources/theme/colors';
import { Pluralizar } from 'infra/helpers/plural';
import { useParams } from 'react-router-dom';

import './styles.css';

function ModalGameMetrics({
  onClose,
  collaboratorId,
  startDate,
  endDate,
}: {
  onClose: () => void;
  collaboratorId?: string;
  startDate?: string;
  endDate?: string;
}) {
  const params = useParams<{ id: string }>();
  const { data, isLoading } = useMetricsGame({
    tenantId: params.id,
    collaboratorId: collaboratorId ?? undefined,
    startDate:startDate ?? undefined,
    endDate:endDate ?? undefined,
  });
  const [isMobile] = useMediaQuery('(max-width: 768px');

  return (
    <Modal
      open={true}
      onCancel={onClose}
      footer={null}
      modalRender={(node) => (
        <div
          style={{
            backgroundImage:
              'url("/background-metrics-modal-bottom.png"),url("/background-metrics-modal-top.png")',
            backgroundSize: '50%, 32%',
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundPosition: 'bottom left,top right',
            backgroundColor: 'white',
          }}
          className={'modal-with-background'}
        >
          {node}
        </div>
      )}
      width={'888px'}
      closeIcon={null}
    >
      <div
        style={{
          height: 'auto',
          borderRadius: '20px',
          background: '#FFF',
          padding: isMobile ? '8px' : '16px 24px',
          boxShadow:
            '0px 3px 4px 0px rgba(0, 0, 0, 0.25), 0px -0.5px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        <p
          style={{
            color: primary,
            fontSize: isMobile ? '16px' : '20px',
            fontWeight: 700,
            marginBottom: isMobile ? '8px' : '16px',
          }}
        >
          Registro de Jogos e Frequência de Uso
        </p>
        {!isLoading ? (
          <Row>
            {data?.data.map((item, index) => (
              <Col
                span={isMobile ? 12 : 6}
                style={{ border: '1px solid #C4C4C4' }}
              >
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  gap={isMobile ? 4 : 8}
                  style={{ minWidth: isMobile ? '85px' : '156px' }}
                >
                  <p
                    style={{
                      fontSize: isMobile ? '14px' : '20px',
                      color: 'black',
                      textAlign: 'center',
                      fontWeight: 500,
                    }}
                  >
                    {item.name['pt-br'].length > 17
                      ? item.name['pt-br'].substr(0, 14) + '...'
                      : item.name['pt-br']}
                  </p>
                  <GamePicture
                    size={isMobile ? 'lg' : 'xl'}
                    url={item?.coverImageUrl}
                    name={item.name['pt-br']}
                  />
                  <p
                    style={{
                      fontSize: isMobile ? '14px' : '20px',
                      color: 'black',
                      textAlign: 'center',
                      fontWeight: 500,
                    }}
                  >
                    {item.count} {Pluralizar('vez',item.count > 1)}
                  </p>
                </Flex>
              </Col>
            ))}
          </Row>
        ) : (
          <Flex justify="center" align="center">
            <Spin size={'large'} />
          </Flex>
        )}

          <Divider style={{ margin: '10px 0' }} />
        <Flex justify='center'>
          <Button size='md' onClick={onClose} >Fechar</Button>
        </Flex>
      </div>
    </Modal>
  );
}

export default ModalGameMetrics;
