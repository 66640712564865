import { useState } from 'react';
import { notificateError } from 'infra/helpers/notifications';
import { useSaveHelperFeedback } from 'core/features/helpers/hooks/useSaveHelperFeedback';
import { HelperFeedbackForm } from 'core/features/helpers/typings';
import Feedback from 'components/feedback';

function HelperFeedback({id}:{id:string}) {
  const [submited, setSubmited] = useState(false);
  const { mutate: handleSave,isLoading } = useSaveHelperFeedback();

  const onSubmit = (values: HelperFeedbackForm) => {
    handleSave(
      { ...values, helperId: id },
      {
        onSuccess: () => {
          setSubmited(true);
        },
        onError: (err: any) => {
          notificateError(
            'Ops, aconteceu algum problema ao salvar seu feedback, tente novamente!'
          );
        },
      }
    );
  };
  return (
    <Feedback
    onSubmit={onSubmit}
    askMessage="Como você avalia essa publicação de ajuda?"
    submited={submited}
    isLoadingSubmit={isLoading}
  />
  );
}

export default HelperFeedback;
