import { Flex, Text } from "@chakra-ui/layout";

const steps = [
  { label: "Seu plano" },
  { label: "Histórico de Pagamentos" },
  { label: "Administração" },
];

function TabHeader({
  step,
  onChange,
}: {
  step: number;
  onChange: (v: number) => void;
}) {
  return (
    <Flex
      mt="48px"
      gridGap="32px"
      flexDir="row"
      borderBottom={"1px solid #ddd"}
    >
      {steps.map((item, index) => (
        <Flex justifyContent="center" key={index}>
          <Flex
            position="relative"
            bottom="-1px"
            borderBottomWidth="4px"
            borderBottomStyle="solid"
            borderBottomColor={step === index ? "blue" : "transparent"}
            onClick={() => onChange(index)}
            py="4px"
            cursor="pointer"
          >
            <Text
              fontWeight={step === index ? "bold" : "normal"}
              color={step === index ? "blue" : "#222"}
            >
              {item.label}
            </Text>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
}

export default TabHeader;
