import { useQuery } from 'react-query';
import axios from 'infra/http';
import { IStudentInfo } from '../typings';

function useStudentInfo(id: string) {
  return useQuery(`/v2/student/${id}`, async (): Promise<IStudentInfo> => {
    const { data } = await axios.get<IStudentInfo>(`/v2/student/${id}`);
    return data;
  });
}

export default useStudentInfo;
