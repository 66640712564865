import useAnalysisTypes from 'core/features/questions/hooks/useAnalysisTypes';
import { t } from 'core/resources/strings';
import { FilterProps } from 'infra/helpers/types';
import GenericFilterComponent from 'components/generic-filter';
import useEnvironments from 'core/features/questions/hooks/useEnvironments';
import useQuestionsAuthors from 'core/features/questions/hooks/useQuestionsAuthors';
import useQuestionTypes from 'core/features/questions/hooks/useQuestionTypes';
import useTagsCompetence from 'core/features/tags/hooks/useTagsCompetence';

function QuestionFilter({ filters, onChangeFilters }: FilterProps) {
  const analysisTypes = useAnalysisTypes();
  const environments = useEnvironments();
  const authors = useQuestionsAuthors();
  const questionTypes = useQuestionTypes();
  const { data: dataCompetences } = useTagsCompetence({
    competence: true,
    disableQuery: !filters.hasOwnProperty('tags'),
  });
  const { data: dataSkills } = useTagsCompetence({
    skill: true,
    disableQuery: !filters.hasOwnProperty('tags'),
  });

  const privacyTypes = [
    {
      id: 0,
      name: t('app.privada'),
    },
    {
      id: 1,
      name: t('app.publica'),
    },
    {
      id: 2,
      name: t('app.global'),
    },
  ];
  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: t('app.competenciaHabilidade'),
          filterName: 'tags',
          options: [],
          children:
            dataCompetences?.map((competence) => {
              return {
                title: competence.name['pt-br'],
                options:
                  dataSkills
                    ?.filter((item) => item.parentId === competence.id)
                    .map((skill) => {
                      return {
                        label:
                          skill.name['pt-br'].length > 26
                            ? skill.name['pt-br'].substr(0, 23) + '...'
                            : skill.name['pt-br'],
                        value: skill.id,
                      };
                    }) ?? [],
              };
            }) ?? [],
        },
        {
          title: t('app.autor'),
          filterName: 'authors',
          options:
            authors.data?.map((item) => {
              return { label: item.name, value: item.id };
            }) ?? [],
        },
        {
          title: t('app.ambienteAnalise'),
          filterName: 'environments',
          options:
            environments?.data?.map((item) => {
              return { label: t(`app.${item.name}`), value: item.id };
            }) ?? [],
          type: 'number',
        },
        {
          title: t('app.tipoAnalise'),
          filterName: 'analysisTypes',
          options:
            analysisTypes?.data?.map((item) => {
              return {
                label: t(`app.${item.name}`),
                value: item.id.toString(),
              };
            }) ?? [],
        },
        {
          title: t('app.tipoPergunta'),
          filterName: 'questionTypes',
          options:
            questionTypes?.data?.map((item) => {
              return { label: item.name, value: item.id.toString() };
            }) ?? [],
        },
        {
          title: t('app.escopo'),
          filterName: 'scopes',
          options:
            privacyTypes?.map((item) => {
              return { label: item.name, value: item.id.toString() };
            }) ?? [],
        },
        {
          title: t('app.faixaEtaria'),
          filterName: 'age',
          open: true,
          type: 'range',
          options: [
            { label: t('app.idadeMinima'), value: undefined },
            { label: t('app.idadeMaxima'), value: undefined },
          ],
        },
      ]}
    />
  );
}

export default QuestionFilter;
