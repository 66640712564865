import Avatar from 'components/avatar';
import { Card, Flex, Typography, Button } from 'antd';
import EyeOutlined from '@ant-design/icons/lib/icons/EyeOutlined';

const { Text } = Typography;

function CardMobile({
  url,
  name,
  onClick,
  onClickButton,
}: {
  url?: string;
  name: string;
  onClick?: () => void;
  onClickButton?: () => void;
}) {

  return (
    <Card hoverable onClick={onClick} style={{ boxShadow: '0px 3px 4px 0px #00000040, 0px -0.5px 4px 0px #00000040' }}>
      <Flex align='center' justify='space-between'>
        <Avatar url={url} name={name} type='secondary' />
        <Text strong
          style={{
            marginLeft: '16px',
          }}
        >
          {name}
        </Text>
        <Button
          type="primary"
          size='large'
          icon={<EyeOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            if (onClickButton) {
              onClickButton();
            }
          }}>
        </Button>
      </Flex>
    </Card>
  );
}

export default CardMobile;
