import { Flex, Modal } from 'antd';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import stars from 'assets/images/estrelas_modal.png';
import cosmoWithBinoculars from 'assets/images/cosmo-with-binoculars.png';
import rotoriWithBinoculars from 'assets/images/rotori-with-binoculars.png';
import { useHistory } from 'react-router';
import { t } from 'core/resources/strings';

function ModalFinishTourLater({ onClose }: { onClose: () => void }) {
  const { colorPrimary } = useU4heroColors();
  const history = useHistory();
  return (
    <Modal
      centered
      maskClosable={false}
      open={true}
      footer={null}
      closeIcon={null}
      wrapClassName="custom-modal-initial-tour"
    >
      <div
        style={{ height: '420px', overflow: 'hidden', position: 'relative' }}
      >
        <img src={stars} style={{ backgroundColor: colorPrimary }} />
        <div
          style={{
            width: '100%',
            height: '400px',
            borderRadius: '50% 50% 50% 49% / 42% 43% 0% 0%',
            background: 'white',
            marginTop: '-160px',
          }}
        ></div>

        <Flex
          justify="center"
          align="center"
          style={{
            position: 'absolute',
            top: 0,
            zIndex: 200,
            height: '100%',
            width: '100%',
          }}
        >
          <img src={cosmoWithBinoculars} />
        </Flex>

        <Flex
          justify="end"
          align="center"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 200,
            height: '100%',
            width: '100%',
          }}
        >
          <img src={rotoriWithBinoculars} />
        </Flex>
      </div>
      <Flex vertical style={{ padding: '0px 16px 16px 16px' }}>
        <SubTitle primary>{t('app.descubraMaisTarde')}</SubTitle>
        <Text size="lg" align="justify">
          {t('app.descubraMaisTexto')}{' '}
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              history.push('/helpers');
              onClose();
            }}
          >
            {t('app.centralDeAjuda')}
          </span>
          . {t('app.obrigadoPorFazerParteDaNossaComunidade')}
        </Text>
        <Flex
          vertical
          justify="center"
          align="center"
          gap="8px"
          style={{ marginTop: '12px' }}
        >
          <Button size="md" onClick={onClose}>
            {t('app.concluir')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default ModalFinishTourLater;
