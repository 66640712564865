import { useQuery } from "react-query";
import axios from "infra/http";
import { QuestionsDetails } from "../types/QuestionsDetails";

function useQuestion(id?: string) {
  const fetchData = async (id: string): Promise<QuestionsDetails> => {
    const { data } = await axios.get<QuestionsDetails>(`/v2/questions/${id}`);

    return data;
  };

  return useQuery([`/v2/questions/${id}`], () => fetchData(id!), {
    enabled: !!id,
  });
}

export default useQuestion;
