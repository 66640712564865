import http from "infra/http";
import { useMutation } from "react-query";

export function useEditTenantResources() {
	
  return useMutation(
    (data:{
      id:string,
      resources:string[]
    }) => {
        return http.put(`/v2/resources/tenant/${data.id}`, data);
    },
    { retry: false }
  );
}
