import { useQuery } from "react-query";
import axios from "infra/http";
import { IAnnotation } from "../types";

function useAnnotations(id:string) {
  return useQuery('/v2/inkluzen/annotations', async ():Promise<IAnnotation[]> => {
    const { data } = await axios.get<IAnnotation[]>('/v2/inkluzen/annotations');
    return data;
  }, {
    enabled: !!id
  });

}

export default useAnnotations;