import { useMutation } from 'react-query';
import http from 'infra/http';

function useToggle2Fa() {
  return useMutation(
    (activate: boolean) => {
      return http.put(`/v1/user/2fa/${activate ? 'activate' : 'deactivate'}`);
    },
    { retry: false }
  );
}
export default useToggle2Fa;
