import { Box } from "@chakra-ui/layout";
import Layout from "components/layout";
import Heading from "components/text/heading";
import { useState } from "react";

import TabHeader from "./components/TabHeader";

function SettingsPage() {
  const [step, setStep] = useState(0);

  return (
    <Layout.Container>
      <Box>
        <Heading mb="16px" fontSize="2xl">
          Configurações
        </Heading>

        <Box mt="32px">
          <TabHeader step={step} onChange={(p) => setStep(p)} />
        </Box>
      </Box>
    </Layout.Container>
  );
}

export default SettingsPage;
