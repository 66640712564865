import { Button, DatePicker, DatePickerProps, Drawer, Flex, Modal, Select, Dropdown, Menu, Spin } from "antd";
import { Input } from "antd";
import useStudentInfo from "core/features/inkluzen/hooks/useStudent";
import { ChangeEvent, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs, { Dayjs } from 'dayjs';
import useAnnotations from "core/features/inkluzen/hooks/useAnnotations";
import lumiProfile from "assets/images/lumi-profile.png";
import { useCreateAnnotation } from "core/features/inkluzen/hooks/useAnnotation";
import { useCurrentUser } from "core/features/user/store";
import { showError, showSuccess } from "infra/helpers/alerta";
import useU4heroColors from "infra/packages/antd/u4heroTokens";
import FilterOutlined from "@ant-design/icons/lib/icons/FilterOutlined";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import ExpansibleCommentCard from '../../../../../components/V2/expansible-comment-card';
interface RouteParams {
  id: string;
}

function AnotationDrawer({
  onClose,
  pageType,
}: {
  onClose: (emptyForm: () => void) => void;
  pageType: string;
}) {
  const { id } = useParams<RouteParams>();
  const { TextArea } = Input;
  const student = useStudentInfo(id);
  const [selectValue, setSelectValue] = useState('Tipo de Anotação');
  const [annotation, setAnnotation] = useState('');
  const [date, setDate] = useState<Dayjs | null>(dayjs());
  const [annotationTypeSelected, setAnnotationTypeSelected] = useState(false);
  const [annotationWriten, setAnnotationWriten] = useState(false);
  const { user } = useCurrentUser();
  const { mutate: handleSave } = useCreateAnnotation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { colorPrimary } = useU4heroColors();
  const { data, refetch } = useAnnotations(id);
  const [selected, setSelected] = useState(pageType);
  const [filter, setFilter] = useState<string | null>(null);
  const [savedWithoutInformation, setSavedWithoutInformation] = useState(false);


  const setTitle = () => (
    <Flex style={{ width: '100%' }} align="center" gap={26}>
      <img style={{ borderRadius: '15px', left: '986px', top: '8px', width: '56px', height: '56px' }} src={lumiProfile} alt="lumi-profile" />
      <p style={{ fontSize: '18px', fontWeight: 400, color: 'black' }}>{student.data?.student}</p>
    </Flex>
  );
  
  const handleSelectChange = (value: string): void => {
    setSelectValue(value);
    setAnnotationTypeSelected(true);
  };

  const handleTextchange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setAnnotation(event.target.value);
    setAnnotationWriten(true);
  };

  const datePickerChange: DatePickerProps['onChange'] = (date, dateString: string) => {
    setDate(date)
  };


  const disableDate = (current: Dayjs) => {
    return current && current.isBefore(dayjs().startOf('day'), 'day');
  };

  const handleFilterChange = (event: any): void => {
    setFilter(event.key);
  }

  const emptyForm = () => {
    setSelectValue('Tipo de Anotação');
    setAnnotation('');
    setDate(dayjs());
    setAnnotationTypeSelected(false);
    setAnnotationWriten(false);
  }

  const saveAnnotation = async () => {

    const body = {
      annotation: annotation,
      annotationType: selectValue,
      date: date?.toDate(),
      responsibleId: user?.id ?? "0",
      studentId: id,
      processId: '321831293-daj32das-323913',
    }

    await handleSave(body, {
      onError: (err: any) => showError(err.response.data.message),
      onSuccess: () =>
        showSuccess().then(() => {
          emptyForm();
          refetch();
        }),
    });
    
  }

  const showModal = () => {
    if (annotationTypeSelected === false && annotationWriten === false ) {
      onClose(emptyForm);
    }

    setIsModalOpen(true);
  }

  const submitAnnotation = () => {
    if (annotationTypeSelected === false || annotationWriten === false) {
      setSavedWithoutInformation(true);
    } else {
      saveAnnotation();
    }
  }

  const handleOk = () => {
    setIsModalOpen(false);
    onClose(emptyForm);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  }

  const filterMenu = (
    <Menu onClick={handleFilterChange}>
      <Menu.Item key="Encaminhamento">Encaminhamento</Menu.Item>
      <Menu.Item key="Sessão">Sessão</Menu.Item>
    </Menu>
  );

  const filteredData = filter 
  ? data?.filter((annotation) => annotation.annotationType === filter) 
  : data

  return (
    <Drawer
      width={589}
      title={setTitle()}
      onClose={showModal}
      open={true}
      style={{ borderRadius: '20px, 0px, 0px, 20px' }}
    >
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Sim
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Não
          </Button>,
        ]}
      >
        <h4
          style={{
            fontWeight: 600,
            fontSize: '24px',
            color: colorPrimary,
          }
          }
        >
          Informações não salvas!
        </h4>
        <p
          style={{
            fontWeight: 400,
            fontSize: '18px'
          }
          }
        >
          Você não salvou as informações que inseriu, deseja sair mesmo assim?
        </p>
      </Modal>
      <Flex vertical>
        <p style={{
          fontWeight: 600,
          fontSize: '20px',
          color: colorPrimary
        }}
        >Criar Anotação</p>
        <Flex gap={8} vertical>
          <Flex align="center" justify="end" style={{ borderBottom: '4px', borderTop: '6px', width: '100%' }}>
            {
              annotationTypeSelected === false && savedWithoutInformation ? 
                <p
                  style={{
                    marginRight: '9px',
                    color: "#BB191E",
                    fontWeight: 400,
                    fontSize: '12px'
                  }}
                >Escolha o tipo de anotação</p>
                : <p></p>
            }
            <Select
              value={selectValue}
              defaultValue="Tipo de Anotação"
              status={annotationTypeSelected === false && savedWithoutInformation ? "error" : undefined}
              style={{ width: '50%', height: '32px' }}
              onChange={handleSelectChange}
              options={[
                { value: 'Encaminhamento', label: 'Encaminhamento' },
                { value: 'Sessão', label: 'Sessão' },
              ]}
            />
          </Flex>
          <Flex justify="end">
            <DatePicker
              style={{ width: '140px', height: '28px' }}
              size="small"
              format="DD/MM/YYYY"
              onChange={datePickerChange}
              defaultValue={dayjs()}
              disabledDate={disableDate}
            />
          </Flex>
          <TextArea
            value={annotation}
            status={annotationWriten === false && savedWithoutInformation ?  "error" : undefined}
            placeholder="Anotação..."
            style={{ width: '100%', height: '260px' }}
            onChange={handleTextchange} rows={6} />
          <Flex
            align="center"
            justify="end"
            style={{ borderBottom: '4px', borderTop: '6px', width: '100%' }}>
            {
              annotationWriten === false && savedWithoutInformation ?
                <p
                  style={{
                    marginRight: '9px',
                    color: "#BB191E",
                    fontWeight: 400,
                    fontSize: '12px'
                  }}
                >Escreva alguma anotação para salvar</p>
                : <p></p>
            }
            <button onClick={submitAnnotation} style={
              {
                fontWeight: 600,
                fontSize: '14px',
                color: "white",
                margin: '4px',
                borderRadius: '10px',
                width: '71px',
                height:
                  '36px',
                backgroundColor: colorPrimary
              }}>SALVAR</button>
            <button onClick={showModal} style={
              {
                fontWeight: 600,
                fontSize: '14px',
                color: colorPrimary,
                margin: '4px',
                borderRadius: '10px',
                width: '91px',
                height: '36px',
                border: `3px solid ${colorPrimary}`
              }}>CANCELAR</button>
          </Flex>
        </Flex>
      </Flex>
      <Flex vertical>
        <p style={{
          marginTop: '12px',
          marginBottom: '12px',
          fontWeight: 600,
          fontSize: '20px',
          color: colorPrimary
        }}>
          Histórico de Anotações
        </p>
        <Flex style={{ borderBottom: '1px solid #C4C4C4' }} justify="center" gap={80}>
          <button style={selected === 'all' ?
            {
              borderRadius: '20px 20px 0px 0px',
              borderBottom: `5px solid ${colorPrimary}`,
              color: colorPrimary,
              fontWeight: 700,
              fontSize: '16px'
            } : {
              fontWeight: 700,
              fontSize: '16px'
            }
          } onClick={() => setSelected('all')}>TODOS</button>
          <button style={selected === 'student' ?
            {
              borderRadius: '20px 20px 0px 0px',
              borderBottom: `5px solid ${colorPrimary}`,
              color: colorPrimary,
              fontWeight: 700,
              fontSize: '16px'
            } : {
              fontWeight: 700,
              fontSize: '16px'
            }
          } onClick={() => setSelected('student')}>Estudante</button>
          <button style={selected === 'process' ?
            {
              borderRadius: '20px 20px 0px 0px',
              borderBottom: `5px solid ${colorPrimary}`,
              color: colorPrimary,
              fontWeight: 700,
              fontSize: '16px'
            } : {
              fontWeight: 700,
              fontSize: '16px'
            }
          } onClick={() => setSelected('process')}>PROCESSAMENTO</button>
        </Flex>
        <Flex justify="end">
          <Dropdown overlay={filterMenu} trigger={['click']}>
            <a
              style={
                {
                  width: '64px',
                  backgroundColor: '#F5F5F5',
                  display: 'flex',
                  alignItems:'center',
                  justifyContent:'center',
                  height:'32px',
                  borderRadius:'10px',
                  marginTop:'12px'
                }
              }
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}>
              <FilterOutlined style={{color:'black', fontSize: '20px', marginRight: '8px' }} />
              <RightOutlined style={{fontSize:'10px', color:'black'}} />
            </a>
          </Dropdown>
        </Flex>
        <Flex gap={12} style={{ marginTop: '30px' }} vertical>
          {
            filteredData? (selected === 'student'
              ? filteredData?.filter((annotation) => annotation.studentId !== null).map((annotation) =>
                <ExpansibleCommentCard
                  type={annotation.annotationType}
                  content={annotation.annotation}
                  date={annotation.date} />)
              : selected === 'process')
              ? filteredData?.filter((annotation) => annotation.processId !== null).map((annotation) =>
                <ExpansibleCommentCard
                  type={annotation.annotationType}
                  content={annotation.annotation}
                  date={annotation.date} />)
              : filteredData?.map((annotation =>
                <ExpansibleCommentCard
                  type={annotation.annotationType}
                  content={annotation.annotation}
                  date={annotation.date} />))
                  : <Spin />
          }
        </Flex>
      </Flex>
    </Drawer>
  );
}

export default AnotationDrawer;