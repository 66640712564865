import { useQuery } from "react-query";
import axios from "infra/http";
import { TagCount } from "../types";

function UseCountTagsByResponsibleId(params: { responsibleId?: string | undefined, classes?:string[],students?:string[],enabled:boolean  }) {
  const fetchTags = async (): Promise<TagCount[]> => {
    const { data } = await axios.get<TagCount[]>(`/v2/tags/responsible`, {
      params,
    });

    return data;
  };

  return useQuery(
    [`/v2/tags/responsible?responsibleId=${params?.responsibleId}&classes=${params?.classes?.join('-')}&students=${params?.students?.join('-')}`],
    fetchTags,{
      enabled:params.enabled
    }
  );
}

export default UseCountTagsByResponsibleId;
