import { useMediaQuery } from '@chakra-ui/react';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import {
  Option,
  OptionNumber,
  OptionDescription,
  OptionPercentage,
} from './styles';

function QuestionPercentage({ option, answer, index }: any) {
  const { colorPrimaryMedium, colorSecondary } =
    useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Option>
      <OptionPercentage
        color={
          option.id === answer?.id
            ? hex2rgba(colorSecondary, 0.5)
            : hex2rgba(colorPrimaryMedium, 0.5)
        }
        percentage={option.percentage}
      >
        <div
          style={{
            marginTop: '4px',
            color: option.id === answer?.id
            ? hex2rgba(colorSecondary, 0.5)
            : hex2rgba(colorPrimaryMedium, 0.5),
            fontSize: '16px',
          }}
        >
          {`${option.percentage} (${option.countAnswers})`}
        </div>
      </OptionPercentage>
      <OptionNumber
        isMobile={isMobile}
        color={
          option.id === answer?.id
            ? hex2rgba(colorSecondary, 0.5)
            : hex2rgba(colorPrimaryMedium, 0.5)
        }
      >
        {['A', 'B', 'C', 'D', 'E'][index]}
      </OptionNumber>

      <OptionDescription
        isMobile={isMobile}
      >
        {option.name}
      </OptionDescription>
    </Option>
  );
}

export default QuestionPercentage;
