import { useMediaQuery } from '@chakra-ui/react';
import { Select } from 'antd';
import useTagsCompetence from 'core/features/tags/hooks/useTagsCompetence';

function SelectCompetence({
  id,
  value,
  onChange,
}: {
  id?: string;
  value: string;
  onChange: (v: string) => void;
}) {
  const { data } = useTagsCompetence({
    competence: true,
  });
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Select
      id={id}
      options={data?.map((item) => ({
        label: item?.name['pt-br'],
        value: item.id,
      }))}
      value={value}
      onChange={onChange}
      size={isMobile ? 'middle' : 'large'}
    />
  );
}

export default SelectCompetence;
