import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Form, Input, Radio, Row, Space } from 'antd';
import InputMask from 'components/inputs/input-mask';
import UploadImagem from 'components/inputs/upload-imagem';
import Text from 'components/typography/text';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { validateCnpj } from 'infra/helpers/validateCnpj';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';

function SchoolInfoSection() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  return (
    <GenericCard>
      <Flex vertical style={{ width: '100%' }}>
        <Text
          size="lg"
          color={colorPrimary}
          style={{ marginBottom: '16px', fontWeight: 700 }}
        >
          Dados da Organização
        </Text>
        <Form.Item
          name="imageUrl"
          rules={[
            {
              message: '',
            },
          ]}
        >
          {/* @ts-ignore */}
          <UploadImagem placeholderImg="instituition" />
        </Form.Item>

        <Row gutter={[24, 0]}>
          <Col span={isMobile ? 24 : 14}>
            {' '}
            <Form.Item
              label="Nome"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe seu nome completo!',
                },
              ]}
            >
              <Input id="id-tour-institutions-create-input-name" placeholder='Insira o nome da Organização' />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 10}>
            <Form.Item
              label="CNPJ"
              rules={[
                {
                  message: 'Por favor, informe o número do cnpj!',
                },
                {
                  validator: async (rule, value) => {
                    if (value) {
                      if (!validateCnpj(value)) {
                        throw new Error('CNPJ invalido!');
                      }
                    }
                  },
                },
              ]}
              name="document"
            >
              <InputMask mask="99.999.999/9999-99">
                {() => <Input id="id-tour-institutions-create-input-document" placeholder='Insira o CNPJ' />}
              </InputMask>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 14}>
            <Form.Item label="Site" name="site">
              <Input id="id-tour-institutions-create-input-site"  placeholder='Site institucional'/>
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 10}>
            <Form.Item
              label="Telefone"
              name="phone"
              rules={[
                {
                  validator: async (rule, value) => {
                    if (value) {
                      const formattedValue = value.replace(/\D/g, '');
                      if (formattedValue.length < 10) {
                        throw new Error(
                          'O telefone deve ter pelo menos 10 números!'
                        );
                      }
                    }
                  },
                },
              ]}
            >
              <InputMask mask="(99) 99999-9999" autoComplete="off">
                {() => <Input id="id-tour-institutions-create-input-phone" placeholder='( * * ) * * * * * - * * * *' />}
              </InputMask>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="sector"
            >
              <Radio.Group id="id-tour-institutions-create-input-sector">
                <span style={{ fontSize: isMobile ? '14px' : '18px' }}>
                Setor:&nbsp;
                </span>
                <Space direction="horizontal">
                  <Radio
                    value={'private'}
                  >
                    Privado
                  </Radio>
                  <Radio
                    value={'public'}
                  >
                    Público
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Flex>
    </GenericCard>
  );
}

export default SchoolInfoSection;
