import { useCurrentUser } from "core/features/user/store";
import { useHistory } from "react-router";

export function useLogout() {
  const { update } = useCurrentUser();
  const history = useHistory();

  const handleLogout = () => {
    update(undefined);
    history.push("/auth/login");
  };

  return { handleLogout };
}
