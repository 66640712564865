import { useQuery } from "react-query";
import axios from "infra/http";
import { GameDetails } from "../typings";

function useGamev1(id?: string) {
  const fetchData = async (): Promise<GameDetails> => {
    const { data } = await axios.get<GameDetails>(`/v1/game/${id}`);

    return data;
  };

  return useQuery(`/v1/game/${id}`, fetchData, {
    enabled: !!id,
  });
}

export default useGamev1;
