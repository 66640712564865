import { useQuery } from 'react-query';
import axios from 'infra/http';

function useTotalClasses() {
  const fetchData = async (): Promise<{
    total: number;
  }> => {
    const { data } = await axios.get<number>(`/v2/indicators/total-classes`);

    return { total: data };
  };

  return useQuery([`/v2/indicators/total-classes`], () => fetchData());
}

export default useTotalClasses;
