function GraphIcon({ size = 40 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="40" height="40" fill="url(#graph)" />
      <defs>
        <pattern
          id="graph"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#graph1" transform="scale(0.01)" />
        </pattern>
        <image
          id="graph1"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADn0lEQVR4nO3dO2sUURyH4TdaRFclooL3e6PYiEoiWCti6aUMIpLCT2AqK0UwinctxFYUbKwUVCwUQUQhjSHGa0TBRoIICuKFIzMwLju7sztn5vwn+b1wGmEmJ/Nkz+xMZiMopZRSSimlVJimATuBfcCcQHNQiW4Cf6IxBvTo6IRrbQIjHgcCzmfKt7UByOCUPyoBE4ixBGIsgRhLIMYSiLEEYiyBGEsgxhKIsQRiLIEYSyDGEoixBGIsgRhLIMYSiLEEYiyBGEsgxhJI5/UBz4CfDR4UyTp+AS+AXfFOBdJ573JA1I+v8UOKAumsWR4x4rHF7VggnffQM4izEEiOFgBDwB3gbobxQyB2OqpXSLUwtGSV1DGdQ+xjfACuVPUVMgNYD2wEFlKdhlIwxqOPgAxWDWQdcA34Vje34ejzK9Ox28kWGFQNpB/43mLtdW8152KvUxkwKgUyEN3fyfKu5DkwDzsdz4hRGZCBNjCsobSDUQmQQ8DvBnNxV7fnozmNpHzTTwMuX13A6ZR5uZuPq1O2Mw2ShjERTzJxQ+++IRSHcaYDDNMgacvURPT7hvpqwD0Dy1czjPfAmhbbmwRpF8MKisM4mwPDJEizZaovw/a1QMuXwziX8nXfAisz7md/g+1XhALJixEKpSt6g9Ho671pAyM+Hw4ntne3UggBstsTRtkoDuOCJ4y4bmA70Jv8x7JBnnjEKAvFYVxM2f/reKnxVdkgDzxjFI1SKkYIEPfy/Jy4Df3fy9UYisO4VCZGqJP6bGBTdAB9V/OE4jAup+znFbCcggp9YVhEtZzXKc1O4O6t7aoiJz8ZQfKgNDtnFI4xmUE6QWmG8RJYRgn5BtkDPAJuARsIX63FOWVR4prgamgM3yD1V+AfgZnYRvkS/fZxvAnG0jIn6wsk7XaIhVdJK5S0MVo2hi+QNIzxaCmggiijwJIQk8wLsq3JLXSfF32+6o5u5DX6AYqHW8LmE6i8ICdSMHzcDimy3ghmJDqPuBuE15MfnKkqSH8FMUyXF6QremTyE/A4esJQ5WgyXxhWMoEYSyDGEoixBGIsgRhLIMYSiLEEYiyBGEsgxhKIsQRiLIEYSyDGEoixBGIsgRhLIMYSiLEEYiyBGEsgxhKIsQRiLIFUAOQ2cFgDX8fgINCTB0QD78dgLP7fD1rlnlQXAKUcg71ZQBZ38HcONejoGOzIumwdEQpF/5DdAKbRRu7jwZs1KOIYZPkLc//6CzrYjD3zPUagAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
}

export default GraphIcon;
