import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Typography } from 'antd';
import Avatar from 'components/avatar';
import moment from 'moment';

const { Text } = Typography;
function Comment({
  name,
  imageUrl,
  comment,
  date,
}: {
  name: string;
  imageUrl?: string;
  comment: string;
  date: string;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Flex
      style={{ background: '#F5F5F5', borderRadius: '20px', padding: '8px' }}
    >
      <Flex vertical gap='6px'>
        <Flex gap={'4px'}>
          <Avatar url={imageUrl} name={name} type='secondary' />

          <Flex vertical>
            <Text
              style={{
                color: 'black',
                fontSize: isMobile ? '14px' : '16px',
                fontWeight: 600,
              }}
            >
              {name}
            </Text>
            <Text
              style={{
                color: '#9D9D9D',
                fontSize: isMobile ? '14px' : '16px',
                fontWeight: 400,
              }}
            >
              {moment(date).format('DD/MM/YYYY - HH[h]mm')}
            </Text>
          </Flex>
        </Flex>
        <p
          style={{
            color: 'black',
            fontSize: isMobile ? '14px' : '16px',
            fontWeight: 400,
          }}
        >
          {comment}
        </p>
      </Flex>
    </Flex>
  );
}

export default Comment;
