import { useQuery } from 'react-query';
import axios from 'infra/http';
import { GameBeingUsed } from '../typings';

interface Params {
  startDate?: string;
  endDate?: string;
}
function useGamesBeingUsed(params: Params) {
  const fetchData = async (
    params: Params
  ): Promise<{ data: GameBeingUsed[]; total: number }> => {
    const { data, headers } = await axios.get<GameBeingUsed[]>(
      `/v2/game/used`,
      {
        params,
      }
    );
    return { data: data, total: Number(headers['total']) };
  };

  return useQuery([`/v2/game/used`, params], () => fetchData(params));
}

export default useGamesBeingUsed;
