import Layout from 'components/layout';
import { Col, Divider, Flex, Row, Spin, Typography } from 'antd';
import { useState } from 'react';
import { DatePicker as DatePickerAntd } from 'antd';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { AiOutlineCalendar } from 'react-icons/ai';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import HeatMap from './components/heatmap';
import useHighestRates from 'core/features/inkluzen/hooks/useHighestRates';
import useRegionalStats from 'core/features/inkluzen/hooks/useRegional';
import useTotalStudents from 'core/features/inkluzen/hooks/useTotalStudents';
import { BiTrendingDown, BiTrendingUp } from 'react-icons/bi';
import useGravity from 'core/features/inkluzen/hooks/useGravity';
import { useCurrentUser } from 'core/features/user/store';
import { Pie } from 'react-chartjs-2';
import Dot from 'components/dot';
import PlacesAutocomplete from './components/placesAutoComplete';
import { PiMapPin } from 'react-icons/pi';
import { t } from 'core/resources/strings';
import moment from 'moment';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import FilterButton from 'components/buttons/filter-button';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

const { Title } = Typography;
const { Text } = Typography;
const { RangePicker } = DatePickerAntd;

const pieColors: Record<string, string> = {
  serious: '#B94386',
  moderate: '#B97643',
  light: '#86B943',
  no_manifest: '#7743B9',
};

// Register the required components
ChartJS.register(ArcElement, Tooltip, Legend);

function MacroView() {
  const { user } = useCurrentUser();
  const [date, setDate] = useState<any>();
  const [gravity, setGravity] = useState('All');
  const [coordinates, setCoordinates] = useState<any>();
  const [placeType, setPlaceType] = useState<any>();
  const [placeName, setPlaceName] = useState<string>();
  const [method, setMethod] = useState('context');
  const {
    colorPrimary,
    colorGreyBorderAndFont,
    colorHover,
    colorGreen,
    colorRed,
    colorSecondaryLight,
  } = useU4heroColors();
  const params = {
    tenantId: user?.tenantId,
    initialDate: date
      ? moment(new Date(date[0])).format('YYYY-MM-DD')
      : undefined,
    finalDate: date
      ? moment(new Date(date[1])).format('YYYY-MM-DD')
      : undefined,
    search: placeName ?? undefined,
    gravity: gravity,
    method: method,
  };
  const { data: highestRates } = useHighestRates(params);
  const { data: regionalStats, isLoading: isLoadingMap } =
    useRegionalStats(params);
  const { data: totalStudents, isLoading: isLoadingTotal } =
    useTotalStudents(params);
  const { data: gravityData, isLoading: isLoadingGravity } = useGravity(params);

  const handlePlaceSelect = (place: google.maps.places.PlaceResult) => {
    const coord = place.geometry?.location?.toJSON();
    setCoordinates([coord?.lat, coord?.lng]);
    setPlaceType(place?.types?.length ? place.types[0] : 'country');
    setPlaceName(place.name);
  };

  // console.log(Object.values(gravityData?.classQA ?? {}));
  return (
    <Layout.Container>
      <Title
        level={2}
        style={{
          fontWeight: 700,
          fontSize: '28px',
          color: colorPrimary,
        }}
      >
        Visão Geral
      </Title>
      <Flex vertical gap="16px">
        <Flex justify="center" gap="96px">
          <Flex vertical>
            <button
              style={{
                color:
                  method === 'context' ? colorPrimary : colorGreyBorderAndFont,
                fontSize: '24px',
                fontWeight: 700,
              }}
              onClick={() => setMethod('context')}
            >
              CONTEXTO
            </button>
            {method === 'context' ? (
              <div
                style={{
                  height: '8px',
                  borderRadius: '20px 20px 0px 0px',
                  background: colorPrimary,
                }}
              />
            ) : null}
          </Flex>
          <Flex>
            <button
              style={{
                color:
                  method === 'meaning' ? colorPrimary : colorGreyBorderAndFont,
                fontWeight: 700,
                fontSize: '24px',
              }}
              onClick={() => setMethod('meaning')}
            >
              SIGNIFICADO
              {method === 'meaning' ? (
                <div
                  style={{
                    height: '8px',
                    borderRadius: '20px 20px 0px 0px',
                    background: colorPrimary,
                  }}
                />
              ) : null}
            </button>
          </Flex>
        </Flex>
        <Divider
          style={{ background: colorHover, margin: '-16px 0px 0px 0px' }}
        />
        <Flex justify="end">
          <Flex gap="22px" align="center">
            <RangePicker
              style={{ height: '34px' }}
              format="DD/MM/YYYY"
              separator={<HiOutlineArrowNarrowRight />}
              suffixIcon={<AiOutlineCalendar color="black" />}
              value={date}
              onChange={(v) => setDate(v)}
            />
            <PlacesAutocomplete onPlaceSelect={handlePlaceSelect} />
          </Flex>
        </Flex>
        {!isLoadingMap ? (
          <GenericCard padding="15px 22px 24px 18px">
            <Flex vertical gap={'18px'} style={{ width: '100%' }}>
              <Flex justify="space-between" style={{ width: '100%' }}>
                <Text
                  style={{
                    color: colorPrimary,
                    fontSize: '24px',
                    fontWeight: 600,
                  }}
                >
                  Mapa de calor regional
                </Text>
                <Flex
                  style={{
                    borderRadius: '10px',
                    background: colorSecondaryLight,
                    fontSize: '12px',
                    fontWeight: 400,
                    padding: '4px 8px',
                    marginRight: '15px',
                  }}
                  gap="8px"
                >
                  <FilterButton
                    name="Todos"
                    value="All"
                    state={gravity}
                    onChange={setGravity}
                  />
                  <FilterButton
                    name="Grave"
                    value="Serious"
                    state={gravity}
                    onChange={setGravity}
                  />
                  <FilterButton
                    name="Moderado"
                    value="Moderate"
                    state={gravity}
                    onChange={setGravity}
                  />
                  <FilterButton
                    name="Leve"
                    value="Light"
                    state={gravity}
                    onChange={setGravity}
                  />
                  <FilterButton
                    name="Não manifesto"
                    value="No manifest"
                    state={gravity}
                    onChange={setGravity}
                  />
                </Flex>
              </Flex>

              <HeatMap
                data={regionalStats?.data ?? []}
                coordinates={coordinates}
                placeType={placeType}
              />
            </Flex>
          </GenericCard>
        ) : (
          <Spin size="large" />
        )}

        <Row gutter={[26, 0]} style={{ marginTop: '16px' }}>
          <Col span={8}>
            {!isLoadingGravity ? (
              <GenericCard>
                <Flex vertical>
                  <Text
                    style={{
                      color: colorPrimary,
                      fontSize: '24px',
                      fontWeight: 600,
                    }}
                  >
                    As 6 Localidades com a Maior Taxa de Depressão
                  </Text>
                  <Flex
                    gap="8px"
                    style={{ alignSelf: 'end', marginBottom: '12px' }}
                  >
                    <PiMapPin color={colorGreyBorderAndFont} size={26} />
                    <Text
                      style={{
                        fontSize: '18px',
                        color: colorGreyBorderAndFont,
                        fontWeight: 600,
                        textTransform: 'uppercase',
                      }}
                    >
                      {placeName}
                    </Text>
                  </Flex>
                  <Row>
                    {highestRates?.data.map((item, index) => (
                      <Col
                        span={12}
                        style={{
                          borderBottom: `1px solid ${colorHover}`,
                          borderLeft:
                            index % 2 === 0 ? '' : `1px solid ${colorHover}`,
                        }}
                      >
                        <Flex gap="28px" align="center">
                          <Text
                            style={{
                              color: colorPrimary,
                              fontSize: '28px',
                              fontWeight: 600,
                            }}
                          >
                            {`${index + 1} -`}
                          </Text>
                          <div>
                            <p
                              style={{
                                color: 'black',
                                fontSize: '20px',
                                fontWeight: 500,
                                textTransform: 'uppercase',
                              }}
                            >
                              {item.city}
                            </p>
                            <p
                              style={{
                                color: 'black',
                                fontSize: '16px',
                                fontWeight: 400,
                                textTransform: 'uppercase',
                              }}
                            >
                              {item.cases} casos
                            </p>
                          </div>
                        </Flex>
                      </Col>
                    ))}
                  </Row>
                </Flex>
              </GenericCard>
            ) : (
              <Spin size="large" />
            )}
          </Col>
          <Col span={8}>
            {!isLoadingTotal ? (
              <GenericCard>
                <Flex vertical>
                  <Text
                    style={{
                      color: colorPrimary,
                      fontSize: '24px',
                      fontWeight: 600,
                    }}
                  >
                    Total de Estudantes
                  </Text>
                  <Text
                    style={{
                      color: 'black',
                      fontSize: '46px',
                      fontWeight: 700,
                    }}
                  >
                    {totalStudents?.total}
                  </Text>
                  <Flex>
                    {totalStudents && totalStudents?.change > 0 ? (
                      <Flex gap="12px">
                        {' '}
                        <BiTrendingUp color={colorGreen} size={24} />{' '}
                        <Text
                          style={{
                            color: colorGreen,
                            fontSize: '14px',
                            fontWeight: 500,
                          }}
                        >
                          Subiu em {`${totalStudents.change}%`}{' '}
                          <span style={{ color: 'black', fontWeight: 400 }}>
                            {' '}
                            na última semana
                          </span>
                        </Text>
                      </Flex>
                    ) : (
                      <Flex gap="12px" align="center">
                        <BiTrendingDown color={colorRed} size={24} />
                        <Text
                          style={{
                            color: colorRed,
                            fontSize: '14px',
                            fontWeight: 500,
                          }}
                        >
                          Caiu em {`${Math.abs(totalStudents?.change ?? 0)}%`}{' '}
                          <span style={{ color: 'black', fontWeight: 400 }}>
                            {' '}
                            na última semana
                          </span>
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </GenericCard>
            ) : (
              <Spin size="large" />
            )}
          </Col>
          <Col span={8}>
            {!isLoadingGravity ? (
              <GenericCard>
                <Flex vertical gap="26px">
                  <Text
                    style={{
                      color: colorPrimary,
                      fontSize: '24px',
                      fontWeight: 600,
                    }}
                  >
                    Análise da Gravidade em Casos de Depressão
                  </Text>
                  <Flex align="center" justify="space-around">
                    <Flex style={{ maxWidth: '174px' }}>
                      <Pie
                        data={{
                          datasets: [
                            {
                              data: Object.values(gravityData?.classQA ?? {}),
                              backgroundColor: Object.values(pieColors),
                              borderWidth: 0,
                            },
                          ],
                        }}
                      />
                    </Flex>
                    <Flex vertical gap="12px">
                      {Object.keys(gravityData?.classQA ?? {})?.map((item) => (
                        <Flex gap="12px" align="center">
                          <Dot size={12} color={pieColors[item]} />
                          <Text
                            style={{
                              textAlign: 'center',
                              fontSize: '16px',
                              fontWeight: 400,
                            }}
                          >
                            {t(`app.${item}`)}
                          </Text>
                          <Text
                            style={{
                              textAlign: 'center',
                              fontSize: '16px',
                              fontWeight: 700,
                              color: pieColors[item],
                            }}
                          >
                            {gravityData && gravityData.classQA[item]}%
                          </Text>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                </Flex>
              </GenericCard>
            ) : (
              <Spin size="large" />
            )}
          </Col>
        </Row>
      </Flex>
    </Layout.Container>
  );
}

export default MacroView;
