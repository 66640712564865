import { useMediaQuery } from '@chakra-ui/react';
import {  Col, Flex, Form, Row } from 'antd';
import Layout from 'components/layout';
import Title from 'components/typography/title';
import { useCurrentUser } from 'core/features/user/store';
import { useEffect } from 'react';
import CustomizedRequiredMark from 'components/required-mark';
import ProfileProgress from './components/ProfileProgress';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import Button from 'components/buttons/button';
import { useTenant } from 'core/features/tenants/hooks/useTenant';
import { useUpdateCurrentTenant } from 'core/features/tenants/hooks/useUpdateCurrentTenant';
import { TenantForm } from 'core/features/tenants/typings/form';
import SchoolInfo from './components/SchoolInfo';
import ContactInfo from './components/ContactInfo';
import { Permissions } from "core/resources/enums/permissions";
import AdressInfo from './components/AddressInfo';
import { useHistory } from 'react-router';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { t } from 'core/resources/strings';

function SchoolProfile() {
  const history = useHistory();
  const [check] = useCheckPermissions();
  if(!check([Permissions.Tenant.Edit,Permissions.Org.Tenant.Edit,Permissions.U4hero.Tenant.Edit])){
    history.push('/errors/no-permission')
  }
  
  const { user} = useCurrentUser();
  const [form] = Form.useForm();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data, isLoading } = useTenant(user?.tenantId);
  const { mutate: handleSave, isLoading: isLoadingSave } = useUpdateCurrentTenant(user?.tenantId as string);

  const _handleSave = (values: TenantForm) => {
    handleSave(values, {
      onSuccess: () => {
        notificateSucess(t('app.perfilAtualizadoComSucesso'));
        history.push('/my-school')
      },
      onError: (err: any) => {
        notificateError(t('app.erroAoSalvarAlteracoes'));
      },
    });
  };
  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  return (
    <Layout.Container>
      <Title primary style={{ marginBottom: '16px' }}>
        {t('app.perfilDaInstituicao')}
      </Title>
      <Form
        id="id-tour-my-settings"
        onFinish={(ev) => _handleSave(ev)}
        validateTrigger="onSubmit"
        layout="vertical"
        requiredMark={CustomizedRequiredMark}
        form={form}
        initialValues={data || undefined}
      >
        <Row
          gutter={isMobile ? [0, 24] : [28, 0]}
          style={{ marginBottom: '24px' }}
        >
          <Col span={isMobile ? 24 : 18} order={isMobile ? 2 : 1}>
            <Flex vertical gap="24px">
              <SchoolInfo />
              <ContactInfo />
              <AdressInfo />
            </Flex>
          </Col>
          <Col span={isMobile ? 24 : 6} order={isMobile ? 1 : 2}>
            <Flex
              vertical
              gap="16px"
              justify="center"
              style={{ position: isMobile ? undefined : 'fixed' }}
            >
              <ProfileProgress />
              <Button
                size="md"
                id="id-tour-my-school-settings-button-save"
                style={{
                  fontWeight: 600,
                  fontSize: isMobile ? '12px' : '16px',
                  alignSelf: 'center',
                }}
                htmlType="submit"
                loading={isLoadingSave}
              >
                {t('app.salvarAlteracoes')}
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>
    </Layout.Container>
  );
}

export default SchoolProfile;
