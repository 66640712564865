import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

function ColumnGraph({
  categories,
  series,
  colors,
}: {
  categories: string[];
  series: ApexAxisChartSeries;
  colors?: string[];
}) {
  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: series.length === 1,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
    },
    xaxis: {
      categories: categories,
      labels: {
        show: true,
      },
    },
    colors: colors,
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />
  );
}

export default ColumnGraph;
