import { useMediaQuery } from '@chakra-ui/react';
import { Col, Row } from 'antd';
import Layout from 'components/layout';
import { useCurrentUser } from 'core/features/user/store';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import ManageCard from '../../../components/ManageCard';
import { Permissions } from 'core/resources/enums/permissions';
import { Link } from 'react-router-dom';
import GraduationCapIcon from 'assets/images/icons/GraduationCapIcon';
import CollaboratorIcon from 'assets/images/icons/CollaboratorIcon';
import LeaseIcon from 'assets/images/icons/LeaseIcon';
import DataIcon from 'assets/images/icons/DataIcon';
import Title from 'components/typography/title';
import ClassRoomIcon from 'assets/images/icons/ClassRoomIcon';
import { t } from 'core/resources/strings';

const permissionsForTab = [
  {
    permission: Permissions.Students.View,
    label: t('app.gerenciarEstudantes'),
    icon: GraduationCapIcon,
    id: 'id-tour-my-school-card-students',
    link: '/my-school/students',
  },
  {
    permission: Permissions.Class.View,
    label: t('app.gerenciarTurmas'),
    icon: ClassRoomIcon,
    id: 'id-tour-my-school-card-classes',
    link: '/my-school/classes',
  },
  {
    permission: Permissions.Collaborators.View,
    label: t('app.gerenciarColaboradores'),
    icon: CollaboratorIcon,
    id: 'id-tour-my-school-card-collaborators',
    link: '/my-school/collaborators',
  },
  {
    permission: Permissions.Collaborators.View,
    label: t('app.gerenciarPerfis'),
    icon: LeaseIcon,
    id: 'id-tour-my-school-card-profiles',
    link: '/my-school/profiles',
  },
  {
    permission: Permissions.Org.Profile.View,
    label: t('app.gerenciarPerfis'),
    icon: LeaseIcon,
    id: 'id-tour-my-school-card-profiles',
    link: '/my-school/profiles',
  },
  {
    permission: Permissions.Org.Collaborators.View,
    label: t('app.gerenciarColaboradores'),
    icon: CollaboratorIcon,
    id: 'id-tour-my-school-card-collaborators',
    link: '/my-school/collaborators',
  },
  {
    permission: Permissions.Org.Tenant.Edit,
    label: t('app.dadosMinhaOrganizacao'),
    icon: DataIcon,
    id: 'id-tour-my-school-card-settings',
    link: '/my-school/settings',
  },
  {
    permission: Permissions.Tenant.Edit,
    label: t('app.dadosMinhaEscola'),
    icon: DataIcon,
    id: 'id-tour-my-school-card-settings',
    link: '/my-school/settings',
  },
];
function MySchoolPage() {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { user } = useCurrentUser();
  let cards: { label: string; icon: any; link: string; id: string }[] = [];
  permissionsForTab.forEach((element) =>
    user?.resources.includes(element.permission)
      ? cards.push({
          label: element.label,
          icon: element.icon,
          link: element.link,
          id: element.id,
        })
      : null
  );

  return (
    <Layout.Container>
      <Title
        primary
        style={{
          marginBottom: '8px',
        }}
      >
        {t('app.minhaEscola')}
      </Title>

      <div
        style={{
          backgroundColor: colorPrimary,
          backgroundImage: "url('/estrelas_1.png')",
          fontFamily: 'Poppins',
          padding: isMobile ? '16px 0px' : '16px',
          height: isMobile ? '' : '74px',
          borderRadius: '20px',
          display: 'flex',
          justifyContent: isMobile ? 'center' : '',
          width: '100%',
          fontSize: isMobile ? '14px' : '28px',
          fontWeight: 400,
          color: '#FFFFFF',
          marginBottom: isMobile ? '18px' : '24px',
        }}
      >
        {t('app.escola')}: {user?.tenantName}
      </div>
      <Row gutter={isMobile ? [0, 18] : [42, 48]}>
        {cards.map((item) => (
          <Col sm={8} style={{ width: '100%' }} key={item.id}>
            <Link to={item.link}>
              <ManageCard
                isMobile={isMobile}
                icon={item.icon}
                title={item.label}
                id={item.id}
              />
            </Link>
          </Col>
        ))}
      </Row>
    </Layout.Container>
  );
}

export default MySchoolPage;
