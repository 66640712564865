import { useMediaQuery } from '@chakra-ui/react';
import {  Form, Radio, Space } from 'antd';
import { GraphSource, GraphView } from 'core/features/indicators-profile/typings';
import { t } from 'core/resources/strings';

function ComparisonModeInput({
  index,
  updateForm,
}: {
  index: number;
  updateForm: (
    fieldName: string | number | (string | number)[],
    value: any
  ) => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const form = Form.useFormInstance();
  const graph = Form.useWatch(['graphs', index], form);
  if (graph?.source !== GraphSource.Student || graph?.graphView !== GraphView.Comparative) {
    return null;
  }
  return (
    <>
      <Form.Item
        rules={[
          {
            required: true,
            message: t('app.mensagemErroModoDeComparacao'),
          },
        ]}
        name={[index, 'comparisonMode']}
        label={t('app.modoDeComparacao')}
      >
        <Radio.Group
          id="id-tour-my-indicators-custom-panel-comparison-input"
          onChange={() => {
            updateForm(['graphs', index, 'comparativePeriod'], undefined);
            updateForm(['graphs', index, 'comparative1'], undefined);
            updateForm(['graphs', index, 'comparative2'], undefined);
          }}
        >
          <Space direction={isMobile ? 'vertical' : 'horizontal'}>
            <Radio value={'timePeriod'}>{t('app.periodoDeTempo')}</Radio>
            <Radio value={'student-versus-class'}>{t('app.estudanteVersusTurma')}</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
}

export default ComparisonModeInput;
