import Layout from 'components/layout';
import { Col, Flex, Row, Spin, Typography } from 'antd';
import { useState } from 'react';
import { DatePicker as DatePickerAntd } from 'antd';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { AiOutlineCalendar } from 'react-icons/ai';
import useGravity from 'core/features/inkluzen/hooks/useGravity';
import { useCurrentUser } from 'core/features/user/store';
import moment from 'moment';
import PlacesAutocomplete from '../macro-view/components/placesAutoComplete';
import GraphCard from '../macro-view/components/graphCard';
import ProcessTable from './components/process-table';
import GeographicTable from './components/geographic-table';
import SymptomsTable from './components/symptoms-table';
import useDegree from 'core/features/inkluzen/hooks/useDegree';
import useFrequentSymptoms from 'core/features/inkluzen/hooks/useFrequentSymptoms';
import useGravityByLocation from 'core/features/inkluzen/hooks/useGravityByLocation';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import FilterButton from 'components/buttons/filter-button';
const { Title } = Typography;
const { RangePicker } = DatePickerAntd;

function ExplorationView() {
  const { user } = useCurrentUser();
  const [date, setDate] = useState<any>();
  const [typeOfView, setTypeOfView] = useState('students');
  const [placeName, setPlaceName] = useState<string>();
  const params = {
    integrationId: user?.tenantIntegrationId,
    initialDate: date
      ? moment(new Date(date[0])).format('YYYY-MM-DD')
      : undefined,
    finalDate: date
      ? moment(new Date(date[1])).format('YYYY-MM-DD')
      : undefined,
    search: placeName ?? undefined,
  };

  const { colorPrimary, colorSecondaryLight } = useU4heroColors();
  const { data: gravityData, isLoading: isLoadingGravityData } =
    useGravity(params);
  const { data: degreeData } = useDegree({
    integrationId: user?.tenantIntegrationId,
    initialDate: date
      ? moment(new Date(date[0])).format('YYYY-MM-DD')
      : undefined,
    finalDate: date
      ? moment(new Date(date[1])).format('YYYY-MM-DD')
      : undefined,
  });
  const { data: symptomsData } = useFrequentSymptoms(params);
  const { data: stateData } = useGravityByLocation({
    tenantId: user?.tenantId,
    initialDate: date
      ? moment(new Date(date[0])).format('YYYY-MM-DD')
      : undefined,
    finalDate: date
      ? moment(new Date(date[1])).format('YYYY-MM-DD')
      : undefined,
    location: 'State',
  });
  const { data: regionData } = useGravityByLocation({
    tenantId: user?.tenantId,
    initialDate: date
      ? moment(new Date(date[0])).format('YYYY-MM-DD')
      : undefined,
    finalDate: date
      ? moment(new Date(date[1])).format('YYYY-MM-DD')
      : undefined,
    location: 'State',
  });
  const { data: cityData } = useGravityByLocation({
    tenantId: user?.tenantId,
    initialDate: date
      ? moment(new Date(date[0])).format('YYYY-MM-DD')
      : undefined,
    finalDate: date
      ? moment(new Date(date[1])).format('YYYY-MM-DD')
      : undefined,
    location: 'City',
  });
  const { data: schoolData } = useGravityByLocation({
    tenantId: user?.tenantId,
    initialDate: date
      ? moment(new Date(date[0])).format('YYYY-MM-DD')
      : undefined,
    finalDate: date
      ? moment(new Date(date[1])).format('YYYY-MM-DD')
      : undefined,
    location: 'School',
  });

  const handlePlaceSelect = (place: google.maps.places.PlaceResult) => {
    setPlaceName(place.name);
  };
  return (
    <Layout.Container>
      <Title
        level={2}
        style={{
          fontWeight: 700,
          fontSize: '28px',
          color: colorPrimary,
        }}
      >
        Exploratória - Visão{' '}
        {typeOfView === 'students'
          ? 'de Estudantes'
          : typeOfView === 'geographic'
          ? 'Geográfica'
          : 'Sintomas'}
      </Title>
      <Flex vertical gap="16px">
        <Flex justify="end">
          <Flex gap="22px" align="center">
            <RangePicker
              style={{ height: '34px' }}
              format="DD/MM/YYYY"
              separator={<HiOutlineArrowNarrowRight />}
              suffixIcon={<AiOutlineCalendar color="black" />}
              value={date}
              onChange={(v) => setDate(v)}
            />
            <PlacesAutocomplete onPlaceSelect={handlePlaceSelect} />
          </Flex>
        </Flex>
        <Flex
          style={{
            borderRadius: '10px',
            background: colorSecondaryLight,
            fontSize: '12px',
            fontWeight: 400,
            padding: '4px 8px',
            marginRight: '15px',
            alignSelf: 'end',
          }}
          gap="8px"
        >
          <FilterButton
            name="Estudantes"
            value="students"
            state={typeOfView}
            onChange={setTypeOfView}
          />
          <FilterButton
            name="Geográfica"
            value="geographic"
            state={typeOfView}
            onChange={setTypeOfView}
          />
          <FilterButton
            name="Sintomas"
            value="symptoms"
            state={typeOfView}
            onChange={setTypeOfView}
          />
        </Flex>
        {!isLoadingGravityData ? (
          <Row gutter={[14, 24]}>
            <Col span={8}>
              <GraphCard
                title="Grau de Atenção Contexto"
                data={degreeData?.classQA}
              />
            </Col>
            <Col span={8}>
              <GraphCard
                title="Grau de Atenção Significado"
                data={degreeData?.classSG}
              />
            </Col>
            <Col span={8}>
              <GraphCard
                title="Sintomas mais Frequentes"
                data={symptomsData?.classQA}
              />
            </Col>
            {/* <Col span={8}>
              <GraphCard title="Estado" data={stateData} />
            </Col>
            <Col span={8}>
              <GraphCard title="Região" data={regionData} />
            </Col>
            <Col span={8}>
              <GraphCard title="Município" data={cityData} />
            </Col>
            <Col span={8}>
              <GraphCard title="Escola" data={schoolData} />
            </Col> */}
          </Row>
        ) : (
          <Spin size="large" />
        )}

        {typeOfView === 'students' ? <ProcessTable params={params} /> : null}
        {typeOfView === 'geographic' ? (
          <GeographicTable params={params} />
        ) : null}
        {typeOfView === 'symptoms' ? <SymptomsTable params={params} /> : null}
      </Flex>
    </Layout.Container>
  );
}

export default ExplorationView;
