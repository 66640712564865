import { AiOutlineCalendar } from 'react-icons/ai';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { Flex, DatePicker as DatePickerAntd, Row, Col } from 'antd';
import { useMediaQuery } from '@chakra-ui/media-query';
import { useCurrentUser } from 'core/features/user/store';
import { useParams } from 'react-router';
import { CarouselWrapper } from './style';
import './style.css';
import dayjs from 'dayjs';
import useGetStudentLowestCompetence from 'core/features/students/hooks/useGetStudentLowestCompetence';
import ViewCard from './viewCard';
import CompetencesFilter from './competencesFilter';
import SubTitle from 'components/typography/subTitle';
import ComparativeMode from '../comparativeMode';
import useGetViewData from './useGetViewsData';
import useGetClassLowestCompetence from 'core/features/classes/hooks/useGetClassLowestCompetence';
import { ComparativeItem } from 'infra/helpers/types';
import { TagType } from 'core/features/competence/typings';
import { t } from 'core/resources/strings';

const { RangePicker } = DatePickerAntd;

function Views({
  setLowestCompetence,
  serieId,
  allowCompare,
  tagType,
}: {
  setLowestCompetence: (v: string) => void;
  serieId?: string;
  allowCompare?: boolean;
  tagType: TagType;
}) {
  const [date, setDate] = useState<any>([
    dayjs().startOf('year'),
    dayjs().endOf('year'),
  ]);
  const [tags, setTags] = useState<string[]>([]);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { user } = useCurrentUser();
  const [comparative, setComparative] = useState<ComparativeItem>({
    type: 'student',
    id: '',
    name: '',
  });

  const { id, classId, tenantId } = useParams<{
    classId: string;
    tenantId: string;
    id: string;
  }>();

  const {
    comprehension,
    isLoadingComprehension,
    decision,
    isLoadingDecision,
    perception,
    isLoadingPerception,
  } = useGetViewData({
    tags,
    tenantId: tenantId ?? (user?.tenantId as string),
    startDate: date
      ? dayjs(date[0]).format('YYYY-MM-DD')
      : dayjs().startOf('year').format('YYYY-MM-DD'),
    endDate: date
      ? dayjs(date[1]).format('YYYY-MM-DD')
      : dayjs().endOf('year').format('YYYY-MM-DD'),
    classId: classId,
    userId: id,
  });

  const {
    comprehension: comprehension2,
    isLoadingComprehension: isLoadingComprehension2,
    decision: decision2,
    isLoadingDecision: isLoadingDecision2,
    perception: perception2,
    isLoadingPerception: isLoadingPerception2,
  } = useGetViewData({
    tags: comparative.id !== '' ? tags : [],
    tenantId: tenantId ?? (user?.tenantId as string),
    startDate: date
      ? dayjs(date[0]).format('YYYY-MM-DD')
      : dayjs().startOf('year').format('YYYY-MM-DD'),
    endDate: date
      ? dayjs(date[1]).format('YYYY-MM-DD')
      : dayjs().endOf('year').format('YYYY-MM-DD'),
    classId: comparative.type === 'class' ? comparative.id : undefined,
    userId: comparative.type === 'student' ? comparative.id : undefined,
  });

  const { data: lowestStudent } = useGetStudentLowestCompetence({
    userId: id,
    startDate: date ? dayjs(date[0]).format('YYYY-MM-DD') : undefined,
    endDate: date ? dayjs(date[1]).format('YYYY-MM-DD') : undefined,
  });

  const { data: lowestClass } = useGetClassLowestCompetence({
    classId: classId,
    startDate: date ? dayjs(date[0]).format('YYYY-MM-DD') : undefined,
    endDate: date ? dayjs(date[1]).format('YYYY-MM-DD') : undefined,
  });

  useEffect(() => {
    if (lowestStudent?.id) {
      setLowestCompetence(lowestStudent.id);
    }
    if (lowestClass?.id) {
      setLowestCompetence(lowestClass.id);
    }
  }, [lowestStudent, lowestClass]);

  const views = [
    {
      name: t('app.conhecimento'),
      description: t('app.conhecimentoDescricao'),
      isLoading: isLoadingComprehension,
      items:
        comprehension?.map((item) => ({
          name: item.name,
          value: Math.round(item.items[0].percent),
        })) ?? [],
    },
    {
      name: t('app.comportamento'),
      description: t('app.comportamentoDescricao'),
      isLoading: isLoadingDecision,
      items:
        decision?.map((item) => ({
          name: item.name,
          value: Math.round(item.items[0].percent),
        })) ?? [],
    },
    {
      name: t('app.percepcao'),
      description: t('app.percepcaoDescricao'),
      isLoading: isLoadingPerception,
      items:
        perception?.map((item) => ({
          name: item.name,
          value: Math.round(item.items[0].percent),
        })) ?? [],
    },
  ];
  const viewsComparative = [
    {
      name: `${t('app.conhecimento')} - ${
        comparative.type === 'student'
          ? `${t('app.estudante')}:`
          : `${t('app.turma')}: `
      }${comparative.name}`,
      description: t('app.conhecimentoDescricao'),
      isLoading: isLoadingComprehension2,
      items:
        comprehension2?.map((item) => ({
          name: item.name,
          value: Math.round(item.items[0].percent),
        })) ?? [],
    },
    {
      name: `${t('app.comportamento')} - ${
        comparative.type === 'student'
          ? `${t('app.estudante')}:`
          : `${t('app.turma')}: `
      }${comparative.name}`,
      description: t('app.comportamentoDescricao'),
      isLoading: isLoadingDecision2,
      items:
        decision2?.map((item) => ({
          name: item.name,
          value: Math.round(item.items[0].percent),
        })) ?? [],
    },
    {
      name: `${t('app.percepcao')} - ${
        comparative.type === 'student'
          ? `${t('app.estudante')}:`
          : `${t('app.turma')}: `
      }${comparative.name}`,
      description: t('app.percepcaoDescricao'),
      isLoading: isLoadingPerception2,
      items:
        perception2?.map((item) => ({
          name: item.name,
          value: Math.round(item.items[0].percent),
        })) ?? [],
    },
  ];

  const mergedViews =
    comparative.id === ''
      ? views
      : views.flatMap((view, index) => [view, viewsComparative[index]]);

  return (
    <Flex vertical gap={12} style={{ marginBottom: isMobile ? '30px' : '' }}>
      <SubTitle primary>
        {tagType === TagType.Competências
          ? t('app.desenvolvimentoDeCompetencias')
          : t('app.analiseDoClimaEscolar')}
      </SubTitle>
      <Flex
        gap={isMobile ? '12px' : '24px'}
        align="center"
        justify="end"
        wrap={allowCompare ? 'wrap' : 'nowrap'}
        style={{ marginBottom: isMobile ? '8px' : '18px' }}
      >
        <RangePicker
          style={{ height: '34px' }}
          format="DD/MM/YYYY"
          separator={<HiOutlineArrowNarrowRight />}
          suffixIcon={<AiOutlineCalendar color="black" />}
          value={date}
          onChange={(v: any) => setDate(v)}
        />
        {allowCompare && (
          <ComparativeMode
            classId={classId}
            serieId={serieId}
            value={comparative}
            tenantId={tenantId}
            onChange={(v) => setComparative(v)}
          />
        )}

        <CompetencesFilter tagType={tagType} value={tags} onChange={setTags} />
      </Flex>

      <CarouselWrapper
        dots={isMobile || comparative.id !== '' ? { className: 'dots' } : false}
        slidesToShow={isMobile ? 1 : comparative.id === '' ? 3 : 2}
        slidesToScroll={isMobile ? 1 : comparative.id === '' ? 3 : 2}
      >
        {mergedViews.map((item, index) => (
          <ViewCard {...item} key={index} />
        ))}
      </CarouselWrapper>
    </Flex>
  );
}

export default Views;
