import React, { ReactNode } from 'react';
import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd';
import { useMediaQuery } from '@chakra-ui/media-query';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';

interface CustomButtonProps extends Omit<AntdButtonProps, 'size' | 'variant'> {
  children?: ReactNode;
  variant?: 'outline' | 'primary';
  size: 'sm' | 'md' | 'lg';
}

function Button({
  children,
  variant = 'primary',
  size,
  ...rest
}: CustomButtonProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary, colorGreyCard } = useU4heroColors();

  const sizes = {
    sm: {
      fontSize: '14px',
      padding: '8px',
    },
    md: {
      fontSize: isMobile ? '14px' : '18px',
      padding: '8px',
    },
    lg: {
      fontSize: isMobile ? '14px' : '18px',
      padding: isMobile ? '12px' : '16px',
    },
  };
  const border = rest.style?.border ?? '0px';
  const padding = rest.style?.padding ?? sizes[size].padding;
  const textTransform = rest.style?.textTransform ?? 'uppercase';
  const disabled = rest.disabled;
  return (
    <AntdButton
      type={variant === 'outline' ? 'default' : 'primary'}
      {...rest}
      style={{
        ...rest.style,
        fontSize: sizes[size].fontSize,
        border: border,
        boxShadow:
          variant === 'outline'
            ? `inset 0 0 0 3px ${disabled ? colorGreyCard : colorPrimary}`
            : undefined,
        color:
          variant === 'outline'
            ? disabled
              ? colorGreyCard
              : colorPrimary
            : undefined,
        borderRadius: variant === 'outline' ? '10px' : undefined,
        padding: padding,
        height: 'fit-content',
        fontWeight: 600,
        textTransform: textTransform,
      }}
    >
      {children}
    </AntdButton>
  );
}

export default Button;
