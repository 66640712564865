function CustomizedRequiredMark(
  label: React.ReactNode,
  { required }: { required: boolean }
) {
  return (
    <>
      {label}&nbsp;
      {required && <span style={{ color: 'black' }}>*</span>}
    </>
  );
}

export default CustomizedRequiredMark;

