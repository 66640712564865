import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMediaQuery } from '@chakra-ui/media-query';
import { Anchor, Button, Col, Flex, Row } from 'antd';
import Layout from 'components/layout';
import usePost from 'core/features/posts/hooks/usePost';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import Text from 'components/typography/text';
import SubTitle from 'components/typography/subTitle';
import usePosts from 'core/features/posts/hooks/usePosts';
import PostCard from '../../components/postCard';
import styles from './style.module.css';
import BackButton from 'components/buttons/back-button';
import PostFeedback from '../../components/postFeedback';

interface AnchorItem {
  key: string;
  href: string;
  title: string;
  children?: AnchorItem[];
}

function PostPage() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();
  const {
    colorPrimary,
    colorGreyBorderAndFont,
    colorSecondary,
    colorPrimaryMedium,
  } = useU4heroColors();
  const {id} = useParams<{ id: string }>();
  const { data } = usePost(id);
  const { data: posts } = usePosts({ page: 1, limit: 3 });
  const createdAt = moment(data?.createdAt);

  const extractHeadings = (htmlContent: string): AnchorItem[] => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const headings = Array.from(doc.querySelectorAll('h1, h2'));
    const items: AnchorItem[] = [];

    let currentH1: AnchorItem | null = null;

    headings.forEach((heading, index) => {
      if (heading.tagName === 'H1') {
        currentH1 = {
          key: `h1-${index + 1}`,
          href: `#${heading.id}`,
          title: heading.textContent || '',
          children: [],
        };
        items.push(currentH1);
      } else if (currentH1 && heading.tagName === 'H2') {
        currentH1.children?.push({
          key: `h2-${index + 1}`,
          href: `#${heading.id}`,
          title: heading.textContent || '',
        });
      }
    });

    return items;
  };

  // Generate items for the Anchor component
  const anchorItems = data ? extractHeadings(data.content) : [];

  return (
    <Layout.Container>
      <BackButton
        onClick={() => history.goBack()}
        style={{ marginBottom: '16px' }}
      />

      <img
        alt={`banner ${data?.title ?? ''}`}
        src={data?.banner}
        style={{
          borderRadius: '10px',
          width: '100%',
          height: isMobile ? '156px' : '275px',
          objectFit: 'cover',
        }}
      />
      <Text
        style={{
          color: colorGreyBorderAndFont,
        }}
        align="end"
      >
        {createdAt.format('DD [de] MMMM [de] YYYY')}
        <br />
        Autor: {data?.author}
      </Text>
      <SubTitle primary align="center">
        {data?.title}
      </SubTitle>
      <Row justify="end" style={{ marginBottom: '16px' }}>
        <Col
          span={isMobile ? 24 : 19}
          style={{ paddingRight: isMobile ? '0px' : '65px' }}
        >
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: data?.content ?? '',
            }}
          />
        </Col>
        <Col span={isMobile ? 0 : 3}>
          <SubTitle primary>Conteúdo</SubTitle>
          <Anchor replace items={anchorItems} />
        </Col>
        <Col span={isMobile ? 24 : 19}>
          <SubTitle primary>Confira mais Novidades!</SubTitle>
          <Row gutter={isMobile ? [0, 16] : [16, 0]}>
            {posts?.data.map((item) => (
              <Col span={isMobile ? 24 : 8} key={item.id}>
                <PostCard post={item} minimalist />
              </Col>
            ))}
          </Row>
        </Col>
        <Col span={3}></Col>
      </Row>
      <PostFeedback id={id}/>
    </Layout.Container>
  );
}

export default PostPage;
