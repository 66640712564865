import { useQuery } from "react-query";
import axios from "infra/http";
import { QuestionType } from "../types/QuestionsDetails";

function useQuestionTypes() {
  return useQuery(`/v2/questions/types`, async (): Promise<QuestionType[]> => {
    const { data } = await axios.get(`/v2/questions/types`);

    return data;
  });
}

export default useQuestionTypes;