import { useMutation } from "react-query";
import http from "infra/http";

function useToggleTour() {
  return useMutation(
    (param: { id: string; isActive: boolean }) => {
      return http.put(
        `/v2/tour/${param.id}/${param.isActive ? "deactivate" : "active"}`
      );
    },
    { retry: false }
  );
}
export default useToggleTour