import { useInfiniteQuery } from 'react-query';
import axios from 'infra/http';
import { useEffect, useState } from 'react';
import { SafeSpaceItem } from '../typings';

export interface SafeSpaceParams {
  limit?: number;
  search?: string;
  anonymous?: boolean;
  unseen?: boolean;
  seen?: boolean;
  classId?: string;
  userId?: string;
  disableConcat?: boolean;
  disableQuery?: boolean;
  series?: string[];
  classes?: string[];
  flags?: number[];
  status?: number[];
  initialDate?: string;
  endDate?: string;
  tenantId?: string;
}

function useInfiniteSafespaces(params: SafeSpaceParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchMessages = async ({
    pageParam = 1,
  }): Promise<{ data: SafeSpaceItem[]; total: number }> => {
    const { data, headers } = await axios.get<SafeSpaceItem[]>(
      `/v2/safeSpace/`,
      {
        params: {
          ...params,
          search: debouncedQuery,
          page: pageParam,
        },
      }
    );

    return { data: data, total: Number(headers['total']) };
  };

  return useInfiniteQuery(
    [`/v2/safeSpace/`, { ...params, search: debouncedQuery }],
    fetchMessages,
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = allPages.length;
        const totalPages = Math.ceil(lastPage.total / (params.limit ?? 10));
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      getPreviousPageParam: (firstPage, allPages) => {
        return allPages.length > 1 ? allPages.length - 1 : undefined;
      },
      keepPreviousData: true,
      enabled: !params.disableQuery,
    }
  );
}

export default useInfiniteSafespaces;
