import { Flex } from 'antd';
import { safeSpaceFlags } from 'infra/helpers/types';
import React from 'react';


const sizes = {
  sm: {
    padding: '4px',
    fontSize: '10px',
    dot: 6,
  },
  md: {
    padding: '4px 8px',
    fontSize: '14px',
    dot: 8,
  },
};

function Classification({
  type,
  size = 'md',
}: {
  type: number;
  size?: 'sm' | 'md';
}) {
  return (
    <Flex
      align="center"
      justify="center"
      style={{
        fontWeight: 'bold',
        background: safeSpaceFlags[type].bg,
        padding: sizes[size].padding,
        borderRadius: '4px',
        width: 'fit-content',
        gap: 8,
      }}
    >
      {React.createElement(
        safeSpaceFlags[type].icon as React.ComponentType<{
          size: number;
          color: string;
        }>,
        { size: size === 'sm' ? 14 : 20, color: safeSpaceFlags[type].color }
      )}

      <p
        style={{
          fontSize: sizes[size].fontSize,
          fontWeight: 600,
          color: safeSpaceFlags[type].color,
        }}
      >
        {safeSpaceFlags[type].label}
      </p>
    </Flex>
  );
}

export default Classification;
