import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import { Image } from '@chakra-ui/image';

import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import {
  currentLocale,
  setLanguagePreference,
} from 'core/resources/strings/polyglot';
import { languages } from 'infra/helpers/types';

function SelectLanguage({ left, top = '40px' }: { left: string; top?: string }) {
  const { colorPrimary, colorPrimaryMedium, colorSecondary } =
    useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');



  return (
    <Box
      width={'194px'}
      borderRadius={'20px'}
      p={'8px'}
      bg={colorPrimaryMedium}
      display="flex"
      flexDirection="column"
      gridGap="16px"
      style={{
        position: 'relative',
        left: left,
        top: top,
        color: 'white',
      }}
    >
      {languages.map((language, key) => (
        <Flex
          key={key}
          gridGap="16px"
          fontSize={isMobile ? '16px' : '20px'}
          align="center"
          cursor="pointer"
          padding="8px"
          style={{
            background: language.value === currentLocale ? 'white' : undefined,
            borderRadius: '20px',
            color: language.value === currentLocale ? colorPrimary : undefined,
          }}
          _hover={{
            borderRadius: '20px',
            background: 'white',
            color: colorPrimary,
          }}
          _active={{
            border: `2px solid ${colorSecondary}`,
          }}
          onClick={() => {
            setLanguagePreference(language.value);
            window.location.reload();
          }}
        >
          <Image src={language.flag} w="25px" h="25px" />
          {language.name}
        </Flex>
      ))}
    </Box>
  );
}

export default SelectLanguage;
