import SideMenu from "./appbar";
import Container from "./container";
import Content from "./content";

function Layout({ children }: { children: any }) {
  return children;
}

Layout.Appbar = SideMenu;
Layout.Container = Container;
Layout.Content = Content;

export default Layout;
