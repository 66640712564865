import { GenericCard } from 'components/V2/generic-card-styled-component';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { Flex } from 'antd';
import { LuRedo2, LuUndo2 } from 'react-icons/lu';
import {
  BsFillHandThumbsDownFill,
  BsFillHandThumbsUpFill,
} from 'react-icons/bs';
import React, { useState } from 'react';
import { useMediaQuery } from '@chakra-ui/media-query';
import { MdAdd, MdRemove } from 'react-icons/md';
import Dot from 'components/dot';
import Text from 'components/typography/text';
import Button from 'components/buttons/button';
import { t } from 'core/resources/strings';

function GaugeMultipleIndicators({
  tag = t('app.semDados'),
  positive,
  data,
  average,
  id,
  onAct,
  onExplore,
}: {
  tag?: string;
  positive: boolean;
  data: {
    percentage: number | null;
    totalResponses: number;
  }[];
  average: number;
  id?: string;
  onAct: () => void;
  onExplore: () => void;
}) {
  const {
    colorPrimary,
    colorPrimaryMedium,
    colorSecondary,
    colorGreyBorderAndFont,
  } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isOpen, setIsOpen] = useState(false);
  const options: ApexOptions = {
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        offsetY: -25,
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 5,
          size: '45%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            fontSize: '1.4vh',
            show: true,
            fontWeight: 800,
          },
          value: {
            show: true,
            fontSize: '2vh',
            fontWeight: 800,
            formatter: function (val: any) {
              return Math.trunc(val) + '%';
            },
          },
          total: {
            show: true,
            label: 'Média',
            formatter: function (w) {
              return `${Math.trunc(average)}%`;
            },
          },
        },
      },
    },
    colors: [
      colorPrimary,
      hex2rgba(colorSecondary, 0.5),
      hex2rgba(colorPrimaryMedium, 0.5),
    ],
    labels: ['Conhecimento', 'Comportamento', 'Percepção'],
    grid: {
      padding: {
        top: 0,
        right: -25,
        bottom: -10,
        left: -25,
      },
    },
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const analysisTypes = [
    {
      label: t('app.conhecimento'),
      description: t('app.conhecimentoDescricao'),
      color: colorPrimary,
    },
    {
      label: t('app.comportamento'),
      description: t('app.comportamentoDescricao'),
      color: colorSecondary,
    },
    {
      label: t('app.percepcao'),
      description: t('app.percepcaoDescricao'),
      color: colorPrimaryMedium,
    },
  ];
  const renderAnalysisDetails = () => (
    <Flex vertical>
      {analysisTypes.map((item, index) => (
        <React.Fragment key={item.label}>
          <Flex gap="8px" align="center">
            <Dot color={item.color} size={12} />
            <Text size="sm">{item.label}</Text>
            <Text
              size="sm"
              style={{
                fontWeight: 700,
                color: item.color,
              }}
            >
              {data[index].percentage !== null
                ? `${Math.round(data[index].percentage as number)}%`
                : t('app.semDados')}{' '}
            </Text>
            {data[index].percentage !== null && (
              <Text
                style={{
                  fontSize: '12px',
                  fontWeight: 400,
                  color: colorGreyBorderAndFont,
                }}
              >{`(${data[index].totalResponses} resp.)`}</Text>
            )}
          </Flex>
          {isOpen && <Text size="sm">{item.description}</Text>}
        </React.Fragment>
      ))}
    </Flex>
  );

  return (
    <GenericCard padding="8px" width="100%" height={'100%'} id={id}>
      <Flex vertical style={{ width: '100%' }}>
        <Flex justify="space-between" style={{ width: '100%' }}>
          <Flex gap="8px">
            <Text>{tag}</Text>
            {positive ? (
              <BsFillHandThumbsUpFill color="#77B943" size={24} />
            ) : (
              <BsFillHandThumbsDownFill color="#B94143" size={24} />
            )}
          </Flex>
          {isOpen ? (
            isMobile ? (
              <MdRemove
                size={24}
                style={{ cursor: 'pointer' }}
                onClick={toggle}
                id={`${id}-button-close`}
              />
            ) : (
              <LuUndo2
                size={28}
                style={{ cursor: 'pointer' }}
                onClick={toggle}
                id={`${id}-button-close`}
              />
            )
          ) : isMobile ? (
            <MdAdd
              size={24}
              style={{ cursor: 'pointer' }}
              onClick={toggle}
              id={`${id}-button-open`}
            />
          ) : (
            <LuRedo2
              size={28}
              style={{ cursor: 'pointer' }}
              onClick={toggle}
              id={`${id}-button-open`}
            />
          )}
        </Flex>
        {isMobile && (
          <ReactApexChart
            width="100%"
            height={'340px'}
            options={options}
            series={data.map((item) =>
              item.percentage !== null ? item.percentage : 0
            )}
            type="radialBar"
          />
        )}

        <Flex vertical justify="space-between" style={{ height: '100%' }}>
          {!isOpen && !isMobile && (
            <ReactApexChart
              width="100%"
              height={isMobile ? '340px' : '290px'}
              options={options}
              series={data.map((item) =>
                item.percentage !== null ? item.percentage : 0
              )}
              type="radialBar"
            />
          )}
          <p
            style={{
              fontSize: '14px',
              fontWeight: 400,
              color: 'black',
              alignSelf: 'end',
              marginTop: isOpen && !isMobile ? '16px' : undefined,
            }}
          >
            {t('app.totalDeRespostas')} ={' '}
            <span style={{ fontWeight: 700 }}>
              {data.reduce((item, acc) => item + acc.totalResponses, 0)}
            </span>
          </p>
          {renderAnalysisDetails()}
          <Flex justify="center" gap="16px">
            <Button size="sm" onClick={onAct}>
              {t('app.atuar')}
            </Button>
            <Button size="sm" onClick={onExplore}>
              {t('app.explorar')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </GenericCard>
  );
}

export default GaugeMultipleIndicators;
