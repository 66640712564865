import { Col, Flex, Row } from "antd";
import { GenericQuestionAnswerCard } from "components/V2/generic-question-answered-styled-component";

function QuestionAnswerCard({
  question,
  answer
}: {
  question: string;
  answer: string;
}) {
  return (
    <GenericQuestionAnswerCard>
      <Row style={
        {
          backgroundColor: '#EFE8F6',
          borderRadius: '20px 10px 10px 20px',
          height: 'auto',
          width: '100%'
        }
      }>
        <Col style={
          {
            borderRadius: '20px 0px 0px 20px',
            padding: '20px',
            maxHeight: '100%',
            width: '100%',
            height: 'auto',
            backgroundColor: '#F5F5F5'
          }
        } flex={'25%'}>
          <Flex justify="center" align="center" vertical style={{ marginRight: '10px', height: '100%' }}>
            <p style={{ fontWeight: 400, fontSize: '18px', color: "black" }}>{question}</p>
          </Flex>
        </Col>
        <Col style={
          {
            width: '100%',
            backgroundColor: '#EFE8F6',
            borderRadius: '0px 10px 10px 0px'
          }
        } flex={'75%'}>
          <Flex align='center' justify='center' style={{ width: '100%', backgroundColor: '#EFE8F6', borderRadius: '20px 10px 10px 20px', padding: '20px' }} vertical>
            <p style={{ fontWeight: 400, fontSize: '18px', color: "black" }}>{answer}</p>
          </Flex>
        </Col>
      </Row>
    </GenericQuestionAnswerCard>
  )
}

export default QuestionAnswerCard;