import React from 'react';
import { Button, ButtonProps } from 'antd';
import { IoCloseSharp } from 'react-icons/io5';

const CloseButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      type="primary"
      size="large"
      icon={<IoCloseSharp size={25} color="white" />}
      {...props} 
    />
  );
};

export default CloseButton;
